import React from 'react';
import { DefaultInputField } from '../../../../Components/InputField/InputField';

export default function DishQuantitySection(props) {
    const { showInputDish } = props;

    return (
        <>
            <div className='flex flex-row justify-between items-center mb-2'>
                <div className='flex flex-row items-center'>
                    <span className='paragraph-medium-medium text-black mr-2 pageContent:text-sm'>Customer order</span>
                    <span className='paragraph-small-italic text-neutral-500 pageContent:text-xs'>
                        (<span className='paragraph-small-semi-bold text-primary-500'>0</span> dish,
                        <span className='paragraph-small-semi-bold text-primary-500'>0</span> Quantity)
                    </span>
                </div>

                <span className='paragraph-medium-medium text-neutral-300 pageContent:text-sm'>Clear order</span>
            </div>

            {showInputDish && (
                <div className='mb-2'>
                    <DefaultInputField
                        placeholder='Enter dish name'
                        shadow='shadow-smallDropDownShadow'
                    />
                </div>
            )}
        </>
    );
}
