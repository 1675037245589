import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';

import { ReactComponent as ReArrangeIcon } from '../../../../../Assets/re-arrange.svg';

import DishCategorySelection from './Components/DishCategorySelection';
import Table from './Components/Table';

export default function UpSellItems() {
    const [isRearrange, setIsRearrange] = useState(false);

    return (
        <>
            <div className='max-w-[636px]'>
                <DishCategorySelection />

                <div
                    className='mb-4 max-w-fit'
                    onClick={() => setIsRearrange((prevState) => !prevState)}>
                    <LargePrimaryButton
                        label='Re-arrange'
                        leftIconDefault={<ReArrangeIcon stroke='#FFFFFF' />}
                        leftIconClick={<ReArrangeIcon stroke='#C4BEED' />}
                    />
                </div>

                <Table isRearrange={isRearrange} />
            </div>
        </>
    );
}
