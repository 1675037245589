import React, { useEffect } from 'react';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import TableLoader from '../../../../../../Components/ContentLoader/TableLoader';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../../../../../../Components/FormControl/FormControls';

export default function Table(props) {
    const {
        orderingMode,
        changeStatus,
        fornatOrderName,
        isListLoading,
        isEdit,
        isSelectionEnable,
        onSelectMode,
        selectedMode,
        setEditModeId,
    } = props;

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words table-row-bg'>
                    <thead>
                        <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                            <th className='px-6 min-w-[124px] paragraph-overline-small text-neutral-700'>STATUS</th>
                            <th className='px-6 min-w-[184px] paragraph-overline-small text-neutral-700'>MODE NAME</th>
                            <th className='px-6 min-w-[159px] paragraph-overline-small text-neutral-700'>ORDER TYPE</th>
                            <th className='px-6 min-w-[167px] paragraph-overline-small text-neutral-700'>ORDER TAB</th>
                        </tr>
                    </thead>

                    <tbody>
                        {isListLoading ? (
                            <TableLoader
                                columns={4}
                                hasSwitch
                                numberOfRow={5}
                            />
                        ) : (
                            orderingMode?.map((el, index) => (
                                <tr
                                    className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                    key={index}>
                                    <td className='px-6'>
                                        <div className={`${'flex flex-row items-center'} `}>
                                            {index > 4 && isEdit && (
                                                <span
                                                    className='mr-3 cursor-pointer'
                                                    onClick={() => setEditModeId(el)}>
                                                    <EditIcon />
                                                </span>
                                            )}

                                            {index > 4 && isSelectionEnable && (
                                                <span className='mr-3'>
                                                    <CheckBoxWithoutLabels
                                                        onChange={(value) => {
                                                            onSelectMode(el.id, value);
                                                        }}
                                                        isChecked={selectedMode.includes(el.id)}
                                                    />
                                                </span>
                                            )}

                                            <ToggleSwitch
                                                setIsEnabled={(value) => changeStatus(el.id, value)}
                                                isEnable={el.status}
                                                canChange
                                            />
                                        </div>
                                    </td>

                                    <td className='px-6'>{el.display_name}</td>

                                    <td className='px-6'>{fornatOrderName(el.type)}</td>

                                    <td className='px-6'>{fornatOrderName(el.tab)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
