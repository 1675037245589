import React from 'react';
import ContentLoader from 'react-content-loader';

export function MenuItemsLoader() {
    return (
        <div className='w-full my-2 h-full'>
            <ContentLoader
                width='100%'
                height='100%'
                className='h-[160px] w-full'>
                <rect
                    x='0'
                    y='0'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='35'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='70'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='105'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='140'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
            </ContentLoader>
        </div>
    );
}

export function DropdownBoxLoader(props) {
    const { hasLabel, className, height, boxHeight } = props;

    return (
        <ContentLoader
            width='100%'
            height='100%'
            className={`${boxHeight ?? (hasLabel ? 'h-[80px]' : 'h-[52px]')} w-full ${className}`}>
            {hasLabel && (
                <rect
                    x='0'
                    y='0'
                    rx='4'
                    ry='4'
                    width='120'
                    height='20'
                />
            )}
            <rect
                x='0'
                y={hasLabel ? '32' : 0}
                rx='6'
                ry='6'
                width='100%'
                height={height ?? 40}
            />
        </ContentLoader>
    );
}

export function SingleLoader(props) {
    const { className } = props;

    return (
        <ContentLoader
            width='100%'
            height='100%'
            className={`w-full ${className}`}>
            <rect
                x='0'
                y={0}
                rx='6'
                ry='6'
                width='100%'
                height='100%'
            />
        </ContentLoader>
    );
}
