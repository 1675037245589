import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';
import { format, parse } from 'date-fns';

export default function EachDaySeparateTimeSlotPopup(props) {
    const { handleClickClose, schedule } = props;

    const isMobileScreen = IsMobileScreen();

    const sortedSchedule = schedule.map((el) => el.time_slots).sort((a, b) => a.day_of_week - b.day_of_week);

    function convertTo12HourFormat(time24) {
        const parsedTime = parse(time24, 'HH:mm', new Date());
        return format(parsedTime, 'hh:mm a');
    }

    const groupedSchedules = {};

    const dayNames = {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
    };

    for (const dayName of Object.values(dayNames)) {
        groupedSchedules[dayName] = [];
    }

    sortedSchedule.forEach(({ day_of_week, start_time, end_time }) => {
        const dayName = dayNames[day_of_week];
        groupedSchedules[dayName].push({
            startTime: `${convertTo12HourFormat(start_time)}`,
            endTime: `${convertTo12HourFormat(end_time)}`,
        });
    });

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto px-8 py-4 md:py-0 md:px-0'>
                <div className='max-w-[450px] w-full rounded-xl bg-shades-50 px-8 py-6 md:px-4 m-auto relative md:mx-4'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to banner</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-6'>
                            <div className='paragraph-large-medium'>Time slots</div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    {Object.entries(groupedSchedules).map(([day, timeSlots]) => (
                        <div
                            key={day}
                            className='mb-4'>
                            <span className='paragraph-small-medium'>{day}</span>

                            {timeSlots.length ? (
                                timeSlots.map(({ startTime, endTime }, index) => (
                                    <div
                                        key={index}
                                        className='flex flex-row items-center my-1'>
                                        <ScheduleIcon
                                            width={24}
                                            height={24}
                                        />
                                        <span className='ml-1 paragraph-small-regular'>
                                            {startTime} - {endTime}
                                        </span>
                                    </div>
                                ))
                            ) : (
                                <div className='paragraph-small-regular text-neutral-400 italic'>
                                    Time slot unavailable
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
