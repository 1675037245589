import React from 'react';
import Card from './Card';
import SliderDashboard from 'react-slick';
import { CardDescription } from './CardComponent';
import { ReactComponent as DineInIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as OrdersIcon } from '../../../Assets/orders.svg';
import { ReactComponent as RiderIcon } from '../../../Assets/riders.svg';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function ServiceTypeSales(props) {
    const { settingsDashboardSlider, numberOfOutlet } = props;

    const cardContent = [
        {
            title: 'Dine-In',
            number: '₹4,50,000.00',
            percentage: '10.2',
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <DineInIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Takeaway',
            number: '₹4,50,000.00',
            percentage: '10.2',
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <OrdersIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Delivery',
            number: '₹4,50,000.00',
            percentage: '10.2',
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <RiderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Dine-In',
            number: '₹4,50,000.00',
            percentage: '10.2',
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <DineInIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0 md:pb-2'>
                <div className='paragraph-large-medium text-black md:px-4 mt-4 lg:mt-4 mb-2 md:mb-0 md:pb-2 md:mt-0'>
                    Service type sales
                </div>
                {!isMobileScreen ? (
                    <div className='-mx-[11px] lg:-mx-0'>
                        {cardContent.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card {...el} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {cardContent.map((el, index) => (
                                <div
                                    className='inline-block mx-2.5 my-2 md:mx-0 md:mb-0 pb-1.5'
                                    key={index}>
                                    <Card {...el} />
                                </div>
                            ))}
                        </SliderDashboard>
                    </div>
                )}
            </div>
        </>
    );
}
