import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import InputSection from '../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function MOV(props) {
    const { handleDiscardForm, fetchSetting, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
        setValue,
    } = useFormContext();

    const orderingModeList = useSelector((state) => state.outlet.orderingModes);

    const formatData = (data) => {
        const filteredData = data.filter((item) => item !== null);
        return {
            minimum_ordering_values: filteredData,
        };
    };

    const onSubmit = (data) => {
        updateCodLimit(formatData(data.minimum_ordering_values));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateCodLimit = async (params) => {
        setValue('isFormLoading', true, { shouldDirty: false });

        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=minimum_ordering_values`, {
                ...params,
            });
            if (response.success) {
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
        setShowFooter(false);
    };

    const [showFooter, setShowFooter] = useState(false);

    const getOrderName = (id) => orderingModeList.find((el) => el.id === id)?.display_name;

    const availableOrderingMode = watch('ordering_modes');

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setShowFooter(false);
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <div className='md:pb-20'>
                {availableOrderingMode?.map((el, index) => {
                    if (!el.is_enabled) return null;

                    const orderingModeName = getOrderName(el.restaurant_ordering_mode_id);

                    setValue(
                        `minimum_ordering_values[${index}].restaurant_ordering_mode_id`,
                        el.restaurant_ordering_mode_id
                    );

                    return (
                        <div
                            className='mb-6'
                            key={index}>
                            <InputSection
                                title={`Minimum ordering value on ${orderingModeName} orders`}
                                description={`Set limit formMinimum ordering on ${orderingModeName} orders.`}
                                placeholder='Enter amount in rupees'
                                shadow='shadow-smallDropDownShadow'
                                name={`minimum_ordering_values[${index}].minimum_ordering_value`}
                                inputType='number'
                                handleInputChange={() => {
                                    setShowFooter(true);
                                    setShowTabChangeWarningPopup(true);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
}
