import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function NetSales() {
    const salesCardContent = [
        { 'My amount': '₹5,50,000.00' },
        {
            Discount: '- ₹1,10,000.00',
        },
        {
            'Delivery charge': '₹1,000.00',
        },
        {
            'Container charge': '₹600.00',
        },
        {
            'Service charge': '₹0.00',
        },
        {
            Tax: '₹7,000.00',
        },
        { 'Waived off': '₹0.00' },
        {
            'Round off': '- ₹0.00',
        },
    ];

    const totalSalesINR = salesCardContent.reduce((acc, obj) => {
        const value = parseFloat(Object.values(obj)[0].replace(/[₹, ]/g, ''));
        return acc + value;
    }, 0);

    const totlalSales = ['Online Sales', 'Offline Sales'];

    const sales = [25, 75];

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: totlalSales,
        legend: {
            show: false,
        },

        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9'],
        stroke: {
            colors: 'transparent',
        },
    };
    return (
        <>
            <div className='flex flex-row justify-between items-center md:block'>
                <div className='w-full'>
                    <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                        <ReactApexChart
                            className='m-auto'
                            type='donut'
                            width={280}
                            height={280}
                            series={sales}
                            options={chartOptions}
                        />
                    </div>
                    <div className='flex flex-row justify-between md:justify-center w-full max-w-[251px] md:max-w-full'>
                        <div className='flex flex-row items-center'>
                            <div className='h-[11px] w-[11px] rounded-full bg-tertiary-600 mr-2' />
                            <span className='paragraph-medium-regular'>Online Sales</span>
                        </div>

                        <div className='flex flex-row items-center md:ml-6'>
                            <div className='h-[11px] w-[11px] rounded-full bg-secondary-600 mr-2' />
                            <span className='paragraph-medium-regular'>Offline Sales</span>
                        </div>
                    </div>
                </div>
                <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                    <div className='border-b border-neutral-300 mb-4'>
                        {salesCardContent.map((el, index) => {
                            return (
                                <div
                                    key={index}
                                    className='flex flex-row justify-between mb-4'>
                                    <span className='paragraph-medium-regular'>{Object.keys(el)}</span>
                                    <span className='paragraph-medium-regular'>{Object.values(el)}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                        <span className='paragraph-medium-semi-bold'>Total Sales</span>
                        <span className='paragraph-medium-semi-bold'>{`₹${totalSalesINR
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
