import React from 'react';
import SliderDashboard from 'react-slick';
import Card from './Card';
import { CardDescription } from './CardComponent';
import { ReactComponent as MobileIcon } from '../../../Assets/mobile.svg';
import { ReactComponent as SwiggyIcon } from '../../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../../Assets/zomato.svg';
import { ReactComponent as BannerIcon } from '../../../Assets/banners.svg';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function PlatformWiseSalesCards(props) {
    const { settingsDashboardSlider, numberOfOutlet } = props;

    const cardContent = [
        {
            title: 'Mobile Application',
            number: '₹4,50,000.00',
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            percentage: '10.2',
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <MobileIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Swiggy',
            number: '₹1,25,000.00',
            percentage: '20.2',
            description: (
                <CardDescription
                    focusContent='22.42%'
                    description='You made :focusContent: of offline sales collected via cash'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: <SwiggyIcon />,
        },
        {
            title: 'Zomato',
            number: '₹1,25,000.00',
            percentage: '32.2',
            description: (
                <CardDescription
                    focusContent='22.42%'
                    description='You made :focusContent: of offline sales collected via cash'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <ZomatoIcon
                    height={32}
                    width={32}
                    stroke='#ffffff'
                />
            ),
        },
        {
            title: 'Website',
            number: '₹1,25,000.00',
            percentage: '5.2',
            description: (
                <CardDescription
                    focusContent='22.42%'
                    description='You made :focusContent: of offline sales collected via cash'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${numberOfOutlet} outlets`,
            positive: false,
            icon: (
                <BannerIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0 md:pb-2'>
                <div className='paragraph-large-medium text-black md:px-4 mt-6 lg:mt-4 mb-2 md:pb-2'>
                    Platform wise sales
                </div>

                {!isMobileScreen ? (
                    <div className='-mx-[11px] lg:-mx-0'>
                        {cardContent.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card {...el} />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <SliderDashboard {...settingsDashboardSlider}>
                            {cardContent.map((el, index) => (
                                <div
                                    className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                    key={index}>
                                    <Card {...el} />
                                </div>
                            ))}
                        </SliderDashboard>
                    </div>
                )}
            </div>
        </>
    );
}
