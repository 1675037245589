import React, { useState } from 'react';
import Header from './Header';
import { useWindowSize } from '@uidotdev/usehooks';
import AddCredit from '../Popups/AddCreditPopup';
import SetAlertPopup from '../Popups/SetAlertPopup';

export default function Layout(props) {
    const { children, alertAmount, fetchTransactionList, fetchServiceCreditList, creditType } = props;

    const screenHeight = useWindowSize().height;

    const [showPopup, setShowPopup] = useState({ addCredit: false, setAlert: false });

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                    style={{ minHeight: screenHeight - 50 }}>
                    <Header setShowPopup={setShowPopup} />
                    {children}
                </div>
            </div>
            {showPopup.addCredit && (
                <AddCredit
                    handleClickClose={() => setShowPopup({ addCredit: false, setAlert: false })}
                    creditType={creditType}
                    setShowPopup={setShowPopup}
                />
            )}
            {showPopup.setAlert && (
                <SetAlertPopup
                    alertAmount={alertAmount}
                    handleClickClose={() => setShowPopup({ addCredit: false, setAlert: false })}
                    fetchTransactionList={fetchTransactionList}
                    fetchServiceCreditList={fetchServiceCreditList}
                />
            )}
        </>
    );
}
