import React, { useState } from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as ReArrangeIcon } from '../../../Assets/re-arrange.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import APIV2 from '../../../api/axios/APIV2';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmDeletePopup from '../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import { bannerCategoryType } from '../Utils/constants';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function TableControllerButtons(props) {
    const {
        tab,
        selectedBanners,
        fetchCatalogueList,
        setSelectedBanners,
        bannerType,
        fetchStoreBannerList,
        bannerCatalogueList,
        storeBannerList,
        setPageState,
        pageState,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const navigate = useNavigate();

    const bannerCategory = [
        { item: 'Primary banner', onClick: () => handleClickTab('Primary banner') },
        { item: 'Secondary banner', onClick: () => handleClickTab('Secondary banner') },
        { item: 'Tertiary banner', onClick: () => handleClickTab('Tertiary banner') },
    ];

    const handleClickTab = (bannerType) => {
        const bannerCategory = bannerCategoryType.filter((el) => el.type === bannerType)[0];

        navigate(`/banners/${tab}/${bannerCategory.url}`);

        if (tab === 'banner-catalogue' && !bannerCatalogueList.hasOwnProperty(bannerCategory.value)) {
            fetchCatalogueList(bannerCategory);
            return;
        }

        if (tab === 'store-banner' && !storeBannerList.hasOwnProperty(bannerCategory.value)) {
            fetchStoreBannerList(bannerCategory);
        }
    };

    const isMobileScreen = IsMobileScreen();

    const handleClickDelete = async () => {
        try {
            const response = await APIV2.delete(`/restaurants/${selectedRestaurantId}/banners`, {
                params: { banner_ids: selectedBanners },
            });

            if (response.success) {
                setShowConfirmDeletePopup(false);
                tab !== 'banner-catalogue' ? fetchStoreBannerList() : fetchCatalogueList();
                setSelectedBanners([]);

                setPageState((prevState) => {
                    return {
                        ...prevState,
                        isSelectionEnable: !prevState.isSelectionEnable,
                    };
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const handleClickCancelDelete = () => {
        setPageState((prevState) => {
            return { ...prevState, isSelectionEnable: false };
        });

        setSelectedBanners([]);
    };

    const handleCloseDeletePopup = () => setShowConfirmDeletePopup(false);

    const handleClickBulSelect = () => {
        setPageState((prevState) => {
            return {
                ...prevState,
                isRearrange: false,
                isEditbanner: false,
                isSelectionEnable: !prevState.isSelectionEnable,
            };
        });
    };

    return (
        <>
            <div className='flex flex-row justify-between mb-6 sm:block'>
                {!isMobileScreen ? (
                    <div className='flex flex-row'>
                        {bannerCategory.map((el, index) => {
                            const words = bannerType.toLowerCase().split('-').join(' ');

                            const formattedBannerType = words.charAt(0).toUpperCase() + words.slice(1);

                            const isTabActive = formattedBannerType === el.item;

                            return (
                                <div
                                    className='mr-4 lg:mr-2 cursor-pointer'
                                    key={index}
                                    onClick={() => handleClickTab(el.item)}>
                                    <Tab
                                        label={el.item}
                                        isActive={isTabActive}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className='w-full'>
                        <DropDownTabs menuItems={bannerCategory} />
                    </div>
                )}

                {!pageState.isSelectionEnable ? (
                    <>
                        {tab !== 'banner-catalogue' && !isMobileScreen && (
                            <div
                                className='w-full max-w-[159px] sm:pt-4 md:pl-4 sm:pl-0 sm:max-w-full'
                                onClick={() =>
                                    setPageState((prevState) => {
                                        return { ...prevState, isRearrange: !prevState.isRearrange };
                                    })
                                }>
                                <LargePrimaryButton
                                    label='Re-arrange'
                                    leftIconDefault={<ReArrangeIcon stroke='#ffffff' />}
                                    leftIconClick={<ReArrangeIcon stroke='#C4BEED' />}
                                    isDefault={false}
                                />
                            </div>
                        )}

                        {tab === 'banner-catalogue' && (
                            <>
                                <div className='flex flex-row sm:pt-4'>
                                    <div
                                        className='md:ml-2 sm:ml-0 sm:w-1/2 min-w-fit cursor-pointer flex justify-end'
                                        onClick={() =>
                                            setPageState((prevState) => {
                                                return { ...prevState, isEditbanner: !prevState.isEditbanner };
                                            })
                                        }>
                                        <LargePrimaryButton
                                            label='Edit banner'
                                            hideLabel='lg:hidden md:block'
                                            leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                                        />
                                    </div>
                                    <div
                                        className='ml-4 md:ml-2 sm:w-1/2 min-w-fit cursor-pointer flex justify-end'
                                        onClick={handleClickBulSelect}>
                                        <LargePrimaryButton
                                            label='Bulk select'
                                            leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                            leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                            hideLabel='lg:hidden sm:block'
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className='flex flex-row items-center sm:mt-4 md:ml-4 sm:ml-0'>
                        <div
                            className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                            onClick={handleClickCancelDelete}>
                            <LargeTertiaryButton
                                isDefault={false}
                                label='Cancel'
                            />
                        </div>
                        <div
                            className='max-w-[192px] md:max-w-full w-1/2'
                            onClick={() => setShowConfirmDeletePopup(true)}>
                            <LargeDestructiveButton
                                disabled={!selectedBanners?.length}
                                label='Delete'
                                isDefault={false}
                            />
                        </div>
                    </div>
                )}

                {showConfirmDeletePopup && (
                    <ConfirmDeletePopup
                        header={<h2 className='paragraph-large-medium ml-2'>Delete the banner?</h2>}
                        description={
                            <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                                Are you sure to delete selected banner
                            </p>
                        }
                        handleClickClose={handleCloseDeletePopup}
                        handleDelete={handleClickDelete}
                    />
                )}
            </div>
        </>
    );
}
