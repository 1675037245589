import React from 'react';
import { ReactComponent as Arrow } from '../../Assets/arrow.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import DropdownWithValueLabel from '../DropDown/DropdownWithValueLabel';

export default function PaginationWithNumber(props) {
    const { lastPage, setSelectedPage, selectedPage, setResultPerPage, selectedResultPerpage } = props;
    const pageSize = useWindowSize();

    const pageNumbers = [];

    for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i);
    }

    const renderEllipsis = () => {
        return (
            <div className='min-w-[42px] mobile:min-w-[30px] h-full flex flex-col border-l border-neutral-300 cursor-default'>
                <span className='m-auto px-0.5'>...</span>
            </div>
        );
    };

    const renderPageNumber = (number) => {
        return (
            <div
                style={{ backgroundColor: number === selectedPage ? '#C4BEED' : '' }}
                className='min-w-[42px] mobile:min-w-[30px] h-full flex flex-col border-l border-neutral-300 cursor-default'
                onClick={() => setSelectedPage(number)}>
                <span className='m-auto px-0.5'>{number}</span>
            </div>
        );
    };

    const renderPagination = () => {
        let pagination = [];

        if (lastPage <= 5) {
            pagination = pageNumbers.map((number) => renderPageNumber(number));
        } else {
            if (selectedPage <= 2) {
                pagination = [
                    ...pageNumbers.slice(0, 3),
                    renderEllipsis(),
                    pageNumbers[lastPage - 3],
                    pageNumbers[lastPage - 2],
                    pageNumbers[lastPage - 1],
                ];
            } else if (selectedPage >= lastPage - 3) {
                pagination = [1, renderEllipsis(), ...pageNumbers.slice(lastPage - 5, lastPage)];
            } else {
                pagination = [
                    1,
                    2,
                    renderEllipsis(),
                    ...pageNumbers.slice(selectedPage - 1, selectedPage + 1),
                    renderEllipsis(),
                    pageNumbers[lastPage - 1],
                ];
            }
        }

        if (pageSize.width < 650) {
            pagination = [selectedPage];
        }

        return pagination.map((number, index) => (
            <React.Fragment key={index}>
                {typeof number === 'number' ? renderPageNumber(number) : number}
            </React.Fragment>
        ));
    };

    const resultPerPageMenuItems = [
        {
            label: 10,
            value: 10,
        },
        {
            label: 20,
            value: 20,
        },
        {
            label: 30,
            value: 30,
        },
        {
            label: 40,
            value: 40,
        },
        {
            label: 50,
            value: 50,
        },
    ];

    return (
        <>
            <div className='flex flex-row items-center justify-between w-full'>
                <span className='paragraph-small-medium mobile:paragraph-small-regular'>
                    {pageSize.width > 450 && 'Showing'} {selectedPage} of {lastPage} results
                </span>

                <div className='flex flex-row'>
                    <div className='w-[70px]'>
                        <DropdownWithValueLabel
                            menuItems={resultPerPageMenuItems}
                            height='h-[37.5px]'
                            boxPadding='px-4 mobile:px-2'
                            setSelectedItemFromDropDown={setResultPerPage}
                            selectedItem={
                                selectedResultPerpage ?? selectedResultPerpage
                                    ? { label: selectedResultPerpage, value: selectedResultPerpage }
                                    : { label: 10, value: 10 }
                            }
                            openFromBottom
                            setSelected={(selection) => setResultPerPage(selection.value)}
                        />
                    </div>

                    <div className='flex flex-row items-center rounded-[5px] border h-[38px] border-neutral-300 paragraph-small-regular ml-4 md:ml-2'>
                        <div
                            className={`min-w-[42px] mobile:min-w-[30px] h-full flex flex-col ${
                                selectedPage > 1 ? 'cursor-pointer' : 'bg-neutral-200 cursor-not-allowed'
                            }`}
                            onClick={() => {
                                lastPage > 1 && selectedPage > 1 && setSelectedPage(selectedPage - 1);
                            }}>
                            <Arrow className='m-auto' />
                        </div>

                        {renderPagination()}

                        <div
                            className={`min-w-[42px] mobile:min-w-[30px] h-full flex flex-col border-l border-neutral-300 ${
                                selectedPage >= lastPage ? 'bg-neutral-200 cursor-not-allowed' : 'cursor-pointer'
                            }`}
                            onClick={() => {
                                selectedPage < lastPage && setSelectedPage(selectedPage + 1);
                            }}>
                            <Arrow className='rotate-180 m-auto' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
