import React, { useCallback, useEffect, useState } from 'react';
import Layout from './Components/Layout';
import { useSelector } from 'react-redux';
import APIV2 from '../../../../api/axios/APIV2';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import Filters from './Components/Filters';
import OfferListTable from './Components/OfferListTable';
import { fetchRestaurantOffer } from '../functions';
import _ from 'lodash';

export default function LinkOfferPopup(props) {
    const { fetchRestaurantOfferList, handleClickClose, fetchOutletWiseOffersList } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [linkUnlinkOutletList, setLinkUnlinkOutletList] = useState({ linkedOutlets: [], unLinkedOutlets: [] });

    const handleClickSave = async () => {
        try {
            const linkPromises = linkUnlinkOutletList.linkedOutlets.map((el) => linkOutlet(el));
            const unlinkPromises = linkUnlinkOutletList.unLinkedOutlets.map((el) => unlinkOutlet(el));

            await Promise.all([...linkPromises, ...unlinkPromises]);

            handleClickClose();

            fetchRestaurantOfferList();

            fetchOutletWiseOffersList();
        } catch (error) {
            console.log(error);
        }
    };

    const linkOutlet = async (linkOfferId) => {
        try {
            const response = await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${linkOfferId}/link`, {
                outlet_ids: [selectedOutletId],
            });

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const unlinkOutlet = async (unLinkOfferId) => {
        try {
            const response = await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${unLinkOfferId}/un-link`, {
                outlet_ids: [selectedOutletId],
            });

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const handleStatusChange = (offerId, doLink) => {
        setLinkUnlinkOutletList((prevState) => {
            const updatedList = { ...prevState };

            if (doLink) {
                updatedList.linkedOutlets = [...prevState.linkedOutlets, offerId];

                updatedList.unLinkedOutlets = prevState.unLinkedOutlets.filter((id) => id !== offerId);
            } else {
                updatedList.unLinkedOutlets = [...prevState.unLinkedOutlets, offerId];

                updatedList.linkedOutlets = prevState.linkedOutlets.filter((id) => id !== offerId);
            }

            return updatedList;
        });
    };

    const [appliedFilter, setAppliedFilter] = useState({ is_offer_active: null, keyword: '' });

    const handleSearchKeyword = useCallback(
        _.debounce(async (keyword) => {
            fetchAndSetList({ ...params, keyword, ...initialPageState });
        }, 1000)
    );

    const handleFilterChange = (filterType, value) => {
        setAppliedFilter((prevFilter) => ({
            ...prevFilter,
            [filterType]: value,
        }));

        setPageState(initialPageState);

        filterType === 'keyword'
            ? handleSearchKeyword(value)
            : fetchAndSetList({ ...params, [filterType]: value, ...initialPageState });
    };

    const [list, setList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const formateOfferList = (offer) => {
        const formattedList = offer.map((offer) => {
            const isLinkedToCurrentOutlet = offer.linked_outlets.includes(selectedOutletId);
            return {
                ...offer,
                isLinkedToCurrentOutlet,
            };
        });
        setList(formattedList);
    };

    const fetchAndSetList = async (params) => {
        setIsLoading(true);

        try {
            const offer = await fetchRestaurantOffer(params, selectedRestaurantId);

            formateOfferList(offer.data);

            setPageState((prevState) => ({ ...prevState, last_page: offer.last_page }));

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const initialPageState = { page: 1, per_page: 10, last_page: null };

    const [pageState, setPageState] = useState(initialPageState);

    const params = {
        page: pageState.page,
        per_page: pageState.per_page,
        ...appliedFilter,
    };

    useEffect(() => {
        fetchAndSetList(params);
    }, []);

    const handlePageStateChange = (state, value) => {
        const newState = { ...pageState, [state]: value };

        setPageState((prevState) => {
            return { ...prevState, [state]: value };
        });

        fetchAndSetList({ ...params, ...newState });
    };

    return (
        <Layout
            handleClickClose={handleClickClose}
            handleClickSave={handleClickSave}
            isSaveDisabled={
                !(linkUnlinkOutletList.linkedOutlets.length || linkUnlinkOutletList.unLinkedOutlets.length)
            }>
            <Filters handleFilterChange={handleFilterChange} />

            <OfferListTable
                handleStatusChange={handleStatusChange}
                list={list}
                isLoading={isLoading}
            />

            <div className='mt-4'>
                <PaginationWithNumber
                    setSelectedPage={(value) => handlePageStateChange('page', value)}
                    setResultPerPage={(value) => handlePageStateChange('per_page', value)}
                    selectedResultPerpage={pageState.per_page}
                    lastPage={pageState.last_page}
                    selectedPage={pageState.page}
                />
            </div>
        </Layout>
    );
}
