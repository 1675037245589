import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function ListViewCustomerGroupDetails(props) {
    const { groupName, createdBy, lastUpdated, customerCount, createdOn, notificationsSent } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 pb-0.5'>GROUP NAME:</h3>
                        <span className='paragraph-small-regular'>{groupName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <div className='mt-1.5'>
                        <div className='mb-1.5 items-center flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED BY:</span>
                            <span className='paragraph-small-regular ml-1 text-primary-500'>{createdBy}</span>
                        </div>
                        <div className='mb-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>LAST UPDATED:</span>
                            <span className='paragraph-small-regular ml-1'>{lastUpdated}</span>
                        </div>
                        <div className='mb-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>CUSTOMER COUNT:</span>
                            <span className='paragraph-small-regular ml-1'>{customerCount}</span>
                        </div>

                        <div className='mb-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED ON:</span>
                            <span className='paragraph-small-regular ml-1'>{createdOn}</span>
                        </div>
                        <div className=''>
                            <span className='paragraph-overline-small text-neutral-700'>NOTIFICATIONS SENT:</span>
                            <span className='paragraph-small-regular ml-1'>{notificationsSent}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
