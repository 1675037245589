import React from 'react';
import { ReactComponent as ReplaceIcon } from '../../../../../../Assets/replace.svg';

export default function DeleteVariantPopUp(props) {
    const { handleClickClose, variantNumber, handleClickDelete } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-[101] md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='pb-3 flex flex-row items-center'>
                        <div className='p-2 bg-destructive-200 rounded-md'>
                            <ReplaceIcon stroke='#EF4444' />
                        </div>
                        <h2 className='paragraph-large-medium ml-2'>Delete the variant?</h2>
                    </div>
                    <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                        Are you sure to delete the
                        <span className='text-destructive-500 italic'> Variant {variantNumber} </span>?
                    </p>

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300'>
                            Cancel
                        </button>

                        <button
                            className='h-12 w-[196px] md:w-1/2 md:ml-[7.5px] paragraph-medium-medium rounded-md text-shades-50 bg-destructive-500'
                            onClick={handleClickDelete}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
