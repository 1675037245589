import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function DropdownSection(props) {
    const {
        title,
        description,
        name,
        disabled,
        placeholder,
        menuItems,
        labelPaddingB,
        openFromBottom,
        label,
        labelStyle,
        fixedHeight,
        rules,
        clearErrorName,
        marginBetween,
        dontShowError,
        boxHeight,
        hasFixedPlaceholder,
    } = props;

    const {
        control,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const handleSelection = (selection, fieldChange) => {
        fieldChange(selection);

        if (clearErrorName) {
            clearErrors([name, ...clearErrorName]);
        } else {
            clearErrors(name);
        }

        props?.handleSelection?.(selection);
    };

    return (
        <>
            <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                <TitleDescription
                    title={title}
                    disabled={disabled}
                    description={description}
                />
            </div>

            <div className='w-full max-w-[312px] md:max-w-full'>
                <Controller
                    render={({ field }) => (
                        <DropdownWithValueLabel
                            menuItems={menuItems}
                            placeholder={placeholder}
                            shadow='shadow-xSmall'
                            height={boxHeight ?? 'h-[48px]'}
                            selectedItem={field.value}
                            setSelected={(selection) => {
                                handleSelection(selection, field.onChange);
                            }}
                            disabled={disabled}
                            labelPaddingB={labelPaddingB}
                            openFromBottom={openFromBottom}
                            label={label}
                            labelStyle={labelStyle}
                            fixedHeight={fixedHeight}
                            hasFixedPlaceholder={hasFixedPlaceholder}
                        />
                    )}
                    name={name}
                    control={control}
                    rules={rules ?? {}}
                />
                {!dontShowError && errors?.[name] && <ErrorMessage errors={errors?.[name]} />}
            </div>
        </>
    );
}
