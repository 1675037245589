import React, { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSelector } from 'react-redux';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import { LargePrimaryButton, LargeTertiaryButton } from '../Buttons/Button';
import APIV5 from '../../api/axios/APIV5';
import { useParams } from 'react-router-dom';
import CircleLoader from '../CircleLoader/CircleLoader';
import TableLoader from '../ContentLoader/TableLoader';
import { handleClickSaveOnTableArrangement } from '../../Screen/TableManagement/HelperFunction/ApiFunctions';
import { ErrorMessage } from '../Error/ErrorMessage';
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';

export default function LeftFlyOut(props) {
    const {
        handleClickClose,
        sectionList,
        totalTable,
        setTotalTable,
        setloaderStatus,
        loaderStatus,
        setTableLists,
        seterrorOfApi,
        errorOfApi,
    } = props;
    const ref = useRef();
    const { section } = useParams();
    let selectedSection = sectionList.find((sections) => sections?.internal_name == section);
    const sensors = useSensors(useSensor(PointerSensor));
    const [tableList, settableList] = useState([]);
    const [isListLoading, setisListLoading] = useState(false);
    const [tableListUpdated, setTableListUpdated] = useState(false);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    useOutsideClick(ref, handleClickClose);

    const fetchTable = async () => {
        try {
            setisListLoading(true);
            const responseSubSection = await Promise.all(
                (selectedSection?.sub_section).map((item, index) => {
                    return APIV5.get(`outlets/40/outlet-sections/${item.id}/table-list`, {
                        params: {
                            per_page: totalTable[index + 1],
                            page: 1,
                        },
                    });
                })
            );
            if (responseSubSection) {
                formateTableResonse([...responseSubSection]);
                setisListLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formateTableResonse = (response) => {
        let tables = [];
        setTotalTable([]);
        response.map((item, index) => {
            setTotalTable((prevState) => [...prevState, response[index]?.tables?.total]);
            const table = {
                subSection: selectedSection?.sub_section[index],
                subSectionTitle: selectedSection?.sub_section[index]?.internal_name,
                tables: item?.tables?.data,
            };
            tables = [...tables, table];
        });
        settableList(tables);
    };

    function handleDragOver({ event, index }) {
        const { active, over } = event;
        if (active?.id && over?.id && active?.id !== over?.id) {
            settableList((previousState) => {
                const updatedTableList = [...previousState];
                const oldIndex = updatedTableList[index].tables.findIndex((item) => item.id === active.id);
                const newIndex = updatedTableList[index].tables.findIndex((item) => item.id === over.id);
                updatedTableList[index].tables = arrayMove(updatedTableList[index].tables, oldIndex, newIndex);
                updatedTableList[index].tables.map((item, index) => {
                    item.display_rank = index + 1;
                });
                setTableListUpdated(true);
                return updatedTableList;
            });
        }
    }

    useEffect(() => {
        fetchTable();
    }, []);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='max-w-[800px] w-full right-0 fixed h-screen bg-shades-50 px-8 py-8 md:px-0 md:pt-4 md:pb-20 m-auto md:max-w-full overflow-auto'
                    ref={ref}>
                    <div className='paragraph-large-medium mb-8'>Re-arrage table list</div>
                    {isListLoading ? (
                        <table>
                            <tbody>
                                <div>
                                    <TableLoader columns={2} />
                                </div>
                                <div className='mt-[30px]'>
                                    <TableLoader columns={2} />
                                </div>
                            </tbody>
                        </table>
                    ) : (
                        <>
                            <span className='paragraph-large-medium mb-4'>{selectedSection?.internal_name}</span>
                            {tableList.map((section, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className='paragraph-large-medium mb-4'>{section?.subSectionTitle}</div>
                                        <div className='w-full border border-neutral-300 rounded-lg overflow-auto scrollbar-style mb-12'>
                                            <DndContext
                                                sensors={sensors}
                                                collisionDetection={closestCenter}
                                                onDragEnd={(event) => handleDragOver({ event, index })}
                                                key={index}
                                                modifiers={[
                                                    restrictToVerticalAxis,
                                                    restrictToVerticalAxis,
                                                    restrictToParentElement,
                                                    restrictToFirstScrollableAncestor,
                                                ]}>
                                                <table className='w-full break-words'>
                                                    <TableHeader />

                                                    <tbody className='overflow-auto h-max block'>
                                                        <SortableContext
                                                            items={section.tables}
                                                            strategy={verticalListSortingStrategy}
                                                            disabled={false}
                                                            dragOverlay={true}>
                                                            {section.tables.length < 1 ? (
                                                                <tr className='h-[70px] justify-center paragraph-small-regular  first:border-none border-t'>
                                                                    <td className='pl-6 cursor-default'>
                                                                        <div className='items-center'>No Tables</div>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                section.tables?.map((el, index) => {
                                                                    return (
                                                                        <TableRows
                                                                            {...el}
                                                                            key={index}
                                                                        />
                                                                    );
                                                                })
                                                            )}
                                                        </SortableContext>
                                                    </tbody>
                                                </table>
                                            </DndContext>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </>
                    )}

                    <div className='flex flex-row items-center fixed bg-white bottom-2 py-2 w-full max-w-[735px]'>
                        <div
                            className='w-1/2 mr-4'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>

                        <div
                            className='w-1/2 ml-4'
                            onClick={() =>
                                handleClickSaveOnTableArrangement({
                                    tableList,
                                    setloaderStatus,
                                    handleClickClose,
                                    setTableLists,
                                    setTotalTable,
                                    section,
                                    sectionList,
                                    seterrorOfApi,
                                    selectedOutletId,
                                    selectedRestaurantId,
                                })
                            }>
                            <LargePrimaryButton
                                label={
                                    !loaderStatus.isLoading ? (
                                        'Save'
                                    ) : (
                                        <CircleLoader
                                            isLoading={loaderStatus.isLoading}
                                            isResponseSuccess={loaderStatus.isResponseSuccess}
                                        />
                                    )
                                }
                                disabled={!tableListUpdated}
                            />
                        </div>
                    </div>
                    <div className='fixed bottom-0'>
                        {errorOfApi && <ErrorMessage errors={{ message: errorOfApi }} />}
                    </div>
                </div>
            </div>
        </>
    );
}
