import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import Layout from './Components/Layout';
import ListSection from './Components/ListSection';
import APIV2 from '../../../api/axios/APIV2';
import _ from 'lodash';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

export default function LoyaltySignupCashback() {
    const today = new Date();
    const navigate = useNavigate();

    const [selectedDates, setSelectedDates] = useState([today, today]);
    const isMobileScreen = IsMobileScreen();

    const [orderDetails, setOrderDetails] = useState([]);

    const [popupOrderId, setPopupOrderId] = useState(null);

    const [updatedpaginationStatus, setUpdatedpaginationStatus] = useState({});

    const showMainLayout = !isMobileScreen || !popupOrderId;

    const [paginationState, setPaginationState] = useState({ resultPerPage: 10, selectedPage: 1, lastPage: 1 });
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const defaultParams = (per_page, page) => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        return {
            filters: {
                from_date: formatDate(from_date),
                to_date: formatDate(to_date),
            },
        };
    };

    const fetchOrderDetail = async (params) => {
        try {
            const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/sign-up-cashbacks`, {
                params: {
                    ...params,
                    ...defaultParams(),
                    per_page: params?.per_page ?? updatedpaginationStatus.resultPerPage,
                    page: params?.page ?? updatedpaginationStatus.selectedPage,
                },
            });

            setOrderDetails(response.customer_sign_up_reward_points.data);

            setPaginationState((prevState) => {
                return {
                    ...prevState,
                    lastPage: response.customer_sign_up_reward_points.last_page,
                    selectedPage: response.customer_sign_up_reward_points.current_page,
                };
            });
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const updatePaginationState = (updates) => {
        setUpdatedpaginationStatus(updates);

        const newSelectedPage =
            updates.selectedPage !== undefined ? updates.selectedPage : paginationState.selectedPage;
        const params = {
            page: newSelectedPage,
            per_page: updates.resultPerPage ?? updatedpaginationStatus.resultPerPage,
        };

        fetchOrderDetail(params, () => {
            if (newSelectedPage === paginationState.selectedPage) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    selectedPage: newSelectedPage,
                    per_page: updates.resultPerPage ?? paginationState.resultPerPage,
                    ...updates,
                }));
            }
        });
    };

    const handlePagination = (data, type) => {
        if (type === 'selectedPage') {
            updatePaginationState(data);
            setPaginationState((prevState) => ({ ...prevState, ...data }));
            return;
        }

        updatePaginationState({ ...data, selectedPage: 1 });
        setPaginationState((prevState) => ({ ...prevState, ...data, selectedPage: 1 }));
    };

    useEffect(() => {
        setIsLoading(true);
        fetchOrderDetail();

        // eslint-disable-next-line
    }, [selectedDates]);

    return (
        <>
            <Layout
                showMainLayout={showMainLayout}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                setIsLoading={setIsLoading}>
                <ListSection
                    selectedDates={selectedDates}
                    updatePaginationState={updatePaginationState}
                    paginationState={paginationState}
                    isLoading={isLoading}
                    orderDetails={orderDetails}
                    handlePagination={handlePagination}
                    fetchOrderDetail={fetchOrderDetail}
                />
            </Layout>
        </>
    );
}
