import React from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import ListViewOutletMenu from '../Catalogue/Components/ListViewOutletMenu';
import { ReactComponent as MenuIcon } from '../../../../Assets/menu.svg';

export default function OutletMenu() {
    const tableDetails = [
        {
            outletName: "Domino's Pizza - Tagore Road Outlet",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
            presetAttached: 'Menu - 007',
            categories: '12',
            dishes: '128',
        },
        {
            outletName: "Domino's Pizza - University Road Outlet",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
            presetAttached: 'Medium Scale Menu - 006',
            categories: '08',
            dishes: '96',
        },
        {
            outletName: "Domino's Pizza - Ahmedabad Road Outlet",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
            presetAttached: 'Medium Scale Menu - 005',
            categories: '08',
            dishes: '120',
        },
        {
            outletName: "Domino's Pizza - Jamnagar Road Outlet",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
            presetAttached: 'Medium Scale Menu - 004',
            categories: '03',
            dishes: '36',
        },
    ];

    const navigate = useNavigate();

    const handleClickOutletName = () => {
        navigate('/menu/outlet-menu', { state: { page: 'outlet-menu' } });
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <div className='mb-4'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[289px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        OUTLET NAME
                                    </th>
                                    <th className='px-6 min-w-[132px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        STATE
                                    </th>
                                    <th className='px-6 min-w-[109px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CITY
                                    </th>
                                    <th className='px-6 min-w-[284px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        ADDRESS
                                    </th>
                                    <th className='px-6 min-w-[223px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        PRESET ATTACHED
                                    </th>
                                    <th className='px-6 min-w-[233px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CATEGORIES AND DISHES
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableDetails.map((el, index) => (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td
                                            className='px-6 cursor-pointer'
                                            onClick={handleClickOutletName}>
                                            {el.outletName}
                                        </td>
                                        <td className='px-6'>{el.state}</td>
                                        <td className='px-6'>{el.city}</td>
                                        <td className='px-6'>{el.address}</td>
                                        <td className='px-6'>{el.presetAttached}</td>
                                        <td className='px-6'>
                                            <div className='flex flex-col'>
                                                <div className='flex flex-row items-center mb-1'>
                                                    <MenuIcon />
                                                    <span className='paragraph-small-regular ml-2'>
                                                        Categories - {el.categories}
                                                    </span>
                                                </div>
                                                <div className='flex flex-row items-center'>
                                                    <MenuIcon />
                                                    <span className='paragraph-small-regular ml-2'>
                                                        Dishes - {el.dishes}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className=''>
                    {tableDetails.map((el, index) => (
                        <div
                            className='mt-2'
                            key={index}>
                            <ListViewOutletMenu
                                outletName={el.outletName}
                                state={el.state}
                                city={el.city}
                                address={el.address}
                                presetAttached={el.presetAttached}
                                categories={el.categories}
                                dishes={el.dishes}
                                handleClickViewDetails={handleClickOutletName}
                            />
                        </div>
                    ))}
                </div>
            )}

            {!isMobileScreen && <PaginationWithNumber />}
        </>
    );
}
