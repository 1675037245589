import React, { useMemo, useState } from 'react';
import DropDownOutlet from '../../../Components/DropDown/DropDownOutlet';
import DropDownAvatar from '../../../Components/DropDown/DropDownAvatar';
import NotificationsPopup from '../Notification/NotificationsPopup';
import { ReactComponent as NotificationIcon } from '../../../Assets/notifications.svg';
import { ReactComponent as HamburgerIcon } from '../../../Assets/hamburger.svg';
import { ReactComponent as SettingsIcon } from '../../../Assets/settings.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import HoldOrder from '../HoldOrder';
import { useSelector } from 'react-redux';
import { links } from './links';
import NewOrder from './NewOrder';

export default function DesktopHeader(props) {
    const {
        setShowRestartOrderPopup,
        toggleLeftPanel,
        isLeftPanelExpanded,
        isDockLetPanel,
        setIsLeftPanelExpanded,
        setShowDeliveryPopup,
        setShowTakeAwayPopup,
        setShowLogoutPopup,
    } = props;

    const [showNotificationPopup, setShowNotificationPopup] = useState(false);

    const navigate = useNavigate();

    const url = useLocation().pathname;

    const pageLabel = useMemo(() => {
        const moduleUrl = url.match(/\/[^/]+/)?.[0] ?? url;

        for (const group of links) {
            for (const link of group.group) {
                if (link.to === moduleUrl || link.baseUrl === moduleUrl) {
                    return link.name;
                }
            }
        }
    }, [url]);

    const isHoldOrder = useSelector((state) => state.orderStatus.isHold);

    const shouldShowRestartOrder = url === '/pos';

    return (
        <>
            <div
                className='flex items-center pl-6 justify-between bg-neutral-50 w-[-webkit-fill-available] h-[50px] sticky top-0 border-b border-neutral-300 px-[25px] lg:pr-4 z-10'
                style={{ width: '-moz-available' }}>
                <div className='flex items-center relative'>
                    <div
                        className='mr-[19px] cursor-pointer'
                        onClick={toggleLeftPanel}>
                        <HamburgerIcon />
                    </div>

                    {isLeftPanelExpanded && !isDockLetPanel && (
                        <span
                            onClick={() => setIsLeftPanelExpanded(false)}
                            style={{
                                display: 'block',
                                position: 'fixed',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                background: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 30,
                            }}
                        />
                    )}
                    <div>
                        <DropDownOutlet label={pageLabel} />
                    </div>
                    <NewOrder
                        className='ml-4 pl-4 border-l border-neutral-300'
                        setShowDeliveryPopup={setShowDeliveryPopup}
                        setShowTakeAwayPopup={setShowTakeAwayPopup}
                    />
                </div>

                <div>
                    <div className='flex items-center flex-row'>
                        {true && (
                            <div className='border-r border-neutral-300 mr-4 pr-4'>
                                <HoldOrder />
                            </div>
                        )}

                        {shouldShowRestartOrder && (
                            <div
                                className='paragraph-medium-medium text-end text-destructive-500 pr-5 cursor-pointer largeTablet:pr-2 largeTablet:text-xs'
                                onClick={() => setShowRestartOrderPopup((prevState) => !prevState)}>
                                Restart Order
                            </div>
                        )}

                        <div>
                            <div className='flex items-center flex-row'>
                                <div className='flex items-center h-10 border-r border-neutral-300'>
                                    <span
                                        className='mr-7 largeTablet:mr-2 cursor-pointer'
                                        onClick={() => navigate('/device-setting')}>
                                        <SettingsIcon
                                            stroke='#706E7E'
                                            width='20px'
                                            height='20px'
                                        />
                                    </span>

                                    <div className='mr-7 largeTablet:mr-2 relative'>
                                        <div
                                            className={`cursor-pointer p-2 ${
                                                showNotificationPopup &&
                                                'bg-[#E2DFF6] rounded-md border-primary-500 border'
                                            }`}
                                            onClick={() => setShowNotificationPopup((prevState) => !prevState)}>
                                            <NotificationIcon
                                                stroke={`${!showNotificationPopup ? '#706E7E' : '#6C5DD3'}`}
                                                width='18px'
                                                height='18px'
                                            />
                                        </div>

                                        {showNotificationPopup && (
                                            <div className='absolute right-0 top-[42px]'>
                                                <NotificationsPopup />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <DropDownAvatar
                                        label='Shyam T.'
                                        widthIcon='24'
                                        heightIcon='24'
                                        setShowLogoutPopup={setShowLogoutPopup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
