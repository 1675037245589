import React, { useState } from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import TableSection from './Components/TableSection';
import DistanceFeeGstSection from './Components/DistanceFeeGstSection';
import ThresholdPopup from '../../Components/ThresholdPopup';
import InputSection from '../../../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function DeliveryCharges(props) {
    const { handleDiscardForm, fetchSetting, setShowTabChangeWarningPopup } = props;

    const [showThresholdPopup, setShowThresholdPopup] = useState({});

    const {
        handleSubmit,
        formState: { isDirty, errors, isValid },
        setValue,
        reset,
        clearErrors,
        watch,
    } = useFormContext();

    const formatData = (data) => {
        return {
            base_delivery_charge: parseInt(data.base_delivery_charge),
            delivery_charges_gst_slab: data.hasGstSlab ? parseInt(data.gstSlab.value) : 0,
            extra_delivery_charge: data?.hasDistanceFees ? parseInt(data.distance_fee.extra_charge) : null,
            normal_delivery_radius: data?.hasDistanceFees ? data.distance_fee.normal_delivery_radius : null,
            delivery_charge_thresholds: data?.delivery_charges.length
                ? data?.delivery_charges?.map((item) => ({
                      ...item,
                      threshold: parseInt(item.threshold),
                      above_threshold: parseInt(item.above_threshold),
                  }))
                : [{ threshold: 0, above_threshold: 0 }],
        };
    };

    const onSubmit = (data) => {
        updateDeliveryCharge(formatData(data.delivery_charge));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateDeliveryCharge = async (params) => {
        try {
            const response = await APIV5.put(`outlets/${selectedOutletId}?section=delivery_charge`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [activeInactiveMode, setActiveInactiveMode] = useState({ activeMode: [], inActiveMode: [] });

    const handleCloseThresholdPopup = () => {
        setShowThresholdPopup({});

        setValue('deliveryChargeThreshold', null);

        setValue('aboveThreshold', null);

        clearErrors(['deliveryChargeThreshold', 'aboveThreshold']);
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <>
            <Layout
                handleClickSave={handleSubmit(onSubmit)}
                handleClickCancel={() => {
                    setShowTabChangeWarningPopup(false);
                    handleDiscardForm();
                }}
                showFooter={isDirty}>
                <div className='lg:max-w-[464px] md:max-w-full md:pb-20'>
                    <TitleDescription
                        className='mb-4'
                        title='Delivery charges'
                        description='Set delivery charges for your customers to pay on checkout for delivery orders only.'
                    />

                    <InputSection
                        placeholder='Please enter base delivery charges'
                        className='mb-4'
                        name='delivery_charge.base_delivery_charge'
                        inputType='number'
                        title='Add base delivery charge'
                        description='Set base delivery charges for delivery orders.'
                        handleInputChange={() => setShowTabChangeWarningPopup(true)}
                    />

                    <TableSection
                        setShowThresholdPopup={setShowThresholdPopup}
                        activeInactiveMode={activeInactiveMode}
                        setActiveInactiveMode={setActiveInactiveMode}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />

                    <DistanceFeeGstSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />
                </div>
            </Layout>

            {showThresholdPopup?.type === 'add' && (
                <ThresholdPopup
                    title='Add threshold'
                    subTitle='Enter threshold values to set within the system'
                    handleClickClose={() => handleCloseThresholdPopup()}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />
            )}

            {showThresholdPopup?.type === 'edit' && (
                <ThresholdPopup
                    title='Edit threshold'
                    subTitle='Edit threshold values to set within the system'
                    handleClickClose={() => handleCloseThresholdPopup()}
                    showThresholdPopup={showThresholdPopup}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />
            )}
        </>
    );
}
