import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react';

export default function FormLayout(props) {
    const { children, isDirty } = props;

    const screenSize = useWindowSize();

    return (
        <div className='pr-0.5 border-r border-neutral-300 w-full xl:pr-0 xl:border-r-0 xl:mb-6 xl:min-w-full md:-mx-1 md:w-auto'>
            <div
                className='min-w-[647px] w-full xl:min-w-full xl:pb-2 xl:border-b overflow-auto scrollbar-style pr-2 lg:overflow-hidden pl-1 pageContent:min-w-0 md:px-1'
                style={{
                    height: !isDirty
                        ? '100%'
                        : !(screenSize.width < 1280)
                          ? screenSize.height < 905
                              ? '100%'
                              : screenSize.height - 244
                          : '100%',
                }}>
                {children}
            </div>
        </div>
    );
}
