import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import DropdownSection from '../../Components/DropdownSection';
import InputSection from '../../Components/InputSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function ConvenientChargeSection(props) {
    const { discountTypeMenuItems, setShowTabChangeWarningPopup } = props;

    const {
        control,
        watch,
        clearErrors,
        setValue,
        formState: { errors },
    } = useFormContext();

    const toggleChange = (value, fieldChange) => {
        setShowTabChangeWarningPopup(true);
        fieldChange(value);
        clearErrors(['bill_payments.convenienceChargeType', 'bill_payments.convenientCharge']);
    };

    useEffect(() => {
        if (!watch('bill_payments.bill_payment_convenience_charge')) {
            return;
        }
        if (watch('bill_payments.bill_payment_convenience_charge_type') === 'percentage_charge') {
            setValue('bill_payments.convenienceChargeType', discountTypeMenuItems[0]);
        } else {
            setValue('bill_payments.convenienceChargeType', discountTypeMenuItems[1]);
        }

        // eslint-disable-next-line
    }, [watch('bill_payments.bill_payment_convenience_charge_type')]);

    const handleSelectionType = () => {
        setShowTabChangeWarningPopup(true);
        clearErrors('errors.bill_payments.bill_payment_convenience_charge');
    };

    return (
        <>
            <div className='flex flex-row items-center mb-6'>
                <h1
                    className={`paragraph-large-medium ${
                        !watch('bill_payments.bill_payment_enabled') && 'text-neutral-300'
                    } mr-4`}>
                    Enable/disable convenient charge
                </h1>

                <div className='pt-1'>
                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={(!!watch('bill_payments.bill_payment_enabled') && field.value) ?? false}
                                canChange={!!watch('bill_payments.bill_payment_enabled')}
                                setIsEnabled={(value) =>
                                    !!watch('bill_payments.bill_payment_enabled') && toggleChange(value, field.onChange)
                                }
                                cursorType={!watch('bill_payments.bill_payment_enabled') && 'cursor-not-allowed'}
                            />
                        )}
                        name='bill_payments.enableConvenienceCharge'
                        control={control}
                    />
                </div>
            </div>

            {watch('bill_payments.enableConvenienceCharge') && (
                <React.Fragment>
                    <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                        <DropdownSection
                            menuItems={discountTypeMenuItems}
                            label='Convenience charge type'
                            shadow='shadow-smallDropDownShadow'
                            labelPaddingB='pb-2'
                            height='h-[52px]'
                            labelStyle='paragraph-large-medium'
                            name='bill_payments.convenienceChargeType'
                            placeholder='Please select convenience charge type'
                            rules={{ required: 'Please select convenience charge type' }}
                            handleSelection={handleSelectionType}
                        />
                    </div>

                    <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                        <InputSection
                            inputType='number'
                            placeholder={`Enter charge in ${
                                watch('bill_payments.convenienceChargeType')?.vallue === 'percentage_charge'
                                    ? 'percentage'
                                    : 'rupees'
                            }`}
                            boxHeight='[52px]'
                            name='bill_payments.bill_payment_convenience_charge'
                            rules={{ required: 'Please enter convenience charge' }}
                            label='Convenience charge'
                            labelStyle={`paragraph-large-medium ${
                                !watch('bill_payments.convenienceChargeType')?.value && 'text-neutral-300'
                            }`}
                            shadow='shadow-smallDropDownShadow'
                            disabled={!watch('bill_payments.convenienceChargeType')?.value}
                            enteringLimit={
                                watch('bill_payments.convenienceChargeType')?.value === 'percentage_charge' && 100
                            }
                            handleInputChange={() => setShowTabChangeWarningPopup(true)}
                        />

                        {errors?.bill_payments?.bill_payment_convenience_charge && (
                            <ErrorMessage errors={errors?.bill_payments?.bill_payment_convenience_charge} />
                        )}
                    </div>
                </React.Fragment>
            )}
        </>
    );
}
