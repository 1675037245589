import React from 'react';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';

export default function Header(props) {
    const { handleClickClose, applyFilter } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='paragraph-medium-medium flex flex-row mb-4 cursor-pointer'
                    onClick={() => handleClickClose()}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium'>Back to offers</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-start mb-2 pb-4'>
                    <div>
                        <span className='paragraph-large-medium'>Link outlets</span>
                        <div className='flex flex-row items-center'>
                            <span className='font-normal italic text-base leading-6 text-neutral-500'>
                                Enable the offer for the outlets
                            </span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={() => handleClickClose()}>
                        <CloseIcon />
                    </div>
                </div>
            )}

            <div className='max-w-[375px] w-full lg:max-w-[298px] md:max-w-full md:mb-4 mb-6'>
                <DefaultInputField
                    placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                    placeholder='Search outlet'
                    enteredValue={(value) => applyFilter(value)}
                />
            </div>
        </>
    );
}
