import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <TitleDescriptionLoader
                    className='max-w-[600px] md:max-w-full mb-2'
                    descHeight={50}
                />

                <SingleLoader className='max-w-[636px] h-[84px] mt-5' />

                <TitleDescriptionLoader
                    className='max-w-[600px] md:max-w-full mb-2 mt-6'
                    descHeight={50}
                />

                <TitleDescriptionLoader
                    className='max-w-[320px] md:max-w-full mb-2 mt-6'
                    descHeight={50}
                />
            </div>
        </>
    );
}
