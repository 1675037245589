import React, { useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as DownArrow } from '../../Assets/chevron-down.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SET_SELECTED_OUTLET_ID, SET_SELECTED_OUTLET } from '../../reduxStore/types/outlet';
import * as outletTypes from '../../reduxStore/types/outlet';
import APIV3 from '../../api/axios/APIV3';
import { useLocation } from 'react-router-dom';

const DropDownOutlet = (props) => {
    const { label } = props;

    const outletList = useSelector((state) => state.outlet.outletList);

    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const dispatch = useDispatch();

    const setOutlet = (outletDetail) => {
        dispatch({
            type: SET_SELECTED_OUTLET_ID,
            payload: {
                selectedOutletId: outletDetail.outletId,
            },
        });

        localStorage.setItem('selectedOutletId', outletDetail.outletId);
        dispatch({
            type: SET_SELECTED_OUTLET,
            payload: {
                selectedOutlet: outletDetail,
            },
        });
    };

    const fetchOrderingModes = async () => {
        try {
            const response = await APIV3.get(`restaurants/32/outlets/40/outlet-ordering-modes`);
            if (response.success) {
                dispatch({
                    type: outletTypes.SET_OUTLET_ORDERING_MODES,
                    payload: response.outlet_ordering_modes,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchOrderingModes();
    }, [selectedOutlet]);

    const { pathname } = useLocation();

    const menuItems = [
        ...(pathname === '/' ? [{ displayName: 'All', outletId: 0, outletData: 0 }] : []),
        ...outletList,
    ];

    return (
        <>
            <label className='paragraph-small-medium'>{label}</label>
            <div className='relative'>
                <Menu
                    as='div'
                    className='flex dropdown-icon'>
                    <Menu.Button className='flex items-center focus:outline-none remove-shadow'>
                        <span className='paragraph-x-small-italic text-neutral-500 mr-1 truncate block w-full xl:max-w-[200px] largeTablet:max-w-[165px]'>
                            {selectedOutlet?.displayName}
                        </span>
                        <DownArrow
                            className='drop-down-icon-rotate'
                            width='16'
                            height='16'
                        />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute w-max mt-6 px-4 py-2 border border-neutral-300 rounded-md shadow-medium bg-shades-50 focus:outline-none'>
                            {menuItems?.map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='py-1.5 cursor-pointer'
                                        onClick={() => {
                                            setOutlet(el);
                                        }}>
                                        <Menu.Item>
                                            <div className='paragraph-x-small-regular leading-normal'>
                                                {el.displayName}
                                            </div>
                                        </Menu.Item>
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
};

export default DropDownOutlet;
