import React from 'react';
import { FooterTab, Header, MobileComponents } from './Components/HomePage';
import Categorylayout from './Components/Categorylayout';
import { useFormContext } from 'react-hook-form';

export default function MobileScreenCategorylayout() {
    const { watch } = useFormContext();

    return (
        <div className='mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[661px] w-[319px] shadow-xl sticky top-0 mobile:max-w-[250px] mobile:w-full'>
            <MobileComponents />

            <div className='rounded-[2rem] overflow-hidden w-[291px] h-[633px] bg-white px-4 mobile:w-[222px] mobile:px-2'>
                <Header />

                <Categorylayout
                    isApplyMask={true}
                    layoutArray={watch('appearance.category_layout')?.map((el) => el.category_display_count)}
                />

                <FooterTab />
            </div>
        </div>
    );
}
