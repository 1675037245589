import React, { useState } from 'react';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as DominosLogo } from '../../../Assets/dominos-logo.svg';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';
import { useWindowSize } from '@uidotdev/usehooks';

export default function ListViewSelectRestaurant(props) {
    const {
        legal_name,
        handleRestaurantSelection,
        ordering_modes,
        setStatusPopupData,
        restaurant_id,
        id,
        is_open,
        state,
        city,
        address,
    } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDropDown = () => {
        setShowDetails(!showDetails);
    };

    const screenWidth = useWindowSize().width;

    const changeNameCase = (name) => {
        const pascalCase = name.replace(/_/g, '-');

        return pascalCase[0].toUpperCase() + pascalCase.slice(1);
    };

    return (
        <>
            <div className='relative'>
                <div className='border border-neutral-300 rounded-md px-4 py-3 relative h-full'>
                    <div className='flex flex-row justify-between items-center'>
                        <div className='flex flex-row items-center ml-[65px] mobile:ml-12'>
                            {screenWidth > 374 && (
                                <span>
                                    <DominosLogo
                                        height={40}
                                        width={40}
                                    />
                                </span>
                            )}
                            <span
                                className='paragraph-small-semi-bold mx-2'
                                onClick={handleRestaurantSelection}>
                                {legal_name}
                            </span>
                        </div>

                        <span>
                            <DropDownIcon
                                className={`${showDetails && 'rotate-180'} cursor-pointer`}
                                onClick={handleClickDropDown}
                            />
                        </span>
                    </div>

                    {showDetails && (
                        <>
                            <div className='flex flex-row items-center my-4'>
                                <div className='flex flex-row items-center w-1/2 md:mr-1.5'>
                                    <div className='paragraph-small-semi-bold text-neutral-400'>State:</div>
                                    <div className='ml-1 paragraph-small-regular'>{state}</div>
                                </div>

                                <div className='flex flex-row items-center w-1/2'>
                                    <div className='paragraph-small-semi-bold text-neutral-400'>City:</div>
                                    <div className='ml-1 paragraph-small-regular'>{city}</div>
                                </div>
                            </div>
                            <div className='flex flex-row items-start mb-0.5'>
                                <div className='paragraph-small-semi-bold text-neutral-400'>Address:</div>
                                <p className='paragraph-small-regular ml-1 md:min-h-[40px]'>{address}</p>
                            </div>

                            {ordering_modes.map((el, index) => {
                                let type = changeNameCase(el.type);
                                let isActive = el.is_available;
                                return (
                                    <div
                                        className='w-1/2 inline-block mt-3.5 cursor-default mobile:w-full'
                                        key={index}>
                                        <div className='flex flex-row'>
                                            <div className='paragraph-small-semi-bold text-neutral-400'>{type}:</div>
                                            <div
                                                className={`font-normal text-sm leading-5 ml-1 cursor-default ${
                                                    isActive ? 'text-success-800' : 'text-destructive-500'
                                                }`}
                                                onClick={() =>
                                                    setStatusPopupData({
                                                        showPopup: true,
                                                        data: {
                                                            restaurant_id,
                                                            outlet_id: id,
                                                            restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                                                            is_orderingmode_active: el.is_available,
                                                        },
                                                        statusOf: 'ordringMode',
                                                    })
                                                }>
                                                {isActive ? 'ACTIVE' : 'INACTIVE'}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>

                <div
                    className='absolute top-5 left-4 mobile:top-[13px]'
                    onClick={() =>
                        setStatusPopupData({
                            showPopup: true,
                            data: {
                                restaurant_id,
                                is_outlet_open: is_open,
                                outlet_id: id,
                            },
                            statusOf: 'outlet',
                        })
                    }>
                    <ToggleSwitch
                        cursorType='cursor-default'
                        isEnable={is_open}
                    />
                </div>
            </div>
        </>
    );
}
