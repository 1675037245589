import React from 'react';
import { ModeCustomerName } from '../../OrderTabPage/Components/TableComponent';
import { useWindowSize } from '@uidotdev/usehooks';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';

export default function TableSection(props) {
    const { orderDetails, setShowPopup } = props;

    const orderStatusStyle = (status) => {
        return status === 'Prepared'
            ? 'text-tertiary-800 bg-tertiary-50 border-tertiary-800'
            : status === 'Preparing'
              ? 'text-secondary-800 bg-secondary-50 border-secondary-800'
              : 'text-primary-500 bg-primary-50 border-primary-500';
    };

    const { width: screenWidth } = useWindowSize();

    return (
        <>
            <div className='w-full pr-5 xl:pr-0'>
                <div className='w-full border border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow h-11'>
                                <th className='text-left pl-6 min-w-[273px] pageContent:min-w-[220px]'>
                                    MODE & CUSTOMER NAME
                                </th>
                                <th className='text-left pl-6 min-w-[144px] pageContent:min-w-[130px]'>ORDER AGE</th>
                                <th className='text-left pl-6 min-w-[163px] pageContent:min-w-[155px]'>ORDER AMOUNT</th>
                                <th className='text-left pl-6 min-w-[153px] pageContent:min-w-[145px]'>ORDER STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails?.map((el, index) => {
                                const statusStyle = orderStatusStyle(el.orderStatus);

                                return (
                                    <tr
                                        key={index}
                                        className={`even:bg-neutral-50 ${
                                            index !== 0 && 'border-t'
                                        } border-neutral-300 paragraph-small-regular cursor-default hover:bg-primary-100 hover:border-primary-500 hover:border-y first:border-y first:bg-primary-100 first:border-primary-500 hover:last:border-b-0`}
                                        onClick={() => screenWidth < 1280 && setShowPopup('ordersDetails')}>
                                        <td className='h-[70px] pl-6'>
                                            <ModeCustomerName
                                                orderingMode={el.orderingMode}
                                                customerName={el.customerName}
                                            />
                                        </td>
                                        <td className='h-[70px] pl-6'>{el.orderAge}</td>
                                        <td className='h-[70px] pl-6'>{el.orderAmount}</td>
                                        <td className='h-[70px] pl-6'>
                                            <span
                                                className={`paragraph-small-medium px-2 py-1 border rounded ${statusStyle}`}>
                                                {el.orderStatus}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

                <div className='my-4'>
                    <PaginationWithNumber />
                </div>
            </div>
        </>
    );
}
