import APIV5 from '../../../api/axios/APIV5';

export const fetchCategoryList = async (selectedRestaurantId, selectedOutletId, params) => {
    try {
        const response = await APIV5(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/categories/stock`,
            { params }
        );
        return response.categories;
    } catch (error) {
        console.log('error <====>', error);
        throw error;
    }
};

export const fetchAddOnList = async (selectedRestaurantId, selectedOutletId, params) => {
    try {
        const response = await APIV5(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/addon-groups/stock`,
            { params }
        );
        return response.addon_groups;
    } catch (error) {
        console.log('error <====>', error);
        throw error;
    }
};
export const fetchCategoryItemsList = async (selectedRestaurantId, selectedOutletId, selectedCategoryId, params) => {
    try {
        const response = await APIV5(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/categories/${selectedCategoryId}/items/stock`,
            { params }
        );
        return response;
    } catch (error) {
        console.log('error <====>', error);
    }
};
export const fetchAddOnItemsList = async (selectedRestaurantId, selectedOutletId, selectedCategoryId, params) => {
    try {
        const response = await APIV5(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/addon-groups/${selectedCategoryId}/choices/stock`,
            { params }
        );
        return response;
    } catch (error) {
        console.log('error <====>', error);
    }
};

export const getFoodType = (category) => {
    if (!!category.vegetarian && !!category.non_vegetarian) {
        return null;
    }
    if (!!category.vegetarian) {
        return 'veg';
    }
    if (!!category.non_vegetarian) {
        return 'nonVeg';
    }
};
