import React, { useState } from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';

export default function FeedBack(props) {
    const { feedbacks } = props;

    const [showDetails, setShowDetails] = useState(false);
    const handleClickShowDetails = () => {
        setShowDetails(!showDetails);
    };

    const renderStars = () => {
        const totalStars = 5;
        const starRating = feedbacks?.riderFeedback?.delivery_star || 0;

        return Array.from({ length: totalStars }, (_, index) => {
            const isHighlighted = index < starRating;
            const fillColor = isHighlighted
                ? starRating >= 4
                    ? '#3D8C82'
                    : starRating >= 3
                      ? '#FFA704'
                      : '#EF4444'
                : '#E5E7EB';
            return (
                <React.Fragment key={index}>
                    <OrderRatingIcon
                        stroke={fillColor}
                        fill={starRating >= 4 ? '#EBF6F5' : starRating >= 3 ? '#FFF5E3' : '#FEF2F2'}
                    />
                    {index !== totalStars - 1 && <div style={{ width: '10px' }} />}
                </React.Fragment>
            );
        });
    };

    return (
        <>
            <div className='border-b border-neutral-300 pb-4 mb-4'>
                <div className='flex flex-row justify-between items-center'>
                    <h3 className='paragraph-medium-medium'>{'Rider feedback'}</h3>
                    <span
                        className='paragraph-medium-regular text-primary-500 cursor-pointer'
                        onClick={handleClickShowDetails}>
                        {showDetails ? 'Hide' : 'Show'}
                    </span>
                </div>

                {showDetails &&
                    (feedbacks?.riderFeedback?.delivery_star ? (
                        <div className='mt-3'>
                            <div>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Rating given on</h3>
                                <span className='font-normal text-base leading-6'>{feedbacks?.date}</span>
                            </div>

                            <div className='mt-3'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Rider rating</h3>
                                <div className='flex flex-row items-center'>
                                    {renderStars()}
                                    <div className='w-1 h-1 mx-3 bg-neutral-500' />
                                    <span className='font-normal text-base leading-6'>
                                        {feedbacks?.riderFeedback?.delivery_star}.0 star
                                    </span>
                                </div>
                            </div>
                            {feedbacks?.riderFeedback?.delivery_comment && (
                                <div className='mt-3'>
                                    <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Rider review</h3>
                                    <p className='font-normal text-base leading-6'>
                                        {feedbacks?.riderFeedback?.delivery_comment}
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='mt-3'>No Feedback Provided</div>
                    ))}
            </div>
        </>
    );
}
