import React from 'react';

export default function DeliveyEstimation() {
    return (
        <>
            <div className='mt-4'>
                <div className='flex flex-row items-center mb-1.5'>
                    <span className='paragraph-medium-medium text-neutral-500'>Estimated time: </span>
                    <span className='paragraph-medium-regular ml-2'>234.78</span>
                </div>

                <div className='flex flex-row items-center mb-1.5'>
                    <span className='paragraph-medium-medium text-neutral-500'>Estimated pick up time: </span>
                    <span className='paragraph-medium-regular ml-2'>02/09/2199 12:43:33</span>
                </div>

                <div className='flex flex-row items-center'>
                    <span className='paragraph-medium-medium text-neutral-500'>Estimated drop off time: </span>
                    <span className='paragraph-medium-regular ml-2'>02/09/2200 12:43:33</span>
                </div>
            </div>
        </>
    );
}
