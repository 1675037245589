import React, { useEffect } from 'react';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import DropdownSection from '../../Components/DropdownSection';
import InputSection from '../../Components/InputSection';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

const OrderingModes = (props) => {
    const { index, title, setShowFooter, setShowTabChangeWarningPopup } = props;

    const {
        watch,
        clearErrors,
        setValue,
        formState: { errors },
    } = useFormContext();

    const handleSelection = (clearErrorName) => {
        clearErrors(clearErrorName);
        setShowFooter(true);
        setShowTabChangeWarningPopup(true);
    };

    useEffect(() => {
        setValue(`other_charges.${index}.display_name`, title);

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <TitleDescription
                title={title}
                description='These are the outlet-wise charges for the customers displayed in the cart section.'
            />

            <div className='flex flex-row lg:block mt-4'>
                <div className='max-w-[312px] w-full relative mb-4 mr-1.5 lg:mb-2 md:max-w-full'>
                    <DropdownSection
                        name={`other_charges.${index}.enablePackaging`}
                        label='(Enable packaging charge)'
                        labelPaddingB='pb-2'
                        labelStyle={`paragraph-medium-italic text-neutral-500`}
                        shadow='shadow-smallDropDownShadow'
                        menuItems={preferenceMenuItem}
                        placeholder='Please select preference'
                        handleSelection={() => handleSelection(`other_charges.${index}.packgingCharge`)}
                        dontShowError
                        boxHeight='h-[52px]'
                        rules={{ required: 'Please fill this field' }}
                    />

                    {errors?.other_charges?.[index]?.enablePackaging && (
                        <ErrorMessage errors={errors?.other_charges?.[index]?.enablePackaging} />
                    )}
                </div>

                <div className='max-w-[312px] w-full relative mb-4 ml-1.5 lg:ml-0 md:max-w-full'>
                    <InputSection
                        name={`other_charges.${index}.packaging_charge`}
                        placeholder='Enter packaging charges in rupees'
                        label='(Packaging charge)'
                        shadow='shadow-smallDropDownShadow'
                        paddingLeft='pl-8'
                        prefix='₹'
                        inputType='number'
                        labelStyle={`paragraph-medium-italic ${!watch(`other_charges.${index}.enablePackaging`)?.value ? 'text-neutral-300' : 'text-neutral-500'}`}
                        prefixClass={`top-[17px] left-4 ${!watch(`other_charges.${index}.enablePackaging`)?.value ? 'text-neutral-300' : 'text-neutral-500'}`}
                        rules={{ required: 'Please fill this field' }}
                        disabled={!watch(`other_charges.${index}.enablePackaging`)?.value}
                        handleInputChange={() => {
                            setShowFooter(true);
                            setShowTabChangeWarningPopup(true);
                        }}
                    />

                    {errors?.other_charges?.[index]?.packaging_charge && (
                        <ErrorMessage errors={errors?.other_charges?.chanrges?.[index]?.packaging_charge} />
                    )}
                </div>
            </div>

            <div className='flex flex-row mb-2 lg:block'>
                <div className='max-w-[312px] w-full relative mb-4 mr-1.5 lg:mb-2 md:max-w-full'>
                    <DropdownSection
                        name={`other_charges.${index}.enableConvenienceCharge`}
                        label='(Enable convenience charge)'
                        labelPaddingB='pb-2'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        shadow='shadow-smallDropDownShadow'
                        menuItems={preferenceMenuItem}
                        placeholder='Please select preference'
                        handleSelection={() => handleSelection(`other_charges.${index}.enableConvenienceCharge`)}
                        dontShowError
                        boxHeight='h-[52px]'
                        rules={{ required: 'Please fill this field' }}
                    />

                    {errors?.other_charges?.[index]?.enableConvenienceCharge && (
                        <ErrorMessage errors={errors?.other_charges?.[index]?.enableConvenienceCharge} />
                    )}
                </div>

                <div className='max-w-[312px] w-full relative mb-4 ml-1.5 lg:mb-0 lg:ml-0 md:max-w-full'>
                    <InputSection
                        name={`other_charges.${index}.convenience_charge`}
                        placeholder='Enter convenience charges in rupees'
                        label='(Convenience charge)'
                        labelStyle={`paragraph-medium-italic ${!watch(`other_charges.${index}.enableConvenienceCharge`)?.value ? 'text-neutral-300' : 'text-neutral-500'}`}
                        shadow='shadow-smallDropDownShadow'
                        paddingLeft='pl-8'
                        inputType='number'
                        prefix='₹'
                        prefixClass={`top-[17px] left-4 ${!watch(`other_charges.${index}.enableConvenienceCharge`)?.value ? 'text-neutral-300' : 'text-neutral-500'}`}
                        rules={{ required: 'Please fill this field' }}
                        disabled={!watch(`other_charges.${index}.enableConvenienceCharge`)?.value}
                        handleInputChange={() => {
                            setShowFooter(true);
                            setShowTabChangeWarningPopup(true);
                        }}
                    />
                    {errors?.other_charges?.chanrges?.[index]?.convenience_charge && (
                        <ErrorMessage errors={errors?.other_charges?.chanrges?.[index]?.convenience_charge} />
                    )}
                </div>
            </div>
        </>
    );
};

export default OrderingModes;
