import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import Header from '../Components/Header';

export default function Layout(props) {
    const { showMainLayout, children, setPopupStatus, setIsEditMode, isEditMode, popupStatus, setShowTableContent } =
        props;

    const screenSize = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {showMainLayout && (
                    <div
                        className={`px-8 pb-6 relative lg:px-4 pt-4 w-full max-w-[1336px] mx-auto bg-white`}
                        style={{ minHeight: screenSize.height - 50 }}>
                        <div style={{ minHeight: screenSize.height - 140 }}>
                            <Header
                                setPopupStatus={setPopupStatus}
                                setIsEditMode={setIsEditMode}
                                isEditMode={isEditMode}
                                popupStatus={popupStatus}
                                setShowTableContent={setShowTableContent}
                            />

                            {children}
                        </div>

                        {/* {!isMobileScreen && <StatusDiscription />} */}
                    </div>
                )}
            </div>
        </>
    );
}
