import React from 'react';

import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { LargeDestructiveButton } from '../../../../../../Components/Buttons/Button';

export default function RiderDetailsPopup(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className={`bg-white w-[460px] rounded-xl px-8 py-6 m-auto overflow-auto [&::-webkit-scrollbar]:hidden md:px-0 md:py-0 md:w-full md:rounded-none`}>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to Orders</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-4 pb-2'>
                        <div className=''>
                            <div className='paragraph-large-medium md:paragraph-medium-medium'>Rider details</div>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Details of the assigned rider for this order
                            </span>
                        </div>
                        <span
                            className='cursor-pointer md:hidden'
                            onClick={handleClickClose}>
                            <CloseIcon
                                height={24}
                                width={24}
                            />
                        </span>
                    </div>

                    <div className='flex flex-row items-center mb-1.5 mt-4'>
                        <span className='paragraph-medium-medium text-neutral-500'>
                            Rider to Restaurant settlement:
                        </span>
                        <span className='paragraph-medium-regular ml-2'>&#8377; 234.78</span>
                    </div>

                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Status: </span>
                        <span className='paragraph-medium-regular ml-2'>Rider will accept order</span>
                    </div>

                    <div className='flex flex-row items-center mb-1.5'>
                        <span className='paragraph-medium-medium text-neutral-500'>Name: </span>
                        <span className='paragraph-medium-regular ml-2'>Bahuali Bhalaldev</span>
                    </div>

                    <div className='flex flex-row items-center mb-6'>
                        <span className='paragraph-medium-medium text-neutral-500'>Mobile number: </span>
                        <span className='paragraph-medium-regular ml-2'>+91 1234567890</span>
                    </div>

                    <div className=''>
                        <LargeDestructiveButton label='Cancel rider assignment' />
                    </div>
                </div>
            </div>
        </>
    );
}
