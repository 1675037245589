import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { LargeTertiaryButton } from '../../../../../../Components/Buttons/Button';
import { useFormContext } from 'react-hook-form';

export default function LogoSection(props) {
    const { setShowuploadImageKey, setShowSelectFromLibraryPopupKey } = props;

    const { watch } = useFormContext();

    return (
        <>
            <TitleDescription
                className='mb-2'
                title='Brand logos'
                description='Upload the brand logos for the application.'
            />

            <div className='max-w-[411px]'>
                <div className='mb-2 paragraph-medium-italic text-neutral-500'>
                    Brand logo(1920px x 1080px or 16:9 ratio)
                </div>

                {watch('brand_settings.brand_logo') ? (
                    <div className='flex flex-row items-center mb-4 md:block'>
                        <div className='border border-neutral-300 rounded-lg'>
                            <img
                                src={watch('brand_settings.brand_logo')?.image_url}
                                alt='brand logo'
                                className='p-2.5 max-w-[235px]'
                            />
                        </div>
                        <div
                            className='ml-2 min-w-[197px] md:min-w-0 md:flex md:ml-0 md:mt-3'
                            onClick={() => setShowuploadImageKey('brand_logo')}>
                            <LargeTertiaryButton label='Replace image' />
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-row md:flex-col justify-between mb-4 md:max-w-[197px]'>
                        <button
                            className='justify-center py-3 paragraph-medium-regular border-neutral-300 rounded-md border w-1/2 mr-2 mobile:text-sm md:mb-2 md:mr-0 md:w-full cursor-pointer'
                            onClick={() => setShowuploadImageKey('brand_logo')}>
                            Upload an image
                        </button>
                        <button
                            className='justify-center py-3 paragraph-medium-regular border-neutral-300 rounded-md border w-1/2 ml-2 md:ml-0 mobile:text-sm md:w-full cursor-pointer'
                            onClick={() => setShowSelectFromLibraryPopupKey('brand_logo')}>
                            Select from library
                        </button>
                    </div>
                )}

                <div className='mb-2'>
                    <span className='paragraph-medium-italic text-neutral-500'>
                        Footer logo(1080px x 1080px or 1:1 ratio)
                    </span>
                </div>

                {watch('brand_settings.footer_logo') ? (
                    <div className='flex flex-row items-center mb-4 md:block'>
                        <div className='border border-neutral-300 rounded-lg'>
                            <img
                                src={watch('brand_settings.footer_logo')?.image_url}
                                alt='footer Logo'
                                className='p-2.5 max-w-[235px]'
                            />
                        </div>
                        <div
                            className='ml-2 min-w-[197px] md:min-w-0 md:flex md:ml-0 md:mt-3'
                            onClick={() => setShowuploadImageKey('footer_logo')}>
                            <LargeTertiaryButton label='Replace image' />
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-row md:flex-col justify-between mb-4 md:max-w-[197px]'>
                        <button
                            className='justify-center py-3 paragraph-medium-regular border-neutral-300 rounded-md border w-1/2 mr-2 md:mr-0 mobile:text-sm md:mb-2 md:w-full'
                            onClick={() => setShowuploadImageKey('footer_logo')}>
                            Upload an image
                        </button>

                        <button
                            className='justify-center py-3 paragraph-medium-regular border-neutral-300 rounded-md border w-1/2 ml-2 md:ml-0 mobile:text-sm md:w-full'
                            onClick={() => setShowSelectFromLibraryPopupKey('footer_logo')}>
                            Select from library
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}
