import React, { useEffect, useState } from 'react';
import CategoryWithUseform from './Components/CategoryWithUseform';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCategoryDetails } from '../Components/Catalogue/functions';
import { advanceSettingMenuItem } from './constants';
import Loader from './Components/Loader/Loader';
import _ from 'lodash';

export default function MenuManagementAddCategory() {
    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const { editId } = useParams();

    const [defaultState, setDefaultState] = useState({});

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const formateAndSetCategoryDetail = async (category) => {
        let defaultState = {};

        defaultState.exposeDish = [];

        defaultState.exposeDish = category?.ordering_modes?.map((el) => {
            return { display_name: el.restaurant_ordering_mode_name, id: el.restaurant_ordering_mode_id };
        });

        const maxKeyTitle = Math.max(...Object.keys(category.translations.title).map(Number));

        const title = Array(maxKeyTitle + 1).fill(null);

        // Fill the title array with the category.translations.title
        for (const key in category.translations.title) {
            const index = Number(key);
            title[index] = category.translations.title[key];
        }

        const maxKeyDescription = Math.max(...Object.keys(category.translations.title).map(Number));

        const description = Array(maxKeyDescription + 1).fill(null);

        // Fill the title array with the category.translations.description
        for (const key in category.translations.description) {
            const index = Number(key);
            description[index] = category.translations.description[key];
        }

        defaultState.title = title;

        defaultState.description = description;

        defaultState.internalName = category.internal_name;

        defaultState.imageDetails = await formateImageDetails(category.image);

        defaultState.advanceSetting = formateAdvanceSetting(category);

        setDefaultState(defaultState);
    };

    const formateAdvanceSetting = (advanceSetting) => {
        let settings = {
            mask: {},
            hideCategory: {},
            tileDetails: {},
            displayTheme: {},
            tilePosition: {},
        };

        settings.tilePosition = advanceSettingMenuItem.tilePosition.find(
            (el) => el.value === advanceSetting.gradient_position
        );

        settings.mask = advanceSettingMenuItem.mask.find((el) => el.value === (advanceSetting.apply_mask ?? 0));

        settings.hideCategory = advanceSettingMenuItem.hideCategory.find((el) => el.value === advanceSetting.hidden);

        settings.displayTheme = advanceSettingMenuItem.displayTheme.find((el) => el.value === advanceSetting.theme);

        settings.tileDetails = advanceSettingMenuItem.tileDetails.find(
            (el) => el.value === advanceSetting.hide_category_tile_details
        );

        return settings;
    };

    const formateImageDetails = (imageDetails) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageDetails.image_url;

            img.onload = () => {
                const aspectRatio = img.naturalWidth / img.naturalHeight;
                const calculatedWidth = 150 * aspectRatio;
                const imageSize = { displaySize: { width: calculatedWidth, height: 150 } };
                const details = { ...imageSize, ...imageDetails };
                resolve(details);
            };

            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    const getAllCategoryDetails = async () => {
        const response = await getCategoryDetails(selectedRestaurantId, editId);

        formateAndSetCategoryDetail(response);
    };

    useEffect(() => {
        !!editId && getAllCategoryDetails();
    }, []);

    return (
        <>
            {!orderingMode?.length || (!!editId && _.isEmpty(defaultState)) ? (
                <Loader />
            ) : (
                <CategoryWithUseform defaultValues={defaultState} />
            )}
        </>
    );
}
