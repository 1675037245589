import React, { useState } from 'react';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as DineInIcons } from '../../../Assets/dine-in.svg';
import { DishItems } from './OrderCardComponent';

export default function OrderCard(props) {
    const { KOT, orderLabel, orderingMode, timeElapsed, items, icon } = props;

    const totalQuantity = items.reduce((acc, current) => acc + Number(current.quantity), 0);

    const [isUpdated, setIsUpdated] = useState(!props.isUpdated);

    const handleUpdateOrder = (value) => {
        setIsUpdated(value);
    };

    return (
        <>
            <div className='w-full border border-neutral-300 rounded-xl p-4'>
                <div className='flex flex-row justify-between items-center mb-4 pb-4 border-b border-neutral-300'>
                    <div className='flex flex-row'>
                        {orderingMode === 'Dine-In order' ? (
                            <>
                                <div className='p-2 bg-primary-500 rounded-lg'>
                                    <DineInIcons
                                        height={32}
                                        width={32}
                                        stroke='#FAFAFA'
                                    />
                                </div>
                            </>
                        ) : (
                            icon
                        )}
                        <div className=''>
                            <div className='ml-2.5 paragraph-medium-semi-bold'>{orderingMode}</div>
                            <div className='ml-2 paragraph-medium-italic text-neutral-500'>Order {orderLabel}</div>
                        </div>
                    </div>
                    <div className='paragraph-medium-semi-bold justify-end flex flex-col text-right'>
                        <div className=''>KOT</div>
                        <div className=''>{KOT < 10 ? '#0' + KOT : '#' + KOT}</div>
                    </div>
                </div>

                {!isUpdated && (
                    <div className='mb-4 pb-4 border-b border-neutral-300 text-center'>
                        <span className='paragraph-overline-large text-tertiary-800'>ORDERED DISHES UPDATED!</span>
                    </div>
                )}

                <div className='flex flex-row justify-between mb-4'>
                    <div className='text-base leading-4 border-b border-neutral-900'>Ordered dishes</div>
                    <div className='text-base leading-4 border-b border-neutral-900'>Quantity</div>
                </div>

                <div className='border-b border-neutral-300 mb-4'>
                    {items.map((el, index) => (
                        <div
                            className='mb-4'
                            key={index}>
                            <DishItems
                                el={el}
                                handleUpdateOrder={handleUpdateOrder}
                            />
                        </div>
                    ))}
                </div>

                <div className='flex flex-row justify-between paragraph-medium-semi-bold mb-4 pb-4 border-b border-neutral-300'>
                    <span>Total dishes quantity</span>
                    <span>{totalQuantity}</span>
                </div>

                <div className='flex flex-row justify-between paragraph-medium-semi-bold border-neutral-300 text-tertiary-800'>
                    <span>Time elapsed</span>
                    <div className='flex flex-row items-center'>
                        <TimerIcon
                            height={24}
                            width={24}
                            stroke='#3D8C82'
                        />
                        <span className='ml-2'>
                            {timeElapsed[0].mins} mins {timeElapsed[1].seconds} secs
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
