import React from 'react';

export default function RatingGuide() {
    return (
        <>
            <div className='md:fixed md:bottom-0 md:px-4 md:pt-2 md:pb-1 md:shadow-dropShadow md:left-0 md:right-0 md:w-full bg-white md:z-10'>
                <div className='flex flex-row border border-neutral-300 px-4 py-[11px] rounded-md md:justify-between'>
                    <span className='paragraph-medium-regular md:hidden'>Rating guide</span>

                    <div className='flex flex-row justify-center mx-10 md:mx-0'>
                        <div className='h-6 w-6 rounded border-2 bg-tertiary-100 border-tertiary-800 mr-1' />
                        <span className='paragraph-medium-regular text-[#000000]'>Good</span>
                    </div>

                    <div className='flex flex-row justify-center'>
                        <div className='h-6 w-6 rounded border-2 bg-secondary-100 border-secondary-700 mr-1' />
                        <span className='paragraph-medium-regular text-[#000000]'>Average</span>
                    </div>

                    <div className='flex flex-row justify-center ml-10 md:ml-0'>
                        <div className='h-6 w-6 rounded border-2 bg-destructive-50 border-destructive-500 mr-1' />
                        <span className='paragraph-medium-regular text-[#000000]'>Bad</span>
                    </div>
                </div>
            </div>
        </>
    );
}
