import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function Header(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium pl-1'>Back to offer</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mb-6'>
                    <div className='paragraph-large-medium'>Offer details</div>

                    <div
                        className='cursor-pointer'
                        onClick={handleClickClose}>
                        <CloseIcon />
                    </div>
                </div>
            )}
        </>
    );
}
