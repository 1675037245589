import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckBoxWithoutLabels } from '../../../../../../../Components/FormControl/FormControls';

export default function TableHeader(props) {
    const { id } = props;

    const { watch, setValue } = useFormContext();

    const allPrices = watch(`${id}.prices`) ?? {};

    const selectAll = (isSelected) => {
        Object.keys(allPrices)?.forEach((key) => {
            if (key !== 'pricing') {
                setValue(`${id}.prices.${key}.status`, isSelected);
            }
        });
    };

    const allStatusTrue = Object.keys(allPrices)
        ?.filter((key) => key !== 'pricing')
        ?.every((key) => allPrices[key].status === true);

    return (
        <>
            <thead>
                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                    <th className='px-6 min-w-[155px] paragraph-overline-small text-neutral-700 flex flow-row items-center pt-2.5'>
                        <span className='mr-2'>
                            <CheckBoxWithoutLabels
                                onChange={(isSelected) => selectAll(isSelected)}
                                isChecked={allStatusTrue}
                            />
                        </span>
                        STATUS
                    </th>

                    <th className='px-6 min-w-[174px] paragraph-overline-small text-neutral-700'>ORDERING MODE</th>
                    <th className='px-6 min-w-[174px] paragraph-overline-small text-neutral-700'>CURRENT PRICE</th>
                    <th className='px-6 min-w-[174px] paragraph-overline-small text-neutral-700'>COMPARE PRICE</th>
                    {/* <th className='px-6 min-w-[174px] paragraph-overline-small text-neutral-700'>PACKAGING PRICE</th> */}
                    {/* <th className='px-6 min-w-[80px] paragraph-overline-small text-neutral-700'>GST</th> */}
                    <th className='px-6 min-w-[130px] paragraph-overline-small text-neutral-700'>QUANTITY</th>
                    <th className='px-6 min-w-[170px] paragraph-overline-small text-neutral-700'>QUANTITY UNIT</th>
                    <th className='px-6 min-w-[110px] paragraph-overline-small text-neutral-700'>SERVES</th>
                    <th className='px-6 min-w-[160px] paragraph-overline-small text-neutral-700'>SERVING UNIT</th>
                    <th className='px-6 min-w-[130px] paragraph-overline-small text-neutral-700'>CALORIES</th>
                    <th className='px-6 min-w-[150px] paragraph-overline-small text-neutral-700'>ALLERGENS</th>
                </tr>
            </thead>
        </>
    );
}
