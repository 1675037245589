import React, { useState } from 'react';

import { ReactComponent as HoldIcon } from '../../Assets/hold.svg';
import { ReactComponent as DininIcon } from '../../Assets/dine-in.svg';
import { ReactComponent as TakeawayIcon } from '../../Assets/orders.svg';
import { ReactComponent as DeliveryIcon } from '../../Assets/riders.svg';

export default function HoldOrder() {
    const holdOrderDetails = [
        { mode: 'dine-in', customerName: 'Sarthak Kanchan' },
        { mode: 'takeaway', customerName: 'Bhrugen Joshi' },
        { mode: 'delivery', customerName: 'Ananya Panday' },
    ];

    const [showHoldOrder, setShowHoldOrder] = useState(false);

    const handleClickHold = () => {
        setShowHoldOrder(!showHoldOrder);
    };

    return (
        <>
            <div className='relative md:static'>
                <div
                    className={`border cursor-pointer ${
                        showHoldOrder ? 'border-primary-500 bg-primary-100' : 'border-neutral-300 bg-neutral-50'
                    } flex flex-row px-1.5 py-2 md:py-[3px] rounded-md max-w-[70px] cursor-pointer`}
                    onClick={handleClickHold}>
                    <HoldIcon
                        stroke={`${showHoldOrder ? '#6C5DD3' : '#706E7E'}`}
                        height={24}
                        width={24}
                    />
                    <span
                        className={`ml-1 paragraph-x-small-medium rounded-3xl ${
                            showHoldOrder ? 'bg-primary-500 text-neutral-50' : 'text-neutral-700 bg-neutral-200'
                        } px-2.5 py-0.5`}>
                        3
                    </span>
                </div>

                {showHoldOrder && (
                    <div className='pt-3 pb-6 px-[22px] border border-neutral-300 absolute right-0 top-12 bg-white rounded-lg min-w-[391px] sm:min-w-[365px] mobile:min-w-min mobile:w-full shadow-large md:mr-[5px] mobile:mr-0 md:px-4 mobile:px-2 mobile:pt-2 mobile:pb-4 z-50'>
                        <span className='paragraph-overline-small'>HOLD ORDERS</span>
                        {holdOrderDetails.map((el, index) => (
                            <div
                                className='flex flex-row items-center w-full px-4 py-3 mobile:px-2 border border-neutral-300 rounded-md justify-between mt-2'
                                key={index}>
                                <div className='flex flex-row items-center'>
                                    <div
                                        className={`flex ${
                                            el.mode === 'takeaway'
                                                ? 'bg-secondary-700'
                                                : el.mode === 'dine-in'
                                                  ? 'bg-primary-500'
                                                  : 'bg-tertiary-800'
                                        } rounded-lg w-10 h-10 mobile:w-[34px] mobile:h-[34px] mr-1`}>
                                        {el.mode === 'takeaway' ? (
                                            <div className='w-6 mobile:w-5 m-auto'>
                                                <TakeawayIcon
                                                    stroke='#FFFFFF'
                                                    className='w-full h-auto'
                                                />
                                            </div>
                                        ) : el.mode === 'dine-in' ? (
                                            <div className='w-6 mobile:w-5 m-auto'>
                                                <DininIcon
                                                    stroke='#FFFFFF'
                                                    className='w-full h-auto'
                                                />
                                            </div>
                                        ) : (
                                            <div className='w-6 mobile:w-5 m-auto'>
                                                <DeliveryIcon
                                                    stroke='#FFFFFF'
                                                    className='w-full h-auto'
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className=''>
                                        <div className='paragraph-overline-small text-neutral-700 mb-1 text-xs'>
                                            MODE & CUSTOMER NAME:
                                        </div>
                                        <div className='paragraph-small-regular text-xs'>{el.customerName}</div>
                                    </div>
                                </div>
                                <span className='paragraph-small-medium text-neutral-500 px-2 py-1 mobile:px-1.5 mobile:py-0.5 mobile:text-xs border border-neutral-500 bg-primary-50 rounded'>
                                    Hold
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
