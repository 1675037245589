import React from 'react';
import Card from './Card';
import SliderDashboard from 'react-slick';
import { ReactComponent as CreditsIcon } from '../../../Assets/credits.svg';
import { ReactComponent as NetSalesIcon } from '../../../Assets/net-sales.svg';
import { ReactComponent as NoOfOrderIcon } from '../../../Assets/no-of-orders.svg';
import { ReactComponent as OnlineOrdersIcon } from '../../../Assets/online-orders.svg';
import { ReactComponent as DiscountIcon } from '../../../Assets/discount.svg';
import { ReactComponent as TaxesIcon } from '../../../Assets/taxes.svg';
import { ReactComponent as CashCollectionIcon } from '../../../Assets/cash-collection.svg';
import { CardDescription } from './CardComponent';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';

export default function OverviewCards(props) {
    const { settingsDashboardSlider, numberOfOutlet, discountAmount, setShowPopupDetails, isRestaurantSelected } =
        props;

    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const displayOutlet = isRestaurantSelected ? numberOfOutlet : selectedOutlet?.displayName;

    const cardContent = [
        {
            title: 'Total sales',
            number: '₹4,50,000.00',
            percentage: '10.2',
            positive: true,
            description: (
                <CardDescription
                    focusContent='₹10,500.00'
                    description='You made an extra total sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total sales of ${displayOutlet} outlets`,
            icon: (
                <CreditsIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Total sales',
                description: 'Total sales defines the overall collection restaurant made',
                popup: 'totalSales',
            },
        },
        {
            title: 'Net sales',
            number: '₹3,67,680.50',
            percentage: '8.2',
            description: (
                <CardDescription
                    focusContent='₹9,500.00'
                    description='You made an extra net sales of :focusContent: this week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Net sales of ${displayOutlet} outlets`,
            positive: true,
            icon: (
                <NetSalesIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Net sales',
                description: 'Net sales defines the overall collection restaurant made',
                popup: 'netSales',
            },
        },
        {
            title: 'No. of orders',
            number: '354',
            percentage: '5.2',
            description: (
                <CardDescription
                    focusContent='125'
                    description='You made :focusContent: orders less than last week'
                    focusContentColor='text-destructive-600'
                />
            ),
            information: 'No. of invoices generated',
            positive: false,
            icon: (
                <NoOfOrderIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Total number of orders',
                description: 'Total number of orders for all ordering modes',
                popup: 'noOfOrders',
            },
        },

        {
            title: 'Cash collection',
            number: '₹1,25,000.00',
            percentage: '10.2',
            description: (
                <CardDescription
                    focusContent='22.42%'
                    description='You made :focusContent: of offline sales collected via cash'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Cash collection of ${displayOutlet} outlets`,
            positive: true,
            icon: (
                <CashCollectionIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            ...(isRestaurantSelected
                ? {
                      popupDetails: {
                          title: 'Taxes details',
                          description: 'Taxes details for different outlets',
                          popup: 'cashCollection',
                      },
                  }
                : {}),
        },
        {
            title: 'Online sales',
            number: '₹3,67,680.50',
            percentage: '8.2',
            description: (
                <CardDescription
                    focusContent='77.58%'
                    description='You made :focusContent: of sales generated from online'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Online sale of ${displayOutlet} outlets`,
            positive: true,
            icon: (
                <OnlineOrdersIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Online sales',
                description: 'Online sales defines the overall online sale for ordering modes',
                popup: 'onlineSales',
            },
        },
        {
            title: 'Taxes',
            number: '₹1,256.52',
            description: (
                <CardDescription
                    focusContent='₹1,256.52'
                    description='You paid :focusContent: taxes recorded on POS'
                    focusContentColor='text-primary-500'
                />
            ),
            information: `Taxes record of ${displayOutlet} outlets`,
            positive: true,
            icon: (
                <TaxesIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Taxes details',
                description: 'Taxes details for different ordering modes',
                popup: 'taxes',
            },
        },
        {
            title: 'Discounts',
            number: '₹3,680.50',
            description: (
                <CardDescription
                    focusContent={discountAmount}
                    description='You gave a discount of :focusContent: amount'
                    focusContentColor='text-primary-500'
                />
            ),
            information: `${discountAmount} of my amount`,
            positive: true,
            icon: (
                <DiscountIcon
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            popupDetails: {
                title: 'Discounts details',
                description: 'Discounts details for different ordering modes',
                popup: 'discount',
            },
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='px-8 lg:px-4 md:px-0'>
                <div className='paragraph-large-medium text-black md:px-4 pb-2 md:pb-2'>Overview</div>
                {!isMobileScreen ? (
                    <div className='flex flex-wrap -mx-2.5'>
                        {cardContent.map((el, index) => (
                            <div
                                className='inline-block mx-2.5 my-2 max-w-[303px] w-full lg:w-1/2 lg:max-w-full lg:mx-0 lg:even:pl-1 lg:odd:pr-1 lg:my-1'
                                key={index}>
                                <Card
                                    {...el}
                                    showDetailsPopup={() => {
                                        el.popupDetails && setShowPopupDetails(el.popupDetails);
                                    }}
                                    showAbout={!!el.popupDetails}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div>
                        <div>
                            <SliderDashboard {...settingsDashboardSlider}>
                                {cardContent.slice(0, 4).map((el, index) => (
                                    <div
                                        className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                        key={index}>
                                        <Card
                                            {...el}
                                            showDetailsPopup={() => {
                                                el.popupDetails && setShowPopupDetails(el.popupDetails);
                                            }}
                                            showAbout={!!el.popupDetails}
                                        />
                                    </div>
                                ))}
                            </SliderDashboard>
                        </div>

                        <div className='mt-1.5 md:mt-0'>
                            <SliderDashboard {...settingsDashboardSlider}>
                                {cardContent.slice(4, 7).map((el, index) => (
                                    <div
                                        className='inline-block mx-2.5 my-2 md:mx-0 md:my-0 pb-1.5'
                                        key={index}>
                                        <Card
                                            {...el}
                                            showDetailsPopup={() => {
                                                el.popupDetails && setShowPopupDetails(el.popupDetails);
                                            }}
                                            showAbout={!!el.popupDetails}
                                        />
                                    </div>
                                ))}
                            </SliderDashboard>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
