import React from 'react';
import TotalSales from './Components/TotalSales';
import NetSales from './Components/NetSales';
import NoOfOrders from './Components/NoOfOrders';
import CashCollection from './Components/CashCollection';
import OnlineSales from './Components/OnlineSales';
import Taxes from './Components/Taxes';
import Discount from './Components/Discount';
import Layout from './Components/Layout';

export default function OrderingModeWiseDetailsPopup(props) {
    const { showPopupDetails, handleClickClose } = props;

    const componentsToMap = {
        totalSales: TotalSales,
        netSales: NetSales,
        noOfOrders: NoOfOrders,
        cashCollection: CashCollection,
        onlineSales: OnlineSales,
        taxes: Taxes,
        discount: Discount,
    };

    const ComponentToRender = componentsToMap[showPopupDetails.popup];

    return (
        <>
            <Layout
                handleClickClose={handleClickClose}
                {...showPopupDetails}>
                <ComponentToRender {...showPopupDetails} />
            </Layout>
        </>
    );
}
