import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function DishPricing(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <>
            <div
                className={`p-4 border border-neutral-300 rounded-lg w-full ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className={`w-full justify-between flex ${showDetails && 'pb-4'}`}>
                    <span className='paragraph-medium-medium'>Dish pricing</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <>
                        {dishDetails?.variants?.length ? (
                            <>
                                {dishDetails?.variants?.map((el, index) => (
                                    <div
                                        className=''
                                        key={index}>
                                        {el?.options?.map((optionEl, optionI) => (
                                            <div
                                                className=''
                                                key={optionI}>
                                                <div className='mr-2 mb-2'>
                                                    <span className='paragraph-small-medium text-neutral-500 '>
                                                        {optionEl.translations.title[selectedLanguage]}
                                                    </span>

                                                    <div className=''>
                                                        {optionEl.ordering_modes.map((modeEl, modeI) => (
                                                            <div
                                                                className='flex flex-row mr-4'
                                                                key={modeI}>
                                                                <span className='paragraph-small-regular text-neutral-500 mr-2'>
                                                                    {modeEl.restaurant_ordering_mode_name}:
                                                                </span>

                                                                <span className='flex flex-wrap gap-2 paragraph-small-regular'>
                                                                    &#8377; {modeEl.price}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                {dishDetails?.ordering_modes?.map((el, index) => (
                                    <div
                                        className='flex flex-row mr-4'
                                        key={index}>
                                        <span className='paragraph-small-regular text-neutral-500 mr-2'>
                                            {el.restaurant_ordering_mode_name}:
                                        </span>

                                        <span className='flex flex-wrap gap-2 paragraph-small-regular'>
                                            &#8377; {el.price}
                                        </span>
                                    </div>
                                ))}
                            </>
                        )}
                        {/* <div className='text-neutral-500 mr-2 paragraph-small-semi-bold pb-1'>Price</div>

                        <div className='flex flex-row mr-4'>
                            <span className='paragraph-small-regular text-neutral-500 mr-2'>Dine in:</span>

                            <span className='flex flex-wrap gap-2'>&#8377; 199</span>
                        </div>

                        <div className='flex flex-row mr-4'>
                            <span className='paragraph-small-regular text-neutral-500 mr-2'>Pick up:</span>

                            <span className='flex flex-wrap gap-2 pb-4'>&#8377; 199</span>
                        </div>

                        <div className='mr-2 paragraph-small-semi-bold pb-1'>Price</div>

                        <div className='paragraph-small-medium text-neutral-500 mr-2 mb-0.5'>Small</div>

                        <div className='flex flex-row mr-4'>
                            <span className='paragraph-small-regular text-neutral-500 mr-2'>Pick up:</span>

                            <span className='flex flex-wrap gap-2'>&#8377; 199</span>
                        </div>
                        <div className='flex flex-row mr-4'>
                            <span className='paragraph-small-regular text-neutral-500 mr-2'>Dine in:</span>

                            <span className='flex flex-wrap gap-2 pb-4'>&#8377; 199</span>
                        </div> */}
                    </>
                )}
            </div>
        </>
    );
}
