import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from '../../../../Assets/search.svg';
import LinkOutletTable from './LinkOutletTable';
import LinkOutletHeader from './LinkOutletHeader';
import APIV2 from '../../../../api/axios/APIV2';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import LinkFilterComponent from './LinkFilterComponent';

export default function LinkOutletPopup(props) {
    const { handleClickClose, linkBannerData, fetchCatalogueList, fetchStoreBannerList } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const outletList = useSelector((state) => state.outlet.outletList);

    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);

    const [linkUnlinkOutletList, setLinkUnlinkOutletList] = useState({ linkedOutlets: [], unLinkedOutlets: [] });

    const [appliedFilter, setAppliedFilter] = useState({ search: null, state: null, city: null });

    const [filteredList, setFilteredList] = useState(outletList);

    const linkOutlet = async () => {
        try {
            const response = await APIV2.patch(
                `restaurants/${selectedRestaurantId}/banners/${linkBannerData.id}/link`,
                { outlet_ids: linkUnlinkOutletList.linkedOutlets }
            );

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const unlinkOutlet = async () => {
        try {
            const response = await APIV2.patch(
                `restaurants/${selectedRestaurantId}/banners/${linkBannerData.id}/un-link`,
                {
                    outlet_ids: linkUnlinkOutletList.unLinkedOutlets,
                }
            );

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSave = async () => {
        const isLinkingSuccess = linkUnlinkOutletList.linkedOutlets.length ? await linkOutlet() : true;

        const isUnlinkingSuccess = linkUnlinkOutletList.unLinkedOutlets.length ? await unlinkOutlet() : true;

        if (isLinkingSuccess && isUnlinkingSuccess) {
            fetchCatalogueList();

            fetchStoreBannerList();

            handleClickClose();
        }
    };

    useEffect(() => {
        setLinkUnlinkOutletList((prevState) => ({
            ...prevState,
            linkedOutlets: linkBannerData.outlet.map((item) => item.outlet_id),
        }));

        if (!(appliedFilter.search || appliedFilter.state || appliedFilter.city)) {
            setFilteredList(outletList);
            return;
        }

        let searchedList = outletList;

        if (appliedFilter.search) {
            searchedList = searchedList.filter((outlet) =>
                outlet.displayName.toLowerCase().includes(appliedFilter.search.toLowerCase())
            );
        }

        if (appliedFilter.state) {
            searchedList = searchedList.filter((el) =>
                appliedFilter.state.includes(
                    el?.outletData?.outlet_address.translations.state[selectedLangaugeId].toLowerCase()
                )
            );
        }

        if (appliedFilter.city) {
            searchedList = searchedList.filter((el) =>
                appliedFilter.city.includes(
                    el?.outletData?.outlet_address.translations.city[selectedLangaugeId].toLowerCase()
                )
            );
        }

        setFilteredList(searchedList);
        // eslint-disable-next-line
    }, [appliedFilter, outletList]);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4 md:py-0 md:pb-16'>
                <div className='max-w-[997px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:py-4 m-auto md:max-w-full lg:max-w-[710px] md:mt-0 md:px-0'>
                    <LinkOutletHeader handleClickClose={handleClickClose} />

                    <div className='flex flex-row justify-between mb-6 md:block md:mb-4 items-center'>
                        <div className='max-w-[375px] w-full lg:max-w-[298px] md:max-w-full'>
                            <DefaultInputField
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                placeholder='Search outlet'
                                enteredValue={(value) =>
                                    setAppliedFilter((prevState) => {
                                        return { ...prevState, search: value };
                                    })
                                }
                            />
                        </div>

                        <LinkFilterComponent
                            setAppliedFilter={setAppliedFilter}
                            appliedFilter={appliedFilter}
                            filteredList={filteredList}
                        />
                    </div>

                    <LinkOutletTable
                        linkBannerData={linkBannerData}
                        setLinkUnlinkOutletList={setLinkUnlinkOutletList}
                        list={filteredList}
                        linkUnlinkOutletList={linkUnlinkOutletList}
                    />

                    <div className='flex justify-end mt-12 md:fixed md:bottom-0 md:justify-center md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:left-0 md:px-4'>
                        <div className='flex flex-row'>
                            <div
                                className='mr-2 md:w-full'
                                onClick={handleClickClose}>
                                <LargeDestructiveButton
                                    label='Discard changes'
                                    fontsSize='sm:text-[15px]'
                                />
                            </div>
                            <div
                                className='ml-2 md:w-full'
                                onClick={handleClickSave}>
                                <LargePrimaryButton
                                    label='Save changes'
                                    fontsSize='sm:text-[15px]'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
