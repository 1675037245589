import React, { useEffect } from 'react';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../../../../../../Components/FormControl/FormControls';
import { useFormContext } from 'react-hook-form';
import { BlankComponentTable } from '../../../../../../Components/BlankComponent/BlankComponent';

export default function Table(props) {
    const { isEditTable, setShowThresholdPopup, isSelectionEnable, handleStatusChange, selectedMode } = props;

    const { watch, setValue } = useFormContext();

    useEffect(() => {
        setValue('delivery_charge.delivery_charges', watch('delivery_charge.delivery_charge_thresholds'));
    }, [watch('delivery_charge.delivery_charge_thresholds')]);

    return (
        <>
            <div className='max-w-[566px] mb-6 lg:max-w-[464px] md:max-w-full'>
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='pl-6 min-w-[201px] paragraph-overline-small text-neutral-700'>
                                    DELIVERY THRESHOLD
                                </th>
                                <th className='pl-6 min-w-[181px] paragraph-overline-small text-neutral-700'>
                                    ABOVE THRESHOLD
                                </th>
                            </tr>
                        </thead>

                        {!watch('delivery_charge.delivery_charges')?.length ? (
                            <BlankComponentTable
                                previewText='No available threshold'
                                colSpan={2}
                            />
                        ) : (
                            <tbody>
                                {watch('delivery_charge.delivery_charges')?.map((el, index) => (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td
                                            className={`px-6 ${isEditTable && 'cursor-pointer'}`}
                                            onClick={() =>
                                                isEditTable && setShowThresholdPopup({ type: 'edit', index })
                                            }>
                                            <div className='flex flex-row items-center'>
                                                {isEditTable && (
                                                    <div className='mr-4'>
                                                        <EditIcon />
                                                    </div>
                                                )}

                                                {isSelectionEnable && (
                                                    <span className='mr-2 -ml-2'>
                                                        <CheckBoxWithoutLabels
                                                            onChange={(value) => handleStatusChange(index, value)}
                                                            id={el.title}
                                                            isChecked={selectedMode.includes(index)}
                                                        />
                                                    </span>
                                                )}

                                                <span>{el.threshold}</span>
                                            </div>
                                        </td>
                                        <td className='px-6'>{el.above_threshold}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
}
