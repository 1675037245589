import React, { useEffect, useState } from 'react';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { format } from 'date-fns';
import { DatePicker } from 'rsuite';
import '../Styles/datePicker.css';
import DropdownSection from '../../../Components/DropDown/DropdownSection';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function ScheduleNotification(props) {
    const { disabled, label, openFromBottom, menuItems, timeTypeName, selectedTimeName, selectedDateName } = props;

    const {
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    useEffect(() => {
        if (!disabled) {
            return;
        }

        setValue(selectedTimeName, null);
        setValue(selectedDateName, null);
        setValue(timeTypeName, null);

        // eslint-disable-next-line
    }, [disabled]);

    useEffect(() => {
        if (watch(timeTypeName)?.value === '0') {
            clearErrors(selectedTimeName);
        }
    }, [watch(timeTypeName)?.value, clearErrors, timeTypeName]);

    const disabledDate = (date) => {
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        return date < currentDate;
    };

    const formatDateAndAddHours = (input, hoursToAdd) => {
        const inputDate = new Date(input);
        inputDate.setHours(inputDate.getHours() + hoursToAdd);

        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const hours = String(inputDate.getHours()).padStart(2, '0');
        const minutes = String(inputDate.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const getPlaceholder = formatDateAndAddHours(new Date(), 0);

    const handleSelection = (dateStr) => {
        const date = new Date(dateStr);

        const formattedDate = format(date, 'yyyy-MM-dd');

        const formattedTime = format(date, 'HH:mm');

        setValue(selectedDateName, formattedDate);
        setValue(selectedTimeName, formattedTime);
    };

    const ranges = [
        {
            label: 'Now',
            value: new Date(),
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
            <div className='flex flex-row items-start mt-1 md:flex-col md:gap-3'>
                <div className='w-1/2 mr-1 relative md:w-full md:mr-0'>
                    <DropdownSection
                        name={timeTypeName}
                        rules={{ required: 'Please select Date' }}
                        icon={<CalenderIcon stroke={disabled ? '#D3D2D8' : '#131126'} />}
                        openFromBottom={openFromBottom}
                        placeholder='Please select time'
                        menuItems={menuItems}
                    />

                    {errors?.[timeTypeName] && <ErrorMessage errors={errors[timeTypeName]} />}
                </div>

                {watch(timeTypeName)?.value === menuItems[1]?.value && (
                    <DatePicker
                        shouldDisableDate={disabledDate}
                        onSelect={(value) => handleSelection(value)}
                        placeholder={getPlaceholder}
                        format='dd MMM yyyy hh:mm aa'
                        showMeridian
                        ranges={ranges}
                        style={{ width: isMobileScreen ? '100%' : 260 }}
                    />
                )}
            </div>
        </>
    );
}
