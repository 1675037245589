import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../Components/InputSection';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function MessageSection(props) {
    const { setMobileScreenPage } = props;

    const {
        watch,
        formState: { errors },
    } = useFormContext();

    const availableLangauge = useSelector((state) => state.langauge.availableLanguages);

    return (
        <>
            <div className='mb-2'>
                <TitleDescription
                    title='Greeting message'
                    description='Write up a quirky greeting message that will be displayed above categories on the home screen of the app.'
                />
            </div>

            <div className='flex flex-row items-center mb-4 lg:block gap-3'>
                {availableLangauge.map((el, index) => (
                    <div
                        className='w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0 relative'
                        key={index}>
                        <InputSection
                            onFocus={() => setMobileScreenPage('home')}
                            inputValue={watch(`brand_settings.translations.like_to_order.${el.language_id}`)}
                            label={`(${el.language_label})`}
                            name={`brand_settings.translations.like_to_order.${el.language_id}`}
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder={`Enter greeting message in ${el.language_label}`}
                            shadow='shadow-smallDropDownShadow'
                            rules={{ required: 'Please enter greeting message' }}
                        />
                        {errors?.brand_settings?.translations?.like_to_order?.[el.language_id] && (
                            <ErrorMessage
                                className='absolute -bottom-5'
                                errors={errors?.brand_settings?.translations?.like_to_order?.[el.language_id]}
                            />
                        )}
                    </div>
                ))}
            </div>

            <div className='mb-2'>
                <TitleDescription
                    title='Special instruction message'
                    description='This message will be displayed on cart screen for special instruction box.'
                />
            </div>

            <div className='flex flex-row items-center mb-7 lg:block gap-3'>
                {availableLangauge.map((el, index) => (
                    <div
                        className='w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0 relative'
                        key={index}>
                        <InputSection
                            onFocus={() => setMobileScreenPage('cart')}
                            label={`(${el.language_label})`}
                            name={`brand_settings.translations.special_instruction_label.${el.language_id}`}
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder={`Enter instruction message in ${el.language_label}`}
                            shadow='shadow-smallDropDownShadow'
                            rules={{ required: 'Please enter instruction message' }}
                        />
                        {errors?.brand_settings?.translations?.special_instruction_label?.[el.language_id] && (
                            <ErrorMessage
                                className='absolute -bottom-5'
                                errors={
                                    errors?.brand_settings?.translations?.special_instruction_label?.[el.language_id]
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
}
