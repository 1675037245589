import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';
import { SingleLoader } from '../../../../../Components/ContentLoader/DropdownLoader';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import ContentLoader from 'react-content-loader';

export default function Loader() {
    const { height: screenHeight } = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div className='bg-white p-4'>
                    <SingleLoader className='max-w-[200px] h-[24px]' />
                    <SingleLoader className='max-w-full h-12 mt-4' />
                    <SingleLoader className='max-w-[60vw] h-8 mt-4' />
                    <SingleLoader className='max-w-full h-12 mt-4' />
                </div>
            ) : (
                <div className='bg-[#fafafa]'>
                    <div
                        className={`px-8 lg:px-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full`}
                        style={{ height: screenHeight - 50 }}>
                        <div className='flex flex-row'>
                            <div
                                className='mr-4 border-r border-neutral-300 pr-4 max-w-[197px] w-full'
                                style={{ height: screenHeight - 50 }}>
                                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                            </div>

                            <div className='flex flex-row max-w-full w-full'>
                                <div className='max-w-[675px] mr-8 border-r border-neutral-300 pr-8 w-full pl-4'>
                                    <SingleLoader className='max-w-[178px] h-7 mt-4' />

                                    <SingleLoader className='max-w-[666px] h-[48px] mt-4' />

                                    <div className='flex flex-row items-center gap-4 mt-6'>
                                        <SingleLoader className='max-w-[200px] h-[48px]' />
                                        <SingleLoader className='max-w-[200px] h-[48px]' />
                                    </div>

                                    <SingleLoader className='max-w-[180px] h-7 mt-8' />
                                    <SingleLoader className='max-w-[400px] h-7 mt-4' />

                                    <SingleLoader className='max-w-[180px] h-7 mt-8' />
                                    <SingleLoader className='max-w-[666px] h-[48px] mt-4' />

                                    <div className='flex flex-row items-center gap-4 mt-4'>
                                        <SingleLoader className='h-[48px]' />
                                        <SingleLoader className='h-[48px]' />
                                    </div>

                                    <SingleLoader className='max-w-[180px] h-7 mt-8' />
                                    <SingleLoader className='max-w-[666px] h-[48px] mt-4' />

                                    <div className='flex flex-row items-center gap-4 mt-4'>
                                        <SingleLoader className='h-[112px]' />
                                        <SingleLoader className='h-[112px]' />
                                    </div>
                                </div>

                                <ContentLoader
                                    width='100%'
                                    height='100%'
                                    className={`h-[666px] w-full max-w-[319px] mt-4`}>
                                    <rect
                                        x='0'
                                        y={0}
                                        rx='48'
                                        ry='48'
                                        width='319'
                                        height={666}
                                    />
                                </ContentLoader>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
