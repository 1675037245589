import React from 'react';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import AllDaySameTimeSelection from '../../../../Components/Timings/Components/AllDaySameTimeSelection';
import EachDaySeparateTimeSelection from '../../../../Components/Timings/Components/EachDaySeparateTimeSelection';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function ModeTimingSection(props) {
    const { title, id, description } = props;

    const {
        control,
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const menuItemTiming = [
        { label: 'Full time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const handleTimeSettingSelection = (selection) => {
        switch (selection.value) {
            case menuItemTiming[1].value:
                setValue(`${id}`, [{ start_time: '', end_time: '' }]);
                break;

            case menuItemTiming[2].value:
                for (let index = 0; index < 7; index++) {
                    setValue(`${id}_timing.${index}`, []);
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <TitleDescription
                description={description}
                title={title}
                className='mb-1'
            />

            <span className='paragraph-medium-italic text-primary-500'>Each day can have only 6 time slots.</span>

            <div className='mb-4 mt-4'>
                <DropdownSection
                    name={`${id}_timingType`}
                    menuItems={menuItemTiming}
                    shadow='shadow-smallDropDownShadow'
                    height='h-[52px]'
                    placeholder='Please select timing type'
                    handleSelection={(selection) => handleTimeSettingSelection(selection)}
                    rules={{ required: 'Please select timing type' }}
                    dontShowError
                />

                {errors?.[`${id}_timingType`] && <ErrorMessage errors={errors[`${id}_timingType`]} />}
            </div>

            <div className='max-w-[450px] md:max-w-full'>
                {watch(`${id}_timingType`)?.value === menuItemTiming[1].value && (
                    <AllDaySameTimeSelection
                        fieldName={`${id}`}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        timingTypeName={`${id}_timingType`}
                        subField={id}
                        limitedSlot={6}
                    />
                )}
            </div>

            <div className='max-w-[450px]'>
                {watch(`${id}_timingType`)?.value === menuItemTiming[2].value && (
                    <EachDaySeparateTimeSelection
                        fieldName={`${id}`}
                        setValue={setValue}
                        control={control}
                        watch={watch}
                        clearErrors={clearErrors}
                        errors={errors}
                        timingTypeName={`${id}_timingType`}
                        subField={id}
                        limitedSlot={6}
                    />
                )}
            </div>
        </>
    );
}
