import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import InputAreaSection from '../../../../../Components/InputField/InputAreaSection';

export default function TitleSection() {
    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <TitleDescription
                title='Offer description'
                description='Please enter the description of the offer your customers will see within the application.'
            />

            <div className='flex flex-row w-full mt-4 mb-6 lg:block gap-3'>
                {availableLanguages.map((el, index) => (
                    <div
                        className='max-w-[311px] w-full lg:mb-3 md:max-w-full'
                        key={index}>
                        <InputAreaSection
                            name={`translations.description.${el.language_id}`}
                            boxHeight='h-[120px]'
                            label={`(${el.language_label})`}
                            labelStyle='paragraph-medium-italic'
                            placeholder={`Enter description in ${el.language_label}`}
                            rules={{ required: `Please enter description in ${el.language_label}` }}
                        />

                        {errors?.translations?.description?.[el.language_id] && (
                            <ErrorMessage errors={errors?.translations?.description?.[el.language_id]} />
                        )}
                    </div>
                ))}
            </div>
        </>
    );
}
