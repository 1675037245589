import React from 'react';
import { Controller } from 'react-hook-form';
import TitleDescription from '../TitleDescription/TitleDescription';
import ToggleSwitch from './ToggleSwitch';

export default function LargeTitleSwitch(props) {
    const { control, title, description, switchName, classNames, disabled } = props;

    const handleToggle = (value, fieldChange) => {
        fieldChange(value);

        props?.handleToggle?.(value);
    };

    return (
        <div className={`flex flex-row justify-between ${classNames} w-full`}>
            <TitleDescription
                title={title}
                description={description}
                disabled={disabled}
            />

            <Controller
                render={({ field }) => (
                    <ToggleSwitch
                        isEnable={(!disabled && field.value) ?? false}
                        canChange={!disabled}
                        setIsEnabled={(value) => {
                            !disabled && handleToggle(value, field.onChange);
                        }}
                        cursorType={disabled && 'cursor-not-allowed'}
                    />
                )}
                name={switchName}
                control={control}
            />
        </div>
    );
}
