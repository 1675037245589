import React, { useEffect, useState } from 'react';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as TimerIcon } from '../../../../Assets/timer.svg';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import { dayTimeSlots } from '../../../CreateOffer/TabComponents/OfferTiming/Components/constants';
import ReactDatePicker from 'react-datepicker';
import { format, isSameSecond, isValid, parse } from 'date-fns';

export default function ScheduleNotification(props) {
    const {
        disabled,
        label,
        openFromBottom,
        menuItems,
        timeTypeName,
        errors,
        selectedTimeName,
        selectedDateName,
        pageTitle,
        id,
    } = props;

    const {
        watch,
        setValue,
        control,
        formState: { isDirty },
        clearErrors,
    } = useFormContext();

    const [showCalender, setShowCalender] = useState(false);

    const [isTimeSlotSelectionEnable, setIsTimeSlotSelectionEnable] = useState(false);

    useEffect(() => {
        setShowCalender(watch(timeTypeName)?.value === 'custom');

        if (watch(timeTypeName)?.value === '0') {
            setIsTimeSlotSelectionEnable(false);
        }
        if (watch(timeTypeName)?.value !== '0') {
            setIsTimeSlotSelectionEnable(true);
        }

        // setValue(selectedTimeName, null);

        // eslint-disable-next-line
    }, [watch(timeTypeName)?.value]);

    const handleClickTimeType = () => {
        watch(timeTypeName)?.value === 'custom' && setShowCalender((prevState) => !prevState);
    };

    const handleSelectTime = (selectedDate, fieldChange) => {
        fieldChange(selectedDate);

        const date = new Date(selectedDate);

        const formattedDate = format(date, 'dd MMMM yyyy');

        setValue(timeTypeName, { label: formattedDate, value: formattedDate });

        setIsTimeSlotSelectionEnable(true);
    };

    useEffect(() => {
        if (!disabled) {
            return;
        }

        setValue(selectedTimeName, null);
        setValue(selectedDateName, null);
        setValue(timeTypeName, null);

        // eslint-disable-next-line
    }, [disabled]);

    useEffect(() => {
        if (watch(timeTypeName)?.value === '0') {
            clearErrors(selectedTimeName);
        }
    }, [watch(timeTypeName)?.value, clearErrors, timeTypeName]);

    return (
        <>
            <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
            <div className='flex flex-row items-center mt-1'>
                <div
                    className='w-1/2 mr-1 relative'
                    onClick={handleClickTimeType}>
                    <div>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    openFromBottom={openFromBottom}
                                    disabled={disabled}
                                    icon={<CalenderIcon stroke={disabled ? '#D3D2D8' : '#131126'} />}
                                    menuItems={menuItems}
                                    placeholder='Please select time'
                                    setSelected={field.onChange}
                                    selectedItem={
                                        menuItems?.filter((item) => item?.value == field.value)?.[0] ?? field.value
                                    }
                                />
                            )}
                            name={timeTypeName}
                            control={control}
                            rules={{ required: 'Please select Date' }}
                        />
                    </div>
                    {errors?.[timeTypeName] && <ErrorMessage errors={errors[timeTypeName]} />}

                    {pageTitle !== 'Edit preset' ? (
                        <div className='absolute left-0 top-7 bottom-10'>
                            <Controller
                                render={({ field }) => (
                                    <ReactDatePicker
                                        selected={new Date()}
                                        onChange={(selectedDate) => handleSelectTime(selectedDate, field.onChange)}
                                        timeIntervals={15}
                                        timeCaption='time'
                                        dateFormat='dd MMM yyyy'
                                        popperPlacement='bottom-end'
                                        minDate={new Date()}
                                        showPopperArrow={false}
                                        className='hidden'
                                        open={showCalender}
                                        popperProps={{
                                            positionFixed: true,
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [-5, -80],
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                )}
                                name={selectedDateName}
                                control={control}
                            />
                        </div>
                    ) : null}
                </div>

                {pageTitle !== 'Edit preset' || watch(timeTypeName)?.value === '0' ? (
                    <div className='w-1/2 ml-1'>
                        <Controller
                            render={({ field }) => (
                                <DropdownWithValueLabel
                                    openFromBottom={openFromBottom}
                                    icon={
                                        <TimerIcon
                                            stroke={
                                                isTimeSlotSelectionEnable === 'false' &&
                                                watch(timeTypeName)?.value !== '0'
                                                    ? '#D3D2D8'
                                                    : '#131126'
                                            }
                                        />
                                    }
                                    menuItems={dayTimeSlots}
                                    shadow='shadow-smallDropDownShadow'
                                    fixedHeight='h-[260px]'
                                    placeholder='Please select time'
                                    disabled={
                                        isTimeSlotSelectionEnable === 'false' && watch(timeTypeName)?.value === '0'
                                            ? isTimeSlotSelectionEnable
                                            : !isTimeSlotSelectionEnable
                                    }
                                    setSelected={(value) => {
                                        field.onChange(value);
                                        if (value === '0') {
                                            clearErrors(timeTypeName);
                                        }
                                    }}
                                    selectedItem={field.value}
                                />
                            )}
                            name={selectedTimeName}
                            control={control}
                        />
                        {errors?.[selectedTimeName] && <ErrorMessage errors={errors[selectedTimeName]} />}
                    </div>
                ) : null}
            </div>
        </>
    );
}
