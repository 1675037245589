import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import ContentLoader from 'react-content-loader';

export default function Loader() {
    return (
        <>
            <div className='mt-4 flex flex-row max-w-full w-full'>
                <div className='max-w-[603px] mr-4 w-full'>
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader className='max-w-[312px] md:max-w-full mb-2' />
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader className='max-w-[312px] md:max-w-full mb-2' />
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader className='max-w-[312px] md:max-w-full mb-2' />
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader className='max-w-[312px] md:max-w-full mb-4' />
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader
                        className='max-w-[312px] md:max-w-full mb-4'
                        hasLabel
                    />
                    <TitleDescriptionLoader className='max-w-[312px] md:max-w-full mb-2' />
                    <DropdownBoxLoader
                        className='max-w-[312px] md:max-w-full mb-4'
                        hasLabel
                    />
                </div>

                <ContentLoader
                    width='100%'
                    height='100%'
                    className={`h-[666px] w-full max-w-[319px] ml-6`}>
                    <rect
                        x='0'
                        y={0}
                        rx='48'
                        ry='48'
                        width='319'
                        height={666}
                    />
                </ContentLoader>
            </div>
        </>
    );
}
