import React, { useState } from 'react';
import Tabs from './Components/Tabs';
import { useWindowSize } from '@uidotdev/usehooks';
import MobileScreenCategorylayout from '../../../../../Components/MobileViewScreen/MobileScreenCategorylayout';
import MobileScreenHomeLayout from '../../../../../Components/MobileViewScreen/MobileScreenHomeLayout';
import Layout from '../Components/Layout';
import { useParams } from 'react-router-dom';
import CategoryLayout from './Components/CategoryLayout';
import HomeSection from './Components/HomeSection';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function Appearance(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const { subTab } = useParams();

    const screenSize = useWindowSize();

    const {
        handleSubmit,
        watch,
        setValue,
        formState: { isDirty },
        reset,
    } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateAppearance = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=appearance`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                setShowFooter(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatData = (data) => {
        return {
            ...data,
            default_category_display_count: data.default_category_display_count.value,
            home_screen_layout: data.home_screen_layout.map((el, index) => ({ ...el, display_rank: index + 1 })),
        };
    };

    const onSubmit = (data) => {
        updateAppearance(formatData(data.appearance));
        setShowFooter(false);
        setShowTabChangeWarningPopup(false);
    };

    const [showFooter, setShowFooter] = useState(false);

    const handleClickDisableOnCategoryTab = () => {
        setValue('appearance.category_layout', watch('defaultValue.appearance.category_layout'));
        setValue(
            'appearance.default_category_display_count',
            watch('defaultValue.appearance.default_category_display_count')
        );
        setShowFooter(false);
        reset(undefined, { keepValues: true });
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                if (subTab !== 'category') {
                    handleDiscardForm();
                    setShowFooter(false);
                } else {
                    handleClickDisableOnCategoryTab();
                }
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <div className='flex flex-row xl:block -mb-20 xl:mb-0'>
                <div className='pr-0.5 border-r border-neutral-300 w-full xl:pr-0 xl:border-r-0'>
                    <div
                        className='min-w-[647px] pageContent:min-w-0 pageContent:w-[628px] pageContentSmall:w-[558px] w-full xl:w-full xl:min-w-full xl:pb-2 xl:border-b overflow-auto scrollbar-style pr-2 pb-4 xl:overflow-visible pl-1 md:px-0'
                        style={{
                            height: !isDirty
                                ? '100%'
                                : !(screenSize.width < 1280)
                                  ? screenSize.height < 905
                                      ? '100%'
                                      : screenSize.height - 244
                                  : '100%',
                        }}>
                        <Tabs />

                        {subTab === 'category' ? (
                            <CategoryLayout
                                setShowFooter={setShowFooter}
                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            />
                        ) : (
                            <HomeSection
                                setShowFooter={setShowFooter}
                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            />
                        )}
                    </div>
                </div>

                <div className='w-full flex justify-center pb-6 xl:pt-6 md:pb-20'>
                    {subTab === 'category' ? <MobileScreenCategorylayout /> : <MobileScreenHomeLayout />}
                </div>
            </div>
        </Layout>
    );
}
