import React, { useRef } from 'react';
import Header from './Header';
import useOutsideClick from '../../../../Helper/Hooks/useOutsideClick';
import { useWindowSize } from '@uidotdev/usehooks';

export default function Layout(props) {
    const { handleClickClose, selectedOfferId, children } = props;

    const popupRef = useRef();

    useOutsideClick(popupRef, handleClickClose);

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='max-w-[600px] w-full rounded-xl md:rounded-none bg-shades-50 px-6 py-6 md:px-0 md:pt-0 md:pb-0 m-auto md:max-w-full overflow-y-auto overflow-x-hidden h-[80vh] scrollbar-style md:h-full md:overflow-hidden'
                    ref={popupRef}>
                    <Header
                        handleClickClose={handleClickClose}
                        selectedOfferId={selectedOfferId}
                    />

                    {children}
                </div>
            </div>
        </>
    );
}
