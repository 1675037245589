import React from 'react';
import TableHeader from './TableHeader';
import { BlankComponentTable } from '../../../../../../../Components/BlankComponent/BlankComponent';
import { useFormContext } from 'react-hook-form';
import { CheckBoxWithoutLabels } from '../../../../../../../Components/FormControl/FormControls';
import { ReactComponent as EditIcon } from '../../../../../../../Assets/edit.svg';
import { useSelector } from 'react-redux';

export default function NoVariantsPricingTable(props) {
    const { setToEditTableDetails, isEditPrice, id } = props;

    const { watch, setValue } = useFormContext();

    const orderingModeList = useSelector((state) => state.outlet.orderingModes);

    const getDetailsOfOrderingMode = (index) => {
        const mode = orderingModeList.find((el) => el.id == index);

        return mode;
    };

    const prices = watch(id)?.prices || {};

    const availableAllergens = watch('availableAllergens');

    return (
        <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
            <table className='w-full break-words table-row-bg'>
                <TableHeader id={id} />

                {!Object.keys(prices).length ? (
                    <BlankComponentTable
                        previewText="Variant doesn't have any price"
                        colSpan={12}
                    />
                ) : (
                    <tbody>
                        {Object.keys(prices).map((key, index) => {
                            if (key === 'pricing') return null;

                            const {
                                price,
                                compare_price,
                                quantity,
                                quantityUnit,
                                serves,
                                servesUnit,
                                calories,
                                allergens,
                            } = prices[key];

                            return (
                                <tr
                                    key={index}
                                    className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'>
                                    <td className='px-6'>
                                        <CheckBoxWithoutLabels
                                            isChecked={watch(`${id}.prices[${key}].status`)}
                                            onChange={(isSelected) =>
                                                setValue(`${id}.prices[${key}].status`, isSelected)
                                            }
                                        />
                                    </td>

                                    <td
                                        className={`px-6 ${isEditPrice && 'cursor-pointer'}`}
                                        onClick={() =>
                                            isEditPrice &&
                                            setToEditTableDetails({ price: prices[key], id, orderingModeId: key })
                                        }>
                                        <div className='flex flex-row'>
                                            {isEditPrice && <EditIcon className='mr-3' />}

                                            {getDetailsOfOrderingMode(key)?.display_name}
                                        </div>
                                    </td>

                                    <td className={`px-6 ${isEditPrice && 'cursor-pointer'}`}>
                                        <span>{price}</span>
                                    </td>

                                    <td className='px-6'>{compare_price}</td>

                                    <td className='px-6'>{quantity}</td>

                                    <td className='px-6'>{quantityUnit?.label}</td>

                                    <td className='px-6'>{serves}</td>

                                    <td className='px-6'>{servesUnit}</td>

                                    <td className='px-6'>{calories}</td>

                                    <td className='px-6 py-0.5'>
                                        {allergens?.map((el, index) => {
                                            const label = availableAllergens?.find(
                                                (allergensEl) => allergensEl?.value?.id === el
                                            )?.label;

                                            return (
                                                <div className='border border-neutral-300 bg-neutral-50 px-2 rounded flex flex-row items-center w-fit my-0.5'>
                                                    <span className='py-0.5 paragraph-x-small-regular mr-1'>
                                                        {label}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
        </div>
    );
}
