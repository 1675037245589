import React, { useState } from 'react';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';

export default function SingleListComponent(props) {
    const { isLinkedToCurrentOutlet, id, coupon_code, offer_type, translations, handleStatusChange } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md mb-4'>
                <div className='flex flex-row'>
                    <div className='mr-4 mt-3'>
                        <ToggleSwitch
                            isEnable={isLinkedToCurrentOutlet}
                            setIsEnabled={(value) => handleStatusChange(id, value)}
                            canChange
                        />
                    </div>

                    <div className='w-full'>
                        <div
                            className='flex flex-row items-center justify-between cursor-pointer'
                            onClick={() => setIsShowDetails((prevState) => !prevState)}>
                            <div className='flex flex-row items-center'>
                                <div>
                                    <h3 className='paragraph-overline-small text-neutral-700 mb-1 flex'>
                                        COUPON CODE:
                                    </h3>
                                    <span className='paragraph-small-regular flex'>{coupon_code}</span>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className={`${isShowDetails && 'rotate-180'}`}>
                                    <DownArrow />
                                </div>
                            </div>
                        </div>

                        {isShowDetails && (
                            <div className='mt-[5px]'>
                                <div className='mb-[7px]'>
                                    <span className='paragraph-overline-small text-neutral-700'>OFFER TYPE:</span>
                                    <span className='paragraph-small-regular ml-1'>{offer_type}</span>
                                </div>
                                <div className='mb-[7px]'>
                                    <span className='paragraph-overline-small text-neutral-700'>TITLE:</span>
                                    <span className='paragraph-small-regular ml-1'>{translations.title[1]}</span>
                                </div>
                                <div className='mb-[7px] flex mobile:block'>
                                    <span className='paragraph-overline-small text-neutral-700'>DESCRIPTION:</span>
                                    <div className='paragraph-small-regular ml-1 mobile:ml-0'>
                                        {translations.description[1]}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
