import React, { useEffect, useState } from 'react';
import Tag from '../../../Components/Tag/Tag';
import Dropdown from '../../../Components/DropDown/Dropdown';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function Selectlangauge(props) {
    const { handleChangeLangauge, notificationDetailsEnable, pageTitle, details } = props;

    const [selectLangs, setSelectLangs] = useState([]);

    const {
        watch,
        setValue,
        formState: { isDirty },
    } = useFormContext();

    const removeTag = (index) => {
        setSelectLangs((prevTags) => prevTags.filter((_, i) => i !== index));
    };
    const setSelectedItemFromDropDown = (item) => {
        !selectLangs.includes(item) && setSelectLangs([...selectLangs, item]);
    };

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    useEffect(() => {
        notificationDetailsEnable === false && setSelectLangs([]);
    }, [notificationDetailsEnable]);

    useEffect(() => {
        pageTitle === 'Notification details' && setSelectLangs(['English']);
    }, [pageTitle]);

    const handleSelectionLanguage = (language) => {
        const selectedLanguageList = watch('langauges')?.length
            ? [...watch('langauges'), language.value]
            : [language.value];

        // setShowFooter(true);

        setValue('langauges', selectedLanguageList);
    };

    const removeLanguage = (tag) => {
        const updatedList = watch('langauges')?.filter((el) => el.id !== tag.id);

        // setShowFooter(true);

        setValue('langauges', updatedList);
    };
    const languageOptions = languageList.map((language) => ({
        label: language.language_label,
        value: language,
    }));

    return (
        <>
            <DropdownWithValueLabel
                label='(Select languages)'
                placeholder='Select languages'
                labelPaddingB='pb-2'
                labelStyle='paragraph-medium-italic text-neutral-500'
                shadow='shadow-smallDropDownShadow'
                className='mb-1'
                menuItems={
                    languageOptions.filter(
                        (item) => !watch('langauges')?.find((selectedItem) => selectedItem.id === item.value.id)
                    ) ?? []
                }
                hasFixedPlaceholder
                setSelected={handleSelectionLanguage}
            />
            {watch('languages')?.map((el, index) => (
                <div
                    className='mr-2 inline-block'
                    key={index}>
                    <Tag
                        tag={el?.language_label}
                        onClose={() => removeLanguage(el)}
                    />
                </div>
            ))}
        </>
    );
}
