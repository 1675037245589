import React from 'react';
import { useFormContext } from 'react-hook-form';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function UsageNumber() {
    const { watch } = useFormContext();

    return (
        <>
            <DropdownSection
                title='Usage numbers preference'
                description='Select whether you would like to set the number of times an individual can use the coupon.'
                name='has_usage_number'
                menuItems={preferenceMenuItem}
                placeholder='Select your preference'
                rules={{ required: 'Please select preference' }}
                clearErrorName={['max_usage_count_per_user']}
                clearField={['max_usage_count_per_user']}
                zIndex='z-0'
            />

            {watch('has_usage_number')?.value && (
                <div className='my-6'>
                    <InputSection
                        title='Usage number limit'
                        description='Enter the number of times the coupon could be used by individual customer.'
                        name='max_usage_count_per_user'
                        disabled={!watch('has_usage_number')?.value}
                        placeholder='Enter usage number limit'
                        inputType='number'
                        rules={
                            watch('has_usage_number')?.value
                                ? { required: 'Please enter usage number limit' }
                                : { required: false }
                        }
                    />
                </div>
            )}
        </>
    );
}
