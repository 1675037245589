import React, { useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';

export default function UserDetailsPopup(props) {
    const { userDetails, handleClickClose } = props;

    const popupRef = useRef();

    useOutsideClick(popupRef, handleClickClose);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center overflow-auto p-4'>
                <div
                    className='bg-white w-[490px] rounded-xl px-8 py-6 m-auto overflow-auto [&::-webkit-scrollbar]:hidden md:px-4'
                    ref={popupRef}>
                    <div className='flex items-center justify-between pb-6 mb-4 border-b border-neutral-300 md:pb-2'>
                        <div className='paragraph-large-medium md:paragraph-medium-medium'>User details</div>
                        <span
                            onClick={handleClickClose}
                            className='cursor-pointer'>
                            <CloseIcon className='w-full h-auto' />
                        </span>
                    </div>

                    <div className='mb-1.5 flex flex-row'>
                        <span className='paragraph-medium-medium text-neutral-500'>User name:</span>
                        <span className='paragraph-medium-regular ml-2'>{userDetails?.name}</span>
                    </div>

                    <div className='py-1.5 flex flex-row'>
                        <span className='paragraph-medium-medium text-neutral-500'>User role:</span>
                        <span className='paragraph-medium-regular ml-2'>{userDetails?.role}</span>
                    </div>

                    <div className='my-1.5 flex flex-row'>
                        <span className='paragraph-medium-medium text-neutral-500'>Email ID:</span>
                        <span className='paragraph-medium-regular ml-2'>{userDetails?.email}</span>
                    </div>

                    <div className='pt-1.5 flex flex-row'>
                        <span className='paragraph-medium-medium text-neutral-500'>Mobile numer:</span>
                        <span className='paragraph-medium-regular ml-2'>{userDetails?.mobile_number}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
