import React from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import DropdownWithValueLabel from '../../DropDown/DropdownWithValueLabel';
import { dayTimeSlots } from './functions';
import { ErrorMessage } from '../../Error/ErrorMessage';
import { DefaultInputField } from '../../InputField/InputField';

export default function SlotSection(props) {
    const {
        fieldName,
        fields,
        handleClickAppend,
        remove,
        control,
        watch,
        setValue,
        errors,
        timingTypeName,
        preparationTimeName,
        limitedSlot,
        setShowTabChangeWarningPopup,
        openFromBottom,
        zIndex,
    } = props;

    const dropdownProps = {
        boxPadding: 'px-4 md:px-2',
        height: 'h-[52px]',
        placeholder: 'Select time',
        selectedItemStyle: 'paragraph-medium-regular',
        shadow: 'shadow-smallDropDownShadow',
        fixedHeight: 'h-[260px]',
        openFromBottom: openFromBottom,
    };

    const handleStartTimeSelection = (field, selection, index) => {
        field.onChange(selection.value);
        setValue(`${fieldName}.${index}.end_time`, null);
        setShowTabChangeWarningPopup?.(true);
    };

    return (
        <>
            {fields.map((item, index) => {
                const modifiedTimeSlots = dayTimeSlots.map((timeSlot) => ({
                    ...timeSlot,
                    disabled:
                        watch(`${fieldName}.${index}.start_time`) &&
                        timeSlot.value <= watch(`${fieldName}.${index}.start_time`),
                }));

                const isStartTimeSelected = watch(`${fieldName}.${index}.start_time`);

                const [firstField, secondField, thirdField] = fieldName.split('.');

                const fieldIndex = parseInt(thirdField ?? secondField);

                const startTimeError =
                    watch(timingTypeName)?.value === 'same_time_slot_for_all_days'
                        ? secondField
                            ? errors?.[firstField]?.[secondField]?.[index]?.start_time
                            : errors?.[fieldName]?.[index]?.start_time
                        : thirdField
                          ? errors?.[firstField]?.[secondField]?.[fieldIndex]?.[index]?.start_time
                          : errors?.[firstField]?.[fieldIndex]?.[index]?.start_time;

                const endTimeError =
                    watch(timingTypeName)?.value === 'same_time_slot_for_all_days'
                        ? secondField
                            ? errors?.[firstField]?.[secondField]?.[index]?.end_time
                            : errors?.[fieldName]?.[index]?.end_time
                        : thirdField
                          ? errors?.[firstField]?.[secondField]?.[fieldIndex]?.[index]?.end_time
                          : errors?.[firstField]?.[fieldIndex]?.[index]?.end_time;

                const prepTimeError = errors?.[firstField]?.[secondField]?.[index]?.[preparationTimeName];

                // const prepTimeError = errors?.[fieldName]?.[index]?.[preparationTimeName];

                return (
                    <div
                        className={`flex flex-row justify-between items-center relative ${
                            preparationTimeName && 'md:block md:mt-6'
                        } ${startTimeError || endTimeError || prepTimeError ? 'mb-6' : 'mb-2 last:mb-0'}`}
                        key={item.id}>
                        <div className='flex flex-row justify-between items-center relative w-full'>
                            <div className='w-full mobile:max-w-[128px]'>
                                <span className='paragraph-small-medium'>Start time</span>

                                <div className='w-full relative mt-1'>
                                    <Controller
                                        render={({ field }) => {
                                            const selectedItem = dayTimeSlots.find((el) => el.value === field.value);

                                            return (
                                                <DropdownWithValueLabel
                                                    {...dropdownProps}
                                                    setSelected={(selection) =>
                                                        handleStartTimeSelection(field, selection, index)
                                                    }
                                                    menuItems={dayTimeSlots}
                                                    selectedItem={selectedItem}
                                                    zIndex={zIndex}
                                                />
                                            );
                                        }}
                                        name={`${fieldName}.${index}.start_time`}
                                        control={control}
                                        rules={{ required: { message: 'Start time is required', value: true } }}
                                    />

                                    <div className='absolute -bottom-[18px]'>
                                        {startTimeError && <ErrorMessage errors={startTimeError} />}
                                    </div>
                                </div>
                            </div>

                            <span className='pt-6 px-4 mobile:py-1 mobile:hidden sm:px-1.5 lg:px-2'>to</span>

                            <div className='w-full mobile:max-w-[128px]'>
                                <span
                                    className={`paragraph-small-medium ${!isStartTimeSelected && 'text-neutral-300'}`}>
                                    End time
                                </span>

                                <div className='w-full relative mt-1'>
                                    <Controller
                                        render={({ field }) => {
                                            const selectedItem = dayTimeSlots.find((el) => el.value === field.value);

                                            return (
                                                <DropdownWithValueLabel
                                                    {...dropdownProps}
                                                    disabled={!isStartTimeSelected}
                                                    selectedItem={selectedItem}
                                                    setSelected={(selection) => {
                                                        field.onChange(selection.value);
                                                        setShowTabChangeWarningPopup?.(true);
                                                    }}
                                                    menuItems={[
                                                        ...modifiedTimeSlots,
                                                        { label: '12:00 AM', value: '00:00' },
                                                    ]}
                                                    zIndex={zIndex}
                                                />
                                            );
                                        }}
                                        name={`${fieldName}.${index}.end_time`}
                                        control={control}
                                        rules={{ required: { message: 'End time is required', value: true } }}
                                    />

                                    <div className='absolute -bottom-[18px]'>
                                        {endTimeError && <ErrorMessage errors={endTimeError} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {preparationTimeName && (
                            <div className='flex flex-row items-center justify-between'>
                                <div className='-mt-1 ml-3 min-w-[200px] lg:min-w-[149px] md:ml-0 md:mt-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <DefaultInputField
                                                boxHeight='[52px]'
                                                labelMarginB='pb-2'
                                                label='Preparation Time'
                                                placeholder='Enter time (minute)'
                                                shadow='shadow-xSmall'
                                                enteredValue={(value) => {
                                                    field.onChange(value);
                                                    setShowTabChangeWarningPopup?.(true);
                                                }}
                                                inputType='number'
                                                value={field.value ?? ''}
                                            />
                                        )}
                                        name={`${fieldName}.${index}.${preparationTimeName}`}
                                        control={control}
                                        rules={{ required: 'Please enter time' }}
                                    />
                                    {prepTimeError && (
                                        <ErrorMessage
                                            className='absolute -bottom-[18px]'
                                            errors={prepTimeError}
                                        />
                                    )}
                                </div>
                                <div
                                    className='min-w-[24px] pt-7 ml-4 sm:ml-1.5 '
                                    onClick={() => {
                                        if (fields.length !== 1) {
                                            setShowTabChangeWarningPopup?.(true);
                                            remove(index);
                                        }
                                    }}>
                                    <CloseIcon
                                        stroke={fields.length !== 1 ? '#EF4444' : '#D3D2D8'}
                                        className={fields.length !== 1 ? 'cursor-pointer' : null}
                                    />
                                </div>
                            </div>
                        )}

                        {!preparationTimeName && (
                            <div
                                className={`min-w-[24px] pt-7 ml-4 sm:ml-1.5 ${preparationTimeName && 'md:hidden'}'`}
                                onClick={() => fields.length !== 1 && remove(index)}>
                                <CloseIcon
                                    stroke={fields.length !== 1 ? '#EF4444' : '#D3D2D8'}
                                    className={fields.length !== 1 ? 'cursor-pointer' : null}
                                />
                            </div>
                        )}

                        {handleClickAppend && index === fields?.length - 1 && (
                            <div
                                className='absolute right-0 top-0 cursor-pointer'
                                onClick={() => fields.length !== limitedSlot && handleClickAppend()}>
                                <span
                                    className={`paragraph-small-medium ${
                                        fields.length === limitedSlot
                                            ? 'text-neutral-300 cursor-not-allowed'
                                            : 'text-primary-500 cursor-pointer'
                                    }`}>
                                    + Add slot
                                </span>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}
