import React from 'react';
import BannerCard from './BannerCard';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { ReactComponent as LinearMenuIcon } from '../../../../Assets/linear-menu.svg';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';
import { useSelector } from 'react-redux';
import APIV2 from '../../../../api/axios/APIV2';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CardLoader from './CardLoader';
import { BlankComponent } from '../../../../Components/BlankComponent/BlankComponent';

export default function CardSection(props) {
    const {
        list,
        handleSelection,
        selectedBanners,
        handleClickUnlink,
        setEditBannerData,
        bannerType,
        setStoreBannerList,
        pageState,
        isLoading,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateListSequence = async (updatedList) => {
        const list = updatedList.map((el) => {
            return { id: el.id };
        });

        try {
            await APIV2.patch(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/banners/change/sequence`,
                { banners: list }
            );
        } catch (error) {}
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(list, result.source.index, result.destination.index);

        updateListSequence(reorderedItems);

        const parts = bannerType.split('-');

        const resultString = parts[0] + parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

        setStoreBannerList((prevState) => {
            return { ...prevState, [resultString]: reorderedItems };
        });
    };

    return isLoading ? (
        <CardLoader />
    ) : !list?.length ? (
        <BlankComponent
            previewText='No banner at this moment'
            styles='border border-neutral-300 rounded-xl'
        />
    ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='cardSection'>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {list?.map((el, index) => (
                            <Draggable
                                key={index}
                                draggableId={`draggable-${index}`}
                                index={index}
                                isDragDisabled={true}>
                                {(provided) => (
                                    <div
                                        className='mb-2 flex flex-row w-full items-center'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <div
                                            className='mb-2 flex flex-row w-full items-center'
                                            key={index}>
                                            {pageState.isEditbanner && (
                                                <span
                                                    className='-ml-1 mr-1'
                                                    onClick={() => setEditBannerData(el)}>
                                                    <EditIcon
                                                        width={16}
                                                        height={16}
                                                    />
                                                </span>
                                            )}

                                            {pageState.isSelectionEnable && (
                                                <span className='mr-2 -ml-2'>
                                                    <CheckBoxWithoutLabels
                                                        onChange={(value) => handleSelection(value, el.id)}
                                                        id={el.title}
                                                        isChecked={selectedBanners.includes(el.id)}
                                                    />
                                                </span>
                                            )}

                                            <BannerCard
                                                item={el}
                                                {...props}
                                                handleClickUnlink={handleClickUnlink}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
