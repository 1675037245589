import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';

export default function ListViewCustomerIndividual(props) {
    const {
        customerName,
        mobileNumber,
        emailId,
        totalOrders,
        revenue,
        lastOrder,
        handleClickName,
        isShowBulkSelectButton,
        id,
        checkedItems,
        handleCheckboxChange,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md md:mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className='flex flex-row items-center'>
                        {isShowBulkSelectButton && (
                            <div className='mr-4 cursor-pointer'>
                                <CheckBoxWithoutLabels
                                    onChange={(isChecked) => handleCheckboxChange(id, isChecked)}
                                    isChecked={checkedItems.includes(id)}
                                />
                            </div>
                        )}
                        <div>
                            <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>CUSTOMER NAME:</h3>
                            <span className='paragraph-small-regular'>{customerName}</span>
                        </div>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-2'>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>MOBILE NUMBER:</span>
                            <span className='paragraph-small-regular ml-1'>{mobileNumber}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>EMAIL ID:</span>
                            <span className='paragraph-small-regular ml-1'>{emailId}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL ORDERS:</span>
                            <span className='paragraph-small-regular ml-1'>{totalOrders}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>REVENUE GENERATED:</span>
                            <span className='paragraph-small-regular ml-1'>{revenue}</span>
                        </div>
                        <div className='flex flex-row justify-between items-center sm:block'>
                            <div className='flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>LAST ORDER:</span>
                                <span className='paragraph-small-regular ml-1'>{lastOrder}</span>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => handleClickName(id)}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
