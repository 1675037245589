import React from 'react';
import { Table } from './Table';
import { TableListView } from './ListView';

import garlicBread from '../../../../Assets/garlic-bread.svg';
import baverages from '../../../../Assets/beverages.svg';
import classicPizza from '../../../../Assets/classic-pizza.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function FavoriteCategory() {
    const header = ['FAVORITE CATEGORY', 'TOTAL ORDERS', 'REVENUE  GENERATED'];

    const style = ['min-w-[239px] lg:w-[238px]', 'min-w-[184px] lg:w-[208px]', 'min-w-[201px] lg:w-[218px]'];

    const tableDetails = [
        [{ icon: classicPizza }, 'Classic pizza', '10,456', '₹1,07,234'],
        [{ icon: garlicBread }, 'Garlic bread', '5,983', '₹29,632'],
        [{ icon: baverages }, 'Beverage', '2,765', '₹14,286'],
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='w-full'>
                <div className='mb-4 md:mb-2'>
                    <span className='paragraph-large-medium text-black'>Favorite category</span>
                </div>

                {!isMobileScreen ? (
                    <Table
                        header={header}
                        style={style}
                        details={tableDetails}
                    />
                ) : (
                    <div>
                        {tableDetails.map((el, index) => (
                            <div
                                key={index}
                                className='mb-2'>
                                <TableListView
                                    details={el}
                                    type='category'
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
