import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SmallPrimaryButton } from '../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { fetchCategoryList } from '../functions';
import { useSelector } from 'react-redux';
import ListLoader from '../Components/ListLoader';
import HasMoreCard from '../Components/HasMoreCard';
import SubCategoryCard from '../Components/SubCategoryCard';
import CategoryCardSection from '../Components/CategoryCardSection';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableItem } from '../Components/SortableItem';

export default function CategorySection(props) {
    const {
        pageState,
        setSelectedCategoryDish,
        selectedCategoryDish,
        setAllCategoryDish,
        isOperationLoading,
        setIsOperationLoading,
        setSelectedCategoryId,
        selectedCategoryId,
        setClickedCategory,
    } = props;

    const navigate = useNavigate();

    const goToAddCategory = () => {
        navigate('/menu/add-category/basic-details');
    };

    const goToEditCategory = (category) => {
        navigate(`/menu/edit-category/${category.category_id}/basic-details`, { state: { category } });
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const screenSize = useWindowSize();

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const [isLoading, setIsLoading] = useState(true);

    const [paginationState, setPaginationState] = useState(initialPaginationState);

    const [categoryList, setCategoryList] = useState([]);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            setPaginationState((prevState) => ({ ...prevState, has_more_pages: category?.has_more_pages }));

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: category?.has_more_pages,
                last_page: category?.last_page,
            }));

            if (params.page === 1) {
                setCategoryList(category?.data);
                setAllCategoryDish(category?.data);
            } else {
                setCategoryList((prevState) => [...prevState, ...category?.data]);
                setAllCategoryDish((prevState) => [...prevState, ...category?.data]);
            }

            params.page === 1 &&
                setSelectedCategoryDish((prevState) => ({
                    ...prevState,
                    category: category?.data?.[0],
                    subCategory: category?.data[0]?.sub_categories,
                    subCategoryId: category?.data?.[0]?.sub_categories?.[0]?.category_id,
                }));

            setClickedCategory({ id: category?.data?.[0]?.category_id, type: 'category' });

            setIsOperationLoading(false);

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        setCategoryList([]);
        setAllCategoryDish([]);
        setIsLoading(true);
        fetchCategory(initialPaginationState);
    }, []);

    const containerRef = useRef(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const [showSubCategory, setShowSubCategory] = useState(null);

    useEffect(() => {
        if (isOperationLoading) {
            setCategoryList([]);
            setAllCategoryDish([]);
            setIsLoading(true);
            fetchCategory(initialPaginationState);
        }
    }, [isOperationLoading]);

    const handleCategorySelection = (isChecked, category) => {
        let selectedCategoryState = isChecked
            ? [...selectedCategoryId, category.category_id]
            : selectedCategoryId.filter((el) => el !== category.category_id);

        if (category.sub_categories.length && !isChecked) {
            const subCategoryIds = category.sub_categories.map((el) => el.category_id);
            selectedCategoryState = selectedCategoryState.filter((el) => !subCategoryIds.includes(el));
        }

        setSelectedCategoryId(selectedCategoryState);
    };

    const handleClickCategoryCard = (category) => {
        setSelectedCategoryDish((prevState) => ({
            ...prevState,
            category: category,
            subCategory: category.sub_categories,
            ...(category.sub_categories.length ? {} : { subCategoryId: null }),
        }));
        !category.sub_categories.length && setShowSubCategory(null);
        setClickedCategory({ id: category.category_id, type: 'category' });
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
    };

    const handleDragStart = (event) => {
        const { active } = event;

        if (!!categoryList.find((el) => el.category_id === active.id)) {
            setShowSubCategory(null);
        }
    };

    useEffect(() => {
        if (isOperationLoading) {
            setCategoryList([]);
            setAllCategoryDish([]);
            setIsLoading(true);
            fetchCategory(initialPaginationState);
        }
    }, [isOperationLoading]);

    return (
        <>
            <div className='flex flex-row justify-between w-full items-center md:pb-2 pb-4 max-w-[411px]'>
                <span className='paragraph-large-medium text-[#000000]'>Categories</span>
                <div
                    className='max-w-[131px] cursor-pointer'
                    onClick={() => goToAddCategory()}>
                    <SmallPrimaryButton
                        leftIconClick={
                            <AddIcon
                                stroke='#C4BEED'
                                height={208}
                                width={20}
                            />
                        }
                        leftIconDefault={
                            <AddIcon
                                stroke='#FFFFFF'
                                height={20}
                                width={20}
                            />
                        }
                        label='Add category'
                    />
                </div>
            </div>

            {isLoading && paginationState?.page === 1 && <ListLoader className='max-w-[411px] h-[104px]' />}

            {!isLoading && !categoryList?.length && (
                <div
                    className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                    style={{ height: screenSize.height - 300 }}>
                    No dishes are available
                </div>
            )}

            <div
                ref={containerRef}
                onScroll={handleScroll}
                className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-10 pl-10'
                style={{ height: screenSize.height - 300 }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}>
                    <SortableContext
                        items={categoryList?.map((item) => item.category_id)}
                        disabled={!pageState?.reArrange}>
                        {categoryList?.map((category) => (
                            <SortableItem
                                key={category.category_id}
                                id={category.category_id}>
                                <CategoryCardSection
                                    pageState={pageState}
                                    category={category}
                                    selectedCategoryDish={selectedCategoryDish}
                                    handleClickCategoryCard={handleClickCategoryCard}
                                    goToEditCategory={goToEditCategory}
                                    setShowSubCategory={setShowSubCategory}
                                    showSubCategory={showSubCategory}
                                    handleCategorySelection={handleCategorySelection}
                                    selectedCategoryId={selectedCategoryId}
                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                    setSelectedCategoryId={setSelectedCategoryId}
                                    setClickedCategory={setClickedCategory}
                                />
                                {!!category.sub_categories.length && showSubCategory === category.category_id && (
                                    <SortableContext items={category?.sub_categories?.map((sub) => sub.category_id)}>
                                        <SubCategoryCard
                                            pageState={pageState}
                                            selectedCategoryDish={selectedCategoryDish}
                                            setSelectedCategoryDish={setSelectedCategoryDish}
                                            subCategory={category?.sub_categories}
                                            handleCategorySelection={handleCategorySelection}
                                            setSelectedCategoryId={setSelectedCategoryId}
                                            selectedCategoryId={selectedCategoryId}
                                            setClickedCategory={setClickedCategory}
                                            goToEditCategory={goToEditCategory}
                                            category={category}
                                        />
                                    </SortableContext>
                                )}
                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>

                {paginationState?.has_more_pages && <HasMoreCard />}

                {isLoading && <ListLoader className='max-w-[303px] h-[104px]' />}
            </div>
        </>
    );
}
