import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../../../Components/TitleDescription/TitleDescription';
import DropDownUnableDisable from '../GlobalSetting/TabComponents/DropDownUnableDisable';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';

export default function DropDownUnableDisableSection(props) {
    const { title, description, name, disabled, rules, marginBetween, maxWidth, className, errors } = props;

    const { control } = useFormContext();

    const handleSelection = (selection, fieldChange) => {
        fieldChange(selection);

        props?.handleSelection?.(selection);
    };

    return (
        <>
            <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                <TitleDescription
                    title={title}
                    disabled={disabled}
                    description={description}
                    maxWidth={maxWidth}
                />
            </div>

            <div className={`w-full max-w-[312px] md:max-w-full ${className}`}>
                <Controller
                    render={({ field }) => (
                        <DropDownUnableDisable
                            handleSelection={(selection) => handleSelection(selection, field.onChange)}
                            selectedValue={field.value}
                            disabled={disabled}
                        />
                    )}
                    name={name}
                    control={control}
                    rules={rules ?? {}}
                />
                {errors?.[name] && <ErrorMessage errors={errors[name]} />}
            </div>
        </>
    );
}
