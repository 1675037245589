import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../Components/InputSection';
import DropdownSection from '../../Components/DropdownSection';
import Layout from '../../Components/Layout';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import TitleDescriptionLoader from '../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../Components/ContentLoader/DropdownLoader';

export default function SMSGateway(props) {
    const { fetchSettings, fetchlanguageList, handleDiscardForm } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useFormContext();

    const onSubmit = (data) => {
        updateSMSSetting(formateData(data.sms_settings));
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateSMSSetting = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=sms_settings`, { ...params });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const smsGatewayMenuItems = [
        { label: '2 Factor', value: 'two_factor' },
        { label: 'SMS Fortius', value: 'smsfortius' },
    ];

    const formateData = (data) => {
        return { two_factor_api_key: data.two_factor_api_key, sms_provider: data.sms_provider.value };
    };

    useEffect(() => {
        setValue(
            'sms_settings.sms_provider',
            smsGatewayMenuItems.find((el) => el.value === watch('sms_settings.provider'))
        );

        !watch('sms_settings.two_factor_api_key') &&
            setValue('sms_settings.two_factor_api_key', watch('sms_settings.two_factor_api_key'));

        //eslint-disable-next-line
    }, [watch('sms_settings.api_key'), watch('sms_settings.provider')]);

    return (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => handleDiscardForm()}
            showFooter={isDirty}>
            {watch('isLoadingForm') ? (
                <>
                    <TitleDescriptionLoader
                        className='mb-4 max-w-[360px] md:max-w-full'
                        descHeight={30}
                    />
                    <DropdownBoxLoader className='max-w-[360px] md:max-w-full' />
                    <TitleDescriptionLoader
                        className='mb-4 max-w-[360px] md:max-w-full'
                        descHeight={30}
                    />
                    <DropdownBoxLoader
                        className='mb-4 max-w-[360px] md:max-w-full'
                        hasLabel
                    />
                </>
            ) : (
                <>
                    <DropdownSection
                        name='sms_settings.sms_provider'
                        title='Please select a SMS Provider'
                        description='Select any provider for the SMS gateway'
                        menuItems={smsGatewayMenuItems}
                        placeholder='Please select api provider'
                        className='mb-4'
                    />

                    <InputSection
                        name='sms_settings.two_factor_api_key'
                        title={
                            watch('sms_settings.sms_provider')?.value === smsGatewayMenuItems[0].value
                                ? '2Factor SMS gateway'
                                : 'SMS Fortius gateway'
                        }
                        description={
                            watch('sms_settings.sms_provider')?.value === smsGatewayMenuItems[0].value
                                ? 'API Settings for your 2Factor SMS account'
                                : 'API Settings for your SMS Fortius account'
                        }
                        label={
                            watch('sms_settings.sms_provider')?.value === smsGatewayMenuItems[0].value
                                ? '(2Factor SMS API key)'
                                : '(SMS Fortius API key)'
                        }
                        placeholder='Enter SMS API Key'
                        shadow='shadow-smallDropDownShadow'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        className=''
                    />
                </>
            )}
        </Layout>
    );
}

// SMS ==. ad22aa91b9eb42e9827ae80b559bd450, santushti key msg : 9066cb67-3120-11e6-b006-00163ef91450
