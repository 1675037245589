import React from 'react';
import MediaLibraryCard from './MediaLibraryCard';
import { CardLoader } from '../../Loader';
import { BlankComponent } from '../../../../../Components/BlankComponent/BlankComponent';

export default function GridSection(props) {
    const { list, isLoading, resultPerPage } = props;

    return (
        <>
            {isLoading ? (
                <div className='grid grid-cols-6 gap-5 pageContent:gap-4 xl:grid-cols-5 largeTablet:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 mobile:grid-cols-1 mobile:gap-3'>
                    {[...Array(resultPerPage)].map((_, index) => (
                        <CardLoader key={index} />
                    ))}
                </div>
            ) : !list.length ? (
                <BlankComponent
                    styles='rounded-xl border border-neutral-300'
                    previewText='No media at this moment'
                />
            ) : (
                <div className='grid grid-cols-6 gap-5 pageContent:gap-4 xl:grid-cols-5 largeTablet:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 mobile:grid-cols-1 mobile:gap-3'>
                    {list?.map((el, index) => {
                        return (
                            <div key={index}>
                                <MediaLibraryCard
                                    {...props}
                                    item={el}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
