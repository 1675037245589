import React, { useState } from 'react';
import { LargePrimaryButton } from '../Buttons/Button';
import { ReactComponent as AddIcon } from '../../Assets/add.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';

export default function MobileOrderingMode(props) {
    const { orderingModes } = props;

    const [showOrderingModeButton, setShowOrderingModeButton] = useState(false);

    return (
        <>
            {showOrderingModeButton && (
                <div className='fixed bg-black bg-opacity-70 inset-0 z-[6]'>
                    <div className='flex flex-col justify-end items-end h-full'>
                        <div className='order-type-mobile flex flex-col items-end mr-4 mb-[98px]'>
                            {orderingModes.map((el, index) => (
                                <div
                                    className='mb-4'
                                    key={index}
                                    onClick={() => el.onClick()}>
                                    <LargePrimaryButton
                                        buttonStyle={el.buttonStyle}
                                        label={el.label}
                                        leftIconDefault={<AddIcon stroke='#ffffff' />}
                                        leftIconClick={<AddIcon stroke='#C4BEED' />}
                                        isDefault={false}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div
                className='fixed bottom-[42px] right-4 w-14 h-14 rounded-full bg-primary-500 flex shadow-xLarge z-[7] cursor-pointer'
                onClick={() => setShowOrderingModeButton((prevState) => !prevState)}>
                <span className='m-auto'>
                    {showOrderingModeButton ? (
                        <CloseIcon
                            width={35}
                            height={35}
                            stroke='#FFFFFF'
                        />
                    ) : (
                        <AddIcon
                            stroke='#fff'
                            width={35}
                            height={35}
                        />
                    )}
                </span>
            </div>
        </>
    );
}
