import React from 'react';

const PlatformSalesDetails = () => {
    const chartData = [
        {
            platform: 'Swiggy',
            sales: '₹1,07,234',
            color: 'bg-secondary-600',
        },
        {
            platform: 'Mobile application',
            sales: '₹2,17,268',
            color: 'bg-primary-600',
        },
        {
            platform: 'Zomato sales',
            sales: '₹95,213',
            color: 'bg-destructive-600',
        },
        {
            platform: 'Website sales',
            sales: '₹47,741',
            color: 'bg-tertiary-600',
        },
        {
            platform: 'Others sales',
            sales: '₹9,299',
            color: 'bg-neutral-600',
        },
    ];
    return (
        <>
            {chartData.map((el, index) => (
                <PlatformSalesInfo
                    {...el}
                    key={index}
                />
            ))}
        </>
    );
};

export default PlatformSalesDetails;

const PlatformSalesInfo = (props) => {
    const { color, platform, sales } = props;
    return (
        <div className='flex justify-between items-center paragraph-medium-regular pb-6 last:pb-0'>
            <div className='flex items-center'>
                <div className={`w-[11px] h-[11px] rounded-full mr-2 ${color}`} />
                <div>{platform}</div>
            </div>
            <div>{sales}</div>
        </div>
    );
};
