import * as types from '../types/fullScreen';

const initialState = {
    togglePanel: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_FULL_SCREEN:
            return {
                ...state,
                togglePanel: action.payload,
            };

        default:
            return state;
    }
};
