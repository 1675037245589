import React from 'react';
import { SingleLoader } from '../../../../Components/ContentLoader/DropdownLoader';

export default function CardLoader() {
    return (
        <>
            <SingleLoader className='h-[70px]' />
            <SingleLoader className='h-[70px] mt-4' />
            <SingleLoader className='h-[70px] mt-4' />
            <SingleLoader className='h-[70px] mt-4' />
            <SingleLoader className='h-[70px] mt-4' />
            <SingleLoader className='h-[70px] mt-4' />
        </>
    );
}
