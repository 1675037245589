import React, { useEffect, useState } from 'react';

export const DefaultInputField = (props) => {
    const {
        label,
        action,
        labelMarginB,
        labelStyle,
        inputType,
        placeholder,
        boxHeight,
        paddingLeft,
        placeholderIcon,
        addon,
        addonIcon,
        placeholderTextColor,
        shadow,
        helperText,
        value,
        addonStyle,
        handleClickAction,
        actionTextColor,
        actionTextStyle,
        paddingRight,
        inputStyle,
        removeSectionStyle,
        disabled,
        handleClickAddon,
        enteredValue,
        onFocus,
        diableInputStyle,
        prefix,
        prefixClass,
        onChange,
        textInputStyle,
        maxValue,
    } = props;

    const [inputValue, setInputValue] = useState(value ?? '');

    const handleChangeInputValue = (value, event) => {
        setInputValue(value);
        enteredValue && enteredValue(value);
        onChange && onChange(value);
    };

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    const inputBoxStyle = `w-full h-${
        boxHeight ? boxHeight : '12'
    } ${textInputStyle ?? 'paragraph-small-regular'} border rounded-md border-neutral-300 ${
        paddingLeft ?? (placeholderIcon ? 'pl-12' : 'pl-4')
    } ${paddingRight ?? (addon || addonIcon ? 'pr-[100px]' : 'pr-4')} placeholder:${
        placeholderTextColor ?? 'text-neutral-300'
    } ${
        diableInputStyle ?? 'disabled:text-neutral-300'
    } ${shadow} focus:border-primary-500 disabled:bg-white outline-none focus:outline-none focus:ring-4 focus:ring-primary-100 caret-primary-500 placeholder:paragraph-small-regular ${inputStyle}`;

    const handleKeyDown = (e) => {
        inputType === 'number' && e.key === 'e' && e.preventDefault();

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className='w-full'>
                {(label || action) && (
                    <div className={`justify-between w-full ${labelMarginB ?? 'mb-1'} flex flex-row`}>
                        <label className={`${labelStyle ?? 'paragraph-small-medium'}`}>{label}</label>
                        <span
                            className={`${actionTextStyle ?? 'paragraph-small-medium'} ${
                                actionTextColor ?? 'text-primary-500'
                            } cursor-pointer ${removeSectionStyle}`}
                            onClick={handleClickAction}>
                            {action}
                        </span>
                    </div>
                )}

                <div className='relative'>
                    <input
                        type={inputType ?? 'text'}
                        placeholder={placeholder}
                        className={inputBoxStyle}
                        onChange={(e) => handleChangeInputValue(e.target.value, e)}
                        disabled={disabled}
                        value={inputValue}
                        onFocus={() => onFocus?.()}
                        onWheel={(e) => inputType === 'number' && e.target.blur()}
                        onKeyDown={(e) => {
                            handleKeyDown(e);
                        }}
                        max={maxValue}
                    />
                    <div
                        className='flex flex-row absolute right-4 top-3.5 cursor-pointer'
                        onClick={() => handleClickAddon?.()}>
                        <span className={`${addonStyle ?? 'text-primary-300 paragraph-small-regular'}`}>{addon}</span>
                        {addonIcon && <div className='h-6 w-6 ml-1 rounded'>{addonIcon}</div>}
                    </div>

                    {placeholderIcon && <div className='h-6 w-6 rounded absolute top-3 left-4'>{placeholderIcon}</div>}
                    {prefix && <span className={`absolute left-1 ${prefixClass}`}> {prefix} </span>}
                </div>
                {helperText && (
                    <p className='w-full pt-2 text-neutral-500 paragraph-small-regular group-disabled:text-neutral-300'>
                        {helperText}
                    </p>
                )}
            </div>
        </>
    );
};

export const DestructiveInputField = (props) => {
    const { label, action, placeholder, addon, addonIcon, placeholderIcon, helperText } = props;

    return (
        <>
            <div className='w-full'>
                {(label || action) && (
                    <div className='flex flex-row justify-between w-full mb-1'>
                        <label className='h-5 font-medium disabled:text-neutral-300 text-sm leading-5'>{label}</label>
                        <span className='text-primary-500 font-medium disabled:text-neutral-300'>{action}</span>
                    </div>
                )}

                <div className='relative'>
                    <input
                        type='text'
                        placeholder={placeholder}
                        className={`w-full h-12 border rounded-md border-destructive-300 pl-12 ${
                            addon || addonIcon ? 'pr-[100px]' : 'pr-4'
                        } placeholder:text-neutral-300 font-normal focus:border-destructive-500 outline-none focus:outline-none focus:ring-4 text-sm focus:ring-destructive-100 caret-destructive-500 placeholder:paragraph-small-regular`}
                    />

                    <div className='flex flex-row absolute right-4 top-3'>
                        <span className='text-neutral-300 text-sm leading-5'>{addon}</span>
                        {addonIcon && <div className='h-6 w-6 ml-1 rounded'>{addonIcon}</div>}
                    </div>
                    {placeholderIcon && <div className='h-6 w-6 rounded absolute top-3 left-4'>{placeholderIcon}</div>}
                </div>
                {helperText && (
                    <p className='w-full pt-2 text-destructive-500 font-normal group-disabled:text-neutral-300'>
                        {helperText}
                    </p>
                )}
            </div>
        </>
    );
};

export const DisabledInputField = (props) => {
    const { label, action, placeholder, addon, addonIcon, placeholderIcon, helperText } = props;

    return (
        <>
            <div className='w-full'>
                <div className='flex flex-row justify-between w-full'>
                    <label className='h-5 text-neutral-300 font-medium'>{label}</label>
                    <span className='text-neutral-300 font-medium'>{action}</span>
                </div>

                <div className='relative mt-1'>
                    <input
                        type='text'
                        placeholder={placeholder}
                        className={`w-full h-12 border rounded-md border-neutral-500 ${
                            placeholderIcon ? 'pl-12' : 'pl-4'
                        } pr-[100px] text-neutral-300 placeholder:text-neutral-300 font-normal outline-none placeholder:paragraph-small-regular`}
                    />

                    <div className='flex flex-row absolute right-4 top-3'>
                        <span className='text-neutral-300'>{addon}</span>
                        {addonIcon && (
                            <img
                                src={addonIcon}
                                alt=''
                                className='w-6 h-6 rounded ml-1'
                            />
                        )}
                    </div>

                    {placeholderIcon && (
                        <img
                            src={placeholderIcon}
                            alt=''
                            className='h-6 w-6 rounded absolute top-3 left-4'
                        />
                    )}
                </div>
                <p className='w-full pt-2 text-neutral-300 font-normal group-disabled:text-neutral-300'>{helperText}</p>
            </div>
        </>
    );
};

export const InputArea = (props) => {
    const {
        label,
        labelMarginB,
        labelStyle,
        type,
        disabled,
        placeholder,
        paddingT,
        boxPaddingB,
        boxHeight,
        resizeNone,
        shadow,
        addon,
        addonIcon,
        placeholderIcon,
        helperText,
        onFocus,
        enteredValue,
    } = props;

    const [inputValue, setInputValue] = useState(props.inputValue ?? '');

    const handleChangeInputValue = (value) => {
        setInputValue(value);
        enteredValue && enteredValue(value);
    };

    useEffect(() => {
        setInputValue(props.inputValue ?? '');
    }, [props.inputValue]);

    return (
        <>
            <div className='w-full'>
                {label && (
                    <div className={`flex flex-row justify-between w-full ${labelMarginB ?? 'mb-2'}`}>
                        <label
                            className={`${
                                labelStyle ??
                                (type === 'storeSetting' ? 'paragraph-medium-italic' : 'paragraph-small-medium')
                            } ${disabled && 'text-neutral-300'} `}>
                            {label}
                        </label>
                    </div>
                )}

                <div className='relative'>
                    <textarea
                        value={inputValue}
                        disabled={disabled}
                        type='text'
                        onFocus={() => {
                            onFocus?.();
                        }}
                        onChange={(e) => handleChangeInputValue(e.target.value)}
                        placeholder={placeholder}
                        className={`${paddingT ?? 'pt-3'} px-4 ${!boxPaddingB ? 'pb-3' : boxPaddingB} ${
                            boxHeight ?? 'h-28'
                        } ${resizeNone} ${shadow} w-full border disabled:bg-white disabled:cursor-not-allowed rounded-md block border-neutral-300 pl-4 placeholder:text-neutral-300 focus:border-primary-500 outline-none focus:outline-none focus:ring-4 focus:ring-primary-100 caret-primary-500 paragraph-small-regular`}
                    />

                    <div className='flex flex-row absolute right-4 top-3'>
                        <span className='text-neutral-300 paragraph-small-regular'>{addon}</span>
                        {addonIcon && <div className='h-6 w-6 ml-1 rounded'>{addonIcon}</div>}
                    </div>

                    {placeholderIcon && <div className='h-6 w-6 rounded absolute top-3 left-4'>{placeholderIcon}</div>}
                </div>
                {helperText && (
                    <p className='w-full pt-2 text-neutral-500 paragraph-small-regular group-disabled:text-neutral-300'>
                        {helperText}
                    </p>
                )}
            </div>
        </>
    );
};
