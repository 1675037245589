import React, { useEffect } from 'react';
import { getBadgeList } from '../../../../Components/Catalogue/functions';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import DropdownWithSeparateItemColor from '../../../../../../Components/DropDown/DropdownWithSeparateItemColor';

export default function SelectBadge() {
    const { setValue, watch, control } = useFormContext();

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const setBadgeList = async () => {
        const response = await getBadgeList();

        const formattedBadges = response?.badges?.map((el) => ({
            label: el?.translations?.display_name?.[selectedLanguage],
            style: { background: el.color },
            className: 'px-2 py-1 text-white rounded w-fit paragraph-small-regular',
            value: el,
        }));

        setValue('availableBadge', formattedBadges);
    };

    useEffect(() => {
        !watch('availableBadge')?.length && setBadgeList();
    }, []);

    const handleSelection = (selection, fieldChange) => {
        fieldChange(selection);
    };

    const handleSelectBadge = (badge, isSelected) => {
        const updatedBadgeList = watch('availableBadge')?.map((el) => {
            if (el?.value?.id === badge?.value?.id) {
                return { ...el, isSelected: isSelected };
            }
            return el;
        });

        setValue('availableBadge', updatedBadgeList);
    };

    return (
        <>
            <TitleDescription
                className='mb-4'
                title='Badges'
                description='Create a new badge or select an existing one to attach it with the dish. The attached badge will be reflected within the mobile application.'
            />

            <div className={`w-full md:max-w-full max-w-[312px]`}>
                <Controller
                    render={({ field }) => (
                        <DropdownWithSeparateItemColor
                            height={'h-[48px]'}
                            selectedItem={field.value}
                            setSelected={(selection) => {
                                handleSelection(selection, field.onChange);
                            }}
                            label='(Select badge)'
                            placeholder='Select a badge for the dish '
                            labelPaddingB='pb-2'
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            shadow='smallDropDownShadow'
                            menuItems={watch('availableBadge')}
                            hasCheckBox
                            handleCheckboxChange={handleSelectBadge}
                            fixedHeight='h-[300px]'
                        />
                    )}
                    name='badges.badge'
                    control={control}
                />
            </div>
        </>
    );
}
