import React from 'react';
import DishCard from '../Components/DishCard';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function DishesSection(props) {
    const { setShowPopup } = props;

    const { height: screenHeight, width: screenWidth } = useWindowSize();

    const dishes = [
        { dishName: 'Double Cheese Margherita ', price: '₹559.00/-', image: pizza1, variants: 'Variants: Yes(3)' },
        { dishName: 'Deluxe Veggie', price: '₹549.00/-', variants: 'Variants: No' },
        { dishName: 'Farmhouse', price: '₹459.00/-', variants: 'Variants: Yes(2)' },
        { dishName: 'Peppy Paneer', price: '₹459.00/-', variants: 'Variants: No' },
        { dishName: 'Veg Extravaganza', price: '₹549.00/-', variants: 'Variants: Yes(5)' },
        { dishName: 'Mexican Green Wave', price: '₹459.00/-', variants: 'Variants: Yes(3)' },
        { dishName: 'Cheese n Corn', price: '₹379.00/-', variants: 'Variants: No' },
        { dishName: 'Peppy Paneer', price: '₹459.00/-', variants: 'Variants: Yes(3)' },
        { dishName: 'Paneer Makhani', price: '₹549.00/-', variants: 'Variants: No' },
        { dishName: 'Farmhouse Extraveganza Vaeggie', price: '₹559.00/-', image: pizza2, variants: 'Variants: Yes(3)' },
        { dishName: 'Peppy Paneer', price: '₹459.00/-', variants: 'Variants: Yes(5)' },
        { dishName: 'Veg Extravaganza', price: '₹549.00/-', variants: 'Variants: No' },
        { dishName: 'Mexican Green Wave', price: '₹459.00/-', variants: 'Variants: Yes(2)' },
        { dishName: 'Cheese n Corn', price: '₹379.00/-', variants: 'Variants: No' },
    ];

    const isMobileScreen = IsMobileScreen();

    const determineHeight = () => {
        let height;

        switch (true) {
            case screenWidth < 768:
                height = 'auto';
                break;

            case screenWidth < 1441:
                height = screenHeight - 375;
                break;

            case screenWidth > 2600:
                height = screenHeight - 465;
                break;

            default:
                height = screenHeight - 380;
                break;
        }
        return height;
    };

    return (
        <>
            <div className='pl-6 md:px-4'>
                <div className='flex flex-row justify-between items-start pr-7 lg:pr-5 md:pr-0 md:block pb-4'>
                    {!isMobileScreen && (
                        <div className='flex flex-col lg:pr-2'>
                            <span className='paragraph-large-semi-bold mb-4'>Dishes</span>
                        </div>
                    )}

                    <div className='w-full max-w-[375px] md:max-w-full'>
                        <DefaultInputField
                            placeholder='Search category or dish'
                            placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                        />
                    </div>
                </div>

                {isMobileScreen && (
                    <div className='border-t border-neutral-300 mt-4 pt-4'>
                        <button
                            onClick={() => setShowPopup('mobileCategory')}
                            className='flex flex-row justify-between m-auto w-full paragraph-medium-regular rounded-md focus:outline-none focus:ring-0 font-normal leading-5 appearance-none px-4 py-[14px] border border-primary-500 bg-primary-50 h-12 items-center'>
                            <span className='paragraph-medium-regular text-primary-500 truncate'>
                                Categories - All (102 dishes)
                            </span>
                            <span className='min-w-[24px] ml-2'>
                                <DownArrow
                                    className='drop-down-icon-rotate'
                                    fill={'#6C5DD3'}
                                />
                            </span>
                        </button>
                    </div>
                )}

                <div className=''>
                    {isMobileScreen && (
                        <div className='pt-4 pb-1'>
                            <span className='paragraph-medium-semi-bold'>Pizza</span>
                        </div>
                    )}

                    <div
                        className='-ml-2.5 overflow-auto scrollbar-style mr-0.5 md:m-0 md:pb-24'
                        style={{ height: determineHeight() }}>
                        <div className='paragraph-medium-italic text-neutral-500 ml-2.5'>Pizza</div>
                        {dishes.map((dish, index) => (
                            <div
                                className='inline-block align-top mx-2.5 mt-4 cursor-pointer md:block md:mx-0 md:mt-2 last:mb-2'
                                key={index}>
                                <DishCard
                                    {...dish}
                                    setShowPopup={setShowPopup}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
