import React from 'react';
import HeaderButtons from './HeaderButtons';
import { useWindowSize } from '@uidotdev/usehooks';

export default function Layout(props) {
    const { children } = props;

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 lg:px-4 pt-4 w-full max-w-[1336px] mx-auto bg-white pb-2'
                    style={{ minHeight: screenHeight - 50 }}>
                    {/* <HeaderButtons /> */}
                    {children}
                </div>
            </div>
        </>
    );
}
