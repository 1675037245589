import React from 'react';
import CalenderField from '../../../../Components/Calender/CalenderField';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as FilterIcon } from '../../../../Assets/filter.svg';
import { ReactComponent as ExportIcon } from '../../../../Assets/export.svg';

export default function ContentControllerSection() {
    return (
        <>
            <div className='flex md:block justify-between mb-6 lg:mb-4 md:mb-0'>
                <div className='md:mb-4'>
                    <CalenderField
                        label='25 Sept 2022 - 09 Oct 2022'
                        buttonStyle='md:w-full'
                    />
                </div>

                <div className='flex justify-between'>
                    <div className='w-[120px] mx-4 lg:mx-2 md:ml-0 md:w-full mobile:mr-1'>
                        <LargePrimaryButton
                            leftIconDefault={<FilterIcon fill='#ffffff' />}
                            leftIconClick={<FilterIcon fill='#C4BEED' />}
                            label='Filters'
                        />
                    </div>

                    <div className='w-[161px] md:ml-2 md:w-full mobile:ml-1'>
                        <LargePrimaryButton
                            leftIconDefault={<ExportIcon stroke='#ffffff' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            label='Export data'
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
