import React from 'react';
import { CategoryPage } from './Components/CategoryPage';
import { CartPage } from './Components/CartPage';
import { HomePage, MobileComponents } from './Components/HomePage';

export default function MobileScreenBrand(props) {
    const { mobileScreenPage } = props;

    const componentToMap = {
        category: CategoryPage,
        cart: CartPage,
        home: HomePage,
    };

    const ComponentToRender = componentToMap[mobileScreenPage];

    return (
        <>
            <div className='mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[661px] w-[319px] shadow-xl sticky top-0 mobile:max-w-[250px] mobile:w-full'>
                <MobileComponents />

                <div className='rounded-[2rem] overflow-hidden w-[291px] h-[633px] bg-white px-4 mobile:w-[222px] mobile:px-2'>
                    {ComponentToRender ? <ComponentToRender {...props} /> : <HomePage {...props} />}
                </div>
            </div>
        </>
    );
}
