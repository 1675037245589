import React from 'react';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { Categories } from '../Components/Catalogue/Components/Card';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';

export default function CategorySection(props) {
    const { categoriesDetails } = props;

    const isMobileScreen = IsMobileScreen();
    const screenWidth = useWindowSize().width;
    const screenHeight = useWindowSize().height;
    return (
        <>
            <div className='min-w-[333px] border-r border-neutral-300 pr-0.5 md:pr-0 md:min-w-full md:border-0 md:pb-16'>
                <div className='flex flex-row items-center mb-4'>
                    <span className='mr-1 text-black paragraph-large-medium'>Categories</span>
                    <span className='paragraph-small-italic text-neutral-500'>(Selected - 00) </span>
                </div>

                <div className='flex flex-row items-center mb-[18px]'>
                    <CheckBoxWithoutLabels />
                    <span className='paragraph-small-medium ml-2'>Select/deselect all</span>
                </div>

                <div
                    className='overflow-auto scrollbar-style md:overflow-hidden md:h-full md:mb-4'
                    style={{
                        height: isMobileScreen
                            ? 'auto'
                            : screenWidth < 1440
                              ? screenHeight - 376
                              : screenWidth < 2100
                                ? screenHeight - 412
                                : '616px',
                    }}>
                    {categoriesDetails.map((el, index) => {
                        return (
                            <div
                                className={`${
                                    index !== categoriesDetails.length - 1 && 'mb-2'
                                } pr-3 w-full md:max-w-full md:mt-2 md:pr-0 md:mb-0`}
                                key={index}>
                                <Categories
                                    img={el.img}
                                    title={el.title}
                                    dishes={el.dishes}
                                    minWidth='min-w-[273px] md:min-w-0'
                                    type={el.type}
                                    imageSize='h-[74px] w-[74px]'
                                    isFromSelection={true}
                                    imageMargin='my-2.5'
                                    subCategoryDetails={el.subCategoryDetails}
                                    page='menuManagement'
                                    dropDownCategoryMarginL='ml-2'
                                    dishNumberTextStyle='paragraph-x-small-italic'
                                    titleStyle='paragraph-small-medium mb-1'
                                    contentMarginL='ml-1'
                                    boxPadding='px-3'
                                    dropdownIconSize='max-w-[16px]'
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
