import React, { useState } from 'react';
import PaginationWithNumber from '../../Components/Pagination/PaginationWithNumber';
import LinkOfferPopup from './Popups/LinkOfferPopup';
import { useNavigate } from 'react-router-dom';
import { IsMobileScreen } from '../../Constants/Constants';
import HeaderGroupDetails from './Components/HeaderGroupDetails';
import GroupDetailsTop from './Components/GroupDetailsTop';
import { TableGroupDetails } from './Components/TableList';
import { useWindowSize } from '@uidotdev/usehooks';

export default function CustomerGroupDetails() {
    const navigate = useNavigate();

    const [showLinkOfferPopUp, setShowLinkOfferPopUp] = useState(false);

    const handleClickLinkOffers = () => {
        setShowLinkOfferPopUp(!showLinkOfferPopUp);
    };
    const goToCustomerInformation = () => {
        navigate('/customer-information');
    };

    const screenHeight = useWindowSize().height;
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!isMobileScreen || !showLinkOfferPopUp) && (
                    <div>
                        <div
                            className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                            style={{ minHeight: screenHeight - 50 }}>
                            <GroupDetailsTop goToCustomerInformation={goToCustomerInformation} />
                            <HeaderGroupDetails handleClickLinkOffers={handleClickLinkOffers} />
                            <TableGroupDetails />

                            {!isMobileScreen && (
                                <div className='pt-4'>
                                    <PaginationWithNumber />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {showLinkOfferPopUp && (
                    <LinkOfferPopup
                        handleClickClose={handleClickLinkOffers}
                        selectedOffer={'04'}
                    />
                )}
            </div>
        </>
    );
}
