import React, { useState } from 'react';
import BadgeLibrary from './Components/BadgeLibrary';
import SelectBadge from './Components/SelectBadge';
import { MultipleTab } from '../../../../../Components/Tabs/Tabs';

export default function Badges() {
    const tabName = ['Select badge', 'Badge library'];

    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <div className='max-w-[636px]'>
                <div className='flex flex-row items-center mb-2'>
                    {tabName.map((el, index) => (
                        <div
                            className='mr-4 mb-4 lg:mb-2 md:mr-2'
                            key={index}>
                            <MultipleTab
                                label={el}
                                isActive={index === activeTab}
                                onClick={() => setActiveTab(index)}
                                paddingLeftRight='px-4 mobile:px-3.5'
                            />
                        </div>
                    ))}
                </div>

                {activeTab === 0 ? <SelectBadge /> : <BadgeLibrary />}
            </div>
        </>
    );
}
