import React from 'react';
import ContentLoader from 'react-content-loader';

const RiderDetailsTopLoader = () => {
    return (
        <ContentLoader
            width='1270px'
            height='160px'
            className=' w-fit rounded-md'>
            <rect
                x='0'
                y='15'
                rx='4'
                ry='4'
                width='130'
                height='130'
            />
            <rect
                x='140'
                y='20'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='140'
                y='65'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='140'
                y='110'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            {/* second column */}
            <rect
                x='420'
                y='20'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='420'
                y='65'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='420'
                y='110'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            {/* third column */}
            <rect
                x='420'
                y='20'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='420'
                y='65'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='420'
                y='110'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            {/* fourth column */}
            <rect
                x='700'
                y='20'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='700'
                y='65'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='700'
                y='110'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            {/* last column */}
            <rect
                x='1000'
                y='20'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='1000'
                y='65'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
            <rect
                x='1000'
                y='110'
                rx='4'
                ry='4'
                width='180'
                height='30'
            />
        </ContentLoader>
    );
};

export default RiderDetailsTopLoader;
