import React, { useState } from 'react';
import { SingleLoader } from '../../../../../Components/ContentLoader/DropdownLoader';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { BlankComponent } from '../../../../../Components/BlankComponent/BlankComponent';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';
import SingleListComponent from './SingleListComponent';

export default function MobileList(props) {
    const { isLoading, list, handleStatusChange } = props;

    return (
        <>
            {isLoading ? (
                <SingleLoader className='h-[60px]' />
            ) : !!list?.length ? (
                list?.map((el, index) => (
                    <SingleListComponent
                        key={index}
                        handleStatusChange={handleStatusChange}
                        {...el}
                    />
                ))
            ) : (
                <BlankComponent previewText='No offer at this moment' />
            )}
        </>
    );
}
