import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as WarningIcon } from '../../../Assets/warning.svg';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { WarningAlert } from '../../../Components/Alerts/Alerts';
import { useNavigate } from 'react-router-dom';

export default function EnterTableCustomerDetailsPopup(props) {
    const { handleClickClose, tableName = 'T2' } = props;

    const navigate = useNavigate();

    const handleProceed = () => {
        navigate('/pos?mode=dine-in');
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 overflow-auto z-50 p-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full sm:p-4'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col text-left'>
                            <span className='paragraph-large-medium'>{tableName}</span>
                            <span className='paragraph-medium-italic text-neutral-500'>Maximum Capacity - 6</span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Mobile number'
                            placeholder='Enter mobile number'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Customer name'
                            placeholder='Enter customer name'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Number of guests'
                            placeholder='Enter number of guests'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='mb-12'>
                        <WarningAlert
                            iconStyle='-mt-0.5'
                            alertHeader='Number of guests beyond table capacity.'
                            headerStyle='paragraph-small-regular'
                            action1='Merge tables'
                            action2='Choose another table'
                            action3='Ignore'
                            icon={<WarningIcon stroke='#CB8400' />}
                            action1Style='mb-0.5 border-b-[1.5px] border-secondary-800 paragraph-small-regular'
                            action2Style='mb-0.5 border-b-[1.5px] border-secondary-800 paragraph-small-regular'
                            action3Style='mb-0.5 border-b-[1.5px] border-secondary-800 paragraph-small-regular'
                            shadow='shadow-smallDropDownShadow'
                        />
                    </div>

                    <div
                        className='mb-4'
                        onClick={handleProceed}>
                        <LargePrimaryButton label='Proceed' />
                    </div>

                    <div className='paragraph-small-medium text-primary-500 text-center cursor-pointer'>
                        Skip for now
                    </div>
                </div>
            </div>
        </>
    );
}
