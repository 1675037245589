import { useEffect, useState } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../Buttons/Button';

import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as CashbackIcon } from '../../../Assets/cashback.svg';
import InformationPopup from './InformationPopup';
import SelectOfferPage from '../../../Components/SelectOffer/SelectOfferPage';

export const RefundFooterButton = (props) => {
    const { handleClickPrint, setShowRefundPopup, refundDetails } = props;
    const isDisabled = refundDetails.length > 0 && refundDetails[0] !== undefined;

    return (
        <>
            <div className='flex flex-row pt-12 md:fixed md:bottom-0 md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4 md:-ml-4'>
                <div
                    className='w-1/2 mr-2'
                    onClick={handleClickPrint}>
                    <LargePrimaryButton
                        label='Print acknowledgement'
                        padding='md:px-6'
                    />
                </div>

                <div
                    className='w-1/2 ml-2'
                    onClick={() => setShowRefundPopup(true)}>
                    <LargePrimaryButton
                        label='Refund'
                        disabled={isDisabled}
                    />
                </div>
            </div>
        </>
    );
};

export const Items = (props) => {
    const {
        quantity,
        food_type,
        price,
        item,
        promotional_quantity,
        variant_option_information,
        customizations,
        selectedLangaugeId,
    } = props;

    const [icon, setIcon] = useState(<VegIcon />);
    const [displayedQuantity, setDisplayedQuantity] = useState(quantity);

    useEffect(() => {
        food_type === 'vegetarian' ? setIcon(<VegIcon />) : setIcon(<NonVegIcon />);
        if (promotional_quantity === 1) {
            setDisplayedQuantity('');
        }
    }, [food_type, promotional_quantity]);

    const totalPrice = quantity > 1 ? price * quantity : price;

    const convertToCurrency = totalPrice.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });

    const dishAmount =
        promotional_quantity === 1 ? (
            <span style={{ textDecoration: 'line-through', color: 'grey' }}>{convertToCurrency}</span>
        ) : (
            <span>{convertToCurrency}</span>
        );

    const groupedCustomizations = customizations.reduce((acc, curr) => {
        const groupName = curr.translations.group_name[selectedLangaugeId];
        if (!acc[groupName]) {
            acc[groupName] = [];
        }
        acc[groupName].push(curr.translations.name[selectedLangaugeId]);
        return acc;
    }, {});

    return (
        <>
            <div className='flex flex-row justify-between paragraph-medium-regular mt-1.5 pb-1.5 last:pb-0'>
                <div className='flex flex-col'>
                    <div className='flex flex-row items-center'>
                        {displayedQuantity && <span className='mr-2 whitespace-nowrap'>{displayedQuantity} X</span>}
                        <div>
                            {item?.translations?.title[selectedLangaugeId]}
                            <span className='ml-1 inline-block align-middle'>{icon}</span>
                        </div>
                    </div>
                    {variant_option_information && (
                        <div>
                            <div className='text-sm text-gray-500'>
                                Variant: {variant_option_information?.translations?.title[selectedLangaugeId]}
                            </div>
                        </div>
                    )}
                    {Object.entries(groupedCustomizations).map(([groupName, titles]) => (
                        <div
                            key={groupName}
                            className='flex flex-row'>
                            <div className='text-sm text-gray-500'>
                                {groupName !== 'undefined' ? groupName : 'Group Name'}: {titles.join(', ')}
                            </div>
                        </div>
                    ))}
                </div>
                <span className='min-w-[114px] text-right self-start'>
                    {promotional_quantity === 1 && (
                        <span className='text-black-500 bg-secondary-600 p-1 rounded ml-2 mr-2'>Free</span>
                    )}
                    {dishAmount}
                </span>
            </div>
        </>
    );
};

export const Paid = () => {
    return (
        <>
            <span className='font-medium text-[10px] leading-3 px-2 py-1 border-success-600 text-success-600 bg-success-50 border rounded ml-2'>
                Paid
            </span>
        </>
    );
};

export const UnPaid = () => {
    return (
        <>
            <span className='font-medium text-[10px] leading-3 px-2 py-1 border-destructive-600 text-destructive-600 bg-destructive-100 border rounded ml-2'>
                Unpaid
            </span>
        </>
    );
};

export const OtherChargesDetails = (props) => {
    const { type, value, extraCharges } = props;
    const [showPopup, setShowPopup] = useState(false);

    const convertToCurrency = value?.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    });

    const amount = `${convertToCurrency}/-`;

    return (
        <div className='relative flex flex-row justify-between items-center w-full'>
            <span className='flex items-center relative'>
                <span className='mr-1'>{type}</span>
                {type === 'Other charges & taxes' && (
                    <div
                        className='flex items-center justify-center h-4 w-4 border border-black rounded-full cursor-pointer mt-1'
                        onMouseEnter={() => setShowPopup(true)}
                        onMouseLeave={() => setShowPopup(false)}>
                        <span className='text-xs font-bold'>i</span>
                        {showPopup && <InformationPopup extraCharges={extraCharges} />}
                    </div>
                )}
            </span>
            <span>₹{amount}</span>
        </div>
    );
};

export const Cashbacks = (props) => {
    const { redeemedAmount, earnedAmount } = props;

    return (
        <>
            <div className='border-b border-neutral-300 pb-4 mb-4'>
                <div className='flex flex-row items-center justify-between mb-4'>
                    <div className='flex flex-row items-center'>
                        <CashbackIcon />
                        <span className='ml-1 paragraph-medium-medium'>Cashback redeemed</span>
                    </div>
                    {redeemedAmount !== undefined ? <span>{redeemedAmount}</span> : <span>₹0.00</span>}
                </div>

                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        <CashbackIcon />
                        <span className='ml-1 paragraph-medium-medium'>Cashback earned</span>
                    </div>

                    {earnedAmount !== undefined ? <span>{earnedAmount}</span> : <span>₹0.00</span>}
                </div>
            </div>
        </>
    );
};

export const OrderRatingFooterButton = (props) => {
    const {
        handleClickSelectOffer,
        showOfferpage,
        handleConfirmDeletePopup,
        grossTotal,
        setCreateOfferDetails,
        handleOfferCreate,
        createOfferDetails,
        offerId,
        toggleCustomerOfferPopup,
        fetchOfferDetails,
        viewOfferId,
    } = props;

    return (
        <>
            <div className='flex flex-row mt-4 pt-[34px] md:fixed md:bottom-0 md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4 md:-ml-4'>
                <div
                    className='w-1/2 mr-2'
                    onClick={handleConfirmDeletePopup}>
                    <LargeDestructiveButton label='Delete Review' />
                </div>

                {!offerId ? (
                    <div
                        className='w-1/2 ml-2'
                        onClick={() => {
                            handleClickSelectOffer();
                        }}>
                        <LargePrimaryButton label='Select Offer' />
                    </div>
                ) : (
                    <div
                        className='w-1/2 ml-2'
                        onClick={() => {
                            toggleCustomerOfferPopup();
                            fetchOfferDetails(offerId);
                        }}>
                        <LargePrimaryButton label='View Offer' />
                    </div>
                )}
            </div>

            {showOfferpage && (
                <SelectOfferPage
                    handleClickClose={handleClickSelectOffer}
                    module={'order-rating'}
                    setCreateOfferDetails={setCreateOfferDetails}
                    grossTotal={grossTotal}
                    handleOfferCreate={handleOfferCreate}
                    createOfferDetails={createOfferDetails}
                />
            )}
        </>
    );
};
