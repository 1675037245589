import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';

export default function Header(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <div className='flex flex-row justify-between items-center mb-6'>
                    <div className=''>
                        <span className='paragraph-large-medium'>Create notification</span>
                        <p className='paragraph-medium-italic text-neutral-500'>Send Notification to your customers</p>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickClose}>
                        <CloseIcon />
                    </div>
                </div>
            ) : (
                <div
                    className='flex flex-row mb-4 pt-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <div className='w-6 h-6'>
                        <LeftArrowIcon className='rotate-90' />
                    </div>
                    <span className='ml-1 paragraph-medium-medium'>Back to notification</span>
                </div>
            )}
        </>
    );
}
