import React, { useEffect, useState } from 'react';
import PhoneInputDropdown from '../../../../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { IsIpadScreen } from '../../../../../Constants/Constants';
import Layout from '../Components/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import { InputField } from '../../../../Login/Components/Components';
import { countryCodes } from '../../../../../Components/MobileNumberInputFlagDropDown/CountryData';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import { ValidateUserPhoneNumber } from '../../../Components/ValidateUserPhoneNumber';
import Loader from './Components/Loader';

export default function Contacts(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const isIpadScreen = IsIpadScreen();

    const methods = useFormContext();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isDirty },
        watch,
        reset,
    } = methods;

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateContact = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=contacts`, { ...params });

            if (response.success) {
                reset();
                setShowFooter(false);
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatData = (data) => {
        return {
            translations: data.translations,
            contact_number: data.contact_number,
            country_code: '+91',
            selectedCountryNumber: {
                dialCode: '91',
                number: data.contact_number,
            },
        };
    };

    const onSubmit = (data) => {
        updateContact(formatData(data.contacts));
        setShowTabChangeWarningPopup(false);
    };

    useEffect(() => {
        if (!watch('contacts.translations.name')) return;

        Object.keys(watch('contacts.translations.name'))?.forEach((languageId) =>
            setValue(`contacts.translations.name[${languageId}]`, watch('contacts.translations.name')[languageId])
        );

        // eslint-disable-next-line
    }, [watch('contacts.translations.name')]);

    const [isShowMobileNumberPopup, setIsShowMobileNumberPopup] = useState(false);

    const [countryData, setCountryData] = useState(
        countryCodes.find((country) => '+' + country.code === (watch('country_code') ?? '+91'))
    );

    const [isValidInput, setIsValidInput] = useState(true);

    const [loaderStatus, setLoaderStatus] = useState(false);

    const handleVarifiedMobileNumber = (varifiedId) => {
        setValue('contacts.varifiedNumberId', varifiedId);

        setValue('contacts.contact_number', watch('contacts.enteredMobileNumber'));
    };

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty || showFooter}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <TitleDescription
                    className='mb-1'
                    title='Outlet name'
                    description='Name your outlet for customers to identify it quickly.'
                />

                <div className='paragraph-medium-italic text-neutral-500 mb-4'>
                    <span className='text-neutral-900'>NOTE: </span>
                    It is always better to name your outlet based on the area/locality.
                </div>

                <div className='flex flex-row items-start mb-4 lg:block gap-3'>
                    {availableLanguages.map((el, index) => (
                        <div
                            className='w-full lg:mb-2 lg:max-w-[312px] md:max-w-full md:mr-0 relative'
                            key={index}>
                            <InputSection
                                name={`contacts.translations.name.${el.language_id}`}
                                labelMarginB='mb-2'
                                label={`(${el.language_label})`}
                                labelStyle='paragraph-medium-italic text-neutral-500'
                                placeholder={`Enter outlet name in ${el.language_label}`}
                                shadow='shadow-smallDropDownShadow'
                                rules={{ required: 'Please select a name' }}
                                dontShowError
                                handleInputChange={() => setShowTabChangeWarningPopup(true)}
                            />
                            {errors?.contacts?.translations?.name?.[el.language_id] && (
                                <ErrorMessage
                                    errors={errors?.contacts?.translations?.name?.[el.language_id]}
                                    className='absolute -bottom-5'
                                />
                            )}
                        </div>
                    ))}
                </div>

                <TitleDescription
                    className='mb-4'
                    title='Contact number'
                    description='The contact number of your outlet is for your customers to contact the outlet for any help directly.'
                />

                <div className='w-1/2 ml-2 relative lg:w-full lg:ml-0 remove-spin-button'>
                    <div
                        className=''
                        onClick={() => setIsShowMobileNumberPopup(true)}>
                        <Controller
                            render={({ field }) => (
                                <InputField
                                    placeholder='Enter mobile number'
                                    paddingLeft='pl-[105px]'
                                    inputType='number'
                                    shadow='shadow-smallDropDownShadow'
                                    setValue={setValue}
                                    isMobileNumberPage={true}
                                    isValidInput={true}
                                    inputValue={watch('contacts.contact_number')}
                                />
                            )}
                            name='contacts.contact_number'
                            control={control}
                            rules={{ required: 'mobile number is required' }}
                        />

                        <div className='absolute top-9 left-4'>
                            <PhoneInputDropdown
                                value={countryData?.code}
                                disabled={true}
                                countryData={countryData}
                                setCountryData={setCountryData}
                                openFromBottom={isIpadScreen ?? true}
                            />
                        </div>
                    </div>

                    {errors?.validatedNumber && <ErrorMessage errors={errors?.validatedNumber} />}

                    {isShowMobileNumberPopup && (
                        <ValidateUserPhoneNumber
                            setIsShowMobileNumberPopup={setIsShowMobileNumberPopup}
                            watch={watch}
                            mobileNumber={watch('contacts.enteredMobileNumber')}
                            otp={watch('contacts.otp')}
                            isValidInput={isValidInput}
                            setIsValidInput={setIsValidInput}
                            countryData={countryData}
                            setCountryData={setCountryData}
                            methods={methods}
                            loaderStatus={loaderStatus}
                            setloaderStatus={setLoaderStatus}
                            selectedRestaurantId={selectedRestaurantId}
                            setMobileNumber={(id, number) => handleVarifiedMobileNumber(id, number)}
                            mobileNumberFIeldName='contacts.enteredMobileNumber'
                            otpFieldName='contacts.otp'
                            setShowFooter={(value) => {
                                setShowFooter(value);
                                setShowTabChangeWarningPopup(true);
                            }}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}
