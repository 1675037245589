import React from 'react';
import MobileScreenAppSetting from '../../../../../Components/MobileViewScreen/MobileScreenAppSetting';
import { useWindowSize } from '@uidotdev/usehooks';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../Components/InputSection';
import DropDownUnableDisableSection from '../../../Components/DropDownUnableDisableSection';
import Layout from '../../../Components/Layout';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function AppSetting(props) {
    const { fetchSettings, fetchlanguageList, handleDiscardForm } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, errors, isValid },
    } = useFormContext();

    const screenSize = useWindowSize();

    const availableLangauge = useSelector((state) => state.langauge.availableLanguages);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const formatData = (data) => {
        const formattedData = {
            app_name: data.app_name,
            name: data.app_name,
            is_customer_email_required: data.is_customer_email_required,
            apply_mask: data.apply_mask,
            repeat_usage_time: data.repeat_usage_time,
            translations: {
                restaurant_name: {},
            },
        };

        data.restaurant_name.forEach((value, index) => {
            if (value !== null) {
                formattedData.translations.restaurant_name[index.toString()] = value;
            }
        });

        return formattedData;
    };

    const updateAppSettings = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=app_settings`, { ...params });
            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = (data) => {
        updateAppSettings(formatData(data.app_settings));
    };

    return watch('isLoadingForm') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => handleDiscardForm()}
            showFooter={isDirty}>
            <div className='flex flex-row xl:block -mb-20 xl:mb-0'>
                <div className='pr-0.5 border-r border-neutral-300 w-full xl:pr-0 xl:border-r-0 md:-mx-1 md:w-auto'>
                    <div
                        className='min-w-[647px] w-full xl:min-w-full xl:pb-2 xl:border-b overflow-auto scrollbar-style pr-2 pb-8 xl:overflow-hidden pageContent:min-w-0 pl-1 md:px-1'
                        style={{
                            height: !isDirty
                                ? '100%'
                                : !(screenSize.width < 1280)
                                  ? screenSize.height < 905
                                      ? '100%'
                                      : screenSize.height - 244
                                  : '100%',
                        }}>
                        <div className='mb-2'>
                            <TitleDescription
                                title='Restaurant name'
                                description='Please enter the name of your restaurant/brand you want your customers to see in various places.'
                            />
                        </div>

                        <div className='flex flex-row items-center mb-4 lg:block'>
                            {availableLangauge.map((el, index) => (
                                <div
                                    className='mr-1.5 w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full lg:mr-0 relative'
                                    key={index}>
                                    <InputSection
                                        inputValue={watch(`app_settings.restaurant_name.${el.language_id}`)}
                                        name={`app_settings.restaurant_name.${el.language_id}`}
                                        label={`(${el.language_label})`}
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                        placeholder={`Enter restaurant name in ${el.language_label}`}
                                        shadow='shadow-smallDropDownShadow'
                                        rules={{ required: 'Please fill this field' }}
                                    />
                                    {errors?.app_settings?.restaurant_name?.[el.language_id] && (
                                        <ErrorMessage
                                            className='absolute -bottom-5'
                                            errors={errors?.app_settings.restaurant_name?.[el.language_id]}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className='mb-4'>
                            <InputSection
                                title='Application display name'
                                description='Please enter the display name of your restaurant that you want your customers to see in various parts of the application.'
                                name='app_settings.app_name'
                                boxHeight='12'
                                placeholder='Enter application display name'
                                inputValue={watch(`app_settings.app_name`)}
                            />
                        </div>

                        <DropDownUnableDisableSection
                            className='mb-4'
                            name='app_settings.is_customer_email_required'
                            title='Enable customer email compulsory'
                            description='Enabling this will make customers input their email id compulsorily.'
                        />

                        <DropDownUnableDisableSection
                            className='mb-4'
                            name='app_settings.apply_mask'
                            title='Apply mask on category images'
                            description='Enabling this will mask the images you upload to the categories for a better look and view.'
                        />

                        <InputSection
                            title='Offer usage time'
                            description='Set Minutes, after which a second offer can apply.'
                            placeholder='Enter time in minutes'
                            name='app_settings.repeat_usage_time'
                            inputType='number'
                        />
                    </div>
                </div>

                <div className='w-full flex justify-center pageContent:max-w-[370px] pageContent:mt-3 pageContent:pb-8 pageContent:h-full pageContentLarge:overflow-auto pageContent:mx-auto'>
                    <MobileScreenAppSetting
                        isApplyMask={watch('app_settings.apply_mask')}
                        hideTile={true}
                    />
                </div>
            </div>
        </Layout>
    );
}
