import React, { useState } from 'react';
import AddonSelectionComp from './AddonSelectionComp';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import TablesComponent from './TablesComponent';
import { ReactComponent as ReArrangeIcon } from '../../../../../../Assets/re-arrange.svg';

export default function SingleVariantWiseTableComp(props) {
    const { selectionVariantId } = props;

    const [editTableLayout, setEditTableLayout] = useState(false);

    return (
        <>
            <AddonSelectionComp selectionVariantId={selectionVariantId} />

            <div
                className='mb-4 max-w-fit'
                onClick={() => setEditTableLayout(!editTableLayout)}>
                <LargePrimaryButton
                    label='Re-arrange'
                    leftIconDefault={<ReArrangeIcon stroke='#FFFFFF' />}
                    leftIconClick={<ReArrangeIcon stroke='#C2BEED' />}
                />
            </div>

            <TablesComponent
                selectionVariantId={selectionVariantId}
                editTableLayout={editTableLayout}
            />
        </>
    );
}
