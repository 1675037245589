import React, { useState } from 'react';
import { ReactComponent as SwiggyIcon } from '../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../Assets/zomato.svg';
import OrderCard from './Components/OrderCard';
import Header from './Components/Header';
import { IsIpadScreen, IsMobileScreen } from '../../Constants/Constants';
import DishesSummery from './Components/DishesSummery';
import { useDispatch } from 'react-redux';
import { TOGGLE_FULL_SCREEN } from '../../reduxStore/types/fullScreen';

export default function KitchenDisplaySystem() {
    const dispatch = useDispatch();

    const orders = [
        {
            icon: (
                <SwiggyIcon
                    height={48}
                    width={48}
                />
            ),
            orderingMode: 'Delivery order',
            orderLabel: '#BBQA',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    note: 'Keep the pizza loaded with ample amount of cheese',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    isPrepared: true,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
            ],
            timeElapsed: [
                {
                    mins: 15,
                },
                {
                    seconds: 16,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            orderLabel: '#BBQA',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    isPrepared: true,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 2,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
            ],
            timeElapsed: [
                {
                    mins: 10,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            orderLabel: '#BBQA',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
            ],
            timeElapsed: [
                {
                    mins: 5,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            orderLabel: '#BBQA',
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            icon: <ZomatoIcon />,
            orderingMode: 'Delivery order',
            orderLabel: '#BBQA',

            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            orderLabel: '#BBQA',
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            icon: (
                <SwiggyIcon
                    height={48}
                    width={48}
                />
            ),
            orderingMode: 'Delivery order',
            orderLabel: '#BBQA',

            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            orderLabel: '#BBQA',
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
    ];

    const [showDishesSummery, setShowDishesSummery] = useState(false);

    const [showFullScreen, setShowFullScreen] = useState(false);

    const handleClickMaximize = () => {
        dispatch({
            type: TOGGLE_FULL_SCREEN,
            payload: !showFullScreen,
        });
        setShowFullScreen(!showFullScreen);
    };

    const isMobileScreen = IsMobileScreen();

    const isIpadScreen = IsIpadScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div className={`w-full ${!showFullScreen ? 'max-w-[1440px]' : 'max-w-[1920px]'} mx-auto bg-white`}>
                    {(!showDishesSummery || !isMobileScreen) && (
                        <Header
                            handleClickDishesSummery={() => setShowDishesSummery((prevState) => !prevState)}
                            handleClickMaximize={handleClickMaximize}
                        />
                    )}

                    <div
                        className={`flex flex-row min-h-screen pl-[22px] lg:pl-3 md:pl-4 md:pr-4 lg:pb-4 md:block ${
                            !showDishesSummery && 'pr-[22px] lg:pr-3'
                        }`}>
                        {(!showDishesSummery || !isMobileScreen) && (
                            <div className={`md:contents pb-6 ${showDishesSummery && 'lg:w-1/2'}`}>
                                {orders.map((el, index) => (
                                    <div
                                        className={`w-[329px] 2xlBig:w-[326px] mx-2.5 inline-block align-top 2xl:mx-0 2xl:w-1/3 pageContentSmall:w-1/2 2xl:min-w-0 2xl:px-2.5 lg:px-1 pt-6 lg:pt-4 md:w-full md:px-0 lg:min-w-0 ${
                                            showDishesSummery ? 'lg:w-full' : 'lg:w-1/2'
                                        }`}
                                        key={index}>
                                        <OrderCard
                                            KOT={index + 1}
                                            {...el}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {showDishesSummery && (
                            <div className='sticky lg:w-1/2 md:w-full border-l border-neutral-300 ml-1 3xl:ml-2 md:border-none md:ml-0'>
                                <div
                                    className={`min-w-[362px] pr-[18px] md:border-0 md:ml-0 md:pr-0 md:min-w-0 lg:min-w-0 sticky lg:w-full ${
                                        showFullScreen ? (isIpadScreen ? 'top-6' : 'top-3') : 'top-[60px]'
                                    }`}>
                                    <DishesSummery
                                        handleClickBack={() => setShowDishesSummery((prevState) => !prevState)}
                                        showFullScreen={showFullScreen}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
