import React from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../../Components/InputSection';
import InputAreaSection from '../../../Components/InputAreaSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function BillSetting(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateOrderingMode = async (params) => {
        setValue('isFormLoading', true);

        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=bill_settings`, { ...params });

            if (response.success) {
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setValue('isFormLoading', false);
        }
    };

    const onSubmit = (data) => {
        updateOrderingMode(data.bill_settings);
        setShowTabChangeWarningPopup(false);
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <InputSection
                    name='bill_settings.legal_name'
                    title='Legal name'
                    description='Enter the legal name of your outlet that will be printed on the bill.'
                    rules={{ required: 'Please fill this field' }}
                    placeholder='Enter legal name'
                    shadow='shadow-smallDropDownShadow'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    className='mb-4'
                    marginBetween='mb-2'
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                <InputAreaSection
                    maxWidth='max-w-[636px] w-full md:max-w-full'
                    name='bill_settings.bill_header'
                    placeholder='Enter bill header'
                    shadow='shadow-smallDropDownShadow'
                    title='Bill header'
                    description='Enter the bill header for your outlet that will be printed on the bill.'
                    className='mb-4'
                    marginBetween='mb-2'
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                <InputAreaSection
                    maxWidth='max-w-[636px] w-full md:max-w-full'
                    name='bill_settings.bill_footer'
                    placeholder='Enter bill footer'
                    shadow='shadow-smallDropDownShadow'
                    title='Bill footer'
                    description='Enter the bill footer for your outlet that will be printed on the bill.'
                    className='mb-4'
                    marginBetween='mb-2'
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />
            </div>
        </Layout>
    );
}
