import React from 'react';
import ContentLoader from 'react-content-loader';

export default function ListViewLoader(props) {
    const { hasSwitch } = props;

    return (
        <div className='w-full my-2 h-full border border-neutral-300 rounded-lg'>
            <div className='flex'>
                {hasSwitch && (
                    <ContentLoader
                        width='70'
                        height='100%'
                        className='h-[70px]'>
                        <rect
                            x='12'
                            y='20'
                            rx='12'
                            ry='12'
                            width='45'
                            height='24'
                        />
                    </ContentLoader>
                )}

                <div className='flex justify-between w-full mr-3'>
                    <ContentLoader
                        width='150px'
                        height='100%'
                        className='h-[70px]'>
                        <rect
                            x='15'
                            y='12'
                            rx='6'
                            ry='6'
                            width='100'
                            height='18'
                        />
                        <rect
                            x='15'
                            y='40'
                            rx='6'
                            ry='6'
                            width='140'
                            height='18'
                        />
                    </ContentLoader>

                    <ContentLoader
                        height='100%'
                        className='h-[70px] w-[35px]'>
                        <rect
                            x='0'
                            y='20'
                            rx='8'
                            ry='8'
                            width='35'
                            height='24'
                        />
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
}
