import React from 'react';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';
import InputAreaSection from '../../../../../Components/InputField/InputAreaSection';

export default function NCBTab(props) {
    const { setAmountDetails, setTabWiseAmount } = props;

    const { watch } = useFormContext();

    const handleChangeStatus = (selection) => {
        if (!!selection.value) {
            setAmountDetails((prevState) => ({
                ...prevState,
                customerPaid: null,
                totalRemaining: null,
                returnCustomer: null,
            }));

            setTabWiseAmount({ cash: null, upi: null, card: null });
        }
    };

    return (
        <>
            <div className='mb-4'>
                <DropdownSection
                    maxWidth='max-w-[375px]'
                    name='isBillNcb'
                    label='Bill NCB?'
                    menuItems={preferenceMenuItem}
                    handleSelection={(selection) => handleChangeStatus(selection)}
                />
            </div>

            <InputAreaSection
                maxWidth='max-w-[375px]'
                name='ncbName'
                placeholder='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cumque aperiam odit officiis?'
                label='Lorem, ipsum dolor.'
                shadow='shadow-xSmall'
                disabled={!watch('isBillNcb')?.value}
                labelStyle={`paragraph-small-medium ${!watch('isBillNcb')?.value && 'text-neutral-300'}`}
            />

            <div className='md:px-4 md:pb-6 w-full mt-[180px] md:fixed md:bottom-0 md:-ml-4 md:bg-white md:shadow-inner md:pt-4'>
                <LargePrimaryButton label='Save' />
            </div>
        </>
    );
}
