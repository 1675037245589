import APIV2 from '../../../api/axios/APIV2';

export const fetchOutletwiseOffer = async (params, selectedRestaurantId, selectedOutletId) => {
    try {
        const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/offers`, {
            params,
        });

        return response;
    } catch (error) {
        console.log(error);
    }
};
