import React, { useState } from 'react';

export default function UpSellCurrentDish(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };
    return (
        <>
            <div
                className={`pt-4 px-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex pb-4'>
                    <span className='paragraph-medium-medium'>Up-sell current dish in cart</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className='pb-4 paragraph-small-regular'>
                        {!!dishDetails.upselling_item ? (
                            <span>Yes, show this item in cart upselling carousal</span>
                        ) : (
                            <span>No, don't show this item in cart upselling carousal</span>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
