import React, { useState } from 'react';
import { IsMobileScreen } from '../../../../../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../../../../Assets/chevron-down.svg';
import { fornatOrderName } from '../../../../../../Constants/function';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../../../../../../Components/InputField/InputField';
import { useFormContext } from 'react-hook-form';

export default function EditingPopup(props) {
    const { editingDetails, handleClickClose, setShowTabChangeWarningPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const { watch, setValue } = useFormContext();

    const [enterValue, setEnterValue] = useState(
        watch(`rider_setting.rider_auto_assignment.edittingTime[${editingDetails.index}]`)
    );

    const handleClickSave = () => {
        setValue(`rider_setting.rider_auto_assignment.edittingTime[${editingDetails.index}]`, enterValue);
        setShowTabChangeWarningPopup(true);
        handleClickClose();
    };

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 md:p-0 md:absolute md:z-[9] overflow-auto md:overflow-visible'>
            <div className='max-w-[500px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:mt-0 md:w-full md:p-4 lg:max-w-[439px] md:max-w-full md:rounded-none'>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center justify-between mb-6'>
                        <div>
                            <h3 className='paragraph-large-medium'>Back to rider setting</h3>
                            <p className='paragraph-medium-italic text-neutral-500'>Edit waiting time of rider</p>
                        </div>
                        <span
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </span>
                    </div>
                ) : (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={handleClickClose}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to rider setting</span>
                        <p className='paragraph-medium-italic text-neutral-500'>Edit waiting time of rider</p>
                    </div>
                )}

                <div className='flex flex-row items-center mb-6'>
                    <span className='paragraph-small-medium mr-3'>Rider type :</span>
                    <span className='paragraph-small-regular'>{fornatOrderName(editingDetails.service_name)}</span>
                </div>

                <DefaultInputField
                    value={watch(`rider_setting.rider_auto_assignment.edittingTime[${editingDetails.index}]`)}
                    enteredValue={setEnterValue}
                    label='Edit waiting time in minuts'
                    placeholder='Please enter waiting time'
                />

                <div className='flex flex-row mt-12 md:bottom-0 md:fixed md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4'>
                    <div
                        className='w-1/2 mr-[9.5px]'
                        onClick={handleClickClose}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='w-1/2 ml-[9.5px]'
                        onClick={handleClickSave}>
                        <LargePrimaryButton label='Update waitintg time' />
                    </div>
                </div>
            </div>
        </div>
    );
}
