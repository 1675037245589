import React, { useEffect, useState } from 'react';
import UploadImageHeader from './UploadImageHeader';
import { useSelector } from 'react-redux';
import APIV5 from '../../api/axios/APIV5';
import ImageWithForm from './ImageWithForm';
import Loader from './Loader';
import _ from 'lodash';

export default function UploadImagePopup(props) {
    const { setUploadedImageDetails, handleClickClose, fetchData, backToPage, id, selectedType, aspectRatio } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [showEditImage, setShowEditImage] = useState(false);

    const [imageDetails, setImageDetails] = useState({});

    const fetchImageDetails = async () => {
        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/uploads/${id}`);
            setImageDetails(response.image);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (typeof id === 'number') {
            fetchImageDetails();
        }
    }, []);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative px-8 py-4 md:py-0 md:px-0'>
                <div
                    className={`max-w-[1123px] lg:max-w-[701px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-4 m-auto md:max-w-full relative md:py-4 md:h-full h-fit ${
                        !showEditImage && 'lg:max-h-max lg:h-auto md:max-h-full'
                    }`}>
                    <UploadImageHeader
                        handleClickClose={handleClickClose}
                        showBackButton={!showEditImage}
                        backToPage={backToPage}
                    />
                    {typeof id === 'number' && _.isEmpty(imageDetails) ? (
                        <Loader />
                    ) : (
                        <ImageWithForm
                            handleClickClose={handleClickClose}
                            backToPage={backToPage}
                            setShowEditImage={setShowEditImage}
                            setUploadedImageDetails={setUploadedImageDetails}
                            fetchData={fetchData}
                            imageDetails={imageDetails}
                            showEditImage={showEditImage}
                            isEditSection={typeof id === 'number'}
                            selectedType={selectedType}
                            aspectRatio={aspectRatio}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
