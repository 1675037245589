import { ReactComponent as CashIcon } from '../../Assets/cash.svg';
import { ReactComponent as UpiIcon } from '../../Assets/upi.svg';
import { ReactComponent as CardIcon } from '../../Assets/card.svg';
import { ReactComponent as NetBankingIcon } from '../../Assets/net-banking.svg';
import { format, parseISO } from 'date-fns';

export const getPaymentMethodIcon = (method) => {
    let icon;

    switch (method) {
        case 'BALANCE':
            icon = <CashIcon />;
            break;
        case 'UPI':
            icon = <UpiIcon />;
            break;
        case 'NET_BANKING':
            icon = <NetBankingIcon />;
            break;
        default:
            icon = <CardIcon />;
    }

    return icon;
};

export const formatPaymentMethodType = (method) => {
    const formattedStr = method
        .replace(/_/g, ' ')
        .split('/')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('/');

    return formattedStr;
};

export const formatDate = (date, type) => {
    return type === 'date' ? format(parseISO(date), 'dd MMM yyyy') : format(parseISO(date), 'hh:mm a');
};

const createBillFormat = (order) => {
    let customizationTotal;
    let start = `
        <div
            id='printInvoice'
            style="
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                font-family: sans-serif;
                font-size: 12px;
            "
            key={{order.order_id}}
        >
            <EuiFlexGroup style="width: 100%; margin: 15px;">
              
                    <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
                        <span style={{ fontSize: '16px' }}>
                            <b>{restaurantName}</b>
                        </span>
                    </div>
                <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
                    <span>{order?.outlet?.translations?.name[languageId]}</span>
                </div>
                <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
                    <span>
                    </span>
                </div>
                
                <hr />
              
                <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">{orderingMode(
                    order
                )}</div>
                <hr />
                <div>
                    <b>Name: </b> {order.customer.name}
                </div>
              
                
                    
                </div>
               <div>
                    <b>Email: </b> {order.customer.email}
                </div>
                <div>{customerAddress(order)}</div>
                
                <hr />

                <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
                    <b>Item(s)</b>
                </div>
                <hr />`;

    let content = '';
    order.cart.cart_items.map((orderItem) => {
        customizationTotal = 0;
        content += `
                <div key={htmlIdGenerator()()}>
                    
               
                    <br />
                </div>
        <hr />
        <div style="justify-content: space-between; flex-direction: row; display: flex ">
            <span>Gross total</span>
            <span>
                ₹
                {order.gross_total.toFixed(2)}
            </span>
        </div>

        <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>Rewards</span>
                <span>
                    ₹
                    {order.cart.reward_points.used_reward_points.value}
                </span>
            </div>
       
            <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>Discount</span>
                <span>
                    ₹
                    {order.discount_amount ? order.discount_amount.toFixed(2) : ''}
                </span>
            </div>
    
            <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>GST</span>
                <span>
                ₹
                    {order.gst_amount}
                </span>
            </div>
       
            <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>Packaging Charge</span>
                <span>
                ₹
                    {order.cart.packaging_charge.toFixed(2)}
                </span>
            </div>
     
            <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>Convenience Charge</span>
                <span>
                    ₹
                    {order.cart.convenience_charge}
                </span>
            </div>
       
  
            <div style="justify-content: space-between; flex-direction: row; display: flex ">
                <span>Delivery Charge</span>
                <span>
                    ₹
                    {order.cart.delivery_charge}
                </span>
            </div>
       
        <hr />
        <div style="justify-content: space-between; flex-direction: row; display: flex ">
            <b>Total</b>
            <b>
                
                ₹
                {order.net_total.toFixed(2)}
            </b>
        </div>
        <hr />
        <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
            Thank you.
        </div>
        <div style="justify-content: center; align-items: center; display: 'flex'; text-align: center ">
            <b>Powered by Fab Systems</b>
        </div>
    </EuiFlexGroup>
</div>`;

        return start + content;
    });
};
