import React from 'react';
import { ReactComponent as ReArrangeIcon } from '../../../Assets/re-arrange.svg';
import SelectionButton from './SelectionButton';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import APIV2 from '../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function OutletOfferControllerButton(props) {
    const {
        fetchOutletWiseOffersList,
        activeState,
        setActiveState,
        selectedOffer,
        setSelectedOffer,
        isDisableButtons,
    } = props;

    const toggleRearrange = () => {
        setSelectedOffer([]);

        setActiveState((prevState) => {
            return { reArrange: !prevState.reArrange, edit: false, selection: false };
        });
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleClickDelete = async () => {
        try {
            const response = await APIV2.delete(`restaurants/${selectedRestaurantId}/offers`, {
                params: {
                    offer_ids: selectedOffer,
                },
            });

            if (response.success) {
                fetchOutletWiseOffersList();
                handleCancel();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCancel = () => {
        setSelectedOffer([]);

        setActiveState((prevState) => {
            return { selection: !prevState.selection, edit: false, reArrange: false };
        });
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <section className='flex flex-row items-center justify-between mb-6 md:mb-4'>
                <SelectionButton
                    setIsSelectionEnable={(value) =>
                        setActiveState({ selection: value, edit: false, reArrange: false })
                    }
                    isSelectionEnable={activeState.selection}
                    handleClickDelete={handleClickDelete}
                    isDisable={selectedOffer.length === 0}
                    handleClickCancel={handleCancel}
                    styles={!activeState.selection && 'md:w-1/2'}
                    shoBulkSelectLabelMobile
                    disabled={isDisableButtons}
                />

                {!(activeState.selection && isMobileScreen) && (
                    <div
                        className='max-w-[159px] md:max-w-full md:w-1/2 md:ml-2'
                        onClick={toggleRearrange}>
                        <LargePrimaryButton
                            label='Re-arrange'
                            leftIconDefault={<ReArrangeIcon stroke='#ffffff' />}
                            leftIconClick={<ReArrangeIcon stroke='#C4BEED' />}
                            isClicked={activeState.reArrange}
                            disabled={isDisableButtons}
                        />
                    </div>
                )}
            </section>
        </>
    );
}
