import React, { useState } from 'react';
import { ReactComponent as UserIcon } from '../../../Assets/user.svg';
import { ReactComponent as CallIcon } from '../../../Assets/call.svg';
import { ReactComponent as CustomersIcon } from '../../../Assets/customers.svg';
import ReservationStatusDropDown from './ReservationStatusDropDown';
import { AboutDot } from '../../DashBoard/Components/CardComponent';

const ReservationCard = (props) => {
    const { setShowPopup, reservationStatus, time, reservationType, name, mobileNumber, guestNumber, isWaiting } =
        props;

    const statusColors = {
        'Not confirmed': { bgColor: 'white', borderColor: '#D3D2D8' },
        'Confirmed': { bgColor: 'success-50', borderColor: '#22C55E' },
        'Cancelled': { bgColor: 'destructive-50', borderColor: '#EF4444' },
        'Late': { bgColor: 'secondary-50', borderColor: '#FFCE73' },
        'Seated': { bgColor: 'primary-50', borderColor: '#6C5DD3' },
    };

    const [color, setColor] = useState(statusColors[reservationStatus]);

    return (
        <div
            className={`border rounded-lg flex flex-row px-4 md:px-3 mobile:px-2 py-2 bg-${color.bgColor}`}
            style={{ borderColor: color.borderColor }}>
            <div className='pr-3 mobile:pr-1 mobile:mr-2 border-r border-neutral-300 mr-4 py-2 max-w-[146px] w-full'>
                <div className='flex flex-col mb-3'>
                    <span className='paragraph-large-regular text-black pb-1'>{isWaiting ? '12' : time}</span>

                    <span className='paragraph-small-italic text-neutral-500'>
                        {isWaiting ? 'Waiting' : reservationType}
                    </span>
                </div>

                <div className='flex flex-row items-center'>
                    <ReservationStatusDropDown
                        reservationStatus={reservationStatus}
                        handleChangeReservationStatus={(status) => setColor(statusColors[status])}
                    />
                </div>
            </div>

            <div className='py-2 w-full'>
                <div className='flex flex-row items-center mb-2'>
                    <UserIcon />

                    <span className='paragraph-small-regular ml-2'>{name}</span>
                </div>

                <div className='flex flex-row items-center mb-2'>
                    <CallIcon />
                    <span className='paragraph-small-regular ml-2'>{mobileNumber}</span>
                </div>

                <div className='flex flex-row justify-between items-end'>
                    <div className='flex flex-row items-center'>
                        <CustomersIcon />
                        <span className='paragraph-small-regular ml-2'>{guestNumber} Guests</span>
                    </div>

                    <div
                        className='mb-2 cursor-pointer'
                        onClick={() => setShowPopup(isWaiting ? 'waitListDetails' : 'reservationDetails')}>
                        <AboutDot />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservationCard;
