import React from 'react';
import { ReactComponent as BackIconMobile } from '../../../Assets/back-icon-mobile.svg';
import { ReactComponent as TimingIcon } from '../../../Assets/timing-mobile.svg';
import { ReactComponent as ShareIcon } from '../../../Assets/share-app-mobile.svg';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import pizza from '../../../Assets/MediaLibrary/pizza1.png';

export default function SubCategoryPage(props) {
    const { categoryName = 'Veg Pizza', subCategoryName = 'Mergherita Pizaa', title = 'Title' } = props;

    return (
        <>
            <div className='flex flex-row justify-between mt-8 mb-4'>
                <BackIconMobile />
                <div className='flex flex-row gap-4'>
                    <TimingIcon />
                    <ShareIcon />
                </div>
            </div>

            <div className='text-[22px] font-bold text-black pb-4 border-b border-neutral-300 w-[290px] -ml-4 pl-4'>
                {categoryName}
            </div>

            <div className='flex flex-row overflow-auto w-screen py-2.5 items-center gap-3 border-b border-neutral-300 -ml-4 pl-4'>
                <div className='text-sm font-bold py-2.5 px-2 bg-[#ed38374d] rounded-xl text-black h-10'>{title}</div>
                <div>Meal For 2 (Veg)</div>
                <div>Meal For 3 (Veg)</div>
            </div>

            <div className='mt-2 mb-2 text-base font-semibold tracking-[2px] h-6'> {title}</div>

            <div className='pt-5 pb-9 flex flex-row justify-between w-full border-b border-neutral-300'>
                <div className='flex flex-col max-w-[60%]'>
                    <VegIcon
                        height={14}
                        width={14}
                        className='mb-1'
                    />

                    <span className='text-[14px] font-bold text-neutral-700'>{subCategoryName}</span>

                    <span className='text-[14px] font-bold text-neutral-700'>&#8377; 199</span>

                    <div className='h-[2.5em] mt-1'>
                        <p className='line-clamp-2 text-[10px]'>
                            Mozzarella Cheese & Signature Pizza Sauce. Nutritional Information as per 100 Gms (Calories
                            300.31 Kacl , Fat 11.35 , Cholesterol 6 , Carbohydrate 39.26 , Sugar 1.4 , Protien 10.28 )
                        </p>
                    </div>
                </div>

                <div className='relative'>
                    <img
                        src={pizza}
                        alt=''
                        className='h-auto w-[100px] rounded-xl'
                    />

                    <button className='bg-[#dfdedc] rounded-xl text-xs h-8 w-[75px] ml-3 absolute -bottom-4'>
                        Add +
                    </button>
                </div>
            </div>

            <div className='pt-5 pb-9 flex flex-row justify-between w-full border-b border-neutral-300'>
                <div className='flex flex-col max-w-[60%]'>
                    <VegIcon
                        height={14}
                        width={14}
                        className='mb-1'
                    />

                    <span className='text-[14px] font-bold text-neutral-700'>{subCategoryName}</span>

                    <span className='text-[14px] font-bold text-neutral-700'>&#8377; 199</span>

                    <div className='h-[2.5em] mt-1'>
                        <p className='line-clamp-2 text-[10px]'>
                            Mozzarella Cheese & Signature Pizza Sauce. Nutritional Information as per 100 Gms (Calories
                            300.31 Kacl , Fat 11.35 , Cholesterol 6 , Carbohydrate 39.26 , Sugar 1.4 , Protien 10.28 )
                        </p>
                    </div>
                </div>

                <div className='relative'>
                    <img
                        src={pizza}
                        alt=''
                        className='h-auto w-[100px] rounded-xl'
                    />

                    <button className='bg-[#dfdedc] rounded-xl text-xs h-8 w-[75px] ml-3 absolute -bottom-4'>
                        Add +
                    </button>
                </div>
            </div>

            <div className='pt-5 pb-9 flex flex-row justify-between w-full border-b border-neutral-300'>
                <div className='flex flex-col max-w-[60%]'>
                    <VegIcon
                        height={14}
                        width={14}
                        className='mb-1'
                    />

                    <span className='text-[14px] font-bold text-neutral-700'>{subCategoryName}</span>

                    <span className='text-[14px] font-bold text-neutral-700'>&#8377; 199</span>

                    <div className='h-[2.5em] mt-1'>
                        <p className='line-clamp-2 text-[10px]'>
                            Mozzarella Cheese & Signature Pizza Sauce. Nutritional Information as per 100 Gms (Calories
                            300.31 Kacl , Fat 11.35 , Cholesterol 6 , Carbohydrate 39.26 , Sugar 1.4 , Protien 10.28 )
                        </p>
                    </div>
                </div>

                <div className='relative'>
                    <img
                        src={pizza}
                        alt=''
                        className='h-auto w-[100px] rounded-xl'
                    />

                    <button className='bg-[#dfdedc] rounded-xl text-xs h-8 w-[75px] ml-3 absolute -bottom-4'>
                        Add +
                    </button>
                </div>
            </div>
        </>
    );
}
