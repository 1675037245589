import React from 'react';
import { useSelector } from 'react-redux';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import InputAreaSection from '../../../../Components/InputField/InputAreaSection';

export default function TermsAndConditions() {
    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    return (
        <>
            <div className='max-w-[636px] md:max-w-full lg:mb-5 md:mb-12 lg:max-w-[459px] w-full'>
                <TitleDescription
                    className='mb-4'
                    title='Terms and condition'
                    description='Enter the desired terms and condition in given languages.'
                />

                {availableLanguages.map((el, index) => (
                    <InputAreaSection
                        maxWidth='w-[636px]'
                        key={index}
                        className='mb-4'
                        label={`(${el.language_label})`}
                        name={`translations.terms_and_conditions.${el.language_id}`}
                        placeholder={`Enter terms and conditions in ${el.language_label}`}
                        boxHeight='h-[120px]'
                        labelStyle='paragraph-medium-italic'
                    />
                ))}
            </div>
        </>
    );
}
