import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../../../../../Assets/chevron-down.svg';

export default function CountDropdown(props) {
    const { handleChangeCount, opemFromBottom } = props;

    const handleClickItem = (item) => {
        setSelectedItem(item);
        handleChangeCount(item);
    };

    const menuItems = [1, 2, 3];

    const [selectedItem, setSelectedItem] = useState(props.selectedItem);

    useEffect(() => {
        setSelectedItem(props.selectedItem);
    }, [props.selectedItem]);

    return (
        <div className='dropdown-icon z-10'>
            <Menu
                as='div'
                className='relative inline-block text-left'>
                <div>
                    <Menu.Button className='paragraph-medium-regular inline-flex items-center w-full justify-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mobile:text-sm remove-shadow'>
                        {selectedItem}
                        <span className='w-6 ml-3'>
                            <DropDownIcon className='drop-down-icon-rotate w-full h-auto' />
                        </span>
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items
                        className={`absolute shadow-xl pb-4 min-w-[55px] z-10 w-full rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none paragraph-small-medium mobile:left-0 ${
                            opemFromBottom && 'bottom-4 mb-2'
                        }  `}>
                        {menuItems.map((el, index) => (
                            <div
                                className='pt-4 cursor-pointer text-center'
                                key={index}
                                onClick={() => handleClickItem(el)}>
                                <Menu.Item>
                                    <span>{el}</span>
                                </Menu.Item>
                            </div>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
