import React from 'react';
import { CategoryLayoutTable } from './TableComponents';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import { ListViewAppearance } from './ListViewAppearance';
import { IsMobileScreen, IsXlScreen } from '../../../../../../Constants/Constants';
import { ReactComponent as TrashIcon } from '../../../../../../Assets/trash.svg';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import DropdownSection from '../../Components/DropdownSection';
import { useFieldArray, useFormContext } from 'react-hook-form';

export default function CategoryLayout(props) {
    const { setShowFooter, setShowTabChangeWarningPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const isXlScreen = IsXlScreen();

    const menuItems = [
        { label: '01', value: 1 },
        { label: '02', value: 2 },
        { label: '03', value: 3 },
    ];

    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'appearance.category_layout',
    });

    return (
        <>
            <div>
                {!isMobileScreen ? (
                    <CategoryLayoutTable
                        fields={fields}
                        append={(value) => {
                            append(value);
                            setShowFooter(true);
                            setShowTabChangeWarningPopup(true);
                        }}
                        remove={(index) => {
                            remove(index);
                            setShowFooter(true);
                            setShowTabChangeWarningPopup(true);
                        }}
                        control={control}
                        setShowFooter={setShowFooter}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                ) : (
                    <div className='w-full mb-4'>
                        <div className='flex flex-row pb-2'>
                            <div
                                className='mr-2 w-full mobile:mr-1'
                                onClick={() => append({ category_display_count: 1 })}>
                                <LargePrimaryButton
                                    label='Add row'
                                    leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                                    leftIconClick={<AddIcon stroke='#C4BEED' />}
                                />
                            </div>

                            <div className='ml-2 w-full mobile:ml-1'>
                                <LargeDestructiveButton
                                    label='Delete row'
                                    leftIconDefault={<TrashIcon stroke='#FFFFFF' />}
                                    leftIconClick={<TrashIcon stroke='#D7EDEB' />}
                                />
                            </div>
                        </div>

                        {fields?.map((el, index) => (
                            <div
                                className='mt-2'
                                key={index}>
                                <ListViewAppearance categoryDisplayCount={el.category_display_count} />
                            </div>
                        ))}
                    </div>
                )}

                <div className='mb-10'>
                    <DropdownSection
                        openFromBottom={!isXlScreen ?? true}
                        menuItems={menuItems}
                        title='Default row categories'
                        description='This value will set the number of categories in all the rows below the last row in the table above.'
                        name='appearance.default_category_display_count'
                        handleSelection={() => setShowFooter(true)}
                    />
                </div>
            </div>
        </>
    );
}
