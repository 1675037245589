import * as types from '../types/orderStatus.js';

const initialState = {
    isHold: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.ON_HOLD:
            return {
                ...state,
                isHold: !state.isHold,
            };

        default:
            return state;
    }
};
