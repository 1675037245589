import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargeDestructiveButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';

export default function WarningPopup(props) {
    const { handleClickClose, handleClickChange } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md: p-4 md:p-0 overflow-auto md:overflow-hidden'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 pt-6 pb-9 px-8 m-auto max-h-[969px] md:max-w-full md:rounded-none md:py-4 md:px-4 md:overflow-hidden md:h-full md:mt-0'>
                    <div className='flex flex-row justify-between items-center border-neutral-300 mb-6'>
                        <div>
                            <span className='paragraph-large-medium'>Confirm change setting</span>
                        </div>

                        <div
                            onClick={handleClickClose}
                            className='md:hidden cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='paragraph-medium-italic text-neutral-500 mb-2'>
                        Are you sure want to change setting tab ?
                    </div>
                    <span className='paragraph-medium-italic text-neutral-500'>
                        This action will discard all your change of current tab.
                    </span>

                    <div className='sticky z-[49] md:z-[8] gap-4 md:gap-2 group-[.undock-left-panel]:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex flex-row justify-end w-full max-w-[1336px] mx-auto bg-white mt-4 md:border-t border-neutral-300'>
                        <div
                            className='w-1/2'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>
                        <div
                            className='w-1/2'
                            onClick={handleClickChange}>
                            <LargeDestructiveButton label='Change' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
