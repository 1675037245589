import React from 'react';
import { Table } from './Table';

export default function TopManager() {
    const header = ['CUSTOMER NAME', 'ORDERS MADE', 'REVENUE  GENERATED'];

    const style = ['min-w-[214px] lg:w-[238px]', 'min-w-[208px] lg:w-[208px]', 'min-w-[202px] lg:w-[218px]'];

    const tableDetails = [
        ['Sarthak kanchan', 345, '₹18,234'],
        ['Arjun patel', 265, '₹9,632'],
        ['John Doe', 76, '₹4,234'],
    ];

    return (
        <>
            <div className='max-w-[626px] w-full lg:max-w-full lg:w-full'>
                <div className='mb-4 md:mb-2'>
                    <span className='paragraph-large-medium text-black'>Top user</span>
                </div>

                <Table
                    header={header}
                    style={style}
                    details={tableDetails}
                />
            </div>
        </>
    );
}
