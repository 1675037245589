import React from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../../Components/Layout';
import InputSection from '../../Components/InputSection';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function Maps(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateMapSetting = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=google_maps_settings`, {
                ...params,
            });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = (data) => {
        updateMapSetting({ google_maps_api_key: data.google_maps_settings.google_maps_api_key });
    };

    // mapkey==> AIzaSyCWD7GnaP4OoeoeuYip6z-f-IetRG30xpo

    return (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => handleDiscardForm()}
            showFooter={isDirty}>
            <InputSection
                name='google_maps_settings.google_maps_api_key'
                title='Google maps'
                description='API settings for your Google Maps account.'
                label='(Google API key)'
                placeholder='Enter Google API Key'
                labelMarginB='mb-2'
                shadow='shadow-smallDropDownShadow'
                labelStyle='paragraph-medium-italic text-neutral-500'
                titleLoaderProps={{ className: 'max-w-[325px] md:max-w-full mb-3' }}
                boxLoaderProps={{ hasLabel: true, className: 'max-w-[325px] md:max-w-full' }}
                isLoading={watch('isLoadingForm')}
            />
        </Layout>
    );
}
