import React from 'react';

const InformationPopup = ({ extraCharges }) => {
    return (
        <div className='absolute z-10 mt-1 bg-white shadow-md rounded-md border border-gray-500 w-[205px] h-[95px] top-auto bottom-full m-1 flex justify-center items-center'>
            <div className='m-1'>
                <p className='m-1 text-gray-800 mt-1'>GST Amount: ₹{extraCharges[0]?.gst_amount}</p>
                <p className='m-1 text-gray-800 mt-1'>Convenience charge: ₹{extraCharges[0]?.convenience_charge}</p>
                <p className='m-1 text-gray-800 mt-1'>Packaging charge: ₹{extraCharges[0]?.packaging_charge}</p>
            </div>
        </div>
    );
};

export default InformationPopup;
