import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as SuccessIcon } from '../../../Assets/success-tick.svg';
import { ReactComponent as ReimbursementIcon } from '../../../Assets/reimbursement.svg';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Header(props) {
    const { code, handleClickClose, isDelivered, isRefundInitiated, page, cartToken } = props;
    const title =
        page === 'orderRating'
            ? 'Customer review details'
            : page === 'abandonedCarts'
              ? 'Customer cart details'
              : 'Customer order details';

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen && (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrow className='rotate-90' />
                    <span className='ml-1'>Back to orders</span>
                </div>
            )}

            <div className='flex flex-row justify-between items-center mb-2.5 pb-4 border-b border-neutral-300'>
                <div>
                    <div className='paragraph-large-medium md:paragraph-medium-medium'>{title}</div>

                    <div className='flex flex-row items-center'>
                        <div className='paragraph-medium-italic text-neutral-500'>
                            {page === 'abandonedCarts'
                                ? `Cart (${cartToken ? `#${cartToken}` : 'N/A'})`
                                : `Order #${code}`}
                        </div>
                        {/* {code && (
                            <>
                                <div className='h-1 w-1 rounded-full bg-neutral-500 mx-3' />
                                <div>
                                    <span className='text-primary-500 paragraph-medium-italic'>Code: {code}</span>
                                </div>
                            </>
                        )} */}
                        {isDelivered && (
                            <>
                                <div className='h-1 w-1 rounded-full bg-neutral-500 mx-3' />
                                <div className='flex flex-row items-center'>
                                    <SuccessIcon />
                                    <span className='paragraph-small-regular ml-1'>Delivered</span>
                                </div>
                            </>
                        )}
                        {isRefundInitiated && (
                            <>
                                <div className='h-1 w-1 rounded-full bg-neutral-500 mx-3' />
                                <div className='flex flex-row items-center'>
                                    <ReimbursementIcon />
                                    <span className='paragraph-small-regular ml-1'>Refund initiated</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <span
                    className='cursor-pointer md:hidden'
                    onClick={handleClickClose}>
                    <CloseIcon
                        height={24}
                        width={24}
                    />
                </span>
            </div>
        </>
    );
}
