import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import APIV5 from '../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as langaugeTypes from '../../../reduxStore/types/langauge';
import Loader from './Components/Loader';
import ParentGlobalComponent from './Components/ParentGlobalComponent';

export default function StoreSetting() {
    const methods = useForm();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const orderingModes = useSelector((state) => state.outlet.orderingModes);

    const { setValue, watch } = methods;

    const [showLoader, setShowLoader] = useState(true);

    const formateAndSetRestaurantName = (restaurantName) => {
        Object.keys(restaurantName).forEach((key) => {
            const index = parseInt(key);
            setValue(`app_settings.restaurant_name.${index}`, restaurantName[key]);
            setValue(`defaultValue.app_settings.restaurant_name.${index}`, restaurantName[key]);
        });
    };

    const formatLikeToOrder = (likeToOrder) => {
        Object.keys(likeToOrder)?.forEach((key) => {
            const index = parseInt(key);
            setValue(`brand_settings.translations.like_to_order.${index}`, likeToOrder[key]);
            setValue(`defaultValue.brand_settings.translations.like_to_order.${index}`, likeToOrder[key]);
        });
    };

    const formatSpecialInstruction = (instruction) => {
        Object.keys(instruction)?.forEach((key) => {
            const index = parseInt(key);
            setValue(`brand_settings.translations.special_instruction_label.${index}`, instruction[key]);
            setValue(`defaultValue.brand_settings.translations.special_instruction_label.${index}`, instruction[key]);
        });
    };

    const formateFooterLogo = (footeLogoDetails) => {
        setValue('brand_settings.footer_logo.image_url', footeLogoDetails.url);
        setValue('defaultValue.brand_settings.footer_logo.image_url', footeLogoDetails.url);
        setValue('brand_settings.logo_footer_image.id', footeLogoDetails.id);
        setValue('defaultValue.brand_settings.logo_footer_image.id', footeLogoDetails.id);
    };

    const formatIntroImage = (introLogoDetails) => {
        setValue('brand_settings.brand_logo.image_url', introLogoDetails.url);
        setValue('defaultValue.brand_settings.brand_logo.image_url', introLogoDetails.url);
        setValue('brand_settings.logo_intro_image.id', introLogoDetails.id);
        setValue('defaultValue.brand_settings.logo_intro_image.id', introLogoDetails.id);
    };

    const formatlanguage = (language) => {
        setValue('language_settings.defaultLanguage', language);

        setValue('defaultValue.language_settings.defaultLanguage', language);

        const selectedLanguageList = watch('language_settings.languageList')
            ?.filter((item) => language?.some((lang) => lang.language === item.value.language))
            ?.map((item) => ({
                id: language.find((lang) => lang.language === item.value.language).id,
                language: item.value.language,
                language_label: item.value.language_label,
            }));

        setValue('language_settings.selectedLanguage', selectedLanguageList);

        setValue('defaultValue.language_settings.selectedLanguage', selectedLanguageList);
    };

    const formateCashBackSetting = (data) => {
        const cashbackSettingData = data;

        const cashbackPercentages = cashbackSettingData.restaurant_ordering_mode_cashback_settings.map(
            (item) => item.cashback_redeem_percentage
        );

        const areAllSameCashback = cashbackPercentages.every((percentage, index, array) => {
            return percentage === array[0];
        });

        const settlementDelays = cashbackSettingData.restaurant_ordering_mode_cashback_settings.map(
            (item) => item.cashback_settlement_delay
        );

        const areAllSameSettlement = settlementDelays.every((delay, index, array) => {
            return delay === array[0];
        });

        if (areAllSameSettlement) {
            const sameDelayValue =
                cashbackSettingData?.restaurant_ordering_mode_cashback_settings[0]?.cashback_settlement_delay;

            if (!sameDelayValue) {
                setValue('cashback_settings.earningDelayEnable', false, { shouldDirty: false });
                setValue('defaultValue.cashback_settings.earningDelayEnable', false, { shouldDirty: false });
            } else {
                setValue('cashback_settings.same_earning_delay.hour', Math.floor(sameDelayValue / 60), {
                    shouldDirty: false,
                });
                setValue('defaultValue.cashback_settings.same_earning_delay.hour', Math.floor(sameDelayValue / 60), {
                    shouldDirty: false,
                });

                setValue('cashback_settings.same_earning_delay.minute', sameDelayValue % 60, { shouldDirty: false });
                setValue('defaultValue.cashback_settings.same_earning_delay.minute', sameDelayValue % 60, {
                    shouldDirty: false,
                });

                setValue('cashback_settings.earningDelayEnable', true, { shouldDirty: false });
                setValue('defaultValue.cashback_settings.earningDelayEnable', true, { shouldDirty: false });
            }

            setValue('cashback_settings.sameEarningDelay', true, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.sameEarningDelay', true, { shouldDirty: false });
        } else {
            setValue('cashback_settings.earningDelayEnable', true, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.earningDelayEnable', true, { shouldDirty: false });
            setValue('cashback_settings.sameEarningDelay', false, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.sameEarningDelay', false, { shouldDirty: false });

            const updatedEarningDelay = cashbackSettingData.restaurant_ordering_mode_cashback_settings.map((item) => {
                const totalMinutes = item.cashback_settlement_delay;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                return { hour: hours, minute: minutes };
            });

            setValue('cashback_settings.earning_delay', updatedEarningDelay);

            setValue('defaultValue.cashback_settings.earning_delay', updatedEarningDelay);
        }

        if (areAllSameCashback) {
            const samePersantageValue =
                cashbackSettingData?.restaurant_ordering_mode_cashback_settings[0]?.cashback_redeem_percentage;

            if (samePersantageValue === 0) {
                setValue('cashback_settings.advanceSettingEnable', false, { shouldDirty: false });
                setValue('defaultValue.cashback_settings.advanceSettingEnable', false, { shouldDirty: false });
            } else {
                setValue('cashback_settings.advanceSettingEnable', true, { shouldDirty: false });
                setValue('defaultValue.cashback_settings.advanceSettingEnable', true, { shouldDirty: false });
            }

            setValue('cashback_settings.sameAdvanceConfiguration', true, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.sameAdvanceConfiguration', true, { shouldDirty: false });

            setValue(
                'cashback_settings.same_cashback_redeem_percentage',
                cashbackSettingData?.restaurant_ordering_mode_cashback_settings[0]?.cashback_redeem_percentage
            );
            setValue(
                'defaultValue.cashback_settings.same_cashback_redeem_percentage',
                cashbackSettingData?.restaurant_ordering_mode_cashback_settings[0]?.cashback_redeem_percentage
            );
        } else {
            setValue('cashback_settings.advanceSettingEnable', true, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.advanceSettingEnable', true, { shouldDirty: false });
            setValue('cashback_settings.sameAdvanceConfiguration', false, { shouldDirty: false });
            setValue('defaultValue.cashback_settings.sameAdvanceConfiguration', false, { shouldDirty: false });
        }

        Object.keys(cashbackSettingData).forEach((key) => {
            setValue(key, cashbackSettingData[key], { shouldDirty: false });
            setValue(`defaultValue.${key}`, cashbackSettingData[key], { shouldDirty: false });
        });
    };

    const setDefaultValue = (data) => {
        Object.keys(data).forEach((key) => {
            if (key === 'language_settings') {
                formatlanguage(data[key]);
                return;
            }

            if (key === 'about_us') {
                Object.keys(data[key]).forEach((index) => {
                    setValue(`translations.about_us.${index}`, data[key][index]);
                    setValue(`defaultValue.translations.about_us.${index}`, data[key][index]);
                });
            }

            if (key === 'contact_us') {
                Object.keys(data[key]).forEach((index) => {
                    setValue(`translations.contact_us.${index}`, data[key][index]);
                    setValue(`defaultValue.translations.contact_us.${index}`, data[key][index]);
                });
            }

            if (key === 'legal') {
                Object.keys(data[key]).forEach((index) => {
                    setValue(`translations.return_policy.${index}`, data[key][index]);
                    setValue(`defaultValue.translations.return_policy.${index}`, data[key][index]);
                });
            }

            if (key === 'sms_settings') {
                setValue('defaultValue.sms_settings.two_factor_api_key', data[key].two_factor_api_key);
            }

            if (key === 'cashback_settings') {
                formateCashBackSetting(data[key]);
            }

            if (typeof data[key] === 'object') {
                Object.keys(data[key]).forEach((subKey) => {
                    if (key === 'app_settings' && subKey === 'name') {
                        formateAndSetRestaurantName(data[key][subKey]);
                        return;
                    }

                    if (subKey === 'apply_mask') {
                        data[key].apply_mask === 1
                            ? setValue('app_settings.apply_mask', 1)
                            : setValue('app_settings.apply_mask', 0);

                        data[key].apply_mask === 1
                            ? setValue('defaultValue.app_settings.apply_mask', 1)
                            : setValue('defaultValue.app_settings.apply_mask', 0);
                    }

                    if (subKey === 'like_to_order') {
                        formatLikeToOrder(data[key][subKey]);
                        return;
                    }

                    if (subKey === 'special_instruction_label') {
                        formatSpecialInstruction(data[key][subKey]);
                        return;
                    }

                    if (subKey === 'logo_footer_image') {
                        formateFooterLogo(data[key][subKey]);
                        return;
                    }

                    if (subKey === 'logo_intro_image') {
                        formatIntroImage(data[key][subKey]);
                        return;
                    }

                    setValue(`defaultValue.${key}.${subKey}`, data[key][subKey]);
                    setValue(`${key}.${subKey}`, data[key][subKey]);
                });
            } else {
                setValue(key, data[key]);
                setValue(`defaultValue.${key}`, data[key]);
            }
        });

        setValue('isLoadingForm', false);
    };

    const fetchSettings = async () => {
        setShowLoader(true);
        try {
            const response = await APIV5.get(`restaurants/${selectedRestaurantId}`);

            setDefaultValue(response.restaurant);
        } catch (error) {
            console.log(error);
        }

        setValue('orderingModes', orderingModes);
        setValue('defaultValue.orderingModes', orderingModes);

        setShowLoader(false);
        setValue('isLoadingForm', false);
    };

    const dispatch = useDispatch();

    const fetchlanguageList = async () => {
        setValue('isLoadingForm', true);

        try {
            const response = await APIV5.get('languages');

            setValue(
                'language_settings.languageList',
                response.languages.map((el) => {
                    return { label: el.language_label, value: el };
                })
            );

            setValue(
                'defaultValue.language_settings.languageList',
                response.languages.map((el) => {
                    return { label: el.language_label, value: el };
                })
            );
        } catch (error) {
            console.log(error);
        } finally {
            setValue('isLoadingForm', false);
        }

        try {
            const responses = await APIV5.get(`restaurants/${selectedRestaurantId}`);

            const languages = responses.restaurant.language_settings.map((el) => ({ ...el, language_id: el.id }));

            dispatch({
                type: langaugeTypes.SET_AVAILABLE_LANGUAGES,
                payload: {
                    availableLanguages: languages,
                },
            });

            let language_id = localStorage.getItem('language');

            if (!language_id) {
                let id = false;
                languages.map((language) => {
                    if (language.language === 'en') {
                        dispatch({
                            type: langaugeTypes.SET_LANGUAGE_ID,
                            payload: {
                                languageId: language.language_id,
                            },
                        });
                        localStorage.setItem('language', language.language_id);
                        id = true;
                    }
                    return null;
                });
                if (!id) {
                    dispatch({
                        type: langaugeTypes.SET_LANGUAGE_ID,
                        payload: {
                            languageId: languages[0].language_id,
                        },
                    });
                    localStorage.setItem('language', languages[0].language_id);
                }
            } else {
                let idState = false;
                languages.map((language) => {
                    if (language.language_id === language_id) {
                        dispatch({
                            type: langaugeTypes.SET_LANGUAGE_ID,
                            payload: {
                                languageId: language.language_id,
                            },
                        });
                        localStorage.setItem('language', language.language_id);
                        idState = true;
                    }
                    return null;
                });
                if (!idState) {
                    languages.map((language) => {
                        if (language.language === 'en') {
                            dispatch({
                                type: langaugeTypes.SET_LANGUAGE_ID,
                                payload: {
                                    languageId: language.language_id,
                                },
                            });
                            localStorage.setItem('language', language.language_id);
                        }
                        return null;
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setValue('isLoadingForm', false);
        }
    };

    useEffect(() => {
        setValue('isLoadingForm', true);

        fetchlanguageList();

        fetchSettings();

        // eslint-disable-next-line
    }, []);

    return showLoader ? (
        <Loader />
    ) : (
        <ParentGlobalComponent
            fetchlanguageList={fetchlanguageList}
            fetchSettings={fetchSettings}
            defaultValue={watch('defaultValue')}
            isLoadingForm={watch('isLoadingForm')}
        />
    );
}
