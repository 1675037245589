import React, { useEffect, useState } from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as TakeawayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as DineIn } from '../../../Assets/order.svg';

export const SingleNotification = (props) => {
    const { mode, descriptions, notificationTime } = props;

    const [activeMenu, setActiveMenu] = useState();

    const [icon, setIcon] = useState();

    useEffect(() => {
        let icon;
        switch (mode) {
            case 'dineIn':
                icon = (
                    <DineIn
                        stroke='#FFFFFF'
                        className='w-full h-auto'
                    />
                );
                break;

            case 'takeAway':
                icon = (
                    <TakeawayIcon
                        stroke='#FFFFFF'
                        className='w-full h-auto'
                    />
                );
                break;

            default:
                icon = (
                    <DeliveryIcon
                        stroke='#FFFFFF'
                        className='w-full h-auto'
                    />
                );
                break;
        }

        setIcon(icon);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className='border-t border-neutral-200 px-6 py-3 cursor-pointer'
            onClick={() => setActiveMenu((prevState) => !prevState)}>
            <div className='flex flex-row'>
                <div
                    className={`flex ${
                        mode === 'takeAway'
                            ? 'bg-secondary-700'
                            : mode === 'dineIn'
                              ? 'bg-primary-500'
                              : 'bg-tertiary-800'
                    } rounded-lg min-w-[40px] h-10 mobile:min-w-[34px] mobile:h-[34px] mr-2`}>
                    <div className='w-6 mobile:w-5 m-auto'>{icon}</div>
                </div>

                <div>
                    <div>{descriptions}</div>

                    <div className='paragraph-x-small-regular text-neutral-500'>{notificationTime}</div>

                    {activeMenu && (
                        <div className='flex items-center cursor-auto'>
                            <div className='min-w-[73px] mr-1'>
                                <LargePrimaryButton
                                    padding='px-1'
                                    label='Accept'
                                    fontfamily='paragraph-x-small-regular'
                                    buttonStyle='h-[36px]'
                                    fontsSize='text-xs'
                                />
                            </div>

                            <div className='min-w-[62px]'>
                                <LargePrimaryButton
                                    padding='px-1'
                                    label='Deny'
                                    fontfamily='paragraph-x-small-regular'
                                    buttonStyle='h-[36px] bg-[#fff] border border-primary-500 hover:bg-[#fff]'
                                    fontsSize='text-xs'
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const Description = ({ focusContent, description, focusContentColor }) => {
    const descriptionParts = description.split(':focuscontent:');

    return (
        <div className='paragraph-small-regular'>
            {descriptionParts.map((part, index) => (
                <React.Fragment key={index}>
                    {index > 0 && (
                        <span className={`font-semibold ${focusContentColor[index - 1]}`}>
                            {focusContent[index - 1]}
                        </span>
                    )}
                    {part}
                </React.Fragment>
            ))}
        </div>
    );
};
