import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectCategorySection from './SelectCategorySection.js';
import SelectDishesSection from './SelectDishesSection.js';

export default function DishCategorySelection(props) {
    const { implementationPresetMenuItems } = props;

    const { watch, setValue } = useFormContext();

    useEffect(() => {
        if (watch('implementation_preset')?.value === implementationPresetMenuItems[1].value) {
            setValue('selected_item', [{}]);
        }

        if (watch('implementation_preset')?.value === implementationPresetMenuItems[2].value) {
            setValue('selected_item', [{ id: null }]);
        }

        // eslint-disable-next-line
    }, [watch('implementation_preset')]);

    if (watch('implementation_preset')?.value === implementationPresetMenuItems[1].value) {
        return <SelectCategorySection />;
    }

    if (watch('implementation_preset')?.value === implementationPresetMenuItems[2].value) {
        return <SelectDishesSection />;
    }
}
