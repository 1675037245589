import React from 'react';
import Layout from '../Components/Layout';
import { useSelector } from 'react-redux';
import TitleDescription from '../../../Components/TitleDescription/TitleDescription';
import InputAreaSection from './InputAreaSection';
import InputAreaLoader from '../../../Components/ContentLoader/InputLoaders';
import TitleDescriptionLoader from '../../../Components/ContentLoader/TitleDescriptionLoader';

export default function TwoInputAreaSection(props) {
    const { title, description, placeholder, name, handleClickSave, handleClickCancel, isLoading, showFooter } = props;

    const availableLangauge = useSelector((state) => state.langauge.availableLanguages);

    return (
        <Layout
            handleClickSave={handleClickSave}
            handleClickCancel={handleClickCancel}
            showFooter={showFooter}>
            <div className='max-w-[636px] md:max-w-full md:pb-20'>
                {isLoading ? (
                    <>
                        <TitleDescriptionLoader className='mb-4' />
                        <InputAreaLoader
                            hasLabel
                            className='mb-4'
                        />
                        <InputAreaLoader
                            hasLabel
                            className='mb-4'
                        />
                    </>
                ) : (
                    <>
                        <TitleDescription
                            className='mb-2'
                            title={title}
                            description={description}
                        />

                        {availableLangauge.map((el, index) => (
                            <div
                                className='mb-4 w-full'
                                key={index}>
                                <InputAreaSection
                                    maxWidth='max-w-[636px] md:max-w-full'
                                    placeholder={`${placeholder} ${el.language_label}`}
                                    shadow='shadow-smallDropDownShadow'
                                    paddingT='pt-4'
                                    label={`(${el.language_label})`}
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    name={`translations.${name}.${el.language_id}`}
                                />
                            </div>
                        ))}
                    </>
                )}
            </div>
        </Layout>
    );
}
