import React from 'react';

export default function TableHeader() {
    return (
        <div className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 h-11 w-full flex flex-row border border-neutral-300 rounded-t-xl'>
            <div className='w-1/2 text-left pl-6 flex items-center my-auto'>Banner image</div>
            <div className='w-1/2 text-left pl-6 flex items-center my-auto'>Banner title</div>
        </div>
    );
}
