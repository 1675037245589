import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RestaurantOfferControllerButton from './RestaurantOfferControllerButton';
import OutletOfferControllerButton from './OutletOfferControllerButton';
import Filters from './Filters';

export default function TableControllerButton(props) {
    const {
        fetchRestaurantOfferList,
        fetchOutletWiseOffersList,
        activeState,
        setActiveState,
        setSelectedOffer,
        selectedOffer,
        setAppliedFilter,
        setPageState,
        isDisableButtons,
    } = props;

    const { tab } = useParams();

    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        setIsFilterActive(false);
    }, [tab]);

    return (
        <>
            {tab !== 'outlet-offer-list' ? (
                <RestaurantOfferControllerButton
                    setIsFilterActive={setIsFilterActive}
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    activeState={activeState}
                    setActiveState={setActiveState}
                    setSelectedOffer={setSelectedOffer}
                    selectedOffer={selectedOffer}
                    setAppliedFilter={setAppliedFilter}
                    setPageState={setPageState}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                />
            ) : (
                <OutletOfferControllerButton
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    activeState={activeState}
                    setActiveState={setActiveState}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    isDisableButtons={isDisableButtons}
                />
            )}

            {isFilterActive && (
                <Filters
                    setAppliedFilter={setAppliedFilter}
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                />
            )}
        </>
    );
}
