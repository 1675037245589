import React, { useEffect, useState } from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useSelector } from 'react-redux';
import APIV2 from '../../../../api/axios/APIV2';
import Layout from './Components/Layout';
import TableSection from './Components/TableSection';
import ListSection from './Components/ListSection';

export default function LinkOutletPopup(props) {
    const { handleClickClose, linkedOutletId, fetchRestaurantOfferList, fetchOutletWiseOffersList, linkOfferId } =
        props;

    const outletList = useSelector((state) => state.outlet.outletList);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const isMobileScreen = IsMobileScreen();

    const [filteredOutletList, setFilteredOutletList] = useState([]);

    const [appliedFilter, setAppliedFilter] = useState({ keyword: null, linkStatus: null });

    const [linkUnlinkOutletList, setLinkUnlinkOutletList] = useState({ linkedOutlets: [], unLinkedOutlets: [] });

    const [pageState, setPageState] = useState({
        resultPerpage: 10,
        lastPage: 1,
        selectedPage: 1,
    });

    const handleChangePageState = (key, value) => {
        setPageState((prevState) => {
            let newState;

            if (key === 'resultPerpage') {
                const lastPage = Math.ceil(filteredOutletList.length / value);
                newState = { lastPage, selectedPage: 1 };
            }

            return { ...prevState, ...newState, [key]: value };
        });
    };

    useEffect(() => {
        setFilteredOutletList(outletList);

        setPageState((prevState) => {
            return { ...prevState, lastPage: Math.ceil(outletList.length / 10) };
        });

        // eslint-disable-next-line
    }, [outletList]);

    const applyFilter = (keyword) => {
        keyword &&
            setAppliedFilter((prevState) => {
                return { ...prevState, keyword };
            });

        const lowerKeyword = keyword.toLowerCase();

        const filteredOutlet = outletList.filter((el) => {
            const translations = el?.outletData?.outlet_address?.translations;

            const { address_line_1, address_line_2 } = translations || {};

            return (
                address_line_1?.[1].toLowerCase().includes(lowerKeyword) ||
                address_line_2?.[1].toLowerCase().includes(lowerKeyword) ||
                el?.outletData?.translations[1]?.toLowerCase().includes(lowerKeyword)
            );
        });

        setFilteredOutletList(filteredOutlet);
    };

    const handleChangeLinking = (doLink, outletId) => {
        setLinkUnlinkOutletList((prevState) => {
            const updatedList = { ...prevState };

            if (doLink) {
                updatedList.linkedOutlets = [...prevState.linkedOutlets, outletId];

                updatedList.unLinkedOutlets = prevState.unLinkedOutlets.filter((id) => id !== outletId);
            } else {
                updatedList.unLinkedOutlets = [...prevState.unLinkedOutlets, outletId];

                updatedList.linkedOutlets = prevState.linkedOutlets.filter((id) => id !== outletId);
            }

            return updatedList;
        });
    };

    const linkOutlet = async () => {
        try {
            const response = await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${linkOfferId}/link`, {
                outlet_ids: linkUnlinkOutletList.linkedOutlets,
            });

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const unlinkOutlet = async () => {
        try {
            const response = await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${linkOfferId}/un-link`, {
                outlet_ids: linkUnlinkOutletList.unLinkedOutlets,
            });

            return await response.success;
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSave = async () => {
        const isLinkingSuccess = linkUnlinkOutletList.linkedOutlets.length ? await linkOutlet() : true;

        const isUnlinkingSuccess = linkUnlinkOutletList.unLinkedOutlets.length ? await unlinkOutlet() : true;

        if (isLinkingSuccess && isUnlinkingSuccess) {
            fetchRestaurantOfferList();

            fetchOutletWiseOffersList();

            handleClickClose();
        }
    };

    const isSaveDisabled = !(linkUnlinkOutletList.linkedOutlets.length || linkUnlinkOutletList.unLinkedOutlets.length);

    const getAddress = (data) => {
        const translations = data?.outletData?.outlet_address?.translations;

        const state = translations?.state?.[1];
        const city = translations?.city?.[1];
        const addressLine1 = translations?.address_line_1?.[1];
        const addressLine2 = translations?.address_line_2?.[1];

        const displayName = data.displayName;

        const address = addressLine1 + ', ' + addressLine2;

        return { state, city, address, displayName };
    };

    useEffect(() => {
        setLinkUnlinkOutletList((prevState) => ({ ...prevState, linkedOutlets: linkedOutletId }));
    }, []);

    return (
        <>
            <Layout
                handleClickClose={handleClickClose}
                applyFilter={applyFilter}
                handleClickSave={handleClickSave}
                isSaveDisabled={isSaveDisabled}>
                {!isMobileScreen ? (
                    <TableSection
                        filteredOutletList={filteredOutletList}
                        pageState={pageState}
                        getAddress={getAddress}
                        handleChangeLinking={handleChangeLinking}
                        setLinkUnlinkOutletList={setLinkUnlinkOutletList}
                        linkUnlinkOutletList={linkUnlinkOutletList}
                    />
                ) : (
                    <ListSection
                        outletList={outletList}
                        getAddress={getAddress}
                        handleChangeLinking={handleChangeLinking}
                        setLinkUnlinkOutletList={setLinkUnlinkOutletList}
                        linkUnlinkOutletList={linkUnlinkOutletList}
                    />
                )}

                {!isMobileScreen && outletList.length > 10 && (
                    <div className='mt-4'>
                        <PaginationWithNumber
                            setSelectedPage={(number) => handleChangePageState('selectedPage', number)}
                            setResultPerPage={(number) => handleChangePageState('resultPerpage', number)}
                            selectedResultPerpage={pageState.resultPerpage}
                            lastPage={pageState.lastPage}
                            selectedPage={pageState.selectedPage}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
}
