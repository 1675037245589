import React from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function TabHeader() {
    const orderStatusTabs = [
        { label: 'Rating', path: '/order-rating/rating', module: 'rating' },
        { label: 'Complaint', path: '/order-rating/complaint/pending', module: 'complaint' },
    ];

    const { tab } = useParams();

    const isMobileScreen = IsMobileScreen();

    const navigate = useNavigate();

    const handleOrderStatusClick = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className='pb-4 border-b border-neutral-300 mb-4'>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center'>
                        {orderStatusTabs?.map((el, index) => (
                            <div
                                className='mr-4 xl:mr-2'
                                onClick={() => handleOrderStatusClick(el.path)}
                                key={index}>
                                <Tab
                                    label={el.label}
                                    isActive={tab === el.module}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='w-full max-w-[213px] md:max-w-full'>
                        <DropDownTabs
                            menuItems={orderStatusTabs?.map((tab, index) => ({
                                item: tab.label,
                                onClick: () => handleOrderStatusClick(tab.path),
                            }))}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
