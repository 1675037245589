import React from 'react';
import { ReactComponent as ExportIcon } from '../../../Assets/export.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import CalenderField from '../../../Components/Calender/CalenderField';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { format, isDate } from 'date-fns';

const Header = (props) => {
    const { setSelectedDates, selectedDates } = props;

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const handleClickExportData = async () => {
        const [from_date, to_date] = selectedDates;

        let formattedFromDate;
        let formattedToDate;

        if (typeof from_date === 'string' && typeof to_date === 'string') {
            formattedFromDate = from_date;
            formattedToDate = to_date;
        } else if (isDate(from_date) && isDate(to_date)) {
            formattedFromDate = format(from_date, 'yyyy-MM-dd');
            formattedToDate = format(to_date, 'yyyy-MM-dd');
        }

        const params = {
            type: 'bill_payments_report',
            start_date: formattedFromDate,
            end_date: formattedToDate,
            outlet_ids: [selectedOutletId],
        };

        try {
            const response = await APIV3.post(`export-report`, { ...params });

            console.log('response ==>', response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='flex flex-row items-center justify-between mb-6 md:block md:w-full md:mb-3'>
                <CalenderField
                    setSelectedDates={setSelectedDates}
                    selectedDates={selectedDates}
                />

                <div
                    className='min-w-[161px] md:mt-2 md:w-full'
                    onClick={handleClickExportData}>
                    <LargePrimaryButton
                        label='Export data'
                        leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                        leftIconClick={<ExportIcon stroke='#C4BEED' />}
                        isDefault={false}
                    />
                </div>
            </div>
        </>
    );
};

export default Header;
