import React from 'react';
import OutletWiseOfferTable from './OutletWiseOffer';
import { IsMobileScreen } from '../../../Constants/Constants';
import OutletWiseOfferListSection from './OutletWiseOfferListSection';
import APIV2 from '../../../api/axios/APIV2';
import { useSelector } from 'react-redux';

export default function OutletWiseOfferSection(props) {
    const {
        selectedOffer,
        setSelectedOffer,
        activeState,
        fetchRestaurantOfferList,
        fetchOutletWiseOffersList,
        setIsListLoading,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const isMobileScreen = IsMobileScreen();

    const onSelectOffer = (id, isSelected) => {
        const selection = isSelected ? [...selectedOffer, id] : selectedOffer.filter((el) => el !== id);

        setSelectedOffer(selection);
    };

    const listProps = {
        isRearrange: activeState.reArrange,
    };

    const unlinkOutlet = async (id) => {
        try {
            await APIV2.patch(`restaurants/${selectedRestaurantId}/offers/${id}/un-link`, {
                outlet_ids: [selectedOutletId],
            });
            fetchRestaurantOfferList();
            fetchOutletWiseOffersList();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <section>
                {!isMobileScreen ? (
                    <OutletWiseOfferTable
                        {...props}
                        onSelectOffer={onSelectOffer}
                        unlinkOutlet={unlinkOutlet}
                    />
                ) : (
                    <OutletWiseOfferListSection
                        {...props}
                        onSelectOffer={onSelectOffer}
                        listProps={listProps}
                        unlinkOutlet={unlinkOutlet}
                        setIsListLoading={setIsListLoading}
                    />
                )}
            </section>
        </>
    );
}
