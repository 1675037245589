import React from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { ReactComponent as HoldIcon } from '../../../Assets/hold.svg';
import { ReactComponent as OfferIcon } from '../../../Assets/offers.svg';
import { useDispatch } from 'react-redux';
import { ON_HOLD } from '../../../reduxStore/types/orderStatus';

export default function OfferAndHoldSection(props) {
    const { setShowPopup } = props;

    const dispatch = useDispatch();

    const handleClickHold = () => {
        dispatch({
            type: ON_HOLD,
        });
    };

    return (
        <>
            <div className='flex flex-row mb-2 pt-2'>
                <div
                    className='mr-1.5 w-full cursor-pointer'
                    onClick={() => setShowPopup('applyOffer')}>
                    <Tab
                        shadow='shadow-smallDropDownShadow'
                        label='Apply offer'
                        iconDefault={<OfferIcon />}
                        tabStyle='pageContent:h-11'
                    />
                </div>

                <div
                    className='w-full ml-1.5 cursor-pointer'
                    onClick={() => handleClickHold()}>
                    <Tab
                        shadow='shadow-smallDropDownShadow'
                        label='Hold order'
                        iconDefault={<HoldIcon className='pageContent:w-5' />}
                        tabStyle='pageContent:h-11'
                        labelStyle='pageContent:text-[15px]'
                    />
                </div>
            </div>
        </>
    );
}
