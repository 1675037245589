import React, { useMemo } from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { tabs } from '../Constants';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { offerTypesActiveTab } from '../../Helper/constant';

export default function Header() {
    const { id } = useParams();

    const { watch } = useFormContext();

    const isMobileScreen = IsMobileScreen();

    const navigate = useNavigate();

    const handleClickClose = () => {
        navigate('/offers/offer-list');
    };

    const handleNavigation = (selection) => {
        const path = !id ? `/offers/create-offer/${selection.value}` : `/offers/edit/${id}/${selection.value}`;

        navigate(path);
    };

    const enabledTabs = useMemo(() => {
        return offerTypesActiveTab?.[watch('offer_type')?.value] || [];
    }, [watch('offer_type')]);

    return (
        <section>
            {isMobileScreen && (
                <div className='mb-4  mx-4'>
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={handleClickClose}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='paragraph-medium-medium'>Back to offers</span>
                    </div>

                    <div className='relative'>
                        <DropdownWithValueLabel
                            selectedItemColor={watch('offer_type')?.value ?? 'text-neutral-300'}
                            disabled={!watch('offer_type')?.value}
                            menuItems={tabs.filter((el) => enabledTabs.includes(el.value))}
                            selectedItem={tabs[0]}
                            setSelected={handleNavigation}
                            zIndex='z-[1]'
                        />
                    </div>
                </div>
            )}
        </section>
    );
}
