import React from 'react';

const UserHeaderIcon = (props) => {
    const { widthOuter, heightOuter, imageIcon, colorIcon, widthIcon, heightIcon } = props;

    return (
        <>
            <div className={`w-${widthOuter} h-${heightOuter} relative mr-2 md:mr-0`}>
                <img
                    src={imageIcon}
                    alt='Avatar'
                />
                <div
                    className={`bg-${colorIcon} w-${widthIcon} h-${heightIcon} rounded-full border-2 border-white absolute -right-0.5 -bottom-0.5`}></div>
            </div>
        </>
    );
};

export default UserHeaderIcon;
