import React, { useCallback } from 'react';
import _ from 'lodash';
import SelectionButton from './SelectionButton';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import APIV2 from '../../../api/axios/APIV2';
import { useSelector } from 'react-redux';

export default function RestaurantOfferControllerButton(props) {
    const {
        setIsFilterActive,
        fetchRestaurantOfferList,
        activeState,
        setActiveState,
        setSelectedOffer,
        selectedOffer,
        setAppliedFilter,
        setPageState,
        fetchOutletWiseOffersList,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleClickDelete = async () => {
        try {
            const response = await APIV2.delete(`restaurants/${selectedRestaurantId}/offers`, {
                params: {
                    offer_ids: selectedOffer,
                },
            });

            if (response.success) {
                fetchRestaurantOfferList();
                fetchOutletWiseOffersList();
                handleCancel();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCancel = () => {
        setSelectedOffer([]);

        setActiveState({ selection: false, edit: false, reArrage: false });
    };

    // eslint-disable-next-line
    const handleSearch = useCallback(
        _.debounce(async (keyword) => {
            setAppliedFilter((prevState) => {
                return { ...prevState, keyword };
            });
            fetchRestaurantOfferList({ keyword, page: 1 });
            setPageState((prevState) => {
                return { ...prevState, restaurantPageState: { ...prevState.restaurantPageState, page: 1 } };
            });
        }, 1000)
    );

    const handleClickEdit = () => {
        setSelectedOffer([]);

        setActiveState((prevState) => {
            return { edit: !prevState.edit, selection: false, reArrage: false };
        });
    };

    return (
        <>
            <section className='flex flex-row items-center justify-between mb-6 md:block md:mb-4'>
                <div className='flex flex-row items-center md:mb-4'>
                    {!activeState.selection && (
                        <div className='w-full min-w-[375px] mr-4 lg:min-w-[298px] lg:mr-2 md:min-w-0'>
                            <DefaultInputField
                                shadow='shadow-xSmall'
                                placeholder='Search offer'
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                enteredValue={handleSearch}
                            />
                        </div>
                    )}

                    <SelectionButton
                        setIsSelectionEnable={(value) =>
                            setActiveState({ selection: value, edit: false, reArrage: false })
                        }
                        isSelectionEnable={activeState.selection}
                        handleClickDelete={handleClickDelete}
                        isDisable={selectedOffer.length === 0}
                        handleClickCancel={handleCancel}
                    />
                </div>

                <div className='flex flex-row items-center'>
                    <div
                        className='md:w-1/2 md:mr-1'
                        onClick={() => {
                            setIsFilterActive((prevState) => !prevState);
                            fetchRestaurantOfferList({ is_offer_active: null, offer_type: null });
                        }}>
                        <LargePrimaryButton
                            label='Filters'
                            leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                            leftIconClick={<FilterIcon fill='#C4BEED' />}
                        />
                    </div>

                    <div
                        className='min-w-[143px] w-full ml-4 lg:min-w-0 lg:w-16 lg:ml-2 md:w-1/2 cursor-pointer md:ml-1'
                        onClick={handleClickEdit}>
                        <LargePrimaryButton
                            label='Edit offer'
                            hideLabel='lg:hidden md:block'
                            leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                            isClicked={activeState.edit}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
