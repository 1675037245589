import React from 'react';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';

export default function TableSelectionSection() {
    return (
        <>
            <div className='flex flex-row items-center mb-4 md:block'>
                <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                    <DropdownWithValueLabel
                        placeholder='Select section'
                        label='Select section'
                        menuItems={[]}
                    />
                </div>
                <div className='w-1/2 ml-2 md:w-full md:ml-0'>
                    <DropdownWithValueLabel
                        label='Select table'
                        placeholder='Select table'
                        menuItems={[]}
                    />
                </div>
            </div>
        </>
    );
}
