import React from 'react';

export default function TableHeader() {
    return (
        <thead className='table table-fixed'>
            <tr className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 h-11'>
                <th className='min-w-[445px] text-left pl-6 shadow-innerShadow'>Table Name</th>
                <th className='min-w-[289px]  pl-6 shadow-innerShadow text-center'>Max Capacity</th>
            </tr>
        </thead>
    );
}
