import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import TableLoader from '../../../../../../Components/ContentLoader/TableLoader';

export default function Loader() {
    return (
        <>
            <TitleDescriptionLoader
                className='max-w-[600px] mt-6 md:max-w-full mb-2'
                descHeight={30}
            />

            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border max-w-[320px] mt-5'>
                <table className='w-full break-words table-row-bg'>
                    <tbody>
                        <TableLoader
                            numberOfRow={6}
                            columns={2}
                        />
                    </tbody>
                </table>
            </div>
        </>
    );
}
