import React from 'react';
import Tag from '../../../../Components/Tag/Tag';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

export default function Selectlanguage() {
    const languageList = useSelector((state) => state.langauge.availableLanguages);

    const languageOptions = languageList.map((language) => ({
        label: language.language_label,
        value: language,
    }));

    const { watch, setValue } = useFormContext();

    const handleSelectionLanguage = (language) => {
        const selectedLanguageList = watch('langauges')?.length
            ? [...watch('langauges'), language.value]
            : [language.value];

        setValue('langauges', selectedLanguageList);
    };

    const removeLanguage = (tag) => {
        const updatedList = watch('langauges')?.filter((el) => el.id !== tag.id);

        setValue('langauges', updatedList);
    };

    return (
        <>
            <DropdownWithValueLabel
                label='(Select languages)'
                placeholder='Select languages'
                labelPaddingB='pb-2'
                labelStyle='paragraph-medium-italic text-neutral-500'
                shadow='shadow-smallDropDownShadow'
                className='mb-1'
                menuItems={
                    languageOptions.filter(
                        (item) => !watch('langauges')?.find((selectedItem) => selectedItem.id === item.value.id)
                    ) ?? []
                }
                hasFixedPlaceholder
                setSelected={handleSelectionLanguage}
            />
            {watch('langauges')?.map((el, index) => (
                <div
                    className='mr-2 inline-block'
                    key={index}>
                    <Tag
                        tag={el.language_label}
                        onClose={() => removeLanguage(el)}
                    />
                </div>
            ))}
        </>
    );
}
