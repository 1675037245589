import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as DineInIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as OrdersIcon } from '../../../Assets/order.svg';
import { ReactComponent as MobileIcon } from '../../../Assets/mobile.svg';
import { ReactComponent as ZomatoIcon } from '../../../Assets/zomato.svg';
import { ReactComponent as SwiggyIcon } from '../../../Assets/swiggy.svg';

export default function FilterDropDown() {
    const menuItems = [
        {
            icon: (
                <DineInIcon
                    stroke='#FAFAFA'
                    height={16}
                    width={16}
                />
            ),
            label: 'Dine-in',
        },
        {
            icon: (
                <OrdersIcon
                    stroke='#FAFAFA'
                    height={16}
                    width={16}
                />
            ),
            label: 'Takeaway',
        },
        {
            icon: (
                <MobileIcon
                    stroke='#FAFAFA'
                    height={16}
                    width={16}
                />
            ),
            label: 'Mobile app',
        },
        {
            icon: (
                <ZomatoIcon
                    stroke='#FAFAFA'
                    height={24}
                    width={24}
                />
            ),
            label: 'Zomato',
        },
        {
            icon: (
                <SwiggyIcon
                    stroke='#FAFAFA'
                    height={24}
                    width={24}
                />
            ),
            label: 'Swiggy',
        },
    ];
    const [dropDownLabel, setDropDownLabel] = useState('All');

    const handleClickMenuItem = (el) => {
        setDropDownLabel(el);
    };

    return (
        <>
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            className={`shadow-xSmall w-full flex flex-row justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none px-4 border-neutral-300 h-12 paragraph-small-regular`}>
                            <div>
                                {dropDownLabel === 'All' ? (
                                    'All'
                                ) : (
                                    <div className='flex flow-row items-center'>
                                        <div
                                            className={`${
                                                !(
                                                    dropDownLabel.label === 'Zomato' || dropDownLabel.label === 'Swiggy'
                                                ) && 'p-1 bg-primary-500'
                                            } rounded`}>
                                            {dropDownLabel.icon}
                                        </div>
                                        <span className='paragraph-small-regular ml-1'>{dropDownLabel.label}</span>
                                    </div>
                                )}
                            </div>
                            <DownArrowIcon className={`drop-down-icon-rotate min-w-[24px] min-h-[24px]`} />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50'>
                            <div
                                className='pt-2 mb-2  cursor-pointer'
                                onClick={() => handleClickMenuItem('All')}>
                                <Menu.Item>
                                    <div className='hover:paragraph-small-semi-bold hover:text-primary-500'>All</div>
                                </Menu.Item>
                            </div>

                            {menuItems.map((el, index) => {
                                return (
                                    <div
                                        className='pt-2 mb-2 cursor-pointer'
                                        key={index}
                                        onClick={() => handleClickMenuItem(el)}>
                                        <Menu.Item>
                                            <div className='flex flow-row items-center'>
                                                <div
                                                    className={`${
                                                        !(el.label === 'Zomato' || el.label === 'Swiggy') &&
                                                        'p-1 bg-primary-500'
                                                    } rounded`}>
                                                    {el.icon}
                                                </div>
                                                <span className='paragraph-small-regular ml-1 hover:paragraph-small-semi-bold hover:text-primary-500'>
                                                    {el.label}
                                                </span>
                                            </div>
                                        </Menu.Item>
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
