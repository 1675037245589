import React, { useEffect, useState } from 'react';
import { IsLargeTabletScreen, IsMobileScreen, IsXlScreen } from '../../Constants/Constants';
import UserDetailsPopup from '../../Components/UserDetailsPopup/UserDetailsPopup';
import Layout from './Components/Layout';
import UploadImagePopup from '../../Components/UploadImagePopup/UploadImagePopup';
import ListControllerSection from './Components/ListControllerSection';
import ListSection from './Components/ListSection/ListSection';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import APIV5 from '../../api/axios/APIV5';

export default function MediaLibrary() {
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [showUploadPopup, setShowUploadPopup] = useState(false);

    const [isSelectionEnable, setIsSelectionEnable] = useState(false);

    const [mediaList, setMediaList] = useState([]);

    const isLargeTabletScreen = IsLargeTabletScreen();

    const isXlScreen = IsXlScreen();

    const resultPerPage = isLargeTabletScreen ? 12 : isXlScreen ? 15 : 18;

    const [paginationState, setPaginationState] = useState({
        lastPage: 1,
        selectedPageNumber: 1,
        resultPerPage: resultPerPage,
    });

    const [selectedMediaList, setSelectedMediaList] = useState([]);

    const [userDetail, setUserDetail] = useState({});

    const isMobileScreen = IsMobileScreen();

    const [isLoading, setIsLoading] = useState(true);

    const [appliedFilter, setAppliedFilter] = useState({ image_type: '', tag_id: null });

    const fetchMedia = async (resultPerPage, pageNumber, extraParams) => {
        setIsLoading(true);

        const params = {
            per_page: resultPerPage ?? paginationState.resultPerPage,
            page: pageNumber ?? paginationState.selectedPageNumber,
            ...(appliedFilter.image_type || appliedFilter.tag_id ? appliedFilter : {}),
            ...(extraParams ?? {}),
        };

        for (const key in params) {
            if (params[key] === null) {
                delete params[key];
            }
        }

        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/images`, { params });

            setMediaList(response.images.data);

            setPaginationState((prevState) => {
                return { ...prevState, lastPage: response.images.last_page };
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMedia();
        // eslint-disable-next-line
    }, []);

    const handlePageChange = (selectedPageNumber) => {
        setPaginationState((prevState) => {
            return { ...prevState, selectedPageNumber };
        });

        fetchMedia(paginationState.resultPerPage, selectedPageNumber);
    };

    const handleChangeResultPerPage = (resultPerPage) => {
        setPaginationState((prevState) => {
            return { ...prevState, resultPerPage, selectedPageNumber: 1 };
        });

        fetchMedia(resultPerPage, 1);
    };

    const handleClickUploadImagePopupClose = () => {
        setShowUploadPopup(false);
    };

    return (
        <>
            <Layout
                setShowUploadPopup={setShowUploadPopup}
                fetchMedia={fetchMedia}
                setAppliedFilter={setAppliedFilter}
                appliedFilter={appliedFilter}
                setPaginationState={setPaginationState}
                showList={!(isMobileScreen && showUploadPopup)}>
                <ListControllerSection
                    setIsSelectionEnable={setIsSelectionEnable}
                    isSelectionEnable={isSelectionEnable}
                    selectedMediaList={selectedMediaList}
                    fetchMedia={fetchMedia}
                    setSelectedMediaList={setSelectedMediaList}
                />

                <ListSection
                    list={mediaList}
                    paginationState={paginationState}
                    handlePageChange={handlePageChange}
                    handleChangeResultPerPage={handleChangeResultPerPage}
                    isSelectionEnable={isSelectionEnable}
                    setSelectedMediaList={setSelectedMediaList}
                    selectedMediaList={selectedMediaList}
                    setUserDetail={setUserDetail}
                    isLoading={isLoading}
                    setShowUploadPopup={setShowUploadPopup}
                    resultPerPage={resultPerPage}
                />
            </Layout>

            {showUploadPopup && (
                <UploadImagePopup
                    handleClickClose={handleClickUploadImagePopupClose}
                    fetchData={fetchMedia}
                    id={showUploadPopup}
                />
            )}

            {!_.isEmpty(userDetail) && (
                <UserDetailsPopup
                    userDetails={userDetail}
                    handleClickClose={() => setUserDetail({})}
                />
            )}
        </>
    );
}
