import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import ListLoader from '../../../Components/ListLoader';
import { Categories } from '../../../Components/Card';
import HasMoreCard from '../../../Components/HasMoreCard';
import { fetchCategoryList, fetchDishList, filterCategorySubCategoryDishId, getFoodType } from '../../../functions';
import SubCategoryCard from './SubCategoryCard';

export default function CategorySection(props) {
    const {
        setSelectedCategoryDish,
        selectedCategoryDish,
        selectedCategory,
        setAllCategoryDish,
        selectedDish,
        allCategoryDish,
        setSelectedCategory,
        setSelectedDish,
    } = props;

    const navigate = useNavigate();

    const goToAddCategory = (value) => {
        navigate('/menu/add-category', { state: { pageName: value } });
    };

    const [isLoading, setIsLoading] = useState(true);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const screenSize = useWindowSize();

    const [paginationState, setPaginationState] = useState({ page: 1, per_page: 10, has_more_pages: null });

    const [categoryList, setCategoryList] = useState([]);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            setPaginationState((prevState) => ({ ...prevState, has_more_pages: category.has_more_pages }));

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: category?.has_more_pages,
                last_page: category?.last_page,
            }));

            if (params.page === 1) {
                setCategoryList(category?.data);
                setAllCategoryDish(category?.data);
            } else {
                setCategoryList((prevState) => [...prevState, ...category?.data]);
                setAllCategoryDish((prevState) => [...prevState, ...category?.data]);
            }

            params.page === 1 &&
                setSelectedCategoryDish((prevState) => ({
                    ...prevState,
                    category: category?.data?.[0],
                    subCategory: category?.data[0]?.sub_categories,
                }));

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        fetchCategory(paginationState);
    }, []);

    const containerRef = useRef(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const [showSubCategory, setShowSubCategory] = useState(null);

    const getSumSubcategoryDish = (subCategories) => {
        return subCategories.reduce((total, category) => total + category.total_items, 0);
    };

    const getSelectionState = (categoryId, hasSubCategory) => {
        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        if (hasSubCategory) {
            const subCategory = filteredId?.find((el) => el.categoryId === categoryId)?.subCategory;

            if (!!subCategory?.length) {
                const allDishId = subCategory?.flatMap((el) => el?.dish?.map((dishEl) => dishEl.id));

                const isFullSelected = allDishId?.every((id) => selectedDish?.includes(id));

                const isIndeterminateSelected = !isFullSelected && allDishId?.some((id) => selectedDish?.includes(id));

                return { isFullSelected, isIndeterminateSelected };
            }
        }

        const dish = filteredId?.find((el) => el.categoryId === categoryId)?.dish;

        const dishIds = dish?.map((d) => d.id);

        const isFullSelected = !!dishIds?.length ? dishIds?.every((id) => selectedDish?.includes(id)) : false;

        const isIndeterminateSelected = !isFullSelected && dishIds?.some((id) => selectedDish?.includes(id));

        return { isFullSelected, isIndeterminateSelected };
    };

    const fetchSingleDishList = async (params, categoryId) => {
        try {
            const response = await fetchDishList(selectedRestaurantId, params, categoryId);

            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const [selectionLoadingCategory, setSelectionLoadingCategory] = useState([]);

    const getAllDishes = async (category, isSelected) => {
        const fetchedDish = allCategoryDish.find((el) => el.category_id === category.category_id)?.dish;

        if (!fetchedDish?.length) {
            setSelectionLoadingCategory((prevState) => [...prevState, category.category_id]);

            let dishList = [];

            await Promise.all(
                Array.from({ length: Math.ceil(category.total_items / 10) }, (_, index) =>
                    fetchSingleDishList({ page: index + 1, per_page: 10 }, category.category_id).then((dishes) => {
                        dishList.push(...dishes);
                    })
                )
            );

            const dishListId = dishList.map((el) => el.item_id);

            if (isSelected) {
                setSelectedDish((prevState) => [...prevState, ...dishListId]);
            } else {
                setSelectedDish((prevState) => prevState.filter((el) => dishListId.includes(el)));
            }

            const newAllCategoryDishState = allCategoryDish.map((el) => {
                if (el.category_id === category.category_id) {
                    return { ...el, dish: dishList };
                }

                return el;
            });

            setAllCategoryDish(newAllCategoryDishState);

            setSelectionLoadingCategory((prevState) => prevState.filter((el) => el !== category.category_id));
        }
    };

    const promiseAllSubcategoryDish = async (subcategory) => {
        let dishList = [];

        await Promise.all(
            Array.from({ length: Math.ceil(subcategory.total_items / 10) }, (_, index) =>
                fetchSingleDishList({ page: index + 1, per_page: 10 }, subcategory.category_id).then((dishes) => {
                    dishList.push(...dishes);
                })
            )
        );

        return dishList;
    };

    const getSubcategoryAllDish = async (subCategory) => {
        const newSubCategory = await Promise.all(
            subCategory.map(async (el) => {
                const dishList = await promiseAllSubcategoryDish(el);
                return { ...el, dish: dishList };
            })
        );

        return newSubCategory;
    };

    const formateAndSetAlCategory = (category, subCategoryList) => {
        const newAllCategoryDishStates = allCategoryDish.map((el) => {
            if (el.category_id === category.category_id) {
                return { ...el, sub_categories: subCategoryList };
            }
            return el;
        });

        setAllCategoryDish(newAllCategoryDishStates);
    };

    const getAllSubCategoryDish = async (category, subCategory, isSelected) => {
        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        let subCategoryList = filteredId.find((el) => el.categoryId === category.category_id)?.subCategory;

        let allDishId = subCategoryList?.flatMap((el) => el?.dish?.map((dishEl) => dishEl.id));

        if (!subCategoryList.dish?.length) {
            subCategoryList = await getSubcategoryAllDish(subCategory);

            formateAndSetAlCategory(category, subCategoryList);

            allDishId = subCategoryList?.flatMap((el) => el?.dish?.map((dishEl) => dishEl.item_id));
        }

        if (isSelected) {
            setSelectedDish((prevState) => [...prevState, ...allDishId]);
        } else {
            setSelectedDish((prevSelectedDish) => prevSelectedDish.filter((id) => !allDishId.includes(id)));
        }
    };

    const onSelectCategory = (category, subCategory, isSelected) => {
        if (!!subCategory?.length) {
            const subCategoryIdList = subCategory.map((el) => el.category_id);

            !selectedCategory.includes(category.category_id)
                ? setSelectedCategory((prevState) => [...prevState, ...subCategoryIdList])
                : setSelectedCategory((prevState) => prevState.filter((el) => !subCategoryIdList.includes(el)));
        }

        !selectedCategory.includes(category.category_id)
            ? setSelectedCategory((prevState) => [...prevState, category.category_id])
            : setSelectedCategory((prevState) => prevState.filter((el) => el !== category.category_id));

        const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

        const dish = filteredId?.find((el) => el.categoryId === category.category_id)?.dish;

        if (!dish && !subCategory.length) {
            getAllDishes(category, isSelected);
            return;
        }

        if (!!subCategory.length) {
            getAllSubCategoryDish(category, subCategory, isSelected);
            return;
        }

        const dishIds = dish?.map((el) => el.id);

        if (isSelected) {
            setSelectedDish((prevState) => [...prevState, ...dishIds]);
        } else {
            setSelectedDish((prevSelectedDish) => prevSelectedDish.filter((id) => !dishIds.includes(id)));
        }
    };

    const showingLoader = () => {};

    return (
        <>
            <div className='flex flex-row justify-between w-full items-center md:pb-2 pb-4'>
                <span className='paragraph-large-medium text-[#000000]'>Categories</span>
            </div>

            {!isLoading && !categoryList.length && (
                <div
                    className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                    style={{ height: screenSize.height - 325 }}>
                    No dishes are available
                </div>
            )}

            <div
                ref={containerRef}
                onScroll={handleScroll}
                className='overflow-auto scrollbar-style mr-4 pr-8'
                style={{ height: screenSize.height - 325 }}>
                {categoryList?.map((el, index) => {
                    const totalItems = !!el.sub_categories.length
                        ? getSumSubcategoryDish(el.sub_categories)
                        : el.total_items;

                    const { isFullSelected, isIndeterminateSelected } = getSelectionState(
                        el.category_id,
                        !!el.sub_categories.length
                    );

                    showingLoader(el.category_id);

                    return (
                        <div key={index}>
                            <div
                                className={`mt-4 w-full max-w-[301px] md:max-w-full md:mt-2 relative `}
                                key={index}>
                                <Categories
                                    minWidth='min-w-[273px]'
                                    img={el.image_url}
                                    title={el.internal_name}
                                    totalItems={totalItems}
                                    type={getFoodType(el)}
                                    isActive={el.category_id === selectedCategoryDish?.category?.category_id}
                                    handleClickCategory={() => {
                                        setSelectedCategoryDish((prevState) => ({
                                            ...prevState,
                                            category: el,
                                            subCategory: el.sub_categories,
                                            ...(el.sub_categories.length ? {} : { subCategoryId: null }),
                                        }));
                                        !el.sub_categories.length && setShowSubCategory(null);
                                    }}
                                    isEdit={false}
                                    handleClickEdit={() => goToAddCategory('editCategory')}
                                    isRearrange={false}
                                    isFromSelection={true}
                                    subCategoryNumber={el.sub_categories.length}
                                    setShowSubCategory={() =>
                                        setShowSubCategory((prevState) => {
                                            return !!prevState ? null : el.category_id;
                                        })
                                    }
                                    showSubCategory={showSubCategory === el.category_id}
                                    onChange={(isSelected) => onSelectCategory(el, el.sub_categories, isSelected)}
                                    isChecked={isFullSelected}
                                    isIndeterminateSelected={isIndeterminateSelected}
                                />
                            </div>

                            {!!el.sub_categories.length && showSubCategory === el.category_id && (
                                <SubCategoryCard
                                    selectedCategoryDish={selectedCategoryDish}
                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                    subCategory={el.sub_categories}
                                    onSelectCategory={onSelectCategory}
                                    selectedCategory={selectedCategory}
                                    selectedDish={selectedDish}
                                    allCategoryDish={allCategoryDish}
                                    setSelectedDish={setSelectedDish}
                                />
                            )}
                        </div>
                    );
                })}

                {paginationState?.has_more_pages && <HasMoreCard />}

                {isLoading && <ListLoader className='max-w-[303px] h-[104px]' />}
            </div>
        </>
    );
}
