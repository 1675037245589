import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../../Assets/chevron-down.svg';

export default function AvailableRiderDropdown(props) {
    const {
        openFromBottom,
        shadow,
        placeholder,
        selectedItem,
        setSelectedItemFromDropDown,
        labelStyle,
        labelPaddingB,
        label,
        disabled,
        boxPadding,
        height,
        selectedItemStyle,
        menuItems,
        itemsStyle,
    } = props;

    const [menuItem, setmenuItem] = useState(placeholder ?? selectedItem ?? menuItems[0]);

    const handleClickItem = (value) => {
        setmenuItem(value);
        setSelectedItemFromDropDown && setSelectedItemFromDropDown(value);
        setSelectedItemColor('');
    };

    const [selectedItemColor, setSelectedItemColor] = useState(placeholder && 'text-neutral-300');

    const setStatus = (active) => {
        return active ? 'bg-tertiary-800' : 'bg-destructive-600';
    };

    return (
        <>
            {label && (
                <div
                    className={`${labelStyle ?? 'paragraph-small-medium'} ${labelPaddingB ?? 'pb-1'} ${
                        disabled && 'text-neutral-300'
                    }`}>
                    {label}
                </div>
            )}
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropDownIcon'>
                        <Menu.Button
                            disabled={disabled}
                            className={`${
                                shadow ?? 'shadow-xSmall'
                            } w-full flex flex-row items-center justify-between mobile:max-w-full rounded-md outline-none boxRing border py-3 appearance-none ${
                                boxPadding ?? 'px-4'
                            } border-neutral-300 ${height ?? 'h-12'} ${
                                selectedItemStyle ?? 'paragraph-small-regular'
                            }`}>
                            <span className={selectedItemColor}>{menuItem}</span>
                            <DownArrowIcon
                                className='drop-down-icon-rotate'
                                height={24}
                                width={24}
                                fill={disabled ? '#D3D2D8' : '#131126'}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50 ${
                                openFromBottom && 'bottom-full mb-2'
                            }`}>
                            {menuItems?.map((el, index) => {
                                const status = setStatus(el.active);

                                return (
                                    <div
                                        className='pt-2 mb-2 cursor-pointer'
                                        key={index}
                                        onClick={() => handleClickItem(el.riderName)}>
                                        <Menu.Item>
                                            <div className='flex flex-row items-center'>
                                                <div className={`h-2 w-2 rounded-full ${status}`} />
                                                <span
                                                    className={`paragraph-small-regular ml-2 active:paragraph-small-semi-bold hover:text-primary-500 ${itemsStyle}`}>
                                                    {el.riderName}
                                                </span>
                                                <span className='paragraph-x-small-italic text-neutral-500 ml-2'>
                                                    {el.mobileNumber}
                                                </span>
                                            </div>
                                        </Menu.Item>
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
