import React from 'react';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { BlankComponentTable } from '../../../../../../Components/BlankComponent/BlankComponent';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TableRow from './TableRow';
import { useFormContext } from 'react-hook-form';

export default function TablesComponent(props) {
    const { editTableLayout, selectionVariantId } = props;

    const isMobileScreen = IsMobileScreen();

    const { watch, setValue } = useFormContext();

    const selectedAddon = watch(`${selectionVariantId}`)?.map((el) => {
        return {
            label: el.label,
            value: el?.value,
            id: el?.value?.addon_group_id,
        };
    });

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const updateSequence = (active, over) => {
        // Get the current index of the active item and the over item
        const oldIndex = selectedAddon.findIndex((item) => item.id === active.id);
        const newIndex = selectedAddon.findIndex((item) => item.id === over.id);

        const newSelectedAddon = arrayMove(selectedAddon, oldIndex, newIndex);

        setValue(`${selectionVariantId}`, newSelectedAddon);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            updateSequence(active, over);
        }
    };

    return (
        <>
            {!isMobileScreen && (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mb-4 max-w-[598px]'>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToParentElement,
                            restrictToFirstScrollableAncestor,
                        ]}>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[265px] lg:min-w-[231px] paragraph-overline-small text-neutral-700'>
                                        ADD-ON GROUP NAME
                                    </th>
                                    <th className='px-6 min-w-[331px] lg:min-w-[227px] paragraph-overline-small text-neutral-700'>
                                        ADD-ON DISHES & PRICES
                                    </th>
                                </tr>
                            </thead>

                            {selectedAddon?.length ? (
                                <tbody>
                                    <SortableContext
                                        items={selectedAddon}
                                        strategy={verticalListSortingStrategy}
                                        disabled={!editTableLayout}>
                                        {selectedAddon?.map((el, index) => {
                                            return (
                                                <TableRow
                                                    {...el}
                                                    isRearrange={editTableLayout}
                                                    key={el.id}
                                                    index={index}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </tbody>
                            ) : (
                                <BlankComponentTable colSpan={2} />
                            )}
                        </table>
                    </DndContext>
                </div>
            )}
        </>
    );
}
