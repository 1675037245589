import React from 'react';
import ContentLoader from 'react-content-loader';

const OfferDetailsPopuploader = () => {
    return (
        <ContentLoader
            width='652px'
            height='720px'
            className=' w-fit rounded-md'>
            <rect
                x='6'
                y='5'
                rx='4'
                ry='4'
                width='400'
                height='35'
            />
            <rect
                x='545'
                y='5'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='6'
                y='55'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            <rect
                x='6'
                y='75'
                rx='4'
                ry='4'
                width='130'
                height='30'
            />
            <rect
                x='6'
                y='115'
                rx='4'
                ry='4'
                width='130'
                height='30'
            />
            <rect
                x='150'
                y='115'
                rx='4'
                ry='4'
                width='160'
                height='30'
            />
            <rect
                x='6'
                y='155'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='190'
                y='155'
                rx='4'
                ry='4'
                width='160'
                height='30'
            />
            <rect
                x='6'
                y='195'
                rx='4'
                ry='4'
                width='150'
                height='30'
            />
            <rect
                x='170'
                y='195'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='6'
                y='245'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            <rect
                x='6'
                y='265'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='6'
                y='305'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='190'
                y='305'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='6'
                y='345'
                rx='4'
                ry='4'
                width='190'
                height='30'
            />
            <rect
                x='210'
                y='345'
                rx='4'
                ry='4'
                width='100'
                height='30'
            />
            <rect
                x='6'
                y='395'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            <rect
                x='6'
                y='415'
                rx='4'
                ry='4'
                width='190'
                height='30'
            />
            <rect
                x='6'
                y='455'
                rx='4'
                ry='4'
                width='190'
                height='30'
            />
            <rect
                x='210'
                y='455'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='6'
                y='495'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='190'
                y='495'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='6'
                y='535'
                rx='4'
                ry='4'
                width='190'
                height='30'
            />
            <rect
                x='210'
                y='535'
                rx='4'
                ry='4'
                width='250'
                height='30'
            />
            <rect
                x='6'
                y='585'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            <rect
                x='6'
                y='605'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='6'
                y='645'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='190'
                y='645'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='6'
                y='685'
                rx='4'
                ry='4'
                width='190'
                height='30'
            />
            <rect
                x='210'
                y='685'
                rx='4'
                ry='4'
                width='250'
                height='30'
            />
        </ContentLoader>
    );
};

export default OfferDetailsPopuploader;
