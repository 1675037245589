import React, { useEffect } from 'react';
import MetaTagDropdown from './MetaTagDropdown';
import { useState } from 'react';

export const InputFieldMetaTag = (props) => {
    const { disabled, value, label, menuItems, placeholder, onChange } = props;
    const [inputValue, setInputValue] = useState('');

    const handleChangeTag = (tag) => {
        const metaTag = `%${tag}%`;
        const newValue = `${value ?? ''}${metaTag} `;
        onChange(newValue);
    };

    return (
        <>
            <div className='flex flex-row items-center justify-between w-full mb-0.5'>
                <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
                <MetaTagDropdown
                    menuItems={menuItems}
                    handleChangeTag={handleChangeTag}
                    disabled={disabled}
                />
            </div>
            <div className=''>
                <input
                    placeholder={placeholder}
                    className='w-full shadow-xSmall h-12 paragraph-small-regular border rounded-md disabled:bg-white border-neutral-300 pl-4 pr-4 placeholder:text-neutral-300 focus:border-primary-500 outline-none focus:outline-none focus:ring-4 focus:ring-primary-100 caret-primary-500 placeholder:paragraph-small-regular'
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                    value={value ?? ''}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export const InputAreaMetaTag = (props) => {
    const { disabled, value, label, menuItems, placeholder } = props;

    const [inputValue, setInputValue] = useState('');

    const handleChangeTag = (value) => {
        setInputValue(inputValue + ' ' + value + ' ');
    };
    const handleChangeInputValue = (value) => {
        setInputValue(value);
    };
    useEffect(() => {
        disabled && setInputValue('');
    }, [disabled]);

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    return (
        <>
            <div className='flex flex-row items-center justify-between w-full mb-0.5'>
                <span className={`paragraph-small-medium ${disabled && 'text-neutral-300'}`}>{label}</span>
                <MetaTagDropdown
                    menuItems={menuItems}
                    handleChangeTag={handleChangeTag}
                    disabled={disabled}
                />
            </div>
            <div className=''>
                <textarea
                    placeholder={placeholder}
                    className='w-full h-[88px] shadow-xSmall paragraph-small-regular border disabled:bg-white rounded-md border-neutral-300 px-4 py-3 placeholder:text-neutral-300 focus:border-primary-500 outline-none focus:outline-none focus:ring-4 focus:ring-primary-100 caret-primary-500 placeholder:paragraph-small-regular md:h-[104px]'
                    onChange={(e) => handleChangeInputValue(e.target.value)}
                    value={inputValue}
                    disabled={disabled}
                />
            </div>
        </>
    );
};
