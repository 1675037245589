import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from './Layout';
import { FormProvider, useForm } from 'react-hook-form';
import BasicDetails from './BasicDetails';
import AdvanceSetting from './AdvanceSetting';
import ExposeDish from '../../AddDish/TabComponents/ExposeDish';
import DishTiming from '../../AddDish/TabComponents/DishTiming';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function DishWithUseForm(props) {
    const componentToMap = {
        'basic-details': BasicDetails,
        'expose-category': ExposeDish,
        'category-timings': DishTiming,
        'advance-setting': AdvanceSetting,
    };

    const { editId } = useParams();

    const [currentPage, setCurrentPage] = useState('basic-details');

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const defaultTiming = orderingMode.reduce((acc, mode) => {
        acc[`timings_${mode.id}_timingType`] = { label: 'Full time', value: 'no_time_slots' };
        return acc;
    }, {});

    const [showHeaderTab, setShowHeaderTab] = useState(false);

    const ComponentToRender = componentToMap[currentPage];

    const defaultValues = !_.isEmpty(props?.defaultValues)
        ? props?.defaultValues
        : {
              exposeDish: [],

              advanceSetting: {
                  mask: { label: 'Mask', value: 1 },
                  hideCategory: { label: 'Show', value: 0 },
                  tileDetails: { label: 'Show', value: 0 },
                  displayTheme: { label: 'Square', value: 'square_image' },
                  tilePosition: { label: 'Top left', value: 'top-left' },
              },

              ...defaultTiming,
          };

    const methods = useForm({
        defaultValues,
    });

    const propsForExposeCategory = {
        title: 'Expose category',
        description:
            'Select the ordering modes in which you would like the category to appear to the in-house workers and the customers.',
    };

    const propsForCategoryTiming = {
        title: 'Category timings',
        description: 'Set the availability of category throughout the day and week according to the ordering modes.',
    };

    const navigate = useNavigate();

    useEffect(() => {
        editId ? navigate(`/menu/edit-category/${editId}/basic-details`) : navigate('/menu/add-category/basic-details');
    }, []);

    return (
        <>
            <FormProvider {...methods}>
                <Layout
                    showHeaderTab={showHeaderTab}
                    setCurrentPage={setCurrentPage}>
                    {ComponentToRender ? (
                        <ComponentToRender
                            setShowHeaderTab={setShowHeaderTab}
                            {...(currentPage === 'expose-category' ? propsForExposeCategory : {})}
                            {...(currentPage === 'category-timings' ? propsForCategoryTiming : {})}
                        />
                    ) : (
                        <div>Invalid Tab</div>
                    )}
                </Layout>
            </FormProvider>
        </>
    );
}
