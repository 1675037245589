import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as LinearMenuIcon } from '../../../../../../Assets/linear-menu.svg';

export default function TableRow(props) {
    const { isRearrange, category, id, index, label } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    return (
        <>
            <tr
                ref={setNodeRef}
                style={style}
                key={index}
                {...attributes}
                {...listeners}
                className={`h-[70px] justify-center paragraph-small-regular border-t first:border-none border-neutral-300`}>
                <td className='pl-6'>
                    <div className='flex flex-row items-center'>
                        {isRearrange && (
                            <span className='mr-3'>
                                <LinearMenuIcon />
                            </span>
                        )}

                        <span>{category?.label}</span>
                    </div>
                </td>

                <td className='px-6'>{label}</td>
            </tr>
        </>
    );
}
