import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import APIV3 from '../../../../../api/axios/APIV3';
import SearchableDropdown from '../../../../../Components/SearchableDropdown/SearchableDropdown';
import Tag from '../../../../../Components/Tag/Tag';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function SelectCategorySection() {
    const {
        watch,
        setValue,
        formState: { errors },
        clearErrors,
        setError,
    } = useFormContext();

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const [categoryMenuItems, setCategoryMenuItems] = useState([]);

    const [defaultMenuItems, setDefaultMenuItems] = useState([]);

    const [searchedQuery, setSearchedQuery] = useState('');

    const formatList = (list) => {
        console.log('list ==>', list);

        const formattedList = list.map((el) => ({
            label: el.translations.title[1],
            value: el.internal_name,
            ...el,
        }));

        const selectedCategoryIds = watch('selected_category')?.map((item) => item.category_id) ?? [];

        const filteredList = formattedList.filter((item) => !selectedCategoryIds.includes(item.category_id));

        setCategoryMenuItems(filteredList);

        !searchedQuery && setDefaultMenuItems(filteredList);
    };

    const fetchCategoryList = async (params) => {
        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/categories/internal-name`, {
                params,
            });

            formatList(response.categories);

            setIsLoadingMenuItems(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        const newIdArray = watch('selected_category')?.length
            ? [...watch('selected_category'), selectedItem]
            : [selectedItem];

        setValue('selected_category', newIdArray);

        fieldOnChange(newIdArray);

        const updateMenuItems = categoryMenuItems.filter((item) => item.value !== selectedItem.value);

        clearErrors('selected_category');

        setCategoryMenuItems(updateMenuItems);
    };

    const handleClickClose = (item) => {
        setValue(
            'selected_category',
            watch('selected_category').filter((customerId) => customerId.value !== item.value)
        );

        watch('selected_category').length === 1 &&
            !!errors &&
            setError('selected_category', { message: 'Please fill the field' });

        setCategoryMenuItems((prevState) => [...prevState, item]);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const onCompleteTyping = (searchQuery) => {
        setSearchedQuery(searchQuery);

        searchQuery.length ? handleFetchList(searchQuery) : setCategoryMenuItems([]);
    };

    // eslint-disable-next-line
    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            fetchCategoryList({ keyword: search_query, language_id: 1 });
        }, 1000)
    );

    useEffect(() => {
        fetchCategoryList({ keyword: '', language_id: 1 });
    }, []);

    const categoryMenuItemToPass = !searchedQuery ? defaultMenuItems : categoryMenuItems;

    return (
        <>
            <section className=''>
                <div className='max-w-[312px]'>
                    <Controller
                        render={({ field }) => (
                            <SearchableDropdown
                                onCompleteTyping={onCompleteTyping}
                                menuItems={categoryMenuItemToPass}
                                placeholder='Select category'
                                handleSelect={(selection) => handleSelect(selection, field.onChange)}
                                setIsLoadingMenuItems={setIsLoadingMenuItems}
                                isLoadingMenuItems={isLoadingMenuItems}
                                openFromBottom
                                fixedHeight={categoryMenuItemToPass?.length < 5 ? 'h-fit' : 'h-[200px]'}
                            />
                        )}
                        name='selected_category'
                    />

                    {errors.selected_category && <ErrorMessage errors={errors.selected_category} />}

                    <div className='align-top mt-1'>
                        {watch('selected_category')?.map((el, index) => {
                            if (!el.category_id) {
                                return;
                            }

                            return (
                                <div
                                    className='mr-2 inline-block'
                                    key={index}>
                                    <Tag
                                        tag={el.internal_name}
                                        onClose={() => handleClickClose(el)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}
