import React from 'react';
import { IsLaptopLargeScreen } from '../../../../../Constants/Constants';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import InputSection from '../Components/InputSection';
import DropdownSection from '../Components/DropdownSection';
import AllDaySameTimeSelection from '../../../../../Components/Timings/Components/AllDaySameTimeSelection';
import EachDaySeparateTimeSelection from '../../../../../Components/Timings/Components/EachDaySeparateTimeSelection';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import Loader from './Components/Loader';

export default function PreparationTime(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const isLaptopLargeScreen = IsLaptopLargeScreen();

    const {
        handleSubmit,
        watch,
        control,
        setValue,
        clearErrors,
        formState: { errors, isDirty, isValid },
        reset,
    } = useFormContext();

    const onSubmit = (data) => {
        const formattedData = formatData(data);
        updateTiming(formattedData);
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateTiming = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=preparation_time`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatDifferentTime = (data) => {
        return data
            ?.map((el, index) => {
                return { days_of_week: [index + 1], time_slots: el };
            })
            ?.filter((el) => !!el.time_slots.length);
    };

    const formatData = (data) => {
        if (data.preparation_time.rushHourType.value === menuItemTiming[1].value) {
            return {
                kitchen_preparation_time: data.preparation_time.kitchen_preparation_time,
                rush_hour_time_slot_setting: data.preparation_time.rushHourType.value,
                rush_hour_timings: [{ days_of_week: [1, 2, 3, 4, 5, 6, 7], time_slots: data.rushHourSchedule }],
            };
        }

        if (data.preparation_time.rushHourType.value === menuItemTiming[2].value) {
            return {
                kitchen_preparation_time: data.preparation_time.kitchen_preparation_time,
                rush_hour_time_slot_setting: data.preparation_time.rushHourType.value,
                rush_hour_timings: formatDifferentTime(data.rushHourSchedule),
            };
        }
        return {
            kitchen_preparation_time: data.preparation_time.kitchen_preparation_time,
            rush_hour_time_slot_setting: data.preparation_time.rushHourType.value,
            rush_hour_timings: [],
        };
    };

    const menuItemTiming = [
        { label: 'No rush hours', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const handleTimeSettingSelection = (selection) => {
        clearErrors('chooseOneTimeSlot');
        setShowTabChangeWarningPopup(true);

        switch (selection.value) {
            case menuItemTiming[1].value:
                setValue('rushHourSchedule', [{ start_time: '', end_time: '' }]);
                break;

            case menuItemTiming[2].value:
                for (let index = 0; index < 7; index++) {
                    setValue(`rushHourSchedule.${index}`, []);
                }
                break;

            default:
                break;
        }
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setValue('rushHourSchedule', []);
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='md:pb-20'>
                <div className='mb-4'>
                    <InputSection
                        label='(Average time to prepare the dish)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter time in minutes'
                        shadow='shadow-smallDropDownShadow'
                        title='Kitchen preparation time'
                        description='This time is what your kitchen usually takes to process and order. We ask this every time you accept the order unless you have enabled “Auto accept orders” in the settings.'
                        name='preparation_time.kitchen_preparation_time'
                        rules={{ required: 'Please enter kitchen preparation time' }}
                        inputType='number'
                        handleInputChange={() => setShowTabChangeWarningPopup(true)}
                    />
                </div>

                <div className='mb-4'>
                    <DropdownSection
                        name='preparation_time.rushHourType'
                        openFromBottom={isLaptopLargeScreen ?? true}
                        menuItems={menuItemTiming}
                        shadow='shadow-smallDropDownShadow'
                        title='Kitchen rush hours'
                        description='Set kitchen Rush hours which will override the normal kitchen preparation time during the rush hour time.'
                        rules={{ required: 'Please select kitchen rush hours option' }}
                        placeholder='Select rush hour time'
                        handleSelection={handleTimeSettingSelection}
                    />
                </div>

                <div className='max-w-[636px] md:max-w-full'>
                    {watch('preparation_time.rushHourType')?.value === 'same_time_slot_for_all_days' && (
                        <AllDaySameTimeSelection
                            fieldName='rushHourSchedule'
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            errors={errors}
                            timingTypeName='preparation_time.rushHourType'
                            preparationTimeName='preparation_time_in_rush'
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                        />
                    )}
                </div>

                <div className='max-w-[636px] mb-6'>
                    {watch('preparation_time.rushHourType')?.value === 'different_time_slots_for_different_days' && (
                        <EachDaySeparateTimeSelection
                            fieldName='rushHourSchedule'
                            setValue={setValue}
                            control={control}
                            watch={watch}
                            clearErrors={clearErrors}
                            errors={errors}
                            timingTypeName='preparation_time.rushHourType'
                            preparationTimeName='preparation_time_in_rush'
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}
