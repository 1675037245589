import React, { useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import useOutsideClick from '../../../../Helper/Hooks/useOutsideClick';

export default function Layout(props) {
    const {
        children,
        handleClickClose,
        onSubmit,
        isDelete,
        isEditSection,
        loaderStatus,
        onDelete,
        onEdit,
        errorOfApi,
        isDirty,
    } = props;

    const ref = useRef();
    useOutsideClick(ref, handleClickClose);
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4 md:py-0 md:h-screen md:block'>
                <div
                    className='max-w-[475px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-0 md:py-4 m-auto md:max-w-full'
                    ref={ref}>
                    <Header
                        handleClickClose={handleClickClose}
                        isEditSection={isEditSection}
                    />

                    {children}

                    <Footer
                        onSubmit={onSubmit}
                        isEditSection={isEditSection}
                        loaderStatus={loaderStatus}
                        isDelete={isDelete}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        errorOfApi={errorOfApi}
                        isDirty={isDirty}
                    />
                </div>
            </div>
        </>
    );
}
