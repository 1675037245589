import React from 'react';
import Card from './Card';
import { CardDescription } from './CardComponent';

import { ReactComponent as CashCollection } from '../../../Assets/cash-collection.svg';
import { ReactComponent as Taxes } from '../../../Assets/taxes.svg';
import { ReactComponent as Dowload } from '../../../Assets/download.svg';
import { ReactComponent as UniqeCustomer } from '../../../Assets/unique-customer.svg';

export default function TotalShowingCards(props) {
    const { numberOfOutlet } = props;

    const cardContent = [
        {
            title: 'Mobile Application',
            number: '2,722',
            percentage: '7.2',
            description: (
                <CardDescription
                    focusContent='950'
                    description='Your application was downloaded :focusContent: times more than last week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: 'Total downloads from all cities',
            positive: true,
            icon: (
                <Dowload
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            header: 'Total downloads',
        },
        {
            title: 'Unique customers',
            number: '12,456',
            percentage: '20.2',
            description: (
                <CardDescription
                    focusContent='9,150'
                    description='Your served :focusContent: new customers more than last week'
                    focusContentColor='text-tertiary-800'
                />
            ),
            information: `Total customers of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <UniqeCustomer
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            header: 'Total customers',
        },
        {
            title: 'Refunds',
            number: '0',
            information: `Total refunds of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <CashCollection
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            header: 'Total refunds',
        },
        {
            title: 'Voids',
            number: '0',
            information: `Total voids of ${numberOfOutlet} outlets`,
            positive: true,
            icon: (
                <Taxes
                    height={24}
                    width={24}
                    stroke='#ffffff'
                />
            ),
            header: 'Total voids',
        },
    ];

    return (
        <>
            <div className='align-top px-[21px] lg:px-4 md:px-0'>
                {cardContent.map((el, index) => {
                    return (
                        <div
                            className='inline-block md:block lg:w-1/2 md:w-full align-top lg:even:pl-1 lg:odd:pr-1 md:even:pl-0 md:odd:pr-0'
                            key={index}>
                            <div className='mt-4 mb-2 md:px-4 md:mt-0'>
                                <span className='paragraph-large-medium text-black px-2.5 lg:px-0'>{el.header}</span>
                            </div>

                            <div className='inline-block mx-2.5 my-2 md:w-full md:mx-0 md:px-4 w-[303px] md:my-0 md:mt-2 lg:mx-0 lg:w-full md:mb-4'>
                                <Card {...el} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
