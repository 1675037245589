import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { RadioButton } from '../../../../Components/FormControl/FormControls';

export default function MobileCategoryPopup(props) {
    const { handleClickClose } = props;

    const menuItems = [
        { item: 'All (102 dishes)' },
        { item: 'Pizza (32 dishes)' },
        { item: 'Burger (02 dishes)' },
        { item: 'Salad (07 dishes)' },
        { item: 'Pastries (05 dishes)' },
        { item: 'Juices (12 dishes)' },
        { item: 'Ice creams (32 dishes)' },
        { item: 'Sides (10 dishes)' },
    ];

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex overflow-auto p-4'>
                <div className='max-w-[343px] w-full rounded-xl bg-shades-50 m-auto px-4 pt-4 pb-2'>
                    <div className='flex flex-row justify-between items-center mb-2'>
                        <span className='paragraph-large-medium'>Select category</span>
                        <span
                            className='cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <CloseIcon />
                        </span>
                    </div>

                    {menuItems.map((el, index) => {
                        const itemWithBracket = el.item.match(/\(.+?\)/);
                        const itemWithoutBracket = el.item.replace(/\(.+?\)/, '');

                        return (
                            <div
                                className='pt-2 mb-2'
                                key={index}
                                onClick={() => {}}>
                                <div className='flex items-center cursor-pointer'>
                                    <RadioButton
                                        label={itemWithoutBracket}
                                        checked={true}
                                        paddingL='2'
                                        labelStyle='paragraph-medium-regular'
                                        optionId={index}
                                    />
                                    <span className='pl-1 paragraph-medium-italic text-neutral-500'>
                                        {itemWithBracket}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
