import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../Assets/chevron-down.svg';
import { OrderStatus } from './TableComponent';
import { ReactComponent as SuccessTickIcon } from '../../../../Assets/success-tick.svg';
import { ReactComponent as InfoIcon } from '../../../../Assets/reimbursement.svg';
import { ReactComponent as CancelledIcon } from '../../../../Assets/cancel.svg';

const ListviewCustomerDetails = (props) => {
    const {
        customerName,
        orderDate,
        orderType,
        orderStatus,
        itemOrdered,
        orderAmount,
        paymentMode,
        handleClickViewDetails,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    const statusIconController = (value) => {
        return value === 'Delivered' || value === 'Refund success' ? (
            <SuccessTickIcon
                height={18}
                width={18}
            />
        ) : value === 'Refund initiated' ? (
            <InfoIcon
                height={18}
                width={18}
            />
        ) : (
            <CancelledIcon
                height={18}
                width={18}
            />
        );
    };

    return (
        <>
            <div
                className='w-full px-4 py-3 border border-neutral-300 rounded-md cursor-pointer'
                onClick={handleClickShowDetails}>
                <div className='flex flex-row items-center justify-between'>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>CUSTOMER NAME:</h3>
                        <span className='paragraph-small-regular'>{customerName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center mr-1.5'>
                                <div className='paragraph-small-regular flex flex-row items-center'>
                                    {orderStatus.map((el, index) => {
                                        const icon = statusIconController(el);

                                        return (
                                            <span
                                                className='ml-1.5'
                                                key={index}>
                                                {icon}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className=''>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderDate}</span>
                        </div>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>CUSTOMER TYPE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderType}</span>
                        </div>
                        <div className='pt-2 flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER STATUS:</span>
                            <div className='paragraph-small-regular ml-1 -my-0.5'>
                                <OrderStatus status={orderStatus} />
                            </div>
                        </div>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ITEMS ORDERED:</span>
                            <span className='paragraph-small-regular ml-1'>{itemOrdered}</span>
                        </div>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER AMOUNT:</span>
                            <span className='paragraph-small-regular ml-1'>{orderAmount}</span>
                        </div>

                        <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700'>PAYMENT MODE:</span>
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <span className='paragraph-small-regular ml-1'>{paymentMode}</span>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={handleClickViewDetails}>
                                View details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ListviewCustomerDetails;
