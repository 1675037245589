import React from 'react';
import LargeTitleSwitch from '../../../../../../Components/ToggleSwitch/LargeTitleSwitch';
import { useFormContext } from 'react-hook-form';
import DelaySection from './DelaySection';

export default function EarningsDelaySection() {
    const { watch, setValue, clearErrors } = useFormContext();

    const handleToggleEarningDelay = () => {
        setValue('cashback_settings.sameEarningDelay', true);
    };

    const handleToggle = () => {
        clearErrors([('cashback_settings.same_earning_delay', 'errors.cashback_settings.earning_delay')]);
    };

    return (
        <>
            <LargeTitleSwitch
                classNames='mb-4'
                title='Earnings delay'
                description='Set the delay time for the cashback to be rewarded to the customer on their orders.'
                switchName='cashback_settings.earningDelayEnable'
                handleToggle={handleToggleEarningDelay}
            />

            <LargeTitleSwitch
                switchName='cashback_settings.sameEarningDelay'
                title='Same configuration for all ordering modes'
                classNames='mb-4 max-w-[417px] md:max-w-full'
                disabled={!watch('cashback_settings.earningDelayEnable')}
                handleToggle={handleToggle}
            />

            <DelaySection />
        </>
    );
}
