import React from 'react';
import UserSection from './Components/UserSection';
import { preferenceMenuItem } from '../../../../Constants/Constants';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';

export default function VisibilityAndLinking() {
    return (
        <>
            <DropdownSection
                className='mb-6'
                title='Coupon page'
                description='Select whether you would like to make the offer visible on coupon page.'
                menuItems={preferenceMenuItem}
                name='display_in_cart_tab'
                placeholder='Select your preference'
                rules={{ required: 'Please select preference' }}
            />

            <DropdownSection
                title='Offer tab'
                description='Select whether you would like to make the offer visible in offer tab.'
                menuItems={preferenceMenuItem}
                name='display_in_offers_tab'
                placeholder='Select your preference'
                className='mb-6'
                rules={{ required: 'Please select preference' }}
                zIndex='z-0'
            />

            <UserSection />
        </>
    );
}
