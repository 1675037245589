import React from 'react';
import { ReactComponent as LinkIcon } from '../../Assets/link.svg';

export const Link = (props) => {
    const { linkLabel, linkOfferNumber } = props;

    return (
        <>
            <div className='flex flex-row items-center'>
                <LinkIcon stroke='#6C5DD3' />
                <span className='paragraph-small-regular text-primary-500 mx-1'>{linkLabel ?? 'Link'}</span>
                {(linkOfferNumber || linkOfferNumber === 0) && (
                    <div className='bg-primary-500 flex-col flex text-primary-50 h-5 w-5 rounded-full text-center justify-center pr-[1px]'>
                        <span className='m-auto leading-none items-center paragraph-x-small-semi-bold text-primary-50'>
                            {linkOfferNumber}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};
