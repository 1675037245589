import React from 'react';
import { ReactComponent as CashBackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { TableBlankComponentView } from '../../../../Components/BlankComponent/TableBlankComponentView';

export default function TableSection(props) {
    const { orderDetails } = props;

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-4'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 text-left paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                            <th className='px-6 min-w-[230px] lg:min-w-[185px]'>CUSTOMER NAME</th>
                            <th className='px-6 min-w-[230px] lg:min-w-[163px]'>SIGN-UP DATE</th>
                            <th className='px-6 min-w-[225px] lg:min-w-[200px]'>CASHBACK EARNED</th>
                        </tr>
                    </thead>

                    <tbody>
                        {!!orderDetails.length ? (
                            orderDetails.map((el, index) => (
                                <tr
                                    className='paragraph-small-regular border-t first:border-none even:bg-neutral-50 border-neutral-300 h-[70px] justify-center'
                                    key={index}>
                                    {el?.customer_name ? (
                                        <td className='px-6'>{el?.customer_name}</td>
                                    ) : (
                                        <td className='px-6'>---</td>
                                    )}
                                    {el?.created_at ? (
                                        <td className='px-6'>{el.created_at}</td>
                                    ) : (
                                        <td className='px-6'>---</td>
                                    )}
                                    {el?.points ? (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <CashBackIcon />
                                                <span className='pl-1'>{el.points}</span>
                                            </div>
                                        </td>
                                    ) : (
                                        <td className='px-6'>
                                            <div className='flex flex-row items-center'>
                                                <span className='pl-1'>---</span>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <TableBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
