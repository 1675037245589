import React from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';

const Rating = (props) => {
    const { index, review, responses, referenceValue, maxWidth } = props;

    const getRatingColors = (rating) => {
        const fill = rating > 2 ? '#FEF2F2' : rating < 2 ? '#EBF6F5' : '#FFF5E3';
        const stroke = rating > 2 ? '#EF4444' : rating < 2 ? '#3D8C82' : '#FFA704';
        return { fill, stroke };
    };

    const { fill: iconFill, stroke: iconStroke } = getRatingColors(index);
    const fill = index > 2 ? 'bg-destructive-500' : index < 2 ? 'bg-tertiary-800' : 'bg-secondary-700';
    const ratingLineWidth = referenceValue ? `${(responses / referenceValue) * 100}%` : '0%';

    return (
        <>
            <div
                className='flex flex-row items-center paragraph-x-small-regular my-[3.5px]'
                key={index}>
                <div className='flex flex-row items-center'>
                    <OrderRatingIcon
                        stroke={iconStroke}
                        fill={iconFill}
                        className='h-4 w-4 lg:h-4 lg:w-4'
                    />
                    <span className='ml-1'>{(5 - index).toFixed(1)}</span>
                </div>
                <div className='mx-2 w-full border border-neutral-300 rounded-[20px] h-1.5 relative'>
                    <div
                        className={`absolute h-1.5 rounded-[20px] -left-[1px] -top-[1px] ${fill}`}
                        style={{ width: ratingLineWidth === '100%' ? '210px' : ratingLineWidth }}
                    />
                </div>
                <span>{responses}</span>
            </div>
        </>
    );
};

export const RatingCard = ({ ratingBasedOnStar }) => {
    const referenceValue = ratingBasedOnStar
        ? Object.values(ratingBasedOnStar).reduce((sum, value) => sum + parseInt(value), 0)
        : 0;
    const maxWidth = '210px';

    const content = [
        { responses: ratingBasedOnStar?.count_5 ?? '0', referenceValue, maxWidth },
        { responses: ratingBasedOnStar?.count_4 ?? '0', referenceValue, maxWidth },
        { responses: ratingBasedOnStar?.count_3 ?? '0', referenceValue, maxWidth },
        { responses: ratingBasedOnStar?.count_2 ?? '0', referenceValue, maxWidth },
        { responses: ratingBasedOnStar?.count_1 ?? '0', referenceValue, maxWidth },
    ];

    return (
        <>
            <div className='px-4 py-2 border border-neutral-300 rounded-md h-[140px]'>
                {content.map((el, index) => (
                    <Rating
                        {...el}
                        index={index}
                        key={index}
                    />
                ))}
            </div>
        </>
    );
};
