import React, { useState } from 'react';

import CustomerInformationPopup from './Popup/CustomerInformationPopup';
import TableNumberPopup from './Popup/TableNumberPopup';
import FireDishesPopup from './Popup/FireDishesPopup';
import { IsMobileScreen } from '../../Constants/Constants';

import ConfirnOrderPopup from './Popup/ConfirnOrderPopup';
import CategoriesSection from './Sections/CategoriesSection';
import DishesSection from './Sections/DishesSection';
import OrderSummery from './Sections/OrderSummery';
import AddPaymentPopup from '../Order/Popup/AddPaymentPopup/AddPaymentPopup';
import Layout from './Components/Layout';
import MobileFooter from './Components/MobileFooter';
import MobileCategoryPopup from './Popup/MobileCategoryPopup/MobileCategoryPopup';
import CustomizeVariantPopUp from './Popup/CustomizeVariant/CustomizeVariantPopUp';
import CustomizeAddonPopUp from './Popup/CustomizeAddon/CustomizeAddonPopUp';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';
import ApplyOffers from './Sections/Components/ApplyOffers';
import AddEditNotePopup from './Popup/AddEditNote/AddEditNotePopup';

export default function POS(props) {
    const { handelHoldOrder } = props;

    const [showOrdersView, setShowOrdersView] = useState(false);

    const isMobileScreen = IsMobileScreen();

    const [showPopup, setShowPopup] = useState();

    const showMobileLayout = isMobileScreen && showPopup === 'addPayment';

    return (
        <>
            {!showMobileLayout && (
                <>
                    <Layout>
                        {(!showOrdersView || !isMobileScreen) && (
                            <div className='w-full pt-4 h-full flex flex-col'>
                                <DishesSection setShowPopup={setShowPopup} />

                                {!isMobileScreen && <CategoriesSection />}
                            </div>
                        )}

                        {(showOrdersView || !isMobileScreen) && (
                            <OrderSummery
                                setShowOrdersView={setShowOrdersView}
                                handelHoldOrder={handelHoldOrder}
                                setShowPopup={setShowPopup}
                            />
                        )}
                    </Layout>

                    <MobileFooter setShowOrdersView={setShowOrdersView} />
                </>
            )}

            {showPopup === 'mobileCategory' && <MobileCategoryPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'customizeVariant' && (
                <CustomizeVariantPopUp
                    setShowPopup={setShowPopup}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'customizeAddon' && (
                <CustomizeAddonPopUp
                    setShowPopup={setShowPopup}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'applyOffer' && (
                <ApplyOffers
                    handleClickClose={() => setShowPopup(null)}
                    backToPage='order summery'
                />
            )}

            {showPopup === 'customerInformation' && <OrderDetailsPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'addEditNote' && <AddEditNotePopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'enterCustomerDetails' && (
                <CustomerInformationPopup
                    handleClickClose={() => setShowPopup(null)}
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'enterTableNumber' && <TableNumberPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'confirmOrder' && (
                <ConfirnOrderPopup
                    handleClickClose={() => setShowPopup(null)}
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'fireDishes' && (
                <FireDishesPopup
                    handleClickClose={() => (isMobileScreen ? setShowPopup('confirmOrder') : setShowPopup())}
                />
            )}

            {showPopup === 'addPayment' && <AddPaymentPopup handleClickClose={() => setShowPopup(null)} />}
        </>
    );
}
