import { ReactComponent as EnableIcon } from '../Assets/success-tick.svg';
import { ReactComponent as DisableIcon } from '../Assets/cancel.svg';
import { useWindowSize } from '@uidotdev/usehooks';

export const PageWidth = () => {
    return useWindowSize().width;
};

export const IsPageContentMedium = () => {
    return PageWidth() < 1640;
};

export const IsLaptopLargeScreen = () => {
    return PageWidth() < 1440;
};

export const IsLaptopScreen = () => {
    return PageWidth() < 1354;
};

export const IsXlScreen = () => {
    return PageWidth() < 1279;
};

export const IsIpadScreen = () => {
    return PageWidth() < 1024;
};

export const IsLargeTabletScreen = () => {
    return PageWidth() < 992;
};

export const IsMobileScreen = () => {
    return PageWidth() < 768;
};

export const OutletName = [
    "Domino's Pizza - Master Outlet(All)",
    "Domino's Pizza - Univerisity Road Outlet",
    "Domino's Pizza - Tagore Road Outlet",
    "Domino's Pizza - Raiya Road Outlet",
    "Domino's Pizza - Gondal Road Outlet",
    "Domino's Pizza - Kalawad Road Outlet",
    "Domino's Pizza - Ahmedabad Highway Outlet",
    "Domino's Pizza - Jamnagar Highway Outlet",
];

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const preferenceMenuItem = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
];

export const enableDisableMenuItem = [
    { icon: <EnableIcon stroke='#FAFAFA' />, label: 'Enable', value: true },
    { icon: <DisableIcon stroke='#FAFAFA' />, label: 'Disable', value: false },
];

export const timingMenuItem = [
    { label: 'Full-time', value: 'no_time_slots' },
    { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
    { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
];

export const gstSlab = [
    { label: '0', value: 0 },
    { label: '5%', value: 5 },
    { label: '12%', value: 12 },
    { label: '18%', value: 18 },
    { label: '28%', value: 28 },
];

export const vegNonVegMenuItem = [
    { label: 'Vegetarian', value: 'vegetarian' },
    { label: 'Non - Vegetarian', value: 'non-vegetarian' },
];

export const showHideMenuItems = [
    { label: 'Hide', value: 1 },
    { label: 'Show', value: 0 },
];
