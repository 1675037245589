import React from 'react';
import DropdownSection from '../../../Components/DropdownSection';
import InputSection from '../../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../../Components/Error/ErrorMessage';
import { riderPreferenceMenuItems } from './constants';

const RiderSettingsType = (props) => {
    const {
        title,
        headerBottomLine,
        label,
        inputFields,
        id,
        index,
        enableId,
        setShowTabChangeWarningPopup,
        globalEnableId,
    } = props;

    const {
        formState: { errors },
        clearErrors,
        watch,
        setValue,
    } = useFormContext();

    const handleSelection = (selection) => {
        setShowTabChangeWarningPopup(true);
        !!enableId && setValue(enableId, selection.value);
        clearErrors(id);
    };

    return (
        <>
            <div className='mb-4'>
                <DropdownSection
                    name={`${id}.preference`}
                    title={title}
                    description={headerBottomLine}
                    label={label}
                    placeholder='Please select preference'
                    labelPaddingB='pb-2'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    shadow='shadow-smallDropDownShadow'
                    menuItems={
                        index === 0 || index === 1 ? riderPreferenceMenuItems.slice(1) : riderPreferenceMenuItems
                    }
                    rules={{ required: 'Please select preference' }}
                    handleSelection={handleSelection}
                    dontShowError
                />

                {errors?.[id]?.preference && <ErrorMessage errors={errors?.[id]?.preference} />}
            </div>

            {globalEnableId && (
                <div className='flex flex-row'>
                    <span className='mr-1 paragraph-small-regular'>Use central rider service :</span>

                    <span className='capitalize font-bold'>{watch(globalEnableId)}</span>
                </div>
            )}

            {watch(`${id}.preference`)?.value === true &&
                inputFields?.map((el, index) => {
                    const neededMsgKey = el.id;

                    const keys = neededMsgKey.split('.');

                    let errorMessage = null;

                    let currentError = errors;

                    keys.forEach((key) => {
                        if (currentError && currentError[key]) {
                            currentError = currentError[key];
                            errorMessage = currentError;
                        } else {
                            errorMessage = null;
                        }
                    });

                    return (
                        <div
                            className='max-w-[312px] w-full mb-4 md:max-w-full'
                            key={index}>
                            <InputSection
                                name={el.id}
                                label={el.label}
                                labelStyle={`paragraph-medium-italic`}
                                placeholder={el.placeholder}
                                shadow='shadow-smallDropDownShadow'
                                dontShowError
                                rules={watch(`${id}.preference`)?.value ? { required: 'Please fill this field' } : {}}
                                handleInputChange={() => setShowTabChangeWarningPopup(true)}
                            />

                            {errorMessage && <ErrorMessage errors={errorMessage} />}
                        </div>
                    );
                })}
        </>
    );
};

export default RiderSettingsType;
