import React, { useState } from 'react';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';

import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';
import { ReactComponent as SelectIcon } from '../../../../../Assets/select.svg';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';
import { ReactComponent as ReArrangeIcon } from '../../../../../Assets/re-arrange.svg';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../Components/Buttons/Button';
import ConfirmDeletePopup from '../../../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import { deleteCategory, deleteDish } from '../functions';
import { useSelector } from 'react-redux';

export default function ControllerButton(props) {
    const { setPageState, pageState, selectedDishId, selectedCategoryId } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const editButton = {
        label: 'Edit',
        onClick: () => setPageState((prevState) => ({ reArrange: false, selection: false, edit: !prevState.edit })),
        leftIconDefault: <EditIcon stroke='#FFFFFF' />,
        leftIconClick: <EditIcon stroke='#C4BEED' />,
    };

    const reArrangeButton = {
        label: 'Re-arrange',
        onClick: () =>
            setPageState((prevState) => ({ edit: false, selection: false, reArrange: !prevState.reArrange })),
        leftIconDefault: <ReArrangeIcon stroke='#FFFFFF' />,
        leftIconClick: <ReArrangeIcon stroke='#C4BEED' />,
    };

    const addOnGroupsButton = {
        label: 'Add-on groups',
    };

    const createPresetButton = {
        label: 'Create preset',
        isDefault: false,
        leftIconDefault: <AddIcon stroke='#FFFFFF' />,
        leftIconClick: <AddIcon stroke='#C4BEED' />,
    };

    const headerButton = [editButton, reArrangeButton, addOnGroupsButton, createPresetButton];

    const handleClickCancelDelete = () => {
        setPageState({
            edit: false,
            reArrange: false,
            selection: false,
        });
    };

    const handleDelete = () => {
        // deleteCategory(selectedRestaurantId);
        // deleteDish(selectedRestaurantId);
    };

    const isButtonClicked = (button) => {
        let isClicked;

        switch (button.label) {
            case 'Edit':
                isClicked = pageState.edit;
                break;

            case 'Re-arrange':
                isClicked = pageState.reArrange;
                break;

            default:
                isClicked = false;
                break;
        }

        return isClicked;
    };

    return (
        <>
            <div className='flex flex-row justify-between mb-6 md:mb-4 xl:block'>
                <div className='flex flex-row md:mb-1 items-center xl:mb-4'>
                    {pageState.selection ? (
                        <div className='flex flex-row items-center sm:mt-4 md:ml-4 sm:ml-0'>
                            <div
                                className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                                onClick={handleClickCancelDelete}>
                                <LargeTertiaryButton
                                    isDefault={false}
                                    label='Cancel'
                                />
                            </div>
                            <div
                                className='max-w-[192px] md:max-w-full w-1/2'
                                onClick={() => setShowConfirmDeletePopup(true)}>
                                <LargeDestructiveButton
                                    label='Delete'
                                    isDefault={false}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='min-w-[375px] mr-4 w-full pageContentSmall:min-w-[330px] xl:max-w-[298px] pageContentSmall:mr-2 md:max-w-full xl:min-w-0'>
                                <DefaultInputField
                                    placeholder='Search category or dish'
                                    placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                    shadow='shadow-xSmall'
                                />
                            </div>

                            <div
                                className='max-w-[156px] md:max-w-none md:w-[64px]'
                                onClick={() =>
                                    setPageState((prevState) => ({
                                        edit: false,
                                        reArrange: false,
                                        selection: !prevState.selection,
                                    }))
                                }>
                                <LargePrimaryButton
                                    label='Bulk select'
                                    hideLabel='md:hidden'
                                    leftIconDefault={<SelectIcon stroke='#ffffff' />}
                                    leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className='flex flex-row xl:justify-end md:block md:pt-0 items-center'>
                    {headerButton.map((el, index) => (
                        <div
                            className='ml-4 pageContentSmall:ml-2 md:ml-0 md:inline-block md:w-1/2 even:md:pl-2 md:mt-2 md:align-top cursor-pointer odd:md:pr-2'
                            onClick={el.onClick}
                            key={index}>
                            <LargePrimaryButton
                                label={el.label}
                                leftIconClick={el.leftIconClick}
                                isDefault={el.isDefault}
                                leftIconDefault={el.leftIconDefault}
                                hideLabel='mobile:text-sm'
                                isClicked={isButtonClicked(el)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    handleClickClose={() => setShowConfirmDeletePopup(false)}
                    description='Would you like to delete selected category and dish?'
                    header='Delete selected category and dish'
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
}
