import React, { useEffect, useState } from 'react';
import RestaurantList from './Components/RestaurantList';
import Header from './Components/Header';
import HeaderBottons from './Components/HeaderBottons';
import FilterComponent from './Components/FilterComponent';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoutPopup from '../SidebarMenu/Popup/LogoutPopup/LogoutPopup';
import { SET_AUTH_TOKEN } from '../../reduxStore/types/auth';
import { useDispatch } from 'react-redux';

export default function SelectRestaurant() {
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);

    const [showFilters, setShowFilters] = useState(false);

    const [showGrid, setShowGrid] = useState(true);

    const [availableFilters, setAvailableFilters] = useState();

    const [filteredRestaurantsList, setFilteredRestaurantsList] = useState([]);

    const [searchedItem, setSearchedItem] = useState(null);

    const [appliedFilter, setAppliedFilter] = useState();

    const [restaurantsList, setRestaurantsList] = useState([]);

    const [openOutletsRestaurantIndex, setOpenOutletsRestaurantIndex] = useState([]);

    const handleClickTab = (value) => {
        setShowGrid(value === 'gridView');
    };

    useEffect(() => {
        if (!user) {
            navigate('/login');
            return;
        }

        fetchRestauranList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!appliedFilter && !searchedItem) {
            return;
        }

        let filteredRestaurants = [...restaurantsList];

        if (searchedItem) {
            filteredRestaurants = getRestaurantsOutletBySearch(filteredRestaurants);
        }

        if (appliedFilter) {
            if (appliedFilter?.state !== null || appliedFilter?.city !== null) {
                filteredRestaurants = getRestaurantsOutletByCityStateFilter(filteredRestaurants);
            }

            const orderingModeFilter = appliedFilter?.modes?.filter((el) => el.status !== null);

            if (orderingModeFilter?.length !== 0) {
                filteredRestaurants = getRestaurantsOutletByOrderimgModeFilter(orderingModeFilter, filteredRestaurants);
            }
        }

        setFilteredRestaurantsList(filteredRestaurants);

        //eslint-disable-next-line
    }, [appliedFilter, searchedItem, restaurantsList]);

    useEffect(() => {
        getFilterData();
        //eslint-disable-next-line
    }, [restaurantsList]);

    const getFilterData = () => {
        const filters = {
            state: [],
            orderingModes: [],
        };

        restaurantsList.forEach((restaurant) => {
            restaurant.outlets.forEach((outlet) => {
                if (!filters.state.some((stateObj) => stateObj.name?.toLowerCase() === outlet.state?.toLowerCase())) {
                    filters.state.push({ name: outlet.state, city: [] });
                }

                const stateObj = filters.state.find(
                    (stateObj) => stateObj.name?.toLowerCase() === outlet.state?.toLowerCase()
                );
                if (!stateObj.city.includes(outlet.city)) {
                    stateObj.city.push(outlet.city);
                }

                outlet.ordering_modes.forEach((mode) => {
                    const displayName = mode.type.toLowerCase().replace('_', ' ');

                    if (!filters.orderingModes.includes(displayName)) {
                        filters.orderingModes.push(displayName);
                    }
                });
            });
        });

        setAvailableFilters(filters);

        setAppliedFilter({
            state: null,
            city: null,
            modes: filters.orderingModes.map((el, index) => {
                return {
                    mode: el,
                    status: appliedFilter?.modes[index]?.status ?? null,
                };
            }),
        });
    };

    const fetchAllOutlets = async (restaurant) => {
        try {
            let response = await APIV5.get(`/users/restaurants/${restaurant.id}`);
            setRestaurantsList((prevState) => [...prevState, { ...restaurant, outlets: response.outlets }]);
            setFilteredRestaurantsList((prevState) => [...prevState, { ...restaurant, outlets: response.outlets }]);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchRestauranList = async () => {
        try {
            let response = await APIV5.get('/users/restaurants');
            await response.restaurants.forEach((restaurant) => fetchAllOutlets(restaurant));
        } catch (error) {
            console.log(error);
        }
    };

    const getRestaurantsOutletBySearch = (restaurants) => {
        const filteredElement = restaurants
            .map((el) => {
                const restaurantMatch = el.name.toLowerCase().includes(searchedItem?.toLowerCase());

                const outletMatches = el.outlets.filter((outlet) =>
                    outlet.legal_name?.toLowerCase().includes(searchedItem?.toLowerCase())
                );

                if (restaurantMatch) {
                    return el;
                } else if (outletMatches.length > 0) {
                    const filteredRestaurant = { ...el };
                    filteredRestaurant.outlets = outletMatches;
                    return filteredRestaurant;
                }
            })
            .filter((item) => item !== undefined);

        return filteredElement;
    };

    const getRestaurantsOutletByCityStateFilter = (restaurants) => {
        const matchingOutlets = restaurants.reduce((result, el) => {
            const matchingOutlet = el.outlets.find(
                (outlet) =>
                    (appliedFilter?.state !== null
                        ? outlet?.state?.toLowerCase() === appliedFilter?.state?.toLowerCase()
                        : true) &&
                    (appliedFilter?.city !== null
                        ? outlet?.city?.toLowerCase() === appliedFilter?.city?.toLowerCase()
                        : true)
            );

            if (matchingOutlet) {
                const filteredRestaurant = { ...el };
                filteredRestaurant.outlets = el.outlets.filter(
                    (outlet) =>
                        (appliedFilter.state !== null
                            ? outlet.state.toLowerCase() === appliedFilter.state?.toLowerCase()
                            : true) &&
                        (appliedFilter.city !== null
                            ? outlet.city.toLowerCase() === appliedFilter.city?.toLowerCase()
                            : true)
                );

                result.push(filteredRestaurant);
            }

            return result;
        }, []);

        return matchingOutlets;
    };

    const getRestaurantsOutletByOrderimgModeFilter = (filter, restaurants) => {
        const filteredRestaurants = restaurants
            .map((el) => {
                const filteredOutlets = el.outlets.filter((outlet) => {
                    return filter.every((filterItem) => {
                        const mode = outlet.ordering_modes.find(
                            (mode) => filterItem.mode.toLowerCase() === mode.display_name.toLowerCase()
                        );
                        return mode && mode.is_available === (filterItem.status === 'active');
                    });
                });

                if (filteredOutlets.length > 0) {
                    const filteredRestaurant = { ...el };
                    filteredRestaurant.outlets = filteredOutlets;
                    return filteredRestaurant;
                }

                return null;
            })
            .filter((restaurant) => restaurant !== null);

        return filteredRestaurants;
    };

    const selectedFilterNumber = () => {
        const selectedFilterNumber =
            (appliedFilter?.state !== null ? 1 : 0) +
            (appliedFilter?.city !== null ? 1 : 0) +
            appliedFilter?.modes.filter((el) => el.status !== null).length;
        return selectedFilterNumber;
    };

    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedOutletId');
        localStorage.removeItem('selectedRestaurantID');
        localStorage.removeItem('authToken');

        dispatch({
            type: SET_AUTH_TOKEN,
            payload: { authToken: null },
        });

        navigate('/login');

        setShowLogoutPopup(false);
    };

    return (
        <>
            <div className='relative'>
                <Header setShowLogoutPopup={setShowLogoutPopup} />

                <div className='px-8 lg:px-4 w-full max-w-[1440px] mx-auto bg-white md:pt-[108px]'>
                    <HeaderBottons
                        handleClickFilter={() => setShowFilters((prevState) => !prevState)}
                        handleClickTab={handleClickTab}
                        showGrid={showGrid}
                        setSearchedItem={setSearchedItem}
                        selectedFilterNumber={selectedFilterNumber()}
                    />

                    {showFilters && (
                        <FilterComponent
                            handleClickClose={() => setShowFilters((prevState) => !prevState)}
                            setAppliedFilter={setAppliedFilter}
                            availableFilters={availableFilters}
                        />
                    )}

                    {filteredRestaurantsList.map((el, index) => {
                        return (
                            <div
                                className='mt-8 pb-8 border-b border-neutral-300 md:pb-5 md:mt-5 last:border-none'
                                key={index}>
                                <RestaurantList
                                    {...el}
                                    showGrid={showGrid}
                                    index={index}
                                    setRestaurantsList={setRestaurantsList}
                                    appliedFilter={appliedFilter}
                                    openOutletsRestaurantIndex={openOutletsRestaurantIndex}
                                    setOpenOutletsRestaurantIndex={setOpenOutletsRestaurantIndex}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            {showLogoutPopup && (
                <LogoutPopup
                    handleLogout={handleLogout}
                    handleClickClose={() => setShowLogoutPopup(false)}
                />
            )}
        </>
    );
}
