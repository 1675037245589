import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CardLoader() {
    return (
        <ContentLoader
            width='400px'
            height='136px'
            className=' w-fit border border-neutral-300 rounded-md'>
            <rect
                x='350'
                y='10'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='15'
                y='45'
                rx='4'
                ry='4'
                width='100'
                height='30'
            />
            <rect
                x='15'
                y='10'
                rx='4'
                ry='4'
                width='200'
                height='20'
            />

            <rect
                x='15'
                y='90'
                rx='4'
                ry='4'
                width='350'
                height='15'
            />
            <rect
                x='15'
                y='110'
                rx='4'
                ry='4'
                width='100'
                height='15'
            />
        </ContentLoader>
    );
}
