import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Tabs from './Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import WarningPopup from './WarningPopup';
import AppSetting from '../TabComponents/AppSetting/AppSetting';
import SMSGateway from '../TabComponents/SMSGateway';
import Maps from '../TabComponents/Maps';
import Languages from '../TabComponents/Languages/Languages';
import AboutUs from '../TabComponents/AboutUs';
import Legal from '../TabComponents/Legal';
import CashbackSettings from '../TabComponents/CashbackSettings/CashbackSettings';
import ContactUs from '../TabComponents/ContactUs';
import Brand from '../TabComponents/Brand/Brand';
import OrderingMode from '../TabComponents/OrderingMode/OrderingMode';

export default function ParentGlobalComponent(props) {
    const { defaultValue, fetchlanguageList, fetchSettings, isLoadingForm } = props;

    const { tab } = useParams();

    const methods = useForm({ defaultValues: { ...defaultValue, isLoadingForm: isLoadingForm } });

    const {
        reset,
        formState: { isDirty },
    } = methods;

    const [showWarningPopup, setShowWarningPopup] = useState({ status: false });

    const componentMapping = {
        'app-settings': AppSetting,
        'brand': Brand,
        'sms-gateway': SMSGateway,
        'maps': Maps,
        'languages': Languages,
        'about-us': AboutUs,
        'contact-us': ContactUs,
        'legal': Legal,
        'cashback-settings': CashbackSettings,
        'ordering-mode': OrderingMode,
    };

    const ComponentToRender = componentMapping[tab];

    const navigate = useNavigate();

    const handleDiscardForm = () => {
        reset();
    };

    return (
        <>
            <FormProvider {...methods}>
                <div className='flex flex-row md:block'>
                    <Tabs setShowWarningPopup={setShowWarningPopup} />

                    <ComponentToRender
                        fetchlanguageList={fetchlanguageList}
                        fetchSettings={fetchSettings}
                        handleDiscardForm={() => handleDiscardForm()}
                    />
                </div>
            </FormProvider>

            {showWarningPopup.status && (
                <WarningPopup
                    handleClickClose={() => setShowWarningPopup({ status: false })}
                    handleClickChange={() => {
                        reset();
                        fetchlanguageList();
                        fetchSettings();
                        navigate(showWarningPopup.route);
                        setShowWarningPopup({ status: false });
                    }}
                    isDirty={isDirty}
                />
            )}
        </>
    );
}
