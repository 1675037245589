import React from 'react';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as CardIcon } from '../../../Assets/card.svg';
import { ReactComponent as CashIcon } from '../../../Assets/cash.svg';
import { ReactComponent as UpiIcon } from '../../../Assets/upi.svg';
import { ReactComponent as CashBackIcon } from '../../../Assets/cashback.svg';

export default function OrderSummery(props) {
    const { orderDetails, isNew } = props;
    const sliceNumber = Math.ceil(orderDetails?.length / 2); // Round up for odd number of items
    const firstColumn = orderDetails?.slice(0, sliceNumber);
    const secondColumn = orderDetails?.slice(sliceNumber);

    return (
        <>
            <div
                className={`flex flex-row justify-between pb-2.5 mb-4 border-neutral-300 md:block ${orderDetails?.length && 'border-b'}`}>
                <div className='flex flex-col'>
                    {firstColumn.map((el, index) => (
                        <Item
                            {...el}
                            key={index}
                            isNew={el.item === 'Order bill name' && isNew}
                        />
                    ))}
                </div>

                <div className='flex flex-col'>
                    {secondColumn.map((el, index) => (
                        <Item
                            {...el}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

const Item = ({ item, value, isNew, actionComponent }) => {
    const icons = {
        'Takeaway': <TakeAwayIcon />,
        'Cash': <CashIcon />,
        'Card': <CardIcon />,
        'Credit card': <CardIcon />,
        'Debit card': <CardIcon />,
        'UPI': <UpiIcon />,
        'Delivery': <DeliveryIcon />,
        'Dine-In': <DininIcon />,
        'UPI_INTENT': <UpiIcon />,
        'Balance': <CashBackIcon />,
    };

    const icon = icons[value];

    return (
        <div className='my-1.5 flex flex-row'>
            <span className='paragraph-medium-medium text-neutral-500'>{item}:</span>

            <div className='flex flex-row items-center ml-2'>
                {icon && <span className='mr-1'>{icon}</span>}
                <span className='paragraph-medium-regular'>{value}</span>
                {isNew && (
                    <span className='font-medium text-[10px] leading-3 px-2 py-1 border-primary-500 text-primary-500 bg-primary-50 rounded border ml-1'>
                        New
                    </span>
                )}
                {actionComponent}
            </div>
        </div>
    );
};
