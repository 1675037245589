import React, { useEffect, useState } from 'react';
import { MultipleTab } from '../../../../../Components/Tabs/Tabs';
import SetPrice from './Components/SetPrice';
import PricingTableComponent from './Components/PricingTableComponent';
import { useFormContext } from 'react-hook-form';
import { getAllergens, getQuantityUnits } from '../../../Components/Catalogue/functions';
import { useSelector } from 'react-redux';

export default function DishPricing() {
    const tabName = ['Set price', 'Pricing table'];

    const [activeTab, setActiveTab] = useState(0);

    const { watch, setValue } = useFormContext();

    const orderingModes = watch('exposeDish');

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const setAllergens = async () => {
        try {
            const response = await getAllergens(selectedRestaurantId);

            setValue(
                'availableAllergens',
                response?.allergens?.map((el) => ({ label: el.internal_name, value: el }))
            );
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const setQuantityUnits = async () => {
        try {
            const response = await getQuantityUnits();

            setValue(
                'availableQuantityUnits',
                response?.quantity_units?.map((el) => ({ label: el.unit, value: el }))
            );
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        !watch('availableAllergens')?.length && setAllergens();
        !watch('availableQuantityUnits')?.length && setQuantityUnits();
    }, []);

    return (
        <>
            <div className=''>
                {!orderingModes?.length ? (
                    <span className='paragraph-medium-italic md:paragraph-small-italic text-neutral-500'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit quod corporis quae.
                    </span>
                ) : (
                    <>
                        <div className='flex flex-row items-center mb-6'>
                            {tabName.map((el, index) => (
                                <div
                                    className='mr-4'
                                    key={index}>
                                    <MultipleTab
                                        label={el}
                                        isActive={index === activeTab}
                                        onClick={() => setActiveTab(index)}
                                    />
                                </div>
                            ))}
                        </div>

                        {activeTab === 0 ? <SetPrice /> : <PricingTableComponent />}
                    </>
                )}
            </div>
        </>
    );
}
