// transform translation array into obj like [null, 'Var1', "Var2'] into {1 : 'Var1', 2 : 'Var2"}]
export const populateTranslations = (translations, array, key) => {
    return array.forEach((item, index) => {
        if (item !== null) {
            translations[key][index] = item;
        }
    });
};

// basic details tab
export const formatBasicDetails = (details) => {
    const formattedDetails = {
        internal_name: details?.nickname,
        image_id: details?.imageDetail?.id,
        translations: {
            title: {},
            description: {},
        },
        food_type: details?.food_type?.value,
        show_agmark: details?.show_agmark?.value,
    };

    populateTranslations(formattedDetails.translations, details.display, 'title');
    populateTranslations(formattedDetails.translations, details.description, 'description');

    return formattedDetails;
};

// badge tab
export const formatBadgeIds = (badges) => {
    const selectedBadgeIds = [];
    const unselectedBadgeIds = [];

    badges?.forEach((badge) => {
        if (badge.isSelected) {
            selectedBadgeIds.push(badge.value.id);
        } else {
            unselectedBadgeIds.push(badge.value.id);
        }
    });

    return { badges_ids: selectedBadgeIds, delete_badge_ids: unselectedBadgeIds };
};

// up-selling tab
export const formatUpSellingDetails = (upSellingData) => {
    const upsellingItemIds = upSellingData?.selectedDish?.map((dish) => dish?.value?.item_id);
    return { upselling_item_ids: upsellingItemIds };
};

export const formateDeleteOrderingMode = (exposeDish, orderingMode) => {
    const exposeDishIds = new Set(exposeDish.map((dish) => dish.id));

    const idsNotInExposeDish = orderingMode.filter((order) => !exposeDishIds.has(order.id)).map((order) => order.id);

    return { delete_ordering_modes: idsNotInExposeDish };
};

// schedule formatting
const formateSameTimeSlotForAllDays = (timing) => {
    return [{ days_of_week: [1, 2, 3, 4, 5, 6, 7], time_slots: timing }];
};

export const formatDifferentTineSlotForDifferentDays = (timing) => {
    return timing
        ?.map((timeSlots, index) => {
            if (timeSlots.length === 0) return null;
            return {
                days_of_week: [index + 1],
                time_slots: timeSlots,
            };
        })
        .filter((schedule) => schedule !== null);
};

export const formatSameTiming = (timingArr, timingType) => {
    let schedule = {};

    switch (timingType) {
        case 'same_time_slot_for_all_days':
            schedule = {
                time_slot_setting: 'same_time_slot_for_all_days',
                schedules: formateSameTimeSlotForAllDays(timingArr),
            };
            break;

        case 'different_time_slots_for_different_days':
            schedule = {
                time_slot_setting: 'different_time_slots_for_different_days',
                schedules: formatDifferentTineSlotForDifferentDays(timingArr),
            };
            break;

        default:
            schedule = {
                time_slot_setting: 'no_time_slots',
                schedules: null,
            };
            break;
    }

    return schedule;
};

// loop through ordering modes
export const getOrderingModeTiming = (mode, timingObj) => {
    const timingTypeKey = `timings_${mode.id}_timingType`;
    const timingKey = `timings_${mode.id}`;
    const timingType = timingObj[timingTypeKey]?.value || 'no_time_slots';
    const timingArr = timingObj[timingKey] || [];

    return formatSameTiming(timingArr, timingType);
};

export const formatOrderingMode = (mode, pricingData, timingObj, dishPackingPrice, dishGstSlab, allAllergens) => {
    const { time_slot_setting, schedules } = getOrderingModeTiming(mode, timingObj);
    const selectedPrice = pricingData[mode.id];

    return {
        restaurant_ordering_mode_name: mode.type,
        restaurant_ordering_mode_id: mode.id,
        time_slot_setting,
        schedules,
        allergens: filterAllergensById(allAllergens, selectedPrice?.allergens) ?? [],
        calories: selectedPrice?.calories,
        serves: selectedPrice.serves,
        serves_unit: selectedPrice.servesUnit,
        compare_price: selectedPrice?.compare_price ?? 0,
        [`compare_price_${mode.id}`]: selectedPrice?.compare_price ?? 0,
        packaging_charge: dishPackingPrice ?? 0,
        [`packaging_charge_${mode.id}`]: dishPackingPrice ?? 0,
        gst_slab: dishGstSlab?.value ?? 0,
        [`gst_slab_${mode.id}`]: dishGstSlab?.value ?? 0,
        price: selectedPrice?.price,
        [`price_${mode.id}`]: selectedPrice?.price,
    };
};

export const extractTimings = (obj) => {
    return Object.keys(obj)
        .filter((key) => key.startsWith('timings'))
        .reduce((result, key) => {
            result[key] = obj[key];
            return result;
        }, {});
};

export const formatePriceAndTimingWithoutVariant = (data) => {
    const timingObj = extractTimings(data);
    const pricingData = data?.pricing?.dishPrice?.prices;
    const selectedOrderingMode = data?.exposeDish;
    const dishPackingPrice = data?.dishPackingPrice ?? 0;
    const dishGstSlab = data?.dishGstSlab;

    let schedule = { schedule: null, time_slot_setting: 'no_time_slots' };

    if (data?.timings?.sameTiming) {
        schedule = formatSameTiming(timingObj.timings_0, timingObj.timings_0_timingType.value);
    }

    const orderingModes = selectedOrderingMode.map((mode) =>
        formatOrderingMode(mode, pricingData, timingObj, dishPackingPrice, dishGstSlab, data.availableAllergens)
    );

    const objToReturn = { ordering_modes: orderingModes, ...schedule, variants: [] };

    return objToReturn;
};

// function for ordering mode schedule if there is no variant
export const formatOrderingModeWithVariant = (data) => {
    const timingObj = extractTimings(data);
    const selectedOrderingModes = data?.exposeDish;

    const formattedModes = selectedOrderingModes.map((mode) => {
        return {
            ...getScheduleForMode(mode, data, timingObj),
            restaurant_ordering_mode_id: mode.id,
            restaurant_ordering_mode_name: mode.display_name,
            compare_price: 0,
            price: 0,
            packaging_charge: 0,
            gst_slab: 0,
        };
    });

    return data.timings?.sameTiming
        ? {
              ...formatSameTiming(timingObj.timings_0, timingObj.timings_0_timingType.value),
              ordering_modes: formattedModes,
          }
        : { ordering_modes: formattedModes, schedule: null, time_slot_setting: 'no_time_slots' };
};

export const getScheduleForMode = (mode, data, timingObj) => {
    return data?.timings?.sameTiming
        ? { time_slot_setting: 'no_time_slots', schedules: null }
        : getOrderingModeTiming(mode, timingObj);
};

const filterAllergensById = (availableAllergens, selectedAllergens) => {
    return (
        availableAllergens
            ?.filter((allergen) => selectedAllergens.includes(allergen.value.id))
            ?.map((allergen) => allergen.value) ?? []
    );
};

const formatVariantOrderingMode = (prices, packingPrice, gstSlab, orderingMode, availableAllergens) => {
    const formatting = Object.entries(prices)
        .filter(([key, value]) => key !== 'pricing')
        .map(([key, priceData]) => {
            const mode = orderingMode.find((mode) => mode.id === parseInt(key));
            if (!mode) return null;

            return {
                restaurant_ordering_mode_name: mode.display_name,
                restaurant_ordering_mode_id: mode.id,
                price: parseFloat(priceData.price) ?? 0,
                compare_price: parseFloat(priceData.compare_price) ?? 0,
                packaging_charge: packingPrice ?? 0,
                gst_slab: gstSlab ?? 0,
                quantity: priceData.quantity ?? 0,
                quantity_unit_id: priceData.quantityUnit?.value ?? null,
                serves: priceData.serves ?? null,
                serves_unit: priceData.serves_unit ?? null,
                allergens: filterAllergensById(availableAllergens, priceData.allergens ?? []) || [],
                calories: priceData.calories || null,
            };
        })
        .filter((item) => item !== null);

    return formatting;
};

export const formateVariant = (
    variantPrice,
    packingPrice,
    gstSlab,
    variantGroupTitle,
    variantGroupDescription,
    has_aggmark_show,
    orderingMode,
    variantGroupId,
    availableAllergens
) => {
    const formattedDetails = {
        translations: {
            title: {},
            description: {},
        },
    };

    populateTranslations(formattedDetails.translations, variantGroupTitle, 'title');
    populateTranslations(formattedDetails.translations, variantGroupDescription, 'description');

    const variants = [
        {
            variant_group_id: variantGroupId ?? null,
            translations: formattedDetails.translations,
            show_agmark: has_aggmark_show,
            options: variantPrice?.map((el, index) => {
                const formattedDetails = {
                    translations: {
                        title: {},
                    },
                };

                populateTranslations(formattedDetails.translations, el.title, 'title');

                return {
                    addons: el.addon?.map((el) => el.value?.addon_group_id) ?? [],
                    addon_group_ids: el.addon?.map((el) => el.value?.addon_group_id) ?? [],
                    translations: formattedDetails.translations,
                    id: el.variantId ?? null,
                    food_type: el.food_type?.value,
                    image_id: el.imageDetails?.id,
                    ordering_modes: formatVariantOrderingMode(
                        el.prices,
                        packingPrice,
                        gstSlab,
                        orderingMode,
                        availableAllergens
                    ),
                };
            }),
        },
    ];

    return { variants };
};
