const { format, addMinutes } = require('date-fns');

function generateTimeSlots() {
    const timeSlots = [];

    const baseDate = new Date(2023, 0, 1);

    for (let i = 0; i < (24 * 60) / 30; i++) {
        const currentDate = addMinutes(baseDate, i * 30);

        const formattedTime = format(currentDate, 'hh:mm a');

        const [hours, minutes, period] = formattedTime.split(/:| /);

        const hourValue = (parseInt(hours, 10) % 12) + (period === 'AM' ? 0 : 12);

        const paddedHour = hourValue.toString().padStart(2, '0');

        const value = `${paddedHour}:${minutes}`;

        const timeSlot = {
            label: formattedTime,
            value,
        };

        timeSlots.push(timeSlot);
    }

    return timeSlots;
}

export const dayTimeSlots = generateTimeSlots();

export const removeSlotIdFromEachDay = (array) => {
    return array.map((item) => ({
        days_of_week: item.days_of_week,
        time_slots: item.time_slots.map((slot) => {
            const { id, ...rest } = slot;
            return rest;
        }),
    }));
};
