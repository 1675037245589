import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function ListViewCustomerIndividualDetails(props) {
    const {
        customerName,
        mobileNumber,
        totalOrders,
        lastOrder,
        orderRating,
        cashbackEarned,
        emailId,
        revenueGenerated,
        firstOrder,
        joinDate,
        discountAvailed,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className=''>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>CUSTOMER NAME:</h3>
                        <span className='paragraph-small-regular'>{customerName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-2'>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>MOBILE NUMBER:</span>
                            <span className='paragraph-small-regular ml-1'>{mobileNumber}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL ORDERS:</span>
                            <span className='paragraph-small-regular ml-1'>{totalOrders}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>LAST ORDER:</span>
                            <span className='paragraph-small-regular ml-1'>{lastOrder}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER RATING:</span>
                            <span className='paragraph-small-regular ml-1'>{orderRating}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>CASHBACK EARNED:</span>
                            <span className='paragraph-small-regular ml-1'>{cashbackEarned}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>EMAIL ID:</span>
                            <span className='paragraph-small-regular ml-1'>{emailId}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>REVENUE GENERATED:</span>
                            <span className='paragraph-small-regular ml-1'>{revenueGenerated}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>FIRST ORDER:</span>
                            <span className='paragraph-small-regular ml-1'>{firstOrder}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>JOIN DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{joinDate}</span>
                        </div>
                        <div className='mb-0.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>DISCOUNT AVAILED:</span>
                            <span className='paragraph-small-regular ml-1'>{discountAvailed}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
