import React from 'react';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { Controller } from 'react-hook-form';
import Layout from './TablePopupComponent/Layout';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { createTable } from '../HelperFunction/ApiFunctions';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

export default function AddTablePopup(props) {
    const {
        control,
        handleClickClose,
        sectionList,
        watch,
        handleSubmit,
        errors,
        loaderStatus,
        setloaderStatus,
        seterrorOfApi,
        errorOfApi,
        setTotalTable,
        setTableList,
    } = props;
    const { section } = useParams();
    const sections = sectionList.map((item) => ({ label: item.internal_name, value: item.id }));
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const subSections =
        watch('outlet_section_id') &&
        sectionList
            ?.filter((items) => items?.id == watch('outlet_section_id')?.value)[0]
            ?.sub_section?.map((item) => ({ label: item?.internal_name, value: item?.id }));

    const onSubmit = (data) => {
        //this function is call create table function to create a new table
        createTable({
            tableData: data,
            setloaderStatus: setloaderStatus,
            handleClickClose: handleClickClose,
            seterrorOfApi,
            section,
            sectionList,
            setTotalTable,
            setTableList,
            selectedRestaurantId,
            selectedOutletId,
        });
    };

    return (
        <Layout
            handleClickClose={handleClickClose}
            onClick={handleSubmit(onSubmit)}
            loaderStatus={loaderStatus}
            errorOfApi={errorOfApi}>
            <div className='w-full'>
                <div className='mb-4'>
                    <Controller
                        render={({ field }) => (
                            <DefaultInputField
                                label='Table name'
                                placeholder='Enter new table name'
                                enteredValue={field.onChange}
                                disabled={loaderStatus.isLoading}
                            />
                        )}
                        name='internal_name'
                        control={control}
                        rules={{ required: 'Internal name require' }}
                    />
                    {errors?.['internal_name'] && <ErrorMessage errors={errors?.['internal_name']} />}
                </div>
                <div className='mb-4 relative'>
                    <Controller
                        render={({ field }) => (
                            <DropdownWithValueLabel
                                label='Select section'
                                menuItems={sections}
                                placeholder='Select section'
                                setSelected={field.onChange}
                                disabled={loaderStatus.isLoading}
                            />
                        )}
                        name='outlet_section_id'
                        control={control}
                        rules={{ required: 'Section require' }}
                    />
                    {errors?.['outlet_section_id'] && <ErrorMessage errors={errors?.['outlet_section_id']} />}
                </div>
                <div className='mb-4 relative'>
                    <Controller
                        render={({ field }) => (
                            <DropdownWithValueLabel
                                label='Select sub-section'
                                menuItems={subSections ?? []}
                                placeholder='Select sub-section'
                                setSelected={field.onChange}
                                disabled={!watch('outlet_section_id') || loaderStatus.isLoading}
                            />
                        )}
                        name='outlet_sub_section_id'
                        control={control}
                        rules={{ required: 'Sub section require' }}
                    />
                    {errors?.['outlet_sub_section_id'] && <ErrorMessage errors={errors?.['outlet_sub_section_id']} />}
                </div>
                <div className='mb-4'>
                    <Controller
                        render={({ field }) => (
                            <DefaultInputField
                                label='Table capacity'
                                placeholder='Enter table capacity'
                                enteredValue={field.onChange}
                                inputType={'number'}
                                disabled={loaderStatus.isLoading}
                            />
                        )}
                        name='capacity'
                        control={control}
                        rules={{ required: 'Table capacity require' }}
                    />
                    {errors?.['capacity'] && <ErrorMessage errors={errors?.['capacity']} />}
                </div>
                <div className='mb-4'>
                    <Controller
                        render={({ field }) => (
                            <DefaultInputField
                                label='Custom URL'
                                placeholder='Enter deeplink URL'
                                enteredValue={field.onChange}
                                disabled={loaderStatus.isLoading}
                            />
                        )}
                        name='deeplink'
                        control={control}
                        rules={{
                            required: 'Custom URL required',
                            pattern: {
                                value: /^(ftp|http|https):\/\/[^ "]+$/,
                                message: 'Invalid URL',
                            },
                        }}
                    />
                    {errors?.['deeplink'] && <ErrorMessage errors={errors?.['deeplink']} />}
                </div>
            </div>
        </Layout>
    );
}
