import React, { useState } from 'react';
import BasicDetails from '../TabComponents/BasicDetails';
import ExposeDish from '../TabComponents/ExposeDish';
import DishPricing from '../TabComponents/DishPricing/DishPricing';
import Badges from '../TabComponents/Badges/Badges';
import LinkAddOnGroup from '../TabComponents/LinkAddOnGroup/LinkAddOnGroup';
import DishTiming from '../TabComponents/DishTiming';
import UpSellItems from '../TabComponents/UpSellItems/UpSellItems';
import UpSellCurrentCart from '../TabComponents/UpSellCurrentCart';
import { useLocation, useParams } from 'react-router-dom';
import Layout from './Layout';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { preferenceMenuItem, vegNonVegMenuItem } from '../../../../Constants/Constants';
import _ from 'lodash';

export default function DishWithUseForm(props) {
    const { defaultDishFormState } = props;

    const { dishTab } = useParams();

    const location = useLocation();

    const { selectedCategoryDish } = location.state || {};

    const findSubCategory = () => {
        const selectedSubCategory =
            selectedCategoryDish?.subCategory?.find((el) => selectedCategoryDish.subCategoryId === el.category_id) ??
            selectedCategoryDish?.subCategory?.[0];

        return !!selectedSubCategory ? { label: selectedSubCategory?.internal_name, value: selectedSubCategory } : null;
    };

    const componentToMap = {
        'basic-details': BasicDetails,
        'expose-dish': ExposeDish,
        'dish-pricing': DishPricing,
        'badges': Badges,
        'link-addon-groups': LinkAddOnGroup,
        'dish-timings': DishTiming,
        'up-sell-dishes': UpSellItems,
        'up-sell-current-dish': UpSellCurrentCart,
    };

    const [showHeaderTab, setShowHeaderTab] = useState(true);

    const ComponentToRender = componentToMap?.[dishTab];

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const defaultTiming = orderingMode.reduce((acc, mode) => {
        acc[`timings_${mode.id}_timingType`] = { label: 'Full time', value: 'no_time_slots' };
        return acc;
    }, {});

    const getDefaultFormValue = () => {
        return _.isEmpty(defaultDishFormState)
            ? {
                  exposeDish: [],
                  availableBadge: [],
                  upSellInCart: { label: 'No', value: false },
                  upSelling: { selectedDish: [] },
                  pricing: { availableVariant: [{ id: 0 }], activeVariant: 0 },
                  details: {
                      category: {
                          label: selectedCategoryDish?.category?.internal_name,
                          value: selectedCategoryDish?.category,
                      },
                      subCategory: { ...findSubCategory() },
                      food_type: vegNonVegMenuItem[1],
                      show_agmark: preferenceMenuItem[0],
                  },

                  ...defaultTiming,
              }
            : defaultDishFormState;
    };

    const methods = useForm({
        defaultValues: getDefaultFormValue(),
    });

    const propsForExposeDish = {
        title: 'Expose dish',
        description:
            'Select the ordering modes in which you would like the dish to appear to the in-house workers and the customers.',
    };

    const propsForDishTiming = {
        title: 'Category timings',
        description: 'Set the availability of category throughout the day and week according to the ordering modes.',
    };

    return (
        <>
            <FormProvider {...methods}>
                <Layout showHeaderTab={showHeaderTab}>
                    {ComponentToRender ? (
                        <ComponentToRender
                            setShowHeaderTab={setShowHeaderTab}
                            {...(dishTab === 'expose-dish' ? propsForExposeDish : {})}
                            {...(dishTab === 'category-timings' ? propsForDishTiming : {})}
                        />
                    ) : (
                        <div>Invalid Tab</div>
                    )}
                </Layout>
            </FormProvider>
        </>
    );
}
