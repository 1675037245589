import React from 'react';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { MultipleTab } from '../../../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../../../Components/DropDown/DropDownTabs';

export default function TabController(props) {
    const { children, setActiveTab, activeTab, tabWiseAmount } = props;

    const isMobileScreen = IsMobileScreen();

    const tabs = [
        { item: 'Cash', badgeText: tabWiseAmount.cash, onClick: () => setActiveTab('cash'), tab: 'cash' },
        { item: 'Card', badgeText: tabWiseAmount.card, onClick: () => setActiveTab('card'), tab: 'card' },
        { item: 'UPI', badgeText: tabWiseAmount.upi, onClick: () => setActiveTab('upi'), tab: 'upi' },
        { item: 'NCB (No charge bill)', onClick: () => setActiveTab('ncb'), tab: 'ncb' },
    ];

    const tabConfiguration = ['cash', 'card', 'upi'];

    return (
        <>
            <div className='flex flex-row items-start md:block md:mt-4'>
                {!isMobileScreen && (
                    <div className='flex flex-col'>
                        {tabs.map((el, index) => {
                            if (!tabConfiguration.includes(el.tab)) {
                                return null;
                            }

                            const label =
                                el.tab === 'ncb' ? (
                                    <span>
                                        NCB
                                        <span
                                            className={`paragraph-small-regular pl-0.5 ${activeTab === el.tab && 'text-primary-500'} `}>
                                            (No charge bill)
                                        </span>
                                    </span>
                                ) : (
                                    el.item
                                );

                            return (
                                <div
                                    className='mt-4 min-w-[173px]'
                                    key={index}>
                                    <MultipleTab
                                        index={index}
                                        label={label}
                                        badgeText={el?.badgeText}
                                        isActive={activeTab === el.tab}
                                        onClick={() => el.onClick()}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                {isMobileScreen && (
                    <div className='w-full'>
                        <DropDownTabs menuItems={tabs} />
                    </div>
                )}

                {children}
            </div>
        </>
    );
}
