export const gatDatepickerStyles = () => {
    return `
  .rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-date-menu.rs-picker-menu {
        position: fixed !important;
        z-index: 50 !important;
        margin-top: 4px !important;
  }
  
  button.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
        font-family: "Inter", sans-serif !important;
        color: #131126 !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        top: 6px;
        position: absolute;
        left: 8px;
  }
  
  .rs-calendar-header-backward svg {
        width: 20px !important ;
        height: 20px !important;
  }
  
  .rs-calendar-header-month-toolbar .rs-calendar-header-forward {
        top: 11px !important;
        position: absolute;
        left: 140px !important;
        padding: 0px !important;
  }
  
  .rs-calendar-header-forward svg {
        width: 20px !important ;
        height: 20px !important;
  }
  
  button.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
        left: 106px !important;
        padding: 0px !important;
        top: -2px !important;
  }
  
  button.rs-calendar-header-title.rs-calendar-header-title-time.rs-btn.rs-btn-subtle.rs-btn-xs {
        padding: 0 2px !important;
        font-family: "Inter", sans-serif !important;
        color: #131126 !important;
        font-weight: 400;
        margin: 0px !important;
  }
  
  .rs-calendar-header-time-toolbar {
        position: absolute;
        right: 14px;
        top: 10px;
  }
  
  button.rs-calendar-header-meridian.rs-btn.rs-btn-subtle.rs-btn-xs {
        font-family: "Inter", sans-serif !important;
        color: #131126 !important;
        font-weight: 400;
  }

  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
      box-shadow:none !important;
  }
  
  .rs-calendar-table-cell-content:hover {
        background-color: #e2dff6 !important;
  }
  
  .rs-calendar-table-cell.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected:hover {
        background-color: #e2dff6 !important;
  }
  
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
        background-color: #e2dff6 !important;
  }
  
  span.rs-calendar-table-cell-day {
        margin: auto;
  }
  
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
        background: #6c5dd3 !important;
  }
  
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
        color: #000 !important;
        background: #e2dff6 !important;
  }
  
  .rs-calendar-table-header-cell-content,
  .rs-calendar-table-header-cell-content:hover,
  .rs-calendar-table-header-cell-content:focus,
  .rs-calendar-table-header-cell-content:hover:focus {
        font-family: "Inter", sans-serif !important;
        color: #131126 !important;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 20px !important;
        padding-bottom: 11px !important;
  }
  
  button.rs-btn.rs-btn-primary.rs-btn-sm {
        background: #6c5dd3 !important;
        color: #ffffff !important;
  }
  
  button.rs-btn.rs-btn-link.rs-btn-sm {
        color: #6c5dd3 !important;
  }
  
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
        background: #6c5dd3 !important;
  }
  
  .rs-calendar-time-dropdown-cell:hover {
        background: #e2dff6 !important;
        color: #000 !important;
  }
  
  .rs-calendar-time-dropdown-column > ul {
        padding-bottom: 30px !important;
  }
  
  .rs-picker-toggle-textbox,
  span.rs-picker-toggle-placeholder {
        font-family: "Inter", sans-serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        padding-right: 16px !important;
  }
  
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        font-family: "Inter", sans-serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        color: #131126 !important;
        padding-right: 16px !important;
  }
  
  .rs-picker-toggle.rs-picker-toggle-active.rs-btn.rs-btn-default{
      box-shadow: 0px 0px 0px 4px rgba(226, 223, 246, 1);
      border: 1px solid #a79ee5;
  }

  span.rs-picker-toggle-placeholder {
        color: #d3d2d8 !important;
  }

  .rs-picker-toggle.rs-btn.rs-btn-default:hover{
      border: 1px solid #a79ee5
  }

  .rs-calendar-month-view .rs-calendar-month-dropdown{
      margin-top: 16px !important;
  }

  .rs-picker-menu .rs-calendar-body{
      padding-top: 20px !important;
  }

  .rs-calendar-month-dropdown-year.rs-calendar-month-dropdown-year-active{
      color: #6C5DD3 !important
  }

  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content{
      background-color: #6C5DD3 !important
  }

  .rs-calendar-month-dropdown-cell-content:hover{
      background-color: #e2dff6 !important
  }
  `;
};
