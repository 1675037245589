import React from 'react';
import { BlankComponentTable } from '../../../../../Components/BlankComponent/BlankComponent';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';

export default function TableSection(props) {
    const {
        filteredOutletList,
        pageState,
        getAddress,
        handleChangeLinking,
        setLinkUnlinkOutletList,
        linkUnlinkOutletList,
    } = props;

    const startIndex = (pageState.selectedPage - 1) * pageState.resultPerpage;

    const endIndex = startIndex + pageState.resultPerpage;

    const displayedOutlets = filteredOutletList.slice(startIndex, endIndex);

    const handleSelectAll = (isChecked) => {
        isChecked
            ? setLinkUnlinkOutletList({
                  linkedOutlets: displayedOutlets?.map((el) => el.outletId),
                  unLinkedOutlets: [],
              })
            : setLinkUnlinkOutletList({
                  linkedOutlets: [],
                  unLinkedOutlets: displayedOutlets?.map((el) => el.outletId),
              });
    };

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words table-row-bg'>
                    <thead>
                        <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                            <th className='px-6 min-w-[205px]'>
                                <div className='flex flex-row items-center gap-2'>
                                    <CheckBoxWithoutLabels
                                        onChange={(isChecked) => handleSelectAll(isChecked)}
                                        isChecked={
                                            displayedOutlets?.length === linkUnlinkOutletList?.linkedOutlets?.length
                                        }
                                    />
                                    LINKING STATUS
                                </div>
                            </th>
                            <th className='px-6 min-w-[302px]'>OUTLET NAME</th>
                            <th className='px-6 min-w-[102px] lg:min-w-[135px]'>STATE</th>
                            <th className='px-6 min-w-[92px] lg:min-w-[151px]'>CITY</th>
                            <th className='px-6 min-w-[334px]'>ADDRESS</th>
                        </tr>
                    </thead>

                    {!filteredOutletList.length ? (
                        <BlankComponentTable colSpan={5} />
                    ) : (
                        <tbody>
                            {displayedOutlets.map((el, index) => {
                                const { state, city, address, displayName } = getAddress(el);

                                return (
                                    <tr
                                        className='paragraph-small-regular justify-center h-[70px] border-t first:border-none'
                                        key={index}>
                                        <td className='px-6'>
                                            <ToggleSwitch
                                                isEnable={linkUnlinkOutletList?.linkedOutlets?.includes(el.outletId)}
                                                canChange
                                                setIsEnabled={(doLink) => handleChangeLinking(doLink, el.outletId)}
                                            />
                                        </td>
                                        <td className='px-6'>{displayName}</td>
                                        <td className='px-6'>{state}</td>
                                        <td className='px-6'>{city}</td>
                                        <td className='px-6'>{address}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
        </>
    );
}
