import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import TableHeader from './TableHeader';
import { Link } from '../../../Components/LinkOffer/Link';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { BlankComponentTable } from '../../../Components/BlankComponent/BlankComponent';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { useNavigate } from 'react-router-dom';

export default function RestaurantOfferTable(props) {
    const {
        selectedOffer,
        activeState,
        isListLoading,
        handleStatusChange,
        onSelectOffer,
        setLinkOfferId,
        linkOfferToOutletSection,
        setSelectedOfferId,
        linkPopup,
        setSelectedOffer,
    } = props;

    const navigate = useNavigate();

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(props.list);
    }, [props.list]);

    const handleNavigation = (id) => {
        navigate(`/offers/edit/${id}/offer-type`);
    };

    console.log('list ==>', list);

    return (
        <>
            <section>
                <div className='w-full border border-neutral-300 rounded-lg overflow-auto scrollbar-style'>
                    <table className='w-full break-words'>
                        <TableHeader
                            isRestaurantPage
                            linkOfferToOutletSection={linkOfferToOutletSection}
                            linkPopup={linkPopup}
                            selectedOffer={selectedOffer}
                            onSelectOffer={onSelectOffer}
                            isActiveSelection={activeState.selection}
                            list={list}
                            setSelectedOffer={setSelectedOffer}
                        />

                        {isListLoading ? (
                            <tbody>
                                <TableLoader
                                    hasSwitch
                                    columns={6}
                                />
                            </tbody>
                        ) : !!list.length ? (
                            <tbody>
                                {list?.map((el, index) => (
                                    <tr
                                        className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t'
                                        key={index}>
                                        {linkPopup && (
                                            <td className='pl-6'>
                                                <span
                                                    className={`${
                                                        el.active ? 'text-success-500' : 'text-destructive-500'
                                                    }`}>
                                                    {el.active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                        )}

                                        <td className='pl-6'>
                                            <ToggleSwitch
                                                isEnable={el.active}
                                                setIsEnabled={(value) => handleStatusChange(el.id, value)}
                                                canChange
                                                // canChange={!linkPopup}
                                                // cursorType={linkPopup && 'cursor-not-allowed'}
                                            />
                                        </td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {activeState.edit && (
                                                    <span
                                                        className='mr-3 cursor-pointer'
                                                        onClick={() => handleNavigation(el.id)}>
                                                        <EditIcon />
                                                    </span>
                                                )}

                                                {activeState.selection && (
                                                    <span className='mr-3'>
                                                        <CheckBoxWithoutLabels
                                                            onChange={(value) => {
                                                                onSelectOffer(el.id, value);
                                                            }}
                                                            isChecked={selectedOffer.includes(el.id)}
                                                        />
                                                    </span>
                                                )}

                                                <span
                                                    onClick={() => !linkPopup && setSelectedOfferId(el.id)}
                                                    className={`${
                                                        !linkPopup &&
                                                        'text-primary-500 hover:underline underline-offset-4 cursor-pointer'
                                                    }`}>
                                                    {el.coupon_code}
                                                </span>
                                            </div>
                                        </td>

                                        <td className='pl-6'>{el.offer_type}</td>

                                        <td className='pl-6'>{el.translations.title[1]}</td>

                                        <td className='pl-6'>{el.translations.description[1]}</td>

                                        {!linkOfferToOutletSection && (
                                            <td
                                                className='pl-6 cursor-pointer'
                                                onClick={() => setLinkOfferId(el.id)}>
                                                <Link linkOfferNumber={el.linked_outlets.length} />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <BlankComponentTable
                                colSpan={6}
                                previewText='No offer at this moment'
                            />
                        )}
                    </table>
                </div>
            </section>
        </>
    );
}
