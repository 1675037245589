import React, { useState } from 'react';

export default function RefundDetails(props) {
    const { refundDetails, rnnCode } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='mt-4 pb-4 mb-4 border-b border-neutral-300'>
                <div className='flex flex-row justify-between items-center'>
                    <h3 className='paragraph-medium-medium'>Refund details</h3>
                    <span
                        className='paragraph-medium-regular text-primary-500 cursor-pointer'
                        onClick={handleClickShowDetails}>
                        {showDetails ? 'Hide' : 'Show'}
                    </span>
                </div>
                {showDetails &&
                    (refundDetails?.[0] ? (
                        <div className='mt-3'>
                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Refund amount:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>
                                    ₹{refundDetails?.[0]?.amount}/-
                                </span>
                            </div>

                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Refund initiated on:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>
                                    {refundDetails?.[0]?.initiate_date}
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Refund initiated at:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>
                                    {refundDetails?.[0]?.initiate_time}
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Refunded on:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>
                                    {refundDetails?.[0]?.refunded_date}
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>Refunded at:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>
                                    {refundDetails?.[0]?.refunded_time}
                                </span>
                            </div>
                            <div className='flex flex-row'>
                                <h3 className='paragraph-medium-italic mb-2 text-neutral-500'>RRN Code:</h3>
                                <span className='ml-1 font-normal text-base leading-6'>{rnnCode}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='mt-3'>No Refund Provided</div>
                    ))}
            </div>
        </>
    );
}
