import React, { useEffect, useLayoutEffect, useState } from 'react';
import { PresetList, UserList } from './Components/ListTable';
import PaginationWithNumber from '../../../Components/Pagination/PaginationWithNumber';
import { UserPopup, CreatePreset, SelectAccessPermission, OutletPermission } from './Components/Popups';
import Header from './Components/Header';
import { IsMobileScreen } from '../../../Constants/Constants';
import Filters from './Components/Filters';
import { useForm, FormProvider } from 'react-hook-form';
import {
    deletePresets,
    deleteUsers,
    getPermissionList,
    getPresetList,
    getSelectedPresetDetail,
    getUsers,
} from './Components/ApiFunctions';
import ConfirmDeletePopup from '../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import { useLoaderData, useNavigation, useParams } from 'react-router-dom';
import ConfirmRoleChangePopup from './Components/ConfirmRoleChangePopup';
import { useSelector } from 'react-redux';

export default function UserSetting({ handleNavigation }) {
    const params = useParams();
    const methods = useForm({
        defaultValues: { mobileNumber: null, otp: null },
    });
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const { setValue, getValues, watch, formState } = methods;
    const routeData = useLoaderData();
    const [paginationState, setPaginationState] = useState({
        selectedPage: 1,
        resultPerPage: 10,
    });
    const [arrayState, setArrayState] = useState({
        presetList: routeData?.custom_roles,
        permissionList: [],
        selectedForDelete: [],
        checkedPresets: [],
        usersList: routeData?.users,
        selectedOutletForPermission: [],
    });
    const [actionState, setActionState] = useState({
        isShowUserList: params.tab == 'user-list' ? true : false,
        isBulkSelect: false,
        isEditMode: false,
        isFilterActive: false,
        isEditPermiison: false,
        isOverWriteChanges: false,
    });
    const [popupStatus, setPopupStatus] = useState({
        addUserPopup: false,
        addPresetPopup: false,
        accessPermissionPopup: false,
        showConfirmDeletePopup: false,
        showChangeRolePopup: false,
    });
    const [loaderStatus, setloaderStatus] = useState({
        isLoading: false,
        isResponseSuccess: '',
        isClicked: false,
    });

    const [appliedFilter, setAppliedFilter] = useState('');
    const isMobileScreen = IsMobileScreen();

    const handlePaginationState = ({ modal, updatedData }) => {
        setPaginationState((prevState) => ({
            ...prevState,
            [modal]: updatedData,
        }));
    };

    const handleArrayState = ({ array, updatedValue }) => {
        setArrayState((prevState) => ({
            ...prevState,
            [array]: typeof updatedValue === 'function' ? updatedValue(prevState[array]) : updatedValue,
        }));
    };

    const handlePopStatus = (modal) => {
        setPopupStatus((prevStatus) => ({
            ...Object.fromEntries(
                Object.keys(prevStatus).map((kay) =>
                    modal == 'showChangeRolePopup' ? ['accessPermissionPopup', true] : [kay, false]
                )
            ),

            [modal]: true,
        }));
    };

    const handleActionState = ({ action, toggle = false, value = false }) => {
        setActionState((prevState) => ({
            ...prevState,
            [action]: toggle ? !prevState[action] : value,
        }));
    };

    const handleClickAdd = async ({ enableEditMode = false }) => {
        !enableEditMode && handleActionState({ action: 'isEditMode', value: false });
        if (actionState.isShowUserList) {
            handlePopStatus('addUserPopup');
            getPresetList({ handleArrayState, setloaderStatus, selectedRestaurantId });
        } else {
            handleArrayState({
                array: 'checkedPresets',
                updatedValue: [],
            });
            handleClickAddRole();
        }
    };
    const handleClickAddRole = () => {
        handlePopStatus('addPresetPopup');
        getPermissionList().then((permissionList) => {
            handleArrayState({ array: 'permissionList', updatedValue: permissionList });
        });
    };

    const resetPopupStatus = () => {
        methods.reset();
        setPopupStatus({
            addUserPopup: false,
            addPresetPopup: false,
            accessPermissionPopup: false,
            showConfirmDeletePopup: false,
            showChangeRolePopup: false,
        });
        setActionState((prevState) => ({
            ...prevState,
            isOverWriteChanges: false,
            isEditPermiison: false,
        }));
        setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        handleArrayState({ array: 'permissionList', updatedValue: [] });
    };

    const handleClickEditPresetList = async (preset) => {
        try {
            const presetName = preset?.name || getValues('user_role');
            const presetId =
                preset?.id ||
                arrayState.presetList?.data?.find((item) => item.name === (preset?.user_role ?? presetName))?.id;
            setValue('defaultvalue.preset_name', presetName);
            setValue('preset_name', presetName);
            setValue('preset_Id', presetId);
            handlePopStatus('accessPermissionPopup');
            handleArrayState({
                array: 'permissionList',
                updatedValue: [],
            });
            const [permissionListResponse, selectedPresetDetail] = await Promise.all([
                getPermissionList(),
                getSelectedPresetDetail({ presetId: getValues('preset_Id'), selectedRestaurantId }),
            ]);
            setValue(
                'checkedPresetsOld',
                selectedPresetDetail?.custom_role?.permissions.map(({ id, ...rest }) => rest)
            );
            handleArrayState({
                array: 'checkedPresets',
                updatedValue: selectedPresetDetail?.custom_role?.permissions.map(({ id, ...rest }) => rest),
            });
            const data = permissionListResponse?.objects?.map((item) => {
                const customRolePermission = selectedPresetDetail?.custom_role?.permissions?.filter(
                    (permission) => permission?.permission_object_id === item?.id
                );
                customRolePermission &&
                    customRolePermission.map((items) => {
                        if (items?.permission_object_id == item?.id) {
                            item = {
                                ...item,
                                permission_id: customRolePermission.map(
                                    (permissionList) => permissionList.permission_id
                                ),
                            };
                        }
                    });
                return item;
            });
            handleArrayState({ array: 'permissionList', updatedValue: { ...permissionListResponse, objects: data } });
        } catch (error) {
            console.error('Error occurred:', error);
        }
    };

    const handleClickEdit = () => {
        handleActionState({ action: 'isEditMode', toggle: true });
        handleActionState({ action: 'isBulkSelect', value: false });
    };
    const handleClickBulkSelect = () => {
        handleArrayState({ array: 'selectedForDelete', updatedValue: [] });
        handleActionState({ action: 'isBulkSelect', toggle: true });
        handleActionState({ action: 'isEditMode', value: false });
    };

    useEffect(() => {
        params.tab === 'user-list'
            ? handleArrayState({ array: 'usersList', updatedValue: routeData?.users })
            : handleArrayState({ array: 'presetList', updatedValue: routeData?.custom_roles });
    }, [routeData]);

    const handleTabChange = (tab) => {
        if (tab === 'user-list' && params.tab != tab) {
            handleActionState({ action: 'isShowUserList', value: true });
            setPaginationState({
                selectedPage: 1,
                resultPerPage: 10,
            });
            handleNavigation({
                route: 'user/user-list',
            });
        } else if (tab == 'preset-list' && params.tab != tab) {
            handleActionState({ action: 'isShowUserList', value: false });
            handleActionState({ action: 'isFilterActive', value: false });
            setPaginationState({
                selectedPage: 1,
                resultPerPage: 10,
            });
            handleNavigation({
                route: 'user/preset-list',
            });
        }
    };

    const handleDelete = async () => {
        try {
            const key = !actionState.isShowUserList ? 'custom_role_ids' : 'user_ids';

            const requestedBody = {
                params: { [key]: [...arrayState.selectedForDelete] },
            };

            if (actionState.isShowUserList) {
                deleteUsers({
                    requestedBody,
                    handleClickClose: resetPopupStatus,
                    handleArrayState,
                    setloaderStatus,
                    selectedRestaurantId,
                });
            } else {
                deletePresets({
                    requestedBody,
                    handleClickClose: resetPopupStatus,
                    handleArrayState,
                    setloaderStatus,
                    selectedRestaurantId,
                });
            }
        } catch (error) {}
    };

    const handlePagination = async ({ page, perPage, isScroll }) => {
        page && handlePaginationState({ modal: 'selectedPage', updatedData: page });
        perPage && handlePaginationState({ modal: 'resultPerPage', updatedData: perPage });
        actionState.isShowUserList && !actionState.isFilterActive
            ? getUsers({
                  handleArrayState,
                  pageNumber: page ?? paginationState.selectedPage,
                  perPage: perPage ?? paginationState.resultPerPage,
                  isScroll,
                  setloaderStatus,
                  keyword: appliedFilter,
                  selectedRestaurantId,
              })
            : getPresetList({
                  handleArrayState,
                  pageNumber: page ?? paginationState.selectedPage,
                  perPage: perPage ?? paginationState.resultPerPage,
                  isScroll,
                  setloaderStatus,
                  selectedRestaurantId,
              });
    };

    const handleFilterClick = async () => {
        if (!actionState.isFilterActive) {
            await getPresetList({ handleArrayState, setloaderStatus, selectedRestaurantId });
            handleActionState({ action: 'isFilterActive', value: true });
        } else handleActionState({ action: 'isFilterActive', value: false });
    };
    return (
        <FormProvider {...methods}>
            <Header
                handleTabChange={handleTabChange}
                handleClickEdit={handleClickEdit}
                handleClickAdd={handleClickAdd}
                handleClickBulkSelect={handleClickBulkSelect}
                handleFilterClick={handleFilterClick}
                handlePopStatus={handlePopStatus}
                selectedForDelete={arrayState.selectedForDelete}
                actionState={actionState}
            />
            {actionState.isFilterActive && (
                <Filters
                    setAppliedFilter={setAppliedFilter}
                    presetList={arrayState.presetList}
                    handlePagination={handlePagination}
                    selectedPage={paginationState.selectedPage}
                    isEditUser={actionState.isEditMode}
                    handleArrayState={handleArrayState}
                    setloaderStatus={setloaderStatus}
                />
            )}
            {(!isMobileScreen || !popupStatus.addUserPopup) && (
                <>
                    {params.tab === 'user-list' ? (
                        <UserList
                            handleArrayState={handleArrayState}
                            handleClickAdd={handleClickAdd}
                            actionState={actionState}
                            arrayState={arrayState}
                            loaderStatus={loaderStatus}
                            setloaderStatus={setloaderStatus}
                            handlePopStatus={handlePopStatus}
                            popupStatus={popupStatus}
                            paginationState={paginationState}
                            handlePagination={handlePagination}
                        />
                    ) : (
                        <PresetList
                            presetList={arrayState.presetList}
                            handleClickEditPresetList={handleClickEditPresetList}
                            handleArrayState={handleArrayState}
                            actionState={actionState}
                            loaderStatus={loaderStatus}
                            selectedPage={paginationState.selectedPage}
                            handlePagination={handlePagination}
                            arrayState={arrayState}
                        />
                    )}
                </>
            )}
            {!isMobileScreen && (
                <div className='mt-6 pb-6'>
                    <PaginationWithNumber
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => handlePagination({ page: value })}
                        setResultPerPage={(value) => handlePagination({ perPage: value })}
                        selectedResultPerpage={paginationState.resultPerPage}
                        lastPage={
                            actionState.isShowUserList
                                ? arrayState.usersList?.last_page
                                : arrayState.presetList?.last_page
                        }
                    />
                </div>
            )}

            {popupStatus.addUserPopup && (
                <UserPopup
                    handleClickClose={resetPopupStatus}
                    isEditUser={actionState.isEditMode}
                    presetList={arrayState.presetList}
                    handlePagination={handlePagination}
                    selectedPage={paginationState.selectedPage}
                    handleClickAddRole={handleClickAddRole}
                    popupStatus={popupStatus}
                    handleClickEditPresetList={handleClickEditPresetList}
                    loaderStatus={loaderStatus}
                    setloaderStatus={setloaderStatus}
                />
            )}
            {popupStatus.addPresetPopup && (
                <CreatePreset
                    handleClickClose={resetPopupStatus}
                    handleArrayState={handleArrayState}
                    permissionList={arrayState.permissionList}
                    checkedPresets={arrayState.checkedPresets}
                    arrayState={arrayState}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    selectedRestaurantId={selectedRestaurantId}
                />
            )}
            {popupStatus.accessPermissionPopup && (
                <SelectAccessPermission
                    header={actionState.isShowUserList ? 'add user' : 'preset list'}
                    handleClickClose={resetPopupStatus}
                    handleArrayState={handleArrayState}
                    handlePopStatus={handlePopStatus}
                    arrayState={arrayState}
                    actionState={actionState}
                    handleClickEditPresetList={handleClickEditPresetList}
                    setloaderStatus={setloaderStatus}
                    loaderStatus={loaderStatus}
                    paginationState={paginationState}
                    handlePagination={handlePagination}
                    handleClickAddRole={handleClickAddRole}
                    oldValue={getValues('defaultvalue')}
                />
            )}

            {popupStatus.showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    header={
                        <h2 className='paragraph-large-medium ml-2'>
                            Delete the {actionState.isShowUserList ? 'User' : 'Preset'}?
                        </h2>
                    }
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            {`Are you sure to delete selected 
                            ${
                                actionState.isShowUserList
                                    ? arrayState.selectedForDelete.length > 1
                                        ? 'Users'
                                        : 'User'
                                    : arrayState.selectedForDelete.length > 1
                                      ? 'Presets'
                                      : 'Preset'
                            }`}
                        </p>
                    }
                    handleClickClose={resetPopupStatus}
                    confirmDelete={handleDelete}
                    loaderStatus={loaderStatus}
                />
            )}
            {popupStatus.showChangeRolePopup && (
                <ConfirmRoleChangePopup
                    header={<h2 className='paragraph-large-medium ml-2'>Edit the Preset</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure to edit the preset
                        </p>
                    }
                    handleClickClose={resetPopupStatus}
                    confirm={() => {
                        handleActionState({ action: 'isEditPermiison', value: true });
                        handleActionState({ action: 'isOverWriteChanges', value: true });
                        handleClickEditPresetList();
                    }}
                />
            )}
        </FormProvider>
    );
}
