import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react';
import Header from './Header';

export default function Layout(props) {
    const {
        children,
        showMainLayout,
        selectedDates,
        setSelectedDates,
        fetchOrderDetail,
        setFilters,
        setIsLoading,
        setCashbackTypePagination,
        orderDetails,
    } = props;

    const screenHeight = useWindowSize().height;

    return (
        <>
            <div className='bg-[#fafafa]'>
                {showMainLayout && (
                    <>
                        <div className='pb-[30px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'>
                            <Header
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                                fetchOrderDetail={fetchOrderDetail}
                                setFilters={setFilters}
                                setIsLoading={setIsLoading}
                                setCashbackTypePagination={setCashbackTypePagination}
                                orderDetails={orderDetails}
                            />
                            {children}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
