import React from 'react';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../Components/InputField/InputSection';

export default function BuyXGetYSection() {
    return (
        <div className='mb-6'>
            <TitleDescription
                className='mb-4'
                title='Buy x and get y offer'
                headerBottomLine='Enter below value for buy x and get y offer to apply this offer.'
            />

            <div className='flex flex-row justify-between w-full mb-6 lg:block gap-4'>
                <InputSection
                    name='buy_x_quantity'
                    inputType='number'
                    label='(Enter buy quantity in number)'
                    placeholder='Enter buy quantity in number'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    className='w-full relative lg:mb-4 md:max-w-full'
                    rules={{ required: 'Please enter buy quantity' }}
                />

                <InputSection
                    name='get_y_quantity'
                    inputType='number'
                    label='(Enter get quantity in number)'
                    placeholder='Enter get quantity in number'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    className='w-full md:max-w-full md:ml-0'
                    rules={{ required: 'Please enter get quantity' }}
                />
            </div>
        </div>
    );
}
