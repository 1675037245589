import * as typesAuth from '../reduxStore/types/auth';
import * as typeLoading from '../reduxStore/types/loading';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import { FIREBASE_CONFIG } from '../envConfig';

export const requestNotificationPermission = async (dispatch) => {
    try {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            generateDeviceToken(dispatch);
        }
    } catch (error) {
        console.log('An error occurred during the permission request: ', error);
    }
};

export const generateDeviceToken = (dispatch) => {
    navigator.serviceWorker?.ready?.then((registration) => {
        let serviceWorker;

        if (registration.installing) {
            serviceWorker = registration.installing;
        } else if (registration.waiting) {
            serviceWorker = registration.waiting;
        } else if (registration.active) {
            serviceWorker = registration.active;
        }

        if (serviceWorker?.state === 'activated') {
            setFirebaseToken(dispatch, serviceWorker);
        } else {
            serviceWorker.addEventListener('statechange', () => {
                if (serviceWorker?.state === 'activated') {
                    setFirebaseToken(dispatch, serviceWorker);
                }
            });
        }

        const webFirebaseInstant = initializeApp(FIREBASE_CONFIG);

        const messagingInstance = getMessaging(webFirebaseInstant);

        onMessage(messagingInstance, function (payload) {
            const notificationTitle = payload.data.title;

            const notificationOptions = {
                body: payload.data?.body,

                actions: [
                    {
                        action: 'coffee-action',
                        title: 'Coffee',
                        type: 'button',
                    },
                ],
            };

            registration.showNotification(notificationTitle, notificationOptions);
        });
    });
};

const setFirebaseToken = (dispatch, serviceWorker) => {
    serviceWorker.postMessage('get-firebase-token');

    const firebaseTokenGeneration = new BroadcastChannel('firebaseTokenTransferChannel');

    firebaseTokenGeneration.onmessage = (event) => {
        const token = event.data.firebaseToken;

        localStorage.setItem('firebaseToken', token);

        dispatch({
            type: typesAuth.SET_FIREBASE_TOKEN,
            payload: {
                firebaseToken: token,
            },
        });

        dispatch({
            type: typeLoading.FINISH_LOADING,
        });
    };
};
