import React from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import Tabs from './Components/Tabs';
import AutoAssignSection from './Components/AutoAssignSection/AutoAssignSection';
import { useParams } from 'react-router-dom';
import RiderSection from './Components/RiderSection/RiderSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';
import { riderPreferenceMenuItems } from './Components/RiderSection/constants';

export default function RiderSetting(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const { subTab } = useParams();

    const {
        handleSubmit,
        formState: { isDirty },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateRiderSetting = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=rider_setting`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateAutoAssignRider = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=auto_assignment_rider_settings`, {
                ...params,
            });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatRiderSetting = (data) => {
        return {
            rider_setting: {
                enable_own_rider_service: data.in_house_riders.preference.value,
                elt: {
                    elt_integration_available: data.elt_integration_available.preference.value === true ? 1 : 0,
                    elt_api_key: data?.elt_riders?.elt_api_key ?? '',
                    elt_primary_address_id: data?.elt_riders?.elt_primary_address_id ?? '',
                },
                dunzo: {
                    dunzo_integration_available: data.dunzo_integration_available.preference.value === true ? 1 : 0,
                    dunzo_client_id: data?.dunzo_riders?.dunzo_client_id ?? '',
                    dunzo_client_secret: data?.dunzo_riders?.dunzo_client_secret ?? '',
                    dunzo_call_back_url: data?.dunzo_riders?.dunzo_call_back_url ?? '',
                    use_global_dunzo_configurations:
                        data.dunzo_integration_available.preference.value === 'central' ? 1 : 0,
                },
                wefast: {
                    wefast_integration_available: data.wefast_integration_available.preference.value === true ? 1 : 0,
                    wefast_auth_token: data?.wefast_riders?.wefast_auth_token ?? '',
                    wefast_call_back_url: data?.wefast_riders?.wefast_call_back_url ?? '',
                    use_global_wefast_configurations:
                        data.wefast_integration_available.preference.value === 'central' ? 1 : 0,
                },
                shadowfax: {
                    is_shadowfax_integration_enabled:
                        data.is_shadowfax_integration_enabled.preference.value === true ? 1 : 0,
                    shadowfax_token: data?.shadowfax_riders?.shadowfax_token ?? '',
                    shadowfax_client_code: data?.shadowfax_riders?.shadowfax_client_code ?? '',
                    use_global_shadowfax_configurations:
                        data.is_shadowfax_integration_enabled.preference.value === 'central' ? 1 : 0,
                },
                porter: {
                    is_porter_integration_enabled: data.is_porter_integration_enabled.preference.value === true ? 1 : 0,
                    porter_token: data?.porter_riders?.porter_token ?? '',
                    use_global_porter_configurations:
                        data.is_porter_integration_enabled.preference.value === 'central' ? 1 : 0,
                },
            },
        };
    };

    const formatAutoAssign = (data, autoAssignRider) => {
        const rider_service_preferences =
            data.ridersPreference.value === 'priority'
                ? []
                : data?.rider_auto_assignment_by_preferences?.map((el, index) => ({
                      ...el,
                      waiting_time: data?.edittingTime?.[el.id] ?? el.waiting_time,
                      preference_rank: index + 1,
                  }));

        return {
            intervention_time: {
                minutes: data.hasManualIntervention.value ? autoAssignRider.intervention_time.minutes : null,
                seconds: data.hasManualIntervention.value ? autoAssignRider.intervention_time.seconds : null,
            },
            enable_rider_auto_assignment_by_priority: data.ridersPreference.value === 'priority' ? 1 : 0,
            enable_rider_auto_assignment_by_preference: data.ridersPreference.value === 'priority' ? 0 : 1,
            rider_service_preferences,
        };
    };

    const sendRequestForCentral = async (params) => {
        try {
            await APIV5.post(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/request-third-party-service-topup`,
                { ...params }
            );
        } catch (error) {
            console.log(error);
        }
    };

    const formatRiderData = (riderData) => {
        const centralEnableService = Object.keys(riderData).filter(
            (key) => riderData[key].preference && riderData[key].preference.value === 'central'
        );
        centralEnableService.map((el) => sendRequestForCentral({ third_party_service: el }));
    };

    const updateRiderSettings = (riderDatas) => {
        const filteredRiderData = Object.keys(riderDatas)
            .filter(
                (key) =>
                    riderDatas[key]?.preference &&
                    riderDatas[key]?.preference.value !== riderPreferenceMenuItems[0]?.value
            )
            .reduce((obj, key) => {
                obj[key] = riderDatas[key];
                return obj;
            }, {});

        const riderSettingPayload = {
            rider_setting: {
                ...filteredRiderData,
                enable_own_rider_service: filteredRiderData.in_house_riders.preference.value,
            },
        };

        updateRiderSetting(riderSettingPayload);
    };

    const onSubmit = (data) => {
        setShowTabChangeWarningPopup(false);
        if (subTab === 'rider') {
            formatRiderData(data.rider_setting);
            updateRiderSettings(data.rider_setting);

            return;
        }
        updateAutoAssignRider(
            formatAutoAssign(data.rider_setting.rider_auto_assignment, data.auto_assignment_rider_settings)
        );
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <Tabs />

                {subTab === 'rider' ? (
                    <RiderSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />
                ) : (
                    <AutoAssignSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />
                )}
            </div>
        </Layout>
    );
}
