import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';

const SaveDiscartSettings = (props) => {
    const { handleClickSave, handleClickCancel } = props;

    return (
        <>
            <div className='absolute z-[19] group-[.undock-left-panel]:z-[8] md:fixed md:border-none md:shadow-dropShadow left-0 right-0 bottom-0 bg-white border-t border-neutral-300'>
                <div className='flex flex-row justify-end w-full md:justify-center px-8 py-6 lg:py-4 md:py-0 md:pb-1 md:pt-2'>
                    <div
                        className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                        onClick={handleClickCancel}>
                        <LargeDestructiveButton
                            label='Discard'
                            isDefault={false}
                        />
                    </div>

                    <div
                        className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                        onClick={handleClickSave}>
                        <LargePrimaryButton
                            label='Save'
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaveDiscartSettings;
