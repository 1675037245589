import React from 'react';
import SectionTab from './SectionTab';

export default function TabSection(props) {
    const {
        isEditMode,
        sectionList,
        setPopupStatus,
        setValue,
        setloaderStatus,
        setTableList,
        setTotalTable,
        setShowTableContent,
    } = props;
    return (
        <>
            <div className='flex justify-between mb-6'>
                <SectionTab
                    isEditMode={isEditMode}
                    sectionList={sectionList}
                    setPopupStatus={setPopupStatus}
                    setValue={setValue}
                    setloaderStatus={setloaderStatus}
                    setTableList={setTableList}
                    setTotalTable={setTotalTable}
                    setShowTableContent={setShowTableContent}
                />
                {/* <StatusTab isEditMode={isEditMode} /> */}
            </div>
        </>
    );
}
