import React, { useEffect, useState } from 'react';
import { ReactComponent as SuccessIcon } from '../../../Assets/success-tick.svg';
import { ReactComponent as ReimbursementIcon } from '../../../Assets/reimbursement.svg';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../Assets/schedule.svg';

export const CreditInformationCard = (props) => {
    const { minHeight, title, number, description, icon } = props;

    return (
        <>
            <div
                className={`border border-neutral-300 rounded-md px-4 py-3 pb-2.5 relative w-full ${
                    minHeight ?? 'min-h-[136px]'
                }`}>
                <div className='paragraph-small-semi-bold text-neutral-500 mb-3'>{title}</div>

                <div className='flex flex-props items-center mb-3'>
                    <span className='heading-h6-semi-bold-desktop mr-2'>{number}</span>
                </div>

                <div className='max-w-[231px] paragraph-x-small-regular text-neutral-500'>{description}</div>

                <div className='absolute right-4 top-3 bg-primary-500 h-8 w-8 rounded-lg justify-center flex'>
                    <span className='m-auto'>{icon}</span>
                </div>
            </div>
        </>
    );
};

export const ReferenceCard = (props) => {
    const { referenceId, type, service, amount, rechargeDate, status } = props;

    const splittedDate = rechargeDate?.split(' ');

    const date = splittedDate.slice(0, 3).join(' ');

    const time = splittedDate.slice(3, 5).join(' ');

    const [isActiveBackground, setIsActiveBackground] = useState(false);

    return (
        <>
            <div
                className={`border rounded-lg p-4 paragraph-small-regular cursor-pointer ${
                    isActiveBackground ? 'bg-primary-100 border-primary-500' : 'bg-white border-neutral-300'
                }`}
                onClick={() => setIsActiveBackground((prevState) => !prevState)}>
                <div className='flex items-center pb-3'>
                    <span className='paragraph-overline-small pr-2'>REFERENCE ID:</span>
                    <span>{referenceId}</span>
                </div>

                <div className='flex items-center pb-3'>
                    <span className='paragraph-overline-small pr-2'>TYPE:</span>
                    <span>{type}</span>
                </div>

                <div className='flex items-center pb-3'>
                    <span className='paragraph-overline-small pr-2'>SERVICE:</span>
                    <span>{service}</span>
                </div>

                <div className='flex items-center pb-3'>
                    <span className='paragraph-overline-small pr-2'>STATUS:</span>
                    <div className='flex flex-row items-center'>
                        {status === 'Deducted' ? (
                            <SuccessIcon
                                height={24}
                                width={24}
                            />
                        ) : (
                            <ReimbursementIcon
                                height={24}
                                width={24}
                            />
                        )}
                        <span className='ml-1'>{status}</span>
                    </div>
                </div>

                <div className='flex items-center pb-3'>
                    <span className='paragraph-overline-small pr-2'>AMOUNT:</span>
                    <span>{amount}</span>
                </div>

                <div className='flex flex-row'>
                    <span className='paragraph-overline-small pr-2'>RECHARGE DATE:</span>
                    <div className=''>
                        <div className='flex items-center pb-0.5'>
                            <CalenderIcon />
                            <span className='pl-1'>{date}</span>
                        </div>

                        <div className='flex items-center pt-[3px]'>
                            <ScheduleIcon />
                            <span className='pl-1'>{time}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
