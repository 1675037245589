import { useSortable } from '@dnd-kit/sortable';
import { ListViewAppearance } from './ListViewAppearance';
import { ReactComponent as LinearMenuIcon } from '../../../../../../Assets/linear-menu.svg';
import { CSS } from '@dnd-kit/utilities';
import { fornatOrderName } from '../../../../Constants/function';

export const TableRows = (props) => {
    const { section_name, index, isRearrange, id } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    return (
        <tr
            ref={setNodeRef}
            style={style}
            key={index}
            {...attributes}
            {...listeners}
            className={`h-[70px] justify-center paragraph-small-regular border-t first:border-none border-neutral-300 ${
                isRearrange && 'cursor-pointer'
            }`}>
            <td className='px-6'>
                <div className='flex flex-auto items-center'>
                    {isRearrange && (
                        <div className='mr-3'>
                            <LinearMenuIcon />
                        </div>
                    )}

                    <span>{fornatOrderName(section_name)}</span>
                </div>
            </td>
        </tr>
    );
};

export const ListView = (props) => {
    const { index, label, isRearrange, id } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    return (
        <div
            className='mt-2'
            key={index}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}>
            <ListViewAppearance
                sectionName={label}
                isRearrange={isRearrange}
            />
        </div>
    );
};
