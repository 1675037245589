import React from 'react';
import { ReactComponent as RedirectIcon } from '../../../Assets/redirect.svg';
import { ReactComponent as ReArrangeIcon } from '../../../Assets/re-arrange.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { Tab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { IsMobileScreen } from '../../../Constants/Constants';

const Header = (props) => {
    const { tab, setIsShowAddBannerPopup, bannerType, setPageState } = props;

    const navigate = useNavigate();

    const handleTabChange = (tab) => {
        navigate(`/banners/${tab}/${bannerType}`);
    };

    const handleClickLibrary = () => {
        navigate('/media-library/grid');
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='flex flex-row justify-between pb-4 mb-4 border-b border-neutral-300 md:block'>
                <div className='flex flex-row md:mb-4'>
                    <div
                        className='mr-2 w-1/2 min-w-[175px] md:min-w-0 lg:mr-0 md:mr-2 mobile:mr-1 cursor-pointer'
                        onClick={() => handleTabChange('banner-catalogue')}>
                        <Tab
                            label='Banner catalogue'
                            isActive={tab === 'banner-catalogue'}
                            bannerTabStyle='md:text-[15px] mobile:text-sm'
                        />
                    </div>

                    <div
                        className='w-1/2 ml-2 mobile:ml-1 cursor-pointer'
                        onClick={() => handleTabChange('store-banner')}>
                        <Tab
                            label='Store banners'
                            isActive={tab === 'store-banner'}
                            bannerTabStyle='md:text-[15px] mobile:text-sm'
                        />
                    </div>
                </div>

                <div className='flex flex-row'>
                    <div
                        className='md:w-1/2 lg:mr-0'
                        onClick={handleClickLibrary}>
                        <LargePrimaryButton
                            label='Media library'
                            leftIconDefault={<RedirectIcon fill='#FFFFFF' />}
                            leftIconClick={<RedirectIcon fill='#C4BEED' />}
                        />
                    </div>

                    {tab === 'banner-catalogue' ? (
                        <div
                            className='ml-4 md:w-1/2 mobile:ml-2 cursor-pointer'
                            onClick={() => setIsShowAddBannerPopup(true)}>
                            <LargePrimaryButton
                                isDefault={false}
                                label='Add banner'
                                leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                                leftIconClick={<AddIcon stroke='#C4BEED' />}
                            />
                        </div>
                    ) : (
                        isMobileScreen && (
                            <div
                                className='ml-4 md:w-1/2 mobile:ml-2 cursor-pointer'
                                onClick={() =>
                                    setPageState((prevState) => {
                                        return { ...prevState, isRearrange: !prevState.isRearrange };
                                    })
                                }>
                                <LargePrimaryButton
                                    label='Re-arrange'
                                    leftIconDefault={<ReArrangeIcon stroke='#ffffff' />}
                                    leftIconClick={<ReArrangeIcon stroke='#C4BEED' />}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
