import * as types from '../types/auth';
import { v4 as uuidv4 } from 'uuid';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

const selectedRestaurantId = localStorage.getItem('selectedRestaurantID')
    ? JSON.parse(localStorage.getItem('selectedRestaurantID'))
    : null;

const firebaseToken = localStorage.getItem('firebaseToken') ? localStorage.getItem('firebaseToken') : null;

const authToken = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null;

const availableUsers = localStorage.getItem('availableUsers') ? localStorage.getItem('availableUsers') : [];

let deviceId = localStorage.getItem('deviceId') ? localStorage.getItem('deviceId') : null;

if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem('deviceId', deviceId);
}

const initialState = {
    deviceId,
    user,
    firebaseToken,
    restaurantDescriptions: {},
    redeemPointValueOnPurchase: 0,
    restaurantList: [],
    selectedRestaurantId,
    authToken,
    availableUsers,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_FIREBASE_TOKEN:
            return {
                ...state,
                firebaseToken: action.payload.firebaseToken,
            };

        case types.SET_USER:
            return {
                ...state,
                user: { ...action.payload },
            };

        case types.SET_RESTAURANT_DESCRIPTIONS:
            return {
                ...state,
                restaurantDescriptions: action.payload.restaurantDescriptions,
            };

        case types.SET_REDEEM_POINTS_ON_PURCHASE:
            return {
                ...state,
                redeemPointValueOnPurchase: action.payload.redeemPointValueOnPurchase,
            };
        case types.SET_NUMBER_OF_RESTAURANTS:
            return {
                ...state,
                numberOfRestaurants: action.payload.numberOfRestaurants,
            };

        case types.SET_RESTAURANT_LIST:
            return {
                ...state,
                restaurantList: action.payload.restaurantList,
            };

        case types.SET_RESTAURANT_ID:
            return {
                ...state,
                selectedRestaurantId: action.payload.restaurantId,
            };

        case types.SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload.authToken,
            };

        case types.SET_AVAILABLE_USER:
            return { ...state, availableUsers: action.payload.availableUser };

        default:
            return state;
    }
};
