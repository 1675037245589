import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../../../Assets/close.svg';
import { IsMobileScreen } from '../../../../../../../Constants/Constants';

export default function Layout(props) {
    const { handleClickClose, handleClickBack, children, dishOperationState } = props;

    const isMobileScreen = IsMobileScreen();

    const titleTagline = {
        move: {
            title: 'Select dishes to move',
            tagline: 'Moving dishes would transfer the items from the original source to the new destination.',
        },
        copy: {
            title: 'Select dishes to copy',
            tagline: 'Copying dishes would create a duplicate of the original dish at the destination.',
        },
        map: {
            title: 'Select dishes to map',
            tagline: 'Mapping dish would link the dish to the destination creating identical real-time clone',
        },
    };

    return (
        <>
            <div
                className={`fixed bg-black bg-opacity-50 inset-0 z-[101] flex py-[18px] md:py-0 md:absolute md:z-[9] md:top-0 md:block`}>
                <div
                    className={`max-w-[722px] w-full rounded-xl bg-shades-50 pt-6 pb-6 px-8 md:p-4 m-auto md:m-0 h-full max-h-[991px] md:max-w-full md:max-h-full md:rounded-none md:py-4 md:pb-14`}>
                    {!isMobileScreen ? (
                        <div className='flex flex-row items-center justify-between mb-6'>
                            <div>
                                <h3 className='paragraph-large-medium'>{titleTagline[dishOperationState]?.title}</h3>
                                <div className='flex flex-row items-center'>
                                    <p className='paragraph-medium-italic text-neutral-500'>
                                        {titleTagline[dishOperationState]?.tagline}
                                    </p>
                                </div>
                            </div>

                            <span
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </span>
                        </div>
                    ) : (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                            onClick={handleClickBack}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to categories</span>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </>
    );
}
