import React from 'react';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../Assets/schedule.svg';
import { ReactComponent as SuccessIcon } from '../../../Assets/success-tick.svg';
import { ReactComponent as ReimbursementIcon } from '../../../Assets/reimbursement.svg';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';

export default function Table(props) {
    const { tableContent } = props;

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full table-fixed break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                            <th className='text-left pl-6 w-[188px]'>REFERENCE ID</th>
                            <th className='text-left pl-6 w-[189px]'>TYPE</th>
                            <th className='text-left pl-6 w-[248px]'>SERVICE</th>
                            <th className='text-left pl-6 w-[227px]'>STATUS</th>
                            <th className='text-left pl-6 w-[187px]'>AMOUNT</th>
                            <th className='text-left pl-6 w-[231px]'>RECHARGE DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableContent.length === 0 ? (
                            <tr>
                                <td colSpan={6}>
                                    <TableBlankComponentView previewText={'No Content Available'} />
                                </td>
                            </tr>
                        ) : (
                            tableContent.map((el, index) => {
                                const splittedDate = el.date?.split(' ');

                                const date = splittedDate?.slice(0, 3).join(' ');

                                const time = splittedDate?.slice(3, 5).join(' ');

                                return (
                                    <tr
                                        key={index}
                                        className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                        <td className='pl-6'>{el.reference_id}</td>
                                        <td className='pl-6'>{el.type}</td>
                                        <td className='pl-6'>{el.third_party_api_name ?? '-'}</td>
                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {el.status === 'Deducted' ? (
                                                    <SuccessIcon
                                                        height={24}
                                                        width={24}
                                                    />
                                                ) : (
                                                    <ReimbursementIcon
                                                        height={24}
                                                        width={24}
                                                    />
                                                )}
                                                <span className='ml-1'>{el.status}</span>
                                            </div>
                                        </td>
                                        <td className='pl-6'>{el.amount} </td>
                                        <td className='pl-6'>
                                            <div className='flex items-center pb-0.5'>
                                                <CalenderIcon />
                                                <span className='pl-1'>{date}</span>
                                            </div>

                                            <div className='flex items-center pt-[3px]'>
                                                <ScheduleIcon />
                                                <span className='pl-1'>{time}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
