import React, { useMemo } from 'react';
import AvatarImage from '../../../Assets/avatar-image.png';
import { ReactComponent as HamburgerIcon } from '../../../Assets/hamburger.svg';
import UserHeaderIcon from '../../../Components/Avatar/AvatarsIcon';
import HoldOrder from '../HoldOrder';
import { useLocation } from 'react-router-dom';
import { links } from './links';

export default function MobileHeader(props) {
    const {
        isHeaderSticky,
        toggleLeftPanel,
        isHoldOrder,
        isDockLetPanel,
        isLeftPanelExpanded,
        setIsLeftPanelExpanded,
    } = props;

    const url = useLocation().pathname;

    const pageLabel = useMemo(() => {
        const moduleUrl = url.match(/\/[^/]+/)?.[0] ?? url;

        const matchedLink = links.find((link) => (link.baseUrl ?? link.to) === moduleUrl);

        return matchedLink?.name ?? matchedLink;
    }, [url]);

    return (
        <>
            <div
                className={`flex items-center justify-between bg-primary-500 pl-5 pr-4 fixed top-0 left-0 right-0 z-10 transition-effect ${
                    isHeaderSticky ? 'py-2.5 shadow-shadowWhite' : 'pt-[60px] pb-4'
                }`}>
                <div
                    className='cursor-pointer'
                    onClick={toggleLeftPanel}>
                    <HamburgerIcon
                        stroke='#FFFFFF'
                        width={32}
                        height={32}
                    />
                </div>

                {isLeftPanelExpanded && !isDockLetPanel && (
                    <span
                        onClick={() => setIsLeftPanelExpanded(false)}
                        style={{
                            display: 'block',
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 30,
                        }}
                    />
                )}

                <span className='paragraph-large-semi-bold text-white mobile:paragraph-medium-semi-bold mobile:text-white'>
                    {pageLabel}
                </span>

                <div className='flex items-center'>
                    {isHoldOrder && (
                        <div className='border-r border-neutral-300 mr-4 pr-4'>
                            <HoldOrder />
                        </div>
                    )}

                    <UserHeaderIcon
                        widthOuter='8'
                        heightOuter='8'
                        imageIcon={AvatarImage}
                        colorIcon='[#22C55E]'
                        widthIcon='3'
                        heightIcon='3'
                    />
                </div>
            </div>
        </>
    );
}
