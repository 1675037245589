import React from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import RestaurantOfferTable from './RestaurantOfferTable';
import RestaurantOfferListSection from './RestaurantOfferListSection';

export default function RestaurantOfferTableSection(props) {
    const { selectedOffer, setSelectedOffer } = props;

    const isMobileScreen = IsMobileScreen();

    const onSelectOffer = (id, isSelected) => {
        const selection = isSelected ? [...selectedOffer, id] : selectedOffer.filter((el) => el !== id);

        setSelectedOffer(selection);
    };

    return (
        <>
            <section>
                {!isMobileScreen ? (
                    <RestaurantOfferTable
                        {...props}
                        onSelectOffer={onSelectOffer}
                    />
                ) : (
                    <RestaurantOfferListSection
                        {...props}
                        onSelectOffer={onSelectOffer}
                    />
                )}
            </section>
        </>
    );
}
