import React, { useEffect, useState } from 'react';
import CircleLoader from '../CircleLoader/CircleLoader';

export const LargePrimaryButton = (props) => {
    const {
        isDefault,
        buttonStyle,
        disabled,
        padding,
        containerStyle,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        fontsSize,
        hideLabel,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isLoading,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (typeof props.isClicked === 'boolean') {
            setIsClicked(props.isClicked);
        }
    }, [props.isClicked]);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-primary-600' : 'bg-primary-500'
                } h-12 rounded-md hover:bg-primary-600 disabled:bg-primary-200 group w-full items-center flex justify-center ${buttonStyle}`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className={`flex flex-row ${padding ?? 'px-4'} md:px-1 items-center truncate ${containerStyle}`}>
                    {leftIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    {label && (
                        <span
                            className={`${isClicked ? 'text-primary-200' : 'text-shades-50'} truncate ${
                                fontsWeight ?? 'font-normal'
                            } ${fontsSize ?? 'text-base'} paragraph-medium-medium mx-1 ${hideLabel} ${isLoading && 'flex gap-3 flex-row items-center'}`}>
                            {label}

                            {isLoading && (
                                <CircleLoader
                                    isLoading={true}
                                    isResponseSuccess={false}
                                />
                            )}
                        </span>
                    )}

                    {rightIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}

                    {badgeText && (
                        <span className='px-2.5 py-0.5 rounded-3xl text-primary-500 bg-white group-hover:text-primary-600 mx-1 group-disabled:text-primary-200 paragraph-x-small-medium'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const LargeTertiaryButton = (props) => {
    const {
        disabled,
        bottonStyle,
        leftIconClick,
        leftIconDefault,
        label,
        fontsWeight,
        nameMargin,
        hideLabel,
        rightIconDefault,
        rightIconClick,
        badgeText,
        isDefault,
        btnStyle,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-tertiary-600' : 'bg-tertiary-700'
                } h-12 rounded-md hover:bg-tertiary-600 disabled:bg-tertiary-200 group w-full items-center flex justify-center ${btnStyle}`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className={`flex flex-row px-4 md:px-1 items-center ${bottonStyle}`}>
                    {leftIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    {label && (
                        <span
                            className={`${isClicked ? 'text-tertiary-200' : 'text-shades-50'} ${
                                fontsWeight ?? 'font-normal'
                            } paragraph-medium-medium ${nameMargin ?? 'mx-1'} ${hideLabel}`}>
                            {label}
                        </span>
                    )}

                    {rightIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}

                    {badgeText && (
                        <span className='px-2.5 py-0.5 rounded-3xl text-tertiary-500 bg-white group-hover:text-tertiary-600 mx-1 group-disabled:text-tertiary-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const LargeSecondaryButton = (props) => {
    const {
        disabled,
        leftIconDefault,
        leftIconClick,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-secondary-600' : 'bg-secondary-500'
                } h-12 rounded-md hover:bg-secondary-600 disabled:bg-secondary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-4 md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-secondary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-medium-medium mx-1`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                    {badgeText && (
                        <span className='px-2.5 py-0.5 rounded-3xl text-secondary-500 bg-white group-hover:text-secondary-600 mx-1 group-disabled:text-secondary-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const LargeDestructiveButton = (props) => {
    const {
        disabled,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        fontsSize,
        hideLabel,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
        buttonStyle,
    } = props;

    useEffect(() => {
        if (typeof props.isClicked === 'boolean') {
            setIsClicked(props.isClicked);
        }
    }, [props.isClicked]);

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-destructive-600' : 'bg-destructive-500'
                } h-12 rounded-md hover:bg-destructive-600 disabled:bg-destructive-200 group w-full items-center flex justify-center ${buttonStyle}`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-4 md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    {label && (
                        <span
                            className={`${isClicked ? 'text-tertiary-200' : 'text-shades-50'} ${
                                fontsWeight ?? 'font-normal'
                            } ${fontsSize ?? 'text-base'} paragraph-medium-medium mx-1 ${hideLabel}`}>
                            {label}
                        </span>
                    )}

                    {rightIconDefault && (
                        <>
                            <div className='mx-1'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}

                    {badgeText && (
                        <span className='px-2.5 py-0.5 rounded-3xl text-destructive-500 bg-white group-hover:text-destructive-600 mx-1 group-disabled:text-destructive-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const MediumPrimaryButton = (props) => {
    const {
        disabled,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
        hideLabel,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-primary-600' : 'bg-primary-500'
                } h-10 rounded-md hover:bg-primary-600 disabled:bg-primary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[13px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-primary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-small-medium mx-[3px] ${hideLabel}`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                    {badgeText && (
                        <span className='px-2 py-0.5 rounded-3xl text-primary-500 bg-white group-hover:text-primary-600 mx-[3px] group-disabled:text-primary-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const MediumSecondaryButton = (props) => {
    const {
        disabled,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-secondary-600' : 'bg-secondary-500'
                } h-10 rounded-md hover:bg-secondary-600 disabled:bg-secondary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[13px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-secondary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-small-medium mx-[3px]`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                    {badgeText && (
                        <span className='px-2 py-0.5 rounded-3xl text-secondary-500 bg-white group-hover:text-secondary-600 mx-[3px] group-disabled:text-secondary-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const MediumTertiaryButton = (props) => {
    const {
        disabled,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-tertiary-600' : 'bg-tertiary-500'
                } h-10 rounded-md hover:bg-tertiary-600 disabled:bg-tertiary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[13px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-tertiary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-small-medium mx-[3px]`}>
                        {label}
                    </span>

                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}

                    {badgeText && (
                        <span className='px-2 py-0.5 rounded-3xl text-tertiary-500 bg-white group-hover:text-tertiary-600 mx-[3px] group-disabled:text-tertiary-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const MediumDestructiveButton = (props) => {
    const {
        leftIconDefault,
        leftIconClick,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        badgeText,
        isDefault,
        disabled,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-destructive-600' : 'bg-destructive-500'
                } h-10 rounded-md hover:bg-destructive-600 disabled:bg-destructive-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[13px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-destructive-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-small-medium mx-[3px]`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                    {badgeText && (
                        <span className='px-2 py-0.5 rounded-3xl text-destructive-500 bg-white group-hover:text-destructive-600 mx-[3px] group-disabled:text-destructive-200'>
                            {badgeText}
                        </span>
                    )}
                </div>
            </button>
        </>
    );
};

export const SmallDestructiveButton = (props) => {
    const {
        leftIconDefault,
        leftIconClick,
        fontsWeight,
        label,
        rightIconDefault,
        rightIconClick,
        isDefault,
        disabled,
        buttonStyle,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-destructive-600' : 'bg-destructive-500'
                } h-8 rounded-md hover:bg-destructive-600 disabled:bg-destructive-200 group w-full items-center flex justify-center ${buttonStyle}`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[9px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    {label && (
                        <span
                            className={`${isClicked ? 'text-destructive-200' : 'text-shades-50'} ${
                                fontsWeight ?? 'font-normal'
                            } paragraph-x-small-medium mx-[3px]`}>
                            {label}
                        </span>
                    )}
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                </div>
            </button>
        </>
    );
};

export const SmallPrimaryButton = (props) => {
    const {
        disabled,
        leftIconDefault,
        leftIconClick,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        isDefault,
        buttonStyle,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-primary-600' : 'bg-primary-500'
                } h-8 rounded-md hover:bg-primary-600 disabled:bg-primary-200 group w-full items-center flex justify-center ${buttonStyle}`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[9px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    {label && (
                        <span
                            className={`${isClicked ? 'text-primary-200' : 'text-shades-50'} ${
                                fontsWeight ?? 'font-normal'
                            } paragraph-x-small-medium mx-[3px]`}>
                            {label}
                        </span>
                    )}

                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                </div>
            </button>
        </>
    );
};

export const SmallSecondaryButton = (props) => {
    const {
        disabled,
        leftIconClick,
        leftIconDefault,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        isDefault,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-secondary-600' : 'bg-secondary-500'
                } h-8 rounded-md hover:bg-secondary-600 disabled:bg-secondary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[9px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-secondary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-x-small-medium mx-[3px]`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                </div>
            </button>
        </>
    );
};

export const SmallTertiaryButton = (props) => {
    const {
        disabled,
        leftIconDefault,
        leftIconClick,
        fontsWeight,
        label,
        rightIconClick,
        rightIconDefault,
        isDefault,
    } = props;

    const [isClicked, setIsClicked] = useState(false);

    const handleClickBtn = () => {
        setIsClicked(isDefault ?? !isClicked);
    };

    return (
        <>
            <button
                className={`${
                    isClicked ? 'bg-tertiary-600' : 'bg-tertiary-500'
                } h-8 rounded-md hover:bg-tertiary-600 disabled:bg-tertiary-200 group w-full items-center flex justify-center`}
                onClick={handleClickBtn}
                disabled={disabled}>
                <div className='flex flex-row px-[9px] md:px-1 items-center'>
                    {leftIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? leftIconClick : leftIconDefault}</div>
                        </>
                    )}

                    <span
                        className={`${isClicked ? 'text-tertiary-200' : 'text-shades-50'} ${
                            fontsWeight ?? 'font-normal'
                        } paragraph-x-small-medium mx-[3px]`}>
                        {label}
                    </span>
                    {rightIconDefault && (
                        <>
                            <div className='mx-[3px]'>{isClicked ? rightIconClick : rightIconDefault}</div>
                        </>
                    )}
                </div>
            </button>
        </>
    );
};
