import React, { useState } from 'react';
import TotalSalesPopup from './Components/TotalSalesPopup';
import TotalRevenue from './Components/TotalRevenue';
import PlatformSales from './Components/PlatformSales';
import OverviewCards from './Components/OverviewCards';
import PlatformWiseSalesCards from './Components/PlatformWiseSalesCards';
import ServiceTypeSales from './Components/ServiceTypeSales';
import FavoriteFood from './Components/Tables/FavoriteFood';
import FavoriteCategory from './Components/Tables/FavoriteCategory';
import OutletWiseStatistics from './Components/Tables/OutletWiseStatistics';
import TopManager from './Components/Tables/TopManager';
import TopCustomer from './Components/Tables/TopCustomer';
import TotalShowingCards from './Components/TotalShowingCards';
import { IsMobileScreen } from '../../Constants/Constants';
import Layout from './Components/Layout';
import OrderingModeWiseDetailsPopup from './Popup/OrderingModeWiseDetailsPopup';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

export default function DashBoard() {
    const numberOfOutlet = 23;

    const discountAmount = '30.83%';

    const [showTotalSalesCard, setShowTotalSalesCard] = useState(false);

    const handleClickClose = () => {
        setShowTotalSalesCard(!showTotalSalesCard);
    };

    const settingsDashboardSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'dashboard-slide',
    };

    const isMobileScreen = IsMobileScreen();

    const [showPopupDetails, setShowPopupDetails] = useState({});

    const outletId = useSelector((state) => state.outlet.selectedOutlet)?.outletId;

    const isRestaurantSelected = outletId === 0;

    return (
        <>
            <Layout>
                <OverviewCards
                    numberOfOutlet={numberOfOutlet}
                    discountAmount={discountAmount}
                    settingsDashboardSlider={settingsDashboardSlider}
                    setShowPopupDetails={setShowPopupDetails}
                    isRestaurantSelected={isRestaurantSelected}
                />

                <div className='px-8 lg:px-4'>
                    {isRestaurantSelected && (
                        <div className='mt-2.5 md:mt-2'>
                            <OutletWiseStatistics />
                        </div>
                    )}

                    <div className='flex flex-row lg:block'>
                        <div className='mr-2.5 w-1/2 lg:w-full mt-6 lg:mt-4 md:mr-0'>
                            <FavoriteFood />
                        </div>

                        <div className='ml-2.5 w-1/2 lg:w-full mt-6 lg:ml-0 md:mt-4'>
                            <FavoriteCategory />
                        </div>
                    </div>

                    <div className='flex flex-row lg:block mt-6 md:mt-0'>
                        <div className='pr-2.5 w-1/2 lg:w-full md:mt-4 lg:pr-0'>
                            <TotalRevenue />
                        </div>
                        <div className='pl-2.5 w-1/2 lg:w-full lg:mt-6 md:mt-4 lg:pl-0'>
                            <PlatformSales />
                        </div>
                    </div>

                    {!isMobileScreen && (
                        <div className='flex flex-row lg:flex-none lg:block'>
                            <div className='mr-2.5 mt-6 lg:mr-0 w-1/2 lg:w-full'>
                                <TopCustomer />
                            </div>
                            <div className='ml-2.5 mt-6 lg:ml-0 w-1/2 lg:w-full'>
                                <TopManager />
                            </div>
                        </div>
                    )}
                </div>

                <PlatformWiseSalesCards
                    numberOfOutlet={numberOfOutlet}
                    discountAmount={discountAmount}
                    settingsDashboardSlider={settingsDashboardSlider}
                />

                <TotalShowingCards numberOfOutlet={numberOfOutlet} />

                <ServiceTypeSales
                    settingsDashboardSlider={settingsDashboardSlider}
                    numberOfOutlet={numberOfOutlet}
                />
            </Layout>

            {showTotalSalesCard && <TotalSalesPopup handleClickClose={handleClickClose} />}

            {!isEmpty(showPopupDetails) && (
                <OrderingModeWiseDetailsPopup
                    handleClickClose={() => setShowPopupDetails({})}
                    showPopupDetails={showPopupDetails}
                />
            )}
        </>
    );
}
