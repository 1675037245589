import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SmallPrimaryButton } from '../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import { fetchDishList } from '../functions';
import { useSelector } from 'react-redux';
import ListLoader from '../Components/ListLoader';
import HasMoreCard from '../Components/HasMoreCard';
import SubCategoryDishList from '../Components/SubCategoryDishList';
import { DraggableDishItem } from '../Components/DraggableComponents';
import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import ConfirmationPopup from '../Popup/ConfirmationPopup/ConfirmationPopup';
import { SortableItem } from '../Components/SortableItem';

export default function DishesSection(props) {
    const {
        setSelectedCategoryDish,
        selectedCategoryDish,
        pageState,
        setAllCategoryDish,
        allCategoryDish,
        isOperationLoading,
        setIsOperationLoading,
        selectedDishId,
        setSelectedDishId,
    } = props;

    const navigate = useNavigate();

    const handleClickAddDish = () => {
        navigate(`/menu/dish/${selectedCategoryDish.category.category_id}/basic-details`, {
            state: { selectedCategoryDish },
        });
    };

    const handleClickEditCategoryDish = (id) => {
        navigate(`/menu/${selectedCategoryDish.category.category_id}/edit-dish/${id}/basic-details`, {
            state: { selectedCategoryDish },
        });
    };

    const [isLoading, setIsLoading] = useState(true);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const screenSize = useWindowSize();

    const initialPageState = { page: 1, per_page: 10, has_more_pages: null };

    const [paginationState, setPaginationState] = useState(initialPageState);

    const [dishList, setDishList] = useState([]);

    const setDishData = (page, dishData) => {
        if (page === 1) {
            setDishList(dishData);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.category_id === selectedCategoryDish.category.category_id) {
                        return { ...el, dish: dishData };
                    }
                    return el;
                })
            );
        } else {
            setDishList((prevState) => [...prevState, ...dishData]);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.category_id === selectedCategoryDish.category.category_id) {
                        return { ...el, dish: [...el?.dish, ...dishData] };
                    }
                    return el;
                })
            );
        }

        page === 1 && setSelectedCategoryDish((prevState) => ({ ...prevState, dish: dishData?.[0]?.item_id }));
    };

    const checkDishExist = () => {
        const currentDish = allCategoryDish.find((el) => el.category_id === selectedCategoryDish.category.category_id);

        setDishList([]);

        const hasDish = !!currentDish?.dish?.length;

        hasDish && setDishList(currentDish.dish);

        if (selectedCategoryDish?.category?.total_items > currentDish?.dish?.length && hasDish) {
            return false;
        }

        return hasDish;
    };

    const fetchDish = async (params) => {
        const hasDish = checkDishExist(params);

        if (hasDish) return;

        setIsLoading(true);

        try {
            const dish = await fetchDishList(selectedRestaurantId, params, selectedCategoryDish.category.category_id);

            setIsLoading(false);

            setPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: dish?.has_more_pages,
                last_page: dish?.last_page,
            }));

            setDishData(params.page, dish?.data);

            setIsLoading(false);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        setPaginationState(initialPageState);

        !!selectedCategoryDish.category && fetchDish(initialPageState);

        // eslint-disable-next-line
    }, [selectedCategoryDish?.category?.category_id]);

    const containerRef = useRef(null);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchDish({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
        // eslint-disable-next-line
    }, [paginationState]);

    const [unlinkItem, setUnlinkItem] = useState(null);

    const handleSuccessUnlink = () => {
        setIsOperationLoading(true);
    };

    useEffect(() => {
        if (isOperationLoading) {
            fetchDish(initialPageState);
        }
        // eslint-disable-next-line
    }, [isOperationLoading]);

    const handleDragEnd = (event) => {
        // const { active, over } = event;
    };

    return (
        <>
            <div className='flex flex-row justify-between items-center w-full mb-4'>
                <span className='paragraph-large-medium text-[#000000]'>Dishes</span>
                <div
                    className='max-w-[131px] cursor-pointer'
                    onClick={handleClickAddDish}>
                    <SmallPrimaryButton
                        leftIconClick={
                            <AddIcon
                                stroke='#C4BEED'
                                height={20}
                                width={20}
                            />
                        }
                        leftIconDefault={
                            <AddIcon
                                stroke='#FFFFFF'
                                height={20}
                                width={20}
                            />
                        }
                        label='Add dish'
                    />
                </div>
            </div>

            {!!selectedCategoryDish?.subCategory?.length ? (
                <SubCategoryDishList
                    setSelectedCategoryDish={setSelectedCategoryDish}
                    selectedCategoryDish={selectedCategoryDish}
                    handleClickEditCategoryDish={handleClickEditCategoryDish}
                    pageState={pageState}
                    setAllCategoryDish={setAllCategoryDish}
                    allCategoryDish={allCategoryDish}
                    selectedDishId={selectedDishId}
                    setSelectedDishId={setSelectedDishId}
                />
            ) : (
                <>
                    <div
                        ref={containerRef}
                        onScroll={handleScroll}
                        className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-6 pl-6 pageContentSmall:-mr-4'
                        style={{ height: screenSize.height - 300 }}>
                        {isLoading && paginationState.page === 1 && <ListLoader className='min-w-[410px] h-[104px]' />}

                        {!isLoading && !dishList.length && (
                            <div
                                className='m-auto flex justify-center min-w-[411px] items-center text-xl font-bold '
                                style={{ height: screenSize.height - 300 }}>
                                No dishes are available
                            </div>
                        )}

                        <DndContext onDragEnd={handleDragEnd}>
                            <SortableContext
                                items={dishList.map((item) => item.item_id)}
                                disabled={!pageState.reArrange}>
                                {dishList.map((dish, index) => (
                                    <SortableItem
                                        key={dish.item_id}
                                        id={dish.item_id}>
                                        <DraggableDishItem
                                            index={index}
                                            handleClickEditCategoryDish={() =>
                                                handleClickEditCategoryDish(dish.item_id)
                                            }
                                            pageState={pageState}
                                            item={dish}
                                            setSelectedCategoryDish={setSelectedCategoryDish}
                                            selectedCategoryDish={selectedCategoryDish}
                                            key={index}
                                            handleUnlinkItem={() => setUnlinkItem(dish)}
                                            selectedDishId={selectedDishId}
                                            setSelectedDishId={setSelectedDishId}
                                        />
                                    </SortableItem>
                                ))}
                            </SortableContext>
                        </DndContext>

                        {isLoading && paginationState.page !== 1 && <ListLoader className='max-w-[411px] h-[104px]' />}
                    </div>
                    {paginationState?.has_more_pages && <HasMoreCard />}
                </>
            )}

            {!!unlinkItem && (
                <ConfirmationPopup
                    handleClickClose={() => setUnlinkItem(null)}
                    unlinkItem={unlinkItem}
                    handleSuccessUnlink={handleSuccessUnlink}
                />
            )}
        </>
    );
}
