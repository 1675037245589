import { useEffect } from 'react';

const useOutsideClick = (ref, handleClickClose) => {
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClickClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [ref, handleClickClose]);
};

export default useOutsideClick;
