import React, { useState } from 'react';
import MobileScreenAddCategoryDish from '../../../../Components/MobileViewScreen/MobileScreenAddCategoryDish';
import DetailsDropDown from '../../Components/Catalogue/Components/DetailsDropDown';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';

export default function AdvanceSetting() {
    const advanceSettingMenuItem = {
        mask: [
            { label: 'Mask', value: 1 },
            { label: 'Unmask', value: 0 },
        ],
        hideCategory: [
            { label: 'Hide', value: 1 },
            { label: 'Show', value: 0 },
        ],
        tileDetails: [
            { label: 'Hide', value: 1 },
            { label: 'Show', value: 0 },
        ],
        displayTheme: [
            { label: 'Square', value: 'square_image' },
            { label: 'Rectangle', value: 'full_size_image' },
        ],
        tilePosition: [
            { label: 'Top left', value: 'top-left' },
            { label: 'Bottom left', value: 'bottom-left' },
        ],
    };

    const { watch } = useFormContext();

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const languageMenuItem = availableLanguages?.map((el) => ({ label: el.language_label, value: el }));

    const [selectedLanguageForMobile, setSelectedLanguageForMobile] = useState(languageMenuItem[0]);

    const screenHeight = useWindowSize().height;

    return (
        <>
            <div className='flex flex-row xl:block xl:mb-0 ml-1'>
                <div
                    className='min-w-fit w-full pr-[30px] border-r border-neutral-300 xl:pr-0 xl:pb-4 xl:border-r-0 xl:border-b xl:mb-6 xl:min-w-full pb-6 md:pb-2 overflow-auto scrollbar-style -ml-1 pl-1'
                    style={{
                        height: screenHeight - 173,
                    }}>
                    <DropdownSection
                        name='advanceSetting.mask'
                        menuItems={advanceSettingMenuItem.mask}
                        title='Mask over category tile'
                        description='Enabling this will mask the images you upload to the categories for a better look and view.'
                        className='mb-6'
                    />

                    <DropdownSection
                        name='advanceSetting.hideCategory'
                        menuItems={advanceSettingMenuItem.hideCategory}
                        title='Hide category'
                        description='Hiding a category will not show this category in the menu however the entire category and the items in the category will remain active.'
                        className='mb-6'
                    />

                    <DropdownSection
                        name='advanceSetting.tileDetails'
                        menuItems={advanceSettingMenuItem.tileDetails}
                        title='Category tile details'
                        description='Show/Hide title and dish count details on the home screen over the category tile. Hiding this is recommended for text-heavy category images.'
                        className='mb-6'
                    />

                    <DropdownSection
                        title='Dish image display theme'
                        description='Choose between square or rectangular image display of dishes in category.'
                        name='advanceSetting.displayTheme'
                        menuItems={advanceSettingMenuItem.displayTheme}
                        className='mb-6'
                    />

                    <DropdownSection
                        title='Tile detail/mask position'
                        description='Choose position of details and mask over the category tile.'
                        name='advanceSetting.tilePosition'
                        menuItems={advanceSettingMenuItem.tilePosition}
                        className='mb-6'
                    />
                </div>

                <div className='w-full flex justify-center pb-10'>
                    <div className='flex flex-col gap-4 items-center h-full'>
                        <MobileScreenAddCategoryDish
                            categoryTitle={watch('title')?.[selectedLanguageForMobile?.value?.id]}
                            renderPageOnMobileScreen='home'
                            image={watch('imageDetails')?.image_url}
                            hasMask={!!watch('advanceSetting')?.mask?.value}
                            hideTile={!watch('advanceSetting')?.tileDetails?.value}
                            maskPosition={watch('advanceSetting')?.tilePosition?.value}
                        />

                        <DetailsDropDown
                            menuItem={languageMenuItem}
                            handleChangeDropDown={(selection) => setSelectedLanguageForMobile(selection)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
