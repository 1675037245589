import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../../../Components/InputSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function DelaySection() {
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            {watch('cashback_settings.sameEarningDelay') !== false ? (
                <>
                    <SingleDelaySection
                        watch={watch}
                        title='Cashback earning delay'
                        hourName='cashback_settings.same_earning_delay.hour'
                        minutName='cashback_settings.same_earning_delay.minute'
                        setValue={setValue}
                    />
                    {(errors?.cashback_settings?.same_earning_delay?.hour ||
                        errors?.cashback_settings?.same_earning_delay?.minute) && (
                        <ErrorMessage errors={{ message: 'Please fill this fields' }} />
                    )}
                </>
            ) : (
                watch('orderingModes')?.map((el, index) => {
                    return (
                        <React.Fragment key={index}>
                            <SingleDelaySection
                                title={el.display_name + ' cashback earning delay'}
                                hourName={`cashback_settings.earning_delay.${index}.hour`}
                                minutName={`cashback_settings.earning_delay.${index}.minute`}
                                watch={watch}
                                setValue={setValue}
                            />
                            {(errors?.cashback_settings?.earning_delay?.[index]?.hour ||
                                errors?.cashback_settings?.earning_delay?.[index]?.minute) && (
                                <ErrorMessage errors={{ message: 'Please fill this fields' }} />
                            )}
                        </React.Fragment>
                    );
                })
            )}
        </>
    );
}

const SingleDelaySection = (props) => {
    const { hourName, title, watch, minutName, setValue } = props;

    return (
        <>
            <h1
                className={`paragraph-large-medium w-full md:paragraph-medium-medium mt-4 mb-2 ${
                    !watch('cashback_settings.earningDelayEnable') && 'text-neutral-300'
                }`}>
                {title}
            </h1>

            <div className='flex flex-row lg:block max-w-[636px] md:max-w-full'>
                <div className='mr-1.5 w-1/2 lg:w-full lg:mr-0 lg:mb-2'>
                    <InputSection
                        inputValue={watch(hourName)}
                        className='mb-4'
                        name={hourName}
                        placeholder='Enter hours'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('cashback_settings.earningDelayEnable')}
                        inputType='number'
                        rules={{ required: 'Please fill this field' }}
                    />
                </div>

                <div className='ml-1.5 w-1/2 lg:w-full lg:ml-0'>
                    <InputSection
                        inputValue={watch(minutName)}
                        className='mb-4'
                        name={minutName}
                        placeholder='Enter minutes'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('cashback_settings.earningDelayEnable')}
                        inputType='number'
                        rules={{ required: 'Please fill this field' }}
                    />
                </div>
            </div>
        </>
    );
};
