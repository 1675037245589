import React from 'react';
import { SingleLoader } from '../../../Components/ContentLoader/DropdownLoader';

export default function ListLoader(props) {
    const { className } = props;
    return (
        <>
            {[...Array(6)].map((_, index) => (
                <SingleLoader
                    key={index}
                    className={`${className} mb-4`}
                />
            ))}
        </>
    );
}
