import React from 'react';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';

export default function ConfirnOrderPopup(props) {
    const { handleClickClose, setShowPopup } = props;

    return (
        <>
            <div className='flex fixed bg-black bg-opacity-50 inset-0 z-40 p-4'>
                <div className='w-full max-w-[343px] rounded-xl bg-shades-50 p-4 m-auto'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div>
                            <div className='paragraph-large-medium'>Select an option</div>
                            <div className='paragraph-medium-italic text-neutral-500'>
                                Select preferable option for order{' '}
                            </div>
                        </div>

                        <div
                            onClick={handleClickClose}
                            className='cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div>
                        <div
                            className='mt-3'
                            onClick={() => setShowPopup('addPayment')}>
                            <LargeTertiaryButton
                                label='Save'
                                bottonStyle='pageContent:px-1'
                            />
                        </div>

                        <div
                            className='mt-3'
                            onClick={() => setShowPopup('addPayment')}>
                            <LargeTertiaryButton
                                label='Save & print'
                                bottonStyle='pageContent:px-1'
                            />
                        </div>

                        <div
                            className='mt-3'
                            onClick={() => setShowPopup('addPayment')}>
                            <LargeTertiaryButton
                                nameMargin='mx-0.2'
                                label='Save & e-bill'
                                bottonStyle='pageContent:px-1'
                            />
                        </div>

                        <div
                            className='mt-3'
                            onClick={() => setShowPopup('fireDishes')}>
                            <LargePrimaryButton
                                label='KOT'
                                isDefault={false}
                            />
                        </div>

                        <div
                            className='mt-3'
                            onClick={() => setShowPopup('fireDishes')}>
                            <LargePrimaryButton label='KOT & print' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
