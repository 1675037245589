import React, { useRef } from 'react';
import ListView from './ListView';
import _ from 'lodash';
import { ListBlankComponentView } from '../../../../Components/BlankComponent/ListBlankComponentView';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { el } from 'date-fns/locale';

export default function ListViewSection(props) {
    const { orderDetails, handleClickCustomerName, setPopupOrderId, fetchOrderDetail, paginationState } = props;

    return (
        <div className='mt-[3px]'>
            {!_.isEmpty(orderDetails) ? (
                orderDetails.map((el, index) => (
                    <ListView
                        key={index}
                        customerName={el.customer_name}
                        cashbackEarned={el.cashback_earned}
                        cashbackReedemed={el.cashback_redeemed}
                        orderAmount={el.order_amount}
                        orderDate={el.order_date}
                        orderId={el.order_id}
                        handleClickCustomerName={handleClickCustomerName}
                        fetchOrderDetail={fetchOrderDetail}
                        paginationState={paginationState}
                        setPopupOrderId={setPopupOrderId}
                    />
                ))
            ) : (
                <ListBlankComponentView previewText={'No Content Available'} />
            )}
        </div>
    );
}
