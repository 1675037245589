import React from 'react';
import { SingleLoader } from '../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <div className='-mx-0.5'>
            {Array.from({ length: 10 }).map((_, index) => (
                <div
                    key={index}
                    className='inline-block mb-10 align-top mx-4 lg:mb-4 lg:w-[153px] lg:mx-1 lg:h-[268px] md:w-[168px] md:h-[276px] md:mx-2 md:my-1 mobile:w-full mobile:h-full'>
                    <SingleLoader className='max-w-[180px] h-[248px] mt-4' />
                </div>
            ))}
        </div>
    );
}
