import React from 'react';
import { currencyData } from '../../../../../Components/MobileNumberInputFlagDropDown/CountryData';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import DropdownSection from '../Components/DropdownSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function Currency(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const decodeEntities = (s) => {
        var str,
            temp = document.createElement('p');
        temp.innerHTML = s;
        str = temp.textContent || temp.innerText;
        temp = null;
        return str;
    };

    const formatData = (data) => {
        return {
            currency_symbol: data.currency.value.symbol,
            currency_code: data.currency.value.code,
        };
    };

    const onSubmit = (data) => {
        updateCurrency(formatData(data.currency));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateCurrency = async (params) => {
        setValue('isFormLoading', true);
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=currency`, { ...params });
            if (response.success) {
                reset();

                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const currencyMenuItems = currencyData.map((item) => {
        return {
            label: `${item.countryname}(${item.currencyCode}) - ${decodeEntities(item.symbol)}`,
            value: { code: item.currencyCode, symbol: decodeEntities(item.symbol) },
        };
    });

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='mb-4'>
                <DropdownSection
                    name='currency.currency'
                    label='Currency'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    placeholder='Select currency'
                    menuItems={currencyMenuItems}
                    rules={{ required: 'Please select a currency' }}
                    title='Currency'
                    description='Select your outlet currency according to your nationality.'
                    fixedHeight='h-[300px]'
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
            </div>
        </Layout>
    );
}
