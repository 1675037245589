import React, { useState } from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import DropdownSection from '../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function OtherSetting(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const { handleSubmit, watch, setValue, reset } = useFormContext();

    const formatData = (data) => {
        return {
            enable_auto_accept_order: data.hasAutoAcceptance.value,
        };
    };

    const onSubmit = (data) => {
        updateOtherSetting(formatData(data.other_settings));
        setShowFooter(false);
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateOtherSetting = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=other_settings`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setShowFooter(false);
                setShowTabChangeWarningPopup(false);
                handleDiscardForm();
            }}
            showFooter={showFooter}>
            <div className='mb-4 md:mb-28'>
                <DropdownSection
                    title='Auto acceptance'
                    description='Select if you would like to auto accept the incoming orders.'
                    menuItems={preferenceMenuItem}
                    rules={{ required: 'Please select a preference' }}
                    name='other_settings.hasAutoAcceptance'
                    shadow='shadow-smallDropDownShadow'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    label='(Auto accept)'
                    placeholder='Please select a preference'
                    handleSelection={() => {
                        setShowFooter(true);
                        setShowTabChangeWarningPopup(true);
                    }}
                />
            </div>
        </Layout>
    );
}
