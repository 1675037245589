import React, { useEffect } from 'react';
import LargeTitleSwitch from '../../../../../../../Components/ToggleSwitch/LargeTitleSwitch';
import { useFormContext } from 'react-hook-form';
import DropdownSection from '../../../Components/DropdownSection';
import PreferenceTable from './Components/PreferenceTable';
import ManualIntervention from './Components/ManualIntervention';
import { preferenceMenuItem } from '../../../../../../../Constants/Constants';
import { ErrorMessage } from '../../../../../../../Components/Error/ErrorMessage';

export default function AutoAssignSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        control,
        watch,
        clearErrors,
        setValue,
        formState: { errors },
    } = useFormContext();

    const riderPreferenceMenuItem = [
        { label: 'Priority based', value: 'priority' },
        { label: 'Preference based', value: 'preference' },
    ];

    const handleToggle = () => {
        clearErrors(['hasManualIntervention', 'ridersPreference', 'manualInterventionMinuts']);
        setShowTabChangeWarningPopup(true);
    };

    useEffect(() => {
        if (
            !!watch('auto_assignment_rider_settings.enable_rider_auto_assignment_by_preference') ||
            !!watch('auto_assignment_rider_settings.enable_rider_auto_assignment_by_priority')
        ) {
            setValue('rider_setting.rider_auto_assignment.autoAssignRider', true);
            setValue('defaultValue.rider_setting.rider_auto_assignment.autoAssignRider', true);
        }

        if (!!watch('auto_assignment_rider_settings.enable_rider_auto_assignment_by_priority')) {
            setValue('rider_setting.rider_auto_assignment.ridersPreference', riderPreferenceMenuItem[0]);
            setValue('defaultValue.rider_setting.rider_auto_assignment.ridersPreference', riderPreferenceMenuItem[0]);
        }

        if (!!watch('auto_assignment_rider_settings.enable_rider_auto_assignment_by_preference')) {
            setValue('rider_setting.rider_auto_assignment.ridersPreference', riderPreferenceMenuItem[1]);
            setValue('defaultValue.rider_setting.rider_auto_assignment.ridersPreference', riderPreferenceMenuItem[1]);
        }

        if (
            !!watch('auto_assignment_rider_settings.intervention_time.seconds') ||
            !!watch('auto_assignment_rider_settings.intervention_time.minutes')
        ) {
            setValue('rider_setting.rider_auto_assignment.hasManualIntervention', preferenceMenuItem[0]);
            setValue('defaultValue.rider_setting.rider_auto_assignment.hasManualIntervention', preferenceMenuItem[0]);
        } else {
            setValue('rider_setting.rider_auto_assignment.hasManualIntervention', preferenceMenuItem[1]);
            setValue('defaultValue.rider_setting.rider_auto_assignment.hasManualIntervention', preferenceMenuItem[1]);
        }

        // eslint-disable-next-line
    }, [watch('auto_assignment_rider_settings')]);

    return (
        <>
            <div className='mb-6'>
                <LargeTitleSwitch
                    control={control}
                    title='Auto-assign riders'
                    description='When turned ON, the system will automatically assign a rider for an order based on the number of factors such as available delivery channels, assignment selection, intervention time etc.'
                    switchName='rider_setting.rider_auto_assignment.autoAssignRider'
                    classNames='max-w-[636px] md:max-w-full lg:max-w-[459px]'
                    handleToggle={handleToggle}
                />
            </div>

            <div className='mb-4'>
                <DropdownSection
                    title='Auto-assign riders preference'
                    description='Set either priority or preference for rider assignment. Priority will assign an order to all agencies, the one that accepts the order first will be assigned the order. In contrast, preference selection will give orders in sequence to the preference list.'
                    label='(Select preference)'
                    shadow='shadow-smallDropDownShadow'
                    menuItems={riderPreferenceMenuItem}
                    name='rider_setting.rider_auto_assignment.ridersPreference'
                    placeholder='Please select preference'
                    disabled={!watch('rider_setting.rider_auto_assignment.autoAssignRider')}
                    labelStyle={`paragraph-medium-italic mb-2 ${
                        !watch('rider_setting.rider_auto_assignment.autoAssignRider')
                            ? 'text-neutral-300'
                            : 'text-neutral-500'
                    }`}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('rider_setting.rider_auto_assignment.autoAssignRider'))
                                return 'Please select preference';
                        },
                    }}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.rider_setting?.rider_auto_assignment?.ridersPreference && (
                    <ErrorMessage errors={errors?.rider_setting?.rider_auto_assignment?.ridersPreference} />
                )}
            </div>

            {watch('rider_setting.rider_auto_assignment.ridersPreference')?.value ===
                riderPreferenceMenuItem[1].value && (
                <PreferenceTable setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />
            )}

            <ManualIntervention
                disabled={!watch('rider_setting.rider_auto_assignment.autoAssignRider')}
                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
            />
        </>
    );
}
