import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import AllDaySameTimeSelection from '../../../../../../Components/Timings/Components/AllDaySameTimeSelection';
import EachDaySeparateTimeSelection from '../../../../../../Components/Timings/Components/EachDaySeparateTimeSelection';
import { useFormContext } from 'react-hook-form';
import DropdownSection from '../../../../Components/DropdownSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function ModeTimingSection(props) {
    const { title, id, menuItemTiming, time_slot_setting, setShowTabChangeWarningPopup } = props;

    const {
        control,
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const menuItemmTiming = [
        { label: 'Full time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const handleTimeSettingSelection = (selection) => {
        clearErrors('chooseOneTimeSlot');
        setShowTabChangeWarningPopup(true);

        switch (selection.value) {
            case menuItemmTiming[1].value:
                setValue(`${id}`, [{ start_time: '', end_time: '' }]);
                break;

            case menuItemmTiming[2].value:
                for (let index = 0; index < 7; index++) {
                    setValue(`${id}.timing.${index}`, []);
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className='mb-1'>
                <TitleDescription
                    description='This is the time when the customers will be able to order from any mode.'
                    title={title}
                />
            </div>

            <span className='paragraph-medium-italic text-primary-500'>Each day can have only 6 time slots.</span>

            <div className='max-w-[312px] w-full relative mb-4 md:max-w-full mt-4'>
                <DropdownSection
                    name={`${id}.timingType`}
                    menuItems={menuItemmTiming}
                    shadow='shadow-smallDropDownShadow'
                    height='h-[52px]'
                    placeholder='Please select timing type'
                    handleSelection={(selection) => handleTimeSettingSelection(selection)}
                    rules={{ required: 'Please select timing type' }}
                    dontShowError
                />

                {errors?.[id]?.timingType && <ErrorMessage errors={errors[id].timingType} />}
            </div>

            <div className='max-w-[450px] md:max-w-full'>
                {watch(`${id}_timingType`)?.value === menuItemmTiming[1].value && (
                    <AllDaySameTimeSelection
                        fieldName={`${id}`}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        timingTypeName={`${id}.timingType`}
                        subField={id}
                        limitedSlot={6}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                )}
            </div>

            <div className='max-w-[450px]'>
                {watch(`${id}_timingType`)?.value === menuItemmTiming[2].value && (
                    <EachDaySeparateTimeSelection
                        fieldName={`${id}`}
                        setValue={setValue}
                        control={control}
                        watch={watch}
                        clearErrors={clearErrors}
                        errors={errors}
                        timingTypeName={`${id}.timingType`}
                        subField={id}
                        limitedSlot={6}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                )}
            </div>
        </>
    );
}
