import React from 'react';
import { formatCurrency } from '../../../Helper/functions';

export default function Cards(props) {
    const { amountDetails } = props;

    const billPaymentCard = [
        { title: 'Total bill', amount: formatCurrency(amountDetails.totalBillAmount), titleColor: 'primary-500' },
        { title: 'Customer paid', amount: formatCurrency(amountDetails.customerPaid), titleColor: 'tertiary-800' },
        {
            title: 'Total remaining',
            amount: formatCurrency(amountDetails.totalRemaining),
            titleColor: 'destructive-500',
        },
        {
            title: 'Return customer',
            amount: formatCurrency(amountDetails.returnCustomer),
            titleColor: 'secondary-800',
        },
    ];

    const billCard = (title, amount, titleColor) => {
        return (
            <div className='border border-neutral-300 rounded-lg px-4 py-[11px] flex flex-col'>
                <span className={`paragraph-small-regular text-${titleColor} pb-1`}>{title}</span>
                <span className='paragraph-medium-semi-bold'>{amount}</span>
            </div>
        );
    };

    return (
        <div className=' w-full pb-4 border-b border-neutral-300'>
            <div className='flex flex-row justify-between md:block md:-mx-1 mobile:mx-0'>
                {billPaymentCard.map((el, index) => (
                    <div
                        className={`${
                            index !== 0 && 'ml-4 md:ml-0 md:mt-2'
                        } w-1/4 md:w-1/2 md:inline-block md:px-1 mobile:w-full mobile:px-0`}
                        key={index}>
                        {billCard(el.title, el.amount, el.titleColor, el.isNegative)}
                    </div>
                ))}
            </div>
        </div>
    );
}
