import React from 'react';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../Assets/chevron-down.svg';

export default function Layout(props) {
    const { children, handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div className='max-w-[830px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-0 md:py-4 m-auto md:max-w-full md:min-h-[60vh]'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to live order</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-6'>
                            <div className='flex flex-col'>
                                <span className='paragraph-large-medium'>Add payment</span>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Select and complete payment
                                </span>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    {children}
                </div>
            </div>
        </>
    );
}
