import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as TimerIcon } from '../../../../Assets/timer.svg';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import DropDownFixedIcon from '../../../Order/Components/DropDownFixedIcon';
import address from '../../../../Assets/address.png';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function DeliveryPopup(props) {
    const { handleClickClose, backToPage } = props;

    const [showSelectionSection, setShowSelectionSection] = useState(false);

    const handleClickDeliveryAddress = () => {
        setShowSelectionSection(!showSelectionSection);
    };

    const navigate = useNavigate();

    const handleClickProceed = () => {
        handleClickClose();
        navigate('/pos?mode=delivery');
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4 md:py-0'>
                <div className='max-w-[830px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-0 md:py-4 m-auto md:max-w-full'>
                    {!isMobileScreen ? (
                        <div className='flex flex-row justify-between items-center mb-6'>
                            <div className='flex flex-col'>
                                <span className='paragraph-large-medium'>Delivery #BBQA</span>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Enter basic details for order delivery
                                </span>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    ) : (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to {backToPage}</span>
                        </div>
                    )}

                    <div className='flex flex-row items-center mb-4 md:block md:w-full'>
                        <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                            <DefaultInputField
                                label='Customer name'
                                placeholder='Enter customer name'
                                shadow='shadow-xSmall'
                                inputStyle='sm:px-3 mobile:px-2'
                            />
                        </div>

                        <div className='w-1/2 ml-2 md:w-full md:ml-0'>
                            <DefaultInputField
                                label='Mobile number'
                                placeholder='Enter mobile number'
                                shadow='shadow-xSmall'
                                inputStyle='sm:px-3 mobile:px-2'
                            />
                        </div>
                    </div>

                    <div className='flex flex-row items-center mb-4 md:block'>
                        <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                            <DropDownFixedIcon
                                icon={<CalenderIcon height={24} />}
                                menuItems={[]}
                                selectedMenuItem='Select date'
                                selectedMenuItemStyle='text-neutral-300'
                                label='Select date'
                            />
                        </div>

                        <div className='w-1/2 ml-2 md:w-full md:ml-0'>
                            <DropDownFixedIcon
                                icon={<TimerIcon height={24} />}
                                menuItems={[]}
                                selectedMenuItem='Select time'
                                selectedMenuItemStyle='text-neutral-300'
                                label='Select time'
                            />
                        </div>
                    </div>

                    <div className='flex flex-row items-center mb-4 md:block'>
                        <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                            <DefaultInputField
                                shadow='shadow-xSmall'
                                label='House name'
                                placeholder='Enter house name'
                                inputStyle='sm:px-3 mobile:px-2'
                            />
                        </div>

                        <div className='w-1/2 ml-2 md:w-full md:ml-0'>
                            <DefaultInputField
                                shadow='shadow-xSmall'
                                label='Landmark'
                                placeholder='Enter landmark'
                                inputStyle='sm:px-3 mobile:px-2'
                            />
                        </div>
                    </div>

                    {!showSelectionSection && (
                        <div
                            className='mb-4 cursor-pointer'
                            onClick={handleClickDeliveryAddress}>
                            <DefaultInputField
                                shadow='shadow-xSmall'
                                label='Delivery address'
                                placeholder='Enter locality'
                            />
                        </div>
                    )}

                    {showSelectionSection && (
                        <>
                            <div className='mb-1'>
                                <DefaultInputField
                                    shadow='shadow-xSmall'
                                    label='Select section'
                                    placeholder='Select section'
                                />
                            </div>

                            <div className='mb-4'>
                                <DefaultInputField
                                    shadow='shadow-xSmall'
                                    placeholder='Select address'
                                />
                            </div>
                        </>
                    )}

                    <div className='mb-12 md:pb-20'>
                        <img
                            src={address}
                            alt=''
                            className='border border-neutral-300 rounded-lg shadow-smallDropDownShadow'
                        />
                    </div>

                    <div
                        className='cursor-pointer md:justify-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'
                        onClick={handleClickProceed}>
                        <LargePrimaryButton label='Proceed' />
                    </div>
                </div>
            </div>
        </>
    );
}
