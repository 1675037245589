import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SingleItemSection from './SingleItemSection.js';

export default function SelectDishesSection() {
    const { control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'selected_item',
    });

    const emptySlot = { item: '' };

    const handleClickAppend = () => {
        append(emptySlot);
    };

    return (
        <div className=''>
            <SingleItemSection
                fields={fields}
                handleClickAppend={handleClickAppend}
                remove={remove}
            />
        </div>
    );
}
