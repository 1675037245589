import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../../Assets/chevron-down.svg';

export default function DropDownFixedIcon(props) {
    const {
        placeholder,
        selectedMenuItem,
        selectedItem,
        label,
        boxPadding,
        icon,
        selectedMenuItemStyle,
        disabled,
        menuItemsContainerWidth,
        menuItems,
    } = props;

    const [item, setItem] = useState(placeholder ?? selectedMenuItem ?? menuItems[0]);

    const handleClickMenuItem = (value) => {
        setItem(value);
        selectedItem && selectedItem(value);
    };

    return (
        <>
            {label && <div className='paragraph-small-medium mb-1'>{label}</div>}
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            disabled={disabled}
                            className={`shadow-xSmall w-full flex flex-row justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none ${
                                boxPadding ?? 'px-4'
                            } border-neutral-300 h-12 paragraph-small-regular`}>
                            <div className='flex flex-row items-center'>
                                {icon}
                                <span
                                    className={`ml-2 max-w-[97px] truncate ${disabled && 'text-neutral-300'} ${
                                        placeholder && 'text-neutral-300'
                                    } ${selectedMenuItemStyle}`}>
                                    {item}
                                </span>
                            </div>
                            <DownArrowIcon
                                className='drop-down-icon-rotate'
                                fill={disabled ? '#D3D2D8' : '#131126'}
                                height={24}
                                width={24}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 ${menuItemsContainerWidth} right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50`}>
                            {menuItems?.map((el, index) => (
                                <div
                                    className='pb-2 mt-2 cursor-pointer'
                                    onClick={() => handleClickMenuItem(el)}
                                    key={index}>
                                    <Menu.Item>
                                        <span className=''>{el.label}</span>
                                    </Menu.Item>
                                </div>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
