import React from 'react';
import ListViewOfferLink from '../../../../MenuManagement/Components/CataloguePreset/Components/ListViewOfferLink';

export default function ListSection(props) {
    const { outletList, getAddress, handleChangeLinking, setLinkUnlinkOutletList, linkUnlinkOutletList } = props;

    return (
        <>
            {outletList.map((el, index) => {
                const { state, city, address, displayName } = getAddress(el);

                return (
                    <div
                        className='mt-2'
                        key={index}>
                        <ListViewOfferLink
                            displayName={displayName}
                            state={state}
                            city={city}
                            address={address}
                            id={el.outletId}
                            linkUnlinkOutletList={linkUnlinkOutletList}
                            handleChangeLinking={handleChangeLinking}
                        />
                    </div>
                );
            })}
        </>
    );
}
