import React from 'react';
import { HomePage, MobileComponents } from './Components/HomePage';
import { CategoryPage } from './Components/CategoryPage';

export default function MobileScreenAddCategoryDish(props) {
    const {
        categoryTitle,
        dishTitle,
        dishDetails,
        renderPageOnMobileScreen,
        categoryDetails,
        stickyTop,
        image,
        hasMask,
        hideTile,
        maskPosition,
        dishImg,
        isNonVeg,
        dontShowAgmark,
    } = props;

    return (
        <>
            <div
                className={`mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[661px] w-[319px] shadow-xl sticky ${
                    stickyTop ?? 'top-0'
                } mobile:max-w-[250px] mobile:w-full`}>
                <MobileComponents />

                <div className='rounded-[2rem] overflow-hidden w-[291px] h-[633px] bg-white px-4 mobile:w-[222px] mobile:px-2'>
                    {renderPageOnMobileScreen === 'category' ? (
                        <CategoryPage
                            isNonVeg={isNonVeg}
                            dishImg={dishImg}
                            categoryDetails={categoryDetails}
                            categoryTitle={categoryTitle}
                            dishTitle={dishTitle}
                            dishDetails={dishDetails}
                            dontShowAgmark={dontShowAgmark}
                        />
                    ) : (
                        <HomePage
                            categoryTitle={categoryTitle}
                            image={image}
                            hasMask={hasMask}
                            hideTile={hideTile}
                            maskPosition={maskPosition}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
