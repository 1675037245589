import React, { useEffect, useState } from 'react';
import { ReactComponent as VegIcon } from '../../../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../../../Assets/non-veg.svg';
import { ReactComponent as LeftArrow } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';
import { ReactComponent as LinearMenuIcon } from '../../../../../Assets/linear-menu.svg';
import { ReactComponent as LinkIcon } from '../../../../../Assets/link.svg';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';

export const Categories = (props) => {
    const {
        isActive,
        img,
        title,
        type,
        totalItems,
        isFromSelection,
        handleClickCategory,
        isEdit,
        handleClickEdit,
        isRearrange,
        subCategoryNumber,
        showSubCategory,
        setShowSubCategory,
        minWidth,
        onChange,
        isChecked,
        isIndeterminateSelected,
    } = props;

    const handleClickCard = () => {
        handleClickCategory?.();
    };

    const imageStyle = `h-[78px] w-[78px] md:h-12 md:w-12 mt-2.5 mb-[14px] mr-1 rounded-md md:mt-0 md:mb-0 mobile:hidden`;

    const boxStyle = `items-start pl-[11px] pr-4 ${isActive ? 'border-primary-500 bg-primary-50' : 'border-neutral-300'} ${minWidth ?? 'min-w-[303px] md:min-w-[343px]'} mobile:min-w-max flex flex-row cursor-pointer w-full rounded-md border md:py-3`;

    const [foodTypeIcons, setFoodTypeIcons] = useState();

    useEffect(() => {
        switch (type) {
            case 'veg':
                setFoodTypeIcons(<VegIcon className='h-[14px] w-[14px]' />);
                break;

            case 'nonVeg':
                setFoodTypeIcons(<NonVegIcon className='h-[14px] w-[14px]' />);
                break;

            default:
                setFoodTypeIcons(
                    <div className='flex flex-row'>
                        <VegIcon className='mx-1 h-[14px] w-[14px]' />
                        <NonVegIcon className='h-[14px] w-[14px]' />
                    </div>
                );
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='flex flex-row items-center relative'>
                {isEdit && (
                    <div
                        className='absolute top-10 -left-6 mr-1 cursor-pointer'
                        onClick={handleClickEdit}>
                        <EditIcon />
                    </div>
                )}

                {isRearrange && (
                    <div className='absolute top-10 -left-6'>
                        <LinearMenuIcon />
                    </div>
                )}

                {isFromSelection && (
                    <div className='mr-1 absolute top-10 -left-7'>
                        <CheckBoxWithoutLabels
                            onChange={(isSelected) => onChange?.(isSelected)}
                            isChecked={isChecked}
                            isIndeterminate={isIndeterminateSelected}
                        />
                    </div>
                )}

                <div
                    className={boxStyle}
                    onClick={handleClickCard}>
                    {img && (
                        <img
                            src={img}
                            alt=''
                            className={imageStyle}
                        />
                    )}

                    <div className={`w-full mt-[13px] md:mt-0 ${isFromSelection ? 'ml-1' : 'ml-2'}`}>
                        <div className='flex flex-row justify-between items-start heading-h6-medium-desktop md:paragraph-medium-medium mb-1'>
                            <div className='max-w-full h-[2.5em]'>
                                <div className='line-clamp-2'>{title}</div>
                            </div>

                            <div className='ml-2 mt-1.5'>{foodTypeIcons}</div>
                        </div>

                        <div
                            className={`${
                                isFromSelection ? 'paragraph-x-small-italic' : 'paragraph-small-italic'
                            } text-neutral-500`}>
                            <span>{totalItems} dishes</span>
                        </div>

                        {!!subCategoryNumber && (
                            <div className='flex flex-row justify-between mt-1.5 items-center'>
                                <span
                                    className={`${
                                        isFromSelection ? 'paragraph-x-small-italic' : 'paragraph-small-italic'
                                    } text-neutral-500`}>
                                    Sub-categories: {subCategoryNumber}
                                </span>
                                <LeftArrow
                                    className={`${showSubCategory && 'rotate-180'} cursor-pointer`}
                                    onClick={() => {
                                        setShowSubCategory?.((prevState) => !prevState);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export const Dishes = (props) => {
    const {
        handleClickDish,
        isEdit,
        handleClickEdit,
        isActive,
        image,
        title,
        variants,
        type,
        isRearrange,
        isSelectionActive,
        onSelectDish,
        isChecked,
        isIndeterminateSelected,
        showUnlink,
        handleUnlinkItem,
    } = props;

    const boxStyle = `${isSelectionActive ? 'px-3' : 'pl-[11px] pr-4'} py-3 h-[104px] md:h-full md:max-h-[96px] ${
        isActive ? 'border-primary-500 bg-primary-50' : 'border-neutral-300'
    } lg:px-3 border rounded-md flex flex-row w-full cursor-pointer`;

    const imageStyle = `h-[78px] w-[78px] ${isSelectionActive ? 'mr-2' : 'mr-4'} lg:mr-2 rounded`;

    const [foodTypeIcons, setFoodTypeIcons] = useState();

    useEffect(() => {
        switch (type) {
            case 'veg':
                setFoodTypeIcons(<VegIcon className='h-[14px] w-[14px]' />);
                break;

            case 'nonVeg':
                setFoodTypeIcons(<NonVegIcon className='h-[14px] w-[14px]' />);
                break;

            default:
                setFoodTypeIcons(
                    <div className='flex flex-row'>
                        <VegIcon className='mx-1 h-[14px] w-[14px]' />
                        <NonVegIcon className='h-[14px] w-[14px]' />
                    </div>
                );
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className='flex flex-row items-center relative'>
                {isEdit && (
                    <div
                        className='absolute top-10 -left-6 cursor-pointer'
                        onClick={handleClickEdit}>
                        <EditIcon />
                    </div>
                )}

                {isRearrange && (
                    <div className='absolute top-10 -left-6'>
                        <LinearMenuIcon />
                    </div>
                )}

                {isSelectionActive && (
                    <div className='mr-1'>
                        <CheckBoxWithoutLabels
                            onChange={(isSelected) => onSelectDish?.(isSelected)}
                            isChecked={isChecked}
                            isIndeterminate={isIndeterminateSelected}
                        />
                    </div>
                )}

                <div
                    className={boxStyle}
                    onClick={() => handleClickDish?.()}>
                    {image && (
                        <img
                            src={image}
                            alt=''
                            className={imageStyle}
                        />
                    )}

                    <div className='w-full'>
                        <div className='flex flex-row items-start justify-between mb-1'>
                            <div className='max-w-full h-[2.5em] heading-h6-medium-desktop md:paragraph-medium-medium pr-4'>
                                <div className='line-clamp-2'>{title}</div>
                            </div>

                            <span className='mt-0.5'>{foodTypeIcons}</span>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <div className=''>
                                <span
                                    className={`${
                                        isSelectionActive ? 'paragraph-x-small-italic' : 'paragraph-small-italic'
                                    } text-neutral-500`}>
                                    Variants:
                                </span>
                                <span
                                    className={`${
                                        isSelectionActive ? 'paragraph-x-small-italic' : 'paragraph-small-italic'
                                    } text-neutral-500 ml-1`}>
                                    {!!variants ? `Yes(${variants})` : `No`}
                                </span>
                            </div>

                            {showUnlink && (
                                <div
                                    className='flex flex-row hover:underline decoration-primary-500 underline-offset-1'
                                    onClick={handleUnlinkItem}>
                                    <LinkIcon
                                        stroke='#6C5DD3'
                                        width={16}
                                        height={20}
                                    />

                                    <span className='paragraph-small-regular text-primary-500 ml-1'>Unlink</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
