import APIV5 from '../../../api/axios/APIV5';
import { handleErrorResponse } from './LoaderFunctions';
const timeout = 1000;

export const getOtp = async ({
    mobileNumber,
    countryCode,
    setError,
    clearErrors,
    setloaderStatus,
    setIsShowInputView,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/send-otp`, {
            mobile_number: mobileNumber,
            country_code: countryCode,
        });
        if (response.success) {
            setTimeout(() => {
                setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
                setIsShowInputView(true);
            }, timeout);
            clearErrors('mobile_number');
        } else {
            handleErrorResponse(setloaderStatus);
        }
        return response;
    } catch (error) {
        setError('mobile_number', {
            message: error?.response?.data?.errors?.country_code ?? error.message,
        });
        handleErrorResponse(setloaderStatus);
    }
};

export const verifyOtp = async ({
    mobileNumber,
    countryCode,
    otp,
    setError,
    setloaderStatus,
    setIsShowMobileNumberPopup,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/verify-otp`, {
            mobile_number: mobileNumber,
            country_code: countryCode,
            otp: otp,
        });
        if (response?.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                setIsShowMobileNumberPopup(false);
                setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
            }, timeout);
            return response;
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        setError('mobile_number', {
            message: error?.response?.data?.message,
        });
        handleErrorResponse(setloaderStatus);
    }
};
