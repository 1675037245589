import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Badges(props) {
    const { dishDetails } = props;

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const [showDetails, setShowDetails] = useState(false);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <>
            <div
                className={`pt-4 px-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex pb-4'>
                    <span className='paragraph-medium-medium'>Badges</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className='flex flex-row gap-1 mb-4'>
                        <div className='flex flex-wrap gap-1'>
                            {dishDetails.badges.length ? (
                                dishDetails.badges.map((el, index) => (
                                    <div
                                        className='px-2 py-1 text-white rounded w-fit paragraph-small-medium flex justify-center items-center'
                                        style={{ backgroundColor: el.color }}
                                        key={index}>
                                        {el.translations.display_name[selectedLanguage]}
                                    </div>
                                ))
                            ) : (
                                <span className='paragraph-small-regular'>No badge available</span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
