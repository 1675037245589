import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function Layout(props) {
    const { children, handleClickClose, applyFilter, handleClickSave, isSaveDisabled } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div className='max-w-[997px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-0 md:pt-0 md:pb-20 m-auto md:max-w-full'>
                    <Header
                        handleClickClose={handleClickClose}
                        applyFilter={applyFilter}
                    />

                    {children}

                    <Footer
                        handleClickSave={handleClickSave}
                        isSaveDisabled={isSaveDisabled}
                    />
                </div>
            </div>
        </>
    );
}
