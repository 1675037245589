import React from 'react';
import InputSection from '../../../../Components/InputField/InputSection';

export default function TitleSection() {
    return (
        <InputSection
            className='my-[13px]'
            name='bannerTitle'
            boxHeight='11'
            label='Banner title'
            placeholder='Enter banner title'
            maxWidth='max-w-full'
            rules={{ required: 'Please enter banner title' }}
        />
    );
}
