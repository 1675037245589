import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function Layout(props) {
    const { handleClickClose, children } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative px-8 py-4 md:py-0 md:px-0'>
                <div className='max-w-[1120px] lg:max-w-[730px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:px-4 m-auto md:max-w-full h-fit relative md:py-4 md:h-full md:max-h-full'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to banner popup</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-6'>
                            <div className=''>
                                <div className='paragraph-large-medium'>Select new image</div>
                                <div className='paragraph-medium-italic text-neutral-500'>
                                    Select any image to set as banner image
                                </div>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </>
    );
}
