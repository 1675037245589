import React from 'react';
import { DropdownBoxLoader, SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <div className='flex flex-row w-full max-w-[636px] items-center justify-between'>
                    <DropdownBoxLoader
                        className='max-w-[300px] md:max-w-full'
                        height={52}
                        boxHeight='h-[52px]'
                    />
                    <SingleLoader className='max-w-[50px] h-[30px]' />
                </div>

                <SingleLoader className='max-w-[280px] h-[50px] mt-10' />

                <SingleLoader className='max-w-[360px] h-[50px] mt-10' />
            </div>
        </>
    );
}
