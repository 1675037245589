import { ReactComponent as HomeTabIcon } from '../../../Assets/home-tab.svg';
import { ReactComponent as OfferTabIcon } from '../../../Assets/offer-tab.svg';
import { ReactComponent as LoyaltyTabIcon } from '../../../Assets/loyalty-tab.svg';
import { ReactComponent as CartTabIcon } from '../../../Assets/cart-tab.svg';
import { ReactComponent as AccountIcon } from '../../../Assets/account.svg';
import { ReactComponent as StoreIcon } from '../../../Assets/store.svg';
import { ReactComponent as ArrowdownIcon } from '../../../Assets/arrow-fill.svg';
import Categorylayout from './Categorylayout';

export const FooterTab = (props) => {
    const { selectedColors } = props;

    return (
        <>
            <div className='absolute w-full bottom-0 z-10 -mx-4 max-w-2xl px-lg font-bold items-center justify-between bg-white flex flex-row pb-3 rounded-b-[2rem] px-4 bg-opacity-90 shadow-dropShadow mobile:-mx-2'>
                <div className='flex flex-col items-center'>
                    <div>
                        <HomeTabIcon fill={selectedColors?.primaryColor ?? '#142348'} />
                    </div>

                    <p className='text-[10px] font-metropolis_bold pt-0.5 uppercase leading-none text-black'>HOME</p>
                </div>

                <div className='flex flex-col items-center'>
                    <div>
                        <OfferTabIcon />
                    </div>

                    <p className='text-[10px] font-metropolis_bold pt-0.5 uppercase leading-none'>OFFERS</p>
                </div>

                <div className='flex flex-col items-center'>
                    <div>
                        <LoyaltyTabIcon />
                    </div>
                    <p className='text-[10px] font-metropolis_bold pt-0.5 uppercase leading-none'>LOYALTY</p>
                </div>

                <div className='flex flex-col items-center'>
                    <div>
                        <CartTabIcon />
                    </div>
                    <p className='text-[10px] font-metropolis_bold pt-0.5 uppercase leading-none'>CART</p>
                </div>
            </div>
        </>
    );
};

export const SingleImage = (props) => {
    const { categoryDisplayCount, categoryTitle, image, hasMask, hideTile, maskPosition } = props;

    return (
        <>
            <div
                className={`relative ${categoryDisplayCount === 1 ? 'w-full' : categoryDisplayCount === 2 ? `w-[128px] h-[128px]` : 'w-[84px] h-[84px]'}`}>
                <div
                    className={`${hasMask && 'gradient-overlay'} relative ${categoryDisplayCount === 1 ? 'w-full' : categoryDisplayCount === 2 ? `w-[128px] h-[128px]` : 'w-[84px] h-[84px]'}`}>
                    <img
                        src={image}
                        alt=''
                        className={`rounded-lg ${categoryDisplayCount === 1 && 'max-h-[130px]'} overflow-hidden relative ${categoryDisplayCount === 1 ? 'w-full' : categoryDisplayCount === 2 ? `w-[128px] h-[128px]` : 'w-[84px] h-[84px]'} w-full`}
                    />
                </div>

                {hideTile !== undefined && !!hideTile && (
                    <div
                        className={`absolute left-1.5 flex flex-col ${maskPosition === 'bottom-left' ? 'bottom-1' : 'top-1'}`}>
                        <span
                            className={`${
                                categoryDisplayCount === 3
                                    ? 'paragraph-small-regular w-[66px] break-words'
                                    : categoryDisplayCount === 2
                                      ? 'paragraph-medium-medium w-[110px] break-words'
                                      : 'paragraph-medium-medium w-[240px] break-words'
                            } text-white -mb-1 min-h-6`}>
                            {categoryTitle ?? 'MAGGI'}
                        </span>
                        <span className='paragraph-x-small-medium flex text-white text-[9px]'>
                            6 options {categoryDisplayCount !== 3 && 'available'}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};

export const Header = (props) => {
    const { selectedColors, brandLogo } = props;

    return (
        <>
            <div className='flex flex-row justify-between items-center mt-7 mb-2'>
                <img
                    src={brandLogo}
                    alt='brandLogo'
                    className='max-w-[110px]'
                />
                <span className='max-w-[22px]'>
                    <AccountIcon className='w-full h-auto' />
                </span>
            </div>

            <div className='flex flex-col mb-4'>
                <span className='text-[10px] tracking-widest ml-6 -mb-1.5'>RAJKOT</span>
                <div className='flex flex-row items-center'>
                    <span className='w-[11px] inline-block'>
                        <StoreIcon className='w-full h-auto' />
                    </span>
                    <span className='flex flex-row items-center ml-3 paragraph-medium-semi-bold'>
                        Race cource road
                        <ArrowdownIcon className='inline-block ml-[3px]' />
                    </span>
                </div>
            </div>
            <div className='shadow-medium -mx-4 px-4 pb-4'>
                <DiningModeSelection selectedColors={selectedColors} />
            </div>
        </>
    );
};

export const DiningModeSelection = (props) => {
    const { selectedColors, page } = props;

    return (
        <>
            <div
                className={`flex flex-row items-center text-center justify-between pr-3 rounded-xl bg-[#ebebeb] paragraph-small-semi-bold w-full mobile:text-[11px] ${
                    page === 'cart' ? 'text-[10px] h-[33px]' : 'h-11'
                }`}>
                <div
                    className={`text-white px-2.5 flex rounded-xl h-full my-auto justify-center ${
                        page === 'cart' && 'min-w-[80px] mobile:min-w-0'
                    }`}
                    style={{ backgroundColor: selectedColors?.primaryColor ?? '#142348' }}>
                    <span className='my-auto'>DELIVERY</span>
                </div>

                <span className='px-2 text-neutral-500 mobile:px-0.5'>PICKUP</span>

                <span className='px-2 text-neutral-500'>DINE IN</span>
            </div>
        </>
    );
};

export const MobileComponents = () => {
    return (
        <>
            <div className='w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute mobile:w-[115px] z-10' />
            <div className='h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg' />
            <div className='h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg' />
            <div className='h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg' />
        </>
    );
};

export const HomePage = (props) => {
    const {
        selectedColors,
        greetingMassage,
        categoryTitle,
        brandLogo,
        footerLogo,
        image,
        hasMask,
        hideTile,
        maskPosition,
    } = props;

    const layoutArray = [1, 2, 3];

    return (
        <>
            <div>
                <Header
                    selectedColors={selectedColors}
                    brandLogo={brandLogo}
                />
                <Categorylayout
                    greetingMassage={greetingMassage}
                    layoutArray={layoutArray}
                    categoryTitle={categoryTitle}
                    image={image}
                    hasMask={hasMask !== undefined ? hasMask : true}
                    hideTile={hideTile}
                    maskPosition={maskPosition}
                />
                <FooterTab
                    selectedColors={selectedColors}
                    footerLogo={footerLogo}
                />
            </div>
        </>
    );
};
