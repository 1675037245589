import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../Assets/edit.svg';
import { ReactComponent as ReplaceIcon } from '../../Assets/replace.svg';
import { ReactComponent as TrashIcon } from '../../Assets/trash.svg';
import { LargeDestructiveButton, LargePrimaryButton } from '../Buttons/Button';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../Error/ErrorMessage';
import ConfirmDeletePopup from '../ConfirmDeletePopup/ConfirmDeletePopup';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import _ from 'lodash';

export default function AddImageSection(props) {
    const { setShowEditImage, typeSubCategory } = props;

    const fileInputRef = useRef(null);
    const categoryListRef = useRef(null);

    useOutsideClick(categoryListRef, () => setShowDropdown(false));

    const {
        setValue,
        formState: { errors },
        clearErrors,
        watch,
    } = useFormContext();

    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        !watch('imageUrlToDisplay') && setValue('image_category', {});
    }, []);

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];

            const reader = new FileReader();

            reader.addEventListener('load', () => {
                setValue('originalImage', {
                    image_url: reader.result?.toString() || '',
                    original_file_name: selectedFile.name,
                });

                setShowEditImage(true);

                setValue('uploaded_on', format(new Date(), 'dd MMM, yyyy'));
                setValue('uploader', user.name);

                const img = new Image();
                img.onload = function () {
                    setValue('originalDimensions', {
                        width: img.width,
                        height: img.height,
                    });
                };
                img.src = reader.result;

                const fileSizeInMB = selectedFile.size / (1024 * 1024);
                setValue('imageSize', fileSizeInMB.toFixed(2));

                const fileType = selectedFile.type;
                setValue('imageType', fileType);
            });

            reader.readAsDataURL(e.target.files[0]);

            clearErrors('selectedImage');

            setValue('is_dirty', true);
        }
    }

    const handleReplaceClick = () => {
        fileInputRef.current.click();
    };

    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const confirmDelete = () => {
        setShowDeletePopup(false);
        setValue('is_dirty', true);

        setValue('image_category', {});
        setValue('editingImage.image_url', null);
        setValue('imageUrlToDisplay', null);
    };

    const handleSelectFileClick = (type) => {
        setValue('aspectRatio', categoryAspectRatio[type.value]);
        setValue('image_category', type);
        setShowDropdown(false);
        fileInputRef.current.click();
    };

    const categoryAspectRatio = {
        categories: 1 / 1.066,
        items: 1,
        banner: 1 / 1.066,
        variant_options: 1,
        notifications: 2,
        intro_logo_image: 1 / 1.066,
        footer_image: 1 / 1.066,
        primary_banner: 1 / 0.572,
        secondary_banner: 26 / 9.624,
        tertiary_banner: 26 / 9.624,
    };

    return (
        <>
            {watch('imageUrlToDisplay') ? (
                <div className='max-w-[668px] w-full lg:mb-6'>
                    <div className='justify-center flex w-full'>
                        <img
                            src={watch('imageUrlToDisplay')}
                            alt='64image'
                            className='max-h-[708px]'
                        />
                    </div>

                    <div className='flex flex-row justify-center w-full mb-6 mt-6'>
                        <div
                            className='max-w-[153px] md:min-w-[64px]'
                            onClick={() => {
                                setShowEditImage((prevState) => !prevState);
                            }}>
                            <LargePrimaryButton
                                label='Edit image'
                                isDefault={false}
                                leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                                leftIconClick={<EditIcon stroke='#C4BEED' />}
                                hideLabel='md:hidden'
                            />
                        </div>

                        <div
                            className='max-w-[184px] mx-4 md:min-w-[64px]'
                            onClick={handleReplaceClick}>
                            <LargePrimaryButton
                                label='Replace image'
                                leftIconDefault={<ReplaceIcon stroke='#FFFFFF' />}
                                leftIconClick={<ReplaceIcon stroke='#C4BEED' />}
                                hideLabel='md:hidden'
                                isDefault={false}
                            />
                            <input
                                type='file'
                                style={{ display: 'none' }}
                                onChange={onSelectFile}
                                accept='.jpg, .jpeg, .png'
                                ref={fileInputRef}
                            />
                        </div>

                        <div
                            className='max-w-[173px] md:min-w-[64px]'
                            onClick={() => setShowDeletePopup(true)}>
                            <LargeDestructiveButton
                                label='Delete image'
                                leftIconDefault={<TrashIcon stroke='#FFFFFF' />}
                                leftIconClick={<TrashIcon stroke='#FEE2E2' />}
                                hideLabel='md:hidden'
                                isDefault={false}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='max-w-[668px] h-[375px] lg:h-[339px] lg:max-w-full w-full border border-neutral-500 rounded-2xl border-dashed justify-center flex flex-col text-center md:mb-4 lg:mb-6 relative'>
                        <div className='justify-center mx-auto relative'>
                            <label
                                className='paragraph-medium-regular border border-neutral-300 rounded-md px-4 py-3 max-w-[125px] mb-4 cursor-pointer'
                                onClick={() =>
                                    _.isEmpty(watch('image_category'))
                                        ? setShowDropdown((prev) => !prev)
                                        : fileInputRef.current.click()
                                }>
                                Select files
                            </label>

                            <input
                                type='file'
                                style={{ display: 'none' }}
                                onChange={onSelectFile}
                                accept='.jpg, .jpeg, .png'
                                ref={fileInputRef}
                            />

                            {showDropdown && (
                                <div
                                    className='border border-neutral-300 rounded-lg pt-4 px-4 w-[180px] bg-white absolute top-10 -right-[30px] z-10'
                                    ref={categoryListRef}>
                                    {typeSubCategory.type.map((type, index) => {
                                        if (type.value === 'riders' || type.value === 'menu') return;

                                        return (
                                            <div
                                                key={index}
                                                className='w-full mb-2 pb-2 cursor-pointer'
                                                onClick={() => handleSelectFileClick(type)}>
                                                {type.label}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>

                        <span className='paragraph-medium-italic text-neutral-500 mt-4'>
                            Maximum file size allowed: 50 MB
                        </span>
                        <div className='absolute -bottom-6'>
                            {errors?.selectedImage && <ErrorMessage errors={errors.selectedImage} />}
                        </div>
                    </div>
                </>
            )}

            {showDeletePopup && (
                <ConfirmDeletePopup
                    handleClickClose={() => setShowDeletePopup(false)}
                    header={<h2 className='paragraph-large-medium ml-2'>Delete the image?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure to delete this image
                        </p>
                    }
                    handleDelete={confirmDelete}
                />
            )}
        </>
    );
}
