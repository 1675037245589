import React from 'react';
import close from '../../Assets/close.svg';

export const NutralAlert = (props) => {
    const { icon, alertHeader, alertContent, action1, action2 } = props;

    return (
        <>
            <div className='w-full'>
                <div className='pt-4 pl-4 pb-4 pr-[21px] flex flex-row items-start border border-neutral-300 rounded-md bg-neutral-50'>
                    <img
                        src={icon}
                        alt=''
                        className='mr-2 opacity-20 w-6 h-6'
                    />
                    <div>
                        <h1 className='paragraph-small-medium mb-2'>{alertHeader}</h1>

                        <p className='paragraph-small-regular mb-5 text-neutral-500'>{alertContent}</p>

                        <div className='flex-row'>
                            <span className='paragraph-small-medium mr-4'>{action1}</span>
                            <span className='paragraph-small-medium'>{action2}</span>
                        </div>
                    </div>
                    <img
                        src={close}
                        alt=''
                        className='pl-[13px] ml-auto'
                    />
                </div>
            </div>
        </>
    );
};

export const PrimaryAlert = (props) => {
    const { icon, alertHeader, alertContent, action1, action2 } = props;

    return (
        <>
            <div className='w-full'>
                <div className='pt-4 pl-4 pb-4 pr-[21px] flex flex-row items-start border border-primary-300 rounded-md bg-primary-50'>
                    <img
                        src={icon}
                        alt=''
                        className='pr-2 opacity-20'
                    />
                    <div>
                        <h1 className='paragraph-small-medium text-primary-300 pb-2'>{alertHeader}</h1>

                        <p className='paragraph-small-regular mb-5 text-primary-500'>{alertContent}</p>

                        <div className='flex-row'>
                            <span className='paragraph-small-medium mr-4 text-primary-500'>{action1}</span>
                            <span className='paragraph-small-medium text-primary-500'>{action2}</span>
                        </div>
                    </div>
                    <img
                        src={close}
                        alt=''
                        className='pl-[13px] ml-auto'
                    />
                </div>
            </div>
        </>
    );
};

export const WarningAlert = (props) => {
    const {
        iconStyle,
        icon,
        headerStyle,
        alertHeader,
        alertContent,
        action1Style,
        action1,
        action2Style,
        action2,
        action3Style,
        action3,
        showcloseIcon,
        shadow,
    } = props;

    return (
        <>
            <div className='w-full'>
                <div
                    className={`pt-4 pl-4 pb-4 pr-[21px] flex flex-row items-start border border-secondary-300 rounded-md bg-warning-50 ${shadow}`}>
                    {icon && <div className={iconStyle}> {icon}</div>}
                    <div className='ml-3 text-left'>
                        <h1 className={`${headerStyle ?? 'paragraph-small-medium'} text-secondary-800 pb-2`}>
                            {alertHeader}
                        </h1>

                        {alertContent && (
                            <p className='paragraph-small-regular mb-5 text-warning-500'>{alertContent}</p>
                        )}

                        <div className='flex-row'>
                            <span
                                className={`${
                                    action1Style ?? 'paragraph-small-medium'
                                } mr-4 text-secondary-800 cursor-pointer`}>
                                {action1}
                            </span>
                            <span
                                className={`${
                                    action2Style ?? 'paragraph-small-medium'
                                } mr-4 text-secondary-800 cursor-pointer`}>
                                {action2}
                            </span>
                            <span
                                className={`${
                                    action3Style ?? 'paragraph-small-medium'
                                } mr-4 text-secondary-800 cursor-pointer`}>
                                {action3}
                            </span>
                        </div>
                    </div>
                    {showcloseIcon && (
                        <img
                            src={close}
                            alt=''
                            className='pl-[13px] ml-auto'
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export const ErrorAlert = (props) => {
    const { icon, alertHeader, alertContent, action1, action2 } = props;

    return (
        <>
            <div className='w-full'>
                <div className='pt-4 pl-4 pb-4 pr-[21px] flex flex-row items-start border border-destructive-300 rounded-md bg-destructive-50'>
                    <img
                        src={icon}
                        alt=''
                        className='pr-2 opacity-20'
                    />
                    <div>
                        <h1 className='paragraph-small-medium text-destructive-800 pb-2'>{alertHeader}</h1>

                        <p className='paragraph-small-regular mb-5 text-destructive-700'>{alertContent}</p>

                        <div className='flex-row'>
                            <span className='paragraph-small-medium mr-4 text-destructive-800'>{action1}</span>
                            <span className='paragraph-small-medium text-destructive-800'>{action2}</span>
                        </div>
                    </div>
                    <img
                        src={close}
                        alt=''
                        className='pl-[13px] ml-auto'
                    />
                </div>
            </div>
        </>
    );
};

export const SuccessAlert = (props) => {
    const { icon, alertHeader, alertContent, action1, action2 } = props;

    return (
        <>
            <div className='w-full'>
                <div className='pt-4 pl-4 pb-4 pr-[21px] flex flex-row items-start border border-success-300 rounded-md bg-success-50'>
                    <img
                        src={icon}
                        alt=''
                        className='pr-2 opacity-20'
                    />
                    <div>
                        <h1 className='paragraph-small-medium text-success-900 pb-2'>{alertHeader}</h1>

                        <p className='paragraph-small-regular mb-5 text-success-700'>{alertContent}</p>

                        <div className='flex-row'>
                            <span className='paragraph-small-medium mr-4 text-success-900'>{action1}</span>
                            <span className='paragraph-small-medium text-success-900'>{action2}</span>
                        </div>
                    </div>
                    <img
                        src={close}
                        alt=''
                        className='pl-[13px] ml-auto'
                    />
                </div>
            </div>
        </>
    );
};
