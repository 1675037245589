import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Tag from '../../../../../Components/Tag/Tag';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { useParams } from 'react-router-dom';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';

export default function OrderingModeSection() {
    const { id } = useParams();

    const { watch, setValue, getValues } = useFormContext();

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const [orderingModesMenuItem, setOrderingModesMenuItem] = useState([]);

    useEffect(() => {
        const filteredOrderingModeMenuItems = orderingMode.map((item) => ({
            label: item.display_name,
            value: item,
        }));

        setOrderingModesMenuItem(filteredOrderingModeMenuItems);

        // eslint-disable-next-line
    }, [orderingMode]);

    const handelSelectionOrderingMode = (selection) => {
        const newOrderingMode = watch('ordering_modes')?.length ? [...watch('ordering_modes'), selection] : [selection];

        setValue('ordering_modes', newOrderingMode);

        const filteredOrderingModeMenuItems = orderingMode
            .filter((item) => !newOrderingMode.some((el) => el.value.id === item.id))
            .map((item) => ({
                label: item.display_name,
                value: item,
            }));

        setOrderingModesMenuItem(filteredOrderingModeMenuItems);
    };

    const [initialOrderingModes, setInitialOrderingModes] = useState([]);

    useEffect(() => {
        setInitialOrderingModes(watch('ordering_modes'));
    }, []);

    const handleClickClose = (mode) => {
        if (!!id) {
            const oldMode = getValues('delete_ordering_modes') ?? [];

            const matchingMode = initialOrderingModes.find((initialMode) => initialMode.value.id === mode.value.id);

            if (matchingMode) {
                setValue('delete_ordering_modes', [...oldMode, matchingMode.value.id]);
            }
        }

        setOrderingModesMenuItem((prevState) => [...prevState, mode]);

        const newOrderingMode = watch('ordering_modes').filter((el) => el.value.id !== mode.value.id);

        setValue('ordering_modes', newOrderingMode);

        const deletedOrderingMode = watch('delete_ordering_modes');

        setValue('delete_ordering_modes', [...deletedOrderingMode, mode.value.id]);
    };

    return (
        <>
            <div className='mb-6'>
                <TitleDescription
                    title='Ordering mode'
                    description='Select ordering mode to apply this offer.'
                />

                <div className='w-full max-w-[312px] md:max-w-full mt-4'>
                    <DropdownWithValueLabel
                        menuItems={orderingModesMenuItem}
                        placeholder='Select ordering mode'
                        shadow='shadow-xSmall'
                        boxHeight='h-[48px]'
                        hasFixedPlaceholder
                        setSelected={handelSelectionOrderingMode}
                        zIndex='z-[1]'
                        additionalBelowSpace={120}
                    />
                </div>

                <div className='align-top mt-1'>
                    {watch('ordering_modes')?.map((el, index) => (
                        <div
                            className='mr-2 inline-block'
                            key={index}>
                            <Tag
                                tag={el.label}
                                onClose={() => handleClickClose(el)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
