import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import CircleLoader from '../../../../Components/CircleLoader/CircleLoader';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function Footer(props) {
    const { isEditSection, onSubmit, loaderStatus, isDelete, onDelete, onEdit, errorOfApi, isDirty } = props;
    const { isLoading, isResponseSuccess, isClicked } = loaderStatus;
    return (
        <>
            <div className='w-full'>
                {isEditSection ? (
                    <div className='flex flex-row border-none px-0 py-0 md:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 w-full mx-auto bg-white border-t border-neutral-300 md:px-4'>
                        <div
                            className='w-1/2 mr-2'
                            onClick={onDelete}>
                            <LargeDestructiveButton
                                label={
                                    !(isClicked && isDelete) ? (
                                        'Delete section'
                                    ) : (
                                        <CircleLoader
                                            isLoading={isLoading}
                                            isResponseSuccess={isResponseSuccess}
                                        />
                                    )
                                }
                            />
                        </div>
                        <div
                            className='w-1/2 ml-2'
                            onClick={onEdit}>
                            <LargePrimaryButton
                                label={
                                    !(isClicked && !isDelete) ? (
                                        'Save changes'
                                    ) : (
                                        <CircleLoader
                                            isLoading={isLoading}
                                            isResponseSuccess={isResponseSuccess}
                                        />
                                    )
                                }
                                disabled={!isDirty}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='border-none px-0 py-0 md:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex w-full mx-auto bg-white border-t border-neutral-300 md:px-4'>
                        <div
                            className='w-full'
                            onClick={onSubmit}>
                            <LargePrimaryButton
                                label={
                                    !isClicked ? (
                                        'Add section'
                                    ) : (
                                        <CircleLoader
                                            isLoading={isLoading}
                                            isResponseSuccess={isResponseSuccess}
                                        />
                                    )
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            {errorOfApi && <ErrorMessage errors={{ message: errorOfApi }} />}
        </>
    );
}
