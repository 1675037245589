import React, { useEffect, useState } from 'react';
import Header from '../../../../../Components/Header/Header';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import { ReactComponent as RearrangeIcon } from '../../../../../Assets/re-arrange.svg';
import ListSection from './Components/ListSection';
import Layout from '../Components/Layout';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import OrderingModeLoader from './Components/OrderingModeLoader';

export default function OrderingMode(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup, showTabChangeWarningPopup } = props;

    const { handleSubmit, setValue, watch, reset, control } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isRearrange, setIsRearrange] = useState(false);

    const updateOrderingMode = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=ordering_modes`, { ...params });
            if (response.success) {
                setValue('isFormLoading', true);
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     fetchSetting();
    //     //eslint-disable-next-line
    // }, []);

    const formatData = (data) => {
        const differentElements = data.filter((dataElement) => {
            const matchedElement = watch('defaultValue.ordering_modes').find(
                (defaultElement) => defaultElement.id === dataElement.id
            );
            return matchedElement && matchedElement.is_enabled !== dataElement.is_enabled;
        });

        return {
            action: 'status',
            ordering_modes: differentElements,
        };
    };

    const updateTranslation = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=ordering_modes`, { ...params });

            if (response.success) {
                setValue('isFormLoading', true);
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const rearrangeModes = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=ordering_modes`, { ...params });

            if (response.success) {
                setValue('isFormLoading', true);
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formateSequence = (data) => ({
        action: 'rearrange',
        restaurant_ordering_modes: data.map((el, index) => ({
            restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
            display_rank: index + 1,
        })),
    });

    const formateTranslation = (data) => {
        return {
            action: 'translations',
            display_name: {
                1: data.appearance.use_in_store_for_dine_in ? 'In Store' : 'Dine In',
            },
        };
    };

    const onSubmit = (data) => {
        setShowTabChangeWarningPopup(false);
        setShowFooter(false);

        if (isRearrange) {
            rearrangeModes(formateSequence(rearrangeOrderingMode));
            setIsRearrange(false);
            return;
        }

        const formattedOrderingModeData = formatData(data.ordering_modes);

        !!formattedOrderingModeData.ordering_modes.length && updateOrderingMode(formattedOrderingModeData);

        updateTranslation(formateTranslation(data));
    };

    const [rearrangeOrderingMode, setRearrangeOrderingMode] = useState([]);

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <OrderingModeLoader />
    ) : (
        <div className='flex flex-col w-full'>
            <Layout
                showFooter={showTabChangeWarningPopup}
                handleClickSave={handleSubmit(onSubmit)}
                handleClickCancel={() => {
                    setIsRearrange(false);
                    setShowFooter(false);
                    handleDiscardForm();
                    setShowTabChangeWarningPopup(false);
                }}>
                <div className='w-full flex flex-row justify-between xl:block'>
                    <div className='mb-6 max-w-[636px]'>
                        <Header
                            title='Ordering mode'
                            headerBottomLine='Choose to enable or disable the ordering mode a customer can select for your outlet.'
                        />
                    </div>

                    {!showFooter && (
                        <div
                            className='max-w-[159px] xl:mb-6 md:max-w-full cursor-pointer'
                            onClick={() => setIsRearrange((prevState) => !prevState)}>
                            <LargePrimaryButton
                                label='Re-arrange'
                                leftIconDefault={<RearrangeIcon stroke='#FFFFFF' />}
                                leftIconClick={<RearrangeIcon stroke='#C4BEED' />}
                            />
                        </div>
                    )}
                </div>

                <div className='max-w-[636px] w-full md:max-w-full mb-6'>
                    <div className='flex flex-row items-center mb-6 lg:mb-4'>
                        <span className='paragraph-large-medium mr-4'>Use In-store instead of Dine-in</span>

                        <Controller
                            render={({ field }) => (
                                <ToggleSwitch
                                    isEnable={field.value ?? false}
                                    canChange
                                    setIsEnabled={(value) => {
                                        field.onChange(value);
                                        setShowTabChangeWarningPopup(true);
                                    }}
                                />
                            )}
                            name='appearance.use_in_store_for_dine_in'
                            control={control}
                        />
                    </div>

                    <ListSection
                        isRearrange={isRearrange}
                        setShowFooter={setShowFooter}
                        rearrangeOrderingMode={rearrangeOrderingMode}
                        setRearrangeOrderingMode={setRearrangeOrderingMode}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                </div>
            </Layout>
        </div>
    );
}
