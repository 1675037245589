import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function Taxes({ popup }) {
    const orderingModes = [
        {
            numberOfOrder: 111,
            restaurant_id: 32,
            display_name: 'Delivery',
            type: 'delivery',
            tab: 'delivery',
            status: true,
        },
        {
            numberOfOrder: 112,
            restaurant_id: 32,
            display_name: 'Pickup',
            type: 'takeaway',
            tab: 'takeaway',
            status: true,
        },
        { numberOfOrder: 113, restaurant_id: 32, display_name: 'Qsr', type: 'qsr', tab: 'dine_in', status: true },
        {
            numberOfOrder: 114,
            restaurant_id: 32,
            display_name: 'Fine Dine',
            type: 'fine_dine',
            tab: 'dine_in',
            status: true,
        },
        {
            numberOfOrder: 115,
            restaurant_id: 32,
            display_name: 'Self Serve',
            type: 'self_serve',
            tab: 'dine_in',
            status: true,
        },
        {
            numberOfOrder: 211,
            restaurant_id: 32,
            display_name: 'gardan',
            type: 'fine_dine',
            tab: 'dine_in',
            status: true,
        },
        { numberOfOrder: 213, restaurant_id: 32, display_name: 'newName', type: 'qsr', tab: 'dine_in', status: false },
        {
            numberOfOrder: 214,
            restaurant_id: 32,
            display_name: 'Test orderd',
            type: 'qsr',
            tab: 'dine_in',
            status: false,
        },
        {
            numberOfOrder: 215,
            restaurant_id: 32,
            display_name: 'Test order1',
            type: 'fine_dine',
            tab: 'dine_in',
            status: false,
        },
        {
            numberOfOrder: 216,
            restaurant_id: 32,
            display_name: 'Test order2',
            type: 'fine_dine',
            tab: 'dine_in',
            status: true,
        },
        {
            numberOfOrder: 217,
            restaurant_id: 32,
            display_name: 'Test order3',
            type: 'fine_dine',
            tab: 'dine_in',
            status: false,
        },
        { numberOfOrder: 218, restaurant_id: 32, display_name: 'test 4', type: 'qsr', tab: 'dine_in', status: false },
        { numberOfOrder: 219, restaurant_id: 32, display_name: 'rw2', type: 'fine_dine', tab: 'dine_in', status: true },
        {
            numberOfOrder: 220,
            restaurant_id: 32,
            display_name: 'new test 1',
            type: 'fine_dine',
            tab: 'dine_in',
            status: false,
        },
        { numberOfOrder: 221, restaurant_id: 32, display_name: 'bname', type: 'qsr', tab: 'dine_in', status: false },
    ];

    const activeOrderingModes = orderingModes.filter((mode) => mode.status);

    const labels = activeOrderingModes.map((mode) => mode.display_name);
    const series = activeOrderingModes.map((mode) => mode.numberOfOrder);

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: labels,
        legend: {
            show: false,
        },

        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9', '#FF6361', '#58508D', '#BC5090', '#FFA600'],
        stroke: {
            colors: 'transparent',
        },
    };

    return (
        <>
            <div className='flex flex-row justify-between items-center md:block'>
                <div className='w-full'>
                    <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                        <ReactApexChart
                            className='m-auto'
                            type='donut'
                            width={280}
                            height={280}
                            series={series}
                            options={chartOptions}
                        />
                    </div>
                    <div className='flex flex-wrap justify-center w-full max-w-[251px] md:max-w-full'>
                        {labels.map((label, index) => (
                            <div
                                key={index}
                                className='flex flex-row items-center m-2'>
                                <div
                                    className='h-[11px] w-[11px] rounded-full mr-2'
                                    style={{ backgroundColor: chartOptions.colors[index % chartOptions.colors.length] }}
                                />
                                <span className='paragraph-medium-regular'>{label}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                    <div className='border-b border-neutral-300 mb-4'>
                        {activeOrderingModes.map((el, index) => {
                            return (
                                <div
                                    key={index}
                                    className='flex flex-row justify-between mb-4'>
                                    <span className='paragraph-medium-regular'>{el.display_name}</span>
                                    <span className='paragraph-medium-regular'>{el.numberOfOrder}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                        <span className='paragraph-medium-semi-bold'>Total {popup}</span>
                        <span className='paragraph-medium-semi-bold'>
                            {activeOrderingModes.reduce((acc, mode) => acc + mode.numberOfOrder, 0)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
