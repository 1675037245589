import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as DineInIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as SuccessTickIcon } from '../../../Assets/success-tick.svg';
import { ReactComponent as CancelIcon } from '../../../Assets/cancel.svg';
import { ReactComponent as UPIIcon } from '../../../Assets/upi.svg';
import { ReactComponent as CashIcon } from '../../../Assets/cash.svg';
import { ReactComponent as CardIcon } from '../../../Assets/card.svg';

export default function ListViewCustomerOrder(props) {
    const {
        orderId,
        orderDate,
        orderType,
        orderStatus,
        paymentMode,
        itemsOrdered,
        viewDetails,
        revenueGenerated,
        fetchOrderDetailPopup,
        setPopupOrderId,
        setCreateOfferDetails,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md md:mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>ORDER BILL NAME:</h3>
                        <span className='paragraph-small-regular'>{orderId}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-[5px]'>
                        <div className='mb-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderDate}</span>
                        </div>
                        <div className='mb-2.5 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700 mr-1'>ORDER TYPE:</span>
                            <div className='flex flex-row items-center'>
                                {orderType === 'Dine-In' ? (
                                    <DineInIcon
                                        height={20}
                                        width={20}
                                    />
                                ) : orderType === 'Takeaway' ? (
                                    <TakeAwayIcon
                                        height={20}
                                        width={20}
                                    />
                                ) : (
                                    <DeliveryIcon
                                        height={20}
                                        width={20}
                                    />
                                )}
                                <span className='ml-1 paragraph-small-regular'>{orderType}</span>
                            </div>
                        </div>
                        <div className='mb-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700 mr-1'>ORDER STATUS:</span>
                            <div className='flex flex-row items-center paragraph-small-regular'>
                                {orderStatus?.customer_picked_up_at !== null ||
                                orderStatus?.rider_delivered_at !== null ||
                                orderStatus?.delivered_at !== null ? (
                                    <>
                                        <SuccessTickIcon
                                            height={20}
                                            width={20}
                                        />
                                        <span className='ml-1'>Delivered</span>
                                    </>
                                ) : (
                                    <>
                                        <CancelIcon />
                                        <span className='ml-1'>Cancelled</span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>ITEMS ORDERED:</span>
                            <span className='paragraph-small-regular ml-1'>{itemsOrdered}</span>
                        </div>
                        <div className='mb-2'>
                            <span className='paragraph-overline-small text-neutral-700'>REVENUE GENERATED:</span>
                            <span className='paragraph-small-regular ml-1'>₹{revenueGenerated}/-</span>
                        </div>
                        <div className='flex flex-row justify-between items-center sm:block'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700 mr-1'>PAYMENT MODE:</span>

                                <div className='flex flex-row items-center'>
                                    {paymentMode === 'UPI' ? (
                                        <UPIIcon className='w-5 h-5' />
                                    ) : paymentMode === 'Cash' ? (
                                        <CashIcon className='w-5 h-5' />
                                    ) : (
                                        <CardIcon className='w-5 h-5' />
                                    )}
                                    <span className='ml-1 paragraph-small-regular'> {paymentMode}</span>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => {
                                    viewDetails();
                                    fetchOrderDetailPopup(orderId);
                                    setPopupOrderId(orderId);
                                    setCreateOfferDetails((prevState) => ({
                                        ...prevState,
                                        id: orderId,
                                    }));
                                }}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
