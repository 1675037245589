import React, { useState } from 'react';
import TableControllerButton from './TableControllerButton';
import Table from './Table';
import { useFormContext } from 'react-hook-form';

export default function TableSection(props) {
    const { setShowThresholdPopup, setActiveInactiveMode, activeInactiveMode, setShowTabChangeWarningPopup } = props;

    const { getValues, setValue } = useFormContext();

    const [isEditTable, setIsEditTable] = useState(false);

    const [isSelectionEnable, setIsSelectionEnable] = useState(false);

    const handleStatusChange = (modeId, doLink) => {
        setActiveInactiveMode((prevState) => {
            const updatedList = { ...prevState };
            if (doLink) {
                updatedList.activeMode = [...prevState.activeMode, modeId];
                updatedList.inActiveMode = prevState.inActiveMode.filter((id) => id !== modeId);
            } else {
                updatedList.inActiveMode = [...prevState.inActiveMode, modeId];
                updatedList.activeMode = prevState.activeMode.filter((id) => id !== modeId);
            }
            return updatedList;
        });
    };

    const handleClickDelete = () => {
        const deliveryCharges = getValues('delivery_charge.delivery_charges');

        setValue(
            'delivery_charge.delivery_charges',
            deliveryCharges.filter((_, index) => !activeInactiveMode.activeMode.includes(index)),
            { shouldDirty: true }
        );

        setActiveInactiveMode({ activeMode: [], inActiveMode: [] });
    };

    return (
        <>
            <TableControllerButton
                handleClickAddThreshold={() => setShowThresholdPopup({ type: 'add' })}
                handleClickEdit={() => {
                    setIsEditTable((prevState) => !prevState);
                    setIsSelectionEnable(false);
                }}
                handleClickBulkSelect={() => {
                    setIsSelectionEnable((prevState) => !prevState);
                    setIsEditTable(false);
                }}
                isEditTable={isEditTable}
                isSelectionEnable={isSelectionEnable}
                handleClickDelete={handleClickDelete}
            />

            <Table
                isEditTable={isEditTable}
                setShowThresholdPopup={setShowThresholdPopup}
                isSelectionEnable={isSelectionEnable}
                handleStatusChange={handleStatusChange}
                selectedMode={activeInactiveMode.activeMode}
            />
        </>
    );
}
