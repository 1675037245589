import React, { useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { Link } from '../../../../Components/LinkOffer/Link';
import { ListViewUserSettingPresetList, ListViewUserSettingUserList } from './ListViewUserSetting';
import ToggleSwitch from '../../../../Components/ToggleSwitch/ToggleSwitch';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { OutletPermission } from './Popups';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { useFormContext } from 'react-hook-form';
import { BlankComponent } from '../../../../Components/BlankComponent/BlankComponent';
import { useNavigation } from 'react-router-dom/dist';

export const UserList = (props) => {
    const {
        handleArrayState,
        handleClickAdd,
        actionState,
        arrayState,
        loaderStatus,
        setloaderStatus,
        paginationState,
        handlePagination,
    } = props;

    const { setValue, watch } = useFormContext();
    const [isShowOutletPopup, setIsShowOutletPopup] = useState(false);

    const isMobileScreen = IsMobileScreen();
    const navigation = useNavigation();

    const handleClickProceed = ({ id, data }) => {
        handleArrayState({
            array: 'selectedOutletForPermission',
            updatedValue: { id: id, outletList: data, oldData: data },
        });
        setIsShowOutletPopup(!isShowOutletPopup);
    };

    const handleChangeReadCheckBox = (props) => {
        handleArrayState({
            array: 'selectedForDelete',
            updatedValue: (prevState) => {
                const objectId = props?.object?.id;
                return prevState.includes(objectId)
                    ? prevState.filter((item) => item !== objectId)
                    : [...prevState, objectId];
            },
        });
    };

    const handleEdit = (user) => {
        const requestedDataForEdit = {
            user_id: user?.id,
            user_name: user?.name,
            user_role: user?.custom_role?.[0]?.name,
            user_email_Id: user?.email,
            validatedNumber: user?.mobile_number,
            country_code: user?.country_code,
        };
        Object.entries(requestedDataForEdit).forEach(([name, value]) => setValue(`defaultvalue.${name}`, value));
        setValue('mobileNumber', user?.mobile_number);
        setValue('phone_detail_id', user?.phone_detail_id);
        handleClickAdd({ enableEditMode: true });
    };
    const cellRenderer = ({ index, key, parent, style }) => {
        const item = arrayState.usersList?.data[index];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                {({ measure }) => (
                    <div
                        className='mt-2'
                        key={index}
                        style={{ ...style }}>
                        <ListViewUserSettingUserList
                            item={item}
                            handleClickProceed={() => handleClickProceed({ id: item?.id, data: item?.outlets })}
                            actionState={actionState}
                            handleEdit={handleEdit}
                            handleChangeReadCheckBox={handleChangeReadCheckBox}
                            onResize={measure}
                            cache={cache}
                            index={index}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 150,
        })
    ).current;

    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight === scrollTop && arrayState.usersList.lastPage > paginationState.selectedPage) {
            handlePagination({ page: paginationState.selectedPage, isScroll: true });
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[252px] lg:min-w-[215px]'>USER NAME</th>
                                <th className='px-6 min-w-[230px] lg:min-w-[165px]'>USER ROLE</th>
                                <th className='px-6 min-w-[271px] lg:min-w-[219px]'>MOBILE NUMBER</th>
                                <th className='px-6 min-w-[284px]'>EMAIL ID</th>
                                <th className='px-6 min-w-[231px]'>OUTLET PERMISSION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayState.usersList?.data.length !== 0 ? (
                                arrayState.usersList?.data?.map((el, index) => {
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={index}>
                                            <td className={`px-6 ${actionState.isEditMode && 'cursor-pointer'}`}>
                                                <div className='flex flex-row items-center'>
                                                    {actionState.isEditMode && (
                                                        <div
                                                            className='mr-4 cursor-pointer'
                                                            onClick={() => handleEdit(el)}>
                                                            <EditIcon />
                                                        </div>
                                                    )}
                                                    {actionState.isBulkSelect && (
                                                        <div className='mr-4 cursor-pointer'>
                                                            <CheckBoxWithoutLabels
                                                                onChange={handleChangeReadCheckBox}
                                                                object={el}
                                                            />
                                                        </div>
                                                    )}
                                                    <span>{el?.name}</span>
                                                </div>
                                            </td>
                                            <td className='px-6'>{el?.custom_role?.name}</td>
                                            <td className='px-6'>{el?.mobile_number}</td>
                                            <td className='px-6'>{el?.email}</td>
                                            <td
                                                className='px-6 cursor-pointer'
                                                onClick={() => handleClickProceed({ id: el?.id, data: el?.outlets })}>
                                                <Link number={el?.outlets?.length} />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : navigation.state !== 'idle' || loaderStatus.isLoading ? (
                                <TableLoader
                                    hasSwitch={false}
                                    columns={5}
                                />
                            ) : (
                                <BlankComponent previewText={'No Data Found'} />
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    {arrayState.usersList?.data.length ? (
                        !isShowOutletPopup && (
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        width={width}
                                        height={600}
                                        rowCount={arrayState.usersList?.data?.length}
                                        rowHeight={cache.rowHeight}
                                        rowRenderer={cellRenderer}
                                        onScroll={handleScroll}
                                        deferredMeasurementCache={cache}
                                    />
                                )}
                            </AutoSizer>
                        )
                    ) : (
                        <BlankComponent previewText='No Data' />
                    )}
                </>
            )}
            {isShowOutletPopup && (
                <OutletPermission
                    page='settingsOutletpermission'
                    hideLabel='opacity-0'
                    handleClickClose={() => setIsShowOutletPopup(!isShowOutletPopup)}
                    arrayState={arrayState}
                    handleArrayState={handleArrayState}
                    watch={watch}
                    actionState={actionState}
                    loaderStatus={loaderStatus}
                    setloaderStatus={setloaderStatus}
                />
            )}
        </>
    );
};

export const PresetList = (props) => {
    const {
        handleClickEditPresetList,
        presetList,
        handleArrayState,
        actionState,
        loaderStatus,
        selectedPage,
        handlePagination,
        arrayState,
    } = props;
    const navigation = useNavigation();

    const handleChangeReadCheckBox = ({ object, type }) => {
        handleArrayState({
            array: 'selectedForDelete',
            updatedValue: (prevState) => {
                const isItemNotSelected = !prevState.includes(object?.id);
                const isNotAllItemsSelected = arrayState.selectedForDelete.length !== presetList.data.length;
                if (isItemNotSelected && isNotAllItemsSelected) {
                    return type ? presetList.data.map((item) => item.id) : [...prevState, object?.id];
                }
                return type ? [] : prevState.filter((item) => item !== object?.id);
            },
        });
    };
    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 150,
        })
    ).current;

    const cellRenderer = ({ index, key, parent, style }) => {
        const item = presetList?.data[index];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                {({ measure }) => (
                    <div
                        className='mt-2'
                        key={key}
                        style={style}>
                        <ListViewUserSettingPresetList
                            item={item}
                            handleClickEditPresetList={handleClickEditPresetList}
                            handleChangeReadCheckBox={handleChangeReadCheckBox}
                            actionState={actionState}
                            onResize={measure}
                            cache={cache}
                            arrayState={arrayState}
                            index={index}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const isMobileScreen = IsMobileScreen();
    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight - 20 < scrollTop && presetList.last_page > selectedPage) {
            handlePagination({ page: selectedPage + 1, isScroll: true });
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[254px] lg:min-w-[215px]'>
                                    <div className='flex flex-row'>
                                        {actionState.isBulkSelect && (
                                            <div className='mr-4 cursor-pointer'>
                                                <CheckBoxWithoutLabels
                                                    onChange={handleChangeReadCheckBox}
                                                    type='all'
                                                />
                                            </div>
                                        )}
                                        PRESET NAME
                                    </div>
                                </th>

                                <th className='px-6 min-w-[227px] lg:min-w-[189px]'>LINKED USERS</th>
                                <th className='px-6 min-w-[268px] lg:min-w-[207px]'>CREATED BY</th>
                                <th className='px-6 min-w-[278px]'>CREATOR NUMBER</th>
                                <th className='px-6 min-w-[243px]'>CREATED ON</th>
                            </tr>
                        </thead>

                        {presetList?.data?.length ? (
                            <tbody>
                                {presetList?.data?.map((el, index) => {
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={el.id}>
                                            <td className={`px-6 ${actionState.isEditMode && 'cursor-pointer'}`}>
                                                <div className='flex flex-row items-center'>
                                                    {actionState.isEditMode && (
                                                        <div
                                                            className='mr-4'
                                                            onClick={() => handleClickEditPresetList(el)}>
                                                            <EditIcon />
                                                        </div>
                                                    )}
                                                    {actionState.isBulkSelect && (
                                                        <div className='mr-4 cursor-pointer'>
                                                            <CheckBoxWithoutLabels
                                                                onChange={handleChangeReadCheckBox}
                                                                object={el}
                                                                isChecked={arrayState.selectedForDelete.includes(el.id)}
                                                            />
                                                        </div>
                                                    )}
                                                    <span>{el?.name}</span>
                                                </div>
                                            </td>
                                            <td className='px-6'>{el?.linked_user_count}</td>
                                            <td className='px-6'>{el?.created_by}</td>
                                            <td className='px-6'>{el?.creator_number}</td>
                                            <td className='px-6'>{el?.created_at}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        ) : navigation.state == 'idle' || loaderStatus.isLoading ? (
                            <TableLoader
                                hasSwitch={false}
                                columns={5}
                            />
                        ) : (
                            <BlankComponent previewText='No Data' />
                        )}
                    </table>
                </div>
            ) : (
                <>
                    {actionState.isBulkSelect && (
                        <div className='ml-4 cursor-pointer flex flex-row'>
                            <CheckBoxWithoutLabels
                                onChange={handleChangeReadCheckBox}
                                type='all'
                            />
                            <span className='ml-2'>Select all</span>
                        </div>
                    )}
                    {presetList?.data ? (
                        <AutoSizer>
                            {({ width }) => (
                                <List
                                    width={width}
                                    height={600}
                                    rowCount={presetList?.data?.length}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={cellRenderer}
                                    onScroll={handleScroll}
                                    deferredMeasurementCache={cache}
                                />
                            )}
                        </AutoSizer>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export const OutletPermissionTable = (props) => {
    const { linkUserData, handleToggleSwitch, outletlist } = props;
    return (
        <>
            <div className='mb-6'>
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[100px] lg:min-w-[90px]'>STATUS</th>
                                <th className='px-6 min-w-[340px] lg:min-w-[302px]'>OUTLET NAME</th>
                                <th className='px-6 min-w-[100px] lg:min-w-[135px]'>STATE</th>
                                <th className='px-6 min-w-[80px] lg:min-w-[151px]'>CITY</th>
                                <th className='px-6 min-w-[405px]'>ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {outletlist?.map((el, index) => {
                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none justify-center h-[70px]'
                                        key={index}>
                                        <td className='px-6'>
                                            <ToggleSwitch
                                                isEnable={linkUserData.some((item) => item?.id == el?.outletId)}
                                                handleToggleSwitch={handleToggleSwitch}
                                                item={el}
                                            />
                                        </td>
                                        <td className='px-6'>{el?.displayName}</td>
                                        <td className='px-6'>
                                            {el?.outletData?.outlet_address?.translations?.state[1]}
                                        </td>
                                        <td className='px-6'>
                                            {el?.outletData?.outlet_address?.translations?.city[1]}
                                        </td>
                                        <td className='px-6'>
                                            {el?.outletData?.outlet_address?.translations?.address_line_1[1] +
                                                el?.outletData?.outlet_address?.translations?.address_line_2[1]}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
