import React from 'react';
import ContentLoader from 'react-content-loader';

export default function TableLoader() {
    const tableCount = [1, 2, 3, 4, 5];
    return (
        <>
            <ContentLoader
                width={400}
                height={50}
                foregroundColor='#d7d8d8'>
                <rect
                    x='0'
                    y='0'
                    rx='0'
                    ry='0'
                    width='410'
                    height='16'
                />
            </ContentLoader>
            <div className='grid grid-cols-6 pageContentSmall:grid-cols-4 relative z-[7] md:grid-cols-2 md:-mx-2 mobile:inline-block mobile:text-center'>
                {tableCount.map((_, index) => {
                    return (
                        <ContentLoader
                            viewBox='0 0 375 320'
                            className='h-[320px] w-[375px] pl-10'
                            backgroundColor='#d7d8d8'
                            key={index}>
                            <rect
                                x='120'
                                y='0'
                                rx='1.1'
                                ry='2'
                                width='50'
                                height='13'
                            />
                            <rect
                                x='30'
                                y='0'
                                rx='1.1'
                                ry='2'
                                width='50'
                                height='13'
                            />
                            <rect
                                x='20'
                                y='20'
                                rx='0'
                                ry='0'
                                width='160'
                                height='123'
                            />
                            <rect
                                x='0'
                                y='32'
                                rx='0'
                                ry='0'
                                width='10'
                                height='100'
                            />
                            <rect
                                x='190'
                                y='32'
                                rx='0'
                                ry='0'
                                width='10'
                                height='100'
                            />
                            <rect
                                x='120'
                                y='150'
                                rx='1.1'
                                ry='2'
                                width='50'
                                height='13'
                            />
                            <rect
                                x='30'
                                y='150'
                                rx='1.1'
                                ry='2'
                                width='50'
                                height='13'
                            />
                        </ContentLoader>
                    );
                })}
            </div>
        </>
    );
}
