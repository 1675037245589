import { useWindowSize } from '@uidotdev/usehooks';
import React from 'react';
import SaveDiscartSettings from '../../../Components/SaveDiscartSettings';

export default function Layout(props) {
    const { children, handleClickSave, handleClickCancel, showFooter } = props;

    const screenSize = useWindowSize();

    return (
        <>
            <div
                className='mt-4 w-full pl-1 largeTablet:pr-1 md:px-0 md:mt-0 overflow-auto'
                style={{
                    height: showFooter
                        ? screenSize.width > 767
                            ? screenSize.height - 244
                            : 'auto'
                        : screenSize.width > 767
                          ? screenSize.height - 147
                          : 'auto',
                }}>
                {children}
            </div>

            {showFooter && (
                <SaveDiscartSettings
                    handleClickSave={handleClickSave}
                    handleClickCancel={handleClickCancel}
                />
            )}
        </>
    );
}
