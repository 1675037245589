import React, { useState } from 'react';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as DownArrow } from '../../../../../../Assets/chevron-down.svg';

export const ListViewOutletOrderingMode = (props) => {
    const { modeName, orderType, orderTab } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDownArrow = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='px-4 py-3 border border-neutral-300 rounded-md'>
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        <ToggleSwitch enable={true} />
                        <div className='flex flex-col ml-4'>
                            <span className='paragraph-overline-small mb-1'>MODE NAME:</span>
                            <span className='paragraph-small-regular'>{modeName}</span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickDownArrow}>
                        <DownArrow className={`${showDetails && 'rotate-180'}`} />
                    </div>
                </div>
                {showDetails && (
                    <div className='mt-1.5 ml-[56px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER TYPE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderType}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER TAB:</span>
                            <span className='paragraph-small-regular ml-1'>{orderTab}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const ListViewPaymentMethod = (props) => {
    const { modeName } = props;

    return (
        <>
            <div className='px-4 py-3 border border-neutral-300 rounded-md'>
                <div className='flex flex-row items-center'>
                    <ToggleSwitch enable={true} />
                    <div className='flex flex-col ml-4'>
                        <span className='paragraph-overline-small mb-1'>MODE NAME:</span>
                        <span className='paragraph-small-regular'>{modeName}</span>
                    </div>
                </div>
            </div>
        </>
    );
};
