import React, { useCallback } from 'react';
import { LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { QRCode } from 'react-qrcode-logo';
import { LinkSharePopup } from '../LinkSharePopup';

export default function QRCodeSection(props) {
    const { header, deeplink, isLinkShare, setIsLinkShare, index } = props;

    // const menuRef = useRef();

    // useOutsideClick(menuRef, () => {
    //     setIsLinkShare(isLinkShare.map(() => false));
    // });
    const downloadQR = useCallback(async () => {
        try {
            const canvas = document.getElementById(deeplink);
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${deeplink}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            // toastsErrorMessage(`Error in downloading QR Code`, dispatch);
        }
    }, [deeplink]);
    return (
        <>
            <div className='flex flex-col'>
                <span className='paragraph-small-medium'>{header} QR code</span>

                <div className='flex flex-row items-start mt-2'>
                    <QRCode
                        ecLevel={'H'}
                        qrStyle={'squares'}
                        value={deeplink}
                        id={deeplink}
                        // logoImage={FabSystemsLogo}
                        logoWidth={60}
                        logoHeight={60}
                    />

                    <div className='flex flex-col w-[197px]'>
                        <div
                            className='mb-2'
                            onClick={downloadQR}>
                            <LargeTertiaryButton label='Download' />
                        </div>

                        <div className='mb-2'>
                            <LinkSharePopup
                                isLinkShare={isLinkShare}
                                // menuRef={menuRef}
                                setIsLinkShare={setIsLinkShare}
                                deeplink={deeplink}
                                index={index}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
