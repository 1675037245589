import React, { useEffect, useState } from 'react';
import UploadImagePopup from '../../../../Components/UploadImagePopup/UploadImagePopup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Header from './Header';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import InputSection from '../../../Setting/Components/InputSection';
import Selectlanguage from './SelectLanguage';
import { useSelector } from 'react-redux';
import LanguageViseDetails from './LanguageViseDetails';
import APIV5 from '../../../../api/axios/APIV5';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function CreateNotificationPopup({
    metaTagsList,
    screensList,
    categoriesList,
    createNotification,
    setCreateNotification,
    handleClickClose,
    isNotificationCreated,
    setIsNotificationCreated,
    selectedType,
}) {
    const methods = useForm();

    const [buttonTitle, setButtonTitle] = useState('');

    const {
        setValue,
        reset,
        watch,
        getValues,
        control,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = methods;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);

    const [selectedLangauge, setSelectedLangauge] = useState([]);

    const handleChangeLanguage = () => {};

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    const handleButtonClick = () => {
        if (watch('langauges')?.length) {
            handleSubmit(sendNotification)();
        } else {
            setError('selectedLanguages', { message: 'Please Select atleast one language' });
        }
    };

    useEffect(() => {
        watch('langauges')?.length && clearErrors('selectedLanguages');
    }, [watch('langauges')?.length]);

    const sendNotification = async (data) => {
        const dateString = data?.notification_time_type?.value;

        const dateParts = dateString?.split(' ');
        const day = dateParts?.[0];
        const monthName = dateParts?.[1];
        const year = dateParts?.[2];

        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const monthNumber = String(monthNames.indexOf(monthName) + 1).padStart(2, '0');
        const formattedDate = `${year}-${monthNumber}-${day}`;

        let formattedTime = data?.notification_selected_time?.value;

        let translations = {};
        let time_to_send = formattedDate && formattedTime ? `${formattedDate} ${formattedTime}` : null;
        let screen = '';
        let param = null;

        data?.notification_title?.forEach((item, index) => {
            translations = {
                ...translations,
                title: { ...translations.title, [index]: item },
                description: { ...translations.description, [index]: data?.notification_description?.[index] },
                image: { ...translations.image, [index]: data?.imageDetails?.[index]?.id },
            };
            screen = data?.screen?.label;
            param = data?.category?.value;
        });

        const response = await APIV5.post(`restaurants/${selectedRestaurantId}/notifications`, {
            time_to_send,
            translations,
            screen,
            param,
        });

        setIsNotificationCreated(!isNotificationCreated);
        setCreateNotification(!createNotification);
    };

    if (showUploadImagePopup) {
        return (
            <UploadImagePopup
                backToPage='Back to create notification'
                showBackArrow={true}
                selectedType={{ label: 'Notifications', value: 'notifications' }}
                handleClickClose={() => setShowUploadImagePopup(null)}
                pageTagLine='Upload an image to be used notification'
                setUploadedImageDetails={(data) => setValue(`imageDetails.${showUploadImagePopup}`, data)}
            />
        );
    }

    return (
        <>
            <FormProvider {...methods}>
                <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex overflow-auto p-4 md:p-0 md:relative'>
                    <div
                        className={`w-full md:h-full md:overflow-auto rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:py-0 m-auto [&::-webkit-scrollbar]:hidden md:px-4 md:max-w-full ${
                            !watch('langauges')?.length || watch('langauges')?.length === 1
                                ? 'max-w-[475px]'
                                : 'max-w-[940px] lg:max-w-[734px]'
                        }`}>
                        {' '}
                        <Header handleClickClose={handleClickClose} />
                        <div className='mb-3.5 max-w-[411px] md:mb-3 md:max-w-full'>
                            <Selectlanguage />
                            {errors?.selectedLanguages && <ErrorMessage errors={errors?.selectedLanguages} />}
                        </div>
                        <div className='flex flex-row md:block md:mb-20'>
                            {watch('langauges')?.map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${
                                            watch('langauges')?.length === 1
                                                ? 'w-full'
                                                : 'w-1/2 odd:pr-6 even:pl-6 even:border-l border-neutral-300 md:w-full odd:md:pr-0 even:md:pl-0 md:even:border-t even:md:mt-4 even:md:pt-4 md:even:border-l-0'
                                        }`}>
                                        <LanguageViseDetails
                                            setShowUploadImagePopup={setShowUploadImagePopup}
                                            index={index}
                                            id={el.id}
                                            metaTagsList={metaTagsList}
                                            screensList={screensList}
                                            categoriesList={categoriesList}
                                            selectedLangauge={selectedLangauge}
                                            language={el.language_label}
                                            selectedType={selectedType}
                                            setButtonTitle={setButtonTitle}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            className='mt-12 md:bottom-0 md:fixed md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4 md:-ml-4'
                            onClick={handleButtonClick}>
                            {buttonTitle?.label === 'Instant' ? (
                                <LargePrimaryButton label='Send notification' />
                            ) : (
                                <LargePrimaryButton label='Schedule notification' />
                            )}
                        </div>
                    </div>
                </div>
            </FormProvider>
        </>
    );
}
