import React from 'react';
import DropdownSection from '../../../../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../../../../Constants/Constants';
import InputSection from '../../../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../../../Components/Error/ErrorMessage';

export default function ManualIntervention(props) {
    const { disabled, setShowTabChangeWarningPopup } = props;

    const {
        clearErrors,
        watch,
        formState: { errors },
    } = useFormContext();

    const handleSelection = () => {
        setShowTabChangeWarningPopup(true);
        clearErrors('rider_setting.rider_auto_assignment.manualInterventionMinuts');
    };

    return (
        <>
            <div className='mb-4 mt-6'>
                <DropdownSection
                    title='Manual intervention'
                    description='This will enable the system to wait for someone to decide before automatically assigning the order to a rider / 3rd party rider agency.'
                    name='rider_setting.rider_auto_assignment.hasManualIntervention'
                    label='(Enable intervention)'
                    shadow='shadow-smallDropDownShadow'
                    menuItems={preferenceMenuItem}
                    placeholder='Please select preference'
                    handleSelection={handleSelection}
                    disabled={disabled}
                    labelStyle={`paragraph-medium-italic mb-2 ${disabled ? 'text-neutral-300' : 'text-neutral-500'}`}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (!disabled) return 'Please select preference';
                        },
                    }}
                    openFromBottom
                />
            </div>

            <div className='flex flex-row'>
                <div className='max-w-[164px] w-full mb-4 mr-2 md:max-w-full'>
                    <InputSection
                        name='auto_assignment_rider_settings.intervention_time.minutes'
                        label='Minutes'
                        placeholder='Enter minutes'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('rider_setting.rider_auto_assignment.hasManualIntervention')?.value}
                        labelStyle={`paragraph-small-medium ${
                            !watch('rider_setting.rider_auto_assignment.hasManualIntervention')?.value &&
                            'text-neutral-300'
                        }`}
                        inputType='number'
                        rules={{
                            validate: (value) => {
                                if (value) return true;
                                if (watch('rider_setting.rider_auto_assignment.hasManualIntervention')?.value)
                                    return 'Please enter minuts';
                            },
                        }}
                        handleInputChange={() => setShowTabChangeWarningPopup(true)}
                    />

                    {errors?.auto_assignment_rider_settings?.intervention_time?.minutes && (
                        <ErrorMessage errors={errors?.auto_assignment_rider_settings?.intervention_time?.minutes} />
                    )}
                </div>

                <div className='max-w-[164px] w-full mb-4 ml-2 md:max-w-full'>
                    <InputSection
                        name='auto_assignment_rider_settings.intervention_time.seconds'
                        label='Seconds'
                        placeholder='Enter seconds'
                        shadow='shadow-smallDropDownShadow'
                        inputType='number'
                        disabled={!watch('rider_setting.rider_auto_assignment.hasManualIntervention')?.value}
                        labelStyle={`paragraph-small-medium ${
                            !watch('rider_setting.rider_auto_assignment.hasManualIntervention')?.value &&
                            'text-neutral-300'
                        }`}
                        handleInputChange={() => setShowTabChangeWarningPopup(true)}
                    />
                </div>
            </div>
        </>
    );
}
