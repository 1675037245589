import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';

export default function ConfirmDiscardMobileButtons(props) {
    const { setShowOrdersView, setShowPopup } = props;

    return (
        <>
            <div className='px-4 pt-2 pb-4 md:fixed md:bottom-0 md:left-0 md:right-0 md:bg-white md:shadow-dropShadow z-10'>
                <div className='flex flex-row justify-between'>
                    <div
                        className='w-1/2 pr-2'
                        onClick={() => setShowOrdersView()}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='w-1/2 pl-2'
                        onClick={() => setShowPopup('confirmOrder')}>
                        <LargePrimaryButton
                            label='Confirm order'
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
