import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';

export default function Dropdown(props) {
    const {
        openFromBottom,
        shadow,
        placeholder,
        selectedItem,
        setSelectedItemFromDropDown,
        labelStyle,
        labelPaddingB,
        label,
        disabled,
        boxPadding,
        height,
        selectedItemStyle,
        menuItems,
        itemsStyle,
        fixedHeight,
        hasFixedPlaceholder,
    } = props;

    const [menuItem, setmenuItem] = useState(placeholder ?? selectedItem ?? menuItems[0]);
    const [selectedItemColor, setSelectedItemColor] = useState(placeholder && 'text-neutral-300');

    useEffect(() => {
        if (selectedItem) {
            setmenuItem(selectedItem);
            setSelectedItemColor('text-neutral-900');
        } else {
            setmenuItem(placeholder ?? menuItems[0]);
            placeholder === undefined && setSelectedItemColor('text-neutral-900');
        }
    }, [selectedItem, placeholder, menuItems]);

    const handleClickItem = (value, index) => {
        setSelectedItemFromDropDown && setSelectedItemFromDropDown(value, index);
        if (hasFixedPlaceholder) return;
        setmenuItem(value);
        setSelectedItemColor('text-neutral-900');
    };

    return (
        <>
            {label && (
                <div
                    className={`${labelStyle ?? 'paragraph-small-medium'} ${labelPaddingB ?? 'pb-1'} ${
                        disabled && 'text-neutral-300'
                    }`}>
                    {label}
                </div>
            )}
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            disabled={disabled}
                            className={`${
                                shadow ?? 'shadow-shadowXsmall'
                            } w-full flex flex-row items-center justify-between mobile:max-w-full rounded-md outline-none boxRing border py-3 appearance-none ${
                                boxPadding ?? 'px-4'
                            } border-neutral-300 ${height ?? 'h-12'} ${
                                selectedItemStyle ?? 'paragraph-small-regular'
                            }`}>
                            <span className='truncate pr-0.5'>
                                <span className={selectedItemColor}>{menuItem}</span>
                            </span>
                            <span>
                                <DownArrowIcon
                                    className='dropDownIconRotate'
                                    height={24}
                                    width={24}
                                    fill={disabled ? '#D3D2D8' : '#131126'}
                                />
                            </span>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50 ${
                                openFromBottom && 'bottom-full mb-2'
                            } ${fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'}`}>
                            {menuItems?.map((el, index) => (
                                <div
                                    className='pt-2 mb-2 cursor-pointer'
                                    key={index}
                                    onClick={() => handleClickItem(el, index)}>
                                    <Menu.Item>
                                        <span
                                            className={`paragraph-small-regular ml-1 active:paragraph-small-semi-bold hover:text-primary-500 ${itemsStyle}`}>
                                            {el}
                                        </span>
                                    </Menu.Item>
                                </div>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
