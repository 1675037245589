import React from 'react';
import { useLocation } from 'react-router-dom';
import { DeliveryCard, DinInCard, TakeAwayCard } from './OrderDetailsComponents';

export default function OrderDetails(props) {
    const { setShowPopup } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');

    const cardComponent = {
        'takeaway': TakeAwayCard,
        'delivery': DeliveryCard,
        'dine-in': DeliveryCard,
    };

    const ComponentToRender = mode ? cardComponent[mode] : DinInCard;

    return (
        <>
            <div className='bg-white pl-3 pr-5 pt-3 sticky top-[50px] z-[5] md:z-0 md:relative md:top-0 md:px-4'>
                <ComponentToRender setShowPopup={setShowPopup} />
            </div>
        </>
    );
}
