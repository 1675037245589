export const advanceSettingMenuItem = {
    mask: [
        { label: 'Mask', value: 1 },
        { label: 'Unmask', value: 0 },
    ],
    hideCategory: [
        { label: 'Hide', value: 1 },
        { label: 'Show', value: 0 },
    ],
    tileDetails: [
        { label: 'Hide', value: 1 },
        { label: 'Show', value: 0 },
    ],
    displayTheme: [
        { label: 'Square', value: 'square_image' },
        { label: 'Rectangle', value: 'full_size_image' },
    ],
    tilePosition: [
        { label: 'Top left', value: 'top-left' },
        { label: 'Bottom left', value: 'bottom-left' },
    ],
};

export const tabsComponent = {
    details: ['internalName', 'imageDetails', 'title'],
};
