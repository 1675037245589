import React from 'react';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { DefaultInputField } from '../../../../../../Components/InputField/InputField';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../../Components/Buttons/Button';

export default function AcceptOrderPopup(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className={`bg-white w-[460px] rounded-xl px-8 py-6 m-auto overflow-auto [&::-webkit-scrollbar]:hidden md:px-0 md:py-0 md:w-full md:rounded-none`}>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to Orders</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-4 pb-2'>
                        <div className=''>
                            <div className='paragraph-large-medium md:paragraph-medium-medium'>Preparation time</div>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Enter preparation time in minute
                            </span>
                        </div>
                        <span
                            className='cursor-pointer md:hidden'
                            onClick={handleClickClose}>
                            <CloseIcon
                                height={24}
                                width={24}
                            />
                        </span>
                    </div>

                    <div className='mb-6'>
                        <DefaultInputField
                            label={
                                <p>
                                    Preparation time <span className='text-neutral-500 italic'>(in minute)</span>
                                </p>
                            }
                            placeholder='Enter preparation time in minute'
                        />
                    </div>

                    <div className='flex flex-row gap-4'>
                        <div
                            className='w-full'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>

                        <div
                            className='w-full'
                            onClick={handleClickClose}>
                            <LargePrimaryButton label='Accept order' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
