import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../../Assets/chevron-down.svg';

export const FilterDropDown = (props) => {
    const { label, setFilter } = props;

    const [selectedStatus, setSelectedStatus] = useState('All');

    const handleChangeSelectedItems = (selectedItem) => {
        setSelectedStatus(selectedItem);

        setFilter(label, selectedItem === 'All' ? null : selectedItem.toLowerCase());
    };

    const menuItems = ['All', 'Active', 'Inactive'];

    return (
        <>
            <div className='flex flex-row justify-between'>
                <div className='paragraph-small-medium mb-1'>
                    {label.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
                </div>
                {selectedStatus !== menuItems[0] && (
                    <div
                        className='text-primary-500 paragraph-small-medium cursor-pointer'
                        onClick={() => handleChangeSelectedItems('All')}>
                        Clear
                    </div>
                )}
            </div>

            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button className='shadow-xSmall w-full flex flex-row justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none px-4 border-neutral-300 h-12 paragraph-small-regular'>
                            {selectedStatus}
                            <DownArrowIcon className='drop-down-icon-rotate min-w-[24px] min-h-[24px]' />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium-medium bg-shades-50 font-normal z-50'>
                            {menuItems.map((el, index) => {
                                return (
                                    <div
                                        className='pt-2 mb-2 cursor-pointer w-full'
                                        key={index}
                                        onClick={() => handleChangeSelectedItems(el)}>
                                        <Menu.Item>
                                            <div className='paragraph-small-regular active:paragraph-small-semi-bold hover:text-primary-500'>
                                                {el}
                                            </div>
                                        </Menu.Item>
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
};
