import APIV5 from '../../../../api/axios/APIV5';
import { handleErrorResponse } from './LoaderFunctions';
const timeout = 1000;
export const getPermissionList = async () => {
    try {
        const response = await APIV5.get(`/permissions`);
        return response;
    } catch (error) {}
};

export const getPresetList = async ({
    handleArrayState,
    pageNumber = 1,
    perPage = 10,
    isScroll = false,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus && setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: false });

        const response = await APIV5.get(
            `/restaurants/${selectedRestaurantId}/custom-roles?per_page=${perPage}&page=${pageNumber}`
        );
        if (response.success) {
            isScroll
                ? handleArrayState({
                      array: 'presetList',
                      updatedValue: (prevState) => ({
                          ...prevState,
                          last_page: response?.custom_roles.last_page,
                          data: [...prevState.data, ...response?.custom_roles.data],
                      }),
                  })
                : handleArrayState({ array: 'presetList', updatedValue: response?.custom_roles });
            setloaderStatus && setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        }
        return response ?? [];
    } catch (error) {
        return [];
    }
};

export const addPreset = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    setError,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/custom-roles`, { ...requestedBody });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                getPresetList({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        setError('addPresetError', { message: error?.response?.data?.errors?.name });
        handleErrorResponse(setloaderStatus);
    }
};

export const editPreset = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    presetId,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    // https://universal-restaurant-backend.test/api/v5/restaurants/1/custom-roles/12
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.put(`/restaurants/${selectedRestaurantId}/custom-roles/${presetId}`, {
            ...requestedBody,
        });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                getPresetList({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};

export const deletePresets = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    // https://universal-restaurant-backend.test/api/v5/restaurants/1/custom-roles/12
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.delete(`/restaurants/${selectedRestaurantId}/custom-roles/bulk-delete`, {
            ...requestedBody,
        });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            handleArrayState({ array: 'selectedForDelete', updatedValue: [] });
            setTimeout(() => {
                handleClickClose();
                getPresetList({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};

export const getSelectedPresetDetail = async ({ presetId, selectedRestaurantId }) => {
    try {
        const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/custom-roles/${presetId}`);
        if (response.success) {
            return response;
        }
    } catch (error) {}
};

export const getOtp = async ({
    mobileNumber,
    countryCode,
    setError,
    clearErrors,
    setloaderStatus,
    setIsShowInputView,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/send-otp`, {
            mobile_number: mobileNumber,
            country_code: countryCode,
        });
        if (response.success) {
            setTimeout(() => {
                setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
                setIsShowInputView(true);
            }, timeout);
            clearErrors('mobile_number');
        } else {
            handleErrorResponse(setloaderStatus);
        }
        return response;
    } catch (error) {
        setError('mobile_number', {
            message: error?.response?.data?.errors?.country_code ?? error.message,
        });
        handleErrorResponse(setloaderStatus);
    }
};
export const verifyOtp = async ({
    mobileNumber,
    countryCode,
    otp,
    setError,
    setloaderStatus,
    setIsShowMobileNumberPopup,
    selectedRestaurantId,
}) => {
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/verify-otp`, {
            mobile_number: mobileNumber,
            country_code: countryCode,
            otp: otp,
        });
        if (response?.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                setIsShowMobileNumberPopup(false);
                setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
            }, timeout);
            return response;
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        setError('mobile_number', {
            message: error?.response?.data?.message,
        });
        handleErrorResponse(setloaderStatus);
    }
};
export const getUsers = async ({
    handleArrayState,
    pageNumber = 1,
    perPage = 10,
    isScroll = false,
    keyword = '',
    setloaderStatus,
    selectedRestaurantId,
}) => {
    // https://api.staging.fab.delivery/api/v5/restaurants/22/users?keyword=manager&per_page=10
    try {
        setloaderStatus && setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: false });

        const response = await APIV5.get(
            `/restaurants/${selectedRestaurantId}/users?keyword=${keyword}&per_page=${perPage}&page=${pageNumber}`
        );
        if (response?.success) {
            isScroll
                ? handleArrayState({
                      array: 'usersList',
                      updatedValue: (prevState) => {
                          const lastState = prevState;
                          lastState.last_page = response?.users?.last_page;
                          lastState.data = [...lastState.data, ...response?.users?.data];
                          return lastState;
                      },
                  })
                : handleArrayState && handleArrayState({ array: 'usersList', updatedValue: response?.users });
        }
        setloaderStatus && setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        return response ?? [];
    } catch (error) {
        return [];
    }
};
export const addUser = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    try {
        // https://universal-restaurant-backend.test/api/v5/restaurants/26/users
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.post(`/restaurants/${selectedRestaurantId}/users`, { ...requestedBody });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                getUsers({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
            return response;
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};

export const linkUserToOutlet = async ({
    id,
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    try {
        // https://api.staging.fab.delivery/api/v5/restaurants/20/users/2
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.patch(`/restaurants/${selectedRestaurantId}/users/${id}`, { ...requestedBody });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                getUsers({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};

export const deleteUsers = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    selectedRestaurantId,
}) => {
    // https://api.staging.fab.delivery/api/v5/restaurants/26/users
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.delete(`/restaurants/${selectedRestaurantId}/users`, { ...requestedBody });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            handleArrayState({ array: 'selectedForDelete', updatedValue: [] });
            setTimeout(() => {
                handleClickClose();
                getUsers({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};

export const editUser = async ({
    requestedBody,
    handleClickClose,
    handleArrayState,
    setloaderStatus,
    id,
    selectedRestaurantId,
}) => {
    try {
        // https://api.staging.fab.delivery/api/v5/restaurants/26/users/3
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const response = await APIV5.put(`/restaurants/${selectedRestaurantId}/users/${id}`, { ...requestedBody });
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                getUsers({ handleArrayState, setloaderStatus, selectedRestaurantId });
            }, timeout);
        } else {
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
    }
};
