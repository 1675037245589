import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '../../../Components/Tabs/Tabs';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';

export default function Layout(props) {
    const { showRenderPage, children } = props;

    const location = useLocation();

    const currentPath = location.pathname.replace('/menu/', '');

    const navigate = useNavigate();

    const isMobileScreen = IsMobileScreen();

    const screenSize = useWindowSize();

    const headerTab = [
        { item: 'Catalogue', onClick: () => navigate('/menu/catalogue'), link: 'catalogue' },
        { item: 'Catalogue presets', onClick: () => navigate('/menu/catalogue-presets'), link: 'catalogue-presets' },
        { item: 'Outlet menu', onClick: () => navigate('/menu/outlet-menu'), link: 'outlet-menu' },
    ];

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 lg:px-4 pt-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full md:pb-10'
                    style={{ minHeight: !isMobileScreen && screenSize.height - 50 }}>
                    {!isMobileScreen ? (
                        <div className='flex flex-row items-center mb-4 pb-4 border-b border-neutral-300'>
                            {headerTab.map((el, index) => (
                                <div
                                    className='mr-4 lg:mr-2 cursor-pointer'
                                    onClick={el.onClick}
                                    key={index}>
                                    <Tab
                                        label={el.item}
                                        isActive={el.link === currentPath}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        showRenderPage && (
                            <div className='mb-4 pb-4 border-b border-neutral-300'>
                                <DropDownTabs menuItems={headerTab} />
                            </div>
                        )
                    )}
                    {children}
                </div>
            </div>
        </>
    );
}
