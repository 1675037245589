import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

const NoPage = () => {
    const screenHeight = useWindowSize().height;

    return (
        <>
            <div>
                <div
                    className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white min-h-screen'
                    style={{ minHeight: screenHeight - 50 }}>
                    <h1 className='display-small-medium text-black'>404</h1>
                </div>
            </div>
        </>
    );
};

export default NoPage;
