import React from 'react';
import ContentLoader from 'react-content-loader';

export default function TitleDescriptionLoader(props) {
    const { className, descHeight } = props;

    return (
        <ContentLoader
            width='100%'
            height='100%'
            className={`h-[${(descHeight ?? 40) + 30}px] w-full ${className}`}>
            <rect
                x='0'
                y={0}
                rx='4'
                ry='4'
                width='120'
                height={30}
            />
            <rect
                x='0'
                y={36}
                rx='4'
                ry='4'
                width='100%'
                height={descHeight ?? 48}
            />
        </ContentLoader>
    );
}
