import { Controller } from 'react-hook-form';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';

export default function ListViewPaymentMethod(props) {
    const { modeName, watch, index, control, setShowFooter, setShowTabChangeWarningPopup } = props;

    return (
        <>
            <div className='px-4 py-3 border border-neutral-300 rounded-md'>
                <div className='flex flex-row items-center'>
                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={watch(`cod_enablement.${index}.is_enabled`) ?? false}
                                canChange
                                setIsEnabled={(value) => {
                                    field.onChange(value);
                                    setShowFooter(true);
                                    setShowTabChangeWarningPopup(true);
                                }}
                            />
                        )}
                        name={`cod_enablement.${index}.is_enabled`}
                        control={control}
                    />

                    <div className='flex flex-col ml-4'>
                        <span className='paragraph-overline-small mb-1'>MODE NAME:</span>
                        <span className='paragraph-small-regular'>{modeName}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
