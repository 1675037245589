import React, { useEffect, useState, useRef } from 'react';
import { Outlet, ScrollRestoration, useMatch, useNavigate } from 'react-router-dom';
import { IsMobileScreen } from '../../Constants/Constants';
import DesktopHeader from './Components/DesktopHeader';
import MobileHeader from './Components/MobileHeader';
import LeftPanel from './Components/LeftPanel';
import { useSelector } from 'react-redux';
import OrderCards from '../OrderCards/OrderCards';
import RestartOrderPopup from './Popup/RestartOrderPopup';
import DeliveryPopup from './Popup/Delivery/DeliveryPopup';
import TakeawayPopup from './Popup/Takeaway/TakeawayPopup';
import LogoutPopup from './Popup/LogoutPopup/LogoutPopup';
import { useDispatch } from 'react-redux';
import { SET_AUTH_TOKEN } from '../../reduxStore/types/auth';

const SidebarMenu = () => {
    const togglePanel = useSelector((state) => state.fullScreen.togglePanel);

    const sideMenu = useRef(null);

    const isMobileScreen = IsMobileScreen();

    const [isHeaderSticky, setHeaderSticky] = useState(false);

    const [showRestartOrderPopup, setShowRestartOrderPopup] = useState(false);

    const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(false);

    const [isDockLetPanel, setIsDockLetPanel] = useState(false);

    const [showDeliveryPopup, setShowDeliveryPopup] = useState(false);

    const [showTakeAwayPopup, setShowTakeAwayPopup] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setHeaderSticky(window.scrollY > 0);
        });
    }, []);

    const handleClickLink = () => {
        !isDockLetPanel && setIsLeftPanelExpanded(false);
    };

    const toggleLeftPanel = () => {
        setIsLeftPanelExpanded((prevState) => !prevState);
        setIsDockLetPanel(false);
    };

    const isLoginPage = !!useMatch('/login');

    const isSelectRestaurantPage = !!useMatch('select-restaurant');

    const showMenubar = !(isLoginPage || isSelectRestaurantPage);

    const [showLogoutPopup, setShowLogoutPopup] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedOutletId');
        localStorage.removeItem('selectedRestaurantID');
        localStorage.removeItem('authToken');

        dispatch({
            type: SET_AUTH_TOKEN,
            payload: { authToken: null },
        });

        navigate('/login');

        setShowLogoutPopup(false);
    };

    return (
        <>
            <ScrollRestoration />

            <div className='flex flex-row items-start'>
                {showMenubar && !togglePanel && (
                    <LeftPanel
                        sideMenu={sideMenu}
                        handleClickLink={handleClickLink}
                        isLeftPanelExpanded={isLeftPanelExpanded}
                        setIsDockLetPanel={setIsDockLetPanel}
                        isDockLetPanel={isDockLetPanel}
                    />
                )}

                <div
                    className={`w-full md:pt-[108px] md:pl-0 content-main ${
                        isDockLetPanel
                            ? 'pl-[300px] xl:pl-[280px]'
                            : showMenubar && !togglePanel && 'pl-[104px] xl:pl-[72px] undock-left-panel group'
                    }`}
                    style={{ paddingLeft: togglePanel && '0', paddingTop: isMobileScreen && !showMenubar && '0' }}>
                    {showMenubar && !isMobileScreen && !togglePanel && (
                        <DesktopHeader
                            setShowDeliveryPopup={setShowDeliveryPopup}
                            setShowTakeAwayPopup={setShowTakeAwayPopup}
                            setShowRestartOrderPopup={setShowRestartOrderPopup}
                            isLeftPanelExpanded={isLeftPanelExpanded}
                            isDockLetPanel={isDockLetPanel}
                            toggleLeftPanel={toggleLeftPanel}
                            setIsLeftPanelExpanded={setIsLeftPanelExpanded}
                            setShowLogoutPopup={setShowLogoutPopup}
                        />
                    )}

                    {isMobileScreen && showMenubar && (
                        <MobileHeader
                            isHeaderSticky={isHeaderSticky}
                            isLeftPanelExpanded={isLeftPanelExpanded}
                            setIsLeftPanelExpanded={setIsLeftPanelExpanded}
                            isDockLetPanel={isDockLetPanel}
                            toggleLeftPanel={toggleLeftPanel}
                        />
                    )}

                    {/* <OrderCards /> */}

                    <Outlet />
                </div>
            </div>

            {showRestartOrderPopup && (
                <RestartOrderPopup handleClickClose={() => setShowRestartOrderPopup((prevState) => !prevState)} />
            )}

            {showDeliveryPopup && <DeliveryPopup handleClickClose={() => setShowDeliveryPopup(false)} />}

            {showTakeAwayPopup && <TakeawayPopup handleClickClose={() => setShowTakeAwayPopup(false)} />}

            {showLogoutPopup && (
                <LogoutPopup
                    handleLogout={handleLogout}
                    handleClickClose={() => setShowLogoutPopup(false)}
                />
            )}
        </>
    );
};
export default SidebarMenu;
