import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../TitleDescription/TitleDescription';
import DropdownWithValueLabel from '../DropDown/DropdownWithValueLabel';
import { ErrorMessage } from '../Error/ErrorMessage';

export default function DropdownSection(props) {
    const {
        title,
        description,
        name,
        disabled,
        placeholder,
        menuItems,
        labelPaddingB,
        openFromBottom,
        label,
        labelStyle,
        fixedHeight,
        rules,
        clearErrorName,
        marginBetween,
        dontShowError,
        boxHeight,
        hasFixedPlaceholder,
        maxWidth,
        className,
        clearField,
        icon,
        zIndex,
        additionalBelowSpace,
    } = props;

    const {
        control,
        clearErrors,
        formState: { errors },
        setValue,
    } = useFormContext();

    const handleSelection = (selection, fieldChange) => {
        fieldChange(selection);

        if (clearErrorName) {
            clearErrors([name, ...clearErrorName]);
        } else {
            clearErrors(name);
        }

        if (clearField?.length) {
            clearField.forEach((el) => setValue(el, null));
        }

        props?.handleSelection?.(selection);
    };

    return (
        <>
            {(title || description) && (
                <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                    <TitleDescription
                        title={title}
                        disabled={disabled}
                        description={description}
                    />
                </div>
            )}

            <div className={`w-full md:max-w-full ${maxWidth ?? 'max-w-[312px]'} ${className}`}>
                <Controller
                    render={({ field }) => (
                        <DropdownWithValueLabel
                            menuItems={menuItems}
                            placeholder={placeholder}
                            shadow='shadow-xSmall'
                            height={boxHeight ?? 'h-[48px]'}
                            selectedItem={field.value}
                            setSelected={(selection) => {
                                handleSelection(selection, field.onChange);
                            }}
                            disabled={disabled}
                            labelPaddingB={labelPaddingB}
                            openFromBottom={openFromBottom}
                            label={label}
                            labelStyle={labelStyle}
                            fixedHeight={fixedHeight}
                            hasFixedPlaceholder={hasFixedPlaceholder}
                            icon={icon}
                            zIndex={zIndex}
                            additionalBelowSpace={additionalBelowSpace}
                        />
                    )}
                    name={name}
                    control={control}
                    rules={rules ?? {}}
                />
                {!dontShowError && errors?.[name] && <ErrorMessage errors={errors?.[name]} />}
            </div>
        </>
    );
}
