import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../../Assets/close.svg';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import Dropdown from '../../../Components/DropDown/Dropdown';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function RefundPopup(props) {
    const { title, tagLine, handleClickClose, partialRefundAmount, setPartialRefundAmount, handleRefund } = props;

    const refundTypes = ['Complete Refund', 'Partial Refund'];

    const [selectedRefundType, setSelectedRefundType] = useState('');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        !!selectedRefundType && setIsDisabled(false);
    }, [selectedRefundType]);

    // useEffect(()=>{
    //     if(popupDetails)
    // },[popupDetails])

    const handleOfferTypeChange = (selectedType) => {
        setSelectedRefundType(selectedType);
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 p-4 overflow-auto flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='flex flex-row justify-between items-start mb-6 md:mb-3'>
                        <div>
                            <span className='paragraph-large-medium'>{title ?? 'Select Refund Type'}</span>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    {tagLine ?? 'Select a refund type to send it to the customer'}
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => handleClickClose()}
                            className='cursor-pointer'>
                            <Close />
                        </div>
                    </div>
                    <div className='mb-12 md:mb-3'>
                        <div className='relative mb-4 md:mb-3'>
                            <Dropdown
                                label='Select refund type'
                                placeholder='Select refund type'
                                menuItems={refundTypes}
                                selectedItem={selectedRefundType}
                                setSelectedItemFromDropDown={handleOfferTypeChange}
                            />
                        </div>
                        {selectedRefundType === 'Partial Refund' && (
                            <div className='mb-4 md:mb-3 mt-3'>
                                <DefaultInputField
                                    label='Enter refund amount'
                                    placeholder='Enter partial refund amount'
                                    value={partialRefundAmount}
                                    enteredValue={setPartialRefundAmount}
                                />
                            </div>
                        )}
                    </div>

                    <div className='flex flex-row items-center'>
                        <div
                            className='w-1/2 mr-2'
                            onClick={() => handleClickClose()}>
                            <LargeDestructiveButton label='Cancel' />
                        </div>
                        <div
                            className='w-1/2 ml-2'
                            onClick={() => handleRefund()}>
                            <LargePrimaryButton
                                label='Send refund'
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
