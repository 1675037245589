import React, { useState } from 'react';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function ListViewCustomerOffer(props) {
    const { couponCode, offerType, title, description } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDownArrow = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='px-4 py-3 border border-neutral-300 rounded-md'>
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        <ToggleSwitch enable={true} />
                        <div className='flex flex-col ml-4'>
                            <span className='paragraph-overline-small mb-1'>COUPON CODE:</span>
                            <span className='paragraph-small-regular'>{couponCode}</span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickDownArrow}>
                        <DownArrow className={`${showDetails && 'rotate-180'}`} />
                    </div>
                </div>

                {showDetails && (
                    <div className='mt-1.5 ml-[56px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>OFFER TYPE:</span>
                            <span className='paragraph-small-regular ml-1'>{offerType}</span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>TITLE:</span>
                            <span className='paragraph-small-regular ml-1'>{title}</span>
                        </div>
                        <div className='mb-[5px] flex flex-row'>
                            <span className='paragraph-overline-small text-neutral-700'>DESCRIPTION:</span>
                            <div className='paragraph-small-regular ml-1'>{description}</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
