import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import CircleLoader from '../../../../Components/CircleLoader/CircleLoader';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function Footer(props) {
    const { isEditTable, onClick, editTable, deleteTable, loaderStatus, isDelete, errorOfApi, isDirty } = props;
    const { isLoading, isResponseSuccess, isClicked } = loaderStatus;

    return (
        <>
            {isEditTable ? (
                <div className='flex flex-row border-none px-0 py-0 md:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 w-full mx-auto bg-white border-t border-neutral-300 md:px-4 mt-12'>
                    <div
                        className='w-1/2 mr-2'
                        onClick={deleteTable}>
                        <LargeDestructiveButton
                            label={
                                !(isClicked && isDelete) ? (
                                    'Delete table'
                                ) : (
                                    <CircleLoader
                                        isLoading={isLoading}
                                        isResponseSuccess={isResponseSuccess}
                                    />
                                )
                            }
                        />
                    </div>
                    <div
                        className='w-1/2 ml-2'
                        onClick={editTable}>
                        <LargePrimaryButton
                            label={
                                !(isClicked && !isDelete) ? (
                                    'Save changes'
                                ) : (
                                    <CircleLoader
                                        isLoading={isLoading}
                                        isResponseSuccess={isResponseSuccess}
                                    />
                                )
                            }
                            disabled={!isDirty}
                        />
                    </div>
                </div>
            ) : (
                <div className='border-none px-0 py-0 md:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex w-full mx-auto bg-white border-t border-neutral-300 md:px-4 mt-12 md:mt-0'>
                    <div
                        className='w-full'
                        onClick={onClick}>
                        <LargePrimaryButton
                            label={
                                !isClicked ? (
                                    'Add table'
                                ) : (
                                    <CircleLoader
                                        isLoading={isLoading}
                                        isResponseSuccess={isResponseSuccess}
                                    />
                                )
                            }
                        />
                    </div>
                </div>
            )}
            {errorOfApi && <ErrorMessage errors={{ message: errorOfApi }} />}
        </>
    );
}
