import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function BottomButtonSection(props) {
    const { setShowPopup, isReservationForm } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isReservationForm ? (
                <div className='flex flex-row items-center md:block md:pb-20'>
                    <div className='w-1/2 mr-2 md:w-full'>
                        <span className='paragraph-large-medium'>Step 1/2</span>
                    </div>
                    <div
                        className='w-1/2 ml-2 md:hidden'
                        onClick={() => setShowPopup('addReservationFee')}>
                        <LargePrimaryButton label='Proceed' />
                    </div>
                </div>
            ) : (
                <div className='flex justify-end w-full pt-6'>
                    <div className=''>
                        <LargePrimaryButton label='Add to queue' />
                    </div>
                </div>
            )}

            {isMobileScreen && (
                <div className='fixed bottom-0 w-full pt-2 pb-1 shadow-dropShadow -ml-4 px-4 bg-white flex flex-row'>
                    <div className='w-1/2 mr-2'>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div className='w-1/2 ml-2'>
                        <LargePrimaryButton label='Add reservation' />
                    </div>
                </div>
            )}
        </>
    );
}
