import React from 'react';

export const ListBlankComponentView = (props) => {
    const { previewText } = props;
    return (
        <div className='bg-neutral-50 w-full py-4 border border-neutral-50 rounded-md flex items-center justify-center'>
            <span className='text-xl font-bold'>{previewText}</span>
        </div>
    );
};
