import React from 'react';
import Chart from 'react-apexcharts';
import PlatformSalesDetails from './PlatformSalesDetails';

export default function PlatformSales() {
    const platform = ['Swiggy', 'Mobile application', 'Zomato sales', 'Website sales', 'Others sales'];
    const sales = [25, 28, 22, 12.5, 12.5];

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: platform,
        legend: {
            show: false,
        },

        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#4B39C9', '#DC2626', '#74C2B9', '#504F5A'],
        stroke: {
            colors: 'transparent',
        },
    };

    return (
        <>
            <div>
                <span className='paragraph-large-medium text-black'>Platform sales</span>
                <div className='rounded-md border border-neutral-300 mt-4 py-2 min-h-[321px] xl:px-0.5'>
                    <div className='xl:overflow-auto scrollbar-style py-[19px]'>
                        <div className='flex justify-between items-center w-full xl:w-[550px] lg:w-full lg:block'>
                            <div className='w-[280px] lg:w-full lg:flex lg:mb-5'>
                                <Chart
                                    className='m-auto'
                                    type='donut'
                                    width={280}
                                    height={280}
                                    series={sales}
                                    options={chartOptions}
                                />
                            </div>
                            <div className='w-full pl-[22px] pr-6 pageContentSmall:pl-0 pageContentSmall:pr-3 lg:pl-5 lg:pr-6 mobile:px-3'>
                                <PlatformSalesDetails />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
