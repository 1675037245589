import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function LinkedAddonGroup(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };
    return (
        <>
            <div
                className={`p-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex'>
                    <span className='paragraph-medium-medium'>Linked add-on groups</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <>
                        {dishDetails.variants.map((el) => {
                            return el.options.map((optionEl, optionIndex) => {
                                return (
                                    <div
                                        className='mt-4'
                                        key={optionIndex}>
                                        <span className='paragraph-small-medium'>
                                            {optionEl.translations.title[selectedLanguage]}
                                        </span>

                                        {optionEl.addons.map((addonEl, addonIndex) => {
                                            return (
                                                <div
                                                    className='mt-2'
                                                    key={addonIndex}>
                                                    <span> {addonEl.translations.group_name[selectedLanguage]} </span>

                                                    <span className='text-neutral-500 paragraph-x-small-italic pl-1'>
                                                        (select minimum {addonEl.min_addons} and maximum{' '}
                                                        {addonEl.max_addons})
                                                    </span>

                                                    {addonEl.choices.map((choiceEl, choiceIndex) => {
                                                        return (
                                                            <div
                                                                className=''
                                                                key={choiceIndex}>
                                                                <span className='paragraph-small-regular text-neutral-500 mr-2'>
                                                                    {choiceEl.translations.name[selectedLanguage]}:
                                                                </span>

                                                                {choiceEl.ordering_modes.map((modeEl, modeIndex) => {
                                                                    return (
                                                                        <div
                                                                            className='flex flex-row mr-4 mt-1'
                                                                            key={modeIndex}>
                                                                            <span className='paragraph-small-regular text-neutral-500 mr-2'>
                                                                                {modeEl.restaurant_ordering_mode_name}:
                                                                            </span>

                                                                            <span className='paragraph-small-regular'>
                                                                                &#8377; {modeEl.price}
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            });
                        })}
                    </>
                )}
            </div>
        </>
    );
}
