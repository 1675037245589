import React from 'react';
import LoyaltyOrderedCashback from './OrderedRewardCashback/LoyaltyOrderedCashback';
import LoyaltySignupCashback from './SignUpCashback/LoyaltySignUpCashback';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTab from './Components/HeaderTab';
import { useWindowSize } from '@uidotdev/usehooks';

export default function LoyaltyCashback() {
    const { section } = useParams();

    const navigate = useNavigate();
    const screenHeight = useWindowSize().height;

    const componentMapping = {
        'ordered-reward-points': LoyaltyOrderedCashback,
        'sign-up-reward-points': LoyaltySignupCashback,
    };

    const handleNavigation = ({ route }) => {
        navigate(`/loyalty-cashback/${route}`);
    };

    const ComponentToRender = componentMapping[section];

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className={`px-8 lg:px-4 pt-4 md:pb-8 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full ${
                        !true && 'md:px-0 md:pt-0'
                    }`}
                    style={{ minHeight: screenHeight - 50 }}>
                    <HeaderTab handleNavigation={handleNavigation} />

                    <div className='-mt-4 md:-mt-0'>
                        <ComponentToRender handleNavigation={handleNavigation} />
                    </div>
                </div>
            </div>
        </>
    );
}
