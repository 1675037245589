import { DiningModeSelection, FooterTab } from './HomePage';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as InstructionIcon } from '../../../Assets/instruction.svg';

export const Item = () => {
    return (
        <>
            <div className='flex flex-row justify-between pb-4 border-b border-neutral-300 px-4 mb-4'>
                <div className='flex flex-col paragraph-small-semi-bold text-[13px]'>
                    <span className=''>
                        <VegIcon />
                    </span>
                    <span className=''>Nachos With Cheese Dip</span>
                    <span>₹118</span>
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row items-center paragraph-small-regular p-[1px] h-[30px] rounded-md bg-[#b8bdc8] text-center cursor-pointer w-[80px] mb-2 shadow-small'>
                        <span className='font-normal cursor-pointer text-xl leading-5 px-[9.5px] py-1 text-black bg-white rounded-md'>
                            -
                        </span>

                        <span className='text-black mx-auto paragraph-medium-regular md:paragraph-small-regular md:text-shades-50 pageContent:text-sm'>
                            1
                        </span>

                        <span className='font-normal text-xl leading-5 px-[7px] py-1 text-black bg-white rounded-md cursor-pointer'>
                            +
                        </span>
                    </div>

                    <span className='paragraph-small-semi-bold text-[13px] text-end'>₹118</span>
                </div>
            </div>
        </>
    );
};

const Header = () => {
    return (
        <>
            <div className='pb-5 border-b border-neutral-300 mb-4'>
                <div className='flex flex-row items-center mx-3'>
                    <span className='paragraph-small-semi-bold mr-4 mobile:mr-1'>Cart</span>
                    <DiningModeSelection page='cart' />
                </div>
            </div>
            <div className='h-3 bg-[#f8f8f8] mb-4' />
        </>
    );
};

const SpecialInstruction = (props) => {
    const { instructionmassage } = props;

    return (
        <>
            <p className='flex items-center px-md pb-4 border-[#f8f8f8] border-b-[12px] border-cart px-3'>
                <span className='inline-block w-[14px]'>
                    <InstructionIcon className='w-full h-auto opacity-60' />
                </span>
                <span className='text-[11px] text-[#7c7f8b] ml-2 leading-none inline-block ml-md pt-[1px]'>
                    {instructionmassage ?? 'Any special cooking request? Eg. Less Spicy'}
                </span>
            </p>
        </>
    );
};

export const CartPage = (props) => {
    const { instructionmassage } = props;

    return (
        <>
            <div className='mt-8 -mx-4'>
                <Header />
                <Item />
                <SpecialInstruction instructionmassage={instructionmassage} />
                <div className='mx-4'>
                    <FooterTab />
                </div>
            </div>
        </>
    );
};
