import React, { useEffect, useState } from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as FilterIcon } from '../../../../Assets/filter.svg';
import { StateCityDropdown } from '../../../../Components/DropDown/StateCityDropdown';
import { useSelector } from 'react-redux';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function LinkFilterComponent(props) {
    const { setAppliedFilter } = props;

    const outletList = useSelector((state) => state.outlet.outletList);

    const selectedLangaugeId = useSelector((state) => state.langauge.languageId);

    const [isFilterActive, setIsFilterActive] = useState(false);

    const [availableFilter, setAvailableFilter] = useState({ state: [], city: [] });

    const [allStateCity, setAllStateCity] = useState({
        state: [],
        city: [],
    });

    const [clearCity, setClearCity] = useState(false);

    useEffect(() => {
        const formattedCityState = outletList.reduce((result, item) => {
            const stateName = item.outletData?.outlet_address.translations.state[selectedLangaugeId];

            const cityName = item.outletData?.outlet_address.translations.city[selectedLangaugeId];

            const existingState = result.find((state) => state.state === stateName);

            if (existingState) {
                if (!existingState.city.includes(cityName)) {
                    existingState.city.push(cityName);
                }
            } else {
                result.push({ state: stateName, city: [cityName] });
            }

            return result;
        }, []);

        const sortedStateCity = {
            state: formattedCityState.map((el) => el.state),
            city: [...new Set(formattedCityState.flatMap((item) => item.city))],
        };

        setAllStateCity(formattedCityState);

        setAvailableFilter(sortedStateCity);
        // eslint-disable-next-line
    }, [outletList]);

    const handleSelectState = (value) => {
        const availableCity = value
            ? allStateCity.filter((el) => el.state.toLowerCase() === value?.toLowerCase())[0].city
            : [...new Set(allStateCity.flatMap((item) => item.city))];

        setAvailableFilter((prevState) => {
            return { ...prevState, city: availableCity };
        });

        value && setClearCity(true);

        setAppliedFilter((prevState) => {
            return { ...prevState, state: value };
        });
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='flex flex-row w-full justify-end ml-3 md:ml-0'>
                {isFilterActive && (
                    <div className='-mt-6 flex flex-row w-full justify-end mr-3 md:mt-3 md:justify-start'>
                        <div className='w-full max-w-[200px] mr-3'>
                            <StateCityDropdown
                                label='State'
                                menuItems={availableFilter.state}
                                setFilter={(value) => handleSelectState(value)}
                            />
                        </div>

                        <div className='w-full max-w-[200px]'>
                            <StateCityDropdown
                                label='City'
                                menuItems={availableFilter.city}
                                setFilter={(value) =>
                                    setAppliedFilter((prevState) => {
                                        return { ...prevState, city: value };
                                    })
                                }
                                clearFilter={clearCity}
                                setClearFilter={setClearCity}
                            />
                        </div>
                    </div>
                )}

                <div
                    className={`max-w-[120px] w-full ${isFilterActive ? `md:mt-9 sm:max-w-[50px]` : isMobileScreen && 'mt-4'}`}
                    onClick={() => {
                        setIsFilterActive((prevState) => !prevState);
                    }}>
                    <LargePrimaryButton
                        label='Filters'
                        leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                        leftIconClick={<FilterIcon fill='#C4BEED' />}
                        hideLabel={isFilterActive && 'sm:hidden'}
                    />
                </div>
            </div>
        </>
    );
}
