import React, { useEffect, useState } from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import MethodSection from './Components/MethodSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function PaymentMethod(props) {
    const { fetchSetting, handleDiscardForm } = props;

    const {
        handleSubmit,
        formState: { isDirty, isValid },
        watch,
        setValue,
        reset,
    } = useFormContext();

    const [showFooter, setShowFooter] = useState(false);

    const initialMethod = [
        {
            label: '(Credit/Debit card)',
            placeholder: 'Select multiple providers',
            isRequired: true,
            id: 'payment_methods.CREDIT_CARD/DEBIT_CARD',
            method: 'CREDIT_CARD/DEBIT_CARD',
        },
        {
            label: '(Net banking)',
            placeholder: 'Select multiple providers',
            isRequired: true,
            id: 'payment_methods.NET_BANKING',
            method: 'NET_BANKING',
        },
        {
            label: '(Balance)',
            placeholder: 'Select multiple providers',
            isRequired: true,
            id: 'payment_methods.BALANCE',
            method: 'BALANCE',
        },
        {
            label: '(UPI)',
            placeholder: 'Select multiple providers',
            isRequired: true,
            id: 'payment_methods.UPI',
            method: 'UPI',
        },
        {
            label: '(UPI Intent)',
            placeholder: 'Select multiple providers',
            isRequired: true,
            id: 'payment_methods.UPI_INTENT',
            method: 'UPI_INTENT',
        },
    ];

    const [methods, setMethod] = useState(initialMethod);

    const formatData = (data) => {
        return data['CREDIT_CARD/DEBIT_CARD']?.value === 'paytm'
            ? {
                  action: 'update',
                  payment_provider: 'paytm',
              }
            : {
                  action: 'update',
                  payment_provider: 'stripe',
              };
    };

    const onSubmit = (data) => {
        updatePaymentMethod(formatData(data.payment_methods));

        reArrangeMethod(formatRearrangedData(data.payment_methods));
    };

    const formatRearrangedData = (data) => {
        return {
            action: 'rearrange',
            payment_methods: methods.map((el, index) => ({
                payment_method: el.method,
                display_sequence: index + 1,
                payment_provider: data['CREDIT_CARD/DEBIT_CARD']?.value,
            })),
        };
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const reArrangeMethod = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=payment_methods`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updatePaymentMethod = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=payment_methods`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        watch('payment_methods.sequence') && setMethod(watch('payment_methods.sequence'));
    }, [watch('payment_methods.sequence')]);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setMethod(initialMethod);
                handleDiscardForm();
            }}
            showFooter={isDirty || showFooter}>
            <MethodSection
                methods={methods}
                setMethod={setMethod}
                setShowFooter={setShowFooter}
            />
        </Layout>
    );
}
