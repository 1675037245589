import React from 'react';
import { FooterTab, Header, MobileComponents } from './Components/HomePage';
import Categorylayout from './Components/Categorylayout';
import { PrimaryBanner, SecondaryBanner, TertiaryBanner } from './Components/BannerPageComponents';
import { useFormContext } from 'react-hook-form';

export default function MobileScreenHomeLayout() {
    const { watch } = useFormContext();

    const components = {
        banner_section: <PrimaryBanner />,
        secondary_banner_section: <SecondaryBanner />,
        category_list_section: <Categorylayout isApplyMask={true} />,
        tertiary_banner_section: <TertiaryBanner />,
    };

    return (
        <>
            <div className='mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[661px] w-[319px] shadow-xl sticky top-0 mobile:max-w-[250px] mobile:w-full'>
                <MobileComponents />

                <div className='rounded-[2rem] w-[291px] h-[633px] bg-white px-4 mobile:w-[222px] mobile:px-2 overflow-auto [&::-webkit-scrollbar]:hidden'>
                    <div className='mb-4 sticky top-0 pt-1 bg-white -mx-4 px-4 z-[1]'>
                        <Header />
                    </div>

                    <div className='mb-16'>
                        {watch('appearance.home_screen_layout')?.map((el, index) => (
                            <div
                                className={`${
                                    el !== 'Category_list_section'
                                        ? '-mr-4 overflow-hidden'
                                        : 'border-t-8 mt-4 -mx-4 px-4 border-[#f8f8f8]'
                                } mb-2`}
                                key={index}>
                                {components[el.section_name]}
                            </div>
                        ))}
                    </div>

                    <FooterTab />
                </div>
            </div>
        </>
    );
}
