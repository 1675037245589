import React from 'react';
import { SingleImage } from './HomePage';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import Pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import Pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import Pizza3 from '../../../Assets/MediaLibrary/pizza3.png';
import Pizza4 from '../../../Assets/MediaLibrary/pizza4.png';
import Pizza5 from '../../../Assets/MediaLibrary/pizza5.png';
import Pizza6 from '../../../Assets/MediaLibrary/pizza6.png';
import iceCreame from '../../../Assets/MediaLibrary/icecreame.png';
import cake from '../../../Assets/MediaLibrary/cake.png';

export default function Categorylayout(props) {
    const { layoutArray, categoryTitle, greetingMassage, image, hasMask, hideTile, maskPosition } = props;

    const categoryArray = layoutArray ?? [1, 2, 3, 3];

    const imageData = [
        { title: 'Millano Pizza', image: Pizza1 },
        { title: 'Italian Pizza', image: Pizza2 },
        { title: 'Garlic bread', image: Pizza6 },
        { title: 'Pizza margerita', image: Pizza3 },
        { title: 'Ice creame', image: iceCreame },
        { title: 'Puff pizza', image: Pizza4 },
        { title: 'Garlic Pizza', image: Pizza5 },
        { title: 'cake', image: cake },
    ];

    return (
        <>
            <div className='flex flex-row justify-between text-[11px] mt-2 paragraph-x-small-regular'>
                <div className='max-w-[230px] overflow-hidden whitespace-nowrap text-overflow-ellipsis'>
                    {greetingMassage ?? 'Have something delicious today?'}
                </div>
                <span className='w-5'>
                    <SearchIcon className='w-full h-auto' />
                </span>
            </div>

            <div
                className={`${
                    categoryArray.length > 4 ? 'h-[420px] pb-16' : 'h-[406px]'
                } overflow-auto [&::-webkit-scrollbar]:hidden`}>
                {categoryArray.map((count, index) => (
                    <div
                        key={index}
                        className='flex flex-row items-center mt-1.5'>
                        {Array.from({ length: count }).map((_, subIndex) => (
                            <div
                                key={subIndex}
                                className={`${count === 1 ? 'w-full' : count === 2 ? `w-[128px] h-[128px]` : 'w-[84px] h-[84px]'}  ${
                                    subIndex !== count - 1 && 'mr-[3px]'
                                }`}>
                                <SingleImage
                                    categoryDisplayCount={count}
                                    categoryTitle={categoryTitle ?? imageData[index].title}
                                    image={image ?? imageData[index].image}
                                    hasMask={hasMask}
                                    hideTile={hideTile}
                                    maskPosition={maskPosition}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
}
