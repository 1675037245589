import React, { useEffect, useState } from 'react';
import './CircleLoader.css'; // Import your CSS file

const CircleLoader = ({ isResponseSuccess, isLoading }) => {
    return (
        <div>
            <div className={`circle-loader ${isLoading == false ? 'load-complete' : ''} `}>
                <div
                    className={`checkmark ${isResponseSuccess == false && isLoading == false ? 'error' : ''} ${
                        isResponseSuccess == true && isLoading == false ? 'draw' : ''
                    }`}></div>
            </div>
        </div>
    );
};

export default CircleLoader;
