import React from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import InputSection from '../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import Loader from './Components/Loader';

export default function FoodLicense(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        watch,
        formState: { errors, isDirty, isValid },
        reset,
        setValue,
    } = useFormContext();

    const formatData = (data) => {
        return { licence: data.licence };
    };

    const onSubmit = (data) => {
        updateFoodLicense(formatData(data.food_licence));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateFoodLicense = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=food_licence`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='mb-4'>
                <InputSection
                    title='Food license'
                    description='Enter food license number of your outlet.'
                    rules={{ required: 'Enter license number' }}
                    name='food_licence.licence'
                    shadow='shadow-smallDropDownShadow'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    label='(License number)'
                    placeholder='Enter license number'
                    dontShowError
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                {errors?.food_licence?.licence && <ErrorMessage errors={errors?.food_licence?.licence} />}
            </div>
        </Layout>
    );
}
