import React, { useEffect, useState } from 'react';
import { ReactComponent as OrderRatingIcon } from '../../../Assets/order-ratings.svg';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function ListViewOrderRating(props) {
    const {
        customerName,
        riderRating,
        orderRating,
        orderId,
        orderFeedback,
        riderFeedback,
        orderDate,
        toggleCustomerReviewPopup,
        platform,
        offer,
        setDeleteReviewId,
        id,
        setCreateOfferDetails,
        toggleCustomerOfferPopup,
        fetchOfferDetails,
        setOfferid,
        offerId,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    const getRatingColors = (rating) => {
        const fill = rating > 3 ? '#EBF6F5' : rating === 3 ? '#FFF5E3' : '#FEF2F2';
        const stroke = rating > 3 ? '#3D8C82' : rating === 3 ? '#FFA704' : '#EF4444';
        return { fill, stroke };
    };

    const { fill: orderIconFill, stroke: orderIconStroke } = getRatingColors(orderRating);

    const { fill: riderIconFill, stroke: riderIconStroke } = getRatingColors(riderRating);

    return (
        <>
            <div className='w-full px-4 py-[11px] border border-neutral-300 rounded-md mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className='flex flex-col'>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>CUSTOMER NAME:</h3>
                        {customerName != null ? (
                            <span className='paragraph-small-regular'>{customerName}</span>
                        ) : (
                            <span className='paragraph-small-regular'>---</span>
                        )}
                    </div>
                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <>
                                {orderRating != null ? (
                                    <div className='flex flex-row items-center mr-1'>
                                        <OrderRatingIcon
                                            className='h-5 w-5'
                                            fill={orderIconFill}
                                            stroke={orderIconStroke}
                                        />
                                        <span className='paragraph-small-regular ml-1'>{orderRating}</span>
                                    </div>
                                ) : (
                                    <div className='flex flex-row items-center mr-1'>
                                        <span className='paragraph-small-regular ml-1'>---</span>
                                    </div>
                                )}
                                {riderRating != null ? (
                                    <div className='flex flex-row items-center mr-1'>
                                        <OrderRatingIcon
                                            className='h-5 w-5'
                                            fill={riderIconFill}
                                            stroke={riderIconStroke}
                                        />
                                        <span className='paragraph-small-regular ml-1'>{riderRating}</span>
                                    </div>
                                ) : (
                                    <div className='flex flex-row items-center mr-1'>
                                        <span className='paragraph-small-regular ml-1'>---</span>
                                    </div>
                                )}
                            </>
                        )}

                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <div className='pt-2'>
                        <div className='flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER ID:</span>
                            {orderId != null ? (
                                <span className='paragraph-small-regular ml-1'>{orderId}</span>
                            ) : (
                                <span className='paragraph-small-regular ml-1'>---</span>
                            )}
                        </div>

                        <div className='pt-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER RATING:</span>
                            {orderRating != null ? (
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <OrderRatingIcon
                                        className='h-5 w-5'
                                        fill={orderIconFill}
                                        stroke={orderIconStroke}
                                    />
                                    <span className='ml-1'>{orderRating}</span>
                                </div>
                            ) : (
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <span className='ml-1'>---</span>
                                </div>
                            )}
                        </div>

                        <div className='pt-2 flex flex-row mobile:block'>
                            <span className='paragraph-overline-small text-neutral-700 whitespace-nowrap'>
                                ORDER FEEDBACK:
                            </span>
                            {orderFeedback != null ? (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>{orderFeedback}</div>
                            ) : (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>---</div>
                            )}
                        </div>

                        <div className='pt-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>RIDER RATING:</span>
                            {riderRating != null ? (
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <OrderRatingIcon
                                        className='h-5 w-5'
                                        fill={riderIconFill}
                                        stroke={riderIconStroke}
                                    />
                                    <span className='ml-1'>{riderRating}</span>
                                </div>
                            ) : (
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <span className='ml-1'>---</span>
                                </div>
                            )}
                        </div>

                        <div className='pt-2 flex flex-row mobile:block'>
                            <span className='paragraph-overline-small text-neutral-700 whitespace-nowrap'>
                                RIDER FEEDBACK:
                            </span>
                            {riderFeedback != null ? (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>{riderFeedback}</div>
                            ) : (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>---</div>
                            )}
                        </div>
                        <div className='pt-2 flex flex-row mobile:block'>
                            <span className='paragraph-overline-small text-neutral-700 whitespace-nowrap'>
                                ORDERING PLATFORM:
                            </span>
                            {platform != null ? (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>{platform}</div>
                            ) : (
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>---</div>
                            )}
                        </div>

                        <div className='flex flex-row justify-between mobile:block'>
                            <div className='pt-2 flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>OFFER:</span>
                                {offer != null ? (
                                    <span className='paragraph-small-regular ml-1 text-primary-500'>{'Created'}</span>
                                ) : (
                                    <span className='paragraph-small-regular ml-1'>{'Not Created'}</span>
                                )}
                            </div>
                            {offer !== null ? (
                                <div className='pt-2 flex flex-row justify-between items-center'>
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => {
                                            toggleCustomerOfferPopup();
                                            fetchOfferDetails(offer);
                                        }}>
                                        View Offer details
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <div className='flex flex-row justify-between mobile:block'>
                            <div className='pt-2 flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                                {orderDate != null ? (
                                    <span className='paragraph-small-regular ml-1'>{orderDate}</span>
                                ) : (
                                    <span className='paragraph-small-regular ml-1'>---</span>
                                )}
                            </div>

                            <div className='pt-2 flex flex-row justify-between items-center'>
                                <span
                                    className='paragraph-small-italic text-primary-500 cursor-pointer'
                                    onClick={() => {
                                        toggleCustomerReviewPopup(orderId);
                                        setDeleteReviewId(id);
                                        setCreateOfferDetails((prevState) => ({
                                            ...prevState,
                                            id: orderId,
                                        }));
                                        setOfferid(offerId);
                                    }}>
                                    View full details
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
