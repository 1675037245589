import React from 'react';
import ContentLoader from 'react-content-loader';

const PresetPopupLoader = () => {
    return (
        <ContentLoader
            width='852px'
            height='600px'
            className=' w-fit rounded-md'>
            {/* header */}
            <rect
                x='6'
                y='5'
                rx='4'
                ry='4'
                width='200'
                height='35'
            />
            <rect
                x='380'
                y='5'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='6'
                y='50'
                rx='4'
                ry='4'
                width='300'
                height='20'
            />
            {/* second part */}
            <rect
                x='6'
                y='120'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='140'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />
            {/* first rider line */}
            <rect
                x='6'
                y='200'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='220'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />

            {/* second rider line */}
            <rect
                x='6'
                y='280'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='300'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />

            {/* third rider line */}
            <rect
                x='6'
                y='360'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='380'
                rx='4'
                ry='4'
                width='350'
                height='40'
            />

            {/* fourth rider line */}
            <rect
                x='6'
                y='440'
                rx='4'
                ry='4'
                width='260'
                height='15'
            />
            <rect
                x='6'
                y='460'
                rx='4'
                ry='4'
                width='150'
                height='40'
            />
            <rect
                x='240'
                y='460'
                rx='4'
                ry='4'
                width='150'
                height='40'
            />

            {/* fifth rider line */}
            <rect
                x='6'
                y='530'
                rx='4'
                ry='4'
                width='160'
                height='50'
            />
            <rect
                x='240'
                y='530'
                rx='4'
                ry='4'
                width='160'
                height='50'
            />
            {/* select rider outlet  */}
            <rect
                x='6'
                y='705'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='730'
                rx='4'
                ry='4'
                width='750'
                height='40'
            />
            {/* rider address */}
            <rect
                x='6'
                y='805'
                rx='4'
                ry='4'
                width='160'
                height='15'
            />
            <rect
                x='6'
                y='830'
                rx='4'
                ry='4'
                width='750'
                height='90'
            />
            {/* button */}
            <rect
                x='6'
                y='945'
                rx='4'
                ry='4'
                width='750'
                height='40'
            />
        </ContentLoader>
    );
};

export default PresetPopupLoader;
