import React from 'react';
import {
    MediumDestructiveButton,
    MediumPrimaryButton,
    MediumTertiaryButton,
} from '../../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';
import { ReactComponent as SelectIcon } from '../../../../../../Assets/select.svg';

export default function TableControllerButton(props) {
    const {
        handleClickAddThreshold,
        handleClickEdit,
        handleClickBulkSelect,
        isEditTable,
        isSelectionEnable,
        handleClickDelete,
    } = props;

    return (
        <>
            <div className='flex flex-row w-full mb-4'>
                {!isSelectionEnable ? (
                    <>
                        <div
                            className='max-w-[157px] mr-2 lg:mr-1 md:min-w-[64px] md:w-full cursor-pointer'
                            onClick={handleClickAddThreshold}>
                            <MediumPrimaryButton
                                fontsWeight='font-medium'
                                label='Add threshold'
                                hideLabel='md:hidden'
                                isDefault={false}
                                leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                                leftIconClick={<AddIcon stroke='#C4BEED' />}
                            />
                        </div>
                        <div
                            className='max-w-[156px] mx-2 lg:mx-1 largeTablet:mx-0 md:min-w-[64px] md:w-full'
                            onClick={handleClickEdit}>
                            <MediumPrimaryButton
                                fontsWeight='font-medium'
                                label='Edit threshold'
                                hideLabel='md:hidden'
                                leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                                leftIconClick={<EditIcon stroke='#C4BEED' />}
                                isClicked={isEditTable}
                            />
                        </div>
                        <div
                            className='max-w-[136px] ml-2 lg:ml-1 md:min-w-[64px] md:w-full'
                            onClick={handleClickBulkSelect}>
                            <MediumPrimaryButton
                                fontsWeight='font-medium'
                                label='Bulk select'
                                hideLabel='md:hidden'
                                leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                            />
                        </div>
                    </>
                ) : (
                    <div className='flex flex-row items-center sm:mt-4 md:ml-4 sm:ml-0'>
                        <div
                            className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                            onClick={handleClickBulkSelect}>
                            <MediumTertiaryButton
                                isDefault={false}
                                label='Cancel'
                            />
                        </div>
                        <div
                            className='max-w-[192px] md:max-w-full w-1/2'
                            onClick={() => handleClickDelete()}>
                            <MediumDestructiveButton
                                label='Delete'
                                isDefault={false}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
