import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as LoyaltyCashbackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { format, parse } from 'date-fns';

export default function ListView(props) {
    const { customerName, cashbackEarned, orderDate, orderAmount, cashbackReedemed, setPopupOrderId, orderId } = props;
    const [isShowDetails, setIsShowDetails] = useState(false);

    const formattedDate = (dateString) => {
        const parsedDate = parse(dateString, 'dd-MMM-yyyy', new Date());
        return format(parsedDate, 'dd MMM yyyy');
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={() => setIsShowDetails((prevState) => !prevState)}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>CUSTOMER NAME:</h3>

                        <span className='paragraph-small-regular'>{customerName}</span>
                    </div>

                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center'>
                                <LoyaltyCashbackIcon />
                                <span className='paragraph-small-regular ml-1'>
                                    {cashbackEarned === null
                                        ? cashbackReedemed
                                        : cashbackEarned === null
                                          ? cashbackReedemed
                                          : cashbackEarned}
                                </span>
                            </div>
                        )}

                        <div className={`ml-[5px] ${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER ID:</span>
                            <span className='paragraph-small-regular ml-1'>{orderId}</span>
                        </div>

                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{formattedDate(orderDate)}</span>
                        </div>

                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER AMOUNT:</span>
                            <span className='paragraph-small-regular ml-1'>₹{orderAmount}</span>
                        </div>
                        {cashbackEarned !== null && (
                            <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-overline-small text-neutral-700'>CASHBACK EARNED:</span>
                                    <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                        <LoyaltyCashbackIcon className='h-5 w-5 mr-1' />
                                        {cashbackEarned}
                                    </div>
                                </div>
                                {cashbackEarned !== null && cashbackReedemed === null ? (
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => setPopupOrderId(orderId)}>
                                        View details
                                    </span>
                                ) : null}
                            </div>
                        )}
                        {cashbackReedemed !== null && (
                            <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                                <div className='flex flex-row items-center'>
                                    <span className='paragraph-overline-small text-neutral-700'>
                                        CASHBACK REDEEMED:
                                    </span>
                                    <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                        <LoyaltyCashbackIcon className='h-5 w-5 mr-1' />
                                        {cashbackReedemed}
                                    </div>
                                </div>

                                {cashbackReedemed !== null && cashbackEarned === null ? (
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => setPopupOrderId(orderId)}>
                                        View details
                                    </span>
                                ) : null}
                                {cashbackReedemed !== null && cashbackEarned !== null ? (
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => setPopupOrderId(orderId)}>
                                        View details
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
