import React from 'react';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { timingMenuItem } from '../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';
import AllDaySameTimeSelection from '../../../../Components/Timings/Components/AllDaySameTimeSelection';
import EachDaySeparateTimeSelection from '../../../../Components/Timings/Components/EachDaySeparateTimeSelection';

export default function SelectDurationSection() {
    const {
        watch,
        control,
        setValue,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const handleTimeSettingSelection = (selection) => {
        clearErrors('chooseOneTimeSlot');

        switch (selection.value) {
            case timingMenuItem[1].value:
                setValue('timing', [{ start_time: '', end_time: '' }]);
                break;

            case timingMenuItem[2].value:
                for (let index = 0; index < 7; index++) {
                    setValue(`timing.${index}`, []);
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div>
                <DropdownSection
                    openFromBottom
                    label='Banner timings'
                    menuItems={timingMenuItem}
                    name='timingType'
                    maxWidth='max-w-full'
                    className='relative my-4'
                    handleSelection={(selection) => handleTimeSettingSelection(selection)}
                />
            </div>

            <div className='max-w-[450px] md:max-w-full'>
                {watch('timingType')?.value === timingMenuItem[1].value && (
                    <AllDaySameTimeSelection
                        fieldName='timing'
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        timingTypeName='timingType'
                        openFromBottom={true}
                    />
                )}
            </div>

            <div className='max-w-[450px]'>
                {watch('timingType')?.value === timingMenuItem[2].value && (
                    <EachDaySeparateTimeSelection
                        fieldName='timing'
                        setValue={setValue}
                        control={control}
                        watch={watch}
                        clearErrors={clearErrors}
                        errors={errors}
                        timingTypeName='timingType'
                        limitedSlot={6}
                        openFromBottom={true}
                    />
                )}
            </div>
        </>
    );
}
