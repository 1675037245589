import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { InputField } from '../../Login/Components/Components';
import PhoneInputDropdown from '../../../Components/MobileNumberInputFlagDropDown/Dropdown';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { getOtp, verifyOtp } from './ApiFunctions';
import { countryCodes } from '../../../Components/MobileNumberInputFlagDropDown/CountryData';
import { IsMobileScreen } from '../../../Constants/Constants';
import CircleLoader from '../../../Components/CircleLoader/CircleLoader';

export const ValidateUserPhoneNumber = (props) => {
    const {
        mobileNumber,
        setIsValidInput,
        otp,
        isValidInput,
        countryData,
        setCountryData,
        methods,
        setIsShowMobileNumberPopup,
        loaderStatus,
        setloaderStatus,
        selectedRestaurantId,
        setMobileNumber,
        mobileNumberFIeldName,
        otpFieldName,
        setShowFooter,
    } = props;
    const {
        setValue,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
    } = methods;

    const { isClicked, isLoading, isResponseSuccess } = loaderStatus;

    const oldCountryCode = getValues('country_code') ?? '+' + countryData.code;
    const [isShowInputView, setIsShowInputView] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const isValidMobileNumber = mobileNumber?.length === 10;
    const isValidOtp = otp?.length === 5;

    const handleInput = () => {
        if (!(mobileNumber && isValidMobileNumber)) {
            setError('mobile_number', {
                message: !mobileNumber ? 'Mobile Number is required' : 'Please Enter Valid Mobile Number',
            });
            return;
        }
        if (isShowInputView && !(otp && isValidOtp)) {
            setError('mobile_number', {
                message: !otp ? 'OTP is required' : 'Please Enter Valid OTP',
            });
            return;
        }
        if (!isShowInputView) {
            otpGetFunc();
        } else {
            verifyOtp({
                mobileNumber,
                countryCode: `+${countryData?.code}`,
                otp,
                setError,
                setloaderStatus,
                setIsShowMobileNumberPopup,
                selectedRestaurantId,
            }).then((response) => {
                if (response?.success) {
                    setIsValidInput(response?.success);
                    setValue('validatedNumber', response?.phone_details?.mobile_number);
                    setValue(otpFieldName, '');
                    clearErrors('mobile_number');
                    clearErrors('validatedNumber');
                    setShowFooter?.(true);
                    setIsShowMobileNumberPopup(false);
                    setMobileNumber(response?.phone_details?.id, getValues(mobileNumberFIeldName));
                }
            });
        }
    };
    const otpGetFunc = () => {
        getOtp({
            mobileNumber,
            countryCode: `+${countryData.code}`,
            setError,
            clearErrors,
            setloaderStatus,
            setIsShowInputView,
            selectedRestaurantId,
        }).then((response) => {
            setSeconds(30);
        });
    };
    const handleClose = () => {
        clearErrors('mobile_number');
        setIsShowMobileNumberPopup(false);
        setCountryData(countryCodes.find((country) => '+' + country?.code == oldCountryCode));
        setValue('mobileNumber', getValues('validatedNumber'));
        setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
    };
    const handleBack = () => {
        clearErrors('mobile_number');
        setCountryData(countryCodes.find((country) => '+' + country?.code == oldCountryCode));
        setIsShowInputView(false);
        setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: false });
    };

    useEffect(() => {
        if (isShowInputView && seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [seconds, isShowInputView]);
    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex p-4 md:p-0 md:absolute md:z-[9] overflow-auto md:overflow-visible'>
            <div className='max-w-[630px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:mt-0 md:w-full md:p-4 lg:max-w-[439px] md:max-w-full md:rounded-none'>
                {IsMobileScreen() && (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={isShowInputView ? handleBack : handleClose}>
                        <LeftArrow className='rotate-90' />
                        <span className='pl-1'>Back to {isShowInputView ? 'Mobile Number' : 'user list'}</span>
                    </div>
                )}
                <div className='flex flex-row justify-between items-center border-neutral-300 mb-6 lg:mb-4'>
                    <div className='flex flex-row'>
                        {!IsMobileScreen() && isShowInputView && (
                            <div
                                className='cursor-pointer'
                                onClick={handleBack}>
                                <LeftArrow className='rotate-90' />
                            </div>
                        )}
                        <span className='paragraph-large-medium'>Validate User</span>
                    </div>

                    <div
                        onClick={handleClose}
                        className='md:hidden cursor-pointer'>
                        <CloseIcon />
                    </div>
                </div>

                <div className='max-w-[480px] relative mb-16 lg:block md:mb-2'>
                    <InputField
                        inputValue={!isShowInputView ? mobileNumber : otp}
                        setValue={setValue}
                        showMobileIcon={isShowInputView}
                        isMobileNumberPage={!isShowInputView}
                        isValidInput={isValidInput}
                        setIsValidInput={setIsValidInput}
                        paddingLeft={!isShowInputView ? 'pl-[105px]' : null}
                        clearErrors={clearErrors}
                        mobileNumberFIeldName={mobileNumberFIeldName}
                        otpFieldName={otpFieldName}
                    />
                    {!isShowInputView && (
                        <div
                            className={`phoneinputbox absolute ${
                                errors?.mobile_number ? 'bottom-8' : 'bottom-3'
                            } left-4`}>
                            <PhoneInputDropdown
                                value={countryData.code}
                                setCountryData={setCountryData}
                                countryData={countryData}
                            />
                        </div>
                    )}
                    {isShowInputView && (
                        <div className='flex flex-row mt-3'>
                            {seconds > 0 && <p>Resend OTP in {seconds} seconds</p>}
                            <button
                                className={`phoneinputbox pl-2 text-neutral-400 ${
                                    seconds == 0 && 'text-primary-500 cursor-pointer'
                                } cursor-default`}
                                onClick={() => seconds == 0 && otpGetFunc()}
                                disabled={seconds != 0}>
                                Resend OTP
                            </button>
                        </div>
                    )}
                    {errors?.mobile_number && <ErrorMessage errors={errors?.mobile_number} />}
                </div>
                <div className='flex flex-row justify-between float-right md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'>
                    <div
                        className='min-w-[164px] cursor-pointer'
                        onClick={() => handleInput()}>
                        <LargePrimaryButton
                            label={
                                isClicked ? (
                                    <CircleLoader
                                        isLoading={isLoading}
                                        isResponseSuccess={isResponseSuccess}
                                    />
                                ) : isShowInputView ? (
                                    'Proceed'
                                ) : (
                                    'Next'
                                )
                            }
                            isDefault={false}
                            // disabled={mobileNumber == watch('validatedNumber')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
