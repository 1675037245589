import { days } from '../../../Constants/Constants';
import { ErrorMessage } from '../../Error/ErrorMessage';
import SingleSeparateSlotSection from './SingleSeparateSlotSection';

export default function EachDaySeparateTimeSelection(props) {
    const { errors, subField } = props;

    return (
        <>
            {days.map((el, index) => (
                <div
                    className='mb-6'
                    key={index}>
                    <SingleSeparateSlotSection
                        dayIndex={index}
                        dayLabel={el}
                        {...props}
                    />
                </div>
            ))}

            {subField
                ? errors?.[subField]?.chooseOneTimeSlot && <ErrorMessage errors={errors[subField].chooseOneTimeSlot} />
                : errors?.chooseOneTimeSlot && <ErrorMessage errors={errors.chooseOneTimeSlot} />}
        </>
    );
}
