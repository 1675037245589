import React from 'react';
import { useFormContext } from 'react-hook-form';
import DropDownUnableDisableSection from '../../../../Components/DropDownUnableDisableSection';
import LargeTitleSwitch from '../../../../../../Components/ToggleSwitch/LargeTitleSwitch';
import CashbackReemSection from './CashbackReemSection';

export default function AdvanceSetting() {
    const {
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const handleToggleAdvanceSettting = (value) => {
        setValue('cashback_settings.sameAdvanceConfiguration', true);
        !value &&
            watch('orderingModes')?.map((el, index) => {
                setValue(`cashback_settings.cashback_redeem_percentage.${index}`, 0);
            });
    };

    const handleToggleSameConfig = () => {
        clearErrors([
            'cashback_settings.same_cashback_redeem_percentage',
            'cashback_settings.cashback_redeem_percentage',
        ]);
    };

    return (
        <>
            <LargeTitleSwitch
                classNames='mb-4'
                title='Advance settings'
                description='Enable or disable advance cashback settings for your mobile application and set the parameters for cashback.'
                switchName='cashback_settings.advanceSettingEnable'
                handleToggle={handleToggleAdvanceSettting}
            />

            <LargeTitleSwitch
                switchName='cashback_settings.sameAdvanceConfiguration'
                disabled={!watch('cashback_settings.advanceSettingEnable')}
                title='Same configuration for all ordering modes'
                classNames='mb-4 max-w-[417px] md:max-w-full'
                handleToggle={handleToggleSameConfig}
            />

            <CashbackReemSection />

            <div className='pb-6 mb-6 border-b border-neutral-300'>
                <DropDownUnableDisableSection
                    name='cashback_settings.promo_consolidation'
                    title='Combine offers'
                    description='Combine other offers with the cashback.'
                    errors={errors}
                    disabled={!watch('cashback_settings.advanceSettingEnable')}
                />
            </div>
        </>
    );
}
