import React from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import '../../Styles/customizeStyles.css';
import '../../Styles/reactDatepicker.css';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';
import { validityMenuItem } from './constants';

export default function ValiditySection() {
    const { watch, setValue } = useFormContext();

    const today = new Date();

    const adjustedToDate = watch('to_date') < watch('from_date') ? watch('from_date') : watch('to_date');

    const handleSelectValidity = (selection) => {
        if (selection.value === validityMenuItem[1].value) {
            setValue('from_date', today);
            setValue('to_date', today);
        }
    };

    return (
        <>
            <DropdownSection
                className='mb-[13px] relative'
                label='Banner validity'
                boxHeight='h-[44px]'
                name='validity'
                menuItems={validityMenuItem}
                maxWidth='max-w-full'
                handleSelection={(selection) => handleSelectValidity(selection)}
            />

            {watch('validity')?.value !== 'always' && (
                <div className='flex flex-row items-center'>
                    <div className='mr-2.5 max-w-[196px] w-full'>
                        <DatePicker
                            selected={watch('from_date')}
                            onChange={(date) => setValue('from_date', date)}
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='dd MMM yyyy'
                            showIcon
                            icon={<CalenderIcon />}
                            showPopperArrow={false}
                            minDate={today}
                        />
                    </div>

                    <div className='ml-2.5 max-w-[196px] w-full'>
                        <DatePicker
                            selected={adjustedToDate}
                            onChange={(date) => setValue('to_date', date)}
                            timeIntervals={15}
                            timeCaption='time'
                            dateFormat='dd MMM yyyy'
                            showIcon
                            icon={<CalenderIcon />}
                            minDate={watch('from_date')}
                            popperPlacement='bottom-end'
                            showPopperArrow={false}
                        />
                    </div>
                </div>
            )}
        </>
    );
}
