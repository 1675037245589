import React from 'react';
import VariantWiseTable from './Components/VariantWiseTable';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';

export default function LinkAddOnGroup() {
    return (
        <>
            <TitleDescription
                className='mb-4'
                title='Link add-on groups'
                description='By linking add-on groups to the dish you can offer customization options to the customers. You can define it as mandatory or optional for the customers too.'
            />

            <VariantWiseTable />
        </>
    );
}
