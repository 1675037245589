import React from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';

export default function LogoutPopup(props) {
    const { handleLogout, handleClickClose } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 md:max-w-[343px]'>
                    <h2 className='paragraph-large-medium'>Logout?</h2>

                    <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3 mt-3'>
                        Would you like to logout?
                    </p>

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300 cursor-pointer'>
                            Cancel
                        </button>

                        <div
                            className='w-1/2 md:ml-[7.5px] cursor-pointer'
                            onClick={() => handleLogout(null)}>
                            <LargePrimaryButton label='Logout' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
