import React, { useState } from 'react';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';
import { ReactComponent as SelectIcon } from '../../../../../../Assets/select.svg';
import Table from './Table';
import BadgePopup from './BadgePopup';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import ConfirmDeletePopup from '../../../../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import { getBadgeList, removeBadge } from '../../../../Components/Catalogue/functions';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

export default function BadgeLibrary() {
    const [showBadgePopup, setShowBadgePopup] = useState(false);

    const { setValue } = useFormContext();

    const togglePopup = (value) => {
        setShowBadgePopup(!showBadgePopup);
        setEditBadgeDetails(value);
    };

    const [isEditable, setIsEditTable] = useState(false);

    const [editBadgeDetails, setEditBadgeDetails] = useState({ title: null, color: null });

    const [isSelection, setIsSelection] = useState(false);

    const [selectedBadge, setSelectedBadge] = useState([]);

    const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const setBadgeList = async () => {
        const response = await getBadgeList();

        const formattedBadges = response?.badges?.map((el) => ({
            label: el?.translations?.display_name?.[selectedLanguage],
            style: { background: el.color },
            className: 'px-2 py-1 text-white rounded w-fit paragraph-small-regular',
            value: el,
        }));

        setValue('availableBadge', formattedBadges);
    };

    const handleDelete = async () => {
        const params = {
            badges_ids: selectedBadge,
        };

        try {
            const response = await removeBadge(params);

            if (response?.success) {
                setShowConfirmDeletePopup(false);
                setIsSelection(false);
                setSelectedBadge([]);
                setBadgeList();
            }
        } catch (error) {
            console.log('error : ', error);

            setShowConfirmDeletePopup(false);
        }
    };

    const handleBadgeSelection = (badgeId, isSelected) => {
        setSelectedBadge((prevState) => {
            if (isSelected) {
                return [...prevState, badgeId];
            } else {
                const updatedBadgeList = prevState.filter((el) => el !== badgeId);
                return updatedBadgeList;
            }
        });
    };

    return (
        <>
            <TitleDescription
                className='mb-4'
                title='Badge Library'
                description='Create a new badge or edit an existing one.'
            />

            {isSelection ? (
                <div className='flex flex-row items-center sm:mt-4 md:ml-4 sm:ml-0 mb-4'>
                    <div
                        className='max-w-[125px] md:max-w-full w-1/2 mr-4 xl:mr-2 cursor-pointer'
                        onClick={() => setIsSelection(false)}>
                        <LargeTertiaryButton
                            isDefault={false}
                            label='Cancel'
                        />
                    </div>
                    <div
                        className='max-w-[192px] md:max-w-full w-1/2'
                        onClick={() => setShowConfirmDeletePopup(true)}>
                        <LargeDestructiveButton
                            disabled={!selectedBadge?.length}
                            label='Delete'
                            isDefault={false}
                        />
                    </div>
                </div>
            ) : (
                <div className='flex flex-row mb-4'>
                    <div
                        className='mr-4 md:min-w-[64px] md:mr-2 cursor-pointer'
                        onClick={() => togglePopup()}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Add badge'
                            hideLabel='lg:hidden'
                            leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                        />
                    </div>
                    <div
                        className='mr-4 md:mr-2 md:min-w-[64px] cursor-pointer'
                        onClick={() => setIsEditTable(!isEditable)}>
                        <LargePrimaryButton
                            label='Edit badge'
                            leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden'
                        />
                    </div>

                    <div
                        className='md:min-w-[64px]'
                        onClick={() => setIsSelection(true)}>
                        <LargePrimaryButton
                            label='Bulk select'
                            leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                            leftIconClick={<SelectIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden'
                        />
                    </div>
                </div>
            )}

            <Table
                isEditable={isEditable}
                togglePopup={togglePopup}
                isSelection={isSelection}
                selectedBadge={selectedBadge}
                onSelection={handleBadgeSelection}
            />

            {showBadgePopup && (
                <BadgePopup
                    setBadgeList={setBadgeList}
                    handleClickClose={togglePopup}
                    editBadgeDetails={editBadgeDetails}
                />
            )}

            {showConfirmDeletePopup && (
                <ConfirmDeletePopup
                    handleClickClose={() => setShowConfirmDeletePopup(false)}
                    header={<h2 className='paragraph-large-medium ml-2'>Delete selected badge?</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Would you like to delete selected badge?
                        </p>
                    }
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
}
