import React from 'react';
import { SingleLoader } from '../ContentLoader/DropdownLoader';

export default function CropperLoader() {
    return (
        <>
            <SingleLoader className='max-w-[1024px] h-[500px]' />
        </>
    );
}
