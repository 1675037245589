import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { DefaultInputField } from '../../../../../../Components/InputField/InputField';
import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import PickColor from '../../../../../../Components/ColorPicker/PickColor';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import CancelButton from '../../../../../../Components/Buttons/CancelButton';
import { addBadge, editBadge } from '../../../../Components/Catalogue/functions';
import { useSelector } from 'react-redux';

export default function BadgePopup(props) {
    const { handleClickClose, editBadgeDetails, setBadgeList } = props;

    const [isLoading, setIsLoading] = useState(false);

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const header = !editBadgeDetails?.isEdit
        ? { title: 'Create new badge', tagline: 'Name your badge and select a color for it.' }
        : { title: 'Edit existing badge', tagline: 'Rename your badge or select different color' };

    const isMobileScreen = IsMobileScreen();

    const createBadge = async () => {
        const params = {
            color: selectedColor,
            internal_name: title,
            translations: {
                display_name: {
                    [selectedLanguage]: title,
                },
            },
        };

        try {
            const response = await addBadge(params);

            if (response?.success) {
                handleClickClose();
                setIsLoading(false);
                setBadgeList();
            }
        } catch (error) {
            console.log('error : ', error);
            setIsLoading(false);
        }
    };

    const updateBadge = async () => {
        const params = {
            color: selectedColor,
            internal_name: title,
            translations: {
                display_name: {
                    [selectedLanguage]: title,
                },
            },
        };

        try {
            const response = await editBadge(editBadgeDetails?.badge?.value?.id, params);

            if (response?.success) {
                handleClickClose();
                setIsLoading(false);
                setBadgeList();
            }
        } catch (error) {
            console.log('error : ', error);
            setIsLoading(false);
        }
    };

    const handleClickSave = () => {
        setIsLoading(true);

        editBadgeDetails?.isEdit ? updateBadge() : createBadge();
    };

    const [title, setTitle] = useState('');

    const [selectedColor, setSelectedColor] = useState(editBadgeDetails?.isEdit ? editBadgeDetails?.color : '#FFFFFF');

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md:block md:overflow-hidden'>
                <div className='max-w-[427px] w-full rounded-xl bg-shades-50 m-auto px-8 py-6 md:px-4 md:rounded-none md:py-4 md:h-full md:max-w-full'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to add dish</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between pb-6 items-center'>
                        <div className=''>
                            <div className='paragraph-large-medium'>{header.title}</div>
                            <div className='paragraph-medium-italic text-neutral-500'>{header.tagline}</div>
                        </div>

                        {!isMobileScreen && (
                            <span
                                onClick={() => handleClickClose()}
                                className='cursor-pointer'>
                                <CloseIcon />
                            </span>
                        )}
                    </div>

                    <div className='mb-6 md:max-w-[240px]'>
                        <DefaultInputField
                            value={editBadgeDetails?.title}
                            label='Badge Title'
                            placeholder='Enter badge title'
                            labelStyle='paragraph-large-medium'
                            labelMarginB='mb-2'
                            boxHeight='[52px]'
                            shadow='shadow-small-drop-down-shadow'
                            enteredValue={setTitle}
                        />
                    </div>

                    <PickColor
                        selectedColor={selectedColor}
                        setSelectedColorFromPopup={setSelectedColor}
                    />

                    <div className='flex flex-row items-center mt-6 w-full md:fixed md:bottom-0 md:bg-slate-50 md:-ml-4 md:px-4 md:pb-1 md:pt-2 md:shadow-dropShadow'>
                        <div className='w-1/2 mr-2'>
                            <CancelButton />
                        </div>
                        <div
                            className='w-1/2 ml-2'
                            onClick={handleClickSave}>
                            <LargePrimaryButton
                                isLoading={isLoading}
                                label={editBadgeDetails?.isEdit ? 'Edit badge' : 'Add badge'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
