import React from 'react';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { ReactComponent as DropdownIcon } from '../../../Assets/chevron-down.svg';
import ReservationCard from './ReservationCard';

const ReservationItem = (props) => {
    const { reservationList, showReservationList, setShowPopup } = props;

    return (
        <>
            <div className='h-full xl:h-auto overflow-auto scrollbar-style -ml-1 -mr-8 pl-1 pr-[26px] md:pl-0 md:pr-2.5 md:ml-0 md:-mr-4'>
                {reservationList.map((reservation, index) => (
                    <div
                        key={index}
                        className='border-b border-neutral-300 pb-6 mb-6'>
                        <div className='flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center'>
                                <CalenderIcon />
                                <span className='paragraph-large-regular ml-4'>{reservation.date}</span>
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={() => showReservationList(index)}>
                                <DropdownIcon className={`${reservation.showList && 'rotate-180'}`} />
                            </div>
                        </div>

                        {reservation.showList &&
                            reservation.reservations.map((el, index) => (
                                <div
                                    className='mt-4'
                                    key={index}>
                                    <ReservationCard
                                        {...el}
                                        isWaiting={!!(index % 2)}
                                        setShowPopup={setShowPopup}
                                    />
                                </div>
                            ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ReservationItem;
