import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TableControllerButton from './Components/TableControllerButton';
import APIV2 from '../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import Layout from './Components/Layout';
import TableSection from './Components/TableSection';
import LinkOutletPopup from './Components/LinkOutletPopup/LinkOutletPopup';
import { fetchOutletwiseOffer } from './Components/functions';
import LinkOfferPopup from './Components/LinkOfferPopup/LinkOfferPopup';
import { IsMobileScreen } from '../../Constants/Constants';
import OfferDetailsPopup from './Components/OfferDetailsPopup/OfferDetailsPopup';
import LeftFlyOut from '../../Components/LeftFlyOut/LeftFlyOut';

export default function Offers() {
    const { tab } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [outletwiseOffersList, setOutletwiseOffersList] = useState([]);

    const [restaurantOfferList, setRestaurantOfferList] = useState([]);

    const [activeState, setActiveState] = useState({ selection: false, edit: false, reArrange: false });

    const [selectedOffer, setSelectedOffer] = useState([]);

    const [appliedFilter, setAppliedFilter] = useState({ is_offer_active: null, offer_type: null, keyword: null });

    const [isListLoading, setIsListLoading] = useState(false);

    const [showLinkOfferPopup, setShowLinkOfferPopup] = useState(false);

    const [selectedOfferId, setSelectedOfferId] = useState(null);

    const initialPageState = {
        restaurantPageState: { page: 1, per_page: 10, last_page: null },
        outletPageState: { page: 1, per_page: 10, last_page: null },
    };

    const [pageState, setPageState] = useState(initialPageState);

    const [linkOfferId, setLinkOfferId] = useState(null);

    const linkedOutletId = restaurantOfferList.find((el) => el.id === linkOfferId)?.linked_outlets;

    const fetchOutletWiseOffersList = async (param) => {
        setIsListLoading(true);

        const params = {
            page: pageState.outletPageState.page,
            per_page: pageState.outletPageState.per_page,
            ...param,
        };

        const response = await fetchOutletwiseOffer(params, selectedRestaurantId, selectedOutletId);

        setOutletwiseOffersList(response.offers.data);

        setIsListLoading(false);

        setNewPageState(response, 'outletPageState');
    };

    const setNewPageState = (response, page) => {
        pageState[page].last_page !== response.offers.last_page &&
            setPageState((prevState) => {
                return { ...prevState, [page]: { ...prevState[page], last_page: response.offers.last_page } };
            });
    };

    const fetchRestaurantOfferList = async (param) => {
        setIsListLoading(true);

        const params = {
            page: pageState.restaurantPageState.page,
            per_page: pageState.restaurantPageState.per_page,
            ...appliedFilter,
            ...param,
        };

        try {
            const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/offers`, {
                params,
            });

            setRestaurantOfferList(response.offers.data);

            setIsListLoading(false);

            setNewPageState(response, 'restaurantPageState');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!selectedOutletId) return;

        fetchRestaurantOfferList();

        fetchOutletWiseOffersList();

        // eslint-disable-next-line
    }, [selectedOutletId]);

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <Layout
                showMainLayout={
                    !(
                        isMobileScreen &&
                        (!!linkOfferId || showLinkOfferPopup || selectedOfferId || activeState.reArrange)
                    )
                }
                setActiveState={setActiveState}
                setShowLinkOfferPopup={setShowLinkOfferPopup}>
                <TableControllerButton
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    activeState={activeState}
                    setActiveState={setActiveState}
                    setSelectedOffer={setSelectedOffer}
                    selectedOffer={selectedOffer}
                    setAppliedFilter={setAppliedFilter}
                    restaurantOfferList={restaurantOfferList}
                    setRestaurantOfferList={setRestaurantOfferList}
                    isDisableButtons={tab === 'offer-list' ? !restaurantOfferList.length : !outletwiseOffersList.length}
                    setPageState={setPageState}
                />

                <TableSection
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    list={{ restaurantOfferList, outletwiseOffersList }}
                    activeState={activeState}
                    pageState={pageState}
                    setPageState={setPageState}
                    selectedOffer={selectedOffer}
                    setSelectedOffer={setSelectedOffer}
                    isListLoading={isListLoading}
                    setLinkOfferId={setLinkOfferId}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    setActiveState={setActiveState}
                    setIsListLoading={setIsListLoading}
                    setSelectedOfferId={setSelectedOfferId}
                />
            </Layout>

            {!!linkOfferId && (
                <LinkOutletPopup
                    handleClickClose={() => setLinkOfferId((prevState) => !prevState)}
                    linkedOutletId={linkedOutletId}
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    linkOfferId={linkOfferId}
                />
            )}

            {showLinkOfferPopup && (
                <LinkOfferPopup
                    list={restaurantOfferList}
                    activeState={activeState}
                    isListLoading={isListLoading}
                    setPageState={setPageState}
                    fetchRestaurantOfferList={fetchRestaurantOfferList}
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    pageState={pageState}
                    handleClickClose={() => setShowLinkOfferPopup(false)}
                />
            )}

            {selectedOfferId && (
                <OfferDetailsPopup
                    handleClickClose={() => setSelectedOfferId(null)}
                    selectedOfferId={selectedOfferId}
                />
            )}

            {activeState.reArrange && (
                <LeftFlyOut
                    fetchOutletWiseOffersList={fetchOutletWiseOffersList}
                    handleClickClose={() =>
                        setActiveState((prevState) => {
                            return { ...prevState, reArrange: false };
                        })
                    }
                />
            )}
        </>
    );
}
