import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as DownArrow } from '../../Assets/chevron-down.svg';
import AvatarImage from '../../Assets/avatar-image.png';
import UserHeaderIcon from '../Avatar/AvatarsIcon';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DropDownAvatar = (props) => {
    const { widthIcon, heightIcon, setShowLogoutPopup } = props;

    const user = useSelector((state) => state.auth.user);

    const oo = [{ label: user?.name, value: user }];

    const menuItems = props.menuItems ? props.menuItems : oo;

    const [menuButton, setMenuButton] = useState(oo[0]);

    const handleClickLogout = () => {
        setShowLogoutPopup((prevState) => !prevState);
    };

    const availableUsers = useSelector((state) => state.auth.availableUsers);

    const navigate = useNavigate();

    const handleAddUser = () => {
        navigate('/login');
    };

    return (
        <>
            <div className='relative pl-4'>
                <Menu
                    as='div'
                    className='flex dropdown-icon'>
                    <Menu.Button className='flex items-center focus:outline-none remove-shadow'>
                        <UserHeaderIcon
                            widthOuter='10'
                            heightOuter='10'
                            imageIcon={AvatarImage}
                            colorIcon='[#22C55E]'
                            widthIcon='[14px]'
                            heightIcon='[14px]'
                        />
                        <div>
                            <label className='paragraph-medium-medium truncate block w-full xl:max-w-[140px] largeTablet:max-w-[75px]'>
                                {menuButton?.name}
                            </label>
                            <div className='paragraph-x-small-italic text-neutral-500 text-left'>Caption</div>
                        </div>
                        <DownArrow
                            className='drop-down-icon-rotate ml-2'
                            width={`${widthIcon}`}
                            height={`${heightIcon}`}
                        />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute right-0 w-max mt-12 px-4 py-2 border border-neutral-300 rounded-md shadow-medium bg-shades-50 focus:outline-none'>
                            {/* {availableUsers?.map((el, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='py-1.5 cursor-pointer'
                                        onClick={() => {
                                            setMenuButton(el);
                                        }}>
                                        <Menu.Item>
                                            <div className='paragraph-x-small-regular leading-normal'>{el?.name}</div>
                                        </Menu.Item>
                                    </div>
                                );
                            })} */}

                            <div
                                className='py-1 paragraph-x-small-regular cursor-pointer text-primary-500'
                                onClick={handleAddUser}>
                                + Add new user
                            </div>

                            <div
                                className='py-1 paragraph-x-small-regular cursor-pointer text-destructive-500'
                                onClick={handleClickLogout}>
                                Logout
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
};

export default DropDownAvatar;
