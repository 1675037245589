import React from 'react';
import { useFormContext } from 'react-hook-form';
import TwoInputAreaSection from '../../Components/TwoInputAreaSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../api/axios/APIV5';

export default function AboutUs(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateAboutUsSetting = async (params) => {
        try {
            const response = await APIV5.put(`/restaurants/${selectedRestaurantId}?section=legal`, { ...params });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = (data) => {
        const formattedData = {};

        data.translations.return_policy.forEach((value, index) => {
            if (value !== null) {
                formattedData[index.toString()] = value;
            }
        });

        updateAboutUsSetting({ translations: { return_policy: formattedData } });
    };

    return (
        <TwoInputAreaSection
            showFooter={isDirty}
            title='Return policy'
            description="Write about your return policy for the customer's orders."
            placeholder='Enter return policy in'
            name='return_policy'
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => handleDiscardForm()}
            isLoading={watch('isLoadingForm')}
        />
    );
}
