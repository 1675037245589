import React from 'react';
import { Tab } from '../../../../../../Components/Tabs/Tabs';
import { useNavigate, useParams } from 'react-router-dom';

export default function Tabs() {
    const { subTab } = useParams();

    const navigate = useNavigate();

    const tabData = [
        { label: 'Rider settings', link: 'rider' },
        { label: 'Auto-assign riders', link: 'auto-assign' },
    ];

    const handleNavigation = (route) => {
        navigate(`/settings/store/rider-setting/${route}`);
    };

    return (
        <>
            <div className='flex flex-row items-center mb-6 lg:mb-4'>
                {tabData.map((el, index) => (
                    <div
                        className={`${
                            index === 0
                                ? 'mr-2 sm:mr-1 md:w-1/2 sm:w-full'
                                : 'sm:w-full md:w-1/2 ml-2 sm:ml-0 min-w-[160px]'
                        } cursor-pointer`}
                        key={index}
                        onClick={() => handleNavigation(el.link)}>
                        <Tab
                            label={el.label}
                            isActive={subTab === el.link}
                            labelStyle='sm:text-[15px]'
                        />
                    </div>
                ))}
            </div>
        </>
    );
}
