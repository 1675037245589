import React, { useState } from 'react';
import CalenderField from '../../../Components/Calender/CalenderField';
import { format } from 'date-fns';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as ExportIcon } from '../../../Assets/export.svg';
import { useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';

export default function CalenderExportData(props) {
    const { setSelectedDates, selectedDates, creditType } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isDisable, setIsDisable] = useState(false);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    let data;

    const handleClickExportData = async () => {
        setIsDisable(true);
        const [from_date, to_date] = selectedDates?.sort((a, b) => a - b);

        if (creditType === 'infrastructure') {
            data = {
                type: 'infrastructure_credit_report',
                start_date: formatDate(from_date),
                end_date: formatDate(to_date),
                restaurant_id: selectedRestaurantId,
            };
        } else if (creditType === 'service') {
            data = {
                type: 'service_credit_report',
                start_date: formatDate(from_date),
                end_date: formatDate(to_date),
                outlet_id: selectedOutletId,
            };
        }
        try {
            const response = await APIV3.post(`export-report`, data);
            if (response.success === true) {
                setIsDisable(false);
            } else {
                setIsDisable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className='flex md:block justify-between mb-6 md:mb-0'>
                <div className='md:mb-4'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>
                <div className='w-full flex justify-between md:mb-3'>
                    <div className='w-[156px] mx-4 lg:mx-2 md:ml-0 md:w-full mobile:mr-1'></div>
                    <div
                        className='w-[161px] md:ml-2 md:w-full mobile:ml-1'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            isDefault={false}
                            label='Export data'
                            leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            disabled={isDisable}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
