import React from 'react';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { BlankComponentTable } from '../../../../../../Components/BlankComponent/BlankComponent';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TableRow from './TableRow';
import { useFormContext } from 'react-hook-form';

export default function TablesComponent(props) {
    const { isRearrange } = props;

    const isMobileScreen = IsMobileScreen();

    const { watch, setValue } = useFormContext();

    const selectedDish = watch('upSelling.selectedDish')?.map((el) => ({ ...el, id: el?.value?.item_id }));

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const updateSequence = (active, over) => {
        // Get the current index of the active item and the over item
        const oldIndex = selectedDish.findIndex((item) => item.id === active.id);
        const newIndex = selectedDish.findIndex((item) => item.id === over.id);

        const newSelectedDish = arrayMove(selectedDish, oldIndex, newIndex);

        setValue(`upSelling.selectedDish`, newSelectedDish);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            updateSequence(active, over);
        }
    };

    return (
        <>
            {!isMobileScreen && (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mb-4'>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToParentElement,
                            restrictToFirstScrollableAncestor,
                        ]}>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[231px] paragraph-overline-small text-neutral-700'>
                                        DISH CATEGORY
                                    </th>

                                    <th className='px-6 min-w-[263px] paragraph-overline-small text-neutral-700'>
                                        DISH NAME
                                    </th>
                                </tr>
                            </thead>

                            {selectedDish?.length ? (
                                <tbody>
                                    <SortableContext
                                        items={selectedDish}
                                        strategy={verticalListSortingStrategy}
                                        disabled={!isRearrange}>
                                        {selectedDish?.map((el, index) => {
                                            return (
                                                <TableRow
                                                    {...el}
                                                    isRearrange={isRearrange}
                                                    key={el.id}
                                                    index={index}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </tbody>
                            ) : (
                                <BlankComponentTable colSpan={2} />
                            )}
                        </table>
                    </DndContext>
                </div>
            )}
        </>
    );
}
