import ContentLoader from 'react-content-loader';

export default function InputAreaLoader(props) {
    const { hasLabel, height, className } = props;

    return (
        <ContentLoader
            width='100%'
            height='100%'
            className={`h-[130px] w-full ${className}`}>
            {hasLabel && (
                <rect
                    x='0'
                    y='0'
                    rx='4'
                    ry='4'
                    width='120'
                    height='20'
                />
            )}
            <rect
                x='0'
                y={hasLabel ? '32' : 0}
                rx='4'
                ry='4'
                width='100%'
                height={height ?? '112'}
            />
        </ContentLoader>
    );
}
