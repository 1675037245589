import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';
import { countryCodes } from './CountryData';
import { ReactComponent as IndiaFlagIcon } from './FlagIcons/IN.svg';

export default function PhoneInputDropdown(props) {
    const { label, labelStyle, labelPaddingB, disabled, openFromBottom } = props;

    const [countryData, setCountryData] = useState({
        fullName: 'India',
        flag: (
            <IndiaFlagIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
        code: '91',
    });

    const handleClickMenuItem = (el) => {
        setCountryData(el);
    };

    const [searchInput, setSearchInput] = useState(''); // State for search input
    const filteredCountryCodes = countryCodes.filter((el) =>
        el.fullName.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };
    return (
        <>
            {label && (
                <div
                    className={`${labelStyle ?? 'paragraph-small-medium'} ${labelPaddingB ?? 'pb-1'} ${
                        disabled && 'text-neutral-300'
                    }`}>
                    {label}
                </div>
            )}
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button className='flex flex-row items-center remove-shadow'>
                            {countryData.flag}
                            <span className='paragraph-small-regular mx-1'>+{countryData.code}</span>
                            <DownArrowIcon
                                className='drop-down-icon-rotate'
                                height={18}
                                width={18}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute h-80 w-60 overflow-auto -left-4 top-[30px] bottom-0 right-0 mt-2 pl-4 pr-1 py-2 border paragraph-small-medium rounded-md shadow-mdiumedium bg-shades-50 font-normal z-10 ${
                                openFromBottom && 'bottom-full mb-3.5 top-auto'
                            }`}>
                            <div className='w-full pt-2 mb-2 flex flex-row items-center'>
                                <SearchIcon />
                                <input
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                    placeholder='Search country code'
                                    className='ml-2 placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full'
                                />
                            </div>
                            <div className='h-[260px] w-full overflow-auto'>
                                {filteredCountryCodes.map((el, index) => {
                                    return (
                                        <div
                                            className='pt-2 mb-2 cursor-pointer pr-4'
                                            key={index}
                                            onClick={() => handleClickMenuItem(el)}>
                                            <Menu.Item>
                                                <span>{el.fullName}</span>
                                            </Menu.Item>
                                        </div>
                                    );
                                })}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
