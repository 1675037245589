import React from 'react';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';

export default function TableHeader(props) {
    const {
        isRestaurantPage,
        linkOfferToOutletSection,
        linkPopup,
        selectedOffer,
        isActiveSelection,
        list,
        setSelectedOffer,
    } = props;

    const handleSelectAll = (isChecked) => {
        const allOffer = list.map((el) => {
            return el.id;
        });

        isChecked ? setSelectedOffer(allOffer) : setSelectedOffer([]);
    };

    return (
        <>
            <thead className=''>
                <tr className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 h-11'>
                    {linkPopup && <th className='text-left pl-6 shadow-innerShadow min-w-[165px]'>ACTIVE STATUS</th>}

                    {isRestaurantPage && (
                        <th
                            className={`text-left min-w-[100px] pl-6 shadow-innerShadow ${
                                linkPopup ? 'min-w-[165px]' : 'min-w-[100px]'
                            }`}>
                            <span>{linkPopup ? 'LINKING STATUS' : 'STATUS'}</span>
                        </th>
                    )}

                    <th
                        className={`text-left shadow-innerShadow relative ${
                            isRestaurantPage ? 'min-w-[192px]' : 'min-w-[230px]'
                        } ${isActiveSelection ? 'pl-16' : 'pl-6'}`}>
                        {isActiveSelection && (
                            <div className='absolute left-6'>
                                <CheckBoxWithoutLabels
                                    onChange={(isChecked) => {
                                        handleSelectAll(isChecked);
                                    }}
                                    isChecked={list.every((el) => !!selectedOffer.includes(el.id))}
                                />
                            </div>
                        )}
                        COUPON CODE
                    </th>
                    <th
                        className={`text-left pl-6 shadow-innerShadow ${
                            isRestaurantPage ? 'min-w-[195px]' : 'min-w-[226px]'
                        }`}>
                        OFFER TYPE
                    </th>
                    <th
                        className={`text-left pl-6 shadow-innerShadow ${
                            isRestaurantPage ? 'min-w-[228px]' : 'min-w-[262px]'
                        }`}>
                        TITLE
                    </th>
                    <th className='text-left min-w-[383px] pl-6 shadow-innerShadow'>DESCRIPTION</th>
                    {!linkOfferToOutletSection && (
                        <th className='text-left min-w-[160px] pl-6 shadow-innerShadow'>
                            {isRestaurantPage ? 'LINK OUTLET' : 'UNLINK OFFER'}
                        </th>
                    )}
                </tr>
            </thead>
        </>
    );
}
