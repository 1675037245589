import React, { useState } from 'react';
import SearchableDropdownWithWindowing from '../../../../Components/SearchableDropdownWithWindowing/SearchableDropdownWithWindowing';
import { getUsers } from './ApiFunctions';
import { useSelector } from 'react-redux';

export default function Filters(props) {
    const {
        setAppliedFilter,
        presetList,
        handlePagination,
        selectedPage,
        isEditUser,
        handleArrayState,
        setloaderStatus,
    } = props;
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleSelection = (selection, key) => {
        setAppliedFilter((prevState) => {
            return { ...prevState, [key]: selection.value };
        });
        getUsers({ handleArrayState, keyword: selection, setloaderStatus, selectedRestaurantId });
    };
    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);
    return (
        <section className='flex md:block items-center justify-end mb-6 md:mb-4'>
            <div className='flex sm:block'>
                <div className='w-[150px] md:w-1/2 sm:w-full mr-2 sm:mr-0 sm:mb-3 md:mr-1'>
                    <SearchableDropdownWithWindowing
                        menuItems={[
                            {
                                label: 'all',
                                value: '',
                            },
                            ...(presetList?.data
                                ? presetList.data.map((item) => ({
                                      label: item.name,
                                      value: item.name,
                                  }))
                                : []),
                        ]}
                        fetchNewData={handlePagination}
                        isLoadingMenuItems={isLoadingMenuItems}
                        pageState={{ lastPage: presetList?.last_page, page: selectedPage }}
                        handleSelect={handleSelection}
                        defaultValue={'all'}
                        showDisplayValue={true}
                        placeholder={!isEditUser && 'Enter user role'}
                        label='User Role'
                        setIsLoadingMenuItems={setIsLoadingMenuItems}
                    />
                </div>
            </div>
        </section>
    );
}
