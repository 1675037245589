import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as FlipIcon } from '../../Assets/flip-vertical.svg';
import { ReactComponent as RotateIcon } from '../../Assets/rotate.svg';
import { ReactComponent as UndoIcon } from '../../Assets/undo.svg';
import SaveButtonContainer from './SaveButtonContainer';
import { Cropper } from 'react-advanced-cropper';
import { LargePrimaryButton } from '../Buttons/Button';
import 'react-advanced-cropper/dist/style.css';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import CropperLoader from './CropperLoader';
import { useWindowSize } from '@uidotdev/usehooks';

export default function EditImageContainer(props) {
    const { handleClickClose } = props;

    const cropperRef = useRef(null);

    const { setValue, watch } = useFormContext();

    const [imageSize, setImageSize] = useState({});

    const [isEnableEditingButton, setIsEnableEditingButton] = useState(false);

    const [undoRedoState, setUndoRedoState] = useState({ undo: [], redo: [] });

    useEffect(() => {
        getImageSize();

        // eslint-disable-next-line
    }, []);

    const { height: screenHeight, width: screenWidth } = useWindowSize();

    const imageContainerSize = {
        height: screenHeight * 0.6,
        width:
            screenWidth < 768
                ? screenWidth - 32
                : screenWidth < 1124
                  ? 647
                  : screenWidth < 1190
                    ? screenWidth - 128
                    : 1059,
    };

    const editButton = [
        {
            label: 'Flip horizontal',
            leftIconDefault: (
                <FlipIcon
                    stroke='#FFFFFF'
                    className='rotate-90'
                />
            ),
            leftIconClick: (
                <FlipIcon
                    stroke='#C4BEED'
                    className='rotate-90'
                />
            ),
            onClick: () => onFlip(true, false),
        },
        {
            label: 'Flip vertical',
            leftIconDefault: <FlipIcon stroke='#FFFFFF' />,
            leftIconClick: <FlipIcon stroke='#C4BEED' />,
            onClick: () => onFlip(false, true),
        },
        {
            label: 'Rotate left',
            leftIconDefault: <RotateIcon stroke='#FFFFFF' />,
            leftIconClick: <RotateIcon stroke='#C4BEED' />,
            onClick: () => onRotate(-90),
        },
        {
            label: 'Rotate right',
            leftIconDefault: (
                <RotateIcon
                    stroke='#FFFFFF'
                    className='-scale-x-100'
                />
            ),
            leftIconClick: (
                <RotateIcon
                    stroke='#C4BEED'
                    className='-scale-x-100'
                />
            ),
            onClick: () => onRotate(90),
        },
    ];

    const getImageDimensions = (base64Data) => {
        return new Promise((resolve) => {
            const img = new Image();

            img.onload = () => {
                resolve({ width: img.naturalWidth, height: img.naturalHeight });
            };

            img.src = base64Data;
        });
    };

    const getImageSize = async () => {
        const size = await getImageDimensions(watch('originalImage.image_url'));

        setImageSize(size);
    };

    const getZoomingRatio = () => {
        let zoomingRation;

        switch (true) {
            case imageSize.width < imageContainerSize.width && imageSize.height > imageContainerSize.height:
                zoomingRation = imageSize.width / imageSize.height;
                break;

            case imageSize.width < imageContainerSize.width:
                zoomingRation = imageSize.height / imageSize.width;
                break;

            case imageSize.width > imageContainerSize.width:
                zoomingRation = imageContainerSize.height / imageSize.width;
                break;

            default:
                zoomingRation = 1;
                break;
        }

        return zoomingRation;
    };

    const onRotate = (angle) => {
        cropperRef.current?.rotateImage(angle);

        const zoomingRation = getZoomingRatio();

        cropperRef.current.zoomImage(zoomingRation);

        const stateObj = { option: `rotate ${angle}`, state: cropperRef.current.getState() };

        setUndoRedoState((prevState) => {
            return { undo: [...prevState.undo, stateObj], redo: [] };
        });
    };

    const onFlip = (horizontal, vertical) => {
        cropperRef.current?.flipImage(horizontal, vertical);

        const stateObj = {
            option: `flip ${horizontal ? 'horizontal' : 'verical'}`,
            state: cropperRef.current.getState(),
        };

        setUndoRedoState((prevState) => {
            return { undo: [...prevState.undo, stateObj], redo: [] };
        });
    };

    const handleClickSave = () => {
        const imageUrl = cropperRef.current.getCanvas()?.toDataURL();

        setValue('editingImage', { image_url: imageUrl });

        setValue('imageUrlToDisplay', imageUrl);

        const croppedCanvas = cropperRef.current.getCanvas();

        const croppedWidth = croppedCanvas.width;
        const croppedHeight = croppedCanvas.height;

        setValue('croppedDimensions', {
            width: croppedWidth,
            height: croppedHeight,
        });

        setValue('is_dirty', true);

        handleClickClose();
    };

    const handleEdit = (editFunction) => {
        const defaultState = cropperRef?.current?.getDefaultState();

        !undoRedoState.undo.length &&
            setUndoRedoState(() => {
                return { redo: [], undo: [{ option: 'default', state: defaultState }] };
            });

        editFunction();
    };

    const handleUndo = () => {
        const { undo, redo } = undoRedoState;

        const currentState = undo.pop();

        const stateToSet = undo[undo.length - 1];

        const redoState = [currentState, ...redo];

        setUndoRedoState({ undo: undo, redo: redoState });

        cropperRef?.current.setState(stateToSet.state);
    };

    const handleRedo = () => {
        const { undo, redo } = undoRedoState;

        const currentState = redo.shift();

        const undoState = [...undo, currentState];

        setUndoRedoState({ undo: undoState, redo: redo });

        cropperRef?.current.setState(currentState.state);
    };

    return (
        <>
            <div className='flex flex-row mb-6'>
                {editButton.map((el, index) => (
                    <div
                        className='mr-4'
                        key={index}
                        onClick={() => handleEdit(el?.onClick, el.label.toLowerCase())}>
                        <LargePrimaryButton
                            {...el}
                            disabled={!isEnableEditingButton}
                            hideLabel='lg:hidden'
                            isDefault={false}
                        />
                    </div>
                ))}
            </div>
            {!isEnableEditingButton && <CropperLoader />}

            <div className={`${!isEnableEditingButton ? 'hidden' : 'block'}`}>
                {watch('originalImage.image_url') && !_.isEmpty(imageSize) && (
                    <Cropper
                        stencilProps={{
                            aspectRatio: watch('aspectRatio'),
                            handlers: {
                                eastNorth: true,
                                north: true,
                                westNorth: true,
                                west: true,
                                westSouth: true,
                                south: true,
                                eastSouth: true,
                                east: true,
                            },
                        }}
                        ref={cropperRef}
                        className='cropper'
                        backgroundClassName='bg-white crop-image-bg'
                        src={watch('originalImage.image_url')}
                        style={{
                            ...imageSize,
                            maxWidth: imageContainerSize.width,
                            maxHeight: imageContainerSize.height,
                            background: 'white',
                        }}
                        onReady={() => setIsEnableEditingButton(true)}
                    />
                )}
            </div>

            <div className='flex flex-row relative w-full mt-6'>
                <div className='flex flex-row'>
                    <div
                        className='mr-4 max-w-[114px]'
                        onClick={handleUndo}>
                        <LargePrimaryButton
                            label='Undo'
                            leftIconDefault={<UndoIcon stroke='#FFFFFF' />}
                            leftIconClick={<UndoIcon stroke='#C4BEED' />}
                            isDefault={false}
                            disabled={undoRedoState.undo.length <= 1}
                        />
                    </div>

                    <div
                        className='max-w-[112px]'
                        onClick={handleRedo}>
                        <LargePrimaryButton
                            label='Redo'
                            leftIconDefault={
                                <UndoIcon
                                    stroke='#FFFFFF'
                                    className='-scale-x-100'
                                />
                            }
                            leftIconClick={
                                <UndoIcon
                                    stroke='#C4BEED'
                                    className='-scale-x-100'
                                />
                            }
                            isDefault={false}
                            disabled={undoRedoState.redo.length === 0}
                        />
                    </div>
                </div>
            </div>

            <div
                className='absolute right-8'
                style={{ bottom: 24 }}>
                <SaveButtonContainer
                    isEditing
                    handleClickSave={handleClickSave}
                    handleClickClose={handleClickClose}
                />
            </div>
        </>
    );
}
