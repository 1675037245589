import React from 'react';
import CouponLimit from './Components/CouponLimit';
import UsageNumber from './Components/UsageNumber';
import OrderCount from './Components/OrderCount';
import DatePickerSection from './Components/DatePickerSection';
import OrderingModeSection from './Components/OrderingModeSection';
import { useFormContext } from 'react-hook-form';
import HideOnExhastSection from '../BasicDetails/Components/HideOnExhastSection';

export default function ValidityAndApplicability() {
    const { watch } = useFormContext();

    return (
        <>
            <div className='mb-6'>
                <CouponLimit />
            </div>

            <div className='mb-6'>
                <UsageNumber />
            </div>

            {(!!watch('has_coupon_limit')?.value || !!watch('has_usage_number')?.value) && (
                <div className='mb-6'>
                    <HideOnExhastSection />
                </div>
            )}

            <div className='mb-6'>
                <OrderCount />
            </div>

            {watch('offer_type')?.value !== 'free_delivery' && (
                <div className='mb-6'>
                    <OrderingModeSection />
                </div>
            )}

            <DatePickerSection />
        </>
    );
}
