import React from 'react';
import { SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <SingleLoader className='max-w-[160px] h-[20px] md:max-w-full' />

                <SingleLoader className='max-w-[636px] h-[75px] mt-3' />

                <SingleLoader className='max-w-[80px] h-[24px] mt-3' />
                <SingleLoader className='max-w-[312px] h-[45px] mt-3' />

                <SingleLoader className='max-w-[80px] h-[24px] mt-3' />
                <SingleLoader className='max-w-[312px] h-[45px] mt-3' />

                <SingleLoader className='max-w-[160px] h-[20px] md:max-w-full mt-5' />

                <SingleLoader className='max-w-[636px] h-[75px] mt-3' />

                <SingleLoader className='max-w-[120px] h-[24px] mt-3' />
                <SingleLoader className='max-w-[312px] h-[45px] mt-3' />

                <SingleLoader className='max-w-[636px] h-[75px] mt-3' />

                <SingleLoader className='max-w-[312px] h-[45px] mt-3' />
            </div>
        </>
    );
}
