import React, { useEffect, useState } from 'react';
import MobileScreenAddCategoryDish from '../../../../Components/MobileViewScreen/MobileScreenAddCategoryDish';
import { IsMobileScreen, preferenceMenuItem, vegNonVegMenuItem } from '../../../../Constants/Constants';
import InputSection from '../../../../Components/InputField/InputSection';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';
import InputAreaSection from '../../../Setting/Components/InputAreaSection';
import UploadImagePopup from '../../../../Components/UploadImagePopup/UploadImagePopup';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import DetailsDropDown from '../../Components/Catalogue/Components/DetailsDropDown';
import { useWindowSize } from '@uidotdev/usehooks';
import _ from 'lodash';
import SelectImageFromLibraryPopup from '../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { fetchCategoryList } from '../../Components/Catalogue/functions';
import DropdownPagination from '../../../../Components/DropDown/DropdownPagination';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';

export default function BasicDetails(props) {
    const { setShowHeaderTab } = props;

    const screenSize = useWindowSize();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);

    const [showSelectFromLibraryPopup, setShowSelectFromLibraryPopup] = useState(false);

    const {
        setValue,
        formState: { errors },
        watch,
        control,
    } = useFormContext();

    const isMobileScreen = IsMobileScreen();

    const toggleUploadImagePopup = () => {
        setShowUploadImagePopup((prevState) => !prevState);
        setShowHeaderTab((prevState) => !prevState);
    };

    const languageMenuItem = availableLanguages?.map((el) => ({ label: el.language_label, value: el }));

    const [selectedLanguageForMobile, setSelectedLanguageForMobile] = useState(languageMenuItem[0]);

    const formateImageDetails = (imageDetails) => {
        const img = new Image();

        img.src = imageDetails.image_url;

        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;

            const calculatedWidth = 150 * aspectRatio;

            setValue('details.imageDetail', imageDetails);

            setValue('details.imageDetail.size', { width: calculatedWidth, height: 150 });
        };
    };

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const [paginationState, setPaginationState] = useState(initialPaginationState);

    const [categories, setCategories] = useState([]);

    const fetchCategory = async (params) => {
        try {
            const category = await fetchCategoryList(selectedRestaurantId, params);

            const formateCategory = category.data.map((el) => ({ label: el.internal_name, value: el }));

            setCategories((prevState) => [...prevState, ...formateCategory]);

            setPaginationState((prevState) => ({
                ...prevState,
                page: params.page,
                has_more_pages: category.has_more_pages,
            }));

            return true;
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        fetchCategory(initialPaginationState);
    }, []);

    const formateSubCategoryMenuItem = () => {
        return (
            watch('details.category.value.sub_categories')?.map((el) => ({ label: el.internal_name, value: el })) ?? []
        );
    };

    const handleSelectCategory = (category, fieldChange) => {
        fieldChange(category);

        setValue('details.subCategory', null);
    };

    return (
        <>
            {(!isMobileScreen || !showUploadImagePopup) && (
                <div className='flex flex-row justify-between xl:block xl:mb-0'>
                    <div
                        className='min-w-[661px] overflow-auto scrollbar-style pb-6 pageContent:min-w-[600px] pageContentSmall:min-w-min pr-6 border-r border-neutral-300 w-full xl:pr-0 xl:pb-2 xl:border-r-0 xl:border-b xl:mb-6 xl:min-w-full md:pb-0 pl-1 -ml-1'
                        style={{ height: screenSize.height - 165 }}>
                        <TitleDescription
                            className='mb-4'
                            title='Basic details'
                            description='Enter the basic details of the dish that you would like to create. The created dish will be reflected in the mobile application and the POS.'
                        />

                        <TitleDescription
                            className='mb-4'
                            title='Dish image'
                            description='Select a dish image that will be displayed within the category in the mobile application and the POS.'
                        />

                        {watch('details.imageDetail')?.image_url ? (
                            <div className='flex mb-6 gap-4 items-center'>
                                <img
                                    src={watch('details.imageDetail.image_url')}
                                    alt=''
                                    height={watch('details.imageDetail.size.height')}
                                    width={watch('details.imageDetail.size.width')}
                                />
                                <div className='justify-center items-center'>
                                    <div
                                        className='mb-2'
                                        onClick={() => setShowUploadImagePopup((prevState) => !prevState)}>
                                        <LargeTertiaryButton
                                            label='Replace image'
                                            isDefault={false}
                                        />
                                    </div>

                                    <div
                                        className='mb-2'
                                        onClick={() => setShowSelectFromLibraryPopup((prevState) => !prevState)}>
                                        <LargePrimaryButton
                                            label='Select from library'
                                            isDefault={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-row paragraph-medium-regular mb-4 md:block'>
                                <button
                                    className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full mr-2 md:mr-0 md:mb-2 md:block'
                                    onClick={toggleUploadImagePopup}>
                                    Upload an image
                                </button>
                                <button
                                    className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full ml-2 md:ml-0 md:block'
                                    onClick={() => setShowSelectFromLibraryPopup(true)}>
                                    Select from library
                                </button>
                            </div>
                        )}

                        <DropdownSection
                            menuItems={vegNonVegMenuItem}
                            name='details.food_type'
                            title='Food type'
                            className='mb-4'
                            marginBetween='mb-2'
                            placeholder='Please select a food type'
                        />

                        <DropdownSection
                            menuItems={preferenceMenuItem}
                            name='details.show_agmark'
                            title='Show agmark'
                            className='mb-4'
                            marginBetween='mb-2'
                            placeholder='Please select a food type'
                            description='Select preference to show agmark on this dish'
                        />

                        <TitleDescription
                            className='mb-4'
                            title='Category'
                            description='Select a category into which the dish will be created. The dish wil be displayed within the category in the mobile application and the POS.'
                        />

                        <div className='w-full md:max-w-full max-w-[312px] mb-4'>
                            <Controller
                                render={({ field }) => (
                                    <DropdownPagination
                                        label='(Select category)'
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                        placeholder='Enter city in english'
                                        shadow='shadow-smallDropDownShadow'
                                        menuItems={categories}
                                        fixedHeight='h-[200px]'
                                        fetchMenuItems={fetchCategory}
                                        setPaginationState={setPaginationState}
                                        paginationState={paginationState}
                                        selectedItem={field.value}
                                        setSelected={(selection) => handleSelectCategory(selection, field.onChange)}
                                    />
                                )}
                                name='details.category'
                                control={control}
                            />
                            {errors?.details?.category && <ErrorMessage errors={errors?.details.category} />}
                        </div>

                        {!!watch('details.category.value.total_sub_categories') && (
                            <DropdownSection
                                menuItems={formateSubCategoryMenuItem()}
                                name='details.subCategory'
                                title='Sub Category'
                                className='mb-4'
                                placeholder='Please select a sub category'
                                description='Select a sub category into which the dish will be created. The dish wil be displayed within the sub category in the mobile application and the POS.'
                            />
                        )}

                        <div className='mb-4'>
                            <InputSection
                                title='Nick name - title'
                                description='Select an nick name for the dish which will be used to access the dish information across the software application.'
                                name='details.nickname'
                                placeholder='Enter nickname'
                                rules={{ required: 'Please enter a nickname for the dish' }}
                            />
                            {errors?.details?.nickname && <ErrorMessage errors={errors?.details.nickname} />}
                        </div>

                        <TitleDescription
                            className='mb-4'
                            title='Display name'
                            description='Select a display name for the dish which will be used to display the dish information across the mobile application and POS.'
                        />
                        <div className='flex flex-row items-start mb-6 lg:block gap-3'>
                            {availableLanguages.map((el, index) => (
                                <div
                                    className='w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full md:mr-0'
                                    key={index}>
                                    <InputSection
                                        label={`(${el.language_label})`}
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                        placeholder={`Enter display name in ${el.language_label}`}
                                        shadow='shadow-smallDropDownShadow'
                                        name={`details.display.${el.id}`}
                                        rules={{ required: `Please enter display name in ${el.language_label}` }}
                                    />
                                    {errors?.details?.display?.[el.id] && (
                                        <ErrorMessage errors={errors?.details?.display?.[el.id]} />
                                    )}
                                </div>
                            ))}
                        </div>
                        <TitleDescription
                            className='mb-4'
                            title='Dish description'
                            description='Select a dish description name for the dish which will be used to display the dish information across the mobile application and POS.'
                        />
                        <div className='flex flex-row items-start mb-4 md:block gap-3'>
                            {availableLanguages.map((el, index) => (
                                <div
                                    className='w-1/2 lg:w-full lg:mb-2 lg:max-w-[312px] md:max-w-full md:mr-0'
                                    key={index}>
                                    <InputAreaSection
                                        label={`(${el.language_label})`}
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                        placeholder={`Enter description name in ${el.language_label}`}
                                        shadow='shadow-smallDropDownShadow'
                                        name={`details.description.${el.id}`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='w-full flex justify-center'>
                        <div className='flex flex-col gap-4 items-center h-full'>
                            <MobileScreenAddCategoryDish
                                isNonVeg={watch('details.food_type')?.value === vegNonVegMenuItem[1]?.value}
                                dishImg={watch('details.imageDetail.image_url')}
                                categoryTitle={watch('details.category.label')}
                                renderPageOnMobileScreen='category'
                                dishTitle={watch(`details.display.${selectedLanguageForMobile?.value?.id}`)}
                                dishDetails={watch(`details.description.${selectedLanguageForMobile?.value?.id}`)}
                                dontShowAgmark={!watch('details.show_agmark').value}
                            />
                            <DetailsDropDown
                                menuItem={languageMenuItem}
                                handleChangeDropDown={(selection) => setSelectedLanguageForMobile(selection)}
                            />
                        </div>
                    </div>
                </div>
            )}

            {showUploadImagePopup && (
                <UploadImagePopup
                    handleClickClose={toggleUploadImagePopup}
                    backToPage='add dish'
                    aspectRatio={1}
                    setUploadedImageDetails={(imageDetails) => formateImageDetails(imageDetails)}
                    selectedType={{ label: 'Items', value: 'items' }}
                />
            )}

            {showSelectFromLibraryPopup && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibraryPopup(false)}
                    handleSelectImage={(imageDetails) => formateImageDetails(imageDetails)}
                />
            )}
        </>
    );
}
