import React from 'react';
import { ReactComponent as DeliveryIcon } from '../../../../Assets/riders.svg';
import { ReactComponent as DinInIcon } from '../../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../../Assets/orders.svg';
import { ReactComponent as UPIIcon } from '../../../../Assets/upi.svg';
import { ReactComponent as CashIcon } from '../../../../Assets/cash.svg';
import { ReactComponent as CardIcon } from '../../../../Assets/card.svg';
import { OrderStatus } from '../Components/TableComponent';
import ListviewCustomerDetails from '../Components/ListviewCustomerDetails';
import ContentControllerSection from '../Components/ContentControllerSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';

export default function FailedOrder(props) {
    const { setShowPopup, isMobileScreen } = props;

    const orderTypeIcon = (orderingMode) => {
        return orderingMode === 'Delivery' ? (
            <DeliveryIcon />
        ) : orderingMode === 'Dine-in' ? (
            <DinInIcon />
        ) : (
            <TakeAwayIcon />
        );
    };

    const paymentModeIcon = (paymentMode) => {
        return paymentMode === 'UPI' ? <UPIIcon /> : paymentMode === 'Cash' ? <CashIcon /> : <CardIcon />;
    };

    const pastOrderDetails = [
        {
            customerName: 'Riddhi Shah',
            orderDate: '18 Nov 2022',
            orderType: 'Dine-In',
            orderStatus: ['Delivered'],
            itemOrdered: '12',
            orderAmount: '₹5,325.00/-',
            paymentMode: 'Cash',
        },
        {
            customerName: 'Riddhi Shah',
            orderDate: '18 Nov 2022',
            orderType: 'Dine-In',
            orderStatus: ['Cancelled', 'Refund initiated'],
            itemOrdered: '12',
            orderAmount: '₹5,325.00/-',
            paymentMode: 'UPI',
        },
        {
            customerName: 'Riddhi Shah',
            orderDate: '18 Nov 2022',
            orderType: 'Dine-In',
            orderStatus: ['Cancelled', 'Refund success'],
            itemOrdered: '12',
            orderAmount: '₹5,325.00/-',
            paymentMode: 'Card',
        },
    ];

    return (
        <>
            <ContentControllerSection />

            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                    <table className='w-full break-words'>
                        <thead>
                            <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                                <th className='min-w-[234px] pl-6 text-left'>CUSTOMER NAME</th>
                                <th className='min-w-[174px] pl-6 text-left'>ORDER DATE</th>
                                <th className='min-w-[166px] pl-6 text-left'>ORDER TYPE</th>
                                <th className='min-w-[191px] pl-6 text-left'>ORDER STATUS</th>
                                <th className='min-w-[163px] pl-6 text-left'>ITEMS ORDERED</th>
                                <th className='min-w-[162px] pl-6 text-left'>ORDER AMOUNT</th>
                                <th className='min-w-[180px] pl-6 text-left'>PAYMENT MODE</th>
                            </tr>
                        </thead>

                        <tbody>
                            {pastOrderDetails?.map((el, index) => {
                                const orderIcon = orderTypeIcon(el.orderingMode);
                                const paymentIcon = paymentModeIcon(el.paymentMode);

                                return (
                                    <tr
                                        key={index}
                                        className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'>
                                        <td
                                            className='pl-6 cursor-pointer'
                                            onClick={() => setShowPopup('orderDetails')}>
                                            {el.customerName}
                                        </td>

                                        <td className='pl-6'>{el.orderDate}</td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {orderIcon}
                                                <span className='pl-1'> {el.orderType}</span>
                                            </div>
                                        </td>

                                        <td className='pl-6'>
                                            <OrderStatus status={el.orderStatus} />
                                        </td>

                                        <td className='pl-6'>{el.itemOrdered}</td>
                                        <td className='pl-6'>{el.orderAmount}</td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {paymentIcon}
                                                <span className='pl-1'>{el.paymentMode}</span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className='mt-3'>
                    {pastOrderDetails?.map((el, index) => {
                        return (
                            <div
                                className='mb-1 pt-1'
                                key={index}>
                                <ListviewCustomerDetails
                                    {...el}
                                    handleClickViewDetails={() => setShowPopup('orderDetails')}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
            <div className='mt-4'>
                <PaginationWithNumber />
            </div>
        </>
    );
}
