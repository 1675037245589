import React from 'react';
import { LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as WhatsappIcon } from '../../../Assets/Whatsapp.svg';
import { ReactComponent as EmailIcon } from '../../../Assets/email.svg';
import { ReactComponent as MessangerIcon } from '../../../Assets/Messenger.svg';
import { ReactComponent as TelegramIcon } from '../../../Assets/Telegram.svg';

export const LinkSharePopup = ({ isLinkShare, setIsLinkShare, deeplink, index }) => {
    const openMailClient = () => {
        const subject = encodeURIComponent('Subject of the email');
        const body = encodeURIComponent(deeplink);
        window.open(`mailto:?subject=${subject}&body=${body}`);
    };
    const openMessengerWithText = () => {
        const messengerUrl = 'https://www.messenger.com/';
        const encodedText = encodeURIComponent(deeplink);
        const messengerWithTextUrl = `${messengerUrl}?text=${encodedText}`;
        window.open(messengerWithTextUrl, '_blank');
    };
    const openTelegramWithText = () => {
        const messengerUrl = 'https://t.me/share/url?url=';
        const encodedText = encodeURIComponent(deeplink);
        const messengerWithTextUrl = `${messengerUrl}$text=${encodedText}`;
        window.open(messengerWithTextUrl, '_blank');
    };
    const links = [
        {
            linkName: 'WhatsApp',
            Icon: () => {
                return (
                    <WhatsappIcon
                        width={20}
                        height={20}
                    />
                );
            },
            onClick: () => window.open(`https://web.whatsapp.com/send?text=${deeplink}`, '_blank', 'noreferrer'),
        },
        {
            linkName: 'Email',
            Icon: () => {
                return (
                    <EmailIcon
                        width={20}
                        height={20}
                    />
                );
            },
            onClick: () => openMailClient(),
        },
        {
            linkName: 'Messanger',
            Icon: () => {
                return (
                    <MessangerIcon
                        width={20}
                        height={20}
                    />
                );
            },
            onClick: () => openMessengerWithText(),
        },
        {
            linkName: 'Telegram',
            Icon: () => {
                return (
                    <TelegramIcon
                        width={20}
                        height={20}
                    />
                );
            },
            onClick: () => openTelegramWithText(),
        },
    ];
    const handleLinkShareClick = (index) => {
        const updatedLinkShareStates = [...isLinkShare];
        updatedLinkShareStates[index] = !isLinkShare[index];
        setIsLinkShare(updatedLinkShareStates);
    };
    return (
        <div className='relative'>
            <div
                className='cursor-pointer '
                onClick={() => handleLinkShareClick(index)}>
                <LargeTertiaryButton label='Share' />
            </div>

            {isLinkShare[index] && (
                <div className='border flex flex-col border-neutral-300 z-[50] rounded-lg p-2 absolute top-[54px] bg-neutral-50 w-full overflow-auto'>
                    {links.map((icon, index) => {
                        return (
                            <div
                                key={index}
                                onClick={icon.onClick}
                                className='flex flex-row items-center text-center mt-1 hover:bg-primary-100 hover:rounded-lg cursor-pointer'>
                                {icon?.Icon()}
                                <div className='cursor-pointer ml-5 paragraph-small-regular'>{icon.linkName}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
