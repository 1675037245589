import React from 'react';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TableRows from './TableRows';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import TableLoader from '../../../../../../Components/ContentLoader/TableLoader';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

export default function Table(props) {
    const {
        isRearrange,
        orderingMode,
        setShowFooter,
        rearrangeOrderingMode,
        setRearrangeOrderingMode,
        setShowTabChangeWarningPopup,
    } = props;

    const { setValue } = useFormContext();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const orderingModeList = useSelector((state) => state.outlet.orderingModes);

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            const modes = rearrangeOrderingMode;

            const oldIndex = modes.findIndex((item) => item.id === active.id);
            const newIndex = modes.findIndex((item) => item.id === over.id);

            const updatedValue = arrayMove(modes, oldIndex, newIndex);

            setRearrangeOrderingMode(updatedValue);

            setShowFooter(true);

            setShowTabChangeWarningPopup(true);
        }
    }

    const formatType = (id) => {
        return orderingModeList.find((el) => el.id === id)?.type;
    };

    const formatTab = (id) => {
        return orderingModeList.find((el) => el.id === id)?.tab;
    };

    const handleToggleOrderingMode = (index, status) => {
        const orderingModes = [
            {
                id: 729,
                outlet_id: 24,
                restaurant_ordering_mode_id: 148,
                status: true,
                time_slot_setting: 'no_time_slots',
                display_rank: 1,
                is_enabled: false,
                is_available: true,
                is_processing: 0,
                timeslots: [],
                display_name: { 1: 'Dine In', 2: 'In Store' },
            },
            {
                id: 730,
                outlet_id: 24,
                restaurant_ordering_mode_id: 149,
                status: true,
                time_slot_setting: 'no_time_slots',
                display_rank: 1,
                is_enabled: true,
                is_available: null,
                is_processing: 0,
                timeslots: [],
                display_name: { 1: 'Dine In', 2: 'In Store' },
            },
            {
                id: 241,
                outlet_id: 24,
                restaurant_ordering_mode_id: 146,
                status: true,
                time_slot_setting: 'different_time_slots_for_different_days',
                display_rank: 2,
                is_enabled: true,
                is_available: true,
                is_processing: 0,
                timeslots: [
                    {
                        id: 40091,
                        start_time: '00:30',
                        end_time: '01:30',
                        module_name: 'outlet_ordering_mode',
                        module_id: 241,
                        day_of_week: 1,
                    },
                ],
                display_name: { 1: 'Delivery', 2: 'Delivery' },
            },
            {
                restaurant_ordering_mode_id: 233,
                status: true,
                display_rank: 3,
                is_enabled: true,
                is_available: null,
                is_processing: 0,
                timeslots: [],
                display_name: { 1: 'Dine In', 2: 'test 1' },
            },
        ];

        const orderingModeLists = [
            { id: 146, restaurant_id: 25, display_name: 'Delivery', type: 'delivery', tab: 'delivery', status: true },
            { id: 147, restaurant_id: 25, display_name: 'Pickup', type: 'takeaway', tab: 'takeaway', status: false },
            { id: 148, restaurant_id: 25, display_name: 'Qsr', type: 'qsr', tab: 'dine_in', status: true },
            { id: 149, restaurant_id: 25, display_name: 'Fine Dine', type: 'fine_dine', tab: 'dine_in', status: true },
            {
                id: 150,
                restaurant_id: 25,
                display_name: 'Self Serve',
                type: 'self_serve',
                tab: 'dine_in',
                status: false,
            },
            { id: 231, restaurant_id: 25, display_name: 'test 2', type: 'fine_dine', tab: 'dine_in', status: true },
            { id: 233, restaurant_id: 25, display_name: 'test 1', type: 'qsr', tab: 'dine_in', status: true },
        ];

        const restaurantId = orderingMode[index]?.restaurant_ordering_mode_id;

        const isModeSelfServe = orderingModeList.find((el) => el.id === restaurantId)?.type === 'self_serve';

        if (isModeSelfServe) {
            orderingMode.forEach((el, index) => {
                const isSelectedModeDineIn =
                    orderingModeList.find((modeEl) => modeEl.id === el.restaurant_ordering_mode_id)?.type === 'dine_in';

                isSelectedModeDineIn && status && setValue(`ordering_modes[${index}].is_enabled`, false);
            });
        }
    };

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor]}>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='pl-6 lg:pl-2 min-w-[184px] lg:min-w-[112px] paragraph-overline-small text-neutral-700'>
                                    MODE NAME
                                </th>
                                <th className='pl-6 lg:pl-2 min-w-[159px] lg:min-w-[123px] paragraph-overline-small text-neutral-700'>
                                    ORDER TYPE
                                </th>
                                <th className='pl-6 lg:pl-2 min-w-[167px] lg:min-w-[124px] paragraph-overline-small text-neutral-700'>
                                    ORDER TAB
                                </th>
                                <th className='pl-6 lg:pl-2 min-w-[124px] lg:min-w-[100px] paragraph-overline-small text-neutral-700'>
                                    STATUS
                                </th>
                            </tr>
                        </thead>

                        {orderingMode?.length ? (
                            <tbody>
                                <SortableContext
                                    items={orderingMode}
                                    strategy={verticalListSortingStrategy}
                                    disabled={!isRearrange}>
                                    {orderingMode?.map((el, index) => {
                                        return (
                                            <TableRows
                                                {...el}
                                                key={el.id}
                                                index={index}
                                                isRearrange={isRearrange}
                                                type={formatType(el.restaurant_ordering_mode_id)}
                                                tab={formatTab(el.restaurant_ordering_mode_id)}
                                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                                                handleToggleOrderingMode={handleToggleOrderingMode}
                                            />
                                        );
                                    })}
                                </SortableContext>
                            </tbody>
                        ) : (
                            <tbody>
                                <TableLoader columns={4} />
                            </tbody>
                        )}
                    </table>
                </DndContext>
            </div>
        </>
    );
}
