import React from 'react';
import ContentLoader from 'react-content-loader';

export default function CardContentLoader() {
    return (
        <ContentLoader
            width='100%'
            height='100%'
            className=' w-fit border border-neutral-300 rounded-md'>
            <rect
                x='15'
                y='100'
                rx='4'
                ry='4'
                width='180'
                height='35'
            />
            <rect
                x='90'
                y='55'
                rx='4'
                ry='4'
                width='60'
                height='20'
            />
            <rect
                x='250'
                y='10'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='15'
                y='55'
                rx='4'
                ry='4'
                width='70'
                height='20'
            />
            <rect
                x='15'
                y='10'
                rx='4'
                ry='4'
                width='180'
                height='20'
            />
            <rect
                x='250'
                y='110'
                rx='4'
                ry='4'
                width='30'
                height='10'
            />
        </ContentLoader>
    );
}
