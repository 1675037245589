import APIV2 from '../../api/axios/APIV2';
import APIV3 from '../../api/axios/APIV3';
import * as authTypes from '../types/auth';
import * as outletTypes from '../types/outlet';
import * as permissionTypes from '../types/permission';
import * as langaugeTypes from '../types/langauge';
import * as userTypes from '../types/user';
import * as creditTypes from '../types/credit';
import { isEmpty } from 'lodash';
import APIV5 from '../../api/axios/APIV5';

export const initializeRestaurant = (restaurantId, navigate, pathName, setIsApplicationLoading) => {
    return async (dispatch) => {
        localStorage.removeItem('selectedOutlets');

        let responses;

        try {
            responses = await Promise.all([
                APIV2.get(`/restaurant-descriptions`),
                APIV2.get(`/users/restaurants`),
                APIV2.get(`/restaurants/${restaurantId}/user/permissions`),
                APIV2.get(`/restaurants/${restaurantId}/languages`),
                APIV3.get(`/restaurants/${restaurantId}/users/outlets`),
                APIV2.get(`/restaurants/${restaurantId}/user/pet-pooja/sync-check`),
                APIV2.get(`/restaurants/${restaurantId}/low-credit-alert`),
                APIV5.get(`/restaurants/${restaurantId}/restaurant-ordering-modes`),
                APIV5.get(`restaurants/${restaurantId}`),
            ]);
            setIsApplicationLoading?.(false);
        } catch (error) {
            console.error('One or more promises failed to resolve:', error);
        }

        let descriptionResponse = responses[0];

        dispatch({
            type: authTypes.SET_RESTAURANT_DESCRIPTIONS,
            payload: {
                restaurantDescriptions:
                    descriptionResponse.success && !isEmpty(descriptionResponse.restaurant)
                        ? descriptionResponse.restaurant
                        : {},
            },
        });

        let restaurantList = responses[1];

        let selectedRestaurant = restaurantList.restaurants.filter((restaurant) => restaurant.id === restaurantId);

        let numberOfRestaurants = restaurantList.restaurants.length;

        dispatch({
            type: authTypes.SET_NUMBER_OF_RESTAURANTS,
            payload: {
                numberOfRestaurants: numberOfRestaurants,
            },
        });

        dispatch({
            type: authTypes.SET_REDEEM_POINTS_ON_PURCHASE,
            payload: {
                redemePointValueOnPurchase: selectedRestaurant[0].settings.redeem_point_value_on_purchase,
            },
        });
        restaurantList &&
            restaurantList.restaurants.map((restaurant) => {
                restaurant.value = restaurant.id;
                restaurant.text = restaurant.name;
                return null;
            });
        dispatch({
            type: authTypes.SET_RESTAURANT_LIST,
            payload: {
                restaurantList: restaurantList,
            },
        });

        let userPermissions = responses[2];

        let permissionsMap = {};

        userPermissions.user_permissions.forEach((permission) => {
            permissionsMap.hasOwnProperty(permission.name)
                ? permissionsMap[permission.name].push(permission.permission)
                : (permissionsMap[permission.name] = [permission.permission]);
        });

        dispatch({
            type: permissionTypes.SET_PERMISSION,
            payload: {
                permission: permissionsMap,
            },
        });

        let languages = responses[8].restaurant.language_settings.map((el) => ({ ...el, language_id: el.id }));

        dispatch({
            type: langaugeTypes.SET_AVAILABLE_LANGUAGES,
            payload: {
                availableLanguages: languages,
            },
        });

        let language_id = localStorage.getItem('language');

        if (!language_id) {
            let id = false;
            languages.map((language) => {
                if (language.language === 'en') {
                    dispatch({
                        type: langaugeTypes.SET_LANGUAGE_ID,
                        payload: {
                            languageId: language.language_id,
                        },
                    });
                    localStorage.setItem('language', language.language_id);
                    id = true;
                }
                return null;
            });
            if (!id) {
                dispatch({
                    type: langaugeTypes.SET_LANGUAGE_ID,
                    payload: {
                        languageId: languages[0].language_id,
                    },
                });
                localStorage.setItem('language', languages[0].language_id);
            }
        } else {
            let idState = false;
            languages.map((language) => {
                if (language.language_id === language_id) {
                    dispatch({
                        type: langaugeTypes.SET_LANGUAGE_ID,
                        payload: {
                            languageId: language.language_id,
                        },
                    });
                    localStorage.setItem('language', language.language_id);
                    idState = true;
                }
                return null;
            });
            if (!idState) {
                languages.map((language) => {
                    if (language.language === 'en') {
                        dispatch({
                            type: langaugeTypes.SET_LANGUAGE_ID,
                            payload: {
                                languageId: language.language_id,
                            },
                        });
                        localStorage.setItem('language', language.language_id);
                    }
                    return null;
                });
            }
        }

        let outlets = responses[4];

        let outletArray = [];

        let languageId = localStorage.getItem('language');

        outlets.outlets.forEach((outlet) => {
            outletArray.push({
                outletId: outlet.outlet_id,
                displayName: outlet.translations.name[languageId],
                outletData: outlet,
            });
        });

        dispatch({
            type: outletTypes.SET_OUTLET_LIST,
            payload: {
                outletList: outletArray,
            },
        });
        if (outletArray.length) {
            dispatch({
                type: outletTypes.SET_SELECTED_OUTLET,
                payload: {
                    selectedOutlet: outletArray[0],
                },
            });
        }
        dispatch({
            type: authTypes.SET_RESTAURANT_ID,
            payload: {
                restaurantId: restaurantId,
            },
        });

        let notificationSoundLoop = localStorage.getItem('notificationSoundLoop');

        if (notificationSoundLoop == null) {
            localStorage.setItem('notificationSoundLoop', 1);
        }

        let petPoojaSync = responses[5];

        dispatch({
            type: userTypes.SET_PET_POOJA_SYNCED,
            payload: {
                petPoojaSynced: { synced: petPoojaSync.success, message: petPoojaSync.message },
            },
        });

        let creditAlerts = responses[6];

        if (!isEmpty(creditAlerts)) {
            dispatch({ type: creditTypes.GET_CREDIT_ALERTS, payload: creditAlerts });
        }

        let orderingModes = responses[7];

        if (!isEmpty(orderingModes)) {
            dispatch({ type: outletTypes.SET_ORDERING_MODES, payload: orderingModes.ordering_modes });
        }

        pathName && navigate(pathName);
    };
};
