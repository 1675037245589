import React from 'react';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';

export default function Header(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='paragraph-medium-medium flex flex-row mb-4 cursor-pointer'
                    onClick={() => handleClickClose()}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium'>Back to offers</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mb-2 pb-4'>
                    <div>
                        <span className='paragraph-large-medium'>Link offers</span>
                        <div className='font-normal italic text-base leading-6 text-neutral-500'>
                            Select offer to link to this outlet
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={() => handleClickClose()}>
                        <CloseIcon />
                    </div>
                </div>
            )}
        </>
    );
}
