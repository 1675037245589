import React, { useState } from 'react';
import CashTab from './PaymentTabs/CashTab';
import Layout from './Components/Layout';
import Cards from './Components/Cards';
import TabController from './Components/TabController';
import UPITab from './PaymentTabs/UPITab';
import NCBTab from './PaymentTabs/NCBTab';
import CardTab from './PaymentTabs/CardTab';
import { FormProvider, useForm } from 'react-hook-form';
import { preferenceMenuItem } from '../../../../Constants/Constants';

export default function AddPaymentPopup(props) {
    const { handleClickClose, totalBillAmount = 5000 } = props;

    const [activeTab, setActiveTab] = useState('cash');

    const tabComponent = {
        cash: CashTab,
        card: CardTab,
        upi: UPITab,
        ncb: NCBTab,
    };

    const method = useForm({ defaultValues: { isBillNcb: preferenceMenuItem[1] } });

    const [amountDetails, setAmountDetails] = useState({
        totalBillAmount: totalBillAmount,
        customerPaid: null,
        totalRemaining: totalBillAmount,
        returnCustomer: null,
    });

    const ComponentToRender = tabComponent[activeTab];

    const handleInputAmount = (amount, tab) => {
        const newTabWiseAmount = { ...tabWiseAmount, [tab]: parseFloat(amount) || 0 };
        const customerPaid = Object.values(newTabWiseAmount).reduce((sum, value) => sum + value, 0);
        const totalRemaining = totalBillAmount - customerPaid;
        const returnCustomer = customerPaid - totalBillAmount < 0 ? 0 : customerPaid - totalBillAmount;

        setAmountDetails({
            totalBillAmount,
            customerPaid,
            totalRemaining: totalRemaining > 0 ? totalRemaining : 0,
            returnCustomer,
        });
        setTabWiseAmount(newTabWiseAmount);
    };

    const [tabWiseAmount, setTabWiseAmount] = useState({ cash: null, upi: null, card: null });

    return (
        <>
            <FormProvider {...method}>
                <Layout handleClickClose={handleClickClose}>
                    <Cards amountDetails={amountDetails} />

                    <TabController
                        amountDetails={amountDetails}
                        activeTab={activeTab}
                        tabWiseAmount={tabWiseAmount}
                        setActiveTab={setActiveTab}>
                        <div
                            className={`w-full max-w-[399px] pt-4 border-l border-neutral-300 ml-6 pl-6 md:max-w-full md:ml-0 md:pl-0 md:border-none ${
                                activeTab === 'upi' && 'md:pb-0'
                            }`}>
                            <ComponentToRender
                                tabWiseAmount={tabWiseAmount}
                                amountDetails={amountDetails}
                                handleInputAmount={handleInputAmount}
                                setAmountDetails={setAmountDetails}
                                setTabWiseAmount={setTabWiseAmount}
                            />
                        </div>
                    </TabController>
                </Layout>
            </FormProvider>
        </>
    );
}
