import React from 'react';
import { ReactComponent as LinearMenuIcon } from '../../../.././../../../../Assets/linear-menu.svg';
import { ReactComponent as TimerIcon } from '../../../.././../../../../Assets/timer.svg';
import { ReactComponent as EditIcon } from '../../../.././../../../../Assets/edit.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { fornatOrderName } from '../../../../../../Constants/function';
import { useFormContext } from 'react-hook-form';

export default function TableRow(props) {
    const { isRearrange, service_name, id, waiting_time, index, isEdit, handleEdit } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const { watch } = useFormContext();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    const waitingTime = watch(`rider_setting.rider_auto_assignment.edittingTime[${id}]`);

    return (
        <>
            <tr
                ref={setNodeRef}
                style={style}
                key={index}
                {...attributes}
                {...listeners}
                className={`paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px] ${
                    isRearrange && 'cursor-pointer'
                }`}>
                <td className='px-6'>
                    <div
                        className={`flex flex-row items-center ${isEdit && 'cursor-pointer'}`}
                        onClick={() => isEdit && handleEdit(service_name, waiting_time)}>
                        {isEdit && (
                            <div className='mr-3'>
                                <EditIcon />
                            </div>
                        )}

                        {isRearrange && (
                            <div className='mr-3'>
                                <LinearMenuIcon />
                            </div>
                        )}
                        <span>{fornatOrderName(service_name)}</span>
                    </div>
                </td>

                <td className='px-6'>
                    <div className='flex flex-row items-center'>
                        <TimerIcon />
                        <span className='ml-2'>{(waitingTime ?? waiting_time) + ' mins'}</span>
                    </div>
                </td>
            </tr>
        </>
    );
}
