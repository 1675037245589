import React from 'react';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';

export default function GridBlockPlus() {
    return (
        <div className='flex flex-col mt-[14px] -ml-2.5'>
            <div className='mb-[111px] lg:mb-[88px] md:mb-[76px]'>
                <AddIcon
                    stroke='#706E7E'
                    height={20}
                    width={20}
                />
            </div>
            <AddIcon
                stroke='#706E7E'
                height={20}
                width={20}
            />
        </div>
    );
}
