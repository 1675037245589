import React from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import ModeTimingSection from './Components/ModeTimingSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';

export default function Timing(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { isDirty },
    } = useFormContext();

    const onSubmit = (data) => {
        const timingEntries = Object.entries(data)
            .filter(([key]) => key.startsWith('timing_'))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const formattedTiming = Object.keys(timingEntries)
            .map((key) => {
                if (key.startsWith('timing_') && key.endsWith('_otherDetails')) {
                    const id = parseInt(key.split('_')[1]);
                    const otherDetails = timingEntries[key];
                    const timingTypeKey = `timing_${id}_timingType`;
                    const timingTypeValue = timingEntries[timingTypeKey].value;
                    const slots = timingEntries[`timing_${id}`];

                    let transformedSlots = [];

                    if (timingTypeValue === 'same_time_slot_for_all_days') {
                        transformedSlots = [
                            {
                                days_of_week: [1, 2, 3, 4, 5, 6, 7],
                                time_slots: slots.map((slot) => ({
                                    start_time: slot.start_time,
                                    end_time: slot.end_time,
                                    kitchen_preparation_time: slot.kitchen_preparation_time,
                                })),
                            },
                        ];
                    }

                    if (timingTypeValue === 'different_time_slots_for_different_days') {
                        transformedSlots = slots
                            .map((el, index) => ({
                                days_of_week: [index + 1],
                                time_slots: el,
                            }))
                            .filter((el) => !!el.time_slots.length);
                    }

                    return {
                        id,
                        restaurant_ordering_mode_id: otherDetails.restaurant_ordering_mode_id,
                        display_rank: otherDetails.display_rank,
                        translations: otherDetails.translations,
                        display_name: otherDetails.display_name,
                        time_slot_setting: timingTypeValue,
                        timeslots: transformedSlots,
                    };
                } else {
                    return null;
                }
            })
            .filter(Boolean);

        let transformedSlots = [];

        if (data.timing_0_timingType.value === 'same_time_slot_for_all_days') {
            transformedSlots = [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: data.timing_0.map((slot) => ({
                        start_time: slot.start_time,
                        end_time: slot.end_time,
                        kitchen_preparation_time: slot.kitchen_preparation_time,
                    })),
                },
            ];
        }

        if (data.timing_0_timingType.value === 'different_time_slots_for_different_days') {
            transformedSlots = data.timing_0
                .map((el, index) => ({
                    days_of_week: [index + 1],
                    time_slots: el,
                }))
                .filter((el) => !!el.time_slots.length);
        }

        const dataToSend = {
            ordering_modes: formattedTiming,
            timeslots: transformedSlots,
            time_slot_setting: data.timing_0_timingType.value,
        };

        updateTiming(dataToSend);
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateTiming = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=timings`, { ...params });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error.response.data.errors);
        }
    };

    const menuItemTiming = [
        { label: 'Full time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    const availableOrderingMode = watch('ordering_modes')?.filter((el) => el.is_enabled);

    return (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <div className='pb-6 mb-6 border-b border-neutral-300 last:border-none'>
                    <ModeTimingSection
                        title='Outlet timings'
                        id='timing_0'
                        menuItemTiming={menuItemTiming.slice(1)}
                        time_slot_setting={watch('timing_0_timingType')}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                </div>

                {watch('timing.timeSlots')?.map((el, index) => {
                    const isModeActive = availableOrderingMode.some(
                        (modeEl) => modeEl.restaurant_ordering_mode_id === el.restaurant_ordering_mode_id
                    );

                    if (!isModeActive) return null;

                    return (
                        <div
                            key={index}
                            className='pb-6 mb-6 border-b border-neutral-300 last:border-none'>
                            <ModeTimingSection
                                id={`timing_${el.id}`}
                                title={el.display_name[1]}
                                menuItemTiming={menuItemTiming}
                                time_slot_setting={el.time_slot_setting}
                                timeslots={el.timeslots}
                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            />
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
}
