import offer from '../../../Assets/MediaLibrary/fizz.png';
import Pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import Pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import Pizza3 from '../../../Assets/MediaLibrary/pizza3.png';
import Pizza4 from '../../../Assets/MediaLibrary/pizza4.png';
import Pizza5 from '../../../Assets/MediaLibrary/pizza5.png';
import Pizza6 from '../../../Assets/MediaLibrary/pizza6.png';
import iceCreame from '../../../Assets/MediaLibrary/icecreame.png';
import cake from '../../../Assets/MediaLibrary/cake.png';

const imageData = [
    { title: 'Millano Pizza', image: Pizza1 },
    { title: 'Italian Pizza', image: Pizza2 },
    { title: 'Garlic bread', image: Pizza6 },
    { title: 'Pizza margerita', image: Pizza3 },
    { title: 'Ice creame', image: iceCreame },
    { title: 'Puff pizza', image: Pizza4 },
    { title: 'Garlic Pizza', image: Pizza5 },
    { title: 'cake', image: cake },
];

export const PrimaryBanner = () => {
    return (
        <>
            <div className='flex flex-row'>
                <img
                    src={Pizza1}
                    alt=''
                    className='h-[125px] min-w-[200px] rounded-xl'
                />
                <img
                    src={Pizza2}
                    alt=''
                    className='h-[125px] min-w-[200px] ml-1.5 rounded-xl'
                />
            </div>
        </>
    );
};

export const SecondaryBanner = () => {
    return (
        <>
            <div className='flex flex-row'>
                <img
                    src={Pizza6}
                    alt=''
                    className='h-[75px] min-w-[175px] rounded-xl'
                />
                <img
                    src={Pizza3}
                    alt=''
                    className='h-[75px] min-w-[175px] ml-1.5 rounded-xl'
                />
            </div>
        </>
    );
};

export const TertiaryBanner = () => {
    return (
        <>
            <div className='flex flex-row'>
                <img
                    src={Pizza5}
                    alt=''
                    className='h-[75px] min-w-[175px] rounded-xl'
                />
                <img
                    src={Pizza4}
                    alt=''
                    className='h-[75px] min-w-[175px] ml-1.5 rounded-xl'
                />
            </div>
        </>
    );
};
