import React from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as FilterIcon } from '../../../Assets/filter.svg';
import { ReactComponent as GridViewIcon } from '../../../Assets/grid-view.svg';
import { ReactComponent as ListViewIcon } from '../../../Assets/view.svg';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function HeaderBottons(props) {
    const { handleClickFilter, handleClickTab, showGrid, selectedFilterNumber, setSearchedItem } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='flex flex-row justify-between md:mt-4 md:block -mb-1'>
                <div className='max-w-[375px] w-full md:max-w-full md:w-full md:mb-4'>
                    <DefaultInputField
                        placeholder='Search outlet or restaurant'
                        placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                        shadow='shadow-xSmall'
                        enteredValue={setSearchedItem}
                    />
                </div>

                <div className='flex flex-row lg:ml-[52px] md:ml-0'>
                    {!isMobileScreen ? (
                        <div
                            className='mr-3 lg:mr-1 cursor-pointer'
                            onClick={handleClickFilter}>
                            <LargePrimaryButton
                                leftIconDefault={<FilterIcon fill='#ffffff' />}
                                leftIconClick={<FilterIcon fill='#C4BEED' />}
                                label='Filters'
                                badgeText={selectedFilterNumber !== 0 && selectedFilterNumber}
                            />
                        </div>
                    ) : (
                        <div
                            className='w-1/2'
                            onClick={() => handleClickFilter()}>
                            <LargePrimaryButton
                                leftIconDefault={<FilterIcon fill='#ffffff' />}
                                leftIconClick={<FilterIcon fill='#C4BEED' />}
                                label='Filters'
                                isDefault={false}
                                badgeText={selectedFilterNumber !== 0 && selectedFilterNumber}
                            />
                        </div>
                    )}

                    {!isMobileScreen ? (
                        <div className='flex flex-row'>
                            <div
                                onClick={() => handleClickTab('gridView')}
                                className='h-12 mx-3 lg:mx-1 3xl:w-[130px] cursor-pointer'>
                                <Tab
                                    label='Grid view'
                                    iconDefault={<GridViewIcon />}
                                    iconClick={<GridViewIcon stroke='#6C5DD3' />}
                                    isActive={showGrid}
                                    labelStyle='paragraph-small-medium ml-2 mr-1'
                                />
                            </div>

                            <div
                                className='h-12 ml-3 lg:ml-1 3xl:w-[125px] cursor-pointer'
                                onClick={() => handleClickTab('listView')}>
                                <Tab
                                    label='List view'
                                    iconDefault={<ListViewIcon />}
                                    iconClick={<ListViewIcon stroke='#6C5DD3' />}
                                    isActive={!showGrid}
                                    labelStyle='paragraph-small-medium'
                                />
                            </div>
                        </div>
                    ) : (
                        isMobileScreen && (
                            <div className='w-full ml-2 mobile:ml-1 md:w-1/2'>
                                <DropDownTabs
                                    menuItems={[
                                        {
                                            item: 'Grid view',
                                            onClick: () => handleClickTab('gridView'),
                                            icon: <GridViewIcon />,
                                            iconClick: <GridViewIcon stroke='#6C5DD3' />,
                                        },
                                        {
                                            item: 'List view',
                                            onClick: () => handleClickTab('listView'),
                                            icon: <ListViewIcon />,
                                            iconClick: <ListViewIcon stroke='#6C5DD3' />,
                                        },
                                    ]}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </>
    );
}
