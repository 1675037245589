import React from 'react';
import UserSetting from './UserSetting/UserSetting';
import StoreSetting from './StoreSetting/StoreSetting';
import GlobalSetting from './GlobalSetting/GlobalSetting';
import { useWindowSize } from '@uidotdev/usehooks';
import HeaderTab from './Components/HeaderTab';
import { useNavigate, useParams } from 'react-router-dom';

export default function Setting() {
    const { settingType } = useParams();

    const screenHeight = useWindowSize().height;
    const navigate = useNavigate();

    const componentMapping = {
        user: UserSetting,
        store: StoreSetting,
        global: GlobalSetting,
    };
    const handleNavigation = ({ route, loaderFunc }) => {
        console.log(' route, loaderFunc', route, loaderFunc);
        navigate(`/settings/${route}`, {
            loader: async () => {
                loaderFunc();
            },
        });
    };

    const ComponentToRender = componentMapping[settingType];

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className={`px-8 lg:px-4 pt-4 md:pb-8 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full ${
                        !true && 'md:px-0 md:pt-0'
                    }`}
                    style={{ minHeight: screenHeight - 50 }}>
                    <HeaderTab handleNavigation={handleNavigation} />

                    <div className={`${settingType !== 'user' && '-mt-4 md:-mt-0'}`}>
                        <ComponentToRender handleNavigation={handleNavigation} />
                    </div>
                </div>
            </div>
        </>
    );
}
