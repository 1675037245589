import React from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

const seatStatuses = [
    { style: 'border-neutral-300', label: { mobile: 'Empty', desktop: 'Empty seats' } },
    { style: 'border-primary-300 bg-primary-50', label: { mobile: 'Seated', desktop: 'Seated seats' } },
    { style: 'border-secondary-500 bg-secondary-50', label: { mobile: 'Reserved', desktop: 'Reserved seats' } },
    { style: 'border-neutral-300 bg-neutral-200', label: { mobile: 'Disabled', desktop: 'Disabled seats' } },
];

const SeatStatus = ({ style, label, count }) => (
    <div className='flex flex-row items-center last:mr-0 mr-10'>
        <div className={`h-6 w-6 rounded border-2 mr-1 ${style}`} />
        <span className='paragraph-medium-regular text-[#000000]'>{`${label} | ${count}`}</span>
    </div>
);

const StatusColorViewer = ({ seatCounts }) => {
    const { width: screenWidth } = useWindowSize();
    const isMobile = screenWidth < 1023;

    return (
        <div className='fixed bottom-6 z-40 group-[.undock-left-panel]:z-[8]'>
            <div className='border border-neutral-300 shadow-medium px-4 py-3 rounded-lg flex-row flex bg-white w-max'>
                {seatStatuses.map((status, index) => (
                    <SeatStatus
                        key={index}
                        style={status.style}
                        label={isMobile ? status.label.mobile : status.label.desktop}
                        count={seatCounts?.[index] ?? 2}
                    />
                ))}
            </div>
        </div>
    );
};

export default StatusColorViewer;
