import React from 'react';
import ReactApexChart from 'react-apexcharts';

const TotalRevenue = () => {
    const chartData = {
        series: [
            {
                name: 'Online sales',
                data: [30000, 40000, 25000, 45000, 45000, 35000, 25000],
            },
            {
                name: 'Offline sales',
                data: [20000, 35000, 40099, 30000, 30000, 45000, 35000],
            },
        ],
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    };

    const formatYAxisLabel = (value) => {
        if (value >= 1000) {
            return `${value / 1000}k`;
        }
        return value;
    };

    const chartOptions = {
        chart: {
            type: 'bar',
            height: '100%',
            width: '100%',
            toolbar: {
                show: false,
            },
        },

        stroke: {
            colors: ['transparent'],
            width: 5,
        },

        bar: {
            columnWidth: '16px',
        },

        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                borderRadius: 4,
                columnWidth: '20px',
                dataLabels: {
                    position: 'top',
                },
            },

            states: {
                hover: {
                    enabled: false,
                },
            },
        },

        dataLabels: {
            enabled: false,
        },

        xaxis: {
            categories: chartData.categories,
            labels: {
                offsetY: 4,
                style: {
                    fontSize: '16px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    cssClass: 'text-sm',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },

        colors: ['#74C2B9', '#FFBB3C'],
        fill: {
            opacity: 1,
        },

        legend: {
            show: false,
        },

        grid: {
            padding: {
                top: 0,
            },
            borderColor: '#D3D2D8',
        },

        yaxis: {
            tickAmount: 5,
            labels: {
                formatter: formatYAxisLabel,
                style: {
                    fontSize: '16px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    cssClass: 'text-sm',
                },
            },
        },

        tooltip: {
            enabled: false,
        },
    };

    return (
        <>
            <span className='paragraph-large-medium text-black'>Total revenue</span>
            <div className='border border-neutral-300 rounded-md mt-4'>
                <div className='mt-2 mb-1.5'>
                    <ReactApexChart
                        options={chartOptions}
                        series={chartData.series}
                        type='bar'
                        height={250}
                    />
                </div>

                <div className='flex flex-row items-center w-full justify-center mb-5'>
                    <div className='flex flex-row items-center mr-3'>
                        <div className='h-3 w-3 bg-[#74C2B9] rounded-full mr-2.5' />
                        <span className='paragraph-small-regular'>Online sales</span>
                    </div>

                    <div className='flex flex-row items-center pl-3'>
                        <div className='h-3 w-3 bg-[#FFBB3C] rounded-full mr-2.5' />
                        <span className='paragraph-small-regular'>Offline sales</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TotalRevenue;
