import React, { useEffect, useState } from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import TableSection from './TableSection';
import ListViewSection from './ListViewSection';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { useNavigate } from 'react-router-dom';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../../../Components/ContentLoader/ListViewLoader';
import _ from 'lodash';

export default function ListSection(props) {
    const {
        selectedDates,
        updatePaginationState,
        paginationState,
        isLoading,
        orderDetails,
        handlePagination,
        fetchOrderDetail,
    } = props;

    const navigate = useNavigate();

    const isMobileScreen = IsMobileScreen();

    const [paginationChanged, setPaginationChanged] = useState(false);

    useEffect(() => {
        setPaginationChanged(true);
        const timer = setTimeout(() => {
            setPaginationChanged(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [paginationState]);

    return (
        <>
            {!isMobileScreen ? (
                isLoading || paginationChanged ? (
                    <table>
                        <tbody>
                            <TableLoader
                                hasSwitch={false}
                                columns={6}
                            />
                        </tbody>
                    </table>
                ) : (
                    <TableSection orderDetails={orderDetails} />
                )
            ) : isLoading || paginationChanged ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <ListViewSection
                    orderDetails={orderDetails}
                    paginationState={paginationState}
                    fetchOrderDetail={fetchOrderDetail}
                />
            )}

            {!_.isEmpty(orderDetails) ? (
                <div className='mt-4'>
                    <PaginationWithNumber
                        lastPage={paginationState.lastPage}
                        selectedPage={paginationState.selectedPage}
                        setSelectedPage={(value) => {
                            handlePagination({ selectedPage: value }, 'selectedPage');
                        }}
                        setResultPerPage={(value) => handlePagination({ resultPerPage: value }, 'resultPerPage')}
                        selectedResultPerpage={paginationState.resultPerPage}
                    />
                </div>
            ) : null}
        </>
    );
}
