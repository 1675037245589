import React, { useEffect } from 'react';
import RiderSettingsType from './RiderSettingsType';
import { useFormContext } from 'react-hook-form';
import { riderPreferenceMenuItems } from './constants';

export default function RiderSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const { watch, setValue } = useFormContext();

    const riderSettingsTypes = [
        {
            title: 'In-house riders',
            description: 'Set this on if you own riders at your outlet to fulfill the delivery orders.',
            label: '(Enable service)',
            id: 'rider_setting.in_house_riders',
            enableId: 'rider_setting.enable_own_rider_service',
        },
        {
            title: 'ELT riders',
            description: 'Set ELT riders API for your outlet.',
            label: '(Enable service)',
            id: 'rider_setting.elt',
            enableId: 'rider_setting.elt.elt_integration_available',

            inputFields: [
                {
                    label: '(API key)',
                    placeholder: 'Enter API key',
                    id: 'rider_setting.elt.elt_api_key',
                },
                {
                    label: '(Primary address ID)',
                    placeholder: 'Enter primary address ID',
                    id: 'rider_setting.elt.elt_primary_address_id',
                },
                {
                    label: '(URL)',
                    placeholder: 'Enter URL',
                    id: 'rider_setting.elt.elt_call_back_url',
                },
            ],
        },
        {
            title: 'Dunzo riders',
            description: 'Set Dunzo riders API for your outlet.',
            label: '(Enable service)',
            id: 'rider_setting.dunzo',
            enableId: 'rider_setting.dunzo.dunzo_integration_available',
            globalEnableId: 'rider_setting.dunzo.dunzo_request.status',
            inputFields: [
                {
                    label: '(Client Secret)',
                    placeholder: 'Enter client secret',
                    id: 'rider_setting.dunzo.dunzo_client_secret',
                },
                {
                    label: '(Client ID)',
                    placeholder: 'Enter primary address Id',
                    id: 'rider_setting.dunzo.dunzo_client_id',
                },
                {
                    label: '(Call back URL)',
                    placeholder: 'Enter callback URL',
                    id: 'rider_setting.dunzo.dunzo_call_back_url',
                },
            ],
        },
        {
            title: 'Wefast riders',
            description: 'Set Wefast riders API for your outlet.',
            label: '(Enable service)',
            id: 'rider_setting.wefast',
            enableId: 'rider_setting.wefast.wefast_integration_available',
            globalEnableId: 'rider_setting.wefast.wefast_request.status',
            inputFields: [
                {
                    label: '(Auth token)',
                    placeholder: 'Enter auth token',
                    id: 'rider_setting.wefast.wefast_auth_token',
                },
                {
                    label: '(URL)',
                    placeholder: 'Enter Url',
                    id: 'rider_setting.wefast.wefast_call_back_url',
                },
            ],
        },
        {
            title: 'Porter riders',
            description: 'Set porter riders API for your outlet.',
            label: '(Enable service)',
            id: 'rider_setting.porter',
            enableId: 'rider_setting.porter.is_porter_integration_enabled',
            globalEnableId: 'rider_setting.porter.porter_request.status',
            inputFields: [
                {
                    label: '(Porter token)',
                    placeholder: 'Enter Porter token',
                    id: 'rider_setting.porter.porter_token',
                },
            ],
        },
        {
            title: 'Shadowfax riders',
            description: 'Set Shadowfax riders API for your outlet.',
            label: '(Enable service)',
            id: 'rider_setting.shadowfax',
            enableId: 'rider_setting.shadowfax.is_shadowfax_integration_enabled',
            globalEnableId: 'rider_setting.shadowfax.shadowfax_request.status',
            inputFields: [
                {
                    label: '(Token)',
                    placeholder: 'Enter token',
                    id: 'rider_setting.shadowfax.shadowfax_token',
                },
                {
                    label: '(Client code)',
                    placeholder: 'Enter client code',
                    id: 'rider_setting.shadowfax.shadowfax_client_code',
                },
            ],
        },
    ];

    useEffect(() => {
        if (!watch('rider_setting')) return;

        if (!!watch('rider_setting.enable_own_rider_service')) {
            setValue('rider_setting.in_house_riders.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.in_house_riders.preference', riderPreferenceMenuItems[2]);
        }

        if (!!watch('rider_setting.elt.elt_integration_available')) {
            setValue('rider_setting.elt.elt_integration_available', true);
            setValue('rider_setting.elt.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.elt.elt_integration_available', false);
            setValue('rider_setting.elt.preference', riderPreferenceMenuItems[2]);
        }

        if (!!watch('rider_setting.dunzo.use_global_dunzo_configurations')) {
            setValue('rider_setting.dunzo.preference', riderPreferenceMenuItems[0]);
        } else if (!!watch('rider_setting.dunzo.dunzo_integration_available')) {
            setValue('rider_setting.dunzo.use_global_dunzo_configurations', true);
            setValue('rider_setting.dunzo.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.dunzo.use_global_dunzo_configurations', false);
            setValue('rider_setting.dunzo.preference', riderPreferenceMenuItems[2]);
        }

        if (!!watch('rider_setting.wefast.use_global_wefast_configurations')) {
            setValue('rider_setting.wefast.preference', riderPreferenceMenuItems[0]);
        } else if (!!watch('rider_setting.wefast.wefast_integration_available')) {
            setValue('rider_setting.wefast.wefast_integration_available', true);
            setValue('rider_setting.wefast.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.wefast.wefast_integration_available', false);
            setValue('rider_setting.wefast.preference', riderPreferenceMenuItems[2]);
        }

        if (!!watch('rider_setting.porter.use_global_porter_configurations')) {
            setValue('rider_setting.porter.preference', riderPreferenceMenuItems[0]);
        } else if (!!watch('rider_setting.porter.is_porter_integration_enabled')) {
            setValue('rider_setting.porter.is_porter_integration_enabled', true);
            setValue('rider_setting.porter.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.porter.is_porter_integration_enabled', false);
            setValue('rider_setting.porter.preference', riderPreferenceMenuItems[2]);
        }

        if (!!watch('rider_setting.shadowfax.use_global_shadowfax_configurations')) {
            setValue('rider_setting.shadowfax.preference', riderPreferenceMenuItems[0]);
        } else if (!!watch('rider_setting.shadowfax.is_shadowfax_integration_enabled')) {
            setValue('rider_setting.shadowfax.is_shadowfax_integration_enabled', false);
            setValue('rider_setting.shadowfax.preference', riderPreferenceMenuItems[1]);
        } else {
            setValue('rider_setting.shadowfax.is_shadowfax_integration_enabled', true);
            setValue('rider_setting.shadowfax.preference', riderPreferenceMenuItems[2]);
        }

        // eslint-disable-next-line
    }, [watch('rider_setting.dunzo')]);

    return (
        <>
            {riderSettingsTypes.map((el, index) => {
                return (
                    <div
                        key={index}
                        className='pb-6 border-b border-neutral-300 mb-6 last:border-none'>
                        <RiderSettingsType
                            {...el}
                            index={index}
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                        />
                    </div>
                );
            })}
        </>
    );
}
