import React from 'react';
import { useFormContext } from 'react-hook-form';
import SingleVariantWiseTableComp from './SingleVariantWiseTableComp';

export default function VariantWiseTable() {
    const { watch } = useFormContext();

    const variants = watch('pricing.variantPrice');

    return (
        <>
            {watch('do_add_variant') ? (
                variants?.map((el, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className='paragraph-large-medium mb-2'>{el.name}</div>

                            <SingleVariantWiseTableComp selectionVariantId={`pricing.variantPrice.${el.id}.addon`} />
                        </React.Fragment>
                    );
                })
            ) : (
                <SingleVariantWiseTableComp selectionVariantId='pricing.dishPrice.addon' />
            )}
        </>
    );
}
