import React from 'react';
import { SingleLoader } from '../ContentLoader/DropdownLoader';
import { IsMobileScreen } from '../../Constants/Constants';

export default function AppLoader() {
    const isMobileScreen = IsMobileScreen();

    return isMobileScreen ? <MobileLoader /> : <DesktopLoader />;
}

const MobileLoader = () => {
    return (
        <>
            <SingleLoader className='max-w-full h-[104px]' />

            <SingleLoader className='max-w-full h-12 mt-6 px-4' />

            <div className='flex flex-row gap-4 px-4'>
                <SingleLoader className='max-w-[50%] h-12 mt-6' />
                <SingleLoader className='max-w-[50%] h-12 mt-6' />
            </div>

            <div className='px-4'>
                <SingleLoader className='max-w-[70%] h-8 mt-6' />
                <SingleLoader className='max-w-[50%] h-8 mt-4' />
                <SingleLoader className='max-w-[35%] h-8 mt-4' />
            </div>

            <div className='px-4'>
                <SingleLoader className='max-w-[70%] h-8 mt-6' />
                <SingleLoader className='max-w-[50%] h-8 mt-4' />
                <SingleLoader className='max-w-[35%] h-8 mt-4' />
            </div>

            <div className='px-4'>
                <SingleLoader className='max-w-[70%] h-8 mt-6' />
                <SingleLoader className='max-w-[50%] h-8 mt-4' />
                <SingleLoader className='max-w-[35%] h-8 mt-4' />
            </div>
        </>
    );
};

const DesktopLoader = () => {
    return (
        <>
            <SingleLoader className='w-full h-[50px] absolute top-0' />

            <div className='pl-[104px] bg-[#FAFAFA]'>
                <div className='w-full max-w-[1336px] pt-[64px] h-screen mx-auto bg-white pb-4 px-8'>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-row gap-3'>
                            <SingleLoader className='max-w-[300px] h-12' />
                            <SingleLoader className='max-w-[150px] h-12' />
                            <SingleLoader className='max-w-[150px] h-12' />
                        </div>

                        <SingleLoader className='max-w-[150px] h-12' />
                    </div>

                    <div className='flex flex-row justify-between mt-12'>
                        <SingleLoader className='max-w-[150px] h-12' />

                        <div className='flex flex-row gap-4'>
                            <SingleLoader className='max-w-[125px] h-12' />
                            <SingleLoader className='max-w-[125px] h-12' />
                        </div>
                    </div>

                    <SingleLoader className='max-w-[500px] h-8 mt-6' />
                    <SingleLoader className='max-w-[350px] h-8 mt-4' />
                    <SingleLoader className='max-w-[200px] h-8 mt-4' />
                    <SingleLoader className='max-w-[500px] h-8 mt-6' />
                    <SingleLoader className='max-w-[350px] h-8 mt-4' />
                    <SingleLoader className='max-w-[200px] h-8 mt-4' />
                    <SingleLoader className='max-w-[500px] h-8 mt-6' />
                    <SingleLoader className='max-w-[350px] h-8 mt-4' />
                    <SingleLoader className='max-w-[200px] h-8 mt-4' />
                </div>
            </div>
        </>
    );
};
