import React, { useState } from 'react';
import _ from 'lodash';
import { LargePrimaryButton } from '../../../../../../../../Components/Buttons/Button';
import { ReactComponent as RearrangeIcon } from '../../../.././../../../../Assets/re-arrange.svg';
import { ReactComponent as EditIcon } from '../../../.././../../../../Assets/edit.svg';
import { IsMobileScreen } from '../../../../../../../../Constants/Constants';
import TableRow from './TableRow';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import { BlankComponentTable } from '../../../../../../../../Components/BlankComponent/BlankComponent';
import EditingPopup from './EditingPopup';

export default function PreferenceTable(props) {
    const { setShowTabChangeWarningPopup } = props;

    const [isRearrange, setIsRearrange] = useState(false);

    const { watch, setValue } = useFormContext();

    const isMobileScreen = IsMobileScreen();

    const ridersByPreferences = watch('rider_setting.rider_auto_assignment.rider_auto_assignment_by_preferences');

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const updateSequence = (active, over) => {
        const oldIndex = ridersByPreferences.findIndex((item) => item.id === active.id);
        const newIndex = ridersByPreferences.findIndex((item) => item.id === over.id);

        return arrayMove(ridersByPreferences, oldIndex, newIndex);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        const updateValue = updateSequence(active, over);

        setValue('rider_setting.rider_auto_assignment.rider_auto_assignment_by_preferences', updateValue);

        setShowTabChangeWarningPopup(true);
    };

    const [isEdit, setIsEdit] = useState(false);

    const [editingDetails, setEditingDetails] = useState({});

    const handleEdit = (editingDetails) => {
        setValue(
            `rider_setting.rider_auto_assignment.edittingTime.${editingDetails.index}`,
            editingDetails.waiting_time
        );

        setEditingDetails(editingDetails);
    };

    return (
        <>
            <div className='flex flex-row my-4'>
                <div
                    className='mr-2 md:w-1/2 mobile:mr-1'
                    onClick={() => {
                        setIsRearrange((prevState) => !prevState);
                        setIsEdit(false);
                    }}>
                    <LargePrimaryButton
                        isClicked={isRearrange}
                        label='Re-arrange'
                        hideLabel='mobile:hidden'
                        leftIconClick={<RearrangeIcon stroke='#C4BEED' />}
                        leftIconDefault={<RearrangeIcon stroke='#FFFFFF' />}
                    />
                </div>
                <div
                    className='ml-2 md:w-1/2 mobile:ml-1'
                    onClick={() => {
                        setIsEdit((prevState) => !prevState);
                        setIsRearrange(false);
                    }}>
                    <LargePrimaryButton
                        isClicked={isEdit}
                        label='Edit waiting time'
                        hideLabel='mobile:hidden'
                        leftIconClick={<EditIcon stroke='#C4BEED' />}
                        leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                    />
                </div>
            </div>

            {!isMobileScreen && (
                <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border mb-6 max-w-[380px] md:max-w-full'>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToParentElement,
                            restrictToFirstScrollableAncestor,
                        ]}>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[200px] paragraph-overline-small text-neutral-700'>
                                        RIDER TYPE
                                    </th>
                                    <th className='px-6 min-w-[180px] paragraph-overline-small text-neutral-700'>
                                        WAITING TIME
                                    </th>
                                </tr>
                            </thead>

                            {ridersByPreferences?.length ? (
                                <tbody>
                                    <SortableContext
                                        items={ridersByPreferences}
                                        strategy={verticalListSortingStrategy}
                                        disabled={!isRearrange}>
                                        {ridersByPreferences?.map((el, index) => {
                                            return (
                                                <TableRow
                                                    {...el}
                                                    key={el.id}
                                                    index={index}
                                                    isRearrange={isRearrange}
                                                    isEdit={isEdit}
                                                    handleEdit={() => handleEdit({ ...el, index: el.id })}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </tbody>
                            ) : (
                                <BlankComponentTable colSpan={2} />
                            )}
                        </table>
                    </DndContext>
                </div>
            )}

            {!_.isEmpty(editingDetails) && (
                <EditingPopup
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    editingDetails={editingDetails}
                    handleClickClose={() => setEditingDetails({})}
                />
            )}
        </>
    );
}
