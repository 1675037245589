import React from 'react';
import CouponCodeSection from './Components/CouponCodeSection';
import DescriptionSection from './Components/DescriptionSection';
import TitleSection from './Components/TitleSection';

export default function BasicDetails() {
    return (
        <>
            <div className='mb-6'>
                <CouponCodeSection />
            </div>

            <div className='mb-6'>
                <TitleSection />
            </div>

            <div className='mb-6'>
                <DescriptionSection />
            </div>
        </>
    );
}
