import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as LinkIcon } from '../../../../Assets/link.svg';
import ToggleSwitch from '../../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';

export const ListViewUserSettingUserList = (props) => {
    const { handleClickProceed, actionState, handleEdit, handleChangeReadCheckBox, item, onResize, cache } = props;
    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };
    useEffect(() => {
        cache.clearAll();
        onResize();
    }, [isShowDetails, onResize]);
    return (
        <>
            <div className='w-full px-4 py-3 border border-neutral-300 rounded-md mt-2 mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    {actionState.isEditMode && (
                        <div
                            className='-mr-28'
                            onClick={() => handleEdit(item)}>
                            <EditIcon />
                        </div>
                    )}
                    {actionState.isBulkSelect && (
                        <div className='-mr-28 cursor-pointer'>
                            <CheckBoxWithoutLabels
                                onChange={handleChangeReadCheckBox}
                                object={item}
                            />
                        </div>
                    )}
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>USER NAME:</h3>
                        <span className='paragraph-small-regular'>{item?.name}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <div className={`${actionState.isEditMode || actionState.isBulkSelect ? 'ml-14' : 'ml-0'} pt-2`}>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>USER ROLE:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.custom_role[0]?.name}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>MOBILE NUMBER:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.mobile_number}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>EMAIL ID:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.email}</span>
                        </div>
                        <div className='pt-2 flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>OUTLET PERMISSION:</span>
                            <div
                                className='paragraph-small-regular ml-1 items-center flex flex-row cursor-pointer'
                                onClick={handleClickProceed}>
                                <LinkIcon
                                    stroke='#6C5DD3'
                                    className='h-5 w-5'
                                />
                                <span className='ml-1 text-primary-500'>{item?.outlets?.length}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const ListViewUserSettingPresetList = (props) => {
    const { item, handleClickEditPresetList, handleChangeReadCheckBox, actionState, onResize, cache, arrayState } =
        props;
    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };
    useEffect(() => {
        cache.clearAll();
        onResize();
    }, [isShowDetails, onResize]);
    return (
        <>
            <div className='w-full px-4 py-3 border border-neutral-300 rounded-md mt-2 mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    {actionState.isEditMode && (
                        <div
                            className='-mr-28'
                            onClick={() => handleClickEditPresetList(item)}>
                            <EditIcon />
                        </div>
                    )}
                    {actionState.isBulkSelect && (
                        <div className='-mr-28 cursor-pointer'>
                            <CheckBoxWithoutLabels
                                onChange={handleChangeReadCheckBox}
                                object={item}
                                isChecked={arrayState.selectedForDelete.includes(item.id)}
                            />
                        </div>
                    )}
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>PRESET NAME:</h3>
                        <span className='paragraph-small-regular'>{item?.name}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className={`${actionState.isEditMode || actionState.isBulkSelect ? 'ml-14' : 'ml-0'}`}>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>LINKED USERS:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.linked_user_count}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED BY:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.crcreated_byeateBy}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATOR NUMBER:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.creator_number}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED ON:</span>
                            <span className='paragraph-small-regular ml-1'>{item?.created_at}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const ListViewOutletPermission = (props) => {
    const { outletName, state, city, address, handleToggleSwitch, linkUserData, object } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDownArrow = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='px-4 py-3 border border-neutral-300 rounded-md'>
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        <ToggleSwitch
                            isEnable={linkUserData.some((item) => item?.id == object?.outletId)}
                            handleToggleSwitch={handleToggleSwitch}
                            item={object}
                        />
                        <div className='flex flex-col ml-4'>
                            <span className='paragraph-overline-small mb-1'>OUTLET NAME:</span>
                            <span className='paragraph-small-regular'>{outletName}</span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickDownArrow}>
                        <DownArrow className={`${showDetails && 'rotate-180'}`} />
                    </div>
                </div>
                {showDetails && (
                    <div className='mt-1.5 ml-[56px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>STATE:</span>
                            <span className='paragraph-small-regular ml-1'>{state}</span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>CITY:</span>
                            <span className='paragraph-small-regular ml-1'>{city}</span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>ADDRESS:</span>
                            <span className='paragraph-small-regular ml-1'>{address}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
