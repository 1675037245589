import React from 'react';

export default function AmountCard() {
    return (
        <div className='pageContent:py-2.5 mb-2 relative amount-card-hover group overflow-hidden'>
            <div className='group-[.amount-card-hover:hover]:transition-all transition-height duration-300 ease-in-out h-0 opacity-0 group-[.amount-card-hover:hover]:h-[110px] group-[.amount-card-hover:hover]:opacity-100 paragraph-medium-regular'>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <span>Gross total</span>
                    <span>₹4,518.00/-</span>
                </div>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <span>Other charges & taxes</span>
                    <span>₹718.00/-</span>
                </div>
                <div className='flex flex-row justify-between items-center mb-3'>
                    <span>Delivery charge</span>
                    <span>₹118.00/-</span>
                </div>
            </div>
            <div className='flex flex-row justify-between cursor-default paragraph-medium-semi-bold'>
                <div className='flex items-center'>
                    <span>Total bill amount</span>
                    <span className='text-success-600 text-[10px] border font-medium w-[37px] py-1 text-center border-success-600 rounded bg-success-50 ml-2 leading-none'>
                        Paid
                    </span>
                </div>
                <span>₹5,325.00/-</span>
            </div>
        </div>
    );
}
