import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';

export default function Header(props) {
    const { handleClickClose, tagLine, title } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <div className='flex flex-row items-center justify-between mb-6 px-8'>
                    <div>
                        <h3 className='paragraph-large-medium'>{title}</h3>
                        <div className='flex flex-row items-center'>
                            <p className='paragraph-medium-italic text-neutral-500'>{tagLine}</p>
                        </div>
                    </div>
                    <span
                        className='cursor-pointer'
                        onClick={handleClickClose}>
                        <CloseIcon />
                    </span>
                </div>
            ) : (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='ml-1'>Back to banners</span>
                </div>
            )}
        </>
    );
}
