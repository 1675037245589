import React from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';

export default function MobileFooter(props) {
    const { setShowOrdersView } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        isMobileScreen && (
            <div className='px-4 pt-2 pb-4 md:fixed md:bottom-0 md:left-0 md:right-0 md:bg-white md:shadow-dropShadow'>
                <div className='flex flex-row justify-between'>
                    <div className='paragraph-small-regular m-auto'>
                        <span className='text-neutral-400 paragraph-small-semi-bold'>Dishes:</span>
                        <span className='pr-1'>02</span>|<span className='pl-1'>₹1,058.00/-</span>
                    </div>

                    <div
                        className='w-1/2 pl-2 mobile:w-28'
                        onClick={() => setShowOrdersView(true)}>
                        <LargePrimaryButton
                            label='View order'
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        )
    );
}
