import React, { useState } from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import DropdownSection from '../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import InputSection from '../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function Taxes(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        formState: { isDirty, errors, isValid },
        watch,
        clearErrors,
        reset,
        setValue,
    } = useFormContext();

    const handleSelection = () => {
        setShowFooter(true);
        clearErrors('gstNumber');
        setShowTabChangeWarningPopup(true);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateOrderingMode = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=taxes`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true, { shouldDirty: false });
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatData = (data) => {
        return {
            gst_applicable: data.gst_applicable.preference.value,
            gst_number: data.gst_number,
        };
    };

    const onSubmit = (data) => {
        updateOrderingMode(formatData(data.taxes));
        setShowFooter(false);
        setShowTabChangeWarningPopup(false);
    };

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setShowFooter(false);
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <div className='md:pb-20'>
                <div className='mb-6'>
                    <DropdownSection
                        name='taxes.gst_applicable.preference'
                        title='Taxes'
                        description='Select if taxes apply to your outlet and set your license number for your customers to see on the invoice.'
                        label='(Charge GST)'
                        labelPaddingB='pb-2'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        shadow='shadow-smallDropDownShadow'
                        menuItems={preferenceMenuItem}
                        rules={{ required: 'Please select a preference' }}
                        placeholder='Please select a preference'
                        handleSelection={handleSelection}
                    />
                </div>

                <div className='max-w-[312px] w-full mb-4 md:max-w-full'>
                    <InputSection
                        name='taxes.gst_number'
                        label='(GST number)'
                        labelStyle={`paragraph-medium-italic ${
                            !watch('taxes.gst_applicable.preference')?.value ? 'text-neutral-300' : 'text-neutral-500'
                        }`}
                        placeholder='Enter GST number'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('taxes.gst_applicable.preference')?.value}
                        rules={{
                            validate: (value) => {
                                if (value) return true;
                                if (watch('taxes.gst_applicable.preference')?.value) return 'Please enter GST number';
                            },
                        }}
                        handleInputChange={(value) => {
                            setShowTabChangeWarningPopup(true);
                            if (value) {
                                setShowFooter(true);
                            }
                        }}
                    />
                    {errors?.taxes?.gst_number && <ErrorMessage errors={errors?.taxes.gst_number} />}
                </div>
            </div>
        </Layout>
    );
}
