import React from 'react';

export default function OptionButtonSwitch(props) {
    const position = props.position ?? 'left';

    return (
        <>
            <div
                className={`h-6 w-[72px] ${
                    position === 'left'
                        ? 'bg-destructive-500'
                        : position === 'middle'
                          ? 'bg-secondary-700'
                          : 'bg-primary-500'
                } rounded-[20px] relative`}>
                <div
                    className={`h-5 w-5 bg-white absolute rounded-full top-0.5 ${
                        position === 'left' ? 'left-0.5' : position === 'middle' ? 'left-[26px]' : 'right-0.5'
                    }`}
                />
            </div>
        </>
    );
}
