import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';

export default function ListViewPresetList(props) {
    const {
        presetName,
        notificationTitle,
        description,
        modificationDate,
        modificationTime,
        handleClickViewDetails,
        cache,
        onResize,
        fetchDetailsOfPreset,
        setEditPreset,
        editPreset,
        el,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };
    useEffect(() => {
        cache.clearAll();
        onResize();
    }, [isShowDetails, onResize]);

    return (
        <>
            <div className='w-full px-4 py-3 border border-neutral-300 rounded-md md:mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700'>PRESET NAME:</h3>
                        <span className='paragraph-small-regular'>{presetName}</span>
                    </div>
                    <div className={`${isShowDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-1.5'>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>NOTIFICATION TITLE:</span>
                            <div className='paragraph-small-regular mt-1'>{notificationTitle}</div>
                        </div>
                        <div className='pt-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>NOTIFICATION DESCRIPTION:</span>
                            <div className='paragraph-small-regular mt-1'>{description}</div>
                        </div>
                        <div className='pt-2.5 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>MODIFICATION DATE:</span>
                            <div className='flex flex-row items-center ml-1'>
                                <CalenderIcon
                                    height={21}
                                    width={21}
                                />
                                <span className='paragraph-small-regular ml-1'>{modificationDate}</span>
                            </div>
                        </div>

                        <div className='pt-1.5 flex flex-row justify-between items-center'>
                            <div className='w-full'>
                                <span className='paragraph-overline-small text-neutral-700'>MODIFICATION TIME:</span>
                                <div className='flex flex-row justify-between w-full mt-1'>
                                    <div className='paragraph-small-regular flex flex-row items-center'>
                                        <TimerIcon
                                            height={21}
                                            width={21}
                                        />
                                        <span className='ml-1'>{modificationTime}</span>
                                    </div>
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => {
                                            fetchDetailsOfPreset(el);
                                            setEditPreset(!editPreset);
                                        }}>
                                        View full details
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
