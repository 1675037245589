import React from 'react';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function AddonAndMaximumDiscount() {
    const { watch } = useFormContext();

    return (
        <>
            <DropdownSection
                className='mb-6'
                title='Apply addon'
                description='Select whether you would like to apply addon to this offer.'
                name='apply_on_addon'
                placeholder='Select your preference'
                menuItems={preferenceMenuItem}
                rules={{ required: 'Please select addon to apply' }}
                zIndex='z-0'
            />

            <TitleDescription
                className='mb-4'
                title='Maximum discount'
                description='Select whether you would like to apply maximum discount this offer.'
            />

            <div className='flex flex-row justify-between w-full mb-6 lg:block gap-4 md:gap-0'>
                <DropdownSection
                    label='Select your preference'
                    name='do_add_maximum_discount'
                    menuItems={preferenceMenuItem}
                    rules={{ required: 'Please select preference' }}
                    labelPaddingB='pb-2'
                    placeholder='Select your preference'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    clearErrorName={['max_discount_amount']}
                    zIndex='z-0'
                />

                {watch('do_add_maximum_discount')?.value && (
                    <InputSection
                        boxHeight='12'
                        placeholder='Enter minimum cart value in rupees'
                        name='max_discount_amount'
                        label='(Enter amount in rupees)'
                        labelStyle={`paragraph-medium-italic ${
                            !watch('do_add_maximum_discount')?.value ? 'text-neutral-300' : 'text-neutral-500'
                        }`}
                        disabled={!watch('do_add_maximum_discount')?.value}
                        inputType='number'
                        rules={
                            watch('do_add_maximum_discount')?.value
                                ? { required: 'Please enter maximum discount' }
                                : { required: false }
                        }
                    />
                )}
            </div>
        </>
    );
}
