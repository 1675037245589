import React, { useEffect, useState } from 'react';
import { FilterDropDown } from './FilterDropDown';
import { IsMobileScreen } from '../../../Constants/Constants';
import CancelButton from '../../../Components/Buttons/CancelButton';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { StateCityDropdown } from './StateCityDropdown';

export default function FilterComponent(props) {
    const { handleClickClose, availableFilters, setAppliedFilter } = props;

    const isMobileScreen = IsMobileScreen();

    const [stateCitiesFilterData, setStateCitiesFilterData] = useState({ state: [], city: [] });

    const getAllStateCity = () => {
        let allStates = [];
        let allCities = [];

        availableFilters.state.forEach((stateObj) => {
            allStates.push(stateObj.name);
            allCities.push(...stateObj.city);
        });

        return { state: allStates, city: allCities };
    };

    useEffect(() => {
        setStateCitiesFilterData(getAllStateCity());
        // eslint-disable-next-line
    }, [availableFilters]);

    const [appliedFilterOnMobileScreen, setAppliedFilterOnMobileScreen] = useState({
        state: null,
        city: null,
        modes: availableFilters.orderingModes?.map((el) => {
            return {
                mode: el,
                status: null,
            };
        }),
    });

    const handleSelectState = (selectedItem) => {
        if (selectedItem === null) {
            setStateCitiesFilterData(getAllStateCity());
            return;
        }

        const selectedStatesCities = availableFilters.state.filter((el) => el.name.toLowerCase() === selectedItem)[0]
            ?.city;

        setStateCitiesFilterData((prevState) => {
            return { ...prevState, city: selectedStatesCities };
        });

        if (isMobileScreen) {
            setAppliedFilterOnMobileScreen((prevState) => {
                return { ...prevState, state: selectedItem };
            });
            return;
        }

        setAppliedFilter((prevState) => {
            return { ...prevState, state: selectedItem };
        });
    };

    const handleSelectCity = (selectedItem) => {
        if (isMobileScreen) {
            setAppliedFilterOnMobileScreen((prevState) => {
                return { ...prevState, city: selectedItem };
            });
            return;
        }

        setAppliedFilter((prevState) => {
            return { ...prevState, city: selectedItem };
        });
    };

    const handleClickApply = () => {
        setAppliedFilter(appliedFilterOnMobileScreen);
        handleClickClose();
    };

    const handleSelectOrderingMode = (label, selectedItem) => {
        if (isMobileScreen) {
            setAppliedFilterOnMobileScreen((prevState) => {
                let newFilter = { ...prevState };
                const updatedModes = newFilter.modes.map((modeObj) => {
                    if (modeObj.mode === label) {
                        return { ...modeObj, status: selectedItem };
                    } else {
                        return modeObj;
                    }
                });

                newFilter.modes = updatedModes;

                return newFilter;
            });
            return;
        }

        setAppliedFilter((prevState) => {
            let newFilter = { ...prevState };
            const updatedModes = newFilter.modes.map((modeObj) => {
                if (modeObj.mode === label) {
                    return { ...modeObj, status: selectedItem };
                } else {
                    return modeObj;
                }
            });

            newFilter.modes = updatedModes;

            return newFilter;
        });
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='-mx-2.5 mt-2 lg:-mx-1'>
                    <div className='inline-block align-top w-[213px] lg:w-[178px] mt-4 relative mx-[9.8px] lg:mx-1'>
                        <StateCityDropdown
                            label='State'
                            menuItems={stateCitiesFilterData.state}
                            setFilter={handleSelectState}
                        />
                    </div>

                    <div className='inline-block align-top w-[213px] lg:w-[178px] mt-4 relative mx-[9.8px] lg:mx-1'>
                        <StateCityDropdown
                            label='City'
                            menuItems={stateCitiesFilterData.city}
                            setFilter={handleSelectCity}
                        />
                    </div>

                    {availableFilters.orderingModes.map((el, index) => {
                        return (
                            <div
                                key={index}
                                className='inline-block align-top w-[213px] lg:w-[178px] mt-4 relative mx-[9.8px] lg:mx-1'>
                                <FilterDropDown
                                    label={el}
                                    setFilter={handleSelectOrderingMode}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className='fixed bg-black bg-opacity-50 inset-0 z-50 p-4 flex overflow-auto'>
                    <div className='h-fit max-h-[624px] rounded-xl bg-shades-50 m-auto mobile:w-full pl-4 py-4 w-full max-w-[343px]'>
                        <span className='paragraph-large-semi-bold'>Select filters</span>

                        <div className='overflow-y-auto scrollbar-style h-full pl-1 pr-2.5 -ml-1 max-h-[500px]'>
                            <div className='pt-[5px] mb-[5px] relative'>
                                <StateCityDropdown
                                    label='State'
                                    menuItems={stateCitiesFilterData.state}
                                    setFilter={handleSelectState}
                                />
                            </div>

                            <div className='pt-[5px] mb-[5px] relative'>
                                <StateCityDropdown
                                    label='City'
                                    menuItems={stateCitiesFilterData.city}
                                    setFilter={handleSelectCity}
                                />
                            </div>

                            {availableFilters.orderingModes.map((el, index) => (
                                <div
                                    className='pt-[5px] mb-[5px] relative'
                                    key={index}>
                                    <FilterDropDown
                                        label={el}
                                        setFilter={handleSelectOrderingMode}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='flex flex-row justify-between mt-4 pr-4'>
                            <CancelButton handleClickCancel={handleClickClose} />

                            <div
                                className='w-full'
                                onClick={handleClickApply}>
                                <LargePrimaryButton label='Apply' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
