import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { useWindowSize } from '@uidotdev/usehooks';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

export default function Tabs(props) {
    const { setShowWarningPopup } = props;

    const { tab } = useParams();

    const {
        formState: { isDirty },
    } = useFormContext();

    const navigate = useNavigate();

    const screenSize = useWindowSize();

    const handleNavigation = (route) => {
        if (isDirty) {
            setShowWarningPopup({ status: true, route: `/settings/global/${route}` });
            return;
        }

        navigate(`/settings/global/${route}`);
    };

    const isMobileScreen = IsMobileScreen();

    const menuItems = [
        { item: 'App settings', onClick: () => handleNavigation('app-settings'), link: 'app-settings' },
        { item: 'Brand', onClick: () => handleNavigation('brand'), link: 'brand' },
        { item: 'SMS gateway', onClick: () => handleNavigation('sms-gateway'), link: 'sms-gateway' },
        { item: 'Maps', onClick: () => handleNavigation('maps'), link: 'maps' },
        { item: 'Languages', onClick: () => handleNavigation('languages'), link: 'languages' },
        { item: 'About us', onClick: () => handleNavigation('about-us'), link: 'about-us' },
        { item: 'Contact us', onClick: () => handleNavigation('contact-us'), link: 'contact-us' },
        { item: 'Legal', onClick: () => handleNavigation('legal'), link: 'legal' },
        { item: 'Cashback settings', onClick: () => handleNavigation('cashback-settings'), link: 'cashback-settings' },
        { item: 'Ordering mode', onClick: () => handleNavigation('ordering-mode'), link: 'ordering-mode' },
    ];

    return (
        <>
            {!isMobileScreen ? (
                <div
                    className='flex flex-col mt-4 pr-6 mr-5 border-r min-w-[200px] border-neutral-300 overflow-auto [&::-webkit-scrollbar]:hidden lg:pr-4 lg:mr-3 lg:min-w-[190px]'
                    style={{ height: screenSize.height - 147 }}>
                    {menuItems.map((el, index) => (
                        <div
                            className='min-w-[175px] w-full mb-4 lg:mb-2 last:pb-8'
                            key={index}>
                            <MultipleTab
                                label={el.item}
                                index={index}
                                onClick={() => handleNavigation(el.link)}
                                isActive={el.link === tab}
                                maxWidth='max-w-[175px]'
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className='mb-4'>
                    <DropDownTabs
                        selectedItem={menuItems.find((el) => el.link === tab)}
                        menuItems={menuItems}
                        dropDownPadding='pr-2'
                        dropDownHeight='overflow-auto max-h-80 scrollbar-style'
                    />
                </div>
            )}
        </>
    );
}
