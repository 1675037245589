import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputSection from '../../../Components/InputSection';
import DropDownUnableDisableSection from '../../../Components/DropDownUnableDisableSection';
import _ from 'lodash';
import AdvanceSetting from './Components/AdvanceSetting';
import EarningsDelaySection from './Components/EarningsDelaySection';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import Layout from '../../../Components/Layout';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import Loader from './Components/Loader';

export default function CashbackSettings(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        watch,
        setValue,
        getValues,
        reset,
        formState: { errors, isDirty, isValid },
    } = useFormContext();

    const formatData = (data) => {
        const formattedData = {};

        formattedData.is_cashback_functionality_enabled = data.is_cashback_functionality_enabled;
        formattedData.reward_point_expiration_duration = data.reward_point_expiration_duration;
        formattedData.promo_consolidation = data.promo_consolidation;
        formattedData.signup_cashback = data.signup_cashback;
        formattedData.cashback_earning_percentage = data.cashback_earning_percentage;
        formattedData.ordering_mode_cashback_configurations = getValues('orderingModes').map((mode) => {
            let cashback_redeem_percentage, cashback_settlement_delay;

            const index = getValues('orderingModes').findIndex((item) => item.id === mode.id);

            if (data.sameAdvanceConfiguration) {
                cashback_redeem_percentage = data.same_cashback_redeem_percentage
                    ? parseFloat(data?.same_cashback_redeem_percentage)
                    : 0;
            } else {
                cashback_redeem_percentage = data?.cashback_redeem_percentage[index]
                    ? parseFloat(data?.cashback_redeem_percentage[index])
                    : 0;
            }

            if (data.sameEarningDelay) {
                cashback_settlement_delay =
                    parseFloat(data?.same_earning_delay?.hour) * 60 + parseFloat(data?.same_earning_delay?.minute);
            } else {
                cashback_settlement_delay =
                    parseFloat(data?.earning_delay?.[index]?.hour) * 60 +
                    parseFloat(data?.earning_delay?.[index]?.minute);
            }

            return {
                restaurant_ordering_mode_id: mode.id,
                cashback_redeem_percentage,
                cashback_settlement_delay,
            };
        });

        return formattedData;
    };

    const onSubmit = (data) => {
        updateCashBackSetting(formatData(data.cashback_settings));
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateCashBackSetting = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=cashback_settings`, {
                ...params,
            });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickSave = () => {
        _.isEmpty(errors) && handleSubmit(onSubmit)();
    };

    return watch('isLoadingForm') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleClickSave}
            handleClickCancel={() => handleDiscardForm()}
            showFooter={isDirty}>
            <div className='max-w-[636px] pb-4 md:max-w-full'>
                <DropDownUnableDisableSection
                    marginBetween='mb-2'
                    className='mb-6'
                    name='cashback_settings.is_cashback_functionality_enabled'
                    title='Cashback settings'
                    description='Enable or disable cashback for your mobile application and set the parameters for cashback.'
                />

                <div className='mb-4'>
                    <InputSection
                        name='cashback_settings.cashback_earning_percentage'
                        title='Cashback percentage'
                        description='Enter the percent of cashback earnings that the customers will get on the order amount.'
                        placeholder='Enter cashback in percentage'
                        shadow='shadow-smallDropDownShadow'
                        enteringLimit={100}
                        inputType='number'
                        dontShowError
                    />
                    {errors?.cashback_settings?.cashback_earning_percentage && (
                        <ErrorMessage errors={errors?.cashback_settings.cashback_earning_percentage} />
                    )}
                </div>

                <InputSection
                    className='mb-4'
                    name='cashback_settings.reward_point_expiration_duration'
                    title='Cashback expiry'
                    description='Enter the expiry duration of the cashback rewarded to the customer for the order. '
                    placeholder='Enter cashback expiry in days'
                    shadow='shadow-smallDropDownShadow'
                />

                <div className='mb-6 pb-6 border-b border-nuetral-300'>
                    <InputSection
                        name='cashback_settings.signup_cashback'
                        title='Signup cashback'
                        description='Enter the amount of cashback a user will get when performing sign-up action within the mobile application.'
                        placeholder='Enter cashback amount in rupees'
                        shadow='shadow-smallDropDownShadow'
                    />
                </div>

                <AdvanceSetting />

                {/* <InputSection
                        className='mb-4'
                        name='cashback_settings.cashback_earning_percentage'
                        title='Cashback redeem percentage'
                        placeholder='Enter cashback in percentage'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('advanceSettingEnable')}
                    /> */}

                <EarningsDelaySection />
            </div>
        </Layout>
    );
}
