import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import HomeScreenlayoutTable from './HomeScreenlayoutTable';
import { ReactComponent as EditIcon } from '../../../../../../Assets/edit.svg';

export default function HomeSection(props) {
    const { setShowFooter, setShowTabChangeWarningPopup } = props;

    const [isRearrange, setIsRearrange] = useState(false);

    return (
        <>
            <div
                className='max-w-[153px] mb-4 md:max-w-full md:pb-2 md:mb-0 cursor-pointer'
                onClick={() => {
                    setIsRearrange((prevState) => !prevState);
                }}>
                <LargePrimaryButton
                    label='Edit layout'
                    leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                    leftIconClick={<EditIcon stroke='#C4BEED' />}
                />
            </div>

            <HomeScreenlayoutTable
                isRearrange={isRearrange}
                setShowFooter={setShowFooter}
                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
            />
        </>
    );
}
