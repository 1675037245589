import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../../Components/Buttons/Button';

import { IsMobileScreen } from '../../../../../../Constants/Constants';
import PriceInputForm from './PriceInputForm';
import { useFormContext } from 'react-hook-form';

export default function EditPricePopUp(props) {
    const { handleClickClose, priceDetails } = props;

    const isMobileScreen = IsMobileScreen();

    const { setValue, watch } = useFormContext();

    const variantNamePrefix = `${priceDetails.id}].prices[${priceDetails?.orderingModeId}]`;

    useEffect(() => {
        setValue(tempVariantName, watch(variantNamePrefix));
    }, []);

    const tempVariantName = `temp.${priceDetails.id}].prices[${priceDetails?.orderingModeId}]`;

    const handleClickSave = () => {
        setValue(variantNamePrefix, watch(tempVariantName));
        handleClickClose();
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md:block md:overflow-hidden'>
                <div className='max-w-[725px] w-full rounded-xl bg-shades-50 pt-6 pb-6 px-8 m-auto max-h-[90vh] overflow-auto scrollbar-style md:max-w-full md:rounded-none md:py-4 md:px-4 md:h-screen md:overflow-hidden'>
                    {!isMobileScreen ? (
                        <div className='flex flex-row items-center justify-between mb-6'>
                            <div>
                                <h3 className='paragraph-large-medium'>Edit price</h3>
                                <div className='flex flex-row items-center'>
                                    <p className='paragraph-medium-italic text-neutral-500'>
                                        Edit existing price for the ordering mode
                                    </p>
                                </div>
                            </div>

                            <span
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </span>
                        </div>
                    ) : (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to delivery charges</span>
                        </div>
                    )}

                    <PriceInputForm
                        id={priceDetails?.id}
                        variantNamePrefix={tempVariantName}
                        isEditing
                    />

                    <div className='flex flex-row'>
                        <div
                            className='mr-2.5 w-1/2 cursor-pointer'
                            onClick={handleClickClose}>
                            <LargeDestructiveButton label='Discard' />
                        </div>

                        <div
                            className='ml-2.5 w-1/2'
                            onClick={handleClickSave}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
