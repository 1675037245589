import React from 'react';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { BlankComponentTable } from '../../../Components/BlankComponent/BlankComponent';
import { formatDate, formatPaymentMethodType, getPaymentMethodIcon } from '../functions';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';

export default function Table(props) {
    const { tableDetails, isListLoading, setPopupOrderId, setOrderDetails } = props;

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words table-row-bg'>
                    <thead>
                        <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                            <th className='px-6 min-w-[147px] lg:min-w-[112px] paragraph-overline-small text-neutral-700'>
                                CODE
                            </th>
                            <th className='px-6 min-w-[279px] lg:min-w-[213px] paragraph-overline-small text-neutral-700'>
                                CUSTOMER NAME
                            </th>
                            <th className='px-6 min-w-[203px] lg:min-w-[160px] paragraph-overline-small text-neutral-700'>
                                ORDER AMOUNT
                            </th>
                            <th className='px-6 min-w-[213px] lg:min-w-[164px] paragraph-overline-small text-neutral-700'>
                                ORDER DATE
                            </th>
                            <th className='pl-6 min-w-[223px] paragraph-overline-small text-neutral-700'>
                                PAYMENT MODE
                            </th>
                        </tr>
                    </thead>

                    {isListLoading ? (
                        <tbody>
                            <TableLoader
                                columns={5}
                                numberOfRow={10}
                            />
                        </tbody>
                    ) : !tableDetails.length ? (
                        <tr>
                            <td colSpan={6}>
                                <TableBlankComponentView previewText={'No Content Available'} />
                            </td>
                        </tr>
                    ) : (
                        <tbody>
                            {tableDetails?.map((el, index) => {
                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td className='px-6'>
                                            <span
                                                className='cursor-pointer text-primary-500'
                                                onClick={() => {
                                                    setPopupOrderId(el.id);
                                                    setOrderDetails(el);
                                                }}>
                                                {el.verification_code}
                                            </span>
                                        </td>

                                        <td className='px-6'>{el.customer_name}</td>

                                        <td className='px-6'>₹ {el.amount} /-</td>

                                        <td className='px-6'>{formatDate(el.created_at, 'date')}</td>

                                        <td className='pl-6'>
                                            <div className='flex flex-row items-center'>
                                                {getPaymentMethodIcon(el.payment_method)}

                                                <span className='paragraph-small-regular ml-1'>
                                                    {formatPaymentMethodType(el.payment_method)}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
        </>
    );
}
