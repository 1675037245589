import React, { useEffect, useState } from 'react';
import DishDetailsList from './DishDetailsList';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate } from 'react-router-dom';
import { getCategoryDetails } from '../functions';
import { useSelector } from 'react-redux';
import { CategoryLoader } from './DishDetailsSection/Loader';

export default function CategoryDetails(props) {
    const { selectedCategoryDish, setDishOperationState, clickedCategory } = props;

    const screenSize = useWindowSize();

    const navigate = useNavigate();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(true);

    const [categoryDetails, setCategoryDetails] = useState(null);

    const getAllCategoryDetails = async (categoryId) => {
        const response = await getCategoryDetails(selectedRestaurantId, categoryId);

        setIsLoading(false);

        setCategoryDetails(response);
    };

    useEffect(() => {
        !!clickedCategory?.id && getAllCategoryDetails(clickedCategory?.id);
    }, [clickedCategory]);

    const isSubcategory = clickedCategory?.type !== 'category';

    return (
        <>
            {isLoading ? (
                <CategoryLoader />
            ) : (
                <div
                    className='overflow-auto scrollbar-style -mr-8 pr-8'
                    style={{ height: screenSize.height - 300 }}>
                    <div className='mb-4 md:mb-2'>
                        <DishDetailsList
                            categoryDetails={categoryDetails}
                            isSubcategory={isSubcategory}
                        />
                    </div>

                    <div className='flex flex-row mb-4 md:block md:mb-0'>
                        <EditItem
                            onClick={() => navigate('/menu/add-sub-category', { state: { selectedCategoryDish } })}
                            label='Add sub-category'
                            isDisable={isSubcategory && !selectedCategoryDish?.subCategory?.length}
                        />
                        <EditItem
                            label='Map item'
                            isDisable={!isSubcategory && selectedCategoryDish?.subCategory?.length}
                            onClick={() => setDishOperationState?.('map')}
                        />
                    </div>

                    <div className='flex flex-row md:block'>
                        <EditItem
                            label='Copy existing item'
                            isDisable={!isSubcategory && selectedCategoryDish?.subCategory?.length}
                            onClick={() => setDishOperationState?.('copy')}
                        />
                        <EditItem
                            label='Move existing item'
                            isDisable={!isSubcategory && selectedCategoryDish?.subCategory?.length}
                            onClick={() => setDishOperationState?.('move')}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export const EditItem = (props) => {
    const { label, isDisable, onClick } = props;

    return (
        <div
            onClick={() => !isDisable && onClick?.()}
            className={`flex flex-row items-center mr-4 ${!isDisable ? 'cursor-pointer' : 'cursor-not-allowed'} md:mb-2`}>
            <AddIcon
                stroke={!isDisable ? '#6C5DD3' : '#C4BEED'}
                height={24}
            />

            <span className={`paragraph-medium-medium ${!isDisable ? 'text-primary-500' : 'text-primary-200'} ml-0.5`}>
                {label}
            </span>
        </div>
    );
};
