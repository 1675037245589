import React from 'react';
import { InputArea } from '../../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';

export default function AddEditNotePopup(props) {
    const { type = 'add', title, note, handleClickClose } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto px-4'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:p-4'>
                    <div className='flex flex-row justify-between items-center mb-3'>
                        <div>
                            <div className='paragraph-large-medium'>{type === 'add' ? 'Add' : 'Edit'} note</div>
                            <div className='paragraph-medium-italic text-neutral-500'>
                                {type === 'add' ? 'Add' : 'Edit'} note for {title}
                            </div>
                        </div>

                        <div
                            onClick={() => handleClickClose()}
                            className='cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <InputArea
                            inputValue={type === 'edit' ? note : ''}
                            label='Custom note'
                            placeholder='Type note'
                            shadow='shadow-smallDropDownShadow'
                            boxHeight='h-40'
                            resizeNone='resize-none'
                        />
                    </div>

                    <div className='cursor-pointer'>
                        <LargePrimaryButton label='Save' />
                    </div>
                </div>
            </div>
        </>
    );
}
