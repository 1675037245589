import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { ReactComponent as VegIcon } from '../../../../../../Assets/vegetable-icon.svg';
import { CheckBox } from '../../../../../../Components/FormControl/FormControls';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';

export default function ReadyOrderPopup(props) {
    const { handleClickClose, items } = props;

    const [selectedItems, setSelectedItems] = useState([]);

    const handleItemClick = (itemName) => {
        const itemIndex = selectedItems.indexOf(itemName);
        if (itemIndex === -1) {
            setSelectedItems([...selectedItems, itemName]);
        } else {
            const updatedItems = [...selectedItems];
            updatedItems.splice(itemIndex, 1);
            setSelectedItems(updatedItems);
        }
    };

    const renderDishItems = () => {
        let items = [];
        props?.items?.forEach((el, index) => {
            for (let i = 0; i < el.quantity; i++) {
                items.push(
                    <div
                        className='pt-4 flex flex-row justify-between'
                        key={index + `${i}`}>
                        <div className='flex flex-row items-center label-text-format pr-4'>
                            <CheckBox
                                label={
                                    <>
                                        {el.displayName}
                                        <span className='inline-block align-middle pl-2'>
                                            <VegIcon />
                                        </span>
                                    </>
                                }
                                optionId={el.name + i + 'dish'}
                                paddingL='4 md:pl-2'
                                labelStyle='paragraph-medium-regular block overflow-hidden'
                                // checked={selectedItems.includes(el.name)}
                                onChange={() => handleItemClick(el)}
                            />
                        </div>
                    </div>
                );
            }
        });
        return items;
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[629px] w-full rounded-xl bg-shades-50 px-6 py-6 m-auto md:p-4'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-4'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Ready Dishes</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Select dishes you would like to serve
                            </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className=''>
                        <span className='paragraph-large-medium mr-2'>Toppings-veg (giant slices)</span>
                        <span className='paragraph-medium-italic text-neutral-500'>({selectedItems?.length}/04)</span>
                    </div>

                    <div className='mb-12 md:mb-4'>{renderDishItems()}</div>

                    <div className='flex flex-row justify-between sm:block'>
                        <CheckBox
                            label='Select/deselect all'
                            optionId='selectDeselectAll'
                            paddingL='4 md:pl-2'
                            labelStyle='paragraph-large-medium'
                            checked={selectedItems.length === items.length}
                        />
                        <div className='max-w-[151px] w-full sm:max-w-full sm:mt-6'>
                            <LargePrimaryButton label='Ready order' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
