import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';

export default function ReservationStatusDropDown(props) {
    const { handleChangeReservationStatus, reservationStatus } = props;

    const handleClickItem = (item) => {
        setLabel(item);
        handleChangeReservationStatus(item.item);
    };

    const menuItems = [
        {
            item: 'Seated',
            textColor: '#6C5DD3',
            dropDownColor: '#6C5DD3',
        },
        {
            item: 'Not confirmed',
            textColor: '#131126',
            dropDownColor: '#131126',
        },
        {
            item: 'Confirmed',
            textColor: '#16A34A',
            dropDownColor: '#16A34A',
        },
        {
            item: 'Cancelled',
            textColor: '#DC2626',
            dropDownColor: '#DC2626',
        },
        {
            item: 'Late',
            textColor: '#ffa704',
            dropDownColor: '#FFA704',
        },
    ];

    const [label, setLabel] = useState(menuItems.filter((el) => reservationStatus === el.item && el)[0]);

    return (
        <div className='dropdown-icon'>
            <Menu
                as='div'
                className='relative inline-block text-left'>
                <div>
                    <Menu.Button
                        className={`paragraph-medium-regular inline-flex items-center w-full justify-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mobile:text-sm remove-shadow`}
                        style={{ color: label.textColor }}>
                        {label.item}
                        <DropDownIcon
                            className='drop-down-icon-rotate h-4'
                            fill={`${label.dropDownColor}`}
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items className='absolute shadow-medium pb-4 z-[1] px-4 w-full min-w-[137px] origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none paragraph-small-medium left-[9px] mobile:left-0'>
                        {menuItems.map((el, index) => (
                            <div
                                className='pt-4 cursor-pointer'
                                key={index}
                                onClick={() => handleClickItem(el)}>
                                <Menu.Item>
                                    <span style={{ color: el.textColor }}>{el.item}</span>
                                </Menu.Item>
                            </div>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
