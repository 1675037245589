import React, { useEffect, useState } from 'react';
import DishWithUseForm from './Components/DishWithUseForm';
import { useLocation, useParams } from 'react-router-dom';
import { getBadgeList, getDishDetails, getQuantityUnits } from '../Components/Catalogue/functions';
import { gstSlab, preferenceMenuItem, showHideMenuItems, vegNonVegMenuItem } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Loader from './Components/Loader';

export default function MenuManagementDish() {
    const { dishId } = useParams();

    const location = useLocation();

    const { selectedCategoryDish } = location.state || {};

    const [defaultDishFormState, setDefaultDishFormState] = useState({});

    const getDetailsOfDish = async () => {
        try {
            const response = await getDishDetails(dishId);

            console.log('const response = ', response);

            formatResponse(response);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const getPrice = (orderingModes, availableQuantityUnits) => {
        const prices = {};

        orderingModes.forEach((mode) => {
            prices[mode.restaurant_ordering_mode_id] = {
                price: mode.price.toString(),
                compare_price: mode.compare_price.toString(),
                quantity: mode.quantity !== null ? mode?.quantity?.toString() : 0,
                calories: mode.calories,
                quantityUnit: availableQuantityUnits?.find((el) => el?.value?.id === mode?.quantity_unit_id),
                serves: mode?.serves,
                servesUnit: mode?.serves_unit,
                allergens: mode?.allergens?.map((el) => el?.id),
            };
        });

        return prices;
    };

    const getTitle = (translations) => {
        const titleDescription = { title: [] };

        for (let key in translations.title) {
            titleDescription.title[parseInt(key)] = translations.title[key];
        }

        return titleDescription;
    };

    const getImageDetails = async (image) => {
        let imageDetails = {
            id: image?.id,
            image_url: image?.image_url,
            size: {},
        };

        const loadImage = (src) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    resolve(img);
                };
            });
        };

        const img = await loadImage(image?.image_url);
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        const calculatedWidth = 150 * aspectRatio;

        imageDetails.size = { width: calculatedWidth, height: 150 };

        return imageDetails;
    };

    const formatVariantAddon = (data) => {
        return data?.map((el) => {
            return { value: el, label: el?.internal_name, isSelected: true };
        });
    };

    const formatVariant = async (variants, availableQuantityUnits) => {
        const prices = await Promise.all(
            variants[0]?.options?.map(async (el, index) => {
                const titleDescription = getTitle(el.translations);

                const food_type = vegNonVegMenuItem?.find((vegNonVegEl) => vegNonVegEl.value === el?.food_type);

                const formattedPrice = getPrice(el?.ordering_modes, availableQuantityUnits);

                const firstKey = Object.keys(formattedPrice)[0];

                const firstValue = formattedPrice[firstKey];

                const variantPriceArray = Array(variants[0]?.options?.length).fill(null);

                variantPriceArray[index] = firstValue;

                return {
                    id: index,
                    variantId: el.id,
                    food_type,
                    ...titleDescription,
                    prices: {
                        ...formattedPrice,
                        pricing: { variantPrice: variantPriceArray },
                    },
                    imageDetails: !!el?.image_url ? await getImageDetails(el?.image_url) : {},
                    addon: formatVariantAddon(el?.addons),
                };
            })
        );

        const variantGroupTitle = [null];
        const variantGroupDescription = [null];

        for (let key in variants[0]?.translations?.title) {
            variantGroupTitle[parseInt(key)] = variants[0]?.translations?.title[key];
            variantGroupDescription[parseInt(key)] = variants[0]?.translations?.description[key];
        }

        const has_aggmark_show = showHideMenuItems?.find((el) => el.value == variants[0]?.show_agmark);

        return {
            variantGroupTitle,
            variantGroupDescription,
            has_aggmark_show,
            do_add_variant: true,
            pricing: { variantPrice: prices, activeVariant: 0 },
            variantGroupId: variants[0]?.id,
        };
    };

    const formatBasicDetails = (data) => {
        const translations = data.translations;

        let details = {
            display: [null],
            description: [null],
            food_type: vegNonVegMenuItem?.find((el) => el.value === data?.food_type),
            nickname: data?.internal_name,
            imageDetail: { image_url: data?.image?.image_url },
            category: { label: selectedCategoryDish?.category?.internal_name, value: selectedCategoryDish?.category },
            show_agmark: preferenceMenuItem.find((el) => el.value === !!data.show_agmark),
        };

        const img = new Image();

        img.src = data?.image?.image_url;

        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;

            const calculatedWidth = 150 * aspectRatio;

            details.imageDetail.size = { width: calculatedWidth, height: 150 };
        };

        Object.keys(translations.title).forEach((key) => {
            const index = parseInt(key, 10);
            details.display[index] = translations?.title?.[key];
            details.description[index] = translations?.description?.[key];
        });

        return { details };
    };

    const formatExposeDish = (data) => {
        const exposeDish = data?.ordering_modes?.map((el, index) => {
            return {
                ...el,
                id: el.restaurant_ordering_mode_id,
                display_name: el.restaurant_ordering_mode_name,
                status: true,
            };
        });

        return { exposeDish };
    };

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    const formatBadge = async (badges) => {
        const response = await getBadgeList();

        const formattedBadges = response?.badges?.map((el) => ({
            label: el?.translations?.display_name?.[selectedLanguage],
            style: { background: el.color },
            className: 'px-2 py-1 text-white rounded w-fit paragraph-small-regular',
            value: el,
            isSelected: badges?.some((badgeEl) => badgeEl.id === el.id),
        }));

        let badge = { availableBadge: formattedBadges };

        return badge;
    };

    const formatTiming = (data) => {
        const ordering_modes = data?.ordering_modes?.map((el) => {
            return {
                schedules: el?.schedules,
                time_slot_setting: el.time_slot_setting,
                id: el.restaurant_ordering_mode_id,
            };
        });

        const requiredArray = {};

        const formatTime = (time) => {
            const [hours, minutes] = time.split(':');
            return `${hours}:${minutes}`;
        };

        ordering_modes.forEach((mode) => {
            const modeId = mode.id;
            const timingTypeKey = `timings_${modeId}_timingType`;
            const timingsKey = `timings_${modeId}`;

            if (mode.time_slot_setting === 'same_time_slot_for_all_days') {
                requiredArray[timingTypeKey] = { label: 'Specific time for all days', value: mode.time_slot_setting };

                requiredArray[timingsKey] = mode.schedules
                    .filter((el) => el.day_of_week === 1)
                    .map((schedule) => ({
                        start_time: formatTime(schedule.start_time),
                        end_time: formatTime(schedule.end_time),
                    }));
            } else if (mode.time_slot_setting === 'different_time_slots_for_different_days') {
                requiredArray[timingTypeKey] = { label: 'Specific time for each day', value: mode.time_slot_setting };

                const weekArray = [[], [], [], [], [], [], []];

                mode.schedules.forEach((schedule) => {
                    const dayIndex = schedule.day_of_week - 1;
                    weekArray[dayIndex].push({
                        start_time: formatTime(schedule.start_time),
                        end_time: formatTime(schedule.end_time),
                    });
                });

                requiredArray[`${timingsKey}_timing`] = weekArray;
                requiredArray[timingsKey] = weekArray;
            } else {
                requiredArray[timingTypeKey] = { label: 'Full time', value: mode.time_slot_setting };

                requiredArray[timingsKey] = [];
            }
        });

        return requiredArray;
    };

    const formatAddon = (data) => {
        const pricing = {
            dishPrice: {
                addon: data?.addon_groups?.map((el) => {
                    return { label: el.internal_name, value: el, isSelected: true };
                }),
            },
        };

        return { pricing };
    };

    const formatUpSellingItem = (upSellingItem) => {
        const selectedDish = upSellingItem?.map((el) => {
            return {
                label: el.internal_name,
                category: {
                    label: el.category?.find((categoryEl) => categoryEl.language_id === selectedLanguage)?.title,
                    value: el.category,
                },
                value: el,
                isSelected: true,
            };
        });

        return { upSelling: { selectedDish } };
    };

    const formatHasUpSellDish = (upSellingItem) => {
        return { upSellInCart: preferenceMenuItem[upSellingItem === 0 ? 1 : 0] };
    };

    const formatOrderingMode = (data, availableQuantityUnits, food_type, addon_groups) => {
        const pricing = getPrice(data, availableQuantityUnits);

        const firstKey = Object.keys(pricing)[0];

        const firstValue = pricing[firstKey];

        const formattedPricing = {
            pricing: {
                dishPrice: {
                    prices: { ...pricing, pricing: { dishPrice: firstValue } },
                    food_type: vegNonVegMenuItem?.find((vegNonVegEl) => vegNonVegEl.value === food_type),
                    addon: addon_groups?.map((el) => {
                        return { label: el.internal_name, value: el, isSelected: true };
                    }),
                },
            },
        };

        return formattedPricing;
    };

    const formatPackingGst = (orderingModes, availableQuantityUnits) => {
        const dishGstSlab = gstSlab?.find((el) => el?.value === parseFloat(orderingModes[0]?.gst_slab));

        const dishPackingPrice = orderingModes[0]?.packaging_charge;

        return { dishPackingPrice, dishGstSlab };
    };

    const formatResponse = async (data) => {
        console.log(` from editing initialize ==>`, data);

        const quantityUnits = await getQuantityUnits();

        const availableQuantityUnits = quantityUnits?.quantity_units?.map((el) => ({ label: el.unit, value: el }));

        const variants = !!data?.variants?.length
            ? await formatVariant(data?.variants, availableQuantityUnits)
            : formatOrderingMode(data?.ordering_modes, availableQuantityUnits, data?.food_type, data?.addon_groups);

        const packingGstPrice = formatPackingGst(
            !!data?.variants?.length ? data?.variants?.[0]?.options?.[0] : data?.ordering_modes,
            availableQuantityUnits
        );

        const basicDetails = formatBasicDetails(data);

        const exposeDish = formatExposeDish(data);

        const badge = await formatBadge(data?.badges);

        const timing = formatTiming(data);

        const upsellingItem = formatUpSellingItem(data?.upselling_items);

        const hasUpSellDish = formatHasUpSellDish(data?.upselling_item);

        setDefaultDishFormState({
            ...basicDetails,
            ...exposeDish,
            ...badge,
            ...timing,
            ...upsellingItem,
            ...hasUpSellDish,
            ...variants,
            ...packingGstPrice,
            availableQuantityUnits,
            initialDishForm: data,
        });
    };

    useEffect(() => {
        !!dishId && getDetailsOfDish();
    }, []);

    return (
        <>
            {!!dishId && _.isEmpty(defaultDishFormState) ? (
                <Loader />
            ) : (
                <DishWithUseForm defaultDishFormState={defaultDishFormState} />
            )}
        </>
    );
}
