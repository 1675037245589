import React, { useState } from 'react';
import { Tab } from '../../../Components/Tabs/Tabs';
import { Description, SingleNotification } from './Components';

const NotificationsPopup = (props) => {
    const [activeTab, setActivetab] = useState(0);

    const handleClickTab = (index) => {
        setActivetab(index);
    };

    const availabilityTab = [
        { label: 'All', badgeText: '5' },
        { label: 'Box', badgeText: '3' },
        { label: 'Credits', badgeText: '1' },
    ];

    const [notificationDetails, setNotificationDetails] = useState([
        {
            mode: 'dineIn',
            descriptions: (
                <Description
                    focusContent={['Margherita Pizza']}
                    description=':focuscontent: is live again from the dish availability'
                    focusContentColor={['text-primary-500']}
                />
            ),
            notificationTime: '1 hr ago',
        },
        {
            mode: 'takeAway',
            descriptions: (
                <Description
                    focusContent={['Delivery order']}
                    description='New :focuscontent: order arrived! Accept it now!'
                    focusContentColor={['text-primary-500']}
                />
            ),
            notificationTime: '2 hrs ago',
        },
        {
            mode: 'dineIn',
            descriptions: (
                <Description
                    focusContent={['Peppy Paneer Pizza']}
                    description=':focuscontent: is live again from the dish availability'
                    focusContentColor={['text-primary-500']}
                />
            ),
            notificationTime: '1 hr ago',
        },
        {
            mode: 'delivery',
            descriptions: (
                <Description
                    focusContent={['Rakesh Kumar', '5 start']}
                    description=':focuscontent: got :focuscontent: rating for his recent delivery'
                    focusContentColor={['text-primary-500', 'text-tertiary-800']}
                />
            ),
            notificationTime: '1 hr ago',
        },
    ]);

    const handleClickClear = () => {
        setNotificationDetails([]);
    };

    return (
        <>
            <div className='border border-neutral-300 bg-white rounded-lg min-w-[391px] sm:min-w-[365px] mobile:min-w-min mobile:w-full shadow-large md:mr-[5px] mobile:mr-0 md:px-4 mobile:px-2 mobile:pt-2 mobile:pb-4 z-50 cursor-auto'>
                <div className='flex justify-between paragraph-overline-small uppercase px-6 pt-6 pb-3'>
                    <span>Notifications</span>
                    <span
                        className='text-[#EF4444] cursor-pointer'
                        onClick={handleClickClear}>
                        Clear All
                    </span>
                </div>

                <div className='flex flex-row pb-3 px-6'>
                    {availabilityTab.map((el, index) => (
                        <div
                            onClick={() => handleClickTab(index)}
                            className='mr-4 w-fit cursor-pointer'
                            key={index}>
                            <Tab
                                {...el}
                                isActive={index === activeTab}
                                labelStyle='paragraph-x-small-regular'
                                badgeTextPadding='px-2'
                                tabHeight='h-9'
                                badgeTextHeight='h-5 leading-normal'
                            />
                        </div>
                    ))}
                </div>

                <div>
                    {notificationDetails.map((el, index) => {
                        return (
                            <React.Fragment key={index}>
                                <SingleNotification {...el} />
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default NotificationsPopup;
