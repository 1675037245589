import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as SelectIcon } from '../../../Assets/select.svg';

export default function SelectionButton(props) {
    const {
        setIsSelectionEnable,
        isSelectionEnable,
        handleClickDelete,
        isDisable,
        handleClickCancel,
        styles,
        shoBulkSelectLabelMobile,
        disabled,
    } = props;

    return (
        <>
            <div className={`flex flex-row justify-between ${isSelectionEnable && 'w-full'} ${styles}`}>
                {!isSelectionEnable ? (
                    <div
                        className='min-w-[156px] md:min-w-[64px] mobile:min-w-[40px] w-full'
                        onClick={() => {
                            setIsSelectionEnable(true);
                        }}>
                        <LargePrimaryButton
                            label='Bulk select'
                            hideLabel={!shoBulkSelectLabelMobile && 'md:hidden'}
                            leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                            leftIconClick={<SelectIcon stroke='#C4BEED' />}
                            disabled={disabled}
                        />
                    </div>
                ) : (
                    <div className='flex flex-row items-center md:w-full justify-between'>
                        <div
                            className='max-w-[125px] mr-4 mobile:mr-2 md:w-1/2 md:max-w-full cursor-pointer'
                            onClick={handleClickCancel}>
                            <LargeTertiaryButton
                                isDefault={false}
                                label='Cancel'
                                hideLabel='lg:hidden'
                                leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                leftIconClick={<CloseIcon stroke='#C4BEED' />}
                            />
                        </div>

                        <div
                            className='max-w-[192px] md:w-1/2 md:max-w-full'
                            onClick={handleClickDelete}>
                            <LargeDestructiveButton
                                label='Delete selected'
                                leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                hideLabel='lg:hidden'
                                disabled={isDisable}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
