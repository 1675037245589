import React from 'react';
import LargeTitleSwitch from '../../../../Components/ToggleSwitch/LargeTitleSwitch';
import { useFormContext } from 'react-hook-form';
import ModeTimingSection from '../Components/ModeTimingSection';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';

export default function DishTiming(props) {
    const { title, description } = props;

    const { control, watch } = useFormContext();

    const menuItemTiming = [
        { label: 'Full time', value: 'no_time_slots' },
        { label: 'Specific time for all days', value: 'same_time_slot_for_all_days' },
        { label: 'Specific time for each day', value: 'different_time_slots_for_different_days' },
    ];

    return (
        <>
            <div className='max-w-[636px]'>
                {!watch('exposeDish')?.length ? (
                    <span className='paragraph-normal-normal'>
                        Please select at least one ordering mode to apply timing to it
                    </span>
                ) : (
                    <>
                        <TitleDescription
                            title={title}
                            description={description}
                            className='mb-6'
                        />

                        <LargeTitleSwitch
                            classNames='mb-6'
                            control={control}
                            title='Enable same timings for all ordering modes'
                            description='Enabling the same timings for all ordering modes will set the dish availability time the same across the selected exposed dish modes.'
                            switchName='timings.sameTiming'
                        />

                        {watch('timings.sameTiming') ? (
                            <ModeTimingSection
                                id={`timings_0`}
                                title='Set timings'
                                description='This is the time when the customers will be able to order the dish from the mobile application.'
                                menuItemTiming={menuItemTiming}
                            />
                        ) : (
                            <>
                                {!!watch('exposeDish')?.length ? (
                                    watch('exposeDish')?.map((el, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='pb-6 mb-6 border-b border-neutral-300 last:border-none'>
                                                <ModeTimingSection
                                                    id={`timings_${el.id}`}
                                                    title={`Set ${el.display_name} timing`}
                                                    menuItemTiming={menuItemTiming}
                                                    description='Please Specify the timings when this item will be available on orderloop.'
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <span className=''>
                                        Please select at least one ordering mode to apply timing to it
                                    </span>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
