import React from 'react';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { ListView, TableRows } from './RearrangeRowComponent';
import { useFormContext } from 'react-hook-form';
import { fornatOrderName } from '../../../../Constants/function';

export default function HomeScreenlayoutTable(props) {
    const { isRearrange, setShowFooter, setShowTabChangeWarningPopup } = props;

    const { watch, setValue } = useFormContext();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            const reArrangedSection = () => {
                const oldIndex = watch('appearance.home_screen_layout').findIndex((item) => item.id === active.id);
                const newIndex = watch('appearance.home_screen_layout').findIndex((item) => item.id === over.id);

                return arrayMove(watch('appearance.home_screen_layout'), oldIndex, newIndex);
            };

            setValue('appearance.home_screen_layout', reArrangedSection());

            setValue('isFormDirty', true, { shouldDirty: true });

            setShowFooter(true);

            setShowTabChangeWarningPopup(true);
        }
    }

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {watch('appearance.home_screen_layout')?.length && (
                <div
                    className={`${
                        !isMobileScreen &&
                        'w-full rounded-lg paragraph-small-regular overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border mb-6'
                    }`}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToParentElement,
                            restrictToFirstScrollableAncestor,
                        ]}>
                        {!isMobileScreen ? (
                            <table className='w-full break-words table-row-bg'>
                                <tbody>
                                    <SortableContext
                                        items={watch('appearance.home_screen_layout')}
                                        strategy={verticalListSortingStrategy}
                                        disabled={!isRearrange}>
                                        {watch('appearance.home_screen_layout')?.map((el, index) => {
                                            return (
                                                <TableRows
                                                    key={el.id}
                                                    index={index}
                                                    isRearrange={isRearrange}
                                                    {...el}
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </tbody>
                            </table>
                        ) : (
                            <SortableContext
                                items={watch('appearance.home_screen_layout')}
                                strategy={verticalListSortingStrategy}
                                disabled={!isRearrange}>
                                {watch('appearance.home_screen_layout')?.map((el, index) => {
                                    return (
                                        <ListView
                                            key={el.id}
                                            index={index}
                                            isRearrange={isRearrange}
                                            {...el}
                                            label={fornatOrderName(el.section_name)}
                                        />
                                    );
                                })}
                            </SortableContext>
                        )}
                    </DndContext>
                </div>
            )}
        </>
    );
}
