import React from 'react';
import ContentLoader from 'react-content-loader';

const OrderInformationPopupLoader = () => {
    return (
        <ContentLoader
            width='652px'
            height='700px'
            className=' w-fit rounded-md'>
            {/* header */}
            <rect
                x='6'
                y='5'
                rx='4'
                ry='4'
                width='400'
                height='35'
            />
            <rect
                x='545'
                y='5'
                rx='4'
                ry='4'
                width='32'
                height='32'
            />
            <rect
                x='6'
                y='55'
                rx='4'
                ry='4'
                width='80'
                height='30'
            />
            <rect
                x='101'
                y='55'
                rx='4'
                ry='4'
                width='80'
                height='30'
            />
            <rect
                x='221'
                y='55'
                rx='4'
                ry='4'
                width='100'
                height='30'
            />
            {/* line in between */}
            <rect
                x='6'
                y='100'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            {/* first line */}
            <rect
                x='6'
                y='120'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='156'
                y='120'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='326'
                y='120'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='476'
                y='120'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* second line */}
            <rect
                x='6'
                y='160'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='156'
                y='160'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='326'
                y='160'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='476'
                y='160'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* third line */}
            <rect
                x='6'
                y='200'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='156'
                y='200'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='326'
                y='200'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='476'
                y='200'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* fourth line */}
            <rect
                x='6'
                y='240'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='156'
                y='240'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='326'
                y='240'
                rx='4'
                ry='4'
                width='140'
                height='30'
            />
            <rect
                x='476'
                y='240'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* line in between */}
            <rect
                x='6'
                y='285'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            {/* ordered dishes */}
            <rect
                x='6'
                y='300'
                rx='4'
                ry='4'
                width='150'
                height='30'
            />
            <rect
                x='476'
                y='300'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='6'
                y='340'
                rx='4'
                ry='4'
                width='250'
                height='30'
            />
            <rect
                x='476'
                y='340'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* line in between */}
            <rect
                x='6'
                y='385'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            {/* gross total portion */}
            <rect
                x='6'
                y='400'
                rx='4'
                ry='4'
                width='150'
                height='30'
            />
            <rect
                x='476'
                y='400'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            <rect
                x='6'
                y='440'
                rx='4'
                ry='4'
                width='170'
                height='30'
            />
            <rect
                x='476'
                y='440'
                rx='4'
                ry='4'
                width='110'
                height='30'
            />
            {/* cashback portion */}
            <rect
                x='6'
                y='480'
                rx='4'
                ry='4'
                width='250'
                height='34'
            />
            <rect
                x='496'
                y='480'
                rx='4'
                ry='4'
                width='90'
                height='28'
            />
            <rect
                x='6'
                y='520'
                rx='4'
                ry='4'
                width='250'
                height='34'
            />
            <rect
                x='496'
                y='520'
                rx='4'
                ry='4'
                width='90'
                height='28'
            />
            {/* line in between */}
            <rect
                x='6'
                y='585'
                rx='4'
                ry='4'
                width='580'
                height='3'
            />
            {/* feedback portion */}
            <rect
                x='6'
                y='600'
                rx='4'
                ry='4'
                width='200'
                height='34'
            />
            <rect
                x='486'
                y='600'
                rx='4'
                ry='4'
                width='100'
                height='30'
            />
            {/* more information button */}
            <rect
                x='6'
                y='650'
                rx='4'
                ry='4'
                width='580'
                height='38'
            />
        </ContentLoader>
    );
};

export default OrderInformationPopupLoader;
