import React, { useCallback, useState } from 'react';
import Tag from '../../../../../Components/Tag/Tag';
import SearchableDropdown from '../../../../../Components/SearchableDropdown/SearchableDropdown';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import APIV2 from '../../../../../api/axios/APIV2';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';

export default function UserSection() {
    const {
        watch,
        setValue,
        setError,
        control,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const [userMenuItems, setUserMenuItems] = useState([{ label: '', value: null }]);

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const formatUserList = (list) => {
        let transformedList = list.map((el) => ({
            label: `${el.name} - ${el.mobile_number}`,
            value: el.id,
            ...el,
        }));

        const customerIds = watch('customer_ids')?.map((el) => el.value);

        const filteredList = transformedList.filter((el) => !customerIds?.includes(el.value));

        setUserMenuItems(filteredList);
    };

    const fetchUserList = async (params) => {
        try {
            const response = await APIV2.get(`restaurants/${selectedRestaurantId}/customers/search`, { params });

            formatUserList(response.customers);

            setIsLoadingMenuItems(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        const newIdArray = watch('customer_ids')?.length ? [...watch('customer_ids'), selectedItem] : [selectedItem];

        setValue('customer_ids', newIdArray);

        clearErrors('customer_ids');

        fieldOnChange(newIdArray);

        const updateMenuItems = userMenuItems.filter((item) => item.value !== selectedItem.value);

        setUserMenuItems(updateMenuItems);
    };

    const handleClickClose = (item) => {
        setValue(
            'customer_ids',
            watch('customer_ids').filter((customerId) => customerId.value !== item.value)
        );

        watch('customer_ids').length === 0 && setError('customer_ids', { message: 'Please select user.' });

        setUserMenuItems((prevUserMenuItems) => [...prevUserMenuItems, item]);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const onCompleteTyping = (searchQuery) => {
        searchQuery.length ? handleFetchList(searchQuery) : setUserMenuItems([]);
    };

    // eslint-disable-next-line
    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            fetchUserList({ search_query });
        }, 1000)
    );

    return (
        <>
            <DropdownSection
                name='do_add_user'
                title='Select users preference'
                description='Select whether you would like to provide this offer to everyone or specific users.'
                marginBetween='mb-4'
                menuItems={preferenceMenuItem}
                placeholder='Select your preference'
                clearErrorName={['do_add_user', 'customer_ids']}
                clearField={['customer_ids']}
                rules={{ required: 'Please select preference' }}
                className='mb-6'
                zIndex='z-0'
            />

            {watch('do_add_user')?.value && (
                <section className='md:mb-24'>
                    <div className='flex flex-row items-center justify-between mb-1'>
                        <h1
                            className={`paragraph-large-medium md:paragraph-medium-medium ${
                                watch('do_add_user')?.value ? 'text-neutral-900' : 'text-neutral-300'
                            }`}>
                            Select user
                        </h1>
                    </div>

                    <span
                        className={`paragraph-medium-italic md:paragraph-small-italic ${
                            watch('do_add_user')?.value ? 'text-neutral-500' : 'text-neutral-300'
                        }`}>
                        Select whether you would like to provide this offer to everyone or specific users.
                    </span>

                    <div className='mt-4 max-w-[312px] md:max-w-full'>
                        <Controller
                            render={({ field }) => (
                                <SearchableDropdown
                                    onCompleteTyping={onCompleteTyping}
                                    menuItems={userMenuItems}
                                    placeholder='Select users'
                                    handleSelect={(selection) => handleSelect(selection, field.onChange)}
                                    setIsLoadingMenuItems={setIsLoadingMenuItems}
                                    isLoadingMenuItems={isLoadingMenuItems}
                                    disabled={!watch('do_add_user')?.value}
                                    zIndex='z-0'
                                />
                            )}
                            name='customer_ids'
                            control={control}
                            rules={
                                watch('do_add_user')?.value ? { required: 'Please select user' } : { required: false }
                            }
                        />

                        {errors?.customer_ids && <ErrorMessage errors={errors.customer_ids} />}

                        <div className='align-top mt-1'>
                            {watch('customer_ids')?.map((el, index) => (
                                <div
                                    className='mr-2 inline-block'
                                    key={index}>
                                    <Tag
                                        tag={el.label}
                                        onClose={() => handleClickClose(el)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}
