import React from 'react';
import CategoryCard from '../Components/CategoryCard';
import pizza1 from '../../../Assets/MediaLibrary/pizza1.png';
import pizza2 from '../../../Assets/MediaLibrary/pizza2.png';
import pizza3 from '../../../Assets/MediaLibrary/pizza3.png';
import pizza4 from '../../../Assets/MediaLibrary/pizza4.png';
import pizza5 from '../../../Assets/MediaLibrary/pizza5.png';
import pizza6 from '../../../Assets/MediaLibrary/pizza6.png';
import pizza7 from '../../../Assets/MediaLibrary/pizza7.png';
import pizza8 from '../../../Assets/MediaLibrary/pizza8.png';

export default function CategoriesSection() {
    const categories = [
        { title: 'All', dishes: '102', image: pizza1 },
        { title: 'Salad', dishes: '10', image: pizza2 },
        { title: 'Pizza', dishes: '32', image: pizza3 },
        { title: 'Pastries', dishes: '10', image: pizza4 },
        { title: 'Burger', dishes: '02', image: pizza5 },
        { title: 'Juices', dishes: '10', image: pizza6 },
        { title: 'Shakes', dishes: '07', image: pizza7 },
        { title: 'Burger', dishes: '02', image: pizza5 },
        { title: 'Juices', dishes: '10', image: pizza6 },
        { title: 'Shakes', dishes: '07', image: pizza7 },
        { title: 'ice cream', dishes: '15', image: pizza8 },
    ];

    return (
        <>
            <div
                className='sticky z-1 bottom-0 pl-6 pr-[26px] pageContent:mb-0 h-fit mt-auto pageContent:inline-grid'
                style={{
                    boxShadow: '0px -4px 15px -3px rgba(16, 24, 40, 0.1), 0px -4px 6px -4px rgba(16, 24, 40, 0.1)',
                }}>
                <div className='max-w-[844px] w-full flex flex-col overflow-auto scrollbar-style pb-3'>
                    <div className='flex flex-row mt-4'>
                        {categories.map(
                            (el, index) =>
                                index % 2 === 0 && (
                                    <div
                                        className='mr-5'
                                        key={index}>
                                        <CategoryCard
                                            title={el.title}
                                            dishes={el.dishes}
                                            image={el.image}
                                        />
                                    </div>
                                )
                        )}
                    </div>

                    <div className='flex flex-row mt-4'>
                        {categories.map(
                            (el, index) =>
                                index % 2 !== 0 && (
                                    <div
                                        className='mr-5'
                                        key={index}>
                                        <CategoryCard
                                            title={el.title}
                                            dishes={el.dishes}
                                            image={el.image}
                                        />
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
