import React, { useState } from 'react';
import DownArrow from '../../../../../Assets/chevron-down.svg';

export default function ListViewMediaCard(props) {
    const { item, setUserDetail, setShowUploadPopup } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    return (
        <>
            <div className='w-full h-full px-4 py-3 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row justify-between items-center cursor-pointer'
                    onClick={() => setIsShowDetails((prevState) => !prevState)}>
                    <div className='flex flex-row items-center'>
                        <img
                            src={item.image_url}
                            alt=''
                            className='w-10 h-10 rounded-[5px]'
                        />

                        <span className='paragraph-medium-medium ml-2'>{item.image_title}</span>
                    </div>

                    <img
                        src={DownArrow}
                        alt='Logo'
                        className={`h-6 w-6 ${isShowDetails && 'rotate-180'}`}
                    />
                </div>

                {isShowDetails && (
                    <div className='mt-4'>
                        <div className='flex flex-row mb-1'>
                            <span className='paragraph-overline-small text-neutral-700'>CATEGORY:</span>

                            <span className='ml-2 paragraph-small-regular'>{'category here'}</span>
                        </div>

                        <div className='flex flex-row py-1'>
                            <span className='paragraph-overline-small text-neutral-700'>SUB-CATEGORY:</span>

                            <span className='ml-2 paragraph-small-regular'>{'subCategory here'}</span>
                        </div>

                        <div className='flex flex-row my-1'>
                            <span className='paragraph-overline-small text-neutral-700'>UPLOADER:</span>

                            <span
                                className='ml-2 paragraph-small-regular text-primary-500 cursor-pointer'
                                onClick={() => setUserDetail(item.uploader)}>
                                {item.uploader.name}
                            </span>
                        </div>

                        <div className='flex flex-row pt-1'>
                            <span className='paragraph-overline-small text-neutral-700'>UPLOADED ON:</span>

                            <span className='ml-2 paragraph-small-regular'>{item.uploaded_on.replace(/,/g, ', ')}</span>
                        </div>

                        <div
                            className='paragraph-small-italic text-primary-500 cursor-pointer pt-2'
                            onClick={() => setShowUploadPopup(item.id)}>
                            View details
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
