import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

const ChecBoxTable = (props) => {
    const { module, errors, handleArrayState, clearErrors, arrayState, disabled, checkedPresets } = props;
    const handleChangeCheckBox = ({ object, checked, permissionNameObject }) => {
        clearErrors();
        checked
            ? handleArrayState({
                  array: 'checkedPresets',
                  updatedValue: (prevState) => [
                      ...prevState,
                      {
                          permission_object_id: object?.id,
                          permission_id: permissionNameObject?.id,
                      },
                  ],
              })
            : handleArrayState({
                  array: 'checkedPresets',
                  updatedValue: (prevState) =>
                      prevState.filter(
                          (item) =>
                              !(
                                  item?.permission_object_id == object?.id &&
                                  item?.permission_id == permissionNameObject?.id
                              )
                      ),
              });
    };

    return (
        <>
            <div className='flex flex-row paragraph-medium-semi-bold mobile:text-sm'>
                <span className='min-w-[270px] md:min-w-[160px]'>{module}</span>
                <div className='flex flex-row justify-between w-full'>
                    {arrayState.permissionList?.permissions?.map((permissionHeader) => {
                        return <span key={permissionHeader?.id}>{permissionHeader?.permission?.toUpperCase()}</span>;
                    })}
                </div>
            </div>

            <div className='mb-12 overflow-auto h-[55vh] md:h-[75vh] scrollbar-style'>
                {arrayState.permissionList?.objects?.map((permission) => {
                    return (
                        <div
                            className='flex flex-row paragraph-small-regular mt-2 items-center'
                            key={permission?.id}>
                            <span className='min-w-[270px] md:min-w-[160px]'>{permission?.name}</span>
                            <div className='flex flex-row justify-between w-full pl-2 pr-[14px]'>
                                {arrayState.permissionList?.permissions?.map((permissionNameObject) => {
                                    return (
                                        <CheckBoxWithoutLabels
                                            onChange={handleChangeCheckBox}
                                            object={permission}
                                            isChecked={checkedPresets?.find(
                                                (item) =>
                                                    item.permission_id == permissionNameObject.id &&
                                                    item.permission_object_id == permission.id
                                            )}
                                            permissionNameObject={permissionNameObject}
                                            disabled={disabled}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            {errors?.permission_list && <ErrorMessage errors={errors?.permission_list} />}
        </>
    );
};

export default ChecBoxTable;
