import React from 'react';
import { DropdownBoxLoader, SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <div className='flex flex-row items-center'>
                    <DropdownBoxLoader
                        className='max-w-[120px] md:max-w-full mb-2'
                        height={50}
                        boxHeight='h-12'
                    />

                    <DropdownBoxLoader
                        className='max-w-[160px] md:max-w-full mb-2 ml-4'
                        height={50}
                        boxHeight='h-12'
                    />
                </div>

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />

                <DropdownBoxLoader className='max-w-[170px] md:max-w-full mt-5 h-[25px]' />

                <DropdownBoxLoader className='max-w-[120px] md:max-w-full mt-8 h-[20px]' />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />
            </div>
        </>
    );
}
