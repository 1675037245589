import React from 'react';
import { MobileComponents } from './Components/HomePage';
import SubCategoryPage from './Components/SubCategoryPage';

export default function MobileScreenSubCategoryScreen(props) {
    const { title } = props;

    return (
        <div
            className={`mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[661px] w-[319px] shadow-xl sticky mobile:max-w-[250px] mobile:w-full`}>
            <MobileComponents />

            <div className='rounded-[2rem] overflow-hidden w-[291px] h-[633px] bg-white px-4 mobile:w-[222px] mobile:px-2'>
                <SubCategoryPage title={title} />
            </div>
        </div>
    );
}
