import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { fornatOrderName } from '../../../Constants/function';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as LinearMenuIcon } from '../../../../../Assets/linear-menu.svg';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function ListViewOutletOrderingMode(props) {
    const { display_name, type, tab, is_enabled, id, isRearrange, index } = props;

    const [showDetails, setShowDetails] = useState(false);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const { control } = props;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    return (
        <>
            <div
                className='px-4 py-3 border border-neutral-300 rounded-md mt-2'
                ref={setNodeRef}
                style={style}
                key={index}
                {...attributes}
                {...listeners}>
                <div className='flex flex-row items-center justify-between'>
                    <div className='flex flex-row items-center'>
                        {isRearrange && (
                            <div className='mr-3'>
                                <LinearMenuIcon />
                            </div>
                        )}

                        <Controller
                            render={({ field }) => (
                                <ToggleSwitch
                                    isEnable={is_enabled}
                                    setIsEnabled={field.onChange}
                                    canChange={!isRearrange}
                                    cursorType={isRearrange && 'cursor-not-allowed'}
                                />
                            )}
                            name={`ordering_modes[${index}].is_enabled`}
                            control={control}
                        />

                        <div className='flex flex-col ml-4'>
                            <span className='paragraph-overline-small mb-1'>MODE NAME:</span>
                            <span className='paragraph-small-regular'>{display_name?.[selectedLanguageId]}</span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={() => setShowDetails((prevState) => !prevState)}>
                        <DownArrow className={`${showDetails && 'rotate-180'}`} />
                    </div>
                </div>

                {showDetails && (
                    <div className='mt-1.5 ml-[56px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER TYPE:</span>
                            <span className='paragraph-small-regular ml-1'>{fornatOrderName(type)}</span>
                        </div>

                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER TAB:</span>
                            <span className='paragraph-small-regular ml-1'>{fornatOrderName(tab)}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
