import React, { useState } from 'react';

export default function ExposeDish(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <>
            <div
                className={`pt-4 px-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex pb-4'>
                    <span className='paragraph-medium-medium'>Expose dish</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className='flex flex-row mr-4'>
                        <span className='paragraph-small-regular text-neutral-500 mr-2 min-w-[94px] flex-shrink-0'>
                            Internal name:
                        </span>

                        <span className='paragraph-small-regular flex flex-wrap gap-2 pb-4'>
                            {dishDetails.ordering_modes.map((el, index) => {
                                return `${el.restaurant_ordering_mode_name}${index !== dishDetails.ordering_modes.length - 1 ? ', ' : ''}`;
                            })}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
}
