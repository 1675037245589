import React from 'react';
import Header from './Header';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Layout(props) {
    const { showMainLayout, children, setActiveState, setShowLinkOfferPopup } = props;

    const pageSize = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#FAFAFA]'>
                {showMainLayout && (
                    <div
                        className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: !isMobileScreen && pageSize.height - 50 }}>
                        <Header
                            setShowLinkOfferPopup={setShowLinkOfferPopup}
                            setActiveState={setActiveState}
                        />

                        {children}
                    </div>
                )}
            </div>
        </>
    );
}
