import React from 'react';

export default function ConfirmRoleChangePopup(props) {
    const { handleClickClose, header, description, confirm } = props;
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-[101] md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='pb-3 flex flex-row items-center'>{header}</div>

                    {description}

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300'>
                            Cancel
                        </button>

                        <button
                            className='h-12 w-[196px] md:w-1/2 md:ml-[7.5px] paragraph-medium-medium rounded-md text-shades-50 bg-primary-500'
                            onClick={confirm}>
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
