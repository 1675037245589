import React from 'react';
import { SingleLoader } from '../../../Components/ContentLoader/DropdownLoader';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function Loader() {
    const { height: pageHeight } = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div className='bg-white p-4'>
                    <SingleLoader className='max-w-[200px] h-[24px]' />
                    <SingleLoader className='max-w-full h-12 mt-4' />
                    <SingleLoader className='max-w-[60vw] h-8 mt-4' />
                    <SingleLoader className='max-w-full h-12 mt-4' />
                </div>
            ) : (
                <div className='bg-[#fafafa]'>
                    <div
                        className='pt-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full'
                        style={{ height: pageHeight - 50 }}>
                        <div className='flex flex-row w-full'>
                            <div className='flex flex-col gap-6 ml-8 border-r border-neutral-300 pr-6 mr-6 w-full max-w-[200px]'>
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                                <SingleLoader className='max-w-[173px] h-[48px]' />
                            </div>
                            <div className='w-full'>
                                <SingleLoader className='max-w-[150px] h-[24px]' />
                                <SingleLoader className='max-w-[500px] h-[24px] mt-2' />
                                <SingleLoader className='max-w-[250px] h-[48px] mt-6' />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
