import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { useFormContext } from 'react-hook-form';

export default function ColorSelectionSection(props) {
    const { handleClickColorSelector } = props;

    const { watch } = useFormContext();

    return (
        <>
            <TitleDescription
                className='mb-2'
                title='Brand colors'
                description='Select your brand colors for the application.'
            />

            <div className='mb-2 paragraph-medium-italic text-neutral-500'>Primary brand color</div>

            {!watch('brand_settings.brand_color_primary') ? (
                <button
                    className='w-full justify-center py-3 paragraph-medium-regular border-neutral-300 rounded-md border mr-2 mobile:mr-1 mobile:text-sm mb-4 max-w-[197px]'
                    onClick={() => handleClickColorSelector('brand_color_primary')}>
                    Select color
                </button>
            ) : (
                <div
                    className='w-[96px] h-12 rounded-md mb-4 cursor-pointer'
                    style={{ background: watch('brand_settings.brand_color_primary') }}
                    onClick={() => handleClickColorSelector('brand_color_primary')}
                />
            )}

            <div className='mb-2 paragraph-medium-italic text-neutral-500'>Secondary brand color</div>

            {!watch('brand_settings.brand_color_secondary') ? (
                <button
                    className='justify-center py-3 paragraph-medium-regular border-neutral-300 w-full rounded-md border mr-2 mobile:mr-1 mobile:text-sm mb-4 max-w-[197px]'
                    onClick={() => handleClickColorSelector('brand_color_secondary')}>
                    Select color
                </button>
            ) : (
                <div
                    className='w-[96px] h-12 rounded-md mb-4 cursor-pointer'
                    onClick={() => handleClickColorSelector('brand_color_secondary')}
                    style={{ backgroundColor: watch('brand_settings.brand_color_secondary') }}
                />
            )}
        </>
    );
}
