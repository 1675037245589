import React from 'react';
import { ReactComponent as MenuIcon } from '../../../../../Assets/menu.svg';
import ListViewCataloguePresets from './ListViewCataloguePresets';
import { Link } from '../../../../../Components/LinkOffer/Link';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function PresetDetails(props) {
    const { handleClickPreset, handleClickLinkOutlet, handleClickCreatedBy } = props;

    const presetDetails = [
        {
            presetName: 'Menu - 007',
            categories: '12',
            dishes: '128',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdate: '20 November, 2022',
            linkOutlet: 4,
        },
        {
            presetName: 'Medium Scale Menu - 006',
            categories: '08',
            dishes: '96',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdate: '20 November, 2022',
            linkOutlet: 4,
        },
        {
            presetName: 'Medium Scale Menu - 005',
            categories: '08',
            dishes: '120',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdate: '20 November, 2022',
            linkOutlet: 4,
        },
        {
            presetName: 'Medium Scale Menu - 004',
            categories: '08',
            dishes: '96',
            createdBy: 'Sarthak Kanchan',
            createdOn: '20 November, 2022',
            lastUpdate: '20 November, 2022',
            linkOutlet: 8,
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <div className='mb-4'>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[273px] lg:min-w-[250px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        PRESET NAME
                                    </th>
                                    <th className='px-6 min-w-[256px] lg:min-w-[237px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CATEGORIES AND DISHES
                                    </th>
                                    <th className='px-6 min-w-[199px] lg:min-w-[162px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CREATED BY
                                    </th>
                                    <th className='px-6 min-w-[179px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        CREATED ON
                                    </th>
                                    <th className='px-6 min-w-[179px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        LAST UPDATE
                                    </th>
                                    <th className='px-6 min-w-[184px] min-width-unset paragraph-overline-small text-neutral-700'>
                                        LINK OUTLET
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {presetDetails.map((el, index) => (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td
                                            className='px-6 cursor-pointer'
                                            onClick={handleClickPreset}>
                                            {el.presetName}
                                        </td>
                                        <td className='px-6'>
                                            <div className='flex flex-col'>
                                                <div className='flex flex-row items-center mb-1'>
                                                    <MenuIcon />
                                                    <span className='paragraph-small-regular ml-2'>
                                                        Categories - {el.categories}
                                                    </span>
                                                </div>
                                                <div className='flex flex-row items-center'>
                                                    <MenuIcon />
                                                    <span className='paragraph-small-regular ml-2'>
                                                        Dishes - {el.dishes}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            className='px-6 text-primary-500 cursor-pointer'
                                            onClick={handleClickCreatedBy}>
                                            {el.createdBy}
                                        </td>
                                        <td className='px-6'>{el.createdOn}</td>
                                        <td className='px-6'>{el.lastUpdate}</td>
                                        <td className='px-6'>
                                            <div
                                                className='cursor-pointer'
                                                onClick={handleClickLinkOutlet}>
                                                <Link linkOfferNumber={el.linkOutlet} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className=''>
                    {presetDetails.map((el, index) => (
                        <div
                            className='mt-2'
                            key={index}>
                            <ListViewCataloguePresets
                                presetName={el.presetName}
                                categories={el.categories}
                                dishes={el.dishes}
                                createdBy={el.createdBy}
                                createdOn={el.createdOn}
                                lastUpdatedOn={el.lastUpdate}
                                handleClickViewDetails={handleClickPreset}
                                handleLClickLink={handleClickLinkOutlet}
                                handleClickCreatedBy={handleClickCreatedBy}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
