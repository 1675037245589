import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { useWindowSize } from '@uidotdev/usehooks';
import APIV3 from '../../../../../api/axios/APIV3';
import SearchableDropdownWithWindowing from './SearchableDropdownWithWindowing.js';

export default function DishSelectionDropdown(props) {
    const { filedName } = props;

    const { watch, setValue } = useFormContext();

    const screenSize = useWindowSize();

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const [dishMenuItem, setDishMenuItem] = useState([]);

    const [pageState, setPageState] = useState({ page: 1, lastPage: 1 });

    const [filters, setFilters] = useState({ searchQuery: '' });

    const formatList = (list) => {
        const formattedList = list.map((el) => ({
            label: el.translations.title[1],
            value: {
                item_id: el.item_id,
                label: el.internal_name,
                quantity: 1,
                display_name: el.translations.title[1],
            },
            ...el,
        }));

        setDishMenuItem(formattedList);
    };

    const fetchDishList = async (param) => {
        const params = {
            page: pageState.page,
            per_page: 10,
            keyword: filters.searchQuery,
            ...param,
        };

        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/items`, {
                params,
            });

            setPageState((prevState) => {
                return { ...prevState, lastPage: response.item.last_page };
            });

            formatList(response.item.data);

            setIsLoadingMenuItems(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        const newIdArray = watch('selected_dish')?.length ? [...watch('selected_dish'), selectedItem] : [selectedItem];

        setValue('selected_dish', newIdArray);

        fieldOnChange(selectedItem);

        const updateMenuItems = dishMenuItem.filter((item) => item.value !== selectedItem.value);

        setDishMenuItem(updateMenuItems);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const onCompleteTyping = (searchQuery) => {
        searchQuery.length ? handleFetchList(searchQuery) : setDishMenuItem([]);
    };

    useEffect(() => {
        fetchDishList({ keyword: ' ', page: 1 });
    }, []);

    // eslint-disable-next-line
    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            setFilters({ keyword: search_query });

            fetchDishList({ keyword: search_query, page: pageState.page });
        }, 1000)
    );

    return (
        <div className='max-w-[312px]'>
            <Controller
                render={({ field }) => (
                    <SearchableDropdownWithWindowing
                        onCompleteTyping={onCompleteTyping}
                        menuItems={dishMenuItem}
                        placeholder='Select dish'
                        handleSelect={(selection) => handleSelect(selection, field.onChange)}
                        setIsLoadingMenuItems={setIsLoadingMenuItems}
                        isLoadingMenuItems={isLoadingMenuItems}
                        openFromBottom={screenSize.height < 785}
                        setPageState={setPageState}
                        pageState={pageState}
                        fetchNewData={fetchDishList}
                        showDisplayValue={true}
                    />
                )}
                name={filedName}
                rules={{ required: 'Please select a dish' }}
            />
        </div>
    );
}
