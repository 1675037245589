import React, { useState } from 'react';
import PricingTable from './PricingTable/PricingTable';
import { useFormContext } from 'react-hook-form';
import EditPricePopUp from './EditPricePopUp';
import _ from 'lodash';
import NoVariantsPricingTable from './PricingTable/NoVariantsPricingTable';

export default function DishPricingTableSection(props) {
    const { isEditPrice } = props;

    const { watch } = useFormContext();

    const variants = watch('pricing.variantPrice');

    const [toEditTableDetails, setToEditTableDetails] = useState({});

    return (
        <>
            {!watch('do_add_variant') ? (
                <NoVariantsPricingTable
                    id='pricing.dishPrice'
                    isEditPrice={isEditPrice}
                    setToEditTableDetails={setToEditTableDetails}
                />
            ) : (
                <>
                    {variants?.map((el, index) => {
                        return (
                            <PricingTable
                                id={`pricing.variantPrice.${el.id}`}
                                key={index}
                                details={el}
                                isEditPrice={isEditPrice}
                                setToEditTableDetails={setToEditTableDetails}
                            />
                        );
                    })}
                </>
            )}

            {!_.isEmpty(toEditTableDetails) && (
                <EditPricePopUp
                    handleClickClose={() => setToEditTableDetails(false)}
                    priceDetails={toEditTableDetails}
                />
            )}
        </>
    );
}
