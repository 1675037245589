import React, { createContext, useContext } from 'react';

const AppContext = createContext();

export const ContextProvider = (props) => {
    const { children, context } = props;

    return <AppContext.Provider value={{ context }}>{children}</AppContext.Provider>;
};

export const useContextProvider = () => useContext(AppContext);
