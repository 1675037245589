import React, { useEffect } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../../Assets/close.svg';
import { RadioButton } from '../../../../../../Components/FormControl/FormControls';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import { Controller, useFormContext } from 'react-hook-form';
import InputSection from '../../../../Components/InputSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';
import APIV5 from '../../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useWindowSize } from '@uidotdev/usehooks';

export default function CreateOrderingModePopup(props) {
    const { fetchSettings, fetchOrderingMode, details, setIsEdit, setEditModeId, fetchlanguageList } = props;

    const isMobileScreen = IsMobileScreen();

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useFormContext();

    const handleClickClose = () => {
        setValue('display_name', '');
        setValue('type', null);
        setIsEdit(false);
        setEditModeId({});
        props.handleClickClose();
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const editMode = async (data) => {
        try {
            const response = await APIV5.put(
                `restaurants/${selectedRestaurantId}/restaurant-ordering-modes/${details.id}`,
                { display_name: data.display_name, type: data.type }
            );

            if (response.success) {
                handleClickClose();
                reset();
                fetchlanguageList();
                fetchSettings();
                fetchOrderingMode();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (data) => {
        if (details) {
            editMode(data);
            return;
        }

        try {
            const response = await APIV5.post(`restaurants/${selectedRestaurantId}/restaurant-ordering-modes`, {
                display_name: data.display_name,
                type: data.type,
            });

            if (response.success) {
                handleClickClose();
                reset();
                fetchlanguageList();
                fetchSettings();
                fetchOrderingMode();
            }
        } catch (error) {
            Object.keys(error.response.data.errors).forEach((key) => {
                setError(key, { message: error.response.data.errors[key] });
            });
        }
    };

    useEffect(() => {
        if (_.isEmpty(details)) return;

        setValue('display_name', details.display_name);
        setValue('type', details.type);
        // eslint-disable-next-line
    }, []);

    const screenHeight = useWindowSize().height;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md: p-4 md:p-0 overflow-auto md:overflow-hidden'>
                <div
                    className={`max-w-[475px] w-full rounded-xl bg-shades-50 pt-6 pb-9 px-8 m-auto max-h-[969px] md:max-w-full md:rounded-none md:py-4 md:px-4 md:overflow-hidden md:mt-0`}
                    style={{
                        height: isMobileScreen ? screenHeight - 50 : 'fit-content',
                    }}>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='pl-1'>Back to global setting</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between items-center border-neutral-300 mb-6'>
                        <div>
                            <span className='paragraph-large-medium'>{details ? 'Edit' : 'Create'} ordering mode</span>
                        </div>

                        <div
                            onClick={handleClickClose}
                            className='md:hidden cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <InputSection
                            name='display_name'
                            placeholder='Enter display name'
                            shadow='shadow-xSmall'
                            label='Enter display name'
                            rules={{ required: 'Please enter a display name' }}
                        />
                    </div>

                    <div className='mb-4'>
                        <label className='paragraph-small-medium'>Select type</label>

                        <div className='mt-3'>
                            <Controller
                                render={({ field }) => (
                                    <>
                                        <RadioButton
                                            marginB='3'
                                            label='QSR'
                                            optionId='qsr'
                                            handleOptionChange={field.onChange}
                                            checked={watch('type') === 'qsr'}
                                        />
                                    </>
                                )}
                                name='type'
                                control={control}
                                rules={{ required: 'Please select type' }}
                            />

                            <Controller
                                render={({ field }) => (
                                    <>
                                        <RadioButton
                                            marginB='3'
                                            label='Fine dine'
                                            optionId='fine_dine'
                                            handleOptionChange={field.onChange}
                                            checked={watch('type') === 'fine_dine'}
                                        />
                                    </>
                                )}
                                name='type'
                                control={control}
                                rules={{ required: 'Please select type' }}
                            />

                            {errors?.type && <ErrorMessage errors={errors.type} />}
                        </div>
                    </div>

                    <div className='sticky z-[100] md:z-[8] md:fixed md:border-none md:shadow-dropShadow left-0 right-0 bottom-0 bg-white md:border-t md:border-neutral-300'>
                        <div className='flex flex-row justify-end w-full md:justify-center pt-6 lg:pt-4 md:py-0 md:pb-1 md:pt-2 md:px-4'>
                            <button
                                onClick={handleClickClose}
                                className='h-12 w-1/2 mr-2.5 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300'>
                                Cancel
                            </button>
                            <div
                                className='w-1/2 ml-2'
                                onClick={handleSubmit(onSubmit)}>
                                <LargePrimaryButton
                                    label={`${details ? 'Edit' : 'Create'} ordering mode`}
                                    isDefault={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
