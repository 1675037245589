import React from 'react';
import { LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import { unLinkItem } from '../../functions';
import { useSelector } from 'react-redux';

export default function ConfirmationPopup(props) {
    const { handleClickClose, unlinkItem, handleSuccessUnlink } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleUnlink = async () => {
        const params = {
            item_id: unlinkItem.item_id,
            category_id: unlinkItem.category_id,
        };

        const response = await unLinkItem(selectedRestaurantId, params);

        if (response.success) {
            handleClickClose();
            handleSuccessUnlink();
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 md:max-w-[343px]'>
                    <h2 className='paragraph-large-medium'>Unlink item?</h2>

                    <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3 mt-3'>
                        Would you like to unlink this item?
                    </p>

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300 cursor-pointer'>
                            Cancel
                        </button>

                        <div
                            className='w-1/2 md:ml-[7.5px] cursor-pointer'
                            onClick={() => handleUnlink(null)}>
                            <LargePrimaryButton label='Unlink' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
