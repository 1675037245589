import React from 'react';

export const TableBlankComponentView = (props) => {
    const { previewText } = props;
    return (
        <div className='bg-white flex items-center justify-center h-[20vh]'>
            <span className='text-2xl font-bold'>{previewText}</span>
        </div>
    );
};
