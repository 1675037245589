import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as LinkIcon } from '../../../Assets/link.svg';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { offerTypes } from '../Constants/Constants';
import { ReactComponent as RearrangeIcon } from '../../../Assets/linear-menu.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';

export default function ListViewOffer(props) {
    const {
        offer_type,
        coupon_code,
        active,
        linked_outlets,
        translations,
        hasSwitch,
        handleStatusChange,
        hasSelection,
        isSelected,
        onSelect,
        isEdit,
        unlinkOutlet,
        isRearrange,
        setLinkOfferId,
        setSelectedOfferId,
        id,
        dontShowViewDetails,
    } = props;

    const offerType = offerTypes.find((type) => type.value === offer_type)?.label;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
        touchAction: 'none',
    };

    const navigate = useNavigate();

    const handleNavigation = (id) => {
        navigate(`/offers/edit/${id}/offer-type`);
    };

    return (
        <div
            className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}>
            <div className='flex flex-row'>
                {hasSwitch && (
                    <div className='mr-4 mt-3'>
                        <ToggleSwitch
                            isEnable={active}
                            setIsEnabled={handleStatusChange}
                            canChange
                        />
                    </div>
                )}
                {isEdit && (
                    <span
                        className='mr-4 mt-3'
                        onClick={() => handleNavigation(id)}>
                        <EditIcon />
                    </span>
                )}
                {isRearrange && (
                    <span className='mr-4 mt-3'>
                        <RearrangeIcon />
                    </span>
                )}
                {hasSelection && (
                    <div className='mr-4 mt-3'>
                        <CheckBoxWithoutLabels
                            onChange={(value) => onSelect(value)}
                            isChecked={isSelected}
                        />
                    </div>
                )}

                <div className='w-full'>
                    <div
                        className='flex flex-row items-center justify-between cursor-pointer'
                        onClick={() => setIsShowDetails((prevState) => !prevState)}>
                        <div className='flex flex-row items-center'>
                            <div>
                                <h3 className='paragraph-overline-small text-neutral-700 mb-1 flex'>COUPON CODE:</h3>
                                <span className='paragraph-small-regular flex'>{coupon_code}</span>
                            </div>
                        </div>

                        <div className='flex flex-row items-center'>
                            <div className={`${isShowDetails && 'rotate-180'}`}>
                                <DownArrow />
                            </div>
                        </div>
                    </div>

                    {isShowDetails && (
                        <div className='mt-[5px]'>
                            <div className='mb-[7px]'>
                                <span className='paragraph-overline-small text-neutral-700'>OFFER TYPE:</span>
                                <span className='paragraph-small-regular ml-1'>{offerType}</span>
                            </div>
                            <div className='mb-[7px]'>
                                <span className='paragraph-overline-small text-neutral-700'>TITLE:</span>
                                <span className='paragraph-small-regular ml-1'>{translations.title[1]}</span>
                            </div>
                            <div className='mb-[7px] flex mobile:block'>
                                <span className='paragraph-overline-small text-neutral-700'>DESCRIPTION:</span>
                                <div className='paragraph-small-regular ml-1 mobile:ml-0'>
                                    {translations.description[1]}
                                </div>
                            </div>

                            <div className='flex flex-row justify-between items-center mobile:block'>
                                <div className='flex flex-row items-center mobile:mb-1'>
                                    <span className='paragraph-overline-small text-neutral-700'>LINKED OFFERS:</span>

                                    <div
                                        className='paragraph-small-regular ml-1 flex flex-row items-center text-primary-500'
                                        onClick={unlinkOutlet ?? setLinkOfferId}>
                                        <LinkIcon
                                            className='h-5 w-5 mr-1'
                                            stroke='#6C5DD3'
                                        />
                                        {linked_outlets?.length ?? 'Unlink'}
                                    </div>
                                </div>

                                {!dontShowViewDetails && (
                                    <span
                                        className='paragraph-small-italic text-primary-500 cursor-pointer'
                                        onClick={() => setSelectedOfferId(id)}>
                                        View details
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
