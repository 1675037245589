import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { useFormContext } from 'react-hook-form';

export default function Tabs(props) {
    const { setShowWarningPopup, showTabChangeWarningPopup } = props;

    const { tab } = useParams();

    const navigate = useNavigate();

    const {
        formState: { isDirty },
        watch,
    } = useFormContext();

    const screenSize = useWindowSize();

    const menuItems = [
        { item: 'Ordering mode', onClick: () => handleNavigation('ordering-mode'), link: 'ordering-mode' },
        { item: 'Appearance', onClick: () => handleNavigation('appearance/category'), link: 'appearance/category' },
        { item: 'Address', onClick: () => handleNavigation('address'), link: 'address' },
        { item: 'Bill settings', onClick: () => handleNavigation('bill-settings'), link: 'bill-settings' },
        { item: 'Bill payments', onClick: () => handleNavigation('bill-payments'), link: 'bill-payments' },
        { item: 'Contacts', onClick: () => handleNavigation('contacts'), link: 'contacts' },
        { item: 'Service radius', onClick: () => handleNavigation('service-radius'), link: 'service-radius' },
        { item: 'Preparation time', onClick: () => handleNavigation('preparation-time'), link: 'preparation-time' },
        { item: 'Delivery charges', onClick: () => handleNavigation('delivery-charges'), link: 'delivery-charges' },
        { item: 'Payment setting', onClick: () => handleNavigation('payment-setting'), link: 'payment-setting' },
        { item: 'Payment method', onClick: () => handleNavigation('payment-method'), link: 'payment-method' },
        { item: 'Currency', onClick: () => handleNavigation('currency'), link: 'currency' },
        { item: 'Timing', onClick: () => handleNavigation('timing'), link: 'timing' },
        { item: 'Rider setting', onClick: () => handleNavigation('rider-setting/rider'), link: 'rider-setting/rider' },
        { item: 'Taxes', onClick: () => handleNavigation('taxes'), link: 'taxes' },
        { item: 'Food license', onClick: () => handleNavigation('food-license'), link: 'food-license' },
        { item: 'Other setting', onClick: () => handleNavigation('other-setting'), link: 'other-setting' },
        { item: 'Other charges', onClick: () => handleNavigation('other-charges'), link: 'other-charges' },
        { item: 'Third party POS', onClick: () => handleNavigation('third-party-POS'), link: 'third-party-POS' },
        { item: 'COD limit', onClick: () => handleNavigation('cod-limit'), link: 'cod-limit' },
        { item: 'Enable cash', onClick: () => handleNavigation('enable-cash'), link: 'enable-cash' },
        { item: 'Min. order value', onClick: () => handleNavigation('mov'), link: 'mov' },
    ];

    const isMobileScreen = IsMobileScreen();

    const handleNavigation = (route) => {
        if (showTabChangeWarningPopup) {
            setShowWarningPopup({ status: showTabChangeWarningPopup, route: `/settings/store/${route}` });
            return;
        }

        navigate(`/settings/store/${route}`);
    };

    return (
        <>
            {!isMobileScreen ? (
                <div
                    className='flex flex-col mt-4 pr-6 mr-5 border-r min-w-[194px] border-neutral-300 overflow-auto [&::-webkit-scrollbar]:hidden lg:pr-4 lg:mr-3 lg:min-w-[186px]'
                    style={{
                        height: screenSize.width < 1921 ? screenSize.height - 244 : screenSize.height - 250,
                    }}>
                    {menuItems.map((el, index) => {
                        if (
                            !(
                                (el.link === 'payment-setting' || el.link === 'payment-method') &&
                                watch('use_central_payment_configurations') === 0
                            )
                        ) {
                            return (
                                <div
                                    className='max-w-[169px] mb-4 lg:mb-2 last:pb-8'
                                    key={index}>
                                    <MultipleTab
                                        maxWidth='max-w-[169px]'
                                        label={el.item}
                                        index={index}
                                        onClick={() => handleNavigation(el.link)}
                                        isActive={`${el.link.split('/')[0]}` === tab}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            ) : (
                <div className='mb-4'>
                    <DropDownTabs
                        menuItems={menuItems}
                        dropDownPadding='pr-2'
                        dropDownHeight='overflow-auto max-h-80 scrollbar-style'
                    />
                </div>
            )}
        </>
    );
}
