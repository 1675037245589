import React, { useState } from 'react';
import { IsMobileScreen, IsPageContentMedium } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { ReactComponent as OrderLoopLogo } from '../../../Assets/orderloop-logo.svg';
import { ReactComponent as OrderLoopLogoMobile } from '../../../Assets/orderloop-logo-mobile.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { links } from './links';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { userHasPermission } from '../../../Helper/auth';

export default function LeftPanel(props) {
    const { sideMenu, handleClickLink, isLeftPanelExpanded, setIsDockLetPanel, isDockLetPanel } = props;

    // const permissions = useSelector((state) => state.permission.permission);

    const screenHeight = useWindowSize().height;

    const isMobileScreen = IsMobileScreen();

    const isPageContentMedium = IsPageContentMedium();

    const [activeIndex, setActiveIndex] = useState(null);

    const url = useLocation().pathname;

    const [openSubLinks, setOpenSubLinks] = useState(null);

    const handleClickSubLink = (index) => {
        setActiveIndex(index);
        handleClickLink();
    };

    const handleOpenSubLink = (link) => {
        setOpenSubLinks(link);
        setActiveIndex(0);
    };

    const moduleUrl = url.match(/\/[^/]+/)?.[0] ?? url;

    const navigate = useNavigate();

    const handleLogoClick = () => {
        if (noOfRestaurants > 1) {
            navigate('/select-restaurant');
        }
    };

    const noOfRestaurants = useSelector((state) => state.auth.numberOfRestaurants);

    let updatedLinks = links.filter((link) => {
        return noOfRestaurants <= 1 ? link.name !== 'Select Restaurant' : link;
    });

    return (
        <>
            <div
                ref={sideMenu}
                className={`bg-neutral-50 w-full inline-table max-w-[104px] xl:max-w-[72px] border-r border-neutral-300 fixed left-0 top-0 bottom-0 z-20 md:fixed md:z-[999] h-screen 3xl:h-full ${
                    isLeftPanelExpanded
                        ? 'max-w-[300px] largeTablet:max-w-[250px] md:max-w-[300px]'
                        : isMobileScreen && 'hidden'
                }`}>
                <div className='mx-[18px] xl:px-2 xl:mx-0 h-[50px] border-b border-neutral-300 sticky top-0 md:absolute md:left-0 md:right-0 bg-neutral-50 flex'>
                    {isLeftPanelExpanded ? (
                        <span className='m-auto w-[140px]'>
                            <OrderLoopLogo className='w-full h-auto' />
                        </span>
                    ) : (
                        <span className='m-auto'>
                            <OrderLoopLogoMobile />
                        </span>
                    )}
                </div>

                <div className='py-4 bg-[#fafafa]'>
                    <div className='md:pt-[51px]'>
                        <div
                            className='scrollbar-style overflow-auto'
                            style={{
                                height: isPageContentMedium
                                    ? screenHeight - 75
                                    : screenHeight - (!isLeftPanelExpanded ? 71 : 131),
                            }}>
                            <div
                                className={`max-w-[56px] xl:max-w-[44px] w-full mx-auto`}
                                style={{ maxWidth: isLeftPanelExpanded && '252px' }}>
                                {links?.map((link, index) => {
                                    const isFirst = index === 0;

                                    return (
                                        <div
                                            className='border-b border-neutral-300 last:border-b-0'
                                            key={index}>
                                            {link?.group?.map((groupEl, groupIndex) => {
                                                const hasPermission = true;

                                                const isTabActive =
                                                    (url.match(/\/[^/]+/)?.[0] ?? url) ===
                                                    (groupEl.baseUrl ?? groupEl.to);

                                                return (
                                                    hasPermission && (
                                                        <div key={`${groupIndex}${index}`}>
                                                            <Link
                                                                key={index}
                                                                to={groupEl.to}
                                                                className={`xl:w-11 ${!isLeftPanelExpanded ? 'block' : 'inline'}`}
                                                                style={{ textDecoration: 'none' }}
                                                                onClick={groupEl.onClick ? handleLogoClick : undefined}>
                                                                <div
                                                                    className={`flex items-center h-14 xl:h-11 rounded-xl xl:rounded-[10px] hover:bg-primary-500 group cursor-pointer ${
                                                                        isTabActive && 'bg-primary-500'
                                                                    } ${isFirst ? 'mb-2' : 'my-2'}`}
                                                                    style={{
                                                                        'backgroundColor':
                                                                            groupEl.subLinks &&
                                                                            isTabActive &&
                                                                            '#C4BEED',
                                                                        '--hover-color': '#C4BEED',
                                                                    }}
                                                                    onClick={() =>
                                                                        groupEl.subLinks
                                                                            ? handleOpenSubLink(groupEl.to)
                                                                            : handleClickLink()
                                                                    }>
                                                                    <div className='w-14'>
                                                                        <span
                                                                            className={`group-hover:block ${
                                                                                !isTabActive && 'hidden'
                                                                            }`}>
                                                                            {groupEl.iconHover}
                                                                        </span>
                                                                        <span
                                                                            className={`group-hover:hidden ${
                                                                                isTabActive && 'hidden'
                                                                            }`}>
                                                                            {groupEl.icon}
                                                                        </span>
                                                                    </div>

                                                                    {isLeftPanelExpanded && (
                                                                        <span
                                                                            className={`w-[196px] text-base font-normal leading-6 text-neutral-900 group-hover:text-white ${
                                                                                isTabActive && 'text-white'
                                                                            }`}
                                                                            style={{
                                                                                'color':
                                                                                    groupEl.subLinks &&
                                                                                    isTabActive &&
                                                                                    '#6C5DD3',
                                                                                '--hover-color': '#6C5DD3',
                                                                            }}>
                                                                            {groupEl.name}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                {/* {updatedLinks.map((link, index) => {
                                    const hasPermission = true;

                                    const isTabActive =
                                        (url.match(/\/[^/]+/)?.[0] ?? url) === (link.baseUrl ?? link.to);

                                    return (
                                        hasPermission && (
                                            <div key={index}>
                                                <Link
                                                    key={index}
                                                    to={link.to}
                                                    className={`xl:w-11 ${!isLeftPanelExpanded ? 'block' : 'inline'}`}
                                                    style={{ textDecoration: 'none' }}
                                                    onClick={link.onClick ? handleLogoClick : undefined}>
                                                    <div
                                                        className={`flex items-center h-14 xl:h-11 mb-2 rounded-xl xl:rounded-[10px] hover:bg-primary-500 group cursor-pointer ${
                                                            isTabActive && 'bg-primary-500'
                                                        }`}
                                                        style={{
                                                            'backgroundColor':
                                                                link.subLinks && isTabActive && '#C4BEED',
                                                            '--hover-color': '#C4BEED',
                                                        }}
                                                        onClick={() =>
                                                            link.subLinks
                                                                ? handleOpenSubLink(link.to)
                                                                : handleClickLink()
                                                        }>
                                                        <div className='w-14'>
                                                            <span
                                                                className={`group-hover:block ${
                                                                    !isTabActive && 'hidden'
                                                                }`}>
                                                                {link.subLinks && isTabActive
                                                                    ? link.iconActive
                                                                    : link.iconHover}
                                                            </span>
                                                            <span
                                                                className={`group-hover:hidden ${
                                                                    isTabActive && 'hidden'
                                                                }`}>
                                                                {link.icon}
                                                            </span>
                                                        </div>

                                                        {isLeftPanelExpanded && (
                                                            <span
                                                                className={`w-[196px] text-base font-normal leading-6 text-neutral-900 group-hover:text-white ${
                                                                    isTabActive && 'text-white'
                                                                }`}
                                                                style={{
                                                                    'color': link.subLinks && isTabActive && '#6C5DD3',
                                                                    '--hover-color': '#6C5DD3',
                                                                }}>
                                                                {link.name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    );
                                })} */}
                            </div>
                        </div>
                    </div>
                </div>

                {!isPageContentMedium && isLeftPanelExpanded && (
                    <div className='flex items-center mx-[18px] xl:px-6 xl:mx-0 py-4 bg-neutral-50 border-t border-neutral-300 sticky bottom-0 justify-between p-4'>
                        <span className='paragraph-medium-regular'>
                            {isDockLetPanel ? 'Undock' : 'Dock'} navigation
                        </span>

                        <span>
                            <ToggleSwitch
                                setIsEnabled={setIsDockLetPanel}
                                isEnable={isDockLetPanel}
                            />
                        </span>
                    </div>
                )}
            </div>
        </>
    );
}
