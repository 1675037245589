import React from 'react';
import ListViewOffer from './ListViewOffer';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';
import { BlankComponent } from '../../../Components/BlankComponent/BlankComponent';

export default function RestaurantOfferListSection(props) {
    const {
        list,
        selectedOffer,
        activeState,
        onSelectOffer,
        isListLoading,
        handleStatusChange,
        setLinkOfferId,
        setSelectedOfferId,
        linkPopup,
    } = props;

    return (
        <>
            {isListLoading ? (
                [...Array(10)].map((_, index) => (
                    <React.Fragment key={index}>
                        <ListViewLoader hasSwitch />
                    </React.Fragment>
                ))
            ) : !list.length ? (
                <BlankComponent
                    styles='rounded-xl border border-neutral-300'
                    previewText='No offer at this moment'
                />
            ) : (
                list.map((el, index) => (
                    <div
                        className='mt-2 first:mt-0'
                        key={index}>
                        <ListViewOffer
                            {...el}
                            hasSelection={activeState.selection}
                            isSelected={selectedOffer.includes(el.id)}
                            onSelect={(isSelected) => onSelectOffer(el.id, isSelected)}
                            isEdit={activeState.edit}
                            hasSwitch
                            handleStatusChange={() => handleStatusChange(el.id)}
                            setLinkOfferId={() => setLinkOfferId(el.id)}
                            setSelectedOfferId={(id) => !linkPopup && setSelectedOfferId(id)}
                        />
                    </div>
                ))
            )}
        </>
    );
}
