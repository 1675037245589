import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { gatDatepickerStyles } from '../Styles/gatDatepickerStyles';
import { RadioButton } from '../../../Components/FormControl/FormControls';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { DatePicker } from 'rsuite';
import '../Styles/datePicker.css';

export default function DisableOutletDiningModePopup(props) {
    const { handleClickClose, handleClickChange } = props;

    const [isShowCalendar, SetIsShowCalendar] = useState(false);

    const formatDateAndAddHours = (input, hoursToAdd) => {
        const inputDate = new Date(input);
        inputDate.setHours(inputDate.getHours() + hoursToAdd);

        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0');
        const day = String(inputDate.getDate()).padStart(2, '0');
        const hours = String(inputDate.getHours()).padStart(2, '0');
        const minutes = String(inputDate.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const [nextAvailableTime, setNextAvailableTime] = useState(formatDateAndAddHours(new Date(), 2));

    const ranges = [
        {
            label: 'Now',
            value: new Date(),
        },
    ];

    const radioButtonDetails = [
        { label: '2 hours', checked: true },
        { label: '4 hours' },
        { label: 'Next business days' },
        { label: 'Schedule time to reopen' },
        { label: 'Manually turn it on' },
    ];

    const handleOptionChange = (index) => {
        SetIsShowCalendar(index === 3);

        let timeValue;

        switch (index) {
            case 0:
                timeValue = formatDateAndAddHours(new Date(), 2);
                break;

            case 1:
                timeValue = formatDateAndAddHours(new Date(), 4);
                break;

            case 2:
                timeValue = formatDateAndAddHours(new Date(), 24);
                break;

            case 3:
                timeValue = formatDateAndAddHours(new Date(), 0);
                break;

            case 4:
                timeValue = null;
                break;

            default:
                break;
        }

        setNextAvailableTime(timeValue);
    };

    const handleSelection = (value) => {
        const formattedDateString = formatDateAndAddHours(value, 0);
        setNextAvailableTime(formattedDateString);
    };

    const disabledDate = (date) => {
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        return date < currentDate;
    };

    const getPlaceholder = formatDateAndAddHours(new Date(), 0);

    return (
        <>
            <style>{gatDatepickerStyles()}</style>

            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:p-4 md:overflow-auto flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 md:max-w-[320px]'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-[11px]'>
                        <div>
                            <span className='paragraph-large-medium'>Change the status?</span>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Close availability for following
                                </span>
                            </div>
                        </div>

                        <div
                            onClick={handleClickClose}
                            className='md:hidden cursor-pointer'>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='pb-6 md:mb-4 md:pb-4'>
                        <div className=''>
                            {radioButtonDetails.map((el, index) => (
                                <React.Fragment key={index}>
                                    <RadioButton
                                        marginB='2'
                                        {...el}
                                        optionId={`radioBox${index}`}
                                        value={el.label}
                                        handleOptionChange={() => handleOptionChange(index)}
                                    />
                                    {index === 3 && isShowCalendar && (
                                        <div className='my-4'>
                                            <DatePicker
                                                shouldDisableDate={disabledDate}
                                                onSelect={(value) => handleSelection(value)}
                                                placeholder={getPlaceholder}
                                                format='dd MMM yyyy hh:mm aa'
                                                showMeridian
                                                ranges={ranges}
                                                style={{ width: 260 }}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-row justify-between'>
                        <div className='w-full mr-[15px]'>
                            <button
                                onClick={handleClickClose}
                                className='h-12 w-full paragraph-medium-medium text-neutral-500 border border-neutral-300 rounded-md lg:max-w-[289px] cursor-pointer'>
                                Cancel
                            </button>
                        </div>

                        <div
                            className='w-full'
                            onClick={() => handleClickChange(nextAvailableTime)}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
