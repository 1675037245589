import axios from 'axios';

const token = localStorage.getItem('authToken');

export const urlToFile = async (imageUrl, filename) => {
    try {
        let blob;
        if (imageUrl.startsWith('data:image')) {
            const base64Content = imageUrl.split(',')[1];
            blob = await fetch(`data:image/png;base64,${base64Content}`).then((res) => res.blob());
        } else {
            blob = await fetch(imageUrl).then((res) => res.blob());
        }

        return new File([blob], filename, { type: blob.type });
    } catch (error) {
        console.error('Error converting URL to File:', error);
        throw error;
    }
};

const updateImage = async (
    formData,
    selectedRestaurantId,
    id,
    setUploadedImageDetails,
    handleClickClose,
    fetchData,
    image_url,
    setIsShowSaveLoader
) => {
    try {
        const response = await axios.post(
            `https://api.staging.fab.delivery/api/v5/restaurants/${selectedRestaurantId}/uploads/${id}`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        if (response.status === 200) {
            setUploadedImageDetails?.({ id: response.data.image.id, image_url });
            handleClickClose?.();
            fetchData?.();
            setIsShowSaveLoader(false);
        }
    } catch (error) {
        console.log('error ==>', error);
    }
};

const uploadImageAPI = async (
    formData,
    selectedRestaurantId,
    setUploadedImageDetails,
    handleClickClose,
    fetchData,
    image_url,
    setIsShowSaveLoader
) => {
    try {
        const response = await axios.post(
            `https://api.staging.fab.delivery/api/v5/restaurants/${selectedRestaurantId}/uploads`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        if (response.status === 200) {
            setUploadedImageDetails?.({ id: response.data.image.id, image_url });
            handleClickClose?.();
            fetchData?.();
            setIsShowSaveLoader(false);
        }
    } catch (error) {
        console.log('error ==>', error);
    }
};

export const uploadImage = async (
    selectedRestaurantId,
    setUploadedImageDetails,
    handleClickClose,
    fetchData,
    imageDetails,
    isEditSection,
    id,
    image_url,
    setIsShowSaveLoader,
    original_file_name
) => {
    const { image_type, image_title, tag_ids, alternative_title, original_image, is_dirty } = imageDetails;

    try {
        const fileObj = await urlToFile(image_url, original_file_name);
        const originalFileObj = await urlToFile(original_image.image_url, original_image.original_file_name);
        const formData = new FormData();
        formData.append('image', fileObj);
        formData.append('original_image', originalFileObj);
        tag_ids.forEach((tagId) => {
            formData.append('tag_ids[]', tagId);
        });
        formData.append('image_type', image_type);
        formData.append('image_title', image_title);
        formData.append('alternative_title', alternative_title);
        formData.append('is_dirty', is_dirty ? 1 : 0);
        isEditSection
            ? updateImage(
                  formData,
                  selectedRestaurantId,
                  id,
                  setUploadedImageDetails,
                  handleClickClose,
                  fetchData,
                  image_url,
                  setIsShowSaveLoader
              )
            : uploadImageAPI(
                  formData,
                  selectedRestaurantId,
                  setUploadedImageDetails,
                  handleClickClose,
                  fetchData,
                  image_url,
                  setIsShowSaveLoader
              );
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};
