import React, { useMemo, useRef, useState } from 'react';
import TitleSection from './BannerPopupComponent/TitleSection';
import ScreenSelection from './BannerPopupComponent/ScreenSelection';
import SelectDurationSection from './BannerPopupComponent/SelectDurationSection';
import BottomButtonSection from './BannerPopupComponent/BottomButtonSection';
import { useSelector } from 'react-redux';
import UploadImagePopup from '../../../Components/UploadImagePopup/UploadImagePopup';
import { parse } from 'date-fns';
import SelectImageFromLibraryPopup from './SelectImagePopupComponent/SelectImageFromLibraryPopup';
import OrderingModeSection from './BannerPopupComponent/OrderingModeSection';
import SelectImageSection from './BannerPopupComponent/SelectImageSection';
import ValiditySection from './BannerPopupComponent/ValiditySection';
import Header from './BannerPopupComponent/Header';
import TypeSelection from './BannerPopupComponent/TypeSelection';
import { FormProvider, useForm } from 'react-hook-form';
import { bannerType, validityMenuItem } from './BannerPopupComponent/constants';
import { timingMenuItem } from '../../../Constants/Constants';

export default function EditBannerPopup(props) {
    const { handleClickClose, editBannerData, fetchCatalogueList } = props;

    const orderingModes = useSelector((state) => state.outlet.orderingModes);

    const [showUploadImagePopupLanguageId, setShowUploadImagePopupLanguageId] = useState(false);
    const [showLibraryImagePopupLanguageId, setShowLibraryImagePopupLanguageId] = useState(null);

    const popupRef = useRef();

    const formatDefaultValue = () => {
        let timing;

        if (editBannerData.time_slot_setting === timingMenuItem[1].value) {
            timing = editBannerData.schedules
                .filter((el) => el.time_slots.day_of_week === 1)
                .map((el) => ({ start_time: el.time_slots.start_time, end_time: el.time_slots.end_time }));
        } else if (editBannerData.time_slot_setting === timingMenuItem[2].value) {
            timing = Array.from({ length: 7 }, () => []);

            editBannerData.schedules.forEach(({ time_slots }) => {
                const { day_of_week, start_time, end_time } = time_slots;
                timing[day_of_week - 1].push({ start_time, end_time });
            });
        }

        const activeKey = Object.keys(editBannerData).find((key) => key.startsWith('is_') && editBannerData[key] === 1);

        const selectedBannerType = bannerType.find((banner) => banner.value[activeKey] === 1);

        const orderingModeIds = editBannerData.ordering_modes.map((mode) => mode.restaurant_ordering_mode_id);

        const parsedDateFromDate = editBannerData.from_date
            ? parse(editBannerData.from_date, 'yyyy-MM-dd HH:mm:ss', new Date())
            : new Date();

        const parsedDateToDate = editBannerData.from_date
            ? parse(editBannerData.to_date, 'yyyy-MM-dd HH:mm:ss', new Date())
            : new Date();

        const filteredOrderingMode = orderingModes
            // .filter((mode) => orderingModeIds.includes(mode.id))
            .map((el) => ({ label: el.display_name, value: el, isSelected: orderingModeIds.includes(el.id) }));

        const timingType = timingMenuItem.find((el) => el.value === editBannerData.time_slot_setting);

        const selectedImage = {};

        Object.keys(editBannerData.translations.image).forEach((key) => {
            const value = editBannerData.translations.image[key];

            selectedImage[key] = { image_url: value.image_url, id: value.id };
        });

        const formattedData = {
            bannerType: selectedBannerType,
            bannerTitle: editBannerData.title,
            selectedScreen: editBannerData.screen,
            orderingMode: filteredOrderingMode,
            validity: validityMenuItem[!!editBannerData.from_date ? 1 : 0],
            from_date: parsedDateFromDate,
            to_date: parsedDateToDate,
            timingType,
            timing: timing,
            selectedImage,
            id: editBannerData.id,
        };

        return {
            defaultValues: formattedData,
        };
    };

    const method = useForm({ ...formatDefaultValue() });

    const { setValue, watch } = method;

    const handleSetImageData = (imageData, id) => {
        setValue(`selectedImage.${id}`, { ...imageData });
    };

    const uploadImageType = useMemo(() => {
        if (!!watch('bannerType')?.value.is_primary) {
            return { label: 'Primary banner', value: 'primary_banner' };
        } else if (!!watch('bannerType')?.value.is_secondary) {
            return { label: 'Secondary banner', value: 'secondary_banner' };
        } else {
            return { label: 'Tertiary banner', value: 'tertiary_banner' };
        }
    }, [watch('bannerType')]);

    return (
        <>
            {!showUploadImagePopupLanguageId && !showLibraryImagePopupLanguageId ? (
                <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex md:relative px-10 md:px-0 overflow-auto'>
                    <div
                        className='max-w-[475px] w-full rounded-xl bg-shades-50 py-6 m-auto max-h-[969px] md:max-w-full md:rounded-none md:py-4 md:max-h-full'
                        ref={popupRef}>
                        <FormProvider {...method}>
                            <Header
                                handleClickClose={handleClickClose}
                                tagLine='Edit an existing banner of the catalogue'
                                title='Edit existing banner'
                            />

                            <div className='pl-6 pr-[14px] md:pl-2 md:pr-0'>
                                <div className='h-[690px] overflow-auto scrollbar-style pr-[12px] md:overflow-hidden md:h-full md:pb-16 md:pr-4 pl-2'>
                                    <TypeSelection />

                                    <SelectImageSection
                                        handleClickUploadImage={setShowUploadImagePopupLanguageId}
                                        setShowLibraryImagePopupLanguageId={setShowLibraryImagePopupLanguageId}
                                    />

                                    <TitleSection />

                                    <ScreenSelection />

                                    <OrderingModeSection />

                                    <ValiditySection />

                                    <SelectDurationSection />
                                </div>
                            </div>

                            <BottomButtonSection
                                type='edit'
                                fetchCatalogueList={fetchCatalogueList}
                                handleClickClose={handleClickClose}
                                id={editBannerData.id}
                            />
                        </FormProvider>
                    </div>
                </div>
            ) : showUploadImagePopupLanguageId ? (
                <UploadImagePopup
                    handleClickClose={() => setShowUploadImagePopupLanguageId(null)}
                    setUploadedImageDetails={(imageData) =>
                        handleSetImageData(imageData, showUploadImagePopupLanguageId)
                    }
                    selectedType={uploadImageType}
                />
            ) : (
                showLibraryImagePopupLanguageId && (
                    <SelectImageFromLibraryPopup
                        handleClickClose={() => setShowLibraryImagePopupLanguageId(null)}
                        handleSelectImage={(imageData) =>
                            handleSetImageData(imageData, showLibraryImagePopupLanguageId)
                        }
                    />
                )
            )}
        </>
    );
}
