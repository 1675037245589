import React, { useState } from 'react';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as TrashIcon } from '../../../Assets/trash.svg';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import AddButton from './AddButton';

export default function KOTCard(props) {
    const {
        id,
        title,
        isAddNotes,
        price,
        isPreparing,
        note,
        extras,
        removeExtras,
        quantity,
        updateQuantity,
        setShowPopup,
    } = props;

    const [showCustomization, setShowCustomization] = useState(false);

    const formatAmount = (amount) => {
        return amount?.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        });
    };

    return (
        <>
            <div className='border border-neutral-300 rounded-md p-4 mobile:p-3 shadow-smallDropDownShadow mb-1 relative min-h-[96px]'>
                <div className='flex flex-row justify-between max-w-[240px] sm:max-w-[227px] mobile:max-w-[172px]'>
                    <div className='flex flex-row paragraph-medium-medium text-black pageContent:text-sm'>
                        <span className='mr-1'>{id}.</span>
                        <span className='largeTablet:w-48 md:w-auto'>{title}</span>
                    </div>
                </div>

                <div className={extras ? 'pt-[3px] mt-1 pl-[29px] ml-2 border-l border-neutral-300' : ''}>
                    <div className='flex flex-row mt-2 mb-4 relative top-[9px] md:inline-block'>
                        {extras && (
                            <div className='flex flex-row mr-3 sm:mr-2 items-center'>
                                <span
                                    className='paragraph-small-regular kottitle-sap cursor-pointer pageContent:text-[13px]'
                                    onClick={() => setShowCustomization((prevState) => !prevState)}>
                                    Customize
                                </span>
                                <DropDownIcon
                                    height={19}
                                    width={19}
                                    className={`${showCustomization ? 'rotate-180' : ''} pageContent:w-[14] pageContent:h-[14px]`}
                                />
                            </div>
                        )}

                        {isAddNotes && (
                            <div
                                onClick={() => setShowPopup('addEditNote')}
                                className='flex paragraph-small-underline text-primary-500 cursor-pointer pageContent:text-xs pageContent:pt-0.5 md:inline-flex'>
                                Add notes
                            </div>
                        )}
                    </div>

                    {showCustomization && (
                        <div>
                            <div className='mb-2'>
                                {extras?.length > 0 && (
                                    <div className='paragraph-small-medium pb-1 kottitle-sap'>With</div>
                                )}
                                <div className='text-neutral-500 paragraph-small-italic pb-2'>
                                    {extras?.map((extra, extraIndex) => (
                                        <div
                                            key={extraIndex}
                                            className='flex items-center justify-between pb-1'>
                                            <span className='paragraph-small-italic text-neutral-500'>
                                                {extra.item}
                                            </span>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className='flex flex-row items-center pr-4 cursor-pointer'
                                                    onClick={() => removeExtras(extraIndex)}>
                                                    <TrashIcon
                                                        stroke='#EF4444'
                                                        height={16}
                                                        width={16}
                                                    />
                                                    <span className='paragraph-small-regular text-destructive-500 ml-1'>
                                                        Remove
                                                    </span>
                                                </div>
                                                <span className='paragraph-small-regular'>
                                                    {formatAmount(extra.prices)}/-
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='flex flex-row justify-between mb-1'>
                                <span className='paragraph-small-medium kottitle-sap'>Note</span>
                                <div
                                    className='flex flex-row items-center cursor-pointer'
                                    onClick={() => setShowPopup('addEditNote')}>
                                    <EditIcon
                                        height={16}
                                        width={16}
                                        stroke='#EF4444'
                                    />
                                    <span className='paragraph-small-regular text-destructive-500 ml-1'>Edit</span>
                                </div>
                            </div>
                            <div className='paragraph-small-regular text-black'>{note}</div>
                        </div>
                    )}
                </div>

                <div className='flex flex-col absolute right-4 top-4'>
                    <span className='paragraph-medium-medium text-end pageContent:text-sm'>
                        {formatAmount(price)}/-
                    </span>
                    {!isPreparing ? (
                        <div className='mt-3'>
                            <AddButton
                                initialValue={quantity}
                                handleValueChange={(changedQuantity) => updateQuantity(changedQuantity)}
                            />
                        </div>
                    ) : (
                        <div className='mt-[18px]'>
                            <span className='px-1 py-0.5 border-primary-500 rounded bg-primary-50 text-primary-500 border pageContent:text-sm pageContent:pt-0.5 pageContent:pb-1'>
                                Preparing
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
