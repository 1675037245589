import React from 'react';

export const Table = (props) => {
    const { header, details, style } = props;

    return (
        <>
            <div className='w-full border border-neutral-300 rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow h-11 justify-center'>
                            {header.map((el, index) => (
                                <th
                                    className={`text-left pageContent:min-w-min pl-6 ${style[index]}`}
                                    key={index}>
                                    {el}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {details.map((row, index) => (
                            <tr
                                key={index}
                                className='first:border-none border-t border-neutral-300 even:bg-neutral-50 paragraph-small-regular h-[70px] justify-center'>
                                {row.map((el, rowIndex) =>
                                    row[0].icon && rowIndex === 0 ? (
                                        <td
                                            className='p-0'
                                            key={rowIndex}>
                                            <div className='w-full pl-6 flex flex-row items-center'>
                                                <img
                                                    src={el.icon}
                                                    alt=''
                                                    className='mr-3'
                                                />
                                                <span className=''>{row[rowIndex + 1]}</span>
                                            </div>
                                        </td>
                                    ) : rowIndex <= 2 ? (
                                        <td
                                            className='pl-6'
                                            key={rowIndex}>
                                            {row[0].icon ? row[rowIndex + 1] : el}
                                        </td>
                                    ) : null
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};
