import React from 'react';
import { useFormContext } from 'react-hook-form';
import { preferenceMenuItem } from '../../../../Constants/Constants';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';

export default function Clubbing() {
    const { watch } = useFormContext();

    return (
        <>
            <div className='max-w-[636px] md:max-w-full lg:mb-5 md:mb-12 lg:max-w-[459px] w-full'>
                <DropdownSection
                    className='mb-6'
                    title='Club offer'
                    description='Select whether you would like to club this offer with other existing offers.'
                    name='allow_offer_clubbing'
                    placeholder='Select your preference'
                    menuItems={preferenceMenuItem}
                    rules={{ required: 'Please select preference' }}
                    clearErrorName={['auto_apply', 'promo_consolidation']}
                    clearField={['auto_apply', 'promo_consolidation']}
                />

                {watch('allow_offer_clubbing')?.value && (
                    <DropdownSection
                        title='Auto-apply offer'
                        description='Select whether you would like to auto-apply this offer with other existing offers.'
                        name='auto_apply'
                        disabled={!watch('allow_offer_clubbing')?.value}
                        menuItems={preferenceMenuItem}
                        placeholder='Select your preference'
                        className='mb-6'
                        rules={
                            watch('allow_offer_clubbing')?.value
                                ? { required: 'Please select preference' }
                                : { required: false }
                        }
                        clearErrorName={['promo_consolidation']}
                        zIndex='z-0'
                    />
                )}

                {watch('auto_apply')?.value && (
                    <DropdownSection
                        title='Consolidate offer'
                        description='Select whether you would like to combine this offer with reward points.'
                        name='promo_consolidation'
                        disabled={!watch('auto_apply')?.value}
                        placeholder='Select your preference'
                        menuItems={preferenceMenuItem}
                        rules={
                            watch('auto_apply')?.value ? { required: 'Please select preference' } : { required: false }
                        }
                        className='mb-6'
                        zIndex='z-0'
                    />
                )}
            </div>
        </>
    );
}
