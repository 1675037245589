import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { IsMobileScreen } from '../../Constants/Constants';

export default function UploadImageHeader(props) {
    const { handleClickClose, showBackButton, backToPage } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium pl-1'>Back to {backToPage}</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mb-6'>
                    <div className='flex flex-row items-center'>
                        {showBackButton && (
                            <span
                                className='mr-2 cursor-pointer'
                                onClick={handleClickClose}>
                                <LeftArrowIcon className='rotate-90' />
                            </span>
                        )}
                        <div className=''>
                            <div className='paragraph-large-medium'>Upload new image</div>
                            <div className='paragraph-medium-italic text-neutral-500'>
                                Allowed file types: .jpeg, .jpg, .png, .webp.
                            </div>
                        </div>
                    </div>

                    {showBackButton && (
                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
