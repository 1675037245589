import React, { useEffect } from 'react';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import Table from './Table';
import { ListViewOutletOrderingMode } from '../../../../StoreSetting/TabComponent/Appearance/Components/ListViewStoreSetting';
import APIV5 from '../../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function ListSection(props) {
    const {
        setActiveInactiveMode,
        orderingMode,
        isListLoading,
        isEdit,
        isSelectionEnable,
        onSelectMode,
        selectedMode,
        setEditModeId,
        setOrderingMode,
        setIsListLoading,
        setDefaultOrderingMode,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const handleStatusChange = (modeId, doLink) => {
        setActiveInactiveMode((prevState) => {
            const updatedList = { ...prevState };
            if (doLink) {
                updatedList.activeMode = [...prevState.activeMode, modeId];
                updatedList.inActiveMode = prevState.inActiveMode.filter((id) => id !== modeId);
            } else {
                updatedList.inActiveMode = [...prevState.inActiveMode, modeId];
                updatedList.activeMode = prevState.activeMode.filter((id) => id !== modeId);
            }
            return updatedList;
        });

        const updatedOrderingMode = orderingMode.map((el) => {
            if (el.id === modeId) {
                return { ...el, status: doLink };
            }
            return el;
        });

        setOrderingMode(updatedOrderingMode);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const fetchOrderingMode = async () => {
        setIsListLoading(true);
        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/restaurant-ordering-modes`);

            setOrderingMode(response.ordering_modes);

            setDefaultOrderingMode(response.ordering_modes);
        } catch (error) {
            console.log(error);
        } finally {
            setIsListLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderingMode();
        // eslint-disable-next-line
    }, []);

    const fornatOrderName = (name) => {
        const words = name.split('_').join(' ');

        return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
    };

    return (
        <>
            <div className='max-w-[636px] w-full md:max-w-full pageContent:mb-6 mb-6'>
                {!isMobileScreen ? (
                    <Table
                        isEdit={isEdit}
                        orderingMode={orderingMode}
                        changeStatus={handleStatusChange}
                        fornatOrderName={fornatOrderName}
                        isListLoading={isListLoading}
                        isSelectionEnable={isSelectionEnable}
                        onSelectMode={onSelectMode}
                        selectedMode={selectedMode}
                        setEditModeId={setEditModeId}
                    />
                ) : (
                    <div className='pb-[70px]'>
                        {orderingMode.map((el, index) => (
                            <div
                                className='mt-2'
                                key={index}>
                                <ListViewOutletOrderingMode
                                    isEnable={el.status}
                                    modeName={el.display_name}
                                    orderType={fornatOrderName(el.type)}
                                    orderTab={fornatOrderName(el.tab)}
                                    handleStatusChange={(value) => handleStatusChange(el.id, value)}
                                    isSelectionEnable={isSelectionEnable}
                                    onSelectMode={onSelectMode}
                                    selectedMode={selectedMode}
                                    setEditModeId={setEditModeId}
                                    isEdit={isEdit}
                                    {...el}
                                    item={el}
                                    index={index}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
