import { ReactComponent as LocationIcon } from '../../../Assets/location-mobile-screen.svg';
import { ReactComponent as TelephoneIcon } from '../../../Assets/telephone-mobile-screen.svg';
import { ReactComponent as ArrowFillIcon } from '../../../Assets/arrow-fill.svg';
import { ReactComponent as HamburgerIcon } from '../../../Assets/hamburger.svg';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/non-veg.svg';
import { ReactComponent as ArrowBackIcon } from '../../../Assets/arrow-back.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as ForwardIcon } from '../../../Assets/forward.svg';
import fssaiLogo from '../../../Assets/fssai-logo.png';
import pizza from '../../../Assets/MediaLibrary/pizza1.png';

export const Footer = (props) => {
    const { footerLogo } = props;

    return (
        <>
            <div className='mt-3 border-b border-neutral-300 pb-3 -mx-4'>
                <div className='mx-4'>
                    <img
                        src={footerLogo}
                        alt='footer logo'
                        className='max-w-[125px] h-auto mb-2.5'
                    />
                    <span className='paragraph-medium-semi-bold'>Race Course Store</span>
                    <div className='flex flex-row'>
                        <span className='max-w-[20px] mt-1 mr-1'>
                            <LocationIcon
                                width={12}
                                height={12}
                            />
                        </span>
                        <p className='paragraph-x-small-regular text-[11px] max-w-[225px] font-medium'>
                            Race Course Ring Road, Opposite Vice CC Play Ground, Rajkot, Gujarat
                        </p>
                    </div>
                    <div className='flex flex-row'>
                        <span className='max-w-[20px] mt-1 mr-1'>
                            <TelephoneIcon
                                width={12}
                                height={12}
                            />
                        </span>
                        <span className='paragraph-x-small-semi-bold underline'>+91 9824885838</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <img
                            src={fssaiLogo}
                            alt=''
                            className='max-w-[20px] h-2.5 mr-1'
                        />
                        <span className='paragraph-x-small-regular text-[9px]'>Lic. No. 1234567891</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export const ItemsFloatingCard = (props) => {
    const { selectedColors } = props;

    return (
        <>
            <div className='w-full absolute bottom-[78px]'>
                <div className='flex justify-end'>
                    <div
                        className='flex flex-row items-center px-4 py-3 rounded-lg w-fit mb-3 shadow-xLarge'
                        style={{ backgroundColor: selectedColors?.secondaryColor ?? '#000' }}>
                        <span className='max-w-[14px]'>
                            <HamburgerIcon
                                className='w-full h-auto'
                                stroke='#FFFFFF'
                            />
                        </span>
                        <span className='paragraph-x-small-reguler text-[12px] ml-2 text-white tracking-widest'>
                            MENU
                        </span>
                    </div>
                </div>

                <div
                    className='flex flex-row justify-between items-center text-white rounded-xl pr-1 pl-4 py-3 shadow-xLarge'
                    style={{ backgroundColor: selectedColors?.primaryColor ?? '#142348' }}>
                    <div className='flex flex-col paragraph-x-small-reguler text-[11px] tracking-widest'>
                        <span>1 items</span>
                        <span>
                            <span className='paragraph-medium-semi-bold text-white text-[18px]'>₹118</span> plus taxes
                        </span>
                    </div>

                    <div className='flex flex-row items-center'>
                        <span className='paragraph-x-small-reguler tracking-widest text-xs text-white mobile:text-[9px]'>
                            View Cart
                        </span>
                        <ArrowFillIcon
                            className='-rotate-90'
                            fill='#FFFFFF'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const CategoryDetails = (props) => {
    const { categoryDetails, categoryTitle } = props;

    return (
        <>
            <span className='heading-h6-medium-mobile'>{categoryTitle ?? 'Maggi'}</span>

            <p className='paragraph-x-small-reguler text-[11px] font-medium pt-3 break-words'>
                {categoryDetails ??
                    "Maggi, one of the most iconic and loved quick recipes, prepared in chef's style bring out the best taste and flavour. For the best hygiene, it is served in a convenient microwave-oven safe container."}
            </p>
        </>
    );
};

const Dish = (props) => {
    const { dishTitle, dishDetails, dishImg, isNonVeg, dontShowAgmark } = props;

    return (
        <>
            <div className='flex flex-row border-b border-neutral-300 pb-3'>
                <div className='flex flex-col'>
                    <span className='max-w-[13px]'>
                        {!dontShowAgmark &&
                            (isNonVeg ? (
                                <NonVegIcon className='w-full h-auto' />
                            ) : (
                                <VegIcon className='w-full h-auto' />
                            ))}
                    </span>
                    <span className='paragraph-small-medium pb-1 break-words max-w-[130px] motion-safe:max-w-[100px]'>
                        {dishTitle ?? 'Maggi'}
                    </span>
                    <p className='paragraph-x-small-regular text-[9px] leading-none text-neutral-500 pb-1'>
                        3 gram | serves 3 person | 10 kcal | contains Gluten, Fish, Crustacean
                    </p>
                    <span className='paragraph-small-medium pb-1'>₹59</span>
                    <span className='paragraph-x-small-reguler text-[9px] break-words max-w-[130px]'>
                        {dishDetails ?? 'DESCRIPTION HERE'}
                    </span>
                </div>
                <div>
                    <div className='relative mb-6'>
                        <img
                            src={dishImg ?? pizza}
                            alt=''
                            className='max-w-[100px] max-h-[100px] rounded-2xl'
                        />
                        <div className='px-4 py-1 rounded-lg bg-white shadow-xLarge w-fit absolute -bottom-3 left-4 paragraph-x-small-reguler text-xs'>
                            Add +
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const CategoryPage = (props) => {
    const {
        selectedColors,
        categoryDetails,
        categoryTitle,
        dishTitle,
        dishDetails,
        footerLogo,
        dishImg,
        isNonVeg,
        dontShowAgmark,
    } = props;

    return (
        <>
            <div className='flex flex-row justify-between mt-6'>
                <span className='max-w-[24px] ml-1.5'>
                    <ArrowBackIcon className='w-full h-auto' />
                </span>
                <div className='flex flex-row items-center'>
                    <span>
                        <TimerIcon
                            fill='#000'
                            stroke='#fff'
                        />
                    </span>
                    <span className='max-w-[24px] ml-2'>
                        <ForwardIcon className='w-full h-auto' />
                    </span>
                </div>
            </div>

            <div className='relative h-full'>
                <div className='mt-4 pb-3 mb-6 border-b border-neutral-300 -mx-4 px-4'>
                    <CategoryDetails
                        categoryDetails={categoryDetails}
                        categoryTitle={categoryTitle}
                    />
                </div>

                <Dish
                    dishImg={dishImg}
                    dishTitle={dishTitle}
                    dishDetails={dishDetails}
                    isNonVeg={isNonVeg}
                    dontShowAgmark={dontShowAgmark}
                />

                <ItemsFloatingCard selectedColors={selectedColors} />
                <Footer footerLogo={footerLogo} />
            </div>
        </>
    );
};
