import React, { useState } from 'react';
import { ReactComponent as Edit } from '../../Assets/edit.svg';
import { ReactComponent as Trash } from '../../Assets/trash.svg';

function EditDelete(props) {
    const { handleClickEdit } = props;

    const [showEditPopup, setShowEditPopup] = useState(false);

    const handleClickDelete = () => {
        props.handleClickDelete();
        setShowEditPopup(false);
    };

    return (
        <>
            <div
                className='flex-row flex cursor-pointer'
                onClick={() => setShowEditPopup(!showEditPopup)}>
                <div className='h-1 w-1 rounded-full bg-neutral-900' />
                <div className='h-1 w-1 rounded-full bg-neutral-900 mx-0.5' />
                <div className='h-1 w-1 rounded-full bg-neutral-900' />
            </div>

            {showEditPopup && (
                <div className='w-[137px] h-[76px] rounded-md border border-neutral-300 shadow-Xsmall absolute right-0 mt-2 px-4 py-2 bg-white'>
                    <div
                        className='flex flex-row items-center pb-3 cursor-pointer'
                        onClick={handleClickEdit}>
                        <Edit className='w-5 h-5 mr-1' />
                        <span className='paragraph-medium-regular'>Rename</span>
                    </div>

                    <div
                        className='flex flex-row items-center cursor-pointer'
                        onClick={handleClickDelete}>
                        <Trash
                            className='w-5 h-5 mr-1'
                            stroke='#EF4444'
                        />
                        <span className='paragraph-medium-regular text-destructive-500'>Delete</span>
                    </div>
                </div>
            )}
        </>
    );
}

export default EditDelete;
