import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as DownArrow } from '../../Assets/chevron-down.svg';
import { CheckBoxWithoutLabels } from '../FormControl/FormControls';

export const DropDownTabs = (props) => {
    const {
        menuItems,
        shadow,
        boxStyle,
        menuButtonIconStyle,
        tabStyle,
        textColor,
        fixedLabel,
        badgeTextStyle,
        dropDownIconFill,
        itemStyle,
        openFromBottom,
        dropDownHeight,
        dropDownPadding,
        selectedItem,
    } = props;

    const [menuButton, setMenuButton] = useState(selectedItem?.item ?? menuItems[0].item);
    const [menuButtonIcon, setMenuButtonIcon] = useState(menuItems[0]?.iconClick);
    const [badgeTextMenuButton, setBadgeTextMenuButton] = useState(menuItems[0]?.badgeText);

    const handleClickMenuItem = (menuItem) => {
        setMenuButton(menuItem.item);
        menuItem.onClick && menuItem.onClick();
        menuItem.iconClick && setMenuButtonIcon(menuItem.iconClick);
        menuItem.badgeText ? setBadgeTextMenuButton(menuItem.badgeText) : setBadgeTextMenuButton(null);
    };
    return (
        <>
            <div className='relative'>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            className={`flex flex-row justify-between ${shadow} m-auto w-full paragraph-medium-regular rounded-md focus:outline-none font-normal leading-5 appearance-none px-4 py-[14px] border ${
                                boxStyle ?? 'border-primary-500 bg-primary-50'
                            } h-12 items-center`}>
                            <div className='flex flex-row items-center truncate'>
                                <div className={`${menuButtonIconStyle ?? 'mobile:hidden'}`}>{menuButtonIcon}</div>
                                <span
                                    className={`${menuButtonIcon && 'ml-2'} ${tabStyle} ${
                                        textColor ?? 'text-primary-500'
                                    }`}>
                                    {fixedLabel && fixedLabel + ' - '}
                                    {menuButton}
                                </span>
                                {badgeTextMenuButton && (
                                    <div
                                        className={`px-2.5 paragraph-x-small-medium mx-2 group-disabled:text-neutral-400 ${
                                            badgeTextStyle ?? 'text-shades-50 bg-primary-500'
                                        } h-6 rounded-3xl py-0.5 disabled:bg-neutral-200`}>
                                        <span className='h-5'>{badgeTextMenuButton}</span>
                                    </div>
                                )}
                            </div>

                            <DownArrow
                                className='drop-down-icon-rotate'
                                fill={dropDownIconFill ?? '#6C5DD3'}
                                width={24}
                                height={24}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            className={`absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-medium-regular rounded-md shadow-medium bg-shades-50 font-normal z-50 ${dropDownPadding} ${
                                openFromBottom && 'bottom-full mb-2'
                            }`}>
                            <div className={`${dropDownHeight}`}>
                                {menuItems.map((el, index) => (
                                    <div
                                        className='pt-2 mb-2 flex flex-row gap-2'
                                        key={index}>
                                        <Menu.Item>
                                            <div
                                                onClick={() => {
                                                    handleClickMenuItem(el);
                                                }}
                                                className='cursor-pointer'>
                                                <div className='flex flex-row items-center'>
                                                    {el.icon}
                                                    <span
                                                        className={`${el.icon && 'ml-2'} ${
                                                            itemStyle ?? 'paragraph-medium-regular'
                                                        } hover:text-primary-500`}>
                                                        {el.item}
                                                    </span>
                                                    {el.badgeText && (
                                                        <div className='px-2.5 paragraph-x-small-medium mx-2 group-disabled:text-neutral-400 h-6 rounded-3xl py-0.5 disabled:bg-neutral-200 text-neutral-700 bg-neutral-200'>
                                                            <span className='h-5'>{el.badgeText}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    </div>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
};
