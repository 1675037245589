import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';

export default function AddReservationFeePopup(props) {
    const { tableName = 'T2', setShowPopup } = props;

    const handleProceed = () => {
        setShowPopup();
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 overflow-auto z-50 p-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full sm:p-4'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col text-left'>
                            <span className='paragraph-large-medium'>Reservation fee</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Make payment for reservation of table {tableName}
                            </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={() => setShowPopup('reserveTable')}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            disabled
                            addonStyle='text-primary-500 paragraph-small-regular'
                            addon='Change'
                            label='Mobile number'
                            labelStyle='text-neutral-300 paragraph-small-medium'
                            placeholder='Enter mobile number'
                            shadow='shadow-xSmall'
                            handleClickAddon={() => setShowPopup('reserveTable')}
                        />
                    </div>

                    <div className='flex flex-row gap-4 items-end'>
                        <div className='w-full'>
                            <DefaultInputField
                                label='Enter reservation amount'
                                placeholder='Enter amount'
                                shadow='shadow-xSmall'
                            />
                        </div>

                        <div
                            className='w-fit'
                            onClick={handleProceed}>
                            <LargePrimaryButton label='Request payment' />
                        </div>
                    </div>

                    <div className='flex flex-row justify-between items-center mt-12'>
                        <span className='paragraph-large-medium'>Step 2/2</span>
                        <div
                            className='paragraph-large-medium text-primary-500 text-center cursor-pointer'
                            onClick={() => setShowPopup()}>
                            Skip for now
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
