import React from 'react';
import KOTCard from '../KOTCard';
import { useWindowSize } from '@uidotdev/usehooks';

export default function KotList(props) {
    const { showInputDish, customerDetail, setCustomerDetail, setItemToRemove, setShowConfirmation, setShowPopup } =
        props;

    const removeExtras = (index, extrasIndex) => {
        customerDetail[index].extras.splice(extrasIndex, 1);
        setCustomerDetail([...customerDetail]);
    };

    const updateQuantity = (updatedQuantity, dishIndex) => {
        if (updatedQuantity === 0) {
            setItemToRemove(dishIndex);
            setShowConfirmation(true);
        } else {
            setCustomerDetail((prevDetails) =>
                prevDetails.map((item, index) => (index === dishIndex ? { ...item, quantity: updatedQuantity } : item))
            );
        }
    };

    const { width: screenWidth, height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='flex flex-row items-center justify-between pb-4 mb-4 border-b border-neutral-300'>
                <span className='paragraph-medium-semi-bold pageContent:text-sm'>KOT 1</span>
                <span className='paragraph-medium-regular text-primary-500 cursor-pointer pageContent:text-sm'>
                    Show
                </span>
            </div>

            <div className='mb-2'>
                <span className='paragraph-medium-semi-bold pageContent:text-sm'>KOT 2</span>
            </div>

            <div className='-mr-5 pr-[14px] md:-mr-0 md:pr-0'>
                {customerDetail.map((el, index) => (
                    <div key={el.title}>
                        <KOTCard
                            {...el}
                            index={index}
                            setCustomerDetail={setCustomerDetail}
                            removeExtras={(extraIndex) => removeExtras(index, extraIndex)}
                            updateQuantity={(updatedQuantity) => updateQuantity(updatedQuantity, index)}
                            setShowPopup={setShowPopup}
                        />
                    </div>
                ))}
            </div>
        </>
    );
}
