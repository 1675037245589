import React from 'react';
import ContentLoader from 'react-content-loader';

const CustomerDetailsTopLoader = () => {
    return (
        <ContentLoader
            width='1270px'
            height='160px'
            className=' w-fit rounded-md'>
            <rect
                x='0'
                y='20'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='0'
                y='65'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='0'
                y='110'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            {/* second column */}
            <rect
                x='330'
                y='20'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='330'
                y='65'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='330'
                y='110'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />

            {/* fourth column */}
            <rect
                x='670'
                y='20'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='670'
                y='65'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='670'
                y='110'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            {/* last column */}
            <rect
                x='1000'
                y='20'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='1000'
                y='65'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
            <rect
                x='1000'
                y='110'
                rx='4'
                ry='4'
                width='200'
                height='30'
            />
        </ContentLoader>
    );
};

export default CustomerDetailsTopLoader;
