import React, { useEffect, useRef, useState } from 'react';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSelector } from 'react-redux';
import TableLoader from '../ContentLoader/TableLoader';
import { fetchOutletwiseOffer } from '../../Screen/Offers/NewComponent/functions';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import { LargePrimaryButton, LargeTertiaryButton } from '../Buttons/Button';
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import APIV5 from '../../api/axios/APIV5';
import { IsMobileScreen } from '../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../Assets/chevron-down.svg';

export default function LeftFlyOut(props) {
    const { handleClickClose, fetchOutletWiseOffersList } = props;

    const [menuItem, setMenuItem] = useState([]);

    const [isLastPage, setIsLastPage] = useState(false);

    const [activePage, setActivePage] = useState(1);

    const [isApiCalled, setIsApiCalled] = useState(false);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const fetchAndSetMenuItem = async (params) => {
        const offerList = await fetchOutletwiseOffer(params, selectedRestaurantId, selectedOutletId);
        setMenuItem(offerList.offers.data);
        setIsListLoading(false);
    };

    useEffect(() => {
        fetchAndSetMenuItem({ page: 1, per_page: 10 });
    }, []);

    const ref = useRef();

    const [isListLoading, setIsListLoading] = useState(true);

    useOutsideClick(ref, handleClickClose);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragOver = async (event) => {
        const { over } = event;
        const thirdLastIndex = menuItem.length - 2;

        if (!isApiCalled && !isLastPage && over && over.id === menuItem[thirdLastIndex].id) {
            setIsApiCalled(true);
            setIsListLoading(true);

            const response = await fetchOutletwiseOffer(
                { page: activePage + 1, per_page: 10 },
                selectedRestaurantId,
                selectedOutletId
            );

            if (response) {
                setMenuItem((prevItems) => [...prevItems, ...response.offers.data]);
                setIsLastPage(!response.offers.has_more_pages);
                setActivePage((prevState) => prevState++);
                setIsListLoading(false);
            }

            setIsApiCalled(false);
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setMenuItem((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };

    const handleClickSave = async () => {
        try {
            const response = await APIV5.put(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/offer/rearrange`,
                { rearrange: menuItem.map((el, index) => ({ offer_id: el.id, display_sequence: index + 1 })) }
            );

            if (response.success) {
                handleClickClose();
                fetchOutletWiseOffersList();
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='max-w-[800px] w-full right-0 fixed h-screen bg-shades-50 px-8 py-8 md:px-0 md:pt-0 md:pb-20 m-auto md:max-w-full md:relative overflow-auto md:h-auto'
                    ref={ref}>
                    {isMobileScreen && (
                        <div
                            className='paragraph-medium-medium flex flex-row mb-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium'>Back to offers</span>
                        </div>
                    )}
                    <div className='paragraph-large-medium mb-8 md:mb-4'>Re-arrage offer list</div>

                    <div className='w-full border border-neutral-300 rounded-lg overflow-auto scrollbar-style'>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            modifiers={[
                                restrictToVerticalAxis,
                                restrictToVerticalAxis,
                                restrictToParentElement,
                                restrictToFirstScrollableAncestor,
                            ]}
                            onDragOver={handleDragOver}>
                            <table className='w-full break-words'>
                                <TableHeader showDescription={!IsMobileScreen()} />

                                <tbody
                                    className={`overflow-auto scrollbar-style ${
                                        menuItem.length > 10 ? 'h-[70.5vh]' : 'h-fit'
                                    } block`}>
                                    <SortableContext
                                        items={menuItem}
                                        strategy={verticalListSortingStrategy}>
                                        {menuItem?.map((el, index) => {
                                            return (
                                                <TableRows
                                                    {...el}
                                                    key={el.id}
                                                    index={index}
                                                />
                                            );
                                        })}
                                    </SortableContext>

                                    {isListLoading ? (
                                        <TableLoader
                                            numberOfRow={menuItem.length ? 1 : 10}
                                            columns={4}
                                        />
                                    ) : null}
                                </tbody>
                            </table>
                        </DndContext>
                    </div>

                    <div className='flex flex-row items-center absolute bottom-5 w-full max-w-[735px] md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'>
                        <div
                            className='w-1/2 mr-4'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>

                        <div
                            className='w-1/2 ml-4'
                            onClick={handleClickSave}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
