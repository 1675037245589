import React from 'react';
import ContentLoader from 'react-content-loader';

export const ContentLoaderWithCheckbox = (props) => {
    const { columns } = props;

    return (
        <>
            {[...Array(8)].map((_, index) => (
                <React.Fragment key={index}>
                    <SingleTableRow numberOfColumn={columns} />
                </React.Fragment>
            ))}
        </>
    );
};

const SingleTableRow = (props) => {
    const { numberOfColumn } = props;

    return (
        <tr>
            <td>
                <div className='w-full border-b h-full'>
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className='h-[70px] w-full pt-6'>
                        <rect
                            x='0'
                            y='0'
                            rx='4'
                            ry='4'
                            width='60%'
                            height='24'
                        />
                    </ContentLoader>
                </div>
            </td>
            {[...Array(numberOfColumn)].map((_, index) => (
                <td
                    key={index}
                    className='w-[20%]'>
                    <div className='border-b ml-14 h-full float-right'>
                        <ContentLoader
                            width='100%'
                            height='100%'
                            className='h-[70px] pt-6'>
                            <rect
                                x='0'
                                y='0'
                                rx='0'
                                ry='0'
                                width='24'
                                height='24'
                            />
                        </ContentLoader>
                    </div>
                </td>
            ))}
        </tr>
    );
};
