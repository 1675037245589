import React from 'react';
import { ReactComponent as LinearMenuIcon } from '../../../../Assets/linear-menu.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import _ from 'lodash';

export default function TableRows(props) {
    const { title, index, translations } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t w-full flex flex-row'
            ref={setNodeRef}
            style={style}
            key={index}
            {...attributes}
            {...listeners}>
            <div className='pl-6 cursor-pointer w-1/2 flex my-auto'>
                <div className='flex flex-row items-center'>
                    <span className='mr-3'>
                        <LinearMenuIcon />
                    </span>

                    <img
                        src={translations.image[_.keys(translations.image)[0]].image_url}
                        alt='img'
                        className='max-h-[50px]'
                    />
                </div>
            </div>

            <div className='pl-6 w-1/2 flex my-auto'>{title} </div>
        </div>
    );
}
