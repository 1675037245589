import React, { useState } from 'react';
import { ReactComponent as SwiggyIcon } from '../../../../Assets/swiggy.svg';
import { ReactComponent as ZomatoIcon } from '../../../../Assets/zomato.svg';
import ListViewOfKOT from '../../Components/ListViewOfKOTSection/ListViewOfKOTSection';
import { IsMobileScreen } from '../../../../Constants/Constants';
import KOT from '../../../../Components/KOT/KOT';
import RiderDetailsPopup from './Popup/RiderDetails/RiderDetailsPopup';
import CancelOrderPopup from './Popup/CancelOrder/CancelOrderPopup';
import AcceptOrderPopup from './Popup/AcceptOrderPopup/AcceptOrderPopup';
import AssignRiderPopup from '../../Popup/AssignRider/AssignRiderPopup';
import ReadyOrderPopup from './Popup/ReadyOrder/ReadyOrderPopup';
import OrderDetailsPopup from '../../../../Components/CustomerOrderDetails/OrderDetailsPopup';
import OrdersDetailsPopup from '../../Popup/OrdersDetailsPopup';

export default function LiveOrder(props) {
    const { isKotView } = props;

    const [showPopup, setShowPopup] = useState(null);

    const orders = [
        {
            icon: (
                <SwiggyIcon
                    height={48}
                    width={48}
                />
            ),
            orderingMode: 'Delivery order',
            label: '#BBQA',
            isBillPaid: true,
            dishPacked: 0,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    note: 'Keep the pizza loaded with ample amount of cheese',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    isPrepared: true,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
            ],
            timeElapsed: [
                {
                    mins: 15,
                },
                {
                    seconds: 16,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            label: '#BBQA',
            isBillPaid: false,
            dishServed: 3,
            tableServed: '(T1)',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    isPrepared: true,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 2,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
            ],
            timeElapsed: [
                {
                    mins: 10,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            label: '#BBQA',
            isBillPaid: true,
            dishServed: 3,
            tableServed: '(T1)',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
            ],
            timeElapsed: [
                {
                    mins: 5,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            label: '#BBQA',
            isBillPaid: true,
            dishPacked: 7,
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            icon: <ZomatoIcon />,
            orderingMode: 'Delivery order',
            label: '#BBQA',
            isBillPaid: true,
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            label: '#BBQA',
            isUpdated: true,
            isBillPaid: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            icon: (
                <SwiggyIcon
                    height={48}
                    width={48}
                />
            ),
            orderingMode: 'Delivery order',
            label: '#BBQA',
            isBillPaid: true,
            isUpdated: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
        {
            orderingMode: 'Dine-In order',
            label: '#BBQA',
            isUpdated: true,
            isBillPaid: true,
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse',
                    foodType: 'veg',
                    quantity: 1,
                    updatedQuantity: 3,
                },
                {
                    displayName: 'Indi Tandoori Paneer',
                    foodType: 'veg',
                    quantity: 1,
                },
            ],
            timeElapsed: [
                {
                    mins: 3,
                },
                {
                    seconds: 10,
                },
            ],
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isKotView && !isMobileScreen && (
                <div className='md:contents -mx-2.5 pageContentSmall:-mx-1 pageContentSmall:pt-2 largeTablet:mx-0 pb-20'>
                    {orders.map((el, index) => (
                        <div
                            className='w-[410px] pageContentLaptopSamll:w-[31.6%] pageContentSmall:w-[32.6%] xl:w-[49.1%] mx-2.5 pageContentSmall:mx-1 largeTablet:mx-0 pt-4 pageContentSmall:pt-2 inline-block largeTablet:block align-top largeTablet:w-full'
                            key={index}>
                            <KOT
                                KOT={index + 1}
                                setShowPopup={setShowPopup}
                                {...el}
                            />
                        </div>
                    ))}
                </div>
            )}

            {(!isKotView || isMobileScreen) && <ListViewOfKOT setShowPopup={setShowPopup} />}

            {showPopup === 'riderDetails' && <RiderDetailsPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'acceptOrder' && <AcceptOrderPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'riderAssignment' && <AssignRiderPopup handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'customerDetails' && (
                <OrderDetailsPopup
                    orderDetails={[]}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'readyOrder' && (
                <ReadyOrderPopup
                    items={orders[0]?.items}
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'cancelOrder' && (
                <CancelOrderPopup
                    backToPage='order'
                    handleClickClose={() => setShowPopup(null)}
                />
            )}

            {showPopup === 'ordersDetails' && <OrdersDetailsPopup handleClickClose={() => setShowPopup(null)} />}
        </>
    );
}
