import React, { useEffect } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { timingMenuItem } from '../../../../Constants/Constants';
import _ from 'lodash';

export default function BottomButtonSection(props) {
    const { id, type, fetchCatalogueList, handleClickClose, canFetchCatalogList } = props;

    const {
        watch,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const addBanner = async (params) => {
        try {
            const res = await APIV2.post(`/restaurants/${selectedRestaurantId}/banners`, params);

            if (res.success) {
                handleClickClose();
                canFetchCatalogList && fetchCatalogueList();
            }
        } catch (error) {
            console.log(error);
            error?.response?.data?.errors?.title &&
                setError('bannerTitle', { message: error?.response?.data?.errors?.title });
        }
    };

    const editBanner = async (params) => {
        try {
            const res = await APIV2.put(`/restaurants/${selectedRestaurantId}/banners/${id}`, params);

            if (res.success) {
                handleClickClose();

                fetchCatalogueList();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateError = () => {};

    const formatData = (data) => {
        const formatSchedule = () => {
            if (data.timingType.value === timingMenuItem[0].value) {
                return [];
            } else if (data.timingType.value === timingMenuItem[2].value) {
                return data.timing.map((el, index) => ({ days_of_week: [index + 1], time_slots: el }));
            } else {
                return [{ days_of_week: [1, 2, 3, 4, 5, 6, 7], time_slots: data.timing }];
            }
        };

        const formattedImage = !_.isArray(data.selectedImage)
            ? data.selectedImage
            : data.selectedImage
                  .map((el, index) => ({ [index]: el.id }))
                  .filter((item) => Object.keys(item).length !== 0);

        const convertArrayToObjSelectedImage = !_.isArray(data.selectedImage)
            ? data.selectedImage
            : Object.assign({}, ...formattedImage);

        const formattedOrderingMode = data?.orderingMode?.filter((el) => !!el.isSelected);

        const delete_ordering_modes = data?.orderingMode?.filter((el) => !el.isSelected)?.map((el) => el?.value?.id);

        return {
            title: data.bannerTitle,
            ordering_modes: formattedOrderingMode.map((el) => ({
                restaurant_ordering_mode_id: el.value.id,
                restaurant_ordering_mode_name: el.value.display_name,
            })),
            time_slot_setting: data.timingType.value,
            delete_ordering_modes,
            to_date: data.validity.value === 'always' ? null : '',
            from_date: data.validity.value === 'always' ? null : '',
            schedules: formatSchedule(),
            translations: { image: convertArrayToObjSelectedImage },
            screen: data.screen.value,
            ...data.bannerType.value,
        };
    };

    const handleClickSave = () => {
        const imageData = watch();

        if (imageData?.orderingMode?.every((mode) => !mode.isSelected)) {
            setError('orderingMode', { message: 'Please select at least one ordering mode' });
            return;
        }

        let hasError;

        availableLanguages.forEach((el) => {
            if (!imageData?.selectedImage?.[el.id]) {
                setError(`imageSelection.${el.id}`, { message: 'Please select an image' });
                hasError = true;
            } else {
                clearErrors(`imageSelection.${el.id}`);
            }
        });

        handleSubmit(generateError)();

        if (!_.isEmpty(errors) || hasError) return;

        const params = formatData(watch());

        type === 'edit' ? editBanner(params) : addBanner(params);
    };

    return (
        <>
            <div className='flex flex-row mt-12 px-8 md:bottom-0 md:fixed md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4'>
                <div
                    className='w-1/2 mr-[9.5px]'
                    onClick={handleClickClose}>
                    <LargeDestructiveButton label='Discard' />
                </div>

                <div
                    className='w-1/2 ml-[9.5px]'
                    onClick={handleClickSave}>
                    <LargePrimaryButton
                        label={type === 'edit' ? 'Edit banner' : 'Add banner'}
                        isDefault={false}
                    />
                </div>
            </div>
        </>
    );
}
