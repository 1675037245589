import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as LinkIcon } from '../../../../../Assets/link.svg';
import { ReactComponent as MenuIcon } from '../../../../../Assets/menu.svg';

export default function ListViewCataloguePresets(props) {
    const {
        presetName,
        categories,
        dishes,
        createdBy,
        createdOn,
        lastUpdatedOn,
        handleLClickLink,
        handleClickViewDetails,
        handleClickCreatedBy,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>PRESET NAME:</h3>
                        <span className='paragraph-small-regular'>{presetName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <div className='mt-[5px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700 pb-1'>
                                CATEGORIES AND DISHES:
                            </span>
                            <div className='flex flex-col'>
                                <div className='flex flex-row items-center mb-1'>
                                    <MenuIcon />
                                    <span className='paragraph-small-regular ml-2'>Categories - {categories}</span>
                                </div>
                                <div className='flex flex-row items-center'>
                                    <MenuIcon />
                                    <span className='paragraph-small-regular ml-2'>Dishes - {dishes}</span>
                                </div>
                            </div>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED BY:</span>
                            <span
                                className='paragraph-small-regular ml-1 text-primary-500 cursor-pointer'
                                onClick={handleClickCreatedBy}>
                                {createdBy}
                            </span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>CREATED ON:</span>
                            <span className='paragraph-small-regular ml-1'>{createdOn}</span>
                        </div>

                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>LAST UPDATED ON:</span>
                            <span className='paragraph-small-regular ml-1'>{lastUpdatedOn}</span>
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small mobile:text-[11px]'>LINK OUTLET:</span>
                                <div
                                    className='flex flex-row items-center mx-1 cursor-pointer'
                                    onClick={handleLClickLink}>
                                    <LinkIcon stroke='#6C5DD3' />
                                    <span className='text-primary-500 ml-1 paragraph-small-regular mobile:text-[11px]'>
                                        4
                                    </span>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => handleClickViewDetails()}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
