import React from 'react';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import ListViewCustomerGroupDetails from './ListViewCustomerGroupDetails';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function GroupDetailsTop(props) {
    const { goToCustomerInformation } = props;
    const isMobileScreen = IsMobileScreen();
    return (
        <>
            {isMobileScreen && (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={goToCustomerInformation}>
                    <LeftArrow className='rotate-90' />
                    <span className='ml-1'>Back to groups</span>
                </div>
            )}

            {!isMobileScreen ? (
                <div className='grid grid-cols-[minmax(261px,387.33px)_minmax(164px,290.33px)_minmax(223px,349.33px)_245px] pb-4 mb-4 border-b border-neutral-300 xl:grid-cols-[minmax(261px,1336px)_245px]'>
                    <div className='mb-3'>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Group name:</span>
                        <span className='paragraph-medium-regular ml-1'>Family</span>
                    </div>

                    <div className='mb-3'>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Customers count:</span>
                        <span className='paragraph-medium-regular ml-1'>02</span>
                    </div>

                    <div className='mb-3'>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Created by:</span>
                        <span className='paragraph-medium-regular ml-1'>Sarthak Kanchan</span>
                    </div>

                    <div className='mb-3'>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Created on:</span>
                        <span className='paragraph-medium-regular ml-1'>20 November, 2022</span>
                    </div>

                    <div className=''>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Last updated:</span>
                        <span className='paragraph-medium-regular ml-1'>20 November, 2022</span>
                    </div>

                    <div className=''>
                        <span className='paragraph-medium-semi-bold text-neutral-400'>Notifications sent:</span>
                        <span className='paragraph-medium-regular ml-1'>13</span>
                    </div>
                </div>
            ) : (
                <div className='mb-4 pb-4 border-b border-neutral-300'>
                    <ListViewCustomerGroupDetails
                        groupName='Family'
                        createdBy='Sarthak Kanchan'
                        lastUpdated='20 November, 2022'
                        customerCount='02'
                        createdOn='20 November, 2022'
                        notificationsSent='13'
                    />
                </div>
            )}
        </>
    );
}
