import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { DefaultInputField } from '../../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function TakeawayPopup(props) {
    const { handleClickClose, backToPage } = props;

    const navigate = useNavigate();

    const handleClickProceed = () => {
        handleClickClose();
        navigate('/pos?mode=takeaway');
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div className='max-w-[475px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:p-0 m-auto md:max-w-full'>
                    {!isMobileScreen ? (
                        <div className='flex flex-row justify-between items-center mb-6'>
                            <div className='flex flex-col'>
                                <span className='paragraph-large-medium'>Takeaway #BBQA</span>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    Enter basic details of the customer{' '}
                                </span>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    ) : (
                        <div
                            className='paragraph-medium-medium flex flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to {backToPage}</span>
                        </div>
                    )}

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Customer name'
                            placeholder='Enter customer name'
                        />
                    </div>

                    <div className='mb-12'>
                        <DefaultInputField
                            label='Mobile number'
                            placeholder='Enter mobile number'
                        />
                    </div>

                    <div
                        className='cursor-pointer md:justify-center md:fixed md:bottom-0 md:left-0 md:right-0 md:mb-0 md:shadow-dropShadow md:bg-white md:px-4 md:pt-2 md:pb-1'
                        onClick={handleClickProceed}>
                        <LargePrimaryButton label='Proceed' />
                    </div>
                </div>
            </div>
        </>
    );
}
