import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import PaginationWithNumber from '../../../Components/Pagination/PaginationWithNumber';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import ListViewCustomerOffer from '../Components/ListViewCustomerOffer';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function LinkOfferPopup(props) {
    const { selectedOffer, handleClickClose } = props;

    const tableDetails = [
        {
            couponCode: 'TRYNEW120',
            offerType: 'Flat discount',
            title: 'Big Discount - 120!',
            description: 'Get ₹120 off on orders above ₹250.',
        },
        {
            couponCode: 'TRYNEW150',
            offerType: 'Flat discount',
            title: 'Big Discount - 50!',
            description: 'Get ₹50 off on orders above ₹250.',
        },
        {
            couponCode: 'WELCOME20',
            offerType: 'Persantage discount',
            title: 'We Welcome You',
            description: 'Thanks for returning! We welcome you with 20% discount on your cart!',
        },
        {
            couponCode: 'BOGO2022',
            offerType: 'Buy X get Y free',
            title: 'Exclusive 2022 BOGO',
            description: 'Buy 1 dish and get another one absolutely free!',
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex md:relative p-4 md:p-0 overflow-auto lg:px-0'>
                <div className='max-w-[1102px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 lg:max-w-[710px] md:max-w-full md:rounded-none'>
                    {!isMobileScreen ? (
                        <div className='flex justify-between items-center mb-6'>
                            <div>
                                <h3 className='paragraph-large-medium'>Link offers</h3>
                                <div className='flex flex-row items-center'>
                                    <p className='paragraph-medium-italic text-neutral-500'>
                                        Enable the offer for the customers of the group
                                    </p>
                                    <div className='w-1 h-1 rounded-full bg-neutral-500 mx-3' />
                                    <span className='paragraph-medium-italic text-neutral-500'>
                                        Offers selected - {selectedOffer}
                                    </span>
                                </div>
                            </div>
                            <span
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </span>
                        </div>
                    ) : (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointerv'
                            onClick={handleClickClose}>
                            <LeftArrow className='rotate-90' />
                            <span className='ml-1'>Back to group details</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-6 md:block md:mb-4'>
                        <div className='max-w-[375px] w-full lg:max-w-[298px] md:max-w-full md:mb-4'>
                            <DefaultInputField
                                placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                                placeholder='Search offer'
                                shadow='shadow-xSmall'
                            />
                        </div>
                        <div className='flex flex-row items-center md:justify-between'>
                            <div className='flex flex-row justify-between items-center'>
                                <span className='paragraph-large-medium text-black mx-4 md:mx-0'>
                                    Show enabled offers
                                </span>
                                <span className='hidden md:block paragraph-medium-italic ml-1 text-neutral-500'>
                                    ({selectedOffer})
                                </span>
                            </div>
                            <ToggleSwitch enable={true} />
                        </div>
                    </div>

                    {!isMobileScreen ? (
                        <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border'>
                            <table className='w-full break-words table-row-bg'>
                                <thead>
                                    <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                        <th className='px-6 min-w-[100px]'>STATUS</th>
                                        <th className='px-6 min-w-[164px]'>COUPON CODE</th>
                                        <th className='px-6 min-w-[193px]'>OFFER TYPE</th>
                                        <th className='px-6 min-w-[198px]'>TITLE</th>
                                        <th className='px-6 min-w-[380px]'>DESCRIPTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableDetails.map((el, index) => {
                                        return (
                                            <tr
                                                className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                                key={index}>
                                                <td className='px-6'>
                                                    <ToggleSwitch enable={true} />
                                                </td>
                                                <td className='px-6'>{el.couponCode}</td>
                                                <td className='px-6'>{el.offerType}</td>
                                                <td className='px-6'>{el.title}</td>
                                                <td className='px-6'>{el.description}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className='md:mb-14'>
                            {tableDetails.map((el, index) => {
                                return (
                                    <div
                                        className='mb-2'
                                        key={index}>
                                        <ListViewCustomerOffer
                                            couponCode={el.couponCode}
                                            offerType={el.offerType}
                                            title={el.title}
                                            description={el.description}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    {!isMobileScreen && (
                        <div className='mt-4 lg:mt-6'>
                            <PaginationWithNumber />
                        </div>
                    )}

                    <div className='flex justify-end mt-12 md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4'>
                        <div className='max-w-[147px] md:max-w-full md:mx-4'>
                            <LargePrimaryButton label='Save changes' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
