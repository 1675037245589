import React, { useState } from 'react';
import { ReactComponent as InformationIcon } from '../../../Assets/information.svg';

export default function TotalBill(props) {
    const { isBillPaid } = props;

    const [showBreakUp, setShowBreakUp] = useState(false);

    return (
        <>
            <div className='relative w-full border-t border-neutral-300 pt-4'>
                <div
                    className={`absolute bottom-9 bg-white w-full border-t border-neutral-300 transition-all duration-300 ${
                        showBreakUp ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
                    }`}>
                    <div className='-mx-4 px-4 bg-white pt-4'>
                        <div className='flex flex-row justify-between mb-3'>
                            <span className='paragraph-medium-regular'>Tax</span>
                            <span className='paragraph-medium-regular'>₹200.00/-</span>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <span className='paragraph-medium-regular'>Subtotal</span>
                            <span className='paragraph-medium-regular'>₹2,125.00/-</span>
                        </div>
                    </div>
                </div>

                <div
                    className='flex flex-row justify-between items-center cursor-default'
                    onMouseEnter={() => setShowBreakUp(true)}
                    onMouseLeave={() => setShowBreakUp(false)}>
                    <div className='flex flex-row items-center'>
                        <span className='paragraph-medium-semi-bold'>Total bill amount</span>
                        {isBillPaid ? (
                            <span className='text-success-600 text-[10px] border h-fit font-medium px-2 py-1 text-center border-success-600 rounded bg-success-50 ml-2 leading-none'>
                                Paid
                            </span>
                        ) : (
                            <span className='text-destructive-600 text-[10px] border h-fit font-medium px-2 py-1 text-center border-destructive-600 rounded bg-destructive-100 ml-2 leading-none'>
                                Unpaid
                            </span>
                        )}
                    </div>

                    <div className='flex flex-row items-center gap-3'>
                        <InformationIcon
                            height={16}
                            width={16}
                        />
                        <span className='paragraph-medium-semi-bold'>₹2,125.00/-</span>
                    </div>
                </div>
            </div>
        </>
    );
}
