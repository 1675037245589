import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function CreatePresetPopup(props) {
    const { handleClickClose, handleClickProceed } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md:block'>
            <div className='max-w-[475px] w-full rounded-xl bg-shades-50 pt-6 pb-9 px-8 m-auto max-h-[969px] md:max-w-full md:rounded-none md:p-4 md:h-full md:max-h-full'>
                {isMobileScreen && (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                        onClick={handleClickClose}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to catalogue</span>
                    </div>
                )}

                <div className='flex flex-row items-center justify-between mb-6'>
                    <div>
                        <h3 className='paragraph-large-medium'>Create preset</h3>
                        <div className='flex flex-row items-center'>
                            <p className='paragraph-medium-italic text-neutral-500'>
                                Create a new preset by naming it.
                            </p>
                        </div>
                    </div>

                    <span
                        className='cursor-pointer md:hidden'
                        onClick={handleClickClose}>
                        <CloseIcon />
                    </span>
                </div>

                <div className='mb-20'>
                    <DefaultInputField
                        label='Preset name'
                        placeholder='Enter preset name'
                        shadow='shadow-xSmall'
                    />
                </div>

                <div className='flex flex-row items-center justify-between w-full'>
                    <span className='paragraph-large-medium md:hidden'>Step 1/2</span>
                    <div
                        className='max-w-[156px] w-full cursor-pointer md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4 md:max-w-full'
                        onClick={handleClickProceed}>
                        <LargePrimaryButton
                            label='Proceed'
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
