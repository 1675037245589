import React from 'react';
import { Categories } from './Cards';
import { getFoodType } from './Function';

export default function SubCategoryCard(props) {
    const {
        subCategory,
        pageState,
        selectedCategoryDish,
        setSelectedCategoryDish,
        handleClickCategory,
        selectedCategoryId,
        setSelectedCategoryId,
        setClickedCategory,
        goToEditCategory,
        category,
        handleChangeAvailability,
        totalItem,
        inStock,
        totalItems,
    } = props;

    const isCategorySelected = (category) => {
        if (selectedCategoryId.includes(category.parent_id)) {
            return true;
        }

        if (selectedCategoryId.includes(category.id)) {
            return true;
        }

        return false;
    };

    const handleCategorySelection = (isChecked, category) => {
        let selectedCategoryState = isChecked
            ? [...selectedCategoryId, category.id]
            : selectedCategoryId.filter((el) => el !== category.id);

        if (isChecked && subCategory.every((el) => selectedCategoryState.includes(el.id))) {
            selectedCategoryState = [...selectedCategoryState, category.parent_id];
        } else if (!isChecked) {
            selectedCategoryState = selectedCategoryState.filter((el) => el !== category.parent_id);
        }

        setSelectedCategoryId(selectedCategoryState);
    };

    const handleClickSubcategory = (subCategory) => {
        setSelectedCategoryDish?.((prevState) => ({
            ...prevState,
            subCategoryId: subCategory.id,
        }));
        handleClickCategory?.(subCategory);
        setClickedCategory({ id: subCategory.id, type: 'subcategory' });
    };

    return (
        <>
            {category?.sub_categories?.map((subCategory) => (
                <SubCategoryCardComponent
                    key={subCategory.id}
                    pageState={pageState}
                    subCategory={subCategory}
                    selectedCategoryDish={selectedCategoryDish}
                    handleClickSubcategory={() => handleClickSubcategory(subCategory)}
                    goToEditCategory={goToEditCategory}
                    handleCategorySelection={handleCategorySelection}
                    isCategorySelected={isCategorySelected}
                    handleChangeAvailability={handleChangeAvailability}
                    totalItem={totalItem}
                    inStock={inStock}
                    totalItems={totalItems}
                />
            ))}
        </>
    );
}

export const SubCategoryCardComponent = (props) => {
    const {
        pageState,
        subCategory,
        selectedCategoryDish,
        handleClickSubcategory,
        goToEditCategory,
        handleCategorySelection,
        isCategorySelected,
        handleChangeAvailability,
        totalItem,
        inStock,
        totalItems,
    } = props;

    return (
        <div
            className={`mt-4 ml-4 -mr-4 w-full max-w-[303px] md:max-w-full md:mt-2 relative ${
                (pageState?.edit || pageState?.reArrange) && 'md:pl-2.5'
            }`}>
            <Categories
                img={subCategory.image_url}
                title={subCategory.internal_name}
                type={getFoodType(subCategory)}
                subCategoryDetails={subCategory.subCategoryDetails}
                isActive={subCategory.id === selectedCategoryDish?.subCategoryId}
                handleClickCategory={() => handleClickSubcategory()}
                isEdit={pageState?.edit}
                handleClickEdit={() => goToEditCategory(subCategory)}
                onChange={(isChecked) => handleCategorySelection(isChecked, subCategory)}
                isChecked={isCategorySelected(subCategory)}
                handleChangeAvailability={handleChangeAvailability}
                totalItem={totalItem}
                inStock={inStock}
                totalItems={totalItems}
            />
        </div>
    );
};
