import React, { useEffect, useState } from 'react';
import { ReactComponent as ScheduleIcon } from '../../../../../../Assets/schedule.svg';
import { format, parse } from 'date-fns';
import { isArray } from 'react-advanced-cropper';

export default function DishTiming(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const convertTo12HourFormat = (time24) => {
        const referenceDate = new Date();
        const [hours, minutes] = time24.split(':');
        const parsedDate = new Date(referenceDate.setHours(hours, minutes));
        return format(parsedDate, 'hh:mm aa');
    };

    const groupTimeSlotsByDay = (timeSlots) => {
        const grouped = {};

        timeSlots.forEach(({ time_slots: { day_of_week, start_time, end_time } }) => {
            const dayName = dayNames[day_of_week - 1];
            const formattedStartTime = convertTo12HourFormat(start_time);
            const formattedEndTime = convertTo12HourFormat(end_time);

            if (!grouped[dayName]) {
                grouped[dayName] = [];
            }

            grouped[dayName].push({
                start_time: formattedStartTime,
                end_time: formattedEndTime,
            });
        });

        return grouped;
    };

    const [timeSlots, setTimeSlots] = useState([]);

    const formateSameTimeForAllDay = () => {
        const oneDayTimeSlot = dishDetails.schedule
            .filter((el) => el.time_slots.day_of_week === 1)
            ?.map((el) => {
                return { startTime: el.time_slots.start_time, endTime: el.time_slots.end_time };
            });

        setTimeSlots(oneDayTimeSlot);
    };

    const formateDifferentDay = () => {
        const groupedTimeSlots = groupTimeSlotsByDay(dishDetails.schedule);
        setTimeSlots(groupedTimeSlots);
    };

    useEffect(() => {
        setTimeSlots([]);
        switch (dishDetails.time_slot_setting) {
            case 'same_time_slot_for_all_days':
                formateSameTimeForAllDay();
                break;

            case 'different_time_slots_for_different_days':
                formateDifferentDay();
                break;

            default:
                break;
        }
    }, [dishDetails.time_slot_setting]);

    const convertTo12HourTime = (time24) => {
        const parsedStartTime = parse(time24.startTime, 'HH:mm', new Date());
        const startTime = format(parsedStartTime, 'hh:mm a');

        const parsedEndTime = parse(time24.startTime, 'HH:mm', new Date());
        const endTime = format(parsedEndTime, 'hh:mm a');

        return { startTime, endTime };
    };

    return (
        <>
            <div
                className={`py-4 px-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex'>
                    <span className='paragraph-medium-medium'>Dish timing</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <>
                        {dishDetails.time_slot_setting === 'no_time_slots' && (
                            <div className='mt-4 paragraph-small-regular'>
                                Available during store operational hours.
                            </div>
                        )}

                        {dishDetails.time_slot_setting === 'same_time_slot_for_all_days' && (
                            <div className='pt-2'>
                                {isArray(timeSlots) &&
                                    timeSlots?.map((el, index) => {
                                        const { startTime, endTime } = convertTo12HourTime(el);

                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-row items-center mt-2'>
                                                <ScheduleIcon
                                                    width={24}
                                                    height={24}
                                                />
                                                <span className='ml-1 paragraph-small-regular'>
                                                    {startTime} - {endTime}
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}

                        {dishDetails.time_slot_setting === 'different_time_slots_for_different_days' &&
                            Object.entries(timeSlots).map(([day, slots]) => (
                                <div
                                    key={day}
                                    className='mt-4'>
                                    <span className='paragraph-small-medium'>{day}</span>

                                    {slots.length ? (
                                        slots.map(({ start_time, end_time }, index) => (
                                            <div
                                                key={index}
                                                className='flex flex-row items-center my-1'>
                                                <ScheduleIcon
                                                    width={24}
                                                    height={24}
                                                />
                                                <span className='ml-1 paragraph-small-regular'>
                                                    {start_time} - {end_time}
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='paragraph-small-regular text-neutral-400 italic'>
                                            Time slot unavailable
                                        </div>
                                    )}
                                </div>
                            ))}
                    </>
                )}
            </div>
        </>
    );
}
