import React, { useState } from 'react';
import InfrastructureCredit from './Components/InfrastructureCredit';
import ServiceCredit from './Components/ServiceCredit';
import { useParams } from 'react-router-dom';
import Layout from './Components/Layout';
import APIV5 from '../../api/axios/APIV5';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

export default function Credits() {
    const { creditType } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isInfrastructureListLoading, setIsInfrastructureListLoading] = useState(false);

    const [isServiceListLoading, setIsServiceListLoading] = useState(false);

    const fetchTransactionList = async (params) => {
        try {
            setIsInfrastructureListLoading(true);
            const queryParams = params ?? {
                filters: { start_date: format(new Date(), 'yyyy-MM-dd'), end_date: format(new Date(), 'yyyy-MM-dd') },
            };

            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/infrastructure/credit-transactions`,
                { params: queryParams }
            );

            setIsInfrastructureListLoading(false);

            return response;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchServiceCreditList = async (params) => {
        try {
            setIsServiceListLoading(true);
            const queryParams = params ?? {
                filters: { start_date: format(new Date(), 'yyyy-MM-dd'), end_date: format(new Date(), 'yyyy-MM-dd') },
            };

            const response = await APIV5.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/services/credit-transactions`,
                { params: queryParams }
            );
            setIsServiceListLoading(false);

            return response;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Layout
            fetchTransactionList={fetchTransactionList}
            fetchServiceCreditList={fetchServiceCreditList}
            creditType={creditType}>
            {creditType === 'infrastructure' ? (
                <InfrastructureCredit
                    fetchTransactionList={fetchTransactionList}
                    creditType={creditType}
                    isInfrastructureListLoading={isInfrastructureListLoading}
                />
            ) : (
                <ServiceCredit
                    fetchServiceCreditList={fetchServiceCreditList}
                    creditType={creditType}
                    isServiceListLoading={isServiceListLoading}
                />
            )}
        </Layout>
    );
}
