import React from 'react';
import { Categories } from './Card';
import { getFoodType } from '../functions';

export default function CategoryCardSection(props) {
    const {
        pageState,
        category,
        selectedCategoryDish,
        handleClickCategoryCard,
        goToEditCategory,
        setShowSubCategory,
        showSubCategory,
        handleCategorySelection,
        selectedCategoryId,
    } = props;

    const getSumSubcategoryDish = (subCategories) => {
        return subCategories.reduce((total, category) => total + category.total_items, 0);
    };

    const totalItems = !!category.sub_categories.length
        ? getSumSubcategoryDish(category.sub_categories)
        : category.total_items;

    return (
        <>
            <div
                className={`mt-4 w-full max-w-[303px] xl:max-w-full md:max-w-full md:mt-2 relative ${
                    (pageState.edit || pageState.reArrange) && 'md:pl-2.5'
                }`}>
                <Categories
                    img={category.image_url}
                    title={category.internal_name}
                    totalItems={totalItems}
                    type={getFoodType(category)}
                    isActive={category.category_id === selectedCategoryDish?.category?.category_id}
                    handleClickCategory={() => handleClickCategoryCard(category)}
                    isEdit={pageState.edit}
                    handleClickEdit={() => goToEditCategory(category)}
                    isRearrange={pageState.reArrange}
                    subCategoryNumber={category.sub_categories.length}
                    setShowSubCategory={() =>
                        setShowSubCategory((prevState) => {
                            return !!prevState ? null : category.category_id;
                        })
                    }
                    showSubCategory={showSubCategory === category.category_id}
                    isFromSelection={pageState.selection}
                    onChange={(isChecked) => handleCategorySelection(isChecked, category)}
                    isChecked={selectedCategoryId.includes(category.category_id)}
                />
            </div>
        </>
    );
}
