import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch';
import { useFieldArray } from 'react-hook-form';
import SlotSection from './SlotSection';

export default function SingleSeparateSlotSection(props) {
    const {
        dayIndex,
        dayLabel,
        fieldName,
        setValue,
        errors,
        control,
        watch,
        clearErrors,
        timingTypeName,
        subField,
        preparationTimeName,
        limitedSlot,
        setShowTabChangeWarningPopup,
        zIndex,
    } = props;

    const handleToggleAvailability = (isActive) => {
        isActive ? addSlot() : remove();

        setOpenBox((prevState) => {
            return !isActive ? prevState.filter((el) => el !== dayIndex) : [...prevState, dayIndex];
        });

        setShowTabChangeWarningPopup?.(true);
    };

    const [openBox, setOpenBox] = useState([]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: `${fieldName}.${dayIndex}`,
    });

    const isSlotActivated = openBox.includes(dayIndex) || !!watch(fieldName)?.[dayIndex]?.length;

    const addSlot = () => {
        const newTimeSlot = { start_time: '', end_time: '' };
        append(newTimeSlot);
    };

    useEffect(() => {
        subField
            ? watch(`${fieldName}`).flat().length && clearErrors(`${subField}.chooseOneTimeSlot`)
            : watch(`${fieldName}`).flat().length && clearErrors('chooseOneTimeSlot');

        if (!fields.length && !!watch(`${fieldName}.${dayIndex}`)?.length) {
            setValue(`${fieldName}.${dayIndex}`, watch(`${fieldName}.${dayIndex}`));
        }

        // eslint-disable-next-line
    }, [watch(`${fieldName}.${dayIndex}`)]);

    return (
        <>
            <div className='flex flex-row items-center justify-between mb-2'>
                <div className='flex flex-row items-center'>
                    <span className='h-6'>
                        <ToggleSwitch
                            canChange
                            setIsEnabled={handleToggleAvailability}
                            isEnable={isSlotActivated}
                        />
                    </span>

                    <div className='paragraph-small-medium pl-2'>{dayLabel}</div>
                </div>

                <span
                    className={`paragraph-small-medium ${
                        isSlotActivated && fields.length !== limitedSlot
                            ? 'text-primary-500 cursor-pointer'
                            : 'text-neutral-500 cursor-not-allowed'
                    }`}
                    onClick={() => isSlotActivated && fields.length !== limitedSlot && addSlot()}>
                    {isSlotActivated ? '+ Add slot' : 'Unavailable'}
                </span>
            </div>

            <SlotSection
                remove={remove}
                fields={fields}
                fieldName={`${fieldName}.${dayIndex}`}
                control={control}
                watch={watch}
                setValue={setValue}
                errors={errors}
                timingTypeName={timingTypeName}
                preparationTimeName={preparationTimeName}
                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                zIndex={zIndex}
            />
        </>
    );
}
