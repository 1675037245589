import * as types from '../types/loading';

const initialState = {
    totalRequest: 0,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.START_LOADING:
            return {
                ...state,
                totalRequest: state.totalRequest + 1,
            };

        case types.FINISH_LOADING:
            return {
                ...state,
                totalRequest: state.totalRequest - 1,
            };

        default:
            return state;
    }
};
