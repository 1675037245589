import React from 'react';
import { ReactComponent as LinearMenuIcon } from '../../Assets/linear-menu.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function TableRows(props) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'pointer',
    };

    return (
        <tr
            className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t'
            ref={setNodeRef}
            style={style}
            key={props.id}
            {...attributes}
            {...listeners}>
            <td className='pl-6 cursor-pointer min-w-[445px]'>
                <div className='flex flex-row items-center'>
                    <span className='mr-3'>
                        <LinearMenuIcon />
                    </span>
                    <span>{props.internal_name}</span>
                </div>
            </td>
            <td className='pl-10 min-w-[289px] text-center'>{props.capacity}</td>
        </tr>
    );
}
