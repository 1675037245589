import React from 'react';
import { ReactComponent as SwiggyIcon } from '../../../../Assets/swiggy.svg';
import ListviewOrder from '../ListviewOrder';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../../Constants/Constants';
import ListViewOfKOT from '../ListViewOfKOT/ListViewOfKOT';
import TableSection from './TableSection';

export default function ListViewOfKOTSection(props) {
    const { setShowPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const orderDetails = [
        {
            customerName: 'Amrendr bahubali',
            orderingMode: 'Dine-in',
            orderAge: '10 mins ago',
            orderAmount: '₹559.00/-',
            orderStatus: 'Pending',
        },
        {
            customerName: 'Amrendr bahubali',
            orderingMode: 'Takeaway',
            orderAge: '15 mins ago',
            orderAmount: '₹559.00/-',
            orderStatus: 'Preparing',
        },
        {
            customerName: 'Amrendr bahubali',
            orderingMode: 'Delivery',
            orderAge: '2 hrs 15 mins',
            orderAmount: '₹559.00/-',
            orderStatus: 'Prepared',
        },
    ];

    const orderCardDetails = [
        {
            icon: (
                <SwiggyIcon
                    height={40}
                    width={40}
                />
            ),
            orderingMode: 'Order number',
            orderLabel: '#BBQR',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    note: 'Keep the pizza loaded with ample amount of cheese',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
            ],
            timeElapsed: [
                {
                    mins: 15,
                },
                {
                    seconds: 16,
                },
            ],
        },
    ];

    const screenWidth = useWindowSize().width;

    return (
        <>
            {!isMobileScreen && (
                <div className='flex flex-row justify-between mt-6 lg:mt-4'>
                    <TableSection
                        orderDetails={orderDetails}
                        setShowPopup={setShowPopup}
                    />

                    {screenWidth > 1280 && (
                        <div className='max-w-[518px] w-full pageContent:min-w-min pageContent:max-w-[450px]'>
                            {orderCardDetails?.map((el, index) => (
                                <div key={index}>
                                    <ListViewOfKOT
                                        {...el}
                                        setShowPopup={setShowPopup}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            {isMobileScreen && (
                <div className='md:mt-4'>
                    {orderDetails?.map((el, index) => (
                        <div
                            className=''
                            key={index}>
                            <ListviewOrder
                                {...el}
                                handleClickOrdersDetails={() => setShowPopup('customerDetails')}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
