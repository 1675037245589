import React from 'react';
import DropdownSection from '../../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import InputSection from '../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function MaximumUsageSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='my-4 max-w-[312px] relative md:max-w-full'>
                <DropdownSection
                    menuItems={preferenceMenuItem}
                    label='Maximum usage count preference'
                    shadow='shadow-smallDropDownShadow'
                    labelPaddingB='pb-2'
                    height='h-[52px]'
                    labelStyle='paragraph-large-medium'
                    rules={{ required: 'Please select preference' }}
                    name='bill_payments.isMaxUsageCount'
                    placeholder='Please select preference'
                    clearErrorName={['bill_payments.max_usage_count']}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />

                {errors?.bill_payments?.isMaxUsageCount && (
                    <ErrorMessage errors={errors?.bill_payments?.isMaxUsageCount} />
                )}
            </div>

            <InputSection
                placeholder='Please add discount'
                name='bill_payments.max_usage_count'
                label='Maximum usage count'
                labelStyle={`paragraph-large-medium ${
                    !watch('bill_payments.isMaxUsageCount')?.value && 'text-neutral-300'
                }`}
                shadow='shadow-smallDropDownShadow'
                disabled={!watch('bill_payments.isMaxUsageCount')?.value}
                inputType='number'
                rules={{
                    validate: (value) => {
                        if (value) return true;
                        if (watch('bill_payments.isMaxUsageCount')?.value) return 'Please enter maximum usage count';
                    },
                }}
                handleInputChange={() => setShowTabChangeWarningPopup(true)}
            />
            {errors?.bill_payments?.max_usage_count && <ErrorMessage errors={errors?.bill_payments?.max_usage_count} />}
        </>
    );
}
