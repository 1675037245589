import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';

const CustomercartdetailsListView = (props) => {
    const { orderType, customerName, customerType, orderDate, orderAmount, cache, onResize, cartId, setPopupOrderId } =
        props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    const [icon, setIcon] = useState();

    useEffect(() => {
        switch (orderType) {
            case 'delivery':
                setIcon(<DeliveryIcon className='h-auto w-full' />);
                break;

            case 'dine-in':
                setIcon(<DininIcon className='h-auto w-full' />);
                break;

            default:
                setIcon(<TakeAwayIcon className='h-auto w-full' />);
                break;
        }
    }, [orderType]);

    const orderTypeList = {
        dine_in: 'Dine-In',
        delivery: 'Delivery',
        takeaway: 'Takeaway',
    };

    useEffect(() => {
        cache.clearAll();
        onResize();
    }, [isShowDetails, onResize]);

    return (
        <>
            <div className='w-full px-4 py-[11px] border border-neutral-300 rounded-md md:mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className={`${isShowDetails && 'flex flex-row items-center'}`}>
                        <h3 className='paragraph-overline-small text-neutral-700 pr-2'>CUSTOMER NAME:</h3>
                        <span className='paragraph-small-regular'>{customerName}</span>
                    </div>

                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center mr-1'>
                                <span
                                    className={`border rounded paragraph-small-regular px-[7px] py-[3px] md:text-xs ${
                                        customerType.charAt(0).toUpperCase() + customerType.slice(1) === 'New'
                                            ? 'text-primary-500 border-primary-500 bg-primary-50'
                                            : 'text-secondary-800 border-secondary-800 bg-secondary-100'
                                    }`}>
                                    {customerType.charAt(0).toUpperCase() + customerType.slice(1)}
                                </span>
                            </div>
                        )}
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>
                {isShowDetails && (
                    <>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700'>CUSTOMER TYPE:</span>
                            <span className='paragraph-small-regular ml-1'>
                                <span
                                    className={`border rounded paragraph-small-regular px-[7px] py-[3px] md:text-xs ${
                                        customerType.charAt(0).toUpperCase() + customerType.slice(1) === 'New'
                                            ? 'text-primary-500 border-primary-500 bg-primary-50'
                                            : 'text-secondary-800 border-secondary-800 bg-secondary-100'
                                    }`}>
                                    {customerType.charAt(0).toUpperCase() + customerType.slice(1)}
                                </span>
                            </span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{orderDate}</span>
                        </div>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER AMOUNT:</span>
                            <span className='paragraph-small-regular ml-1'>₹{orderAmount}/-</span>
                        </div>

                        <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700'>ORDER TYPE:</span>
                                <div className='flex flex-row items-center ml-1'>
                                    <div className='mr-1 w-5 h-5'>{icon}</div>
                                    <span className='paragraph-small-regular'>{orderTypeList[orderType]}</span>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer mobile:pt-1'
                                onClick={() => setPopupOrderId(cartId)}>
                                View full details
                            </span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CustomercartdetailsListView;
