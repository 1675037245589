import React from 'react';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';
import { offerTypes } from '../Constants/Constants';

export default function Filters(props) {
    const { setAppliedFilter, fetchRestaurantOfferList } = props;

    const menuItemStatus = [
        { label: 'All', value: null },
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
    ];

    const handleSelection = (selection, key) => {
        setAppliedFilter((prevState) => {
            return { ...prevState, [key]: selection.value };
        });

        fetchRestaurantOfferList({ [key]: selection.value, page: 1 });
    };

    return (
        <section className='flex md:block items-center justify-end mb-6 md:mb-4'>
            <div className='flex sm:block'>
                <div className='w-[200px] md:w-1/2 sm:w-full ml-2 sm:ml-0 md:ml-1'>
                    <DropdownWithValueLabel
                        menuItems={menuItemStatus}
                        label='Mode'
                        setSelected={(selection) => handleSelection(selection, 'is_offer_active')}
                        placeholder='Select offer mode'
                    />
                </div>

                <div className='w-[200px] md:w-1/2 sm:w-full ml-2 sm:ml-0 md:ml-1'>
                    <DropdownWithValueLabel
                        menuItems={offerTypes}
                        label='Offer Type'
                        placeholder='Select offer type'
                        setSelected={(selection) => handleSelection(selection, 'offer_type')}
                    />
                </div>
            </div>
        </section>
    );
}
