import React, { useRef, useState } from 'react';
import './styles.css';
import OrderHeader from '../../../../Components/KOT/Components/OrderHeader';
import OrderDishes, { ItemsComponent } from '../../../../Components/KOT/Components/OrderDishes';
import TotalBill from '../../../../Components/KOT/Components/TotalBill';
import OrderActions from '../../../../Components/KOT/Components/OrderActions';
import { useWindowSize } from '@uidotdev/usehooks';

export default function ListViewOfKOT(props) {
    const {
        icon,
        orderingMode,
        label,
        tableServed,
        dishPacked,
        dishServed,
        handleClickCancelOrder,
        isUpdated,
        items,
        setShowPopup,
    } = props;

    const [showWithItems, setShowWithItems] = useState(false);

    const kotRef = useRef();

    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='w-full pt-3 border border-neutral-300 rounded-xl'>
                <div
                    className='px-4 overflow-auto scrollbar-style -mt-1 pb-3'
                    style={{ height: screenHeight - 200 }}
                    ref={kotRef}>
                    <OrderHeader
                        orderingMode={orderingMode}
                        icon={icon}
                        label={label}
                        setShowPopup={setShowPopup}
                        listView
                    />
                    <OrderDishes
                        dishPacked={dishPacked}
                        dishServed={dishServed}
                        tableServed={tableServed}
                        isUpdated={isUpdated}
                        listView
                    />
                    <ItemsComponent
                        items={items}
                        handleClickWithDropDown={() => setShowWithItems((prevState) => !prevState)}
                        showWithItems={showWithItems}
                    />

                    <div className='mb-4 pb-4 border-b border-neutral-300'>
                        <TotalBill />
                    </div>

                    <OrderActions
                        orderingMode={orderingMode}
                        handleClickCancelOrder={handleClickCancelOrder}
                        setShowPopup={setShowPopup}
                    />
                </div>
            </div>
        </>
    );
}
