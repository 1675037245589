import React from 'react';
import { Categories } from './Card';
import { getFoodType } from '../functions';
import { SortableSubItem } from './SortableItem';

export default function SubCategoryCard(props) {
    const {
        subCategory,
        pageState,
        selectedCategoryDish,
        setSelectedCategoryDish,
        handleClickCategory,
        selectedCategoryId,
        setSelectedCategoryId,
        setClickedCategory,
        goToEditCategory,
        category,
    } = props;

    const isCategorySelected = (category) => {
        if (selectedCategoryId.includes(category.parent_id)) {
            return true;
        }

        if (selectedCategoryId.includes(category.category_id)) {
            return true;
        }

        return false;
    };

    const handleCategorySelection = (isChecked, category) => {
        let selectedCategoryState = isChecked
            ? [...selectedCategoryId, category.category_id]
            : selectedCategoryId.filter((el) => el !== category.category_id);

        if (isChecked && subCategory.every((el) => selectedCategoryState.includes(el.category_id))) {
            selectedCategoryState = [...selectedCategoryState, category.parent_id];
        } else if (!isChecked) {
            selectedCategoryState = selectedCategoryState.filter((el) => el !== category.parent_id);
        }

        setSelectedCategoryId(selectedCategoryState);
    };

    const handleClickSubcategory = (subCategory) => {
        setSelectedCategoryDish?.((prevState) => ({
            ...prevState,
            subCategoryId: subCategory.category_id,
        }));
        handleClickCategory?.(subCategory);
        setClickedCategory({ id: subCategory.category_id, type: 'subcategory' });
    };

    return (
        <>
            {category.sub_categories.map((subCategory) => (
                <SortableSubItem
                    key={subCategory.category_id}
                    id={subCategory.category_id}
                    parentCategory={category}>
                    <SubCategoryCardComponent
                        pageState={pageState}
                        subCategory={subCategory}
                        selectedCategoryDish={selectedCategoryDish}
                        handleClickSubcategory={() => handleClickSubcategory(subCategory)}
                        goToEditCategory={goToEditCategory}
                        handleCategorySelection={handleCategorySelection}
                        isCategorySelected={isCategorySelected}
                    />
                </SortableSubItem>
            ))}
        </>
    );
}

export const SubCategoryCardComponent = (props) => {
    const {
        pageState,
        subCategory,
        selectedCategoryDish,
        handleClickSubcategory,
        goToEditCategory,
        handleCategorySelection,
        isCategorySelected,
    } = props;

    return (
        <div
            className={`mt-4 ml-4 -mr-4 w-full max-w-[303px] md:max-w-full md:mt-2 relative ${
                (pageState?.edit || pageState?.reArrange) && 'md:pl-2.5'
            }`}>
            <Categories
                img={subCategory.image_url}
                title={subCategory.internal_name}
                totalItems={subCategory.total_items}
                type={getFoodType(subCategory)}
                subCategoryDetails={subCategory.subCategoryDetails}
                isActive={subCategory.category_id === selectedCategoryDish?.subCategoryId}
                handleClickCategory={() => handleClickSubcategory()}
                isEdit={pageState?.edit}
                handleClickEdit={() => goToEditCategory(subCategory)}
                isRearrange={pageState?.reArrange}
                isFromSelection={pageState.selection}
                onChange={(isChecked) => handleCategorySelection(isChecked, subCategory)}
                isChecked={isCategorySelected(subCategory)}
            />
        </div>
    );
};
