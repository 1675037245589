import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function CashCollection() {
    const outletList = [
        { outletId: 33, displayName: 'Bodakdev Storess', cashCollection: '1234' },
        { outletId: 34, displayName: 'CG Road Store', cashCollection: '5432' },
        { outletId: 35, displayName: 'Burj Khalifa Outlet', cashCollection: '52' },
        { outletId: 36, displayName: 'Marol Outlet', cashCollection: '424' },
        { outletId: 40, displayName: 'Race Course Store', cashCollection: '135' },
        { outletId: 185, displayName: 'raiya circle', cashCollection: '6235' },
    ];

    const labels = outletList.map((outlet) => outlet.displayName);
    const series = outletList.map((outlet) => parseFloat(outlet.cashCollection));

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: labels,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9', '#FF6361', '#58508D', '#BC5090', '#FFA600'],
        stroke: {
            colors: 'transparent',
        },
    };

    return (
        <div className='flex flex-row md:flex-col justify-between items-center'>
            <div className='w-full md:w-1/2'>
                <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                    <ReactApexChart
                        className='m-auto'
                        type='donut'
                        width={280}
                        height={280}
                        series={series}
                        options={chartOptions}
                    />
                </div>

                <div className='flex flex-wrap justify-center w-full max-w-[251px] md:max-w-full'>
                    {labels.map((label, index) => (
                        <div
                            className='inline-block'
                            key={index}>
                            <div className='flex flex-row items-center m-2'>
                                <div
                                    className='h-[11px] w-[11px] rounded-full mr-2'
                                    style={{ backgroundColor: chartOptions.colors[index % chartOptions.colors.length] }}
                                />
                                <span className='paragraph-medium-regular'>{label}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                <div className='border-b border-neutral-300 mb-4'>
                    {outletList.map((outlet, index) => {
                        return (
                            <div
                                key={index}
                                className='flex flex-row justify-between mb-4'>
                                <span className='paragraph-medium-regular'>{outlet.displayName}</span>
                                <span className='paragraph-medium-regular'>{outlet.cashCollection}</span>
                            </div>
                        );
                    })}
                </div>
                <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                    <span className='paragraph-medium-semi-bold'>Total taxes</span>
                    <span className='paragraph-medium-semi-bold'>
                        {outletList
                            .reduce((acc, outlet) => acc + parseFloat(outlet.cashCollection), 0)
                            .toLocaleString('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                            })}
                    </span>
                </div>
            </div>
        </div>
    );
}
