import React from 'react';
import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import { ReactComponent as AddIcon } from '../../Assets/add.svg';
import DishDetailsList from './Components/Catalogue/Components/DishDetailsList';
import { IsMobileScreen } from '../../Constants/Constants';

export default function MenuManagementDishDetails() {
    const dishDetailsList = [
        'Basic details',
        'Expose dish',
        'Dish pricing',
        'Badges',
        'Link add-on groups',
        'Dish timing',
        'Up-sell dishes',
        'Up-sell current dish in cart',
    ];
    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'>
                    {!isMobileScreen ? (
                        <div className=''>
                            <div className='flex flex-row items-center justify-between w-full mb-4'>
                                <span className='paragraph-large-medium'>View details</span>
                                <span className='paragraph-large-medium'>Garlic breadsticks details</span>
                            </div>
                        </div>
                    ) : (
                        <div className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'>
                            <LeftArrow className='rotate-90' />
                            <span className='pl-1'>Back to outlet dishes</span>
                        </div>
                    )}

                    {dishDetailsList.map((el, index) => (
                        <div
                            className='mt-4'
                            key={index}>
                            <DishDetailsList
                                title={el}
                                dishTitleEnglish='Paneet makhni'
                                interName='Paneer Makhani 122-212'
                                dishTitleGujarati=' પનીર મખાણી'
                                descriptionEnglish='Flavorful trio of juicy paneer, crisp capsicum with spicy red paprika'
                                descriptionGujarati='રસદાર પનીર, મસાલેદાર લાલ પૅપ્રિકા સાથે ક્રિસ્પ કેપ્સિકમની સ્વાદિષ્ટ ત્રિપુટી'
                            />
                        </div>
                    ))}

                    <div className='mt-4'>
                        <div className='mb-2'>
                            <DishDetailsList
                                title='Category details'
                                interName='Paneer Makhani 122-212'
                                dishTitleGujarati=' પનીર મખાણી'
                                descriptionEnglish='Flavorful trio of juicy paneer, crisp capsicum with spicy red paprika'
                                descriptionGujarati='રસદાર પનીર, મસાલેદાર લાલ પૅપ્રિકા સાથે ક્રિસ્પ કેપ્સિકમની સ્વાદિષ્ટ ત્રિપુટી'
                                dishSizeEnglish='Regular'
                                dishSizeGujarati='નિયમિત'
                                dishServesEnglish='1 person'
                                dishServeGujarati='૧ વ્યક્તિ'
                                tilePOsition='Top left'
                                theme='Full-size image'
                            />
                        </div>
                        <EditItem
                            label='Add sub-category'
                            marginR='mr-4'
                        />
                        <EditItem label='Map item' />
                        <EditItem
                            label='Copy existing item'
                            marginR='mr-4'
                        />
                        <EditItem label='Move existing item' />
                    </div>
                </div>
            </div>
        </>
    );
}

const EditItem = (props) => {
    const { label, marginR } = props;
    return (
        <>
            <div className={`flex flex-row items-center cursor-pointer mb-2 ${marginR}`}>
                <AddIcon
                    stroke='#6C5DD3'
                    height={24}
                />
                <span className='paragraph-medium-medium text-primary-500 ml-0.5'>{label}</span>
            </div>
        </>
    );
};
