import React, { useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import ConfirmDiscardMobileButtons from './Components/ConfirmDiscardMobileButtons';
import OrdersButtonDesktop from './Components/OrdersButtonDesktop';
import AmountCard from './Components/AmountCard';
import OrderDetails from './Components/OrderDetails';
import ConfirmDeletePopup from '../../../Components/ConfirmDeletePopup/ConfirmDeletePopup';
import KotList from '../Components/KotListSection/KotList';
import OfferAndHoldSection from '../Components/OfferAndHoldSection';
import DishQuantitySection from '../Components/DishQuantitySection/DishQuantitySection';
import { useWindowSize } from '@uidotdev/usehooks';

export default function OrderSummery(props) {
    const { setShowOrdersView, setShowPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);

    const initialCustomerDetail = [
        {
            id: 1,
            isAddNotes: true,
            title: 'Double Cheese Margherita 1',
            price: 1118,
            note: 'Keep the pizza with ample amount of the veggies.',
            quantity: 2,
        },
        {
            id: 2,
            title: 'Farmhouse Extraveganza Veggie',
            price: 529,
            note: 'Keep the pizza loaded with ample amount of the veggies and herbs.',
            quantity: 1,
        },
        {
            id: 3,
            isAddNotes: true,
            title: 'Indi Tandoori Paner',
            price: 649,
            note: 'Keep the pizza loaded with ample amount of the herbs.',
            quantity: 3,
            extras: [
                { item: 'Extra cheese', prices: 1118 },
                { item: 'Extra veggies', prices: 1150 },
            ],
        },
        {
            id: 4,
            title: 'Indi Tandoori Cheese',
            price: 430,
            note: 'Keep the pizza with ample amount of the veggies.',
            quantity: 1,
            extras: [
                { item: 'Extra cheese', prices: 1118 },
                { item: 'Extra1 veggies', prices: 1150 },
                { item: 'Extra 2veggies', prices: 1150 },
                { item: 'Extra v3eggies', prices: 1150 },
                { item: 'Extra ve4ggies', prices: 1150 },
            ],
        },
        {
            id: 5,
            isAddNotes: true,
            title: 'Deluxe Veggie',
            price: 391,
            note: 'Keep the pizza loaded with ample amount of the veggies and herbs.',
            quantity: 2,
            extras: [{ item: 'Extra veggies', prices: 1150 }],
        },
        {
            id: 6,
            title: 'Mexican Green Wave',
            price: 769,
            note: 'Keep the pizza with ample amount of the herbs.',
            quantity: 1,
            extras: [
                { item: 'Extra cheese', prices: 1118 },
                { item: 'Extra veggies', prices: 1150 },
            ],
        },
        {
            id: 7,
            title: 'Cheese n Corn',
            price: 377,
            note: 'Keep the pizza loaded with ample amount of the herbs.',
            quantity: 10,
            extras: [{ item: 'Extra cheese', prices: 1118 }],
        },
    ];

    const { height: screenHeight } = useWindowSize();

    const [customerDetail, setCustomerDetail] = useState(initialCustomerDetail);

    const [showInputDish, setShowInputDish] = useState(!true);

    const confirmRemoveItem = () => {
        setCustomerDetail((prevDetails) => prevDetails.filter((_, index) => index !== itemToRemove));
        setItemToRemove(null);
        setShowConfirmation(false);
    };

    const cancelRemoveItem = () => {
        setItemToRemove(null);
        setShowConfirmation(false);
    };

    return (
        <>
            <div className='max-w-[443px] pageContent:min-w-[400px] largeTablet:min-w-[348px] w-full border-l border-neutral-300 relative md:pb-24 md:max-w-full h-full flex flex-col md:min-w-full md:border-l-0'>
                <OrderDetails setShowPopup={setShowPopup} />

                <div
                    className='bg-white pl-3 pr-5 md:px-4 overflow-auto scrollbar-style'
                    style={{ height: screenHeight - 400 }}>
                    <DishQuantitySection showInputDish={showInputDish} />

                    <KotList
                        showInputDish={showInputDish}
                        customerDetail={customerDetail}
                        setCustomerDetail={setCustomerDetail}
                        setItemToRemove={setItemToRemove}
                        setShowConfirmation={setShowConfirmation}
                        setShowPopup={setShowPopup}
                    />
                </div>

                <div
                    className='bg-white sticky md:relative bottom-0 pl-3 pr-5 md:px-4 md:pb-1 mt-auto'
                    style={{
                        boxShadow: '0px -4px 15px -3px rgba(16, 24, 40, 0.1), 0px -4px 6px -4px rgba(16, 24, 40, 0.1)',
                    }}>
                    <OfferAndHoldSection setShowPopup={setShowPopup} />

                    <AmountCard />

                    <OrdersButtonDesktop setShowPopup={setShowPopup} />
                </div>

                {isMobileScreen && (
                    <ConfirmDiscardMobileButtons
                        setShowOrdersView={setShowOrdersView}
                        setShowPopup={setShowPopup}
                    />
                )}
            </div>

            {showConfirmation && (
                <ConfirmDeletePopup
                    header={<h2 className='paragraph-large-medium ml-2'>Remove dish</h2>}
                    description={
                        <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                            Are you sure to remove this item?
                        </p>
                    }
                    handleDelete={confirmRemoveItem}
                    handleClickClose={cancelRemoveItem}
                />
            )}
        </>
    );
}
