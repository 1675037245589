import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IsMobileScreen } from '../../../../../Constants/Constants';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import ListViewPaymentMethod from './Components/ListViewPaymentMethod';
import Loader from './Components/Loader';
import Layout from '../Components/Layout';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';

export default function EnableCash(props) {
    const { handleDiscardForm, fetchSetting, setShowTabChangeWarningPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const { control, watch, handleSubmit, setValue, reset } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const orderingModeList = useSelector((state) => state.outlet.orderingModes);

    const [showFooter, setShowFooter] = useState(false);

    const updateEnableCash = async (data) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=cash_enablement`, { ...data });

            if (response.success) {
                setShowFooter(false);
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = (data) => {
        const formattedData = {
            payment_methods: data.cash_enablement.map((el) => ({
                id: el.id,
                is_enabled: el.is_enabled,
            })),
        };

        updateEnableCash(formattedData);
        setShowTabChangeWarningPopup(false);
    };

    const getOrderingModeId = (id) => orderingModeList.find((el) => el.id === id)?.display_name;

    const availableOrderingMode = watch('ordering_modes')?.filter((el) => el.is_enabled);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowFooter(false);
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <TitleDescription
                className='mb-4'
                title='Enable cash'
                description='Select ordering modes for in which cash payment will be accepted.'
            />

            {!isMobileScreen && (
                <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border max-w-[310px] mb-6'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[184px] paragraph-overline-small text-neutral-700'>
                                    MODE NAME
                                </th>
                                <th className='px-6 min-w-[126px] paragraph-overline-small text-neutral-700'>STATUS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {watch('cash_enablement')?.map((el, index) => {
                                const isShowMode = availableOrderingMode.some(
                                    (modeEl) => modeEl.restaurant_ordering_mode_id === el.restaurant_ordering_mode_id
                                );

                                if (!isShowMode) return null;

                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                        key={index}>
                                        <td className='px-6'>{getOrderingModeId(el.restaurant_ordering_mode_id)}</td>
                                        <td className='px-6'>
                                            <Controller
                                                render={({ field }) => (
                                                    <ToggleSwitch
                                                        isEnable={watch(`cash_enablement.${index}.is_enabled`) ?? false}
                                                        canChange
                                                        setIsEnabled={(value) => {
                                                            field.onChange(value);
                                                            setShowFooter(true);
                                                            setShowTabChangeWarningPopup(true);
                                                        }}
                                                    />
                                                )}
                                                name={`cash_enablement.${index}.is_enabled`}
                                                control={control}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            {isMobileScreen &&
                watch('cash_enablement')?.map((el, index) => (
                    <div
                        className='mt-2'
                        key={index}>
                        <ListViewPaymentMethod
                            modeName={el.ordering_mode}
                            watch={watch}
                            index={index}
                            control={control}
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            setShowFooter={setShowFooter}
                        />
                    </div>
                ))}
        </Layout>
    );
}
