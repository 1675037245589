import React, { useState } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../../Components/Buttons/Button';
import CategorySection from './Components/CategorySection';
import Layout from './Components/Layout';
import DishSection from './Components/DishSection';
import { useSelector } from 'react-redux';
import { filterCategorySubCategoryDishId, mapMoveCopyOperationSave } from '../../functions';

export default function DishMapCopyMovePopup(props) {
    const { handleClickClose, dishOperationState, setIsOperationLoading } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [selectedCategoryDish, setSelectedCategoryDish] = useState({ category: '', dish: '' });

    const [selectedDish, setSelectedDish] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState([]);

    const [allCategoryDish, setAllCategoryDish] = useState([]);

    const filteredId = filterCategorySubCategoryDishId(allCategoryDish);

    const handleClickSave = async (params) => {
        const response = await mapMoveCopyOperationSave(
            selectedRestaurantId,
            dishOperationState,
            props.selectedCategoryDish,
            selectedDish,
            filteredId
        );

        if (response.success) {
            handleClickClose();
            setIsOperationLoading(true);
        }
    };

    return (
        <Layout
            handleClickClose={handleClickClose}
            dishOperationState={dishOperationState}>
            <div className='flex flex-row items-start mb-12 pageContent:mb-3 md:flex-col-reverse'>
                <div className=''>
                    <CategorySection
                        selectedCategoryDish={selectedCategoryDish}
                        setSelectedCategoryDish={setSelectedCategoryDish}
                        selectedDish={selectedDish}
                        setSelectedDish={setSelectedDish}
                        selectedCategory={selectedCategory}
                        setAllCategoryDish={setAllCategoryDish}
                        allCategoryDish={allCategoryDish}
                        setSelectedCategory={setSelectedCategory}
                    />
                </div>

                <div className=''>
                    <DishSection
                        setSelectedDish={setSelectedDish}
                        selectedDish={selectedDish}
                        setSelectedCategoryDish={setSelectedCategoryDish}
                        selectedCategoryDish={selectedCategoryDish}
                        selectedCategory={selectedCategory}
                        setAllCategoryDish={setAllCategoryDish}
                        allCategoryDish={allCategoryDish}
                        setSelectedCategory={setSelectedCategory}
                    />
                </div>
            </div>

            <div className='flex justify-end mt-12 pageContent:mt-3 md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4 md:px-4'>
                <div className='flex flex-row'>
                    <div className='mr-2 md:w-full'>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='ml-2 md:w-full'
                        onClick={() => {
                            handleClickSave();
                        }}>
                        <LargePrimaryButton label='Save' />
                    </div>
                </div>
            </div>
        </Layout>
    );
}
