import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function Layout(props) {
    const { children, defaultParams } = props;

    const methods = useForm();

    const pageHeight = useWindowSize().height;

    const isMobileScreen = IsMobileScreen();

    useEffect(() => {
        if (!_.isEmpty(defaultParams)) {
            Object.keys(defaultParams).forEach((key) => {
                methods.setValue(key, defaultParams[key]);
            });
        }

        //eslint-disable-next-line
    }, [defaultParams]);

    return (
        <div className='bg-[#fafafa]'>
            <div
                className='pt-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full md:pb-4 scrollbar-hide'
                style={{ height: isMobileScreen ? 'auto' : pageHeight - 50 }}>
                <Header />

                {children}

                <Footer />
            </div>
        </div>
    );
}
