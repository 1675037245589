import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';

export default function HideOnExhastSection() {
    const { control } = useFormContext();

    return (
        <>
            <div className='flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full'>
                <TitleDescription
                    title='Hide offer'
                    description='Enable this will hide this offer when exhausted.'
                />
                <Controller
                    render={({ field }) => (
                        <ToggleSwitch
                            isEnable={field.value ?? false}
                            canChange
                            setIsEnabled={field.onChange}
                        />
                    )}
                    name='hide_on_exhaustion'
                    control={control}
                />
            </div>
        </>
    );
}
