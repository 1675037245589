import React from 'react';
import { ReactComponent as DineInIcons } from '../../../Assets/dine-in.svg';

const OrderHeader = (props) => {
    const { orderingMode, icon, label, listView, setShowPopup } = props;

    return (
        <div className='flex flex-row justify-between items-center mb-4 pb-4 border-b border-neutral-300'>
            <div className='flex flex-row items-center'>
                {orderingMode === 'Dine-In order' ? (
                    <div className='p-2 bg-primary-500 rounded-lg'>
                        <DineInIcons
                            height={32}
                            width={32}
                            stroke='#FAFAFA'
                        />
                    </div>
                ) : (
                    icon
                )}
                {listView ? (
                    <div className='ml-1.5 flex flex-col items-start'>
                        <span className='paragraph-medium-italic text-neutral-500'>Order number</span>
                        <span className='paragraph-large-medium'>Order #BBQR</span>
                    </div>
                ) : (
                    <div className='ml-2 flex flex-col items-start'>
                        <span className='paragraph-medium-semi-bold'>{orderingMode}</span>
                        <span className='paragraph-medium-italic text-neutral-500'>Order {label}</span>
                    </div>
                )}
            </div>

            {listView ? (
                <div className='flex flex-col items-end'>
                    <div className='paragraph-medium-italic text-neutral-500'>Order details</div>
                    <span
                        onClick={() => setShowPopup('customerDetails')}
                        className='paragraph-large-underline text-primary-500 cursor-pointer'>
                        View details
                    </span>
                </div>
            ) : (
                <div className='flex flex-col items-end'>
                    <div className='paragraph-medium-regular'>09 Aug 2022, 11:27 AM</div>
                    <div className='paragraph-medium-italic text-neutral-500'>
                        {orderingMode === 'Dine-In order' ? 'KOT #1243' : 'Swiggy'}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderHeader;
