import APIV2 from '../../../api/axios/APIV2';

export const fetchOutletwiseOffer = async (params, selectedRestaurantId, selectedOutletId) => {
    try {
        const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/offers`, {
            params,
        });

        return response;
    } catch (error) {
        return error;
    }
};

export const fetchRestaurantOffer = async (params, selectedRestaurantId) => {
    try {
        const response = await APIV2.get(`/restaurants/${selectedRestaurantId}/offers`, {
            params,
        });

        return response.offers;
    } catch (error) {
        return error;
    }
};
