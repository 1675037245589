import React, { useEffect, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import { ReactComponent as CalenderIcon } from '../../../Assets/calendar.svg';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';

export default function ListViewNotificationList(props) {
    const {
        notificationTitle,
        description,
        screen,
        date,
        time,
        handleNotificationDetailPopup,
        handleCheckboxChange,
        checkedItems,
        notificationId,
        showCheckbox,
        fetchDetailsOfNotification,
        cache,
        onResize,
        setEditNotification,
        editNotification,
    } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    useEffect(() => {
        cache.clearAll();
        onResize();
    }, [isShowDetails, onResize]);

    return (
        <>
            <div className='w-full px-4 py-3 border border-neutral-300 rounded-md md:mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div className='flex flex-row items-center'>
                        {showCheckbox && (
                            <div className='mr-4 cursor-pointer'>
                                <CheckBoxWithoutLabels
                                    onChange={(isChecked) => handleCheckboxChange(notificationId, isChecked)}
                                    isChecked={checkedItems?.includes(notificationId)}
                                />
                            </div>
                        )}
                        <div>
                            <h3 className='paragraph-overline-small text-neutral-700 uppercase'>NOTIFICATION TITLE:</h3>
                            <span className='paragraph-small-regular'>{notificationTitle}</span>
                        </div>
                    </div>

                    <div className={`${isShowDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-2'>
                        <div className='flex flex-row items-center'>
                            <span className='paragraph-overline-small text-neutral-700 uppercase'>DESCRIPTION:</span>
                            <span className='paragraph-small-regular ml-1'>{description}</span>
                        </div>
                        <div className='pt-2 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700 uppercase'>SCREEN:</span>
                            <span className='paragraph-small-regular ml-1'>{screen}</span>
                        </div>
                        <div className='pt-1.5 flex items-center'>
                            <span className='paragraph-overline-small text-neutral-700 uppercase'>DATE:</span>
                            <div className='flex flex-row items-center ml-1'>
                                <CalenderIcon
                                    height={21}
                                    width={21}
                                />
                                <span className='paragraph-small-regular ml-1'>{date}</span>
                            </div>
                        </div>

                        <div className='pt-2 flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700 uppercase'>TIME:</span>
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <TimerIcon
                                        height={21}
                                        width={21}
                                    />
                                    <span className='ml-1'>{time}</span>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer'
                                onClick={() => {
                                    fetchDetailsOfNotification(notificationId);
                                    setEditNotification(!editNotification);
                                }}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
