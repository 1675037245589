import React from 'react';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function CashTab(props) {
    const { handleInputAmount } = props;

    return (
        <>
            <div className='md:mb-0'>
                <InputSection
                    maxWidth='max-w-[375px]'
                    name='cashAmount'
                    placeholder='Enter cash given by customer in rupees'
                    label='Cash amount'
                    shadow='shadow-xSmall'
                    inputType='number'
                    handleInputChange={(amount) => handleInputAmount(amount, 'cash')}
                />

                <div className='md:px-4 md:pb-6 w-full pt-[180px] md:fixed md:bottom-0 md:-ml-4 md:bg-white md:shadow-inner md:pt-4'>
                    <LargePrimaryButton label='Add cash' />
                </div>
            </div>
        </>
    );
}
