import React from 'react';
import TabHeader from './TabHeader';
import { useWindowSize } from '@uidotdev/usehooks';
import { IsMobileScreen } from '../../../Constants/Constants';
import Header from './Header';

export default function Layout(props) {
    const { children, showCustomerReview } = props;

    const { height: screenHeight } = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!showCustomerReview || !isMobileScreen) && (
                    <div
                        className='px-8 pb-[30px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: screenHeight - 50 }}>
                        <TabHeader />

                        {children}
                    </div>
                )}
            </div>
        </>
    );
}
