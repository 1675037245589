import React, { useCallback, useEffect, useState } from 'react';
import { ChromePicker, HuePicker } from 'react-color';
import useEyeDropper from 'use-eye-dropper';
import { ReactComponent as ColorDropperIcon } from '../../Assets/colorDropper.svg';
import { ColorLabel, ColorComparisonBox } from './ColorPickerComponent';

export default function PickColor(props) {
    const { selectedColor, hideTitle, setSelectedColorFromPopup } = props;

    const [color, setColor] = useState(selectedColor ?? '#000000');

    useEffect(() => {
        selectedColor && setColor(selectedColor);
    }, [selectedColor]);

    const [currentColor, setCurrentColor] = useState('#FFFFFF');

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
        setCurrentColor(color);
    };

    const { open } = useEyeDropper();

    const pickColor = useCallback(() => {
        const openPicker = async () => {
            try {
                const color = await open();
                setColor(color.sRGBHex);
            } catch (e) {
                console.log(e);
            }
        };
        openPicker();
    }, [open]);

    const handleChangeCurrentColor = (color) => {
        setSelectedColorFromPopup?.(color.hex);
    };

    return (
        <>
            {!hideTitle && <div className='paragraph-large-medium mb-2'>Pick color</div>}

            <div className='flex flex-row align-top items-start justify-between md:block'>
                <div className='flex flex-row md:mb-6'>
                    <div className='mr-2 md:mr-9 mobile:mr-3'>
                        <ChromePicker
                            color={color}
                            onChange={handleColorChange}
                            disableAlpha={true}
                            onChangeComplete={handleChangeCurrentColor}
                        />
                    </div>

                    <div className='max-h-[240px] max-w-[25px] border-neutral-300 ml-2 md:ml-4 mobile:ml-3'>
                        <HuePicker
                            direction='vertical'
                            color={color}
                            onChange={handleColorChange}
                            disableAlpha={true}
                        />
                    </div>
                </div>

                <div className='flex flex-col justify-between h-[240px] items-end mb-4 md:flex-row md:justify-normal md:h-fit md:items-center'>
                    <ColorComparisonBox
                        color={color}
                        currentColor={currentColor}
                    />

                    <button
                        className='h-12 w-12 border border-neutral-300 rounded md:ml-[55px] mobile:ml-[27px]'
                        onClick={pickColor}>
                        <div className='justify-center items-center flex h-full'>
                            <ColorDropperIcon />
                        </div>
                    </button>
                </div>
            </div>

            <ColorLabel
                color={color}
                currentColor={currentColor}
                setColor={setColor}
                setSelectedColorFromPopup={setSelectedColorFromPopup}
            />
        </>
    );
}
