import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import InputSection from '../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';
import _ from 'lodash';
import { fornatOrderName } from '../../../Constants/function';

export default function CodLimit(props) {
    const { handleDiscardForm, fetchSetting, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        formState: { isDirty, errors },
        watch,
        reset,
        setValue,
    } = useFormContext();

    const formatData = (data) => {
        return {
            cash_limit: {
                ...data,
            },
        };
    };

    const onSubmit = (data) => {
        updateCodLimit(formatData(data.cash_limit));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateCodLimit = async (params) => {
        setValue('isFormLoading', true, { shouldDirty: false });

        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=cash_limit`, { ...params });
            if (response.success) {
                reset();
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
        setShowFooter(false);
    };

    const [showFooter, setShowFooter] = useState(false);

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setShowFooter(false);
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <div className='md:pb-20'>
                {!_.isEmpty(watch('cash_limit')) &&
                    Object.keys(watch('cash_limit')).map((key, index) => {
                        return (
                            <div
                                className='mb-6'
                                key={index}>
                                <InputSection
                                    title={`Cash limit on ${fornatOrderName(key)} orders`}
                                    description={`Set limit for cash payment on ${fornatOrderName(key)} orders.`}
                                    placeholder='Enter amount in rupees'
                                    shadow='shadow-smallDropDownShadow'
                                    name={`cash_limit.${key}`}
                                    inputType='number'
                                    handleInputChange={() => {
                                        setShowFooter(true);
                                        setShowTabChangeWarningPopup(true);
                                    }}
                                />
                            </div>
                        );
                    })}
            </div>
        </Layout>
    );
}
