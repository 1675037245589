import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { formatDate, getPaymentMethodIcon } from '../functions';

export default function ListViewBillPayment(props) {
    const { verification_code, customer_name, amount, created_at, payment_method, handleClickViewDetails } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    return (
        <>
            <div className='w-full px-4 py-3 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={() => setIsShowDetails((prevState) => !prevState)}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-0.5'>CODE:</h3>
                        <span className='paragraph-small-regular'>{verification_code}</span>
                    </div>

                    <div className={`${isShowDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>

                {isShowDetails && (
                    <>
                        <div className='mt-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>CUSTOMER NAME:</span>
                            <span className='paragraph-small-regular ml-1'>{customer_name}</span>
                        </div>

                        <div className='mt-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER AMOUNT:</span>
                            <span className='paragraph-small-regular ml-1'>₹ {amount} /-</span>
                        </div>

                        <div className='mt-1.5'>
                            <span className='paragraph-overline-small text-neutral-700'>ORDER DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{formatDate(created_at, 'date')}</span>
                        </div>

                        <div className='flex flex-row justify-between mobile:block items-center mt-2'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700'>PAYMENT MODE:</span>

                                <div className='flex flex-row items-center ml-1'>
                                    {getPaymentMethodIcon(payment_method)}
                                </div>

                                <span className='paragraph-small-regular ml-1'>
                                    {payment_method === 'CREDIT_CARD/DEBIT_CARD' ? 'Card' : payment_method}
                                </span>
                            </div>

                            <div className='mobile:mt-1 flex flex-row justify-between items-center mobile:block'>
                                <span
                                    className='paragraph-small-italic text-primary-500 cursor-pointer'
                                    onClick={handleClickViewDetails}>
                                    View full details
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
