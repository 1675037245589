import React, { useState } from 'react';

export default function TotalBill() {
    const [showBreakUp, setShowBreakUp] = useState(false);

    return (
        <>
            <div className='relative w-full mb-4 rounded-md'>
                <div
                    className={`absolute bottom-10 bg-white w-full border-t border-x rounded-t-md pt-4 px-4 border-neutral-300 transition-all duration-300 ${
                        showBreakUp ? 'opacity-100' : 'opacity-0'
                    }`}>
                    <div className='bg-white'>
                        <div className='flex flex-row justify-between mb-4'>
                            <span className='paragraph-medium-regular text-neutral-500'>Tax</span>
                            <span className='paragraph-medium-regular text-neutral-500'>₹200.00/-</span>
                        </div>

                        <div className='flex flex-row justify-between mb-4'>
                            <span className='paragraph-medium-regular text-neutral-500'>Subtotal</span>
                            <span className='paragraph-medium-regular text-neutral-500'>₹2,125.00/-</span>
                        </div>
                    </div>
                </div>

                <div
                    className='flex flex-row justify-between items-center cursor-default px-4 pb-4 border-neutral-300 pt-4 border rounded-lg'
                    onMouseEnter={() => setShowBreakUp(true)}
                    onMouseLeave={() => setShowBreakUp(false)}>
                    <div className='flex items-center'>
                        <span className='paragraph-medium-semi-bold pr-2 pageContent:text-[15px]'>Total </span>
                        <span className='paragraph-x-small-regular text-neutral-500 pt-1 underline decoration-dashed'>
                            Break up
                        </span>
                    </div>
                    <span className='paragraph-medium-semi-bold pageContent:text-[15px]'>₹2,325.00/-</span>
                </div>
            </div>
        </>
    );
}
