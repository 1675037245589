import React, { useRef } from 'react';
import CustomercartdetailsListView from './CustomercartdetailsListView';
import { ReactComponent as DeliveryIcon } from '../../../Assets/riders.svg';
import { ReactComponent as DininIcon } from '../../../Assets/dine-in.svg';
import { ReactComponent as TakeAwayIcon } from '../../../Assets/orders.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';
import { TableBlankComponentView } from '../../../Components/BlankComponent/TableBlankComponentView';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { format } from 'date-fns';

export default function Table(props) {
    const {
        toggleCustomerCartDetailsPopup,
        setPopupOrderId,
        abandonedCartsList,
        isAbandonedCartListLoading,
        paginationChanged,
        paginationState,
        fetchAbandonedCartList,
    } = props;

    const orderTypeIcon = (type) => {
        return type === 'delivery' ? <DeliveryIcon /> : type === 'dine-in' ? <DininIcon /> : <TakeAwayIcon />;
    };

    const cellRenderer = ({ index, key, parent, style }) => {
        const el = abandonedCartsList[index];

        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                {({ measure }) => (
                    <div
                        className='mt-2'
                        key={index}
                        style={{ ...style }}>
                        <CustomercartdetailsListView
                            customerName={el.customer_name ? el.customer_name : '-'}
                            customerType={!!el.is_new_customer ? 'new' : 'old'}
                            orderDate={el.order_date ? el.order_date : '-'}
                            orderAmount={el.order_amount ? el.order_amount : '-'}
                            orderType={el.order_type ? el.order_type : '-'}
                            cartId={el.id}
                            handleClickCustomerCartDetails={toggleCustomerCartDetailsPopup}
                            onResize={measure}
                            cache={cache}
                            index={index}
                            setPopupOrderId={setPopupOrderId}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 150,
        })
    ).current;

    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight === scrollTop && paginationState.lastPage > paginationState.selectedPage) {
            fetchAbandonedCartList({
                page: paginationState.selectedPage + 1,
                isScroll: true,
            });
        }
    };

    const orderType = {
        dine_in: 'Dine-In',
        delivery: 'Delivery',
        takeaway: 'Takeaway',
    };

    return (
        <>
            <div className=''>
                {!IsMobileScreen() ? (
                    <div className='my-4'>
                        <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                            {isAbandonedCartListLoading || paginationChanged ? (
                                <table>
                                    <tbody>
                                        <TableLoader
                                            hasSwitch={false}
                                            columns={5}
                                        />
                                    </tbody>
                                </table>
                            ) : (
                                <table className='w-full break-words'>
                                    <thead>
                                        <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 h-[44px] justify-center'>
                                            <th className='text-left pl-6 min-w-[261px] lg:min-w-[202px] shadow-innerShadow'>
                                                CUSTOMER NAME
                                            </th>
                                            <th className='text-left pl-6 min-w-[253px] lg:min-w-[165px] shadow-innerShadow'>
                                                CUSTOMER TYPE
                                            </th>
                                            <th className='text-left pl-6 min-w-[234px] lg:min-w-[147px] shadow-innerShadow'>
                                                ORDER DATE
                                            </th>
                                            <th className='text-left pl-6 min-w-[262px] lg:min-w-[165px] shadow-innerShadow'>
                                                ORDER AMOUNT
                                            </th>
                                            <th className='text-left pl-6 min-w-[260px] lg:min-w-[165px] shadow-innerShadow'>
                                                ORDER TYPE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {abandonedCartsList && abandonedCartsList.length > 0 ? (
                                            abandonedCartsList.map((el, index) => {
                                                const icon = orderTypeIcon(el.order_type);
                                                return (
                                                    <tr
                                                        key={index}
                                                        className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular justify-center h-[70px]'>
                                                        <td
                                                            onClick={
                                                                el.customer_name
                                                                    ? () => setPopupOrderId(el.id)
                                                                    : () => setPopupOrderId(null)
                                                            }
                                                            className={`px-6 cursor-pointer ${
                                                                el.customer_name
                                                                    ? 'text-primary-500 hover:underline underline-offset-3'
                                                                    : ''
                                                            }`}>
                                                            {el.customer_name ? el.customer_name : '-'}
                                                        </td>

                                                        <td className='pl-6'>
                                                            <span
                                                                className={`border rounded paragraph-small-medium px-2 py-1 md:text-xs ${
                                                                    el.is_new_customer === 1
                                                                        ? 'text-primary-500 border-primary-500 bg-primary-50'
                                                                        : 'text-secondary-800 border-secondary-800 bg-secondary-100'
                                                                }`}>
                                                                {el.is_new_customer === 1 ? 'New' : 'Old'}
                                                            </span>
                                                        </td>
                                                        <td className='pl-6'>{el.order_date ? el.order_date : '-'}</td>
                                                        <td className='pl-6'>
                                                            {el.order_amount ? `₹${el.order_amount}/-` : '-'}
                                                        </td>
                                                        <td className='pl-6'>
                                                            <div className='flex flex-row items-center'>
                                                                <span className='mr-1'>{icon}</span>
                                                                <span>{orderType[el.order_type]}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>
                                                    <TableBlankComponentView previewText={'No Content Available'} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                ) : isAbandonedCartListLoading || paginationChanged ? (
                    <div>
                        {[...Array(10)].map((_, index) => (
                            <ListViewLoader
                                key={index}
                                hasSwitch={false}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='mt-1'>
                        {abandonedCartsList && abandonedCartsList.length > 0 ? (
                            <List
                                width={700}
                                height={600}
                                rowCount={abandonedCartsList.length}
                                rowHeight={cache.rowHeight}
                                rowRenderer={cellRenderer}
                                onScroll={handleScroll}
                                deferredMeasurementCache={cache}
                                autoWidth
                            />
                        ) : (
                            <ListBlankComponentView previewText={'No Content Available'} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
