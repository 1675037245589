import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as LoyaltyCashbackIcon } from '../../../../Assets/loyalty-cashback.svg';
import { format, parseISO } from 'date-fns';

export default function ListView(props) {
    const { customerName, points, createdAt } = props;
    const [isShowDetails, setIsShowDetails] = useState(false);

    const formattedDate = (dateString) => {
        const parsedDate = parseISO(dateString);
        return format(parsedDate, 'dd MMM yyyy');
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md mb-4'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={() => setIsShowDetails((prevState) => !prevState)}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>CUSTOMER NAME:</h3>

                        <span className='paragraph-small-regular'>{customerName ?? 'N/A'}</span>
                    </div>

                    <div className='flex flex-row items-center'>
                        {!isShowDetails && (
                            <div className='flex flex-row items-center'>
                                <LoyaltyCashbackIcon />
                                <span className='paragraph-small-regular ml-1'>{points !== null ? points : null}</span>
                            </div>
                        )}

                        <div className={`ml-[5px] ${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <>
                        <div className='pt-2'>
                            <span className='paragraph-overline-small text-neutral-700'>SIGN-UP DATE:</span>
                            <span className='paragraph-small-regular ml-1'>{createdAt}</span>
                        </div>

                        <div className='pt-2 flex flex-row justify-between items-center mobile:block'>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-overline-small text-neutral-700'>CASHBACK EARNED:</span>
                                <div className='paragraph-small-regular ml-1 flex flex-row items-center'>
                                    <LoyaltyCashbackIcon className='h-5 w-5 mr-1' />
                                    {points}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
