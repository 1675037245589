import React from 'react';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { useWindowSize } from '@uidotdev/usehooks';

export default function GridViewCard(props) {
    const {
        setStatusPopupData,
        state,
        city,
        address,
        ordering_modes,
        legal_name,
        handleRestaurantSelection,
        is_open,
        restaurant_id,
        id,
        image_url,
    } = props;

    const screenWidth = useWindowSize().width;

    const changeNameCase = (name) => {
        const pascalCase = name.replace(/_/g, '-');
        return pascalCase[0].toUpperCase() + pascalCase.slice(1);
    };

    const handleClickOrderingMode = (e) => {
        e.stopPropagation();
    };

    return (
        <>
            <div className='flex flex-col align-top md:w-full cursor-pointer md:inline-block mx-2.5 md:mx-0'>
                <div className='relative flex-grow mt-4 md:mt-2'>
                    <div
                        className='border border-neutral-300 h-full rounded-md py-3 px-4 w-full cursor-pointer md:w-full md:mx-0 md:mt-0'
                        onClick={handleRestaurantSelection}>
                        <div className='flex flex-row items-center mb-4'>
                            {screenWidth > 374 && (
                                <span className='mr-2'>
                                    <img
                                        src={image_url}
                                        alt='logo'
                                        className='h-10 w-10'
                                    />
                                </span>
                            )}
                            <span className='paragraph-small-semi-bold max-w-[193px] lg:max-w-full lg:pr-12 mobile:min-h-[40px] mobile:justify-center md:pr-[60px] mobile:flex mobile:flex-col'>
                                {legal_name}
                            </span>
                        </div>

                        <div className='flex flex-row items-center mb-4'>
                            <div className='flex flex-row items-center w-1/2 md:mr-1.5'>
                                <div className='paragraph-small-semi-bold text-neutral-400'>State:</div>
                                <div className='ml-1 paragraph-small-regular'>{state}</div>
                            </div>

                            <div className='flex flex-row items-center w-1/2'>
                                <div className='paragraph-small-semi-bold text-neutral-400'>City:</div>
                                <div className='ml-1 paragraph-small-regular'>{city}</div>
                            </div>
                        </div>

                        <div className='flex flex-row items-start mb-0.5'>
                            <div className='paragraph-small-semi-bold text-neutral-400'>Address:</div>
                            <p className='paragraph-small-regular ml-1'>{address.replace(/,([A-Za-z])/g, ', $1')}</p>
                        </div>

                        {ordering_modes.map((el, index) => {
                            let display_name = changeNameCase(el.type);

                            let isActive = el.is_available;
                            return (
                                <div
                                    className={`${
                                        display_name === 'Self-serve' && screenWidth < 1080 && screenWidth > 1024
                                            ? 'w-full'
                                            : 'w-1/2'
                                    } inline-block mt-3.5 cursor-default mobile:w-full`}
                                    key={index}
                                    onClick={handleClickOrderingMode}>
                                    <div className='flex flex-row'>
                                        <div className='paragraph-small-semi-bold text-neutral-400'>
                                            {display_name}:
                                        </div>
                                        <div
                                            className={`font-normal text-sm leading-5 ml-1 cursor-default ${
                                                isActive ? 'text-success-800' : 'text-destructive-500'
                                            }`}
                                            onClick={() =>
                                                setStatusPopupData({
                                                    showPopup: true,
                                                    data: {
                                                        restaurant_id,
                                                        outlet_id: id,
                                                        restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                                                        is_orderingmode_active: el.is_available,
                                                    },
                                                    statusOf: 'ordringMode',
                                                })
                                            }>
                                            {isActive ? 'ACTIVE' : 'INACTIVE'}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div
                            className={`${
                                screenWidth < 1080 && screenWidth > 1024 ? 'w-full' : 'w-1/2'
                            } inline-block mt-3.5 cursor-default mobile:w-full`}
                            onClick={handleClickOrderingMode}>
                            <div className='flex flex-row'>
                                <div className='paragraph-small-semi-bold text-neutral-400'>Zomato:</div>
                                <div
                                    className={`font-normal text-sm leading-5 ml-1 cursor-default ${
                                        false ? 'text-success-800' : 'text-destructive-500'
                                    }`}
                                    onClick={() =>
                                        setStatusPopupData({
                                            showPopup: true,
                                            data: {
                                                restaurant_id,
                                                outlet_id: id,
                                                isActive: true,
                                            },
                                            statusOf: 'zomato',
                                        })
                                    }>
                                    {false ? 'ACTIVE' : 'INACTIVE'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className='top-5 right-4 absolute'
                        onClick={() =>
                            setStatusPopupData({
                                showPopup: true,
                                data: {
                                    restaurant_id,
                                    is_outlet_open: is_open,
                                    outlet_id: id,
                                },
                                statusOf: 'outlet',
                            })
                        }>
                        <ToggleSwitch
                            cursorType='cursor-default'
                            isEnable={is_open}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
