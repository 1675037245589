import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { format, isValid, parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalenderIcon } from '../../../../../../Assets/calendar.svg';
import DropdownSection from '../../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function DatePickerSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const initialDate = { startTime: new Date(), endTime: new Date() };

    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        if (watch('bill_payments.validateFrom')) {
            const fromDate = watch('bill_payments.validateFrom');

            const toDate = watch('bill_payments.validateTo');

            if (isValid(parseISO(fromDate)) && isValid(parseISO(toDate))) {
                setSelectedDate({
                    startTime: parseISO(watch('bill_payments.validateFrom')),
                    endTime: parseISO(watch('bill_payments.validateTo')),
                });
            }
        }

        // eslint-disable-next-line
    }, []);

    const today = new Date();

    const handleDateSelection = (fieldOnChange, date, timeSection) => {
        setShowTabChangeWarningPopup(true);

        const formattedDate = format(date, 'yyyy-MM-dd');

        if (timeSection === 'startTime' && selectedDate.endTime < date) {
            setSelectedDate({ startTime: date, endTime: date });

            setValue('bill_payments.validateFrom', formattedDate);

            setValue('bill_payments.validateTo', formattedDate);

            return;
        }

        setSelectedDate((prevState) => {
            return { ...prevState, [timeSection]: date };
        });

        setValue('bill_payments.validateTo', formattedDate);

        fieldOnChange(formattedDate);
    };

    const handleSelectionOfferValidity = (fieldOnChange, selection) => {
        setValue('bill_payments.validateFrom', new Date());
        setValue('bill_payments.validateTo', new Date());
        setSelectedDate(initialDate);
    };

    useEffect(() => {
        watch('bill_payments.hasCouponValidity')?.value === false && handleSelectionOfferValidity();

        //eslint-disable-next-line
    }, [watch('bill_payments.hasCouponValidity')]);

    return (
        <>
            <div className='mb-6 mt-4'>
                <DropdownSection
                    title='Coupon validity'
                    description='Define the duration for the coupon validity.'
                    name='bill_payments.hasCouponValidity'
                    placeholder='Select your preference'
                    menuItems={preferenceMenuItem}
                    rules={{ required: 'Please select preference' }}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.bill_payments?.hasCouponValidity && (
                    <ErrorMessage errors={errors?.bill_payments?.hasCouponValidity} />
                )}
            </div>

            <div className='flex flex-row items-center md:mb-16'>
                <div className='mr-2.5 max-w-[196px] w-full'>
                    <Controller
                        render={({ field }) => (
                            <DatePicker
                                selected={selectedDate.startTime}
                                onChange={(value) => handleDateSelection(field.onChange, value, 'startTime')}
                                timeIntervals={15}
                                timeCaption='time'
                                dateFormat='dd MMM yyyy'
                                showIcon
                                icon={
                                    <CalenderIcon
                                        className={`${
                                            !watch('bill_payments.hasCouponValidity')?.value && 'stroke-[#d3d2d8]'
                                        }`}
                                    />
                                }
                                showPopperArrow={false}
                                minDate={today}
                                disabled={!watch('bill_payments.hasCouponValidity')?.value}
                            />
                        )}
                        name='bill_payments.validateFrom'
                        control={control}
                    />
                </div>

                <div className='ml-2.5 max-w-[196px] w-full'>
                    <Controller
                        render={({ field }) => (
                            <DatePicker
                                selected={selectedDate.endTime}
                                onChange={(value) => handleDateSelection(field.onChange, value, 'endTime')}
                                timeIntervals={15}
                                timeCaption='time'
                                dateFormat='dd MMM yyyy'
                                showIcon
                                icon={
                                    <CalenderIcon
                                        className={`${
                                            !watch('bill_payments.hasCouponValidity')?.value && 'stroke-[#d3d2d8]'
                                        }`}
                                    />
                                }
                                popperPlacement='bottom-end'
                                minDate={selectedDate.startTime}
                                disabled={!watch('bill_payments.hasCouponValidity')?.value}
                                showPopperArrow={false}
                                className={
                                    !watch('bill_payments.hasCouponValidity')?.value && 'text-neutral-300 cursor-none'
                                }
                            />
                        )}
                        name='bill_payments.validateTo'
                        control={control}
                    />
                </div>
            </div>
        </>
    );
}
