import React from 'react';

export default function TitleDescription(props) {
    const { title, disabled, description, className, maxWidth } = props;

    return (
        <div className={`w-full md:max-w-full ${className} ${maxWidth ?? 'max-w-[636px] lg:max-w-[462px]'}`}>
            <h1
                className={`paragraph-large-medium w-full md:paragraph-medium-medium ${
                    disabled && 'text-neutral-300'
                }`}>
                {title}
            </h1>
            <span
                className={`paragraph-medium-italic md:paragraph-small-italic ${
                    disabled ? 'text-neutral-300' : 'text-neutral-500'
                }`}>
                {description}
            </span>
        </div>
    );
}
