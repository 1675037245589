import React from 'react';
import InputSection from '../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function ThresholdSection(props) {
    const { discountType, setShowTabChangeWarningPopup } = props;

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                <InputSection
                    placeholder={`Enter threshold amount ${
                        discountType === 'flat' ? '(in rupees)' : '(in percentage)'
                    }`}
                    label={`Above threshold amount ${discountType === 'flat' ? '(in rupees)' : '(in percentage)'}`}
                    labelStyle='paragraph-large-medium'
                    shadow='shadow-smallDropDownShadow'
                    name='bill_payments.aboveThresholdValue'
                    inputType='number'
                    rules={{
                        validate: (value) => {
                            if (discountType !== 'flat' && value > 100) return 'Please enter less than 100';
                            if (!value) return 'Please fill this field';
                        },
                    }}
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                {errors?.bill_payments?.aboveThresholdValue && (
                    <ErrorMessage errors={errors.bill_payments?.aboveThresholdValue} />
                )}
            </div>

            <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                <InputSection
                    placeholder={`Enter threshold amount ${
                        discountType === 'flat' ? '(in rupees)' : '(in percentage)'
                    }`}
                    label={`Below threshold amount ${discountType === 'flat' ? '(in rupees)' : '(in percentage)'}`}
                    labelStyle='paragraph-large-medium'
                    shadow='shadow-smallDropDownShadow'
                    name='bill_payments.belowThresholdValue'
                    inputType='number'
                    rules={{
                        validate: (value) => {
                            if (discountType !== 'flat' && value > 100) return 'Please enter less than 100';
                            if (!value) return 'Please fill this field';
                        },
                    }}
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                {errors?.bill_payments?.belowThresholdValue && (
                    <ErrorMessage errors={errors.bill_payments?.belowThresholdValue} />
                )}
            </div>
        </>
    );
}
