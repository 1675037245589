import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';
import Header from './Components/Header';
import LiveOrder from './OrderTabPage/LiveOrder/LiveOrder';
import PastOrder from './OrderTabPage/PastOrder/PastOrder';
import FailedOrder from './OrderTabPage/FailedOrder/FailedOrder';
import BillPayment from '../BillPayment/BillPayment';
import OrderDetailsPopup from '../../Components/CustomerOrderDetails/OrderDetailsPopup';

export default function Orders() {
    const { orderType } = useParams();

    const screenHeight = useWindowSize().height;

    const componentToMap = {
        'live': LiveOrder,
        'past': PastOrder,
        'failed': FailedOrder,
        'bill-payment': BillPayment,
    };

    const [isKotView, setIsKotView] = useState(false);

    const ComponentToRender = componentToMap[orderType];

    const [showPopup, setShowPopup] = useState(null);

    const orderDetails = [{}];

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className='px-8 pageContentSmall:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'
                    style={{ minHeight: screenHeight - 50 }}>
                    <Header
                        setShowPopup={setShowPopup}
                        setIsKotView={setIsKotView}
                    />

                    <ComponentToRender
                        isKotView={isKotView}
                        setShowPopup={setShowPopup}
                    />
                </div>
            </div>

            {showPopup === 'orderDetails' && <OrderDetailsPopup />}
        </>
    );
}
