import React from 'react';
import { LargePrimaryButton } from '../../../../../Components/Buttons/Button';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function UPITab(props) {
    const { handleInputAmount } = props;

    return (
        <>
            <InputSection
                maxWidth='max-w-[375px]'
                label='Enter amount'
                placeholder='Enter UPI amount'
                shadow='shadow-xSmall'
                inputType='number'
                name='upiAmount'
                handleInputChange={(amount) => handleInputAmount(amount, 'upi')}
            />
            {/* 
            <div className='flex items-center my-4 w-full max-w-[375px]'>
                <div className='border-t border-neutral-300 flex-grow'></div>
                <span className='paragraph-medium-regular mx-2'>or</span>
                <div className='border-t border-neutral-300 flex-grow'></div>
            </div>

            <InputSection
                maxWidth='max-w-[375px]'
                label='Enter mobile number'
                placeholder='Enter mobile number'
                shadow='shadow-xSmall'
                name='mobileNumber'
            />

            <div className='text-primary-500 paragraph-medium-medium cursor-pointer hover:underline underline-offset-2 w-fit mt-2'>
                Send link
            </div> */}

            <div className='md:px-4 md:pb-6 w-full mt-[180px] md:fixed md:bottom-0 md:-ml-4 md:bg-white md:shadow-inner md:pt-4'>
                <LargePrimaryButton label='Save' />
            </div>
        </>
    );
}
