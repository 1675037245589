import React from 'react';
import TableHeader from '../../TableHeader';
import TableLoader from '../../../../../Components/ContentLoader/TableLoader';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { BlankComponentTable } from '../../../../../Components/BlankComponent/BlankComponent';
import MobileList from './MobileList';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function OfferListTable(props) {
    const { handleStatusChange, isLoading, list } = props;

    const isMobileScreen = IsMobileScreen();

    return !isMobileScreen ? (
        <div className='w-full border border-neutral-300 rounded-lg overflow-auto scrollbar-style'>
            <table className='w-full break-words'>
                <TableHeader
                    linkPopup
                    isRestaurantPage
                    linkOfferToOutletSection
                />

                {isLoading ? (
                    <tbody>
                        <TableLoader
                            hasSwitch
                            columns={6}
                        />
                    </tbody>
                ) : !!list?.length ? (
                    <tbody>
                        {list?.map((el, index) => (
                            <tr
                                className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t'
                                key={index}>
                                <td className='pl-6'>
                                    <span className={`${el.active ? 'text-success-500' : 'text-destructive-500'}`}>
                                        {el.active ? 'Active' : 'Inactive'}
                                    </span>
                                </td>

                                <td className='pl-6'>
                                    <ToggleSwitch
                                        isEnable={el.isLinkedToCurrentOutlet}
                                        setIsEnabled={(value) => handleStatusChange(el.id, value)}
                                        canChange
                                    />
                                </td>

                                <td className='pl-6'>{el.coupon_code}</td>

                                <td className='pl-6'>{el.offer_type}</td>

                                <td className='pl-6'>{el.translations.title[1]}</td>

                                <td className='pl-6'>{el.translations.description[1]}</td>
                            </tr>
                        ))}
                    </tbody>
                ) : (
                    <BlankComponentTable
                        colSpan={6}
                        previewText='No offer at this moment'
                    />
                )}
            </table>
        </div>
    ) : (
        <MobileList {...props} />
    );
}
