import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';

export default function CustomerInformationPopup(props) {
    const { handleClickClose, setShowPopup } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 md:p-4 m-auto'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Customer information</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Enter basic details for dine-in order
                            </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Customer name'
                            placeholder='Enter customer name'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='mb-4'>
                        <DefaultInputField
                            label='Mobile number'
                            placeholder='Enter mobile number'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='mb-12'>
                        <DefaultInputField
                            label='Number of guests'
                            placeholder='Enter number of guests'
                            shadow='shadow-xSmall'
                        />
                    </div>

                    <div className='flex flex-row items-center justify-between'>
                        <span className='paragraph-large-medium'>Step 1/2</span>
                        <div
                            className='max-w-[118px] cursor-pointer'
                            onClick={() => setShowPopup('enterTableNumber')}>
                            <LargePrimaryButton
                                label='Continue'
                                isDefault={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
