import * as types from '../types/permission';

const initialState = {
    permission: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PERMISSION:
            return {
                ...state,
                permission: action.payload.permission,
            };

        default:
            return state;
    }
};
