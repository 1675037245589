import React, { useEffect, useState } from 'react';
import { ReactComponent as OrderloopIcon } from '../../../Assets/orderloop-logo.svg';
import UserHeaderIcon from '../../../Components/Avatar/AvatarsIcon';
import AvatarImage from '../../../Assets/avatar-image.png';
import { ReactComponent as OrderLoopLogo } from '../../../Assets/orderloop-logo.svg';
import DropDownAvatar from '../../../Components/DropDown/DropDownAvatar';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useSelector } from 'react-redux';

export default function Header(props) {
    const { setShowLogoutPopup } = props;

    const user = useSelector((state) => state.auth.user);

    const [isHeaderSticky, setHeaderSticky] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setHeaderSticky(window.scrollY > 0);
        });
    }, []);

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen && (
                <div
                    className={`flex items-center justify-between bg-primary-500 px-4 fixed top-0 left-0 right-0 z-10 transition-effect ${
                        isHeaderSticky ? 'py-2.5 shadow-white' : 'pt-[60px] pb-4'
                    }`}>
                    <span className='paragraph-large-semi-bold text-white m-auto'>
                        <OrderloopIcon
                            fill='#FFFFFF'
                            height={32}
                        />
                    </span>

                    <span className='absolute right-0 lg:right-4'>
                        <UserHeaderIcon
                            widthOuter='8'
                            heightOuter='8'
                            imageIcon={AvatarImage}
                            colorIcon='[#22C55E]'
                            widthIcon='3'
                            heightIcon='3'
                        />
                    </span>
                </div>
            )}

            {!isMobileScreen && (
                <div className='px-8 lg:px-4 w-full max-w-[1440px] mx-auto bg-white'>
                    <div className='flex flex-row justify-center relative h-20 mb-6 items-center lg:border-b lg:border-neutral-300 lg:-mx-4 lg:px-4 lg:bg-[#FAFAFA] lg:sticky lg:top-0 lg:z-20'>
                        <div className='w-[217px]'>
                            <OrderLoopLogo />
                        </div>

                        <span className='absolute right-0 lg:right-4'>
                            <DropDownAvatar
                                label={user?.name}
                                widthIcon='16'
                                heightIcon='16'
                                setShowLogoutPopup={setShowLogoutPopup}
                            />
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}
