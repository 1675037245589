import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import APIV3 from '../../../../api/axios/APIV3';
import SearchableDropdownWithWindowing from './Components/SearchableDropdownWithWindowing';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import InputSection from '../../../../Components/InputField/InputSection';

export default function FreeItemSection() {
    const {
        watch,
        setValue,
        control,
        clearErrors,
        setError,
        formState: { errors },
    } = useFormContext();

    const [isLoadingMenuItems, setIsLoadingMenuItems] = useState(false);

    const [freeItemList, setFreeItemList] = useState([]);

    const [defaultMenuItems, setDefaultMenuItems] = useState([]);

    const [searchedQuery, setSearchedQuery] = useState('');

    const formatList = (list) => {
        const formatedList = list.map((el) => ({
            label: el.translations.title[1],
            value: {
                item_id: el.item_id,
                label: el.internal_name,
                quantity: 1,
                display_name: el.translations.title[1],
            },
            ...el,
        }));

        const filteredList = formatedList.filter(
            (item) => !watch('free_items')?.some((freeItem) => freeItem.item_id === item.value.item_id)
        );

        setFreeItemList(filteredList);

        !searchedQuery && setDefaultMenuItems(filteredList);
    };

    const fetchItemList = async (param) => {
        setIsLoadingMenuItems(true);

        const params = {
            page: pageState.page,
            per_page: 10,
            search_query: filters.searchQuery,
            ...param,
        };

        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/items`, {
                params,
            });

            setPageState((prevState) => {
                return { ...prevState, lastPage: response.item.last_page };
            });

            formatList(response.item.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoadingMenuItems(false);
        }
    };

    const handleSelect = (selectedItem, fieldOnChange) => {
        const newIdArray = watch('free_items')?.length ? [...watch('free_items'), selectedItem] : [selectedItem];

        setValue('free_items', newIdArray);

        fieldOnChange(newIdArray);

        clearErrors('free_items');

        const updateMenuItems = freeItemList.filter((item) => item.value !== selectedItem.value);

        setFreeItemList(updateMenuItems);
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const onCompleteTyping = (searchQuery) => {
        setSearchedQuery(searchQuery);

        searchQuery.length ? handleFetchList(searchQuery) : setFreeItemList([]);
    };

    const [filters, setFilters] = useState({ searchQuery: '' });

    const [pageState, setPageState] = useState({ page: 1, lastPage: 1 });

    const handleFetchList = useCallback(
        _.debounce(async (search_query) => {
            setFilters({ searchQuery: search_query });

            fetchItemList({ keyword: search_query, search_query: search_query, page: pageState.page });
        }, 1000)
    );

    const removeItem = (index) => {
        const removedItemList = watch('free_items').filter((_, itemIndex) => itemIndex !== index);
        setValue('free_items', removedItemList);
        if (watch('free_items').length === 0) {
            setError('free_items', { message: 'Plaese fill this filed' });
        }
    };

    useEffect(() => {
        fetchItemList({ keyword: '', page: 1 });
    }, []);

    const categoryMenuItemToPass = !searchedQuery ? defaultMenuItems : freeItemList;

    const freeItems = watch('free_items');

    const freeItemIds = new Set(freeItems.map((item) => item.item_id));

    const filteredCategoryMenuItems = categoryMenuItemToPass.filter((item) => !freeItemIds.has(item.item_id));

    return (
        <>
            <section className='mb-6'>
                <div className='max-w-[312px]'>
                    <h1 className='paragraph-large-medium md:paragraph-medium-medium'>Select item for offer</h1>

                    <span className='paragraph-medium-italic md:paragraph-small-italic text-neutral-500'>
                        This item will be free for this offer
                    </span>

                    <div className='mt-4'>
                        <Controller
                            render={({ field }) => (
                                <SearchableDropdownWithWindowing
                                    onCompleteTyping={onCompleteTyping}
                                    menuItems={filteredCategoryMenuItems}
                                    placeholder='Select item'
                                    handleSelect={(selection) => handleSelect(selection, field.onChange)}
                                    setIsLoadingMenuItems={setIsLoadingMenuItems}
                                    isLoadingMenuItems={isLoadingMenuItems}
                                    setPageState={setPageState}
                                    pageState={pageState}
                                    fetchNewData={fetchItemList}
                                    showDisplayValue={false}
                                />
                            )}
                            name='free_items'
                            control={control}
                            rules={{ required: 'Please select a free item' }}
                        />
                        {errors.free_items && <ErrorMessage errors={errors.free_items} />}
                    </div>
                </div>

                <div className='align-top mt-1'>
                    {watch('free_items')?.map((el, index) => (
                        <React.Fragment key={el.item_id}>
                            <div className='flex flex-row items-center mt-4 gap-2'>
                                <span className='paragraph-medium-regular'>{el.label}</span>

                                <InputSection
                                    className='h-fit'
                                    maxWidth='max-w-[100px]'
                                    inputType='number'
                                    placeholder='Select quantity'
                                    name={`free_items.${index}.quantity`}
                                />

                                <div
                                    className='min-w-[24px] ml-4 sm:ml-1.5'
                                    onClick={() => removeItem(index)}>
                                    <CloseIcon
                                        stroke='#EF4444'
                                        className='cursor-pointer'
                                    />
                                </div>
                            </div>

                            {errors?.free_items?.[index]?.quantity && (
                                <ErrorMessage errors={errors.free_items?.[index].quantity} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </section>
        </>
    );
}
