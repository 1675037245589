import React from 'react';
import { ReactComponent as LinearMenuIcon } from '../../Assets/linear-menu.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { offerTypes } from '../../Screen/Offers/Constants/Constants';
import { IsMobileScreen } from '../../Constants/Constants';

export default function TableRows(props) {
    const { coupon_code, offer_type, translations, index } = props;

    const offerType = offerTypes.find((el) => el.value === offer_type).label;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'pointer',
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <tr
            className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t'
            ref={setNodeRef}
            style={style}
            key={index}
            {...attributes}
            {...listeners}>
            <td className='pl-6 cursor-pointer min-w-[192px]'>
                <div className='flex flex-row items-center'>
                    <span className='mr-3'>
                        <LinearMenuIcon />
                    </span>

                    <span>{coupon_code}</span>
                </div>
            </td>

            <td className='pl-6 min-w-[133px]'>{offerType}</td>

            <td className='pl-6 min-w-[168px]'>{translations.title[1]}</td>

            {!isMobileScreen && <td className='pl-6 min-w-[235px]'>{translations.description[1]}</td>}
        </tr>
    );
}
