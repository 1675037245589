import React from 'react';
import { ReactComponent as Replace } from '../../../Assets/replace.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';

export default function EnableOutletDiningModePopup(props) {
    const { handleClickClose, handleClickChange } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4 md:max-w-[343px]'>
                    <div className='pb-3 flex flex-row items-center'>
                        <div className='p-3 w-10 bg-primary-100 rounded-md'>
                            <Replace className='h-4 w-4' />
                        </div>

                        <h2 className='paragraph-large-medium ml-2'>Change the status?</h2>
                    </div>

                    <p className='paragraph-medium-italic text-neutral-500 pb-12 md:pb-3'>
                        Are you sure to change the status from
                        <span className='text-destructive-500 italic'> INACTIVE</span> to
                        <span className='text-success-700 italic'> ACTIVE</span>
                    </p>

                    <div className='flex flex-row justify-between'>
                        <button
                            onClick={handleClickClose}
                            className='h-12 w-[196px] md:w-1/2 md:mr-[7.5px] paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300 cursor-pointer'>
                            Cancel
                        </button>

                        <div
                            className='w-1/2 md:ml-[7.5px] cursor-pointer'
                            onClick={() => handleClickChange(null)}>
                            <LargePrimaryButton label='Change' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
