import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as SelectIcon } from '../../../../Assets/select.svg';
import { ReactComponent as EditIcon } from '../../../../Assets/edit.svg';
import { ReactComponent as AddIcon } from '../../../../Assets/add.svg';
import { ReactComponent as FilterIcon } from '../../../../Assets/filter.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { Tab } from '../../../../Components/Tabs/Tabs';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { useParams } from 'react-router-dom';

const Header = (props) => {
    const {
        handleClickEdit,
        handleClickAdd,
        handleClickBulkSelect,
        handleTabChange,
        handleFilterClick,
        selectedForDelete,
        handlePopStatus,
        actionState,
    } = props;
    const params = useParams();

    const isMobileScreen = IsMobileScreen();
    return (
        <>
            <div className='flex flex-row justify-between mb-4 md:block'>
                {!isMobileScreen ? (
                    !actionState.isBulkSelect && (
                        <>
                            <div className='flex flex-row items-center mr-4 lg:mr-2'>
                                <div
                                    className='mr-4 lg:mr-2 cursor-pointer'
                                    onClick={() => handleTabChange('user-list')}>
                                    <Tab
                                        label='Users list'
                                        isActive={params.tab === 'user-list'}
                                    />
                                </div>
                                <div
                                    className='cursor-pointer mr-4'
                                    onClick={() => handleTabChange('preset-list')}>
                                    <Tab
                                        label='Presets list'
                                        isActive={params.tab === 'preset-list'}
                                    />
                                </div>
                                <div
                                    className='mr-4'
                                    onClick={handleClickBulkSelect}>
                                    <LargePrimaryButton
                                        label='Bulk select'
                                        leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                        leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                        isClicked={actionState.isBulkSelect}
                                    />
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    <div className='flex flex-row mb-4'>
                        <div className='w-full mr-2'>
                            <DropDownTabs
                                menuItems={[
                                    { item: 'Users list', onClick: () => handleTabChange('Users list') },
                                    { item: 'Presets list', onClick: () => handleTabChange('Presets list') },
                                ]}
                            />
                        </div>
                        <div
                            className='min-w-[64px]'
                            onClick={handleClickBulkSelect}>
                            <LargePrimaryButton
                                leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                leftIconClick={<SelectIcon stroke='#C4BEED' />}
                                isClicked={actionState.isBulkSelect}
                            />
                        </div>
                    </div>
                )}

                {actionState.isBulkSelect && (
                    <div className='flex flex-row items-center md:w-full md:mb-2'>
                        <div
                            className='max-w-[125px] mr-4 mobile:mr-2 md:w-1/2 md:max-w-full cursor-pointer'
                            onClick={handleClickBulkSelect}>
                            <LargeTertiaryButton
                                isDefault={false}
                                label='Cancel'
                                hideLabel='lg:hidden'
                                leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                                leftIconClick={<CloseIcon stroke='#C4BEED' />}
                            />
                        </div>

                        <div
                            className='max-w-[192px] md:w-1/2 md:max-w-full'
                            onClick={() => handlePopStatus('showConfirmDeletePopup')}>
                            <LargeDestructiveButton
                                label='Delete selected'
                                leftIconDefault={<SelectIcon stroke='#FFFFFF' />}
                                leftIconClick={<SelectIcon stroke='#D7EDEB' />}
                                hideLabel='lg:hidden'
                                disabled={selectedForDelete < 1}
                            />
                        </div>
                    </div>
                )}

                {!(isMobileScreen && actionState.isBulkSelect) && (
                    <div className='flex flex-row'>
                        {actionState.isShowUserList ? (
                            <>
                                <div
                                    className='md:w-full'
                                    onClick={handleFilterClick}>
                                    <LargePrimaryButton
                                        hideLabel='md:hidden'
                                        label='Filter'
                                        leftIconClick={<FilterIcon fill='#C4BEED' />}
                                        leftIconDefault={<FilterIcon fill='#FFFFFF' />}
                                        isClicked={actionState.isFilterActive}
                                    />
                                </div>

                                <div
                                    className='ml-4 cursor-pointer md:w-full'
                                    onClick={handleClickEdit}>
                                    <LargePrimaryButton
                                        label='Edit user'
                                        isClicked={actionState.isEditMode}
                                        leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                                        leftIconClick={<EditIcon stroke='#C4BEED' />}
                                        hideLabel='lg:hidden'
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className='ml-4 lg:ml-2 cursor-pointer md:w-full md:ml-0'
                                    onClick={handleClickEdit}>
                                    <LargePrimaryButton
                                        label='Edit preset'
                                        isClicked={actionState.isEditMode}
                                        leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                                        leftIconClick={<EditIcon stroke='#C4BEED' />}
                                    />
                                </div>
                            </>
                        )}
                        <div
                            className='ml-4 cursor-pointer md:w-full'
                            onClick={handleClickAdd}>
                            <LargePrimaryButton
                                label={actionState.isShowUserList ? 'Add user' : 'Add preset'}
                                isDefault={false}
                                leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                                leftIconClick={<AddIcon stroke='#c4beed' />}
                                hideLabel={`lg:hidden ${!actionState.isShowUserList ? 'md:block' : 'md:hidden'}`}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Header;
