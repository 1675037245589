import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import Dropdown from '../../../Components/DropDown/Dropdown';

export default function TableNumberPopup(props) {
    const { handleClickClose } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:p-4'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Table number</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Select table for the customer
                            </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-12'>
                        <Dropdown
                            label='Table number'
                            placeholder='Select table'
                            menuItems={[]}
                        />
                    </div>

                    <div className='flex flex-row justify-between items-center'>
                        <span className='paragraph-large-medium'>Step 2/2</span>
                        <div className='max-w-[118px]'>
                            <LargePrimaryButton label='Continue' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
