import React, { useEffect, useState } from 'react';
import Logo from '../../Assets/orderloop-logo.svg';
import { LargePrimaryButton } from '../../Components/Buttons/Button';
import LogoMobile from '../../Assets/orderloop-logo-mobile.svg';
import LoginSlider from './Components/LoginSlider';
import { useNavigate } from 'react-router-dom';
import { InputField } from './Components/Components';
import APIV2_1 from '../../api/axios/APIV2_1';
import { useDispatch, useSelector } from 'react-redux';
import { SET_AVAILABLE_USER, SET_USER } from '../../reduxStore/types/auth';
import { IsMobileScreen } from '../../Constants/Constants';
import { useForm } from 'react-hook-form';
import APIV2 from '../../api/axios/APIV2';
import LoginContentLoader from './Components/ContentLoader';
import ApplicationLoading from './Components/ApplicationLoading/ApplicationLoading';

export default function Login(props) {
    let isApplicationLoading = false;
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { setValue, watch, register } = useForm();

    const [mobileNumber, otp] = watch(['mobileNumber', 'otp']);

    const firebaseToken = useSelector((state) => state.auth.firebaseToken);

    const deviceId = useSelector((state) => state.auth.deviceId);

    const authToken = useSelector((state) => state.auth.authToken);

    const isMobileScreen = IsMobileScreen();

    const [isMobileNumberPage, setIsMobileNumberPage] = useState(true);

    const [isValidInput, setIsValidInput] = useState(true);

    const postMobileNumber = async () => {
        try {
            await APIV2_1.post('/user/login', {
                mobile_number: mobileNumber,
                country_code: '+91',
            });
            setIsMobileNumberPage(false);
        } catch (error) {
            setIsValidInput(false);
        }
    };

    const verifyOtp = async () => {
        try {
            let response = await APIV2_1.get('/user/otp/verification', {
                params: {
                    mobile_number: mobileNumber,
                    country_code: '+91',
                    device_id: deviceId,
                    platform: 'web_app',
                    otp,
                    token: firebaseToken ?? 'null',
                },
            });

            setIsValidInput(response.success);

            registerDevice();

            dispatch({
                type: SET_USER,
                payload: {
                    ...response.user,
                },
            });

            let user = response.user;

            user['accepting_orders'] = response.device.accepting_orders;

            localStorage.setItem('authToken', response.token);

            localStorage.setItem('user', JSON.stringify(user));

            const availableUsers = localStorage.getItem('availableUsers');

            const parsedAvailableUsers = !!availableUsers ? JSON.parse(localStorage.getItem('availableUsers')) : [];

            const isUserAvailable = parsedAvailableUsers?.filter((el) => el.id !== user?.id);

            localStorage.setItem(
                'availableUsers',
                JSON.stringify([...isUserAvailable, { ...user, authToken: response.token }])
            );

            dispatch({
                type: SET_AVAILABLE_USER,
                payload: { availableUser: [...isUserAvailable, { ...user, authToken: response.token }] },
            });

            response.success && navigate('/select-restaurant');
        } catch (error) {
            console.log(error);
        }
    };

    const registerDevice = async () => {
        try {
            await APIV2.put(`/devices`, {
                device_id: deviceId,
                platform: 'web_app',
                token: firebaseToken ?? 'null',
            });
        } catch (error) {
            console.log(error);
        }
    };

    const isValidMobileNumber = mobileNumber?.length === 10;

    const isValidOtp = otp?.length === 5;

    const handleClickSignIn = () => {
        const isValidInput = isMobileNumberPage ? isValidMobileNumber : isValidOtp;
        isValidInput ? (isMobileNumberPage ? postMobileNumber() : verifyOtp()) : setIsValidInput(false);
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClickSignIn();
        }
    };

    return (
        <>
            {isApplicationLoading && <ApplicationLoading />}

            <div className='max-w-[1440px] w-full mx-auto bg-white flex flex-row justify-between min-h-screen md:min-h-0'>
                <LoginSlider />

                <div className='max-w-[615px] w-full xl:bg-[#695AD3] xl:px-[76px] relative xl:max-w-full md:px-0 md:h-full md:bg-transparent'>
                    <div className='h-full bg-white px-4'>
                        {isApplicationLoading ? (
                            <LoginContentLoader />
                        ) : (
                            <>
                                <div className='mb-[184px] pageContentLarge:mb-[120px] pageContent:mb-20 pt-10 md:pt-4 md:mb-[50px]'>
                                    {!isMobileScreen ? (
                                        <img
                                            src={Logo}
                                            alt='Logo'
                                            className='w-[200px] mx-auto'
                                        />
                                    ) : (
                                        <img
                                            src={LogoMobile}
                                            alt='Logo'
                                        />
                                    )}
                                </div>

                                <div className='max-w-[375px] w-full mx-auto md:max-w-full'>
                                    <div className='mb-10 md:mb-[25px]'>
                                        <h1 className='heading-h1-bold-desktop md:heading-h3-bold-desktop mb-2'>
                                            Hello there!
                                        </h1>
                                        <p className='paragraph-small-regular'>
                                            Sign in and start your wonderful day with the Orderloop.
                                        </p>
                                    </div>

                                    <div className='mb-10'>
                                        <InputField
                                            inputValue={isMobileNumberPage ? mobileNumber : otp}
                                            register={register}
                                            setValue={setValue}
                                            onKeyDown={onKeyDown}
                                            isMobileNumberPage={isMobileNumberPage}
                                            isValidInput={isValidInput}
                                            setIsValidInput={setIsValidInput}
                                            mobileNumberFIeldName={'mobileNumber'}
                                            otpFieldName={'otp'}
                                            showMobileIcon
                                        />
                                    </div>

                                    <div
                                        className='mb-6'
                                        onClick={handleClickSignIn}>
                                        <LargePrimaryButton
                                            label='Proceed'
                                            isDefault={false}
                                        />
                                    </div>

                                    <div className='text-center md:fixed md:bottom-0 md:pb-2 md:pt-2.5 md:left-0 md:right-0 md:bg-white'>
                                        <span className='paragraph-small-medium'>New to OrderLoop?</span>
                                        <a
                                            href='https://www.orderloop.in/'
                                            target='_blank'>
                                            <span className='text-primary-500 paragraph-small-medium ml-0.5'>
                                                Contact us
                                            </span>
                                        </a>
                                    </div>
                                </div>

                                {!isMobileScreen && (
                                    <div className='text-center absolute bottom-10 xl:relative xl:bottom-0 xl:pb-10 xl:pt-[236px] left-0 right-0 text-neutral-500 paragraph-x-small-regular'>
                                        &copy; 2022 Orderloop. All rights reserved.
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
