import * as types from '../types/device';

const notificationSoundLoop = localStorage.getItem('notificationSoundLoop')
    ? !!JSON.parse(localStorage.getItem('notificationSoundLoop'))
    : null;

const initialState = {
    notificationSoundLoop,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NOTIFICATION_SOUND_LOOP:
            return {
                ...state,
                notificationSoundLoop: action.payload.isContinueLoop,
            };

        default:
            return state;
    }
};
