import React from 'react';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';
import { useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';

export default function ExposeDish(props) {
    const { title, description } = props;

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const {
        control,
        setValue,
        getValues,
        formState: { errors },
        clearErrors,
        setError,
        watch,
        handleSubmit,
    } = useFormContext();

    const handleChangeSelection = (mode, isSelected) => {
        const selectedMode = getValues('exposeDish') || [];

        const newSelectedMode = !isSelected ? selectedMode.filter((el) => el.id !== mode.id) : [...selectedMode, mode];

        setValue('exposeDish', newSelectedMode, { shouldValidate: true, shouldDirty: true });

        isSelected || !selectedMode.length
            ? clearErrors('exposeDish')
            : !_.isEmpty(errors) && setError('exposeDish', { message: 'Please select at least one ordering mode' });
    };

    const onSubmit = (params) => {
        console.log('Submitted Params:', params);
    };

    return (
        <>
            <TitleDescription
                className='mb-6'
                title={title}
                description={description}
            />

            <div className='mb-4'>
                <div className='mb-4 paragraph-medium-italic text-neutral-500'>
                    Selected modes(active) -{' '}
                    {watch('exposeDish')?.length < 10 ? `0${watch('exposeDish')?.length}` : watch('exposeDish')?.length}
                </div>
            </div>

            <div className=''>
                <Controller
                    name='exposeDish'
                    control={control}
                    defaultValue={[]}
                    rules={{
                        validate: (value) => value.length > 0 || 'Please enter at least one ordering mode',
                    }}
                    render={({ field }) => (
                        <>
                            {orderingMode.map((el, index) => {
                                const isChecked = field.value.some((item) => item.id === el.id);

                                return (
                                    <div
                                        className='flex flex-row items-center mb-4'
                                        key={index}>
                                        <CheckBoxWithoutLabels
                                            onChange={(isChecked) => handleChangeSelection(el, isChecked)}
                                            isChecked={isChecked}
                                        />
                                        <span className='paragraph-medium-regular ml-6'>{el.display_name}</span>
                                    </div>
                                );
                            })}
                        </>
                    )}
                />
            </div>

            {!!errors.exposeDish && <ErrorMessage errors={errors.exposeDish} />}
        </>
    );
}
