import React, { useEffect, useState } from 'react';
import Header from '../../../../../Components/Header/Header';
import {
    LargeDestructiveButton,
    LargePrimaryButton,
    LargeTertiaryButton,
} from '../../../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../../../Assets/add.svg';
import { ReactComponent as EditIcon } from '../../../../../Assets/edit.svg';
import { ReactComponent as SelectionIcon } from '../../../../../Assets/select.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import CreateOrderingModePopup from './Popup/CreateOrderingModePopup';
import { useFormContext } from 'react-hook-form';
import ListSection from './Components/ListSection';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import Layout from '../../../Components/Layout';
import _ from 'lodash';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function OrderingMode(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const [showCreateOrderingModePopup, setShowCreateOrderingModePopup] = useState(false);

    const { handleSubmit, setValue } = useFormContext();

    const onSubmit = () => {
        (activeInactiveMode.activeMode.length || activeInactiveMode.inActiveMode.length) && updateModeStatus();
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const initialMode = { activeMode: [], inActiveMode: [] };

    const [activeInactiveMode, setActiveInactiveMode] = useState({ activeMode: [], inActiveMode: [] });

    const [orderingMode, setOrderingMode] = useState([]);

    const [defaultOrderingMode, setDefaultOrderingMode] = useState([]);

    console.log(
        'orderingMode ==>',
        orderingMode.map((el) => el.status),
        defaultOrderingMode.map((el) => el.status),
        activeInactiveMode
    );

    const [isListLoading, setIsListLoading] = useState(false);

    const fetchOrderingMode = async () => {
        setIsListLoading(true);

        try {
            const response = await APIV5.get(`/restaurants/${selectedRestaurantId}/restaurant-ordering-modes`);

            setOrderingMode(response.ordering_modes);

            // setDefaultOrderingMode(response.ordering_modes);

            if (response.success) {
                setValue('isLoadingForm', false);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsListLoading(false);
        }
    };

    const updateModeStatus = async () => {
        const ordering_modes = [];

        Object.entries(activeInactiveMode).forEach(([status, outlets]) => {
            outlets.forEach((outletId) => {
                ordering_modes.push({ id: outletId, status: status === 'activeMode' });
            });
        });

        try {
            const response = await APIV5.patch(`restaurants/${selectedRestaurantId}/restaurant-ordering-modes`, {
                ordering_modes,
            });

            if (response.success) {
                setActiveInactiveMode(initialMode);
                fetchOrderingMode();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [isEdit, setIsEdit] = useState(false);

    const [editModeId, setEditModeId] = useState({});

    const [isSelectionEnable, setIsSelectionEnable] = useState(false);

    const handleClickDelete = async () => {
        try {
            const response = await APIV5.delete(`restaurants/${selectedRestaurantId}/restaurant-ordering-modes`, {
                params: { ordering_mode_ids: selectedMode },
            });

            if (response.success) {
                setIsSelectionEnable(false);
                fetchOrderingMode();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedMode, setSelectedMode] = useState([]);

    const onSelectMode = (id, isSelected) => {
        setSelectedMode(isSelected ? [...selectedMode, id] : selectedMode.filter((el) => el !== id));
    };

    const isMobileScreen = IsMobileScreen();

    if (isMobileScreen && showCreateOrderingModePopup) {
        return (
            <CreateOrderingModePopup
                setIsEdit={setIsEdit}
                setEditModeId={setEditModeId}
                handleClickClose={() => setShowCreateOrderingModePopup(false)}
                fetchOrderingMode={fetchOrderingMode}
            />
        );
    }

    if (isMobileScreen && !_.isEmpty(editModeId)) {
        return (
            <CreateOrderingModePopup
                setIsEdit={setIsEdit}
                setEditModeId={setEditModeId}
                fetchOrderingMode={fetchOrderingMode}
                handleClickClose={() => setEditModeId({})}
                details={editModeId}
            />
        );
    }

    const handleClickCancel = () => {
        handleDiscardForm();
        setOrderingMode(defaultOrderingMode);
        setActiveInactiveMode(initialMode);
    };

    return (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleClickCancel();
            }}
            showFooter={
                !!selectedMode?.length ||
                !!activeInactiveMode?.activeMode?.length ||
                !!activeInactiveMode?.inActiveMode?.length
            }>
            <div className='w-full flex flex-row justify-between xl:block'>
                <div className='mb-6 max-w-[636px] xl:mb-2'>
                    <Header
                        title='Ordering mode'
                        headerBottomLine='Create a new ordering mode or choose to enable or disable the ordering mode a customer can select for your outlet.'
                    />
                </div>

                <div
                    className='max-w-[187px] xl:mb-4 md:max-w-full cursor-pointer'
                    onClick={() => setShowCreateOrderingModePopup((prevState) => !prevState)}>
                    <LargePrimaryButton
                        label='Ordering mode'
                        leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                        leftIconClick={<AddIcon stroke='#C4BEED' />}
                        isDefault={false}
                    />
                </div>
            </div>
            {!isSelectionEnable ? (
                <div className='flex flex-row gap-2 mb-4 md:w-full'>
                    <div
                        className='md:w-1/2'
                        onClick={() => {
                            setIsEdit((prevState) => !prevState);
                            setIsSelectionEnable(false);
                        }}>
                        <LargePrimaryButton
                            label='Edit mode'
                            leftIconDefault={<EditIcon stroke='#FFFFFF' />}
                            leftIconClick={<EditIcon stroke='#C4BEED' />}
                        />
                    </div>

                    <div
                        className='md:w-1/2'
                        onClick={() => {
                            setIsSelectionEnable((prevState) => !prevState);
                            setIsEdit(false);
                        }}>
                        <LargePrimaryButton
                            label='Bulk select'
                            leftIconDefault={<SelectionIcon stroke='#FFFFFF' />}
                            leftIconClick={<SelectionIcon stroke='#C4BEED' />}
                        />
                    </div>
                </div>
            ) : (
                <div className='flex flex-row items-center md:w-full mb-4'>
                    <div
                        className='max-w-[125px] mr-4 mobile:mr-2 md:w-1/2 md:max-w-full cursor-pointer'
                        onClick={() => setIsSelectionEnable(false)}>
                        <LargeTertiaryButton
                            isDefault={false}
                            label='Cancel'
                            hideLabel='lg:hidden'
                            leftIconDefault={<CloseIcon stroke='#FFFFFF' />}
                            leftIconClick={<CloseIcon stroke='#C4BEED' />}
                        />
                    </div>

                    <div
                        className='max-w-[192px] md:w-1/2 md:max-w-full'
                        onClick={handleClickDelete}>
                        <LargeDestructiveButton
                            label='Delete selected'
                            leftIconDefault={<SelectionIcon stroke='#FFFFFF' />}
                            leftIconClick={<SelectionIcon stroke='#D7EDEB' />}
                            hideLabel='lg:hidden'
                        />
                    </div>
                </div>
            )}

            <ListSection
                setActiveInactiveMode={setActiveInactiveMode}
                orderingMode={orderingMode}
                fetchOrderingMode={fetchOrderingMode}
                isListLoading={isListLoading}
                isEdit={isEdit}
                isSelectionEnable={isSelectionEnable}
                onSelectMode={onSelectMode}
                selectedMode={selectedMode}
                setEditModeId={setEditModeId}
                setOrderingMode={setOrderingMode}
                setIsListLoading={setIsListLoading}
                setDefaultOrderingMode={setDefaultOrderingMode}
            />

            {showCreateOrderingModePopup && (
                <CreateOrderingModePopup
                    fetchlanguageList={fetchlanguageList}
                    setIsEdit={setIsEdit}
                    setEditModeId={setEditModeId}
                    handleClickClose={() => setShowCreateOrderingModePopup(false)}
                    fetchOrderingMode={fetchOrderingMode}
                    fetchSettings={fetchSettings}
                />
            )}

            {!_.isEmpty(editModeId) && (
                <CreateOrderingModePopup
                    fetchlanguageList={fetchlanguageList}
                    setIsEdit={setIsEdit}
                    setEditModeId={setEditModeId}
                    fetchOrderingMode={fetchOrderingMode}
                    handleClickClose={() => setEditModeId({})}
                    details={editModeId}
                    fetchSettings={fetchSettings}
                />
            )}
        </Layout>
    );
}
