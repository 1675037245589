import React from 'react';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';

export default function GuestOccasionSection() {
    return (
        <>
            <div className='flex flex-row items-center mb-4 md:block md:mb-0'>
                <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                    <DefaultInputField
                        label='Number of guests'
                        placeholder='Enter number of guests'
                        shadow='shadow-xSmall'
                        inputType='number'
                    />
                </div>

                <div className='w-1/2 ml-2 md:w-full md:ml-0 md:mb-2'>
                    <DropdownWithValueLabel
                        menuItems={[{ label: 'Casual', value: 'casual' }]}
                        label='Select occasion'
                        placeholder='Select occasion'
                    />
                </div>
            </div>
        </>
    );
}
