import React, { useState } from 'react';
import DetailsDropDown from '../Components/DetailsDropDown';
import CategoryDetails from '../Components/CategoryDetails';
import DishDetailsSection from '../Components/DishDetailsSection';
import { useWindowSize } from '@uidotdev/usehooks';

export default function DetailsSection(props) {
    const { selectedCategoryDish, setDishOperationState, clickedCategory } = props;

    const detailMenuItem = [
        { label: 'Dish details', value: 'dishDetails' },
        { label: 'Category details', value: 'categoryDetails' },
    ];

    const [activeDetails, setActiveDetails] = useState(detailMenuItem?.[1]);

    return (
        <>
            <div className='flex flex-row justify-between items-center w-full pt-0.5 mb-4 md:mb-2 md:pt-0'>
                <span className='paragraph-large-medium'>View details</span>
                <DetailsDropDown
                    handleChangeDropDown={(item) => setActiveDetails(item)}
                    menuItem={detailMenuItem}
                    selectedItem={detailMenuItem[1]}
                />
            </div>

            {activeDetails?.value === 'dishDetails' ? (
                <DishDetailsSection selectedCategoryDish={selectedCategoryDish} />
            ) : (
                <CategoryDetails
                    selectedCategoryDish={selectedCategoryDish}
                    setDishOperationState={setDishOperationState}
                    clickedCategory={clickedCategory}
                />
            )}
        </>
    );
}
