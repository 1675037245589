import React, { useEffect, useState } from 'react';
import ListViewOffer from './ListViewOffer';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';
import { BlankComponent } from '../../../Components/BlankComponent/BlankComponent';
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { fetchOutletwiseOffer } from './functions';
import { useSelector } from 'react-redux';

export default function OutletWiseOfferListSection(props) {
    const { list, selectedOffer, activeState, onSelectOffer, isListLoading, unlinkOutlet, setIsListLoading } = props;

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [pageState, setPageState] = useState(props.pageState);

    const [menuItem, setMenuItem] = useState(list);

    const [isLastPage, setIsLastPage] = useState(true);

    useEffect(() => {
        setMenuItem(list);
    }, [list]);

    const fetchMoreData = async () => {
        const params = {
            page: pageState.page + 1,
            per_page: pageState.per_page,
        };

        setIsListLoading(true);

        const response = await fetchOutletwiseOffer(params, selectedRestaurantId, selectedOutletId);

        if (response) {
            setMenuItem((prevItems) => [...prevItems, ...response.offers.data]);

            setIsListLoading(false);

            setPageState((prevState) => {
                return { ...prevState, page: response.offers.current_page };
            });

            setIsLastPage(!response.offers.has_more_pages);
        }
    };

    const handleDragOver = (event) => {
        const { over } = event;

        const thirdLastIndex = menuItem.length - 2;

        if (!isLastPage && over && over.id === menuItem[thirdLastIndex].id) {
            fetchMoreData();
        }
    };

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setMenuItem((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    return (
        <>
            {isListLoading ? (
                [...Array(10)].map((_, index) => (
                    <React.Fragment key={index}>
                        <ListViewLoader />
                    </React.Fragment>
                ))
            ) : !list.length ? (
                <BlankComponent
                    styles='rounded-xl border border-neutral-300'
                    previewText='No offer at this moment'
                />
            ) : (
                <>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}>
                        <SortableContext
                            items={menuItem}
                            strategy={verticalListSortingStrategy}
                            disabled={!activeState.reArrange}>
                            {menuItem?.map((el) => {
                                return (
                                    <div
                                        className='mt-2 first:mt-0'
                                        key={el.id}>
                                        <ListViewOffer
                                            {...el}
                                            hasSelection={activeState.selection}
                                            isSelected={selectedOffer?.includes(el.id)}
                                            onSelect={(isSelected) => onSelectOffer(el.id, isSelected)}
                                            isRearrange={activeState.reArrange}
                                            unlinkOutlet={() => unlinkOutlet(el.id)}
                                            activeState={activeState}
                                            dontShowViewDetails
                                        />
                                    </div>
                                );
                            })}
                        </SortableContext>
                    </DndContext>
                </>
            )}
        </>
    );
}
