import React from 'react';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as SwiggyIcon } from '../../../Assets/swiggy.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { IsMobileScreen } from '../../../Constants/Constants';
import OrdersCard from '../Components/OrdersCard';

export default function OrdersDetailsPopup(props) {
    const { handleClickClose, handleClickCancelOrder, handleClickAssignRider } = props;

    const isMobileScreen = IsMobileScreen();

    const orderDetails = [
        {
            icon: (
                <SwiggyIcon
                    height={40}
                    width={40}
                />
            ),
            orderingMode: 'Order number',
            orderLabel: '#BBQR',
            items: [
                {
                    displayName: 'Double Cheese Margherita Pizza',
                    note: 'Keep the pizza loaded with ample amount of cheese',
                    foodType: 'veg',
                    quantity: 2,
                    addons: 'Keep the pizza loaded with ample amount of cheese',
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Farmhouse Extraveganza Veggie',
                    foodType: 'veg',
                    quantity: 3,
                    customization: [
                        {
                            displayName: 'Extra cheese',
                        },
                        {
                            displayName: 'Extra veggies',
                        },
                    ],
                },
                {
                    displayName: 'Mexican Green Wave',
                    foodType: 'veg',
                    quantity: 2,
                },
                {
                    displayName: 'Italian Pesto Pasta',
                    foodType: 'veg',
                    quantity: 2,
                },
            ],
            timeElapsed: [
                {
                    mins: 15,
                },
                {
                    seconds: 16,
                },
            ],
        },
    ];
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[8] z-[49] flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div className='max-w-[518px] w-full rounded-xl md:rounded-none bg-shades-50 md:px-0 md:py-0 m-auto md:max-w-full md:z-[9] z-50 min-h-[600px] md:min-h-0'>
                    <div className='flex mx-4 mt-4 md:mx-0 md:mt-0'>
                        {isMobileScreen ? (
                            <div
                                className='flex items-center paragraph-medium-medium cursor-pointer'
                                onClick={handleClickClose}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to live orders</span>
                            </div>
                        ) : (
                            <div
                                className='cursor-pointer ml-auto'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        )}
                    </div>

                    {orderDetails.map((el, index) => (
                        <div key={index}>
                            <OrdersCard
                                handleClickCancelOrder={handleClickCancelOrder}
                                ordersIcon={el.icon}
                                isUpdated={el.isUpdated}
                                orderLabel={el.orderLabel}
                                orderingMode={el.orderingMode}
                                timeElapsed={el.timeElapsed}
                                items={el.items}
                                handleClickAssignRider={handleClickAssignRider}
                                borderStyle='border-none'
                                paddingRight='pr-0'
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
