import React, { useCallback } from 'react';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import SearchableDropdownWithWindowing from '../../../../../Components/SearchableDropdownWithWindowing/SearchableDropdownWithWindowing';
import debounce from 'lodash/debounce';

export default function MobileNumberCustomerName() {
    const onCompleteTyping = (searchQuery) => {
        searchQuery.length ? handleFetchList(searchQuery) : handleFetchList();
    };

    const handleFetchList = useCallback(debounce(async (search_query) => {}, 1000));

    return (
        <>
            <div className='flex flex-row items-center mb-4 md:block md:mb-0 gap-4'>
                <div className='w-1/2 md:ml-0 md:w-full md:mb-2'>
                    <SearchableDropdownWithWindowing
                        onCompleteTyping={(searchNumber) => onCompleteTyping(searchNumber)}
                        label='Mobile number'
                        placeholder='Enter mobile number'
                    />
                </div>
                <div className='w-1/2 md:w-full md:mr-0 md:mb-2'>
                    <DefaultInputField
                        label='Customer name'
                        placeholder='Enter customer name'
                    />
                </div>
            </div>
        </>
    );
}
