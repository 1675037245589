import React, { useState, useEffect, useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import { CheckBox } from '../../../Components/FormControl/FormControls';

export default function SearchDropDown(props) {
    const {
        label,
        disabled,
        menuItems,
        labelColor,
        fixedHeight,
        placeholder,
        handleSearch,
        fetchMoreData,
        openFromBottom,
        handleSelectItem,
        dropdownIconColor,
        searchInputPlaceholder,
    } = props;

    const [boxLabel, setBoxLabel] = useState(null);

    const handleClickMenuItem = (menuItem) => {
        setBoxLabel(menuItem.label);
        handleSelectItem(menuItem);
    };

    // Add a ref to the Menu.Items div
    const menuItemsRef = React.useRef(null);

    // Function to handle scroll event
    const handleScroll = useCallback(() => {
        if (menuItemsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = menuItemsRef.current;

            if (scrollTop + clientHeight >= scrollHeight) {
                fetchMoreData();
            }
        }
    }, [fetchMoreData]);

    // Add event listener for scrolling
    useEffect(() => {
        const currentMenuItemsRef = menuItemsRef.current;
        if (currentMenuItemsRef) {
            currentMenuItemsRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentMenuItemsRef) {
                currentMenuItemsRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    return (
        <>
            <label className={`paragraph-medium-italic flex ${labelColor ?? 'text-neutral-500'}`}>{label}</label>
            <div className='mt-2'>
                <Menu
                    as='div'
                    className=''>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            disabled={disabled}
                            className='shadow-smallDropDownShadow flex flex-row justify-between w-full mobile:max-w-full rounded-md outline-none border py-[13px] appearance-none px-4 border-neutral-300 paragraph-small-regular mobile:px-1'>
                            <div className={``}>
                                {!boxLabel ? (
                                    <div className='font-normal italic text-neutral-300 pt-0.5 text-sm leading-5 '>
                                        {placeholder}
                                    </div>
                                ) : (
                                    <div className={`pt-0.5 text-left justify-start paragraph-small-regular`}>
                                        {boxLabel}
                                    </div>
                                )}
                            </div>
                            <DownArrow
                                className={`drop-down-icon-rotate ml-3`}
                                fill={dropdownIconColor ?? '#131126'}
                                height={24}
                                width={24}
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items
                            ref={menuItemsRef} // Attach the ref here
                            className={`absolute mt-2 left-0 right-0 mobile:w-full md:max-w-full ring-1 ring-black ring-opacity-5 focus:outline-none px-4 py-2 border border-neutral-300 rounded-md shadow-medium bg-shades-50 z-50 paragraph-small-regular ${
                                openFromBottom && 'bottom-full mb-2'
                            } ${fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'}`}>
                            <div className='w-full pt-1 mb-2 flex flex-row items-center'>
                                <SearchIcon
                                    width={28}
                                    height={28}
                                />
                                <input
                                    onChange={(e) => handleSearch(e.target.value)}
                                    placeholder={searchInputPlaceholder}
                                    className='ml-2 placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full'
                                />
                            </div>
                            {menuItems?.map((el, index) => (
                                <div
                                    key={index}
                                    className='pt-2 mb-2 cursor-pointer relative h-fit'
                                    onClick={() => handleClickMenuItem(el)}>
                                    <CheckBox
                                        optionId={index}
                                        itemPosition='item-start'
                                        labelStyle='paragraph-small-regular'
                                        label={el.label}
                                        className='mr-2'
                                        isChecked={el?.isSelected ?? false}
                                        onChange={(isSelected) => handleSelectItem(isSelected, el)}
                                    />
                                </div>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
