import React from 'react';
import { Table } from './Table';
import { TableListView } from './ListView';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function FavoriteFood() {
    const header = ['FAVORITE DISH', 'TOTAL ORDERS', 'REVENUE GENERATED'];

    const style = ['min-w-[214px] lg:w-[238px]', 'min-w-[208px] lg:w-[208px]', 'min-w-[200px] lg:w-[218px]'];

    const tableDetails = [
        ['Paneer tikka pizza', 345, '₹18,234'],
        ['Burn to hell pizza', 256, '₹9,632'],
        ['7 cheese pizza', 76, '₹4,234'],
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='w-full'>
                <div className='mb-4 md:mb-2'>
                    <span className='paragraph-large-medium text-black'>Favorite food</span>
                </div>

                {!isMobileScreen ? (
                    <Table
                        header={header}
                        style={style}
                        details={tableDetails}
                    />
                ) : (
                    <div>
                        {tableDetails.map((el, index) => (
                            <div
                                key={index}
                                className='mb-2'>
                                <TableListView
                                    details={el}
                                    type='food'
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}
