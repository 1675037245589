import React from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import Table from './Table';
import MobileListSection from './MobileListSection';

export default function ListSection(props) {
    const { billList, isListLoading, setPopupOrderId, setOrderDetails } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <Table
                    tableDetails={billList}
                    isListLoading={isListLoading}
                    setPopupOrderId={setPopupOrderId}
                    setOrderDetails={setOrderDetails}
                />
            ) : (
                <MobileListSection list={billList} />
            )}
        </>
    );
}
