import React from 'react';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function CouponCodeSection() {
    return (
        <>
            <TitleDescription
                title='Coupon code'
                description='Please enter the code of the coupon you would like to create.'
            />

            <div className='mt-4 max-w-[312px] w-full relative md:max-w-full'>
                <InputSection
                    boxHeight='12'
                    placeholder='Enter coupon code'
                    name='coupon_code'
                    rules={{ required: 'Please fill this field' }}
                />
            </div>
        </>
    );
}
