import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';

export default function Header(props) {
    const { handleClickClose, isMobileScreen, isEditSection } = props;

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium pl-1'>Back to table management</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mb-6'>
                    <div className='flex flex-col'>
                        <span className='paragraph-large-medium'>
                            {isEditSection ? 'Edit section' : 'Add new section'}
                        </span>
                        <span className='paragraph-medium-italic text-neutral-500'>
                            {isEditSection
                                ? 'Edit your existing restaurant section'
                                : 'Create a new section for your restaurant outlet'}
                        </span>
                    </div>

                    <div
                        className='cursor-pointer'
                        onClick={() => handleClickClose()}>
                        <CloseIcon />
                    </div>
                </div>
            )}
        </>
    );
}
