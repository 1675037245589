import React, { useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { IsMobileScreen } from '../../../../Constants/Constants';
import InputSection from '../TabComponent/Components/InputSection';
import { useFormContext } from 'react-hook-form';

export default function ThresholdPopup(props) {
    const { handleClickClose, title, subTitle, showThresholdPopup, setShowTabChangeWarningPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const { handleSubmit, setValue, getValues, watch, clearErrors } = useFormContext();

    const onSubmit = (data) => {
        if (showThresholdPopup?.type === 'edit') {
            setValue(
                `delivery_charge.delivery_charges[${showThresholdPopup.index}].threshold`,
                parseInt(data.deliveryChargeThreshold)
            );
            setValue(
                `delivery_charge.delivery_charges[${showThresholdPopup.index}].above_threshold`,
                parseInt(data.aboveThreshold)
            );
        } else {
            const indexToAddThreshold = watch('delivery_charge.delivery_charges')?.length ?? 0;

            setValue(
                `delivery_charge.delivery_charges[${indexToAddThreshold}].threshold`,
                parseInt(data.deliveryChargeThreshold)
            );
            setValue(
                `delivery_charge.delivery_charges[${indexToAddThreshold}].above_threshold`,
                parseInt(data.aboveThreshold)
            );
        }

        setShowTabChangeWarningPopup(true);

        handleClickClose();
    };

    useEffect(() => {
        if (showThresholdPopup?.type === 'edit') {
            setValue(
                'deliveryChargeThreshold',
                parseInt(getValues('delivery_charge.delivery_charges')[showThresholdPopup.index].threshold)
            );
            setValue(
                'aboveThreshold',
                parseInt(getValues('delivery_charge.delivery_charges')[showThresholdPopup.index].above_threshold)
            );
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md:block p-4 md:p-0 overflow-auto md:overflow-hidden'>
            <div className='max-w-[475px] w-full rounded-xl bg-shades-50 pt-6 pb-9 px-8 m-auto max-h-[969px] md:max-w-full md:rounded-none md:py-4 md:px-4 md:h-screen md:overflow-hidden'>
                {!isMobileScreen ? (
                    <div className='flex flex-row items-center justify-between mb-6'>
                        <div>
                            <h3 className='paragraph-large-medium'>{title}</h3>
                            <div className='flex flex-row items-center'>
                                <p className='paragraph-medium-italic text-neutral-500'>{subTitle}</p>
                            </div>
                        </div>
                        <span
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </span>
                    </div>
                ) : (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 px-8 md:px-0 cursor-pointer'
                        onClick={() => handleClickClose()}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to delivery charges</span>
                    </div>
                )}

                <div className='mb-4'>
                    <InputSection
                        name='deliveryChargeThreshold'
                        labelStyle='paragraph-large-medium'
                        label='Delivery charge threshold'
                        placeholder='Enter amount in rupees'
                        shadow='shadow-xSmall'
                        rules={{ required: 'Please fill this field' }}
                        maxWidth='max-w-full'
                        inputType='number'
                    />
                </div>

                <div className='mb-12'>
                    <InputSection
                        name='aboveThreshold'
                        labelStyle='paragraph-large-medium'
                        label='Charge above threshold'
                        placeholder='Enter amount in rupees'
                        shadow='shadow-xSmall'
                        rules={{ required: 'Please fill this field' }}
                        maxWidth='max-w-full'
                        inputType='number'
                    />
                </div>

                <div
                    className='sticky z-[1000] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex flex-row w-full mx-auto bg-white md:px-4'
                    onClick={handleSubmit(onSubmit)}>
                    <LargePrimaryButton
                        label={`${showThresholdPopup?.type === 'edit' ? 'Edit' : 'Add'} charges`}
                        isDefault={false}
                    />
                </div>
            </div>
        </div>
    );
}
