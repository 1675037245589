import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function InputSection(props) {
    const {
        title,
        description,
        name,
        disabled,
        placeholder,
        inputType,
        enteringLimit,
        marginBetween,
        rules,
        label,
        labelStyle,
        placeholderIcon,
        shadow,
        maxWidth,
        paddingLeft,
        prefix,
        prefixClass,
        dontShowError,
    } = props;

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const handleInputChange = (value, fieldChange) => {
        props?.handleInputChange?.(value);

        if (enteringLimit) {
            if (value > enteringLimit) {
                setError(name, { message: 'Please enter a number below 100' });
            } else {
                clearErrors(name);
                fieldChange(value);
            }
            return;
        }

        fieldChange(value);
    };

    return (
        <>
            <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                <TitleDescription
                    title={title}
                    disabled={disabled}
                    description={description}
                />
            </div>

            <div className={`w-full md:max-w-full ${maxWidth ?? 'max-w-[312px]'}`}>
                <Controller
                    render={({ field }) => (
                        <DefaultInputField
                            boxHeight='[52px]'
                            labelMarginB='pb-2'
                            placeholder={placeholder}
                            shadow={shadow ?? 'shadow-xSmall'}
                            enteredValue={(e) => {
                                handleInputChange(e, field.onChange);
                            }}
                            inputType={inputType}
                            disabled={disabled}
                            value={field.value ?? ''}
                            label={label}
                            labelStyle={labelStyle}
                            placeholderIcon={placeholderIcon}
                            paddingLeft={paddingLeft}
                            prefix={prefix}
                            prefixClass={prefixClass}
                        />
                    )}
                    name={name}
                    rules={rules ?? {}}
                    control={control}
                />
                {!dontShowError && errors?.[name] && <ErrorMessage errors={errors[name]} />}
            </div>
        </>
    );
}
