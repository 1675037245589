import React from 'react';

export default function CancelButton(props) {
    const { handleClickCancel } = props;

    return (
        <>
            <button
                className='h-12 w-full md:mr-2 paragraph-medium-medium rounded-md text-neutral-500 bg-shades-50 border border-neutral-300'
                onClick={handleClickCancel}>
                Cancel
            </button>
        </>
    );
}
