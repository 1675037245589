import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <TitleDescriptionLoader
                    className='max-w-[600px] md:max-w-full mb-2'
                    descHeight={50}
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={120}
                    boxHeight='h-[120px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={120}
                    boxHeight='h-[120px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={60}
                    boxHeight='h-[60px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={60}
                    boxHeight='h-[60px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[330px] md:max-w-full my-2'
                    height={60}
                    boxHeight='h-[60px]'
                />
            </div>
        </>
    );
}
