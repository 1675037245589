import React, { useEffect, useState } from 'react';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import TableSection from './TableSection';
import CardSection from './CardSection';
import { IsMobileScreen } from '../../../../Constants/Constants';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import { bannerCategoryType } from '../../Utils/constants';

export default function Table(props) {
    const {
        tab,
        bannerCatalogueList,
        fetchStoreBannerList,
        storeBannerList,
        fetchCatalogueList,
        setSelectedBanners,
        bannerType,
        lastPage,
        handleChangeResultPerPage,
        selectedPage,
        setSelectedPage,
        isLoading,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const handleSelection = (isSelected, bannerId) => {
        isSelected
            ? setSelectedBanners((prevState) => [...prevState, bannerId])
            : setSelectedBanners((prevState) => prevState.filter((id) => id !== bannerId));
    };

    const [list, setlist] = useState([]);

    useEffect(() => {
        const bannerCategory = bannerCategoryType.find((category) => category.url === bannerType)?.value;

        tab === 'store-banner'
            ? setlist(storeBannerList[bannerCategory])
            : setlist(bannerCatalogueList[bannerCategory]);
    }, [bannerType, bannerCatalogueList, tab, storeBannerList]);

    const handleClickUnlink = async (bannerId) => {
        try {
            const response = await APIV2.patch(`restaurants/${selectedRestaurantId}/banners/${bannerId}/un-link`, {
                outlet_ids: [selectedOutletId],
            });

            if (response.success) {
                fetchStoreBannerList();
                fetchCatalogueList();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const isMobileScreen = IsMobileScreen();

    useEffect(() => {
        setSelectedPage(1);
        // eslint-disable-next-line
    }, [tab, bannerType]);

    const tabKey = tab === 'banner-catalogue' ? 'catalogue' : 'store';

    const category = bannerCategoryType.find((el) => el.url === bannerType);

    const handlePageChange = (page) => {
        setSelectedPage(page);

        tab === 'banner-catalogue' ? fetchCatalogueList(category, page) : fetchStoreBannerList(category, page);
    };

    return (
        <>
            {!isMobileScreen ? (
                <TableSection
                    {...props}
                    list={list}
                    handleSelection={handleSelection}
                    handleClickUnlink={handleClickUnlink}
                    isLoading={isLoading}
                />
            ) : (
                <CardSection
                    {...props}
                    list={list}
                    handleSelection={handleSelection}
                    handleClickUnlink={handleClickUnlink}
                    isLoading={isLoading}
                />
            )}
            <div className='mt-4'>
                <PaginationWithNumber
                    lastPage={lastPage[tabKey][category.value]}
                    setSelectedPage={handlePageChange}
                    selectedPage={selectedPage}
                    setResultPerPage={handleChangeResultPerPage}
                />
            </div>
        </>
    );
}
