import React, { useState } from 'react';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { Tab } from '../../../Components/Tabs/Tabs';
import { ReactComponent as SearchIcon } from '../../../Assets/search.svg';
import ReservationItem from './ReservationItem';

export default function ReservationsWaitList(props) {
    const { setShowPopup } = props;

    const [reservationList, setReservationList] = useState([
        {
            date: '12th Nov 2022',
            showList: false,
            reservations: [
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
            ],
        },
        {
            date: '12th Nov 2022',
            showList: false,
            reservations: [
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
            ],
        },
        {
            date: '14th Nov 2022',
            showList: false,
            reservations: [
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
                {
                    time: '7:30 PM',
                    reservationType: 'Reservation',
                    reservationStatus: 'Not confirmed',
                    name: 'Mr. Arjun Patel',
                    mobileNumber: '(+91) 9876543231',
                    guestNumber: 12,
                },
            ],
        },
    ]);

    const statusTab = ['Waitlist', 'Reservations'];

    const [activeStatusTab, setActiveStatusTab] = useState(0);

    const handleClickStatusTab = (index) => {
        setReservationList((prevList) =>
            prevList.map((item, idx) => ({
                ...item,
                showList: index === idx ? !prevList[idx].showList : prevList[idx].showList,
            }))
        );
    };

    return (
        <>
            <div className='mb-6 xl:pr-[54px] md:pr-0'>
                <DefaultInputField
                    placeholder='Search reservation / waitlist'
                    placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                />
            </div>

            <div className='mb-6 flex flex-row items-center'>
                {statusTab.map((el, index) => (
                    <div
                        className='mr-4 mobile:mr-2'
                        onClick={() => setActiveStatusTab(index)}
                        key={index}>
                        <Tab
                            label={el}
                            isActive={activeStatusTab === index}
                            tabStyle='mobile:px-3'
                        />
                    </div>
                ))}
            </div>

            <ReservationItem
                reservationList={reservationList}
                setShowPopup={setShowPopup}
                showReservationList={(index) => handleClickStatusTab(index)}
                activeStatusTab={activeStatusTab}
            />
        </>
    );
}
