import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader, SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <TitleDescriptionLoader
                    className='max-w-[600px] md:max-w-full mb-2'
                    descHeight={50}
                />
                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />
                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <SingleLoader className='max-w-[320px] h-[50px] mt-2' />
            </div>
        </>
    );
}
