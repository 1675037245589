import React from 'react';
import DropdownSection from '../../Components/DropdownSection';
import InputSection from '../../Components/InputSection';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function DiscountUptoSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='my-4 max-w-[312px] relative md:max-w-full'>
                <DropdownSection
                    menuItems={preferenceMenuItem}
                    label='Discount upto amount preference'
                    shadow='shadow-smallDropDownShadow'
                    labelPaddingB='pb-2'
                    height='h-[52px]'
                    labelStyle='paragraph-large-medium'
                    name='bill_payments.isAddDiscountUptoAmount'
                    placeholder='Please select preference'
                    disabled={watch('bill_payments.discountType')?.value !== 'percentage_charge'}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('bill_payments.discountType')?.value === 'percentage_charge')
                                return 'Please select preference';
                        },
                    }}
                    clearErrorName={['bill_payments.discount_upto_amount']}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.bill_payments?.isAddDiscountUptoAmount && (
                    <ErrorMessage errors={errors?.bill_payments?.isAddDiscountUptoAmount} />
                )}
            </div>

            <InputSection
                placeholder='Please add discount'
                name='bill_payments.discount_upto_amount'
                label='Discount upto amount'
                labelStyle={`paragraph-large-medium ${
                    !watch('bill_payments.isAddDiscountUptoAmount')?.value && 'text-neutral-300'
                }`}
                shadow='shadow-smallDropDownShadow'
                disabled={!watch('bill_payments.isAddDiscountUptoAmount')?.value}
                inputType='number'
                rules={{
                    validate: (value) => {
                        if (value) return true;
                        if (watch('bill_payments.isAddDiscountUptoAmount')?.value)
                            return 'Please enter discount upto amount';
                    },
                }}
                handleInputChange={() => setShowTabChangeWarningPopup(true)}
            />
            {errors?.bill_payments?.discount_upto_amount && (
                <ErrorMessage errors={errors?.bill_payments?.discount_upto_amount} />
            )}
        </>
    );
}
