import React, { useState } from 'react';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../../Components/Buttons/Button';
import UploadImagePopup from '../../../../../../Components/UploadImagePopup/UploadImagePopup';
import SelectImageFromLibraryPopup from '../../../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import { useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import _ from 'lodash';

export default function VariantImageNameForm(props) {
    const { imageDetailsName } = props;

    const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);

    const [showSelectFromLibraryPopup, setShowSelectFromLibraryPopup] = useState(false);

    const { setValue, watch } = useFormContext();

    const formateImageDetails = (imageDetails) => {
        const img = new Image();

        img.src = imageDetails.image_url;

        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;

            const calculatedWidth = 150 * aspectRatio;

            setValue(imageDetailsName, imageDetails);

            setValue(`${imageDetailsName}.size`, { width: calculatedWidth, height: 150 });
        };
    };

    return (
        <>
            {!!watch('pricing.variantPrice')?.length && (
                <>
                    <TitleDescription
                        className='mb-4'
                        title='Dish image'
                        description='Select a dish image that will be displayed within the category in the mobile application and the POS.'
                    />
                    {!_.isEmpty(watch(imageDetailsName)) ? (
                        <div className='flex mb-6 gap-4 items-center'>
                            <img
                                src={watch(imageDetailsName)?.image_url}
                                alt=''
                                height={watch(imageDetailsName)?.size?.height}
                                width={watch(imageDetailsName)?.size?.width}
                            />
                            <div className='justify-center items-center'>
                                <div
                                    className='mb-2'
                                    onClick={() => setShowUploadImagePopup((prevState) => !prevState)}>
                                    <LargeTertiaryButton
                                        label='Replace image'
                                        isDefault={false}
                                    />
                                </div>

                                <div
                                    className='mb-2'
                                    onClick={() => setShowSelectFromLibraryPopup((prevState) => !prevState)}>
                                    <LargePrimaryButton
                                        label='Select from library'
                                        isDefault={false}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-row paragraph-medium-regular mb-4 md:block'>
                            <button
                                className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full mr-2 md:mr-0 md:mb-2 md:block'
                                onClick={() => setShowUploadImagePopup((prevState) => !prevState)}>
                                Upload an image
                            </button>
                            <button
                                className='justify-center h-12 border-neutral-300 rounded-md border max-w-[197px] w-full ml-2 md:ml-0 md:block'
                                onClick={() => setShowSelectFromLibraryPopup(true)}>
                                Select from library
                            </button>
                        </div>
                    )}
                </>
            )}

            {showUploadImagePopup && (
                <UploadImagePopup
                    handleClickClose={() => setShowUploadImagePopup((prevState) => !prevState)}
                    backToPage='add dish'
                    aspectRatio={0.938} // 1000 - width  1066 - height
                    setUploadedImageDetails={(imageDetails) => formateImageDetails(imageDetails)}
                    selectedType={{ label: 'Items', value: 'items' }}
                />
            )}
            {showSelectFromLibraryPopup && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibraryPopup(false)}
                    handleSelectImage={(imageDetails) => formateImageDetails(imageDetails)}
                />
            )}
        </>
    );
}
