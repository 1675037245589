import React from 'react';
import NoVariantsPricingTable from './NoVariantsPricingTable';
import { useSelector } from 'react-redux';

export default function PricingTable(props) {
    const { isEditPrice, details, setToEditTableDetails, id } = props;

    const selectedLanguage = useSelector((state) => state.langauge.languageId);

    return (
        <>
            <div className='mb-4'>
                <p className='paragraph-large-medium'>
                    Variant
                    <span className='text-neutral-500 paragraph-large-italic ml-1'>
                        ({details?.title[selectedLanguage]})
                    </span>
                </p>
            </div>

            <div className='mb-6'>
                <NoVariantsPricingTable
                    id={id}
                    isEditPrice={isEditPrice}
                    setToEditTableDetails={setToEditTableDetails}
                />
            </div>
        </>
    );
}
