import React from 'react';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[500px] md:max-w-full my-2'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[320px] md:max-w-full my-2'
                    height={52}
                    boxHeight='h-[52px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={120}
                    boxHeight='h-[120px]'
                />

                <DropdownBoxLoader
                    className='max-w-[160px] md:max-w-full mt-5'
                    height={30}
                    boxHeight='h-[30px]'
                />
                <DropdownBoxLoader
                    className='max-w-[636px] md:max-w-full my-2'
                    height={120}
                    boxHeight='h-[120px]'
                />
            </div>
        </>
    );
}
