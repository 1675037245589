import React from 'react';
import { DropdownBoxLoader, SingleLoader } from '../../../../Components/ContentLoader/DropdownLoader';
import TitleDescriptionLoader from '../../../../Components/ContentLoader/TitleDescriptionLoader';
import ContentLoader from 'react-content-loader';
import OrderingModeLoader from '../TabComponent/OrderingMode/Components/OrderingModeLoader';

export default function Loader() {
    return (
        <>
            <div className='flex flex-row mt-4'>
                <div className='pr-6 mr-5'>
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                    <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                </div>
                <div className='flex flex-row max-w-full w-full mt-4'>
                    <OrderingModeLoader />
                </div>
            </div>
        </>
    );
}
