import React from 'react';
import InputSection from '../../Components/InputSection';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function PaytmGatewayDetails(props) {
    const { title, tagLine, relatedField, mainKey } = props;

    const {
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='mb-6'>
                <TitleDescription
                    title={title}
                    description={tagLine}
                />
            </div>

            {relatedField.map((el, index) => {
                const provider = el.id.split('.')[2];

                const error = errors?.payment_settings?.[mainKey]?.[provider];

                return (
                    <div
                        className='max-w-[312px] w-full mb-4 md:max-w-full last:mb-2'
                        key={index}>
                        <InputSection
                            name={el.id}
                            labelMarginB='mb-2'
                            label={el.label}
                            labelStyle='paragraph-medium-italic text-neutral-500'
                            placeholder={el.placeholder}
                            shadow='shadow-smallDropDownShadow'
                            rules={el.isRequired ? { required: 'Please fill this field' } : {}}
                        />
                        {error && <ErrorMessage errors={error} />}
                    </div>
                );
            })}
        </>
    );
}
