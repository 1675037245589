import React from 'react';
import ListViewMediaCard from './ListViewMediaCard';
import { CheckBoxWithoutLabels } from '../../../../../Components/FormControl/FormControls';

export default function MobileViewListSection(props) {
    const { list, isSelectionEnable, selectedMediaList, setSelectedMediaList, setUserDetail, setShowUploadPopup } =
        props;

    const handleSelection = (id) => {
        const updatedList = selectedMediaList.includes(id)
            ? selectedMediaList.filter((item) => item !== id)
            : [...selectedMediaList, id];

        setSelectedMediaList(updatedList);
    };

    return (
        <>
            {list.map((el, index) => (
                <div
                    key={index}
                    className='py-1 flex flex-row items-center gap-2'>
                    {isSelectionEnable && (
                        <CheckBoxWithoutLabels
                            id={el.id}
                            onChange={() => handleSelection(el.id)}
                            isChecked={selectedMediaList.includes(el.id)}
                        />
                    )}

                    <ListViewMediaCard
                        item={el}
                        setUserDetail={setUserDetail}
                        setShowUploadPopup={setShowUploadPopup}
                    />
                </div>
            ))}
        </>
    );
}
