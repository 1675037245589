import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Tabs from './Tabs';
import WarningPopup from '../../Components/WarningPopup';
import { useNavigate, useParams } from 'react-router-dom';

import {
    OrderingMode,
    Appearance,
    Address,
    BillSetting,
    BillPayment,
    Contacts,
    ServiceRadius,
    PreparationTime,
    DeliveryCharges,
    PaymentSetting,
    PaymentMethod,
    Currency,
    Timing,
    RiderSetting,
    Taxes,
    FoodLicense,
    OtherSetting,
    OtherCharges,
    ThirdPartyPOS,
    CodLimit,
    EnableCash,
    MOV,
} from '../TabComponent';

export default function StoreSettingParentComponent(props) {
    const { fetchSetting, defaultValues, isFormLoading } = props;

    const [showWarningPopup, setShowWarningPopup] = useState({ status: false });

    const navigate = useNavigate();

    const { tab } = useParams();

    const methods = useForm({ defaultValues: { ...defaultValues, isFormLoading: isFormLoading } });

    const [showTabChangeWarningPopup, setShowTabChangeWarningPopup] = useState(false);

    const {
        setValue,
        getValues,
        reset,
        formState: { isDirty },
    } = methods;

    const componentMapping = {
        'ordering-mode': OrderingMode,
        'appearance': Appearance,
        'address': Address,
        'bill-settings': BillSetting,
        'bill-payments': BillPayment,
        'contacts': Contacts,
        'service-radius': ServiceRadius,
        'preparation-time': PreparationTime,
        'delivery-charges': DeliveryCharges,
        'payment-setting': PaymentSetting,
        'payment-method': PaymentMethod,
        'currency': Currency,
        'timing': Timing,
        'rider-setting': RiderSetting,
        'taxes': Taxes,
        'food-license': FoodLicense,
        'other-setting': OtherSetting,
        'other-charges': OtherCharges,
        'third-party-POS': ThirdPartyPOS,
        'cod-limit': CodLimit,
        'enable-cash': EnableCash,
        'mov': MOV,
    };

    const handleDiscardForm = () => {
        reset();
    };

    const ComponentToRender = componentMapping[tab];

    return (
        <>
            <FormProvider {...methods}>
                <div className='flex flex-row md:block'>
                    <Tabs
                        setShowWarningPopup={setShowWarningPopup}
                        showTabChangeWarningPopup={showTabChangeWarningPopup}
                    />

                    <div className='w-full'>
                        <ComponentToRender
                            fetchSetting={fetchSetting}
                            handleDiscardForm={handleDiscardForm}
                            setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            showTabChangeWarningPopup={showTabChangeWarningPopup}
                        />
                    </div>
                </div>
            </FormProvider>

            {showWarningPopup.status && (
                <WarningPopup
                    handleClickClose={() => {
                        setShowWarningPopup({ status: false });
                    }}
                    handleClickChange={() => {
                        reset();
                        fetchSetting();
                        navigate(showWarningPopup.route);
                        setShowWarningPopup({ status: false });
                        setShowTabChangeWarningPopup(false);
                    }}
                    isDirty={isDirty}
                    showTabChangeWarningPopup={showTabChangeWarningPopup}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />
            )}
        </>
    );
}
