import React, { useRef } from 'react';
import Header from './Components/Header';
import OrderSummery from './Components/OrderSummery';
import OrderedDishes from './Components/OrderedDishes';
import OtherDetails from './Components/OtherDetails';
import OrderInformationPopupLoader from '../ContentLoader/OrderInformationPopupLoader';
import useOutsideClick from '../../Helper/Hooks/useOutsideClick';
import OrderSummaryBillPayment from './Components/OrderSummaryBillPayment';

export default function OrderDetailsPopup(props) {
    const {
        code,
        orderDetails = [],
        isPaid,
        page,
        handleClickClose,
        isDelivered,
        otherAmount,
        isRefundInitiated,
        cashbacks,
        feedbacks,
        isPopupLoading,
        netTotal,
        extraCharges,
        orderedDishes,
        isRiderFeedback,
        setShowRefundPopup,
        refundDetails,
        handleClickPrint,
        rnnCode,
        handleConfirmDeletePopup,
        handleOfferCreate,
        handleClickSelectOffer,
        showOfferpage,
        setCreateOfferDetails,
        offerId,
        toggleCustomerOfferPopup,
        fetchOfferDetails,
        grossTotal,
        selectedLangaugeId,
    } = props;

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClickClose);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='bg-white w-[652px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'
                    ref={popupRef}>
                    {isPopupLoading ? (
                        <div ref={popupRef}>
                            <OrderInformationPopupLoader />
                        </div>
                    ) : (
                        <div>
                            <Header
                                code={code}
                                handleClickClose={handleClickClose}
                                isDelivered={isDelivered}
                                isRefundInitiated={isRefundInitiated}
                                page={page}
                            />

                            {page === 'billPayment' ? (
                                <OrderSummaryBillPayment
                                    orderDetails={orderDetails}
                                    otherAmount={otherAmount}
                                />
                            ) : (
                                <OrderSummery
                                    orderDetails={orderDetails}
                                    otherAmount={otherAmount}
                                />
                            )}

                            <OrderedDishes
                                isPaid={isPaid}
                                page={page}
                                otherAmount={otherAmount}
                                netTotal={netTotal}
                                orderedDishes={orderedDishes}
                                extraCharges={extraCharges}
                                selectedLangaugeId={selectedLangaugeId}
                            />

                            <OtherDetails
                                handleConfirmDeletePopup={handleConfirmDeletePopup}
                                page={page}
                                cashbacks={cashbacks}
                                feedbacks={feedbacks}
                                isRiderFeedback={isRiderFeedback}
                                setShowRefundPopup={setShowRefundPopup}
                                refundDetails={refundDetails}
                                handleClickPrint={handleClickPrint}
                                rnnCode={rnnCode}
                                handleOfferCreate={handleOfferCreate}
                                handleClickSelectOffer={handleClickSelectOffer}
                                showOfferpage={showOfferpage}
                                setCreateOfferDetails={setCreateOfferDetails}
                                offerId={offerId}
                                toggleCustomerOfferPopup={toggleCustomerOfferPopup}
                                fetchOfferDetails={fetchOfferDetails}
                                grossTotal={grossTotal}
                                selectedLangaugeId={selectedLangaugeId}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
