import React from 'react';
import { SingleLoader } from '../ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='flex flex-row'>
                <SingleLoader className='max-w-[668px] h-[375px]' />
                <div className='pl-4 w-full max-w-[375px]'>
                    <SingleLoader className='max-w-[375px] h-[68px] mt-4' />
                    <SingleLoader className='max-w-[375px] h-[68px] mt-4' />
                    <SingleLoader className='max-w-[375px] h-[68px] mt-4' />
                    <SingleLoader className='max-w-[375px] h-[68px] mt-4' />
                    <SingleLoader className='max-w-[375px] h-[68px] mt-4 mb-12' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4' />
                    <SingleLoader className='max-w-[375px] h-6 mt-4 mb-12' />

                    <div className='flex flow-row gap-4'>
                        <SingleLoader className='max-w-[183px] h-12 mt-4' />
                        <SingleLoader className='max-w-[183px] h-12 mt-4' />
                    </div>
                </div>
            </div>
        </>
    );
}
