import React, { useEffect, useState } from 'react';
import { ReactComponent as Close } from '../../Assets/close.svg';
import { LargePrimaryButton } from '../Buttons/Button';
import DropdownWithValueLabel from '../DropDown/DropdownWithValueLabel';
import { DefaultInputField } from '../InputField/InputField';

export default function SelectOfferPage(props) {
    const { title, tagLine, handleClickClose, module, setCreateOfferDetails, grossTotal, handleOfferCreate } = props;

    const couponCodes = ['TRYNEW120', 'TRYNEW50', 'WELCOME20', 'BOGO2022'];

    const offerTypes = [
        { label: 'Flat discount', value: 'flat_discount' },
        { label: 'Flat discount', value: 'flat_discount' },
        { label: 'Percentage discount', value: 'percentage' },
        { label: 'BOGO2022', value: 'BOGO2022' },
    ];

    const [selectedFlatDiscountType, setSelectedFlatDiscountType] = useState('full');
    console.log('selectedFlatDiscountType <====>', selectedFlatDiscountType);
    console.log('grossTotal <====>', grossTotal);
    const [isCreateOfferDisabled, setIsCreateOfferDisabled] = useState(false);
    const [exceedsGrossTotal, setExceedsGrossTotal] = useState(false);

    const flatDiscountTypes = [
        { label: 'Full', value: 'full' },
        { label: 'Partial', value: 'partial' },
    ];

    const handleFlatDiscountTypeChange = (selection) => {
        setSelectedFlatDiscountType(selection.value);
        setIsCreateOfferDisabled(selection.value === 'partial');
    };

    useEffect(() => {
        if (selectedFlatDiscountType === 'full') {
            setCreateOfferDetails((prevState) => ({
                ...prevState,
                gross_total: grossTotal,
            }));
            setExceedsGrossTotal(false);
        }
    }, [selectedFlatDiscountType, grossTotal]);

    const handlePartialAmountChange = (value) => {
        setCreateOfferDetails((prevState) => ({
            ...prevState,
            partial_amount: value,
        }));

        if (value === '' || parseFloat(value) > grossTotal) {
            setIsCreateOfferDisabled(true);
            setExceedsGrossTotal(true);
        } else {
            setIsCreateOfferDisabled(false);
            setExceedsGrossTotal(false);
        }
    };

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 p-4 overflow-auto flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='flex flex-row justify-between items-start mb-6 md:mb-3'>
                        <div>
                            <span className='paragraph-large-medium'>{title ?? 'Select offer'}</span>
                            <div className='flex flex-row items-center'>
                                <span className='paragraph-medium-italic text-neutral-500'>
                                    {tagLine ?? 'Select an offer to send it to the customer'}
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => handleClickClose()}
                            className='cursor-pointer'>
                            <Close />
                        </div>
                    </div>
                    {module === 'order-rating' || module === 'customer-information' ? (
                        <div className='mb-12 md:mb-3'>
                            <div className='relative mb-4 md:mb-3'>
                                <DefaultInputField
                                    label={'Offer Type'}
                                    value={'Flat Discount'}
                                />
                            </div>
                            <div className='relative mb-4 md:mb-3'>
                                <DropdownWithValueLabel
                                    label='Select Flat discount type'
                                    placeholder='Select flat discount type'
                                    menuItems={flatDiscountTypes}
                                    selectedItem={flatDiscountTypes.find(
                                        (item) => item.value === selectedFlatDiscountType
                                    )}
                                    setSelected={handleFlatDiscountTypeChange}
                                />
                            </div>
                            {selectedFlatDiscountType === 'partial' && (
                                <div className='mt-4'>
                                    <DefaultInputField
                                        label='(Enter amount in rupees)'
                                        inputType={'number'}
                                        enteredValue={handlePartialAmountChange}
                                    />
                                    {exceedsGrossTotal && (
                                        <p className='text-red-500 text-sm mt-1'>
                                            The entered amount should not exceed the gross total.
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='mb-12 md:mb-3'>
                            <div className='relative mb-4 md:mb-3'>
                                <DropdownWithValueLabel
                                    label='Select offer type'
                                    placeholder='Select offer type'
                                    menuItems={offerTypes}
                                />
                            </div>
                            <div className='relative'>
                                <DropdownWithValueLabel
                                    label='Select coupon code'
                                    placeholder='Select coupon code'
                                    menuItems={couponCodes}
                                />
                            </div>
                        </div>
                    )}

                    <div className='items-center'>
                        <div onClick={handleOfferCreate}>
                            <LargePrimaryButton
                                label='Send offer'
                                disabled={isCreateOfferDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
