import React from 'react';

export const BlankComponent = (props) => {
    const { previewText, styles } = props;

    return (
        <div className={`bg-white text-center justify-center py-2 h-[20vh] flex ${styles}`}>
            <span className='text-xl font-bold my-auto'>{previewText ?? 'No preview at this moment'}</span>
        </div>
    );
};

export const BlankComponentTable = (props) => {
    const { previewText, colSpan } = props;

    return (
        <tbody>
            <tr>
                <td colSpan={colSpan}>
                    <BlankComponent previewText={previewText} />
                </td>
            </tr>
        </tbody>
    );
};
