import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import pizza1 from '../../Assets/MediaLibrary/pizza1.png';
import pizza2 from '../../Assets/MediaLibrary/pizza2.png';
import pizza3 from '../../Assets/MediaLibrary/pizza3.png';
import pizza4 from '../../Assets/MediaLibrary/pizza4.png';
import pizza5 from '../../Assets/MediaLibrary/pizza5.png';
import pizza6 from '../../Assets/MediaLibrary/pizza6.png';
import pizza7 from '../../Assets/MediaLibrary/pizza7.png';
import pizza8 from '../../Assets/MediaLibrary/pizza8.png';
import EditCategoryPopup from './Components/EditCategoryPopup';
import { IsMobileScreen } from '../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import Header from './Components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import APIV5 from '../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { Categories, Dishes } from './Components/Cards';
import {
    fetchAddOnItemsList,
    fetchAddOnList,
    fetchCategoryItemsList,
    fetchCategoryList,
    getFoodType,
} from './Components/Function';
import { useRef } from 'react';
import { useCallback } from 'react';

import SubCategoryCard from './Components/SubCategoryCard';
import ListLoader from './Components/ListLoader';
import { FormProvider, useForm } from 'react-hook-form';

export default function ItemAvailability() {
    const { tab } = useParams();
    const [showItemAvailability, setShowItemAvailability] = useState(tab === 'dish' ? 0 : 1);

    const methods = useForm();
    const { watch } = methods;

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    const navigate = useNavigate();

    const handleClickAvailabilityTab = (tab) => {
        if (tab === 'dish') {
            setShowItemAvailability(0);
        } else if (tab === 'add-on') {
            setShowItemAvailability(1);
        }
        navigate(`/availability/${tab}`);
    };

    const [showEditCategory, setShowEditCategory] = useState(false);

    const [editType, setEditType] = useState('left');

    const [type, setType] = useState(null);

    const handleChangeAvailability = (type) => {
        setShowEditCategory(!showEditCategory);
        setEditType(type);
        setType(type);
    };

    const [showDishMobile, setShowDishMobile] = useState(false);

    const handleClickCategory = (categoryName) => {
        setShowDishMobile(!showDishMobile);
    };

    const screenHeight = useWindowSize().height;
    const isMobileScreen = IsMobileScreen();

    const screenSize = useWindowSize();

    const [selectedCategoryId, setSelectedCategoryId] = useState([]);

    const [selectedCategoryDish, setSelectedCategoryDish] = useState({ category: '', dish: '' });

    const [clickedCategory, setClickedCategory] = useState(null);

    const [showSubCategory, setShowSubCategory] = useState(null);

    const [showVariants, setShowVariants] = useState(null);
    const [variantsList, setvariantsList] = useState([]);

    const [allCategoryDish, setAllCategoryDish] = useState([]);

    const [selectedDishId, setSelectedDishId] = useState([]);

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [keyword, setKeyword] = useState(null);

    //https://api.staging.fab.delivery/api/v5/restaurants/32/outlets/40/categories/stock

    const [delayedKeyword, setDelayedKeyword] = useState(null);

    const [categoriesList, setCategoriesList] = useState([]);
    const [subCategoryDishList, setSubCategoryDishList] = useState([]);

    const [dishList, setDishList] = useState([]);
    const [filteredDishList, setFilteredDishList] = useState([]);

    const initialPaginationState = { page: 1, per_page: 10, has_more_pages: null };
    const initialItemPaginationState = { page: 1, per_page: 10, has_more_pages: null };

    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isItemLoading, setIsItemLoading] = useState(false);

    const [paginationState, setPaginationState] = useState(initialPaginationState);
    const [itemPaginationState, setItemPaginationState] = useState(initialItemPaginationState);

    const [itemOrCategoryUpdated, setItemOrCategoryUpdated] = useState(false);

    const [dishesAddOnsOff, setDishesAddOnsOff] = useState({ 'dishes': null, 'add-ons': null });

    const [selectedOption, setSelectedOption] = useState('radioBox01');

    useEffect(() => {
        setCategoriesList([]);
        setPaginationState(initialPaginationState);
        setItemPaginationState(initialItemPaginationState);
    }, [tab]);

    const [filteredCategoriesList, setFilteredCategoriesList] = useState([]);

    useEffect(() => {
        if (selectedFilter === 'out_of_stock') {
            const filtered = categoriesList.filter(
                (category) =>
                    category.total_item_ordering_modes > category.in_stock_item_ordering_mode_count ||
                    (category.total_item_ordering_modes === 0 && category.in_stock_item_ordering_mode_count === 0)
            );
            setFilteredCategoriesList(filtered);
        } else {
            setFilteredCategoriesList(categoriesList);
        }
    }, [selectedFilter, categoriesList]);

    const fetchCategory = useCallback(
        async (params) => {
            try {
                setIsCategoryLoading(true);

                let response;
                if (tab === 'dish') {
                    response = await fetchCategoryList(selectedRestaurantId, selectedOutletId, params);
                } else if (tab === 'add-on') {
                    response = await fetchAddOnList(selectedRestaurantId, selectedOutletId, params);
                } else {
                    throw new Error('Invalid tab');
                }

                if (params.page === 1) {
                    setCategoriesList(response?.data);
                } else {
                    setCategoriesList((prevState) => [...prevState, ...response?.data]);
                }

                setPaginationState((prevState) => ({
                    ...prevState,
                    has_more_pages: response.has_more_pages,
                    last_page: response.last_page,
                }));

                params.page === 1 &&
                    setSelectedCategoryDish((prevState) => ({
                        ...prevState,
                        category: response?.data?.[0],
                        subCategory: response?.data[0]?.sub_categories,
                    }));

                setClickedCategory({ id: response?.data?.[0]?.id, type: 'category' });

                setIsCategoryLoading(false);
            } catch (error) {
                console.log('error : ', error);
                setIsCategoryLoading(false);
            }
        },
        [selectedRestaurantId, selectedOutletId, tab]
    );

    const fetchItemsList = async (params) => {
        try {
            setIsItemLoading(true);
            let response;
            if (tab === 'dish') {
                const hasDish = checkDishExist(params);

                if (hasDish) return;
                response = await fetchCategoryItemsList(
                    selectedRestaurantId,
                    selectedOutletId,
                    selectedCategoryDish.category.id,
                    params
                );
            } else if (tab === 'add-on') {
                const hasDish = checkDishExist(params);

                if (hasDish) return;
                response = await fetchAddOnItemsList(
                    selectedRestaurantId,
                    selectedOutletId,
                    selectedCategoryDish.category.id,
                    params
                );
            } else {
                throw new Error('Invalid tab');
            }

            setDishData(params.page, tab === 'dish' ? response?.items?.data : response?.choices?.data);
            setItemPaginationState((prevState) => ({
                ...prevState,
                has_more_pages: tab === 'dish' ? response?.items?.has_more_pages : response?.choices?.has_more_pages,
                last_page: tab === 'dish' ? response?.items?.last_page : response?.choices?.last_page,
            }));
            setIsItemLoading(false);
        } catch (error) {
            console.log('error <====>', error);
            setIsItemLoading(false);
        }
    };

    useEffect(() => {
        if (selectedFilter === 'out_of_stock') {
            const filtered = dishList.filter(
                (dish) =>
                    dish.total_ordering_modes > dish.in_stock_ordering_mode ||
                    (dish.total_ordering_modes === 0 && dish.in_stock_ordering_mode === 0)
            );
            setFilteredDishList(filtered);
        } else {
            setFilteredDishList(dishList);
        }
    }, [selectedFilter, dishList]);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setDelayedKeyword(keyword);
        }, 1000);

        return () => {
            clearTimeout(delayTimer);
        };
    }, [keyword]);

    useEffect(() => {
        const params = { ...paginationState };
        if (delayedKeyword) {
            params.keyword = delayedKeyword;
        }
        fetchCategory(params);
    }, [fetchCategory, delayedKeyword, itemOrCategoryUpdated]);

    const fetchOffItemsAndAddOns = async () => {
        try {
            const response = await APIV5.get(
                `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/items-off`
            );
            setDishesAddOnsOff((prevState) => ({
                ...prevState,
                'dishes': response.items_off,
                'add-ons': response.addons_off,
            }));
        } catch (error) {
            console.log('error <====>', error);
        }
    };
    useEffect(() => {
        fetchOffItemsAndAddOns();
    }, [selectedOutletId, selectedRestaurantId, itemOrCategoryUpdated]);

    useEffect(() => {
        if (selectedCategoryDish?.category?.id) {
            fetchItemsList(initialPaginationState);
        }
    }, [selectedCategoryDish?.category, itemOrCategoryUpdated]);

    const handleCategorySelection = (isChecked, category) => {
        let selectedCategoryState = isChecked
            ? [...selectedCategoryId, category.id]
            : selectedCategoryId.filter((el) => el !== category.id);

        if (category.sub_categories.length && !isChecked) {
            const subCategoryIds = category.sub_categories.map((el) => el.id);
            selectedCategoryState = selectedCategoryState.filter((el) => !subCategoryIds.includes(el));
        }

        setSelectedCategoryId(selectedCategoryState);
    };

    const handleClickCategoryCard = (category) => {
        setSelectedCategoryDish((prevState) => ({
            ...prevState,
            category: category,
            subCategory: category.sub_categories,
            ...(category.sub_categories?.length ? {} : { subCategoryId: null }),
        }));
        !category.sub_categories?.length && setShowSubCategory(null);
        setClickedCategory({ id: category.category_id, type: 'category' });
    };

    const containerCategoryRef = useRef(null);

    const containerItemref = useRef(null);

    const handleCategoryScroll = useCallback(() => {
        const container = containerCategoryRef.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (scrollTop + clientHeight === scrollHeight && paginationState.last_page !== paginationState.page) {
                fetchCategory({ ...paginationState, page: paginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [paginationState]);

    const handleItemScroll = useCallback(() => {
        const container = containerItemref.current;

        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;

            if (
                scrollTop + clientHeight === scrollHeight &&
                itemPaginationState.last_page !== itemPaginationState.page
            ) {
                fetchItemsList({ ...itemPaginationState, page: itemPaginationState.page + 1 });

                setPaginationState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
            }
        }
    }, [itemPaginationState]);

    const setDishData = (page, dishData) => {
        if (page === 1) {
            setDishList(dishData);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.id === selectedCategoryDish.category.id) {
                        return { ...el, dish: dishData };
                    }
                    return el;
                })
            );
        } else {
            setDishList((prevState) => [...prevState, ...dishData]);

            setAllCategoryDish((prevState) =>
                prevState.map((el) => {
                    if (el.id === selectedCategoryDish.category.id) {
                        return { ...el, dish: [...el.dish, ...dishData] };
                    }
                    return el;
                })
            );
        }

        page === 1 && setSelectedCategoryDish((prevState) => ({ ...prevState, dish: dishData?.[0]?.id }));
    };

    const [isCategoryDataFetched, setIsCategoryDataFetched] = useState(false);

    const checkDishExist = () => {
        const currentDish = allCategoryDish.find((el) => el.id === selectedCategoryDish.category.id);

        setDishList([]);

        const hasDish = !!currentDish?.dish?.length;

        hasDish && setDishList(currentDish.dish);

        if (selectedCategoryDish?.category?.total_item_ordering_modes > currentDish?.dish?.length && hasDish) {
            return false;
        }

        return hasDish;
    };

    const getAllSubCategory = async (category) => {
        let dishList = [];

        const hasDish = checkDishExist(category);

        if (hasDish) {
            return;
        }

        for (let index = 0; index < Math.ceil(category.total_item_ordering_modes / 10); index++) {
            const dish = await fetchCategoryItemsList(selectedRestaurantId, selectedOutletId, category?.id, {
                page: index + 1,
                per_page: 10,
            });

            dishList.push(...dish?.items?.data);
        }

        setAllCategoryDish((prevState) =>
            prevState.map((el) => {
                if (el.id === category.parent_id) {
                    return {
                        ...el,
                        sub_categories: el.sub_categories.map((subCategoryEl) => {
                            if (subCategoryEl.id === category.parent_id) {
                                return { ...subCategoryEl, dish: dishList };
                            } else {
                                return subCategoryEl;
                            }
                        }),
                    };
                }

                return el;
            })
        );

        setSubCategoryDishList((prevState) => [
            ...prevState,
            { category: { name: category.internal_name, id: category.id }, dish: dishList },
        ]);
    };

    useEffect(() => {
        if (!!selectedCategoryDish?.subCategory?.length) {
            setIsItemLoading(true);

            setSubCategoryDishList([]);

            const getAllSubCategoryPromises = selectedCategoryDish?.subCategory.map((el) => getAllSubCategory(el));

            Promise.all(getAllSubCategoryPromises)
                .then(() => {
                    setIsItemLoading(false);
                })
                .catch((error) => {
                    console.error('Error while fetching subcategories:', error);
                });
        }
    }, [selectedCategoryDish.category.id]);

    const [trueSwitchStates, setTrueSwitchStates] = useState([]);
    const [falseSwitchStates, setFalseSwitchStates] = useState([]);
    const [hidden, setHidden] = useState(false);

    const handleSaveSwitchStates = (switchStates) => {
        const trueStates = [];
        const falseStates = [];

        for (const [key, value] of Object.entries(switchStates)) {
            if (value) {
                trueStates.push(key);
            } else {
                falseStates.push(key);
            }
        }

        setTrueSwitchStates(trueStates);
        setFalseSwitchStates(falseStates);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${formatDate(date)} ${hours}:${minutes}:${seconds}`;
    };

    // Function to get current time and add 2 hours
    const getCurrentTimePlusTwoHours = () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 2);
        return formatTime(currentDate);
    };

    // Function to get current time and add 4 hours
    const getCurrentTimePlusFourHours = () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 4);
        return formatTime(currentDate);
    };

    const addBusinessDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        const dayOfWeek = result.getDay();
        if (dayOfWeek === 0) {
            // Sunday
            result.setDate(result.getDate() + 1); // Move to Monday
        } else if (dayOfWeek === 6) {
            // Saturday
            result.setDate(result.getDate() + 2); // Move to Monday
        }
        return result;
    };

    const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    // Function to get next business day with time
    const getNextBusinessDay = () => {
        const currentDate = new Date();
        const nextBusinessDay = addBusinessDays(currentDate, 1);
        return formatDateTime(nextBusinessDay);
    };

    const [currentTimePlusTwoHours, setCurrentTimePlusTwoHours] = useState(null);
    const [currentTimePlusFourHours, setCurrentTimePlusFourHours] = useState(null);
    const [nextBusinessDateTime, setNextBusinessDateTime] = useState(null);

    useEffect(() => {
        setCurrentTimePlusTwoHours(getCurrentTimePlusTwoHours());
        setCurrentTimePlusFourHours(getCurrentTimePlusFourHours());
        setNextBusinessDateTime(getNextBusinessDay());
    }, [selectedOption === 'radioBox01']);

    const [scheduleTimeType, setScheduleTimeType] = useState(watch('schedule_time_type'));

    const convertDateFormat = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const convertedDate = convertDateFormat(watch('schedule_time_type')?.value);

        setScheduleTimeType((prevState) => ({
            ...prevState,
            value: convertedDate,
        }));
    }, [watch('schedule_time_type')]);

    const [scheduleSelectedTime, setScheduleSelectedTime] = useState(watch('schedule_selected_time'));

    const convertTimeFormat = (timeString) => {
        if (!timeString) return ''; // Return an empty string if timeString is undefined
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = String(parseInt(hours, 10) + 12);
        }

        return `${hours}:${minutes}:00`;
    };

    useEffect(() => {
        const convertedTime = convertTimeFormat(watch('schedule_selected_time')?.label);
        setScheduleSelectedTime((prevState) => ({
            ...prevState,
            value: convertedTime,
        }));
    }, [watch('schedule_selected_time')]);

    const handleCategoryItemEdit = async () => {
        const nextAvailableTime = `${scheduleTimeType.value} ${scheduleSelectedTime.value}`;
        const getNextAvailableTime = () => {
            switch (selectedOption) {
                case 'radioBox01':
                    return currentTimePlusTwoHours;
                case 'radioBox02':
                    return currentTimePlusFourHours;
                case 'radioBox03':
                    return nextBusinessDateTime;
                case 'radioBox04':
                    return nextAvailableTime;
                default:
                    return null;
            }
        };

        const updateStock = async (endpoint, data) => {
            try {
                const response = await APIV5.patch(
                    `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/${endpoint}`,
                    data
                );
                if (response.success) {
                    setShowEditCategory(false);
                    setItemOrCategoryUpdated(!itemOrCategoryUpdated);
                }
            } catch (error) {
                console.log('error ==>', error);
            }
        };

        if (tab === 'dish') {
            if (type === 'item' && selectedCategoryDish?.dish) {
                const itemsData = {
                    items: [
                        {
                            item_id: selectedCategoryDish.dish,
                            available_ordering_mode: trueSwitchStates,
                            unavailable_ordering_mode: falseSwitchStates,
                            next_available_time: getNextAvailableTime(),
                            hidden: hidden,
                        },
                    ],
                };
                await updateStock('items/update-stock', itemsData);
            } else if (type === 'category') {
                const categoriesData = {
                    categories: [
                        {
                            category_id: selectedCategoryDish?.category?.id,
                            available_ordering_mode: trueSwitchStates,
                            unavailable_ordering_mode: falseSwitchStates,
                            next_available_time: getNextAvailableTime(),
                            hidden: hidden,
                        },
                    ],
                };
                await updateStock('categories/update-stock', categoriesData);
            } else if (type === 'subCategory') {
                const categoriesData = {
                    categories: [
                        {
                            category_id: selectedCategoryDish?.subCategoryId,
                            available_ordering_mode: trueSwitchStates,
                            unavailable_ordering_mode: falseSwitchStates,
                            next_available_time: getNextAvailableTime(),
                            hidden: hidden,
                        },
                    ],
                };
                await updateStock('categories/update-stock', categoriesData);
            }
        } else if (tab === 'add-on') {
            if (type === 'choice' && selectedCategoryDish?.dish) {
                const choicesData = {
                    choices: [
                        {
                            choice_id: selectedCategoryDish.dish,
                            available_ordering_mode: trueSwitchStates,
                            unavailable_ordering_mode: falseSwitchStates,
                            next_available_time: getNextAvailableTime(),
                            hidden: hidden,
                        },
                    ],
                };
                await updateStock('addon-choices/update-stock', choicesData);
            } else if (type === 'add-on' && !selectedCategoryDish?.dish) {
                const addOnData = {
                    addon_groups: [
                        {
                            group_id: selectedCategoryDish?.category?.id,
                            available_ordering_mode: trueSwitchStates,
                            unavailable_ordering_mode: falseSwitchStates,
                            next_available_time: getNextAvailableTime(),
                            hidden: hidden,
                        },
                    ],
                };
                await updateStock('addon-groups/update-stock', addOnData);
            }
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <div className='bg-[#fafafa]'>
                    <div
                        className='px-8 pb-[70px] lg:px-4 lg:pb-0 pt-4 w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: screenHeight - 50 }}>
                        <Header
                            handleClickAvailabilityTab={handleClickAvailabilityTab}
                            showItemAvailability={showItemAvailability}
                            showDishMobile={showDishMobile}
                            setKeyword={setKeyword}
                            dishesAddOnsOff={dishesAddOnsOff}
                            setSelectedFilter={setSelectedFilter}
                        />

                        <div className='flex flex-row lg:block'>
                            {(!showDishMobile || !isMobileScreen) && (
                                <div className='pr-8 mr-8 border-r border-neutral-300 lg:border-0 lg:pb-2 lg:pr-0 lg:mr-0'>
                                    <div className='lg:h-[388px] lg:overflow-auto lg:pr-3 scrollbar-style md:h-full md:overflow-hidden md:pr-0'>
                                        <h3 className='paragraph-large-medium text-black md:pb-2'>
                                            {showItemAvailability === 0 ? 'Categories' : 'Add-on groups'}
                                            {isCategoryLoading && paginationState.page === 1 && (
                                                <ListLoader className='w-[420px] h-[104px] mt-2' />
                                            )}
                                        </h3>

                                        <div
                                            ref={containerCategoryRef}
                                            onScroll={handleCategoryScroll}
                                            className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-10 pl-10'
                                            style={{ height: screenSize.height - 280 }}>
                                            {filteredCategoriesList?.map((category, index) => {
                                                return (
                                                    <div
                                                        className='mt-4 w-full max-w-[396px] md:max-w-full md:mt-2'
                                                        key={index}>
                                                        <Categories
                                                            img={category.image_url}
                                                            title={category.internal_name}
                                                            totalItem={
                                                                tab === 'dish'
                                                                    ? category.total_item_ordering_modes
                                                                    : category.total_addon_ordering_modes
                                                            }
                                                            inStock={
                                                                tab === 'dish'
                                                                    ? category.in_stock_item_ordering_mode_count
                                                                    : category.in_stock_choice_ordering_mode_count
                                                            }
                                                            subCategoryDetails={category.subCategoryDetails}
                                                            handleChangeAvailability={() =>
                                                                handleChangeAvailability(
                                                                    tab === 'dish' ? 'category' : 'add-on'
                                                                )
                                                            }
                                                            onChange={(isChecked) =>
                                                                handleCategorySelection(isChecked, category)
                                                            }
                                                            handleClickCategory={() =>
                                                                handleClickCategoryCard(category)
                                                            }
                                                            isActive={
                                                                category.id === selectedCategoryDish?.category?.id
                                                            }
                                                            subCategoryNumber={category?.sub_categories?.length}
                                                            totalItems={
                                                                tab === 'dish'
                                                                    ? category.in_stock_item_ordering_mode_count
                                                                    : tab === 'add-on'
                                                                      ? category.in_stock_choice_ordering_mode_count
                                                                      : 'N/A'
                                                            }
                                                            type={getFoodType(category)}
                                                            setShowSubCategory={() =>
                                                                setShowSubCategory((prevState) => {
                                                                    return !!prevState ? null : category.id;
                                                                })
                                                            }
                                                            showSubCategory={showSubCategory === category.category_id}
                                                        />
                                                        {!!selectedCategoryDish?.subCategory?.length &&
                                                            !showSubCategory && (
                                                                <SubCategoryCard
                                                                    key={category.id}
                                                                    selectedCategoryDish={selectedCategoryDish}
                                                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                                                    subCategory={category.sub_categories}
                                                                    handleCategorySelection={handleCategorySelection}
                                                                    setSelectedCategoryId={setSelectedCategoryId}
                                                                    selectedCategoryId={selectedCategoryId}
                                                                    setClickedCategory={setClickedCategory}
                                                                    category={category}
                                                                    handleChangeAvailability={() =>
                                                                        handleChangeAvailability('subCategory')
                                                                    }
                                                                    totalItem={
                                                                        tab === 'dish'
                                                                            ? category?.total_item_ordering_modes
                                                                            : category?.total_addon_ordering_modes
                                                                    }
                                                                    inStock={
                                                                        tab === 'dish'
                                                                            ? category?.in_stock_item_ordering_mode_count
                                                                            : category?.in_stock_choice_ordering_mode_count
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(showDishMobile || !isMobileScreen) && (
                                <div className=' w-full md:relative md:h-full md:pr-0 lg:bg-white lg:border-t lg:pt-4 lg:pb-2 lg:border-neutral-300 md:border-0 md:pt-0'>
                                    <div className='lg:w-full lg:pr-2 md:border-none lg:h-[336px] lg:overflow-auto scrollbar-style md:overflow-hidden md:h-full md:pr-0'>
                                        <div className='max-w-[507px] w-full md:max-w-full'>
                                            {!isMobileScreen ? (
                                                <span className='paragraph-large-medium text-black'>Dishes</span>
                                            ) : (
                                                <div
                                                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                                    onClick={handleClickCategory}>
                                                    <LeftArrow className='rotate-90' />
                                                    <span className='ml-1'>Back to categories</span>
                                                </div>
                                            )}

                                            <div className='mt-4 md:pb-2'>
                                                <span className='paragraph-large-italic text-neutral-500'>
                                                    {selectedCategoryDish?.category?.internal_name}
                                                </span>
                                            </div>
                                            {isItemLoading && paginationState.page === 1 && (
                                                <ListLoader className='w-[420px] h-[104px] mt-2' />
                                            )}
                                            <div
                                                className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-6 pl-6'
                                                style={{ height: screenSize.height - 300 }}>
                                                {subCategoryDishList?.length > 0 ? (
                                                    subCategoryDishList.map(({ category, dish }) => (
                                                        <div key={category.id}>
                                                            <div className='mt-4 md:pb-2'>
                                                                <span className='paragraph-large-italic text-neutral-500'>
                                                                    {category.name}
                                                                </span>
                                                            </div>
                                                            {dish?.length > 0 ? (
                                                                dish.map((item, index) => (
                                                                    <div
                                                                        className='mt-4 md:h-full md:w-full md:mt-2'
                                                                        key={index}>
                                                                        <Dishes
                                                                            title={
                                                                                tab === 'dish'
                                                                                    ? item.internal_name
                                                                                    : item?.translations?.name[
                                                                                          selectedLanguageId
                                                                                      ]
                                                                            }
                                                                            type={item?.food_type}
                                                                            handleChangeAvailability={() =>
                                                                                handleChangeAvailability(
                                                                                    tab === 'dish' ? 'item' : 'choice'
                                                                                )
                                                                            }
                                                                            position={item.position}
                                                                            variants={item.variants_count}
                                                                            handleClickDish={() =>
                                                                                setSelectedCategoryDish(
                                                                                    (prevState) => ({
                                                                                        ...prevState,
                                                                                        dish: item.id,
                                                                                    })
                                                                                )
                                                                            }
                                                                            isActive={
                                                                                selectedCategoryDish.dish === item.id
                                                                            }
                                                                            totalItem={
                                                                                tab === 'dish'
                                                                                    ? item?.total_ordering_modes
                                                                                    : item.total_addon_ordering_modes
                                                                            }
                                                                            inStock={
                                                                                tab === 'dish'
                                                                                    ? item?.in_stock_ordering_mode
                                                                                    : item?.in_stock_choice_ordering_mode_count
                                                                            }
                                                                            setShowVariants={() =>
                                                                                setShowVariants((prevState) =>
                                                                                    prevState ? null : item.id
                                                                                )
                                                                            }
                                                                            showVariants={showVariants === item.id}
                                                                        />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <span className='paragraph-small-italic text-neutral-500'>
                                                                    No dishes available
                                                                </span>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div
                                                        ref={containerItemref}
                                                        onScroll={handleItemScroll}
                                                        className='overflow-auto scrollbar-style -mr-8 pr-8 -ml-6 pl-6'
                                                        style={{ height: screenSize.height - 300 }}>
                                                        {filteredDishList.length > 0 ? (
                                                            filteredDishList.map((item, index) => {
                                                                const handleSelectDish = (isSelected) => {
                                                                    const selectedDishState = isSelected
                                                                        ? [...selectedDishId, item.id]
                                                                        : selectedDishId.filter((el) => el !== item.id);

                                                                    setSelectedDishId(selectedDishState);
                                                                };
                                                                return (
                                                                    <div
                                                                        className='mt-4 md:h-full md:w-full md:mt-2'
                                                                        key={index}>
                                                                        <Dishes
                                                                            title={
                                                                                tab === 'dish'
                                                                                    ? item.internal_name
                                                                                    : item?.translations?.name[
                                                                                          selectedLanguageId
                                                                                      ]
                                                                            }
                                                                            type={item?.food_type}
                                                                            handleChangeAvailability={() =>
                                                                                handleChangeAvailability(
                                                                                    tab === 'dish' ? 'item' : 'choice'
                                                                                )
                                                                            }
                                                                            position={item.position}
                                                                            variants={item.variants_count}
                                                                            handleClickDish={() =>
                                                                                setSelectedCategoryDish(
                                                                                    (prevState) => ({
                                                                                        ...prevState,
                                                                                        dish: item.id,
                                                                                    })
                                                                                )
                                                                            }
                                                                            isActive={
                                                                                selectedCategoryDish.dish === item.id
                                                                            }
                                                                            onSelectDish={(isSelected) =>
                                                                                handleSelectDish(isSelected)
                                                                            }
                                                                            totalItem={
                                                                                tab === 'dish'
                                                                                    ? item?.total_ordering_modes
                                                                                    : item.total_addon_ordering_modes
                                                                            }
                                                                            inStock={
                                                                                tab === 'dish'
                                                                                    ? item?.in_stock_ordering_mode
                                                                                    : item?.in_stock_choice_ordering_mode_count
                                                                            }
                                                                            setShowVariants={() =>
                                                                                setShowVariants((prevState) =>
                                                                                    !!prevState ? null : item.id
                                                                                )
                                                                            }
                                                                            showVariants={showVariants === item.id}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <span className='paragraph-small-italic text-neutral-500'>
                                                                No dishes available
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {showEditCategory && (
                            <EditCategoryPopup
                                handleClickClose={handleChangeAvailability}
                                editType={editType}
                                onSave={handleSaveSwitchStates}
                                hidden={hidden}
                                setHidden={setHidden}
                                handleCategoryItemEdit={handleCategoryItemEdit}
                                timeTypeName='schedule_time_type'
                                selectedTimeName='schedule_selected_time'
                                selectedDateName='schedule_selected_date'
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                            />
                        )}
                    </div>
                </div>
            </FormProvider>
        </>
    );
}
