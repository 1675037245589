import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../../../Components/TitleDescription/TitleDescription';
import { DefaultInputField } from '../../../Components/InputField/InputField';
import { ErrorMessage } from '../../../Components/Error/ErrorMessage';
import { DropdownBoxLoader } from '../../../Components/ContentLoader/DropdownLoader';
import TitleDescriptionLoader from '../../../Components/ContentLoader/TitleDescriptionLoader';

export default function InputSection(props) {
    const {
        title,
        description,
        name,
        disabled,
        placeholder,
        inputType,
        enteringLimit,
        marginBetween,
        rules,
        label,
        labelStyle,
        placeholderIcon,
        shadow,
        maxWidth,
        paddingLeft,
        prefix,
        prefixClass,
        dontShowError,
        className,
        boxHeight,
        onFocus,
        titleLoaderProps,
        boxLoaderProps,
        isLoading,
        inputValue,
    } = props;

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const handleInputChange = (value, fieldChange) => {
        props?.handleInputChange?.(value);

        if (enteringLimit) {
            if (value > enteringLimit) {
                setError(name, { message: `Please enter a number below ${enteringLimit}` });
            } else {
                clearErrors(name);
                fieldChange(value);
            }
            return;
        }

        fieldChange(value);
    };

    return (
        <>
            {isLoading ? (
                <>
                    <TitleDescriptionLoader {...titleLoaderProps} />
                    <DropdownBoxLoader {...boxLoaderProps} />
                </>
            ) : (
                <>
                    <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                        <TitleDescription
                            title={title}
                            disabled={disabled}
                            description={description}
                        />
                    </div>
                    <div className={`w-full md:max-w-full ${maxWidth ?? 'max-w-[312px]'} ${className}`}>
                        <Controller
                            render={({ field }) => (
                                <>
                                    <DefaultInputField
                                        boxHeight={boxHeight ?? '[52px]'}
                                        labelMarginB='pb-2'
                                        placeholder={placeholder}
                                        shadow={shadow ?? 'shadow-xSmall'}
                                        enteredValue={(e) => {
                                            handleInputChange(e, field.onChange);
                                        }}
                                        inputType={inputType}
                                        disabled={disabled}
                                        value={inputValue ?? field.value ?? ''}
                                        label={label}
                                        labelStyle={labelStyle}
                                        placeholderIcon={placeholderIcon}
                                        paddingLeft={paddingLeft}
                                        prefix={prefix}
                                        prefixClass={prefixClass}
                                        onFocus={onFocus}
                                    />
                                </>
                            )}
                            name={name}
                            rules={rules ?? {}}
                            control={control}
                            // disabled={disabled}
                        />
                        {!dontShowError && errors?.[name] && <ErrorMessage errors={errors[name]} />}
                    </div>
                </>
            )}
        </>
    );
}
