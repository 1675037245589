import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import ThresholdSection from './ThresholdSection';
import InputSection from '../../Components/InputSection';
import CouponValiditySection from './CouponValiditySection';
import MinimumAmountSection from './MinimumAmountSection';
import DiscountUptoSection from './DiscountUptoSection';
import MaximumUsageSection from './MaximumUsageSection';
import UsageCountPerUserSection from './UsageCountPerUserSection';
import DropdownSection from '../../Components/DropdownSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function DiscountSection(props) {
    const { discountTypeMenuItems, customizableTypeMenuItems, setShowTabChangeWarningPopup } = props;

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='mb-6 pb-6 border-b border-neutral-300'>
                <div className='flex flex-row items-center'>
                    <h1
                        className={`paragraph-large-medium ${
                            !watch('bill_payments.bill_payment_enabled') && 'text-neutral-300'
                        } mr-4`}>
                        Enable/disable discount
                    </h1>
                    <div className='pt-1'>
                        <Controller
                            render={({ field }) => (
                                <ToggleSwitch
                                    isEnable={(!!watch('bill_payments.bill_payment_enabled') && field.value) ?? false}
                                    canChange={!!watch('bill_payments.bill_payment_enabled')}
                                    setIsEnabled={(e) => {
                                        if (!!watch('bill_payments.bill_payment_enabled')) {
                                            field.onChange(e);
                                            setShowTabChangeWarningPopup(true);
                                        }
                                    }}
                                    cursorType={!watch('bill_payments.bill_payment_enabled') && 'cursor-not-allowed'}
                                />
                            )}
                            name='bill_payments.enableDiscount'
                            control={control}
                        />
                    </div>
                </div>

                {watch('bill_payments.enableDiscount') && (
                    <div className='mt-4'>
                        <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                            <DropdownSection
                                menuItems={customizableTypeMenuItems}
                                label='Customizable type'
                                shadow='shadow-smallDropDownShadow'
                                labelPaddingB='pb-2'
                                height='h-[52px]'
                                labelStyle='paragraph-large-medium'
                                placeholder='Please select customizable type'
                                rules={{ required: 'Please select customizable type' }}
                                name='bill_payments.customizableType'
                                clearErrorName={[
                                    'bill_payments.isAddMinimumAmount',
                                    'bill_payments.isAddDiscountUptoAmount',
                                ]}
                                handleSelection={() => setShowTabChangeWarningPopup(true)}
                            />

                            {errors?.bill_payments?.customizableType && (
                                <ErrorMessage errors={errors?.bill_payments.customizableType} />
                            )}
                        </div>

                        {watch('bill_payments.customizableType')?.value === customizableTypeMenuItems[1].value && (
                            <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                                <InputSection
                                    placeholder='Enter threshold amount'
                                    label='Threshold amount'
                                    labelStyle='paragraph-large-medium'
                                    shadow='shadow-smallDropDownShadow'
                                    boxHeight='[52px]'
                                    labelMarginB='pb-2'
                                    inputType='number'
                                    name='bill_payments.thresholdAmount'
                                    rules={{ required: 'Please fill this field' }}
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />
                                {errors?.bill_payments?.thresholdAmount && (
                                    <ErrorMessage errors={errors?.bill_payments.thresholdAmount} />
                                )}
                            </div>
                        )}
                        <div className='mb-4 max-w-[312px] relative md:max-w-full'>
                            <DropdownSection
                                menuItems={discountTypeMenuItems}
                                label='Type of discount'
                                shadow='shadow-smallDropDownShadow'
                                labelPaddingB='pb-2'
                                height='h-[52px]'
                                labelStyle='paragraph-large-medium'
                                placeholder='Please select discount type'
                                rules={{ required: 'Please select discount type' }}
                                name='bill_payments.discountType'
                                clearErrorName={[
                                    'bill_payments.isAddMinimumAmount',
                                    'bill_payments.isAddDiscountUptoAmount',
                                    'bill_payments.aboveThresholdValue',
                                    'bill_payments.belowThresholdValue',
                                    'bill_payments.simpleDiscountAmount',
                                ]}
                                handleSelection={() => setShowTabChangeWarningPopup(true)}
                            />
                            {errors?.bill_payments?.discountType && (
                                <ErrorMessage errors={errors?.bill_payments.discountType} />
                            )}
                        </div>

                        {watch('bill_payments.customizableType')?.value === customizableTypeMenuItems[0].value && (
                            <>
                                <InputSection
                                    placeholder={`Enter ${
                                        watch('bill_payments.discountType')?.value !== 'flat' ? 'percentage' : 'fixed'
                                    } amount`}
                                    label={`${
                                        watch('bill_payments.discountType')?.value !== 'flat' ? 'Percentage' : 'Fixed'
                                    } amount`}
                                    labelStyle='paragraph-large-medium'
                                    shadow='shadow-smallDropDownShadow'
                                    rules={{ required: 'Please fill this field' }}
                                    name='bill_payments.simpleDiscountAmount'
                                    enteringLimit={watch('bill_payments.discountType')?.value !== 'flat' && 100}
                                    inputType='number'
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />

                                {errors?.bill_payments?.simpleDiscountAmount && (
                                    <ErrorMessage errors={errors?.bill_payments?.simpleDiscountAmount} />
                                )}
                            </>
                        )}

                        {watch('bill_payments.customizableType')?.value === customizableTypeMenuItems[1].value && (
                            <ThresholdSection
                                discountType={watch('bill_payments.discountType')?.value}
                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            />
                        )}

                        <MinimumAmountSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />

                        <DiscountUptoSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />

                        <MaximumUsageSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />

                        <UsageCountPerUserSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />

                        <CouponValiditySection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />
                    </div>
                )}
            </div>
        </>
    );
}
