import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import BillPaymentSection from './Components/BillPaymentSection';
import DiscountSection from './Components/DiscountSection';
import ConvenientChargeSection from './Components/ConvenientChargeSection';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import Loader from './Components/Loader';

export default function BillPayment(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        setValue,
        watch,
        setError,
        formState: { errors, isDirty, isValid },
        reset,
    } = useFormContext();

    const [isDefaultOfferEnabled, setIsDefaultOfferEnabled] = useState(false);

    const customizableTypeMenuItems = [
        { label: 'Simple discount', value: 'simpleDiscount' },
        { label: 'Variable discount', value: 'variableDiscount' },
    ];

    const discountTypeMenuItems = [
        { label: 'Percentage discount', value: 'percentage' },
        { label: 'Fixed discount', value: 'flat' },
    ];

    const onSubmit = () => {
        if (!_.isEmpty(errors)) return;

        handleSubmit(submitData)();
    };

    const submitData = (data) => {
        if (data.bill_payments.bill_payment_enabled) {
            updateConvenienceCharge(formateCharge(data.bill_payments));
            setIsDefaultOfferEnabled(false);
        }

        if (isDefaultOfferEnabled && !data.bill_payments.enableDiscount) {
            if (data?.bill_payments?.offers?.id) {
                deletBillPayemntOffer(data?.bill_payments?.offers?.id);
                setIsDefaultOfferEnabled(false);
            }
            return;
        }

        if (data.bill_payments.enableDiscount) {
            isDefaultOfferEnabled
                ? editBillPaymentOffer(formatData(data.bill_payments))
                : createBillPaymentOffer(formatData(data.bill_payments));
            setIsDefaultOfferEnabled(false);
        }

        if (!data.bill_payments.bill_payment_enabled) {
            deletBillPayemntOffer(data?.bill_payments?.offers?.id);
            updateConvenienceCharge({
                is_bill_payment_enabled: 0,
                bill_payment_convenience_charge_type: 'percentage_charge',
                bill_payment_convenience_charge: 0,
            });
        }

        if (!data.bill_payments.enableConvenienceCharge) {
            updateConvenienceCharge({
                is_bill_payment_enabled: 0,
                bill_payment_convenience_charge_type: 'percentage_charge',
                bill_payment_convenience_charge: 0,
            });
        }
    };

    const formateCharge = (data) => {
        return {
            is_bill_payment_enabled: data?.bill_payment_enabled ? 1 : 0,
            bill_payment_convenience_charge_type:
                data?.convenienceChargeType?.value === 'percentage' ? 'percentage_charge' : 'fixed_charge',
            bill_payment_convenience_charge: data?.bill_payment_enabled
                ? parseInt(data.bill_payment_convenience_charge, 10)
                : null,
        };
    };

    const formatData = (data) => {
        return {
            id: data?.offers?.id ?? data.id,
            amount: data.simpleDiscountAmount,
            type: data.discountType.value,
            minimum_payment_amount: data.isAddMinimumAmount ? data.minimum_payment_amount : null,
            bill_payment_convenience_charge: data.enableConvenienceCharge ? data.bill_payment_convenience_charge : null,
            max_usage_count: data?.isMaxUsageCount?.value ? data.max_usage_count : null,
            max_usage_count_per_user: data?.isMaximumUsagePerUser?.value ? data.max_usage_count_per_user : null,
            discount_upto_amount: data?.isAddDiscountUptoAmount?.value ? data.discount_upto_amount : null,
            validate_from: data.hasCouponValidity.value ? data.validateFrom : null,
            validate_to: data.hasCouponValidity.value ? data.validateTo : null,
            threshold_amount: data.customizableType.value === 'variableDiscount' ? data.thresholdAmount : null,
            discount_below_threshold:
                data.customizableType.value === 'variableDiscount' ? data.belowThresholdValue : null,
            discount_above_threshold:
                data.customizableType.value === 'variableDiscount' ? data.aboveThresholdValue : null,
        };
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const setDefaultValue = (data) => {
        setValue('bill_payments.id', data.id);
        setValue('defaultValue.bill_payments.id', data.id);

        setValue('bill_payments.offers', data);
        setValue('defaultValue.bill_payments.offers', data);

        if (!_.isEmpty(data)) {
            setValue('bill_payments.enableDiscount', true);
            setValue('defaultValue.bill_payments.enableDiscount', true);
            setIsDefaultOfferEnabled(true);
        } else {
            setValue('bill_payments.enableDiscount', false);
            setValue('defaultValue.bill_payments.enableDiscount', false);
        }

        if (data.threshold_amount === 0 && data.discount_above_threshold === 0 && data.discount_below_threshold === 0) {
            setValue('bill_payments.customizableType', customizableTypeMenuItems[0]);
            setValue('defaultValue.bill_payments.customizableType', customizableTypeMenuItems[0]);
        } else {
            setValue('bill_payments.customizableType', customizableTypeMenuItems[1]);
            setValue('defaultValue.bill_payments.customizableType', customizableTypeMenuItems[1]);
            setValue('bill_payments.thresholdAmount', data.threshold_amount);
            setValue('bill_payments.aboveThresholdValue', data.discount_above_threshold);
            setValue('bill_payments.belowThresholdValue', data.discount_below_threshold);
            setValue('defaultValue.bill_payments.thresholdAmount', data.threshold_amount);
            setValue('defaultValue.bill_payments.aboveThresholdValue', data.discount_above_threshold);
            setValue('defaultValue.bill_payments.belowThresholdValue', data.discount_below_threshold);
        }

        if (data.type === discountTypeMenuItems[0].value) {
            setValue('bill_payments.discountType', discountTypeMenuItems[0]);
            setValue('defaultValue.bill_payments.discountType', discountTypeMenuItems[0]);
        } else {
            setValue('bill_payments.discountType', discountTypeMenuItems[1]);
            setValue('defaultValue.bill_payments.discountType', discountTypeMenuItems[1]);
        }

        setValue('bill_payments.simpleDiscountAmount', data.amount);
        setValue('defaultValue.bill_payments.simpleDiscountAmount', data.amount);

        if (!!data.minimum_payment_amount) {
            setValue('bill_payments.isAddMinimumAmount', preferenceMenuItem[0]);
            setValue('defaultValue.bill_payments.isAddMinimumAmount', preferenceMenuItem[0]);
            setValue('bill_payments.minimum_payment_amount', data.minimum_payment_amount);
            setValue('defaultValue.bill_payments.minimum_payment_amount', data.minimum_payment_amount);
        } else {
            setValue('bill_payments.isAddMinimumAmount', preferenceMenuItem[1]);
            setValue('defaultValue.bill_payments.isAddMinimumAmount', preferenceMenuItem[1]);
        }

        if (!!data.discount_upto_amount) {
            setValue('bill_payments.isAddDiscountUptoAmount', preferenceMenuItem[0]);
            setValue('defaultValue.bill_payments.isAddDiscountUptoAmount', preferenceMenuItem[0]);
            setValue('bill_payments.discount_upto_amount', data.discount_upto_amount);
            setValue('defaultValue.bill_payments.discount_upto_amount', data.discount_upto_amount);
        } else {
            setValue('bill_payments.isAddDiscountUptoAmount', preferenceMenuItem[1]);
            setValue('defaultValue.bill_payments.isAddDiscountUptoAmount', preferenceMenuItem[1]);
        }

        if (!!data.max_usage_count) {
            setValue('bill_payments.isMaxUsageCount', preferenceMenuItem[0]);
            setValue('defaultValue.bill_payments.isMaxUsageCount', preferenceMenuItem[0]);
            setValue('bill_payments.max_usage_count', data.max_usage_count);
            setValue('defaultValue.bill_payments.max_usage_count', data.max_usage_count);
        } else {
            setValue('bill_payments.isMaxUsageCount', preferenceMenuItem[1]);
            setValue('defaultValue.bill_payments.isMaxUsageCount', preferenceMenuItem[1]);
        }

        if (!!data.max_usage_count_per_user) {
            setValue('bill_payments.isMaximumUsagePerUser', preferenceMenuItem[0]);
            setValue('defaultValue.bill_payments.isMaximumUsagePerUser', preferenceMenuItem[0]);
            setValue('bill_payments.max_usage_count_per_user', data.max_usage_count_per_user);
            setValue('defaultValue.bill_payments.max_usage_count_per_user', data.max_usage_count_per_user);
        } else {
            setValue('bill_payments.isMaximumUsagePerUser', preferenceMenuItem[1]);
            setValue('defaultValue.bill_payments.isMaximumUsagePerUser', preferenceMenuItem[1]);
        }

        if (!!data.validate_from) {
            setValue('bill_payments.hasCouponValidity', preferenceMenuItem[0]);
            setValue('defaultValue.bill_payments.hasCouponValidity', preferenceMenuItem[0]);
            setValue('bill_payments.validateFrom', data.validate_from);
            setValue('defaultValue.bill_payments.validateFrom', data.validate_from);
            setValue('bill_payments.validateTo', data.validate_to);
            setValue('defaultValue.bill_payments.validateTo', data.validate_to);
        } else {
            setValue('bill_payments.hasCouponValidity', preferenceMenuItem[1]);
            setValue('defaultValue.bill_payments.hasCouponValidity', preferenceMenuItem[1]);
        }
    };

    const fetchBillPayemntSetting = async () => {
        try {
            const response = await APIV5.get(`/outlets/${selectedOutletId}/bill-payment-offers`);
            response.outlet_bill_payment_offers.length && setDefaultValue(response.outlet_bill_payment_offers[0]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchBillPayemntSetting();
        // eslint-disable-next-line
    }, []);

    const deletBillPayemntOffer = async (id) => {
        try {
            const response = await APIV5.delete(`bill-payment-offers/${id}`);
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                fetchBillPayemntSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const editBillPaymentOffer = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}/bill-payment-offers/${params.id}`, {
                ...params,
            });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                fetchBillPayemntSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const createBillPaymentOffer = async (params) => {
        try {
            const response = await APIV5.post(`outlets/${selectedOutletId}/bill-payment-offers`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                fetchBillPayemntSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateConvenienceCharge = async (params) => {
        if (
            params.bill_payment_convenience_charge_type === 'percentage_charge' &&
            params.bill_payment_convenience_charge > 100
        ) {
            setError('bill_payments.bill_payment_convenience_charge', { message: 'Please enter a number below 100' });
            return;
        }

        try {
            const response = await APIV5.put(`outlets/${selectedOutletId}?section=bill_payments`, {
                ...params,
            });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                fetchBillPayemntSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={onSubmit}
            handleClickCancel={() => handleDiscardForm()}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full lg:max-w-[462px] md:max-w-full md:pb-20'>
                <BillPaymentSection setShowTabChangeWarningPopup={setShowTabChangeWarningPopup} />

                <DiscountSection
                    customizableTypeMenuItems={customizableTypeMenuItems}
                    discountTypeMenuItems={discountTypeMenuItems}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />

                <ConvenientChargeSection
                    discountTypeMenuItems={discountTypeMenuItems}
                    setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                />
            </div>
        </Layout>
    );
}
