import React from 'react';
import ContentLoader from 'react-content-loader';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function PopupLoader() {
    const isMobileScreen = IsMobileScreen();

    return (
        <div className='md:mt-5'>
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            <SingleLoader />
            {!isMobileScreen && (
                <>
                    <SingleLoader />
                    <SingleLoader />
                    <SingleLoader />
                    <SingleLoader />
                    <SingleLoader />
                    <SingleLoader />
                    <SingleLoader />
                </>
            )}
        </div>
    );
}

const SingleLoader = () => {
    return (
        <ContentLoader
            width='100%'
            height='100%'
            className='h-[22px] mb-8 inline-block w-1/2 md:w-full md:block'>
            <rect
                x='0'
                y='0'
                rx='8'
                ry='8'
                width='100'
                height='20'
            />
            <rect
                x='130'
                y='0'
                rx='8'
                ry='8'
                width='120'
                height='20'
            />
        </ContentLoader>
    );
};
