import React from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';

export default function Layout(props) {
    const { showPopup, children } = props;

    const isMobileScreen = IsMobileScreen();
    const { height: screenHeight } = useWindowSize();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!showPopup || !isMobileScreen) && (
                    <div
                        className='w-full max-w-[1336px] mx-auto bg-white'
                        style={{ minHeight: screenHeight - 50 }}>
                        <div className='flex flex-row items-start md:block h-full'>{children}</div>
                    </div>
                )}
            </div>
        </>
    );
}
