import React from 'react';

export default function CardDescription(props) {
    const { description, focusContentColor, focusContent } = props;

    const segments = description.split(':focusContent:');

    const focusContentStyle = {
        color: focusContentColor,
    };

    return (
        <>
            <p className='paragraph-x-small-regular text-neutral-500 max-w-[231px]'>
                {segments[0]}
                {focusContent && (
                    <span
                        className='paragraph-x-small-medium'
                        style={focusContentStyle}>
                        {' ' + focusContent + ' '}
                    </span>
                )}
                {segments[1]}
            </p>
        </>
    );
}
