import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';

export default function BillPaymentSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const { control, setValue } = useFormContext();

    const handleEnableToggle = (value, fieldChange) => {
        setShowTabChangeWarningPopup(true);
        fieldChange(value);

        if (!value) {
            setValue('bill_payments.enableDiscount', false);
            setValue('bill_payments.enableConvenienceCharge', false);
        }
    };

    return (
        <>
            <div className='mb-6 border-b pb-6 border-neutral-300'>
                <div className='flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full'>
                    <TitleDescription
                        title='Enable/disable bill payments'
                        description='Enable or disable bill payment settings.'
                    />

                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={field.value ?? false}
                                canChange
                                setIsEnabled={(value) => {
                                    handleEnableToggle(value, field.onChange);
                                }}
                            />
                        )}
                        name='bill_payments.bill_payment_enabled'
                        control={control}
                    />
                </div>
            </div>
        </>
    );
}
