import { useEffect, useState } from 'react';
import { DropDownTabs } from '../DropDown/DropDownTabs';
import { DefaultInputField } from '../InputField/InputField';

export const ColorComparisonBox = (props) => {
    const { color, currentColor } = props;

    return (
        <>
            <div className='items-center justify-center text-center w-fit md:flex md:flex-row'>
                <div className='paragraph-x-small-regular pb-1 md:mr-2'>New</div>

                <div className='flex flex-col md:flex-row'>
                    <div
                        className={`rounded-t md:rounded-l md:rounded-tr-none w-16 h-10 ${
                            color === '#FFFFFF' && 'border border-neutral-300'
                        }`}
                        style={{ backgroundColor: color }}
                    />

                    <div
                        className={`rounded-b md:rounded-r md:rounded-bl-none w-16 h-10 ${
                            currentColor === '#FFFFFF' && 'border border-neutral-300'
                        }`}
                        style={{ backgroundColor: currentColor }}
                    />
                </div>

                <div className='paragraph-x-small-regular pt-1 md:ml-2'>Current</div>
            </div>
        </>
    );
};

export const ColorLabel = (props) => {
    const { color, setColor, setSelectedColorFromPopup } = props;

    const [RGBColor, setRGBColor] = useState({ red: 255, green: 255, blue: 255 });

    const hexToRgb = (hex) => {
        hex = hex.replace('#', '');

        const hexRegex = /^(?:[0-9a-fA-F]{3}){1,2}$/;
        if (!hexRegex.test(hex)) {
            throw new Error('Invalid HEX color format.');
        }

        if (hex.length === 3) {
            hex = hex
                .split('')
                .map((char) => char + char)
                .join('');
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        setRGBColor({ red: r, green: g, blue: b });
    };

    useEffect(() => {
        hexToRgb(color);
    }, [color]);

    const [isHexActive, setisHexActive] = useState(true);

    const toggleActiveColorTab = (activetab) => {
        setisHexActive(activetab === 'HEX');
    };

    const rgbToHex = (r, g, b) => {
        const toHex = (c) => c.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
    };

    const menuItems = [
        { item: 'HEX', onClick: () => toggleActiveColorTab('HEX') },
        { item: 'RGB', onClick: () => toggleActiveColorTab('RGB') },
    ];

    const handleRgbChange = (colorName, value) => {
        const newValue = Math.max(0, Math.min(255, Number(value)));
        const newRgbColor = { ...RGBColor, [colorName]: newValue };
        setRGBColor(newRgbColor);
        const newHexColor = rgbToHex(newRgbColor.red, newRgbColor.green, newRgbColor.blue);
        setColor(newHexColor);
        setSelectedColorFromPopup?.(newHexColor);
    };

    return (
        <>
            <div className='flex flex-row'>
                {isHexActive ? (
                    <HexColorLabel
                        setSelectedColorFromPopup={setSelectedColorFromPopup}
                        setColor={setColor}
                        color={color}
                    />
                ) : (
                    <div className='flex flex-row items-center'>
                        {Object.keys(RGBColor).map((colorName, index) => (
                            <div
                                className={`${index === 1 && 'mx-4 mobile:mx-[11px]'}`}
                                key={index}>
                                <SingleColorLabel
                                    colorValue={RGBColor[colorName]}
                                    colorLabel={colorName}
                                    onChange={(value) => handleRgbChange(colorName, value)}
                                />
                            </div>
                        ))}
                    </div>
                )}

                <div className='ml-2 w-[108px] mobile:w-[89px]'>
                    <DropDownTabs
                        openFromBottom
                        menuItems={menuItems}
                        boxStyle='bg-shades-50 border-neutral-300'
                        textColor='text-black'
                        tabStyle='paragraph-small-regular'
                        dropDownIconFill='#131126'
                        itemStyle='paragraph-small-regular'
                    />
                </div>
            </div>
        </>
    );
};

export const SingleColorLabel = (props) => {
    const { colorValue, colorLabel, onChange } = props;

    return (
        <>
            <div className='w-fit items-center'>
                {/* <div className='border rounded border-neutral-300 h-[48px] w-[72px] justify-center mb-[1px] flex md:w-[56px]'>
                    <span className='paragraph-x-small-regular items-center my-auto'>{colorValue}</span>
                </div> */}

                <div className='w-[72px] justify-center mb-[1px] flex md:w-[56px]'>
                    {/* <input
                        type='number'
                        value={colorValue}
                        onChange={onChange}
                        className='paragraph-x-small-regular items-center my-auto w-full text-center'
                        // min={0}
                        max={255}
                    /> */}

                    <DefaultInputField
                        inputType='number'
                        value={colorValue}
                        enteredValue={onChange}
                        maxValue={255}
                        textInputStyle='paragraph-x-small-regular'
                    />
                </div>

                <div className='items-center mx-auto w-fit'>
                    <span className='paragraph-x-small-medium'>{colorLabel[0].toUpperCase()}</span>
                </div>
            </div>
        </>
    );
};

export const HexColorLabel = (props) => {
    const { color, setColor, setSelectedColorFromPopup } = props;

    const [tempColorName, setTempColorName] = useState('');

    const handleColorChange = (newColor) => {
        setTempColorName(newColor);

        const isValidColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(newColor);
        if (isValidColor) {
            setColor(newColor);
            setSelectedColorFromPopup(newColor);
        }
    };

    useEffect(() => {
        setTempColorName(color.toUpperCase());
    }, [color]);

    return (
        <>
            <div className='items-center w-[248px] mb-[1px] flex md:w-[200px] mobile:w-[191px]'>
                <DefaultInputField
                    value={tempColorName}
                    onChange={(e) => handleColorChange(e.target.value)}
                    textInputStyle='paragraph-x-small-regular'
                />
            </div>
        </>
    );
};
