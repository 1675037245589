import React from 'react';
import { useFormContext } from 'react-hook-form';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import InputSection from '../../../../../Components/InputField/InputSection';

export default function CouponLimit() {
    const { watch } = useFormContext();

    return (
        <>
            <DropdownSection
                title='Coupon limit preference'
                description='Select preference to add coupon limit.'
                name='has_coupon_limit'
                placeholder='Select your preference'
                menuItems={preferenceMenuItem}
                rules={{ required: 'Please select preference' }}
                clearErrorName={['max_usage_count']}
                clearField={['max_usage_count']}
                className='mb-4'
            />

            {watch('has_coupon_limit')?.value && (
                <InputSection
                    className='mb-6'
                    title='Coupon limit'
                    description='Select the number of times the coupon could be used in total.'
                    name='max_usage_count'
                    disabled={!watch('has_coupon_limit')?.value}
                    placeholder='Enter coupon limit'
                    inputType='number'
                    rules={
                        watch('has_coupon_limit')?.value
                            ? { required: 'Please enter coupon limit' }
                            : { required: false }
                    }
                />
            )}
        </>
    );
}
