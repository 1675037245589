import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../../Assets/chevron-down.svg';
import { ReactComponent as MenuIcon } from '../../../../../Assets/menu.svg';

export default function ListViewOutletMenu(props) {
    const { outletName, state, city, address, presetAttached, categories, dishes, handleClickViewDetails } = props;

    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
                <div
                    className='flex flex-row items-center justify-between cursor-pointer'
                    onClick={handleClickShowDetails}>
                    <div>
                        <h3 className='paragraph-overline-small text-neutral-700 mb-1'>OUTLET NAME:</h3>
                        <span className='paragraph-small-regular'>{outletName}</span>
                    </div>
                    <div className='flex flex-row items-center'>
                        <div className={`${isShowDetails && 'rotate-180'}`}>
                            <DownArrow />
                        </div>
                    </div>
                </div>

                {isShowDetails && (
                    <div className='mt-[5px]'>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>STATE:</span>
                            <span className='paragraph-small-regular ml-1'>{state}</span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>CITY:</span>
                            <span className='paragraph-small-regular ml-1'>{city}</span>
                        </div>
                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>ADDRESS:</span>
                            <span className='paragraph-small-regular ml-1'>{address}</span>
                        </div>

                        <div className='mb-[5px]'>
                            <span className='paragraph-overline-small text-neutral-700'>PRESET ATTACHED:</span>
                            <span className='paragraph-small-regular ml-1'>{presetAttached}</span>
                        </div>
                        <div className='flex flex-row justify-between items-end'>
                            <div>
                                <span className='paragraph-overline-small text-neutral-700 pb-1'>
                                    CATEGORIES AND DISHES:
                                </span>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row items-center mb-1'>
                                        <MenuIcon />
                                        <span className='paragraph-small-regular ml-2'>Categories - {categories}</span>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <MenuIcon />
                                        <span className='paragraph-small-regular ml-2'>Dishes - {dishes}</span>
                                    </div>
                                </div>
                            </div>
                            <span
                                className='paragraph-small-italic text-primary-500 cursor-pointer mobile:min-w-max'
                                onClick={() => handleClickViewDetails()}>
                                View full details
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
