import React from 'react';
import ToggleSwitch from '../../../Components/ToggleSwitch/ToggleSwitch';
import { ReactComponent as EditIcon } from '../../../Assets/edit.svg';
import { ReactComponent as LinkIcon } from '../../../Assets/link.svg';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import { offerTypes } from '../Constants/Constants';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function TableRows(props) {
    const {
        id,
        index,
        active,
        offer_type,
        coupon_code,
        activeState,
        unlinkOutlet,
        translations,
        selectedOffer,
        onSelectOffer,
        isAllOfferPage,
    } = props;

    const offerType = offerTypes.find((el) => el.value === offer_type)?.label;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: activeState.reArrange ? 'pointer' : 'default',
    };

    return (
        <tr
            className='h-[70px] justify-center even:bg-neutral-50 paragraph-small-regular border-neutral-300 first:border-none border-t'
            ref={setNodeRef}
            style={style}
            key={index}
            {...attributes}
            {...listeners}>
            {isAllOfferPage && (
                <td className='pl-6'>
                    <ToggleSwitch isEnable={active} />
                </td>
            )}

            <td className={`pl-6 ${(activeState.reArrange || activeState.edit) && 'cursor-pointer'} min-w-[100px]`}>
                <div className='flex flex-row items-center'>
                    {activeState.selection && (
                        <span className='mr-3'>
                            <CheckBoxWithoutLabels
                                onChange={(value) => {
                                    onSelectOffer(id, value);
                                }}
                                isChecked={selectedOffer.includes(id)}
                            />
                        </span>
                    )}

                    {activeState.edit && (
                        <span className='mr-3'>
                            <EditIcon />
                        </span>
                    )}

                    <span>{coupon_code}</span>
                </div>
            </td>

            <td className='pl-6 min-w-[230px]'>{offerType}</td>

            <td className='pl-6 min-w-[226px]'>{translations.title[1]}</td>

            <td className='pl-6 min-w-[262px]'>{translations.description[1]}</td>

            {!activeState.reArrange && (
                <td className='pl-6'>
                    <div
                        className='flex flex-el items-center cursor-pointer'
                        onClick={unlinkOutlet}>
                        <LinkIcon
                            height={24}
                            width={24}
                            stroke='#6C5DD3'
                        />

                        <span className='ml-1 paragraph-small-regular text-primary-500'>Unlink</span>
                    </div>
                </td>
            )}
        </tr>
    );
}
