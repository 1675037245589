import React, { useRef, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { Tab } from '../../../Components/Tabs/Tabs';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { setOtherStateDefault } from '../Constants/Constants';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';

export default function Header(props) {
    const { setActiveState, setShowLinkOfferPopup } = props;

    const { tab } = useParams();

    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);

    const handleNavigation = (link) => {
        navigate(`/offers/${link}`);
        setOtherStateDefault(setActiveState, { selection: false, edit: false, reArrage: false });
    };

    const handleClickCreateOffer = () => {
        if (tab !== 'outlet-offer-list') {
            handleNavigation('create-offer/offer-type');
            return;
        }
        setShowMenu((prevState) => !prevState);
    };

    const handleClickNewOffer = () => {
        handleNavigation('create-offer/offer-type');
        setShowMenu(false);
    };

    const linkOffer = () => {
        setShowLinkOfferPopup(true);
        setShowMenu(false);
    };

    const menuRef = useRef();

    useOutsideClick(menuRef, () => {
        setShowMenu(false);
    });

    return (
        <>
            <section className='flex flex-row items-center justify-between pb-4 mb-4 border-b border-neutral-300 md:block'>
                <div className='flex flex-row items-center md:mb-4'>
                    <div
                        className='mr-2 md:w-1/2 lg:mr-1 cursor-pointer'
                        onClick={() => handleNavigation('offer-list')}>
                        <Tab
                            label='Offers list'
                            isActive={tab === 'offer-list'}
                            type='offer'
                        />
                    </div>

                    <div
                        className='ml-2 md:w-1/2 lg:ml-1 cursor-pointer'
                        onClick={() => handleNavigation('outlet-offer-list')}>
                        <Tab
                            label='Outlet-wise offers'
                            isActive={tab === 'outlet-offer-list'}
                            type='offer'
                        />
                    </div>
                </div>

                <div
                    className='relative'
                    ref={menuRef}>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickCreateOffer}>
                        <LargePrimaryButton
                            label={tab === 'outlet-offer-list' ? 'Create / Link offer' : 'Create offer'}
                            leftIconDefault={<AddIcon stroke='#FFFFFF' />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                            isClicked={showMenu}
                        />
                    </div>

                    {showMenu && (
                        <div className='border border-neutral-300 rounded-lg p-4 absolute top-[54px] bg-neutral-50 w-full'>
                            <div
                                className='w-full mb-2 pb-2 cursor-pointer'
                                onClick={handleClickNewOffer}>
                                Create a new offer
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={linkOffer}>
                                Link an existing offer
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
