import React, { useState } from 'react';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';
import { format, parse } from 'date-fns';
import EachDaySeparateTimeSlotPopup from './EachDaySeparateTimeSlotPopup';

export default function TimeSloteSection(props) {
    const { item } = props;

    const [showTimeSlot, setShowTimeSlot] = useState(false);

    if (item.time_slot_setting === 'no_time_slots') {
        return <div className=''>-</div>;
    }

    if (item.time_slot_setting === 'same_time_slot_for_all_days') {
        const scdeules = item.schedules.filter((el) => el.time_slots.day_of_week === 1).map((el) => el.time_slots);

        const convert12Hour = (time) => {
            const parsedTime = parse(time, 'HH:mm', new Date());

            const formatted12HourTime = format(parsedTime, 'hh:mm a');

            return formatted12HourTime;
        };

        return (
            <>
                {scdeules.map((el, index) => (
                    <div key={index}>
                        <SingleSectionCompoent
                            startTime={convert12Hour(el.start_time)}
                            endTime={convert12Hour(el.end_time)}
                        />
                    </div>
                ))}
            </>
        );
    }

    return (
        <>
            <span
                className='paragraph-small-regular text-primary-500 cursor-pointer'
                onClick={() => setShowTimeSlot(true)}>
                Show time slot
            </span>

            {showTimeSlot && (
                <EachDaySeparateTimeSlotPopup
                    handleClickClose={() => setShowTimeSlot(false)}
                    schedule={item.schedules}
                />
            )}
        </>
    );
}

const SingleSectionCompoent = (props) => {
    const { startTime, endTime } = props;

    return (
        <div className='flex flex-row items-center mb-1'>
            <ScheduleIcon
                width={24}
                height={24}
            />
            <span className='ml-1 paragraph-small-regular'>
                {startTime} - {endTime}
            </span>
        </div>
    );
};
