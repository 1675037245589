import React, { useState } from 'react';
import { IsIpadScreen } from '../../../../../Constants/Constants';
import { ReactComponent as CloseIcon } from '../../../../../Assets/close.svg';
import { TabBig } from '../../../../../Components/Tabs/Tabs';

export default function Layout(props) {
    const { type, setShowPopup, title = 'Title', statusTitle = 'Status Title', children } = props;

    const isIpadScreen = IsIpadScreen();

    const [isReservationForm, setIsReservationForm] = useState(type === 'reserve');

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4 md:relative md:z-[9] md:p-0'>
                <div className='max-w-[830px] w-full rounded-xl md:rounded-none bg-shades-50 px-8 py-6 m-auto relative md:px-4'>
                    {isIpadScreen && (
                        <div
                            className='cursor-pointer absolute top-[38px] right-[32px] mobile:right-5'
                            onClick={() => setShowPopup(null)}>
                            <CloseIcon />
                        </div>
                    )}

                    {isIpadScreen && (
                        <div className='pb-5 flex'>
                            <div
                                className='cursor-pointer'
                                onClick={() => setIsReservationForm(true)}>
                                <TabBig
                                    label='Reserve Table'
                                    isActive={isReservationForm}
                                    bottonStyle='mobile:px-1'
                                />
                            </div>
                            <div
                                className='ml-4 mobile:ml-2 cursor-pointer'
                                onClick={() => setIsReservationForm(false)}>
                                <TabBig
                                    label='Waitlist'
                                    isActive={!isReservationForm}
                                    bottonStyle='mobile:px-1'
                                />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div>
                            <div className='paragraph-large-medium'>{title}</div>
                            <div className='paragraph-medium-italic text-neutral-500'>{statusTitle}</div>
                        </div>
                        <div
                            className='cursor-pointer lg:hidden'
                            onClick={() => setShowPopup(null)}>
                            <CloseIcon />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
}
