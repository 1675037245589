import React from 'react';
import { InputArea } from '../../../../Components/InputField/InputField';
import MobileNumberCustomerName from './Components/MobileNumberCustomerName';
import DateAndTimeSection from './Components/DateAndTimeSection';
import GuestOccasionSection from './Components/GuestOccasionSection';
import TableSelectionSection from './Components/TableSelectionSection';
import Layout from './Components/Layout';
import BottomButtonSection from './Components/BottomButtonSection';

export default function ReserveWaitListFillDetails(props) {
    const { setShowPopup, type } = props;

    const isReservationForm = type === 'reserve';

    return (
        <>
            <Layout setShowPopup={setShowPopup}>
                <MobileNumberCustomerName />

                {isReservationForm && <DateAndTimeSection />}

                <GuestOccasionSection />

                <TableSelectionSection />

                <div className='mb-12'>
                    <InputArea
                        label='Special requests'
                        labelMarginB='mb-1'
                        placeholder='Add Special requests'
                    />
                </div>

                <BottomButtonSection
                    setShowPopup={setShowPopup}
                    isReservationForm={isReservationForm}
                />
            </Layout>
        </>
    );
}

//  select section and table will not be compulsory --> done
//  add refund button
//  if paid make disable text field of amount and besides it
// remove reservation status and ad step 1/1 2/2
