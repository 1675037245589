import React from 'react';
import ListViewOfferLink from './ListViewOfferLink';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { IsMobileScreen } from '../../../../../Constants/Constants';

export default function LinkOutletTable() {
    const linkOutletTableDetails = [
        {
            outletName: "Domino's Pizza - Master Outlet(All)",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
        },
        {
            outletName: "Domino's Pizza - Master Outlet(All)",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
        },
        {
            outletName: "Domino's Pizza - Master Outlet(All)",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
        },
        {
            outletName: "Domino's Pizza - Master Outlet(All)",
            state: 'Gujarat',
            city: 'Rajkot',
            address: 'Shyamal Infinity, beneath Radio Mirchi, kalawad road',
        },
    ];

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto [&::-webkit-scrollbar]:hidden border-neutral-300 border'>
                    <table className='w-full break-words table-row-bg'>
                        <thead>
                            <tr className='paragraph-overline-small text-neutral-700 shadow-innerShadow bg-neutral-50 text-left justify-center h-11'>
                                <th className='px-6 min-w-[90px]'>STATUS</th>
                                <th className='px-6 min-w-[302px]'>OUTLET NAME</th>
                                <th className='px-6 min-w-[102px] lg:min-w-[135px]'>STATE</th>
                                <th className='px-6 min-w-[92px] lg:min-w-[151px]'>CITY</th>
                                <th className='px-6 min-w-[334px]'>ADDRESS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {linkOutletTableDetails.map((el, index) => {
                                return (
                                    <tr
                                        className='paragraph-small-regular border-t first:border-none justify-center h-[70px]'
                                        key={index}>
                                        <td className='px-6'>
                                            <ToggleSwitch enable={true} />
                                        </td>
                                        <td className='px-6'>{el.outletName}</td>
                                        <td className='px-6'>{el.state}</td>
                                        <td className='px-6'>{el.city}</td>
                                        <td className='px-6'>{el.address}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className=''>
                    {linkOutletTableDetails.map((el, index) => (
                        <div
                            className='mt-2'
                            key={index}>
                            <ListViewOfferLink
                                outletName={el.outletName}
                                state={el.state}
                                city={el.city}
                                address={el.address}
                            />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}
