import React from 'react';
import { countryCodes } from '../../../../../Components/MobileNumberInputFlagDropDown/CountryData';
import Layout from '../Components/Layout';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import InputAreaSection from '../../../Components/InputAreaSection';
import InputSection from '../Components/InputSection';
import DropdownSection from '../../../Components/DropdownSection';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function Address(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const countryName = countryCodes.map((el) => {
        return { label: el.fullName, value: el };
    });

    const {
        watch,
        setValue,
        reset,
        formState: { isDirty, isValid, errors },
        handleSubmit,
    } = useFormContext();

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateAddress = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=outlet_address`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const formatData = (data) => {
        const translations = {};

        Object.keys(data.translations).forEach((key) => {
            const translation = {};
            data.translations[key].forEach((value, index) => {
                if (value !== null && index !== 0) {
                    translation[index] = value;
                }
            });
            translations[key] = translation;
        });

        const iso_country_code = data.countryCode.value.shortName;

        return {
            iso_country_code: iso_country_code,
            translations: translations,
        };
    };

    const onSubmit = (data) => {
        updateAddress(formatData(data.outlet_address));
        setShowTabChangeWarningPopup(false);
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                setShowTabChangeWarningPopup(false);
                handleDiscardForm();
            }}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full lg:max-w-[462px] md:max-w-full md:pb-[120px] overflow-auto md:overflow-hidden -mx-1 px-1'>
                <div className='mb-4'>
                    <TitleDescription
                        title='Outlet address'
                        description='The customers see this address. The accurately detailed address will help customers find your outlet.'
                    />
                </div>

                <div className='flex flex-row items-start gap-3 mb-4 lg:block'>
                    {availableLanguages.map((el, index) => {
                        return (
                            <div
                                className='w-1/2 lg:w-full lg:max-w-[312px] lg:mb-2 md:max-w-full'
                                key={index}>
                                <InputAreaSection
                                    name={`outlet_address.translations.address_line_1[${el.language_id}]`}
                                    label={`(${el.language_label}: Address line 1)`}
                                    shadow='shadow-smallDropDownShadow'
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    rules={{ required: 'Please fill this field' }}
                                    placeholder={`Enter address line 1 in ${el.language_label}`}
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />

                                {errors?.outlet_address?.translations?.address_line_1?.[el.language_id] && (
                                    <ErrorMessage
                                        errors={errors?.outlet_address.translations?.address_line_1[el.language_id]}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className='flex flex-row items-start gap-3 mb-4 lg:block'>
                    {availableLanguages.map((el, index) => {
                        return (
                            <div
                                className='w-1/2 lg:w-full lg:max-w-[312px] lg:mb-2 md:max-w-full'
                                key={index}>
                                <InputAreaSection
                                    name={`outlet_address.translations.address_line_2[${el.language_id}]`}
                                    label={`(${el.language_label}: Address line 2)`}
                                    shadow='shadow-smallDropDownShadow'
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    rules={{ required: 'Please fill this field' }}
                                    placeholder={`Enter address line 2 in ${el.language_label}`}
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />

                                {errors?.outlet_address?.translations?.address_line_2?.[el.language_id] && (
                                    <ErrorMessage
                                        errors={errors?.outlet_address?.translations?.address_line_2[el.language_id]}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className='flex flex-row items-start gap-3 mb-4 lg:block'>
                    {availableLanguages.map((el, index) => {
                        return (
                            <div
                                className='w-1/2 lg:w-full lg:max-w-[312px] lg:mb-2 md:max-w-full'
                                key={index}>
                                <InputSection
                                    name={`outlet_address.translations.city[${el.language_id}]`}
                                    rules={{ required: 'Please fill this field' }}
                                    placeholder={`Enter city in ${el.language_label}`}
                                    paddingT='pt-4'
                                    label={`(${el.language_label}: City)`}
                                    shadow='shadow-smallDropDownShadow'
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    boxHeight='[52px]'
                                    labelMarginB='pb-2'
                                    dontShowError
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />

                                {errors?.outlet_address?.translations?.city?.[el.language_id] && (
                                    <ErrorMessage
                                        errors={errors?.outlet_address?.translations?.city?.[el.language_id]}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className='flex flex-row items-start gap-3 mb-4 lg:block'>
                    {availableLanguages.map((el, index) => {
                        return (
                            <div
                                className='w-1/2 lg:w-full lg:max-w-[312px] lg:mb-2 md:max-w-full'
                                key={index}>
                                <InputSection
                                    name={`outlet_address.translations.state[${el.language_id}]`}
                                    rules={{ required: 'Please fill this field' }}
                                    placeholder={`Enter state in ${el.language_label}`}
                                    paddingT='pt-4'
                                    label={`(${el.language_label}: state)`}
                                    shadow='shadow-smallDropDownShadow'
                                    labelStyle='paragraph-medium-italic text-neutral-500'
                                    boxHeight='[52px]'
                                    labelMarginB='pb-2'
                                    dontShowError
                                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                                />

                                {errors?.outlet_address?.translations?.state?.[el.language_id] && (
                                    <ErrorMessage
                                        errors={errors?.outlet_address?.translations?.state?.[el.language_id]}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className='w-1/2 mr-1.5 relative lg:w-full lg:max-w-[312px] md:max-w-full pb-10'>
                    <DropdownSection
                        name='outlet_address.countryCode'
                        rules={{ required: 'Please fill this field' }}
                        openFromBottom
                        fixedHeight='h-[190px]'
                        label='(Country)'
                        placeholder='Select country'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        labelpaddingB='pb-2'
                        menuItems={countryName}
                        shadow='shadow-xSmall'
                        boxHeight='h-[48px]'
                        handleSelection={() => setShowTabChangeWarningPopup(true)}
                    />
                </div>
            </div>
        </Layout>
    );
}
