import { ReactComponent as DownArrowIcon } from '../../../../../../Assets/chevron-down.svg';

export const ListViewAppearance = (props) => {
    const { categoryDisplayCount, sectionName } = props;

    return (
        <>
            <div className='flex flex-row border justify-between border-neutral-300 rounded-md items-center px-4 py-3'>
                <div className='flex flex-col'>
                    <span className='paragraph-overline-small mb-1'>
                        {categoryDisplayCount ? 'CATEGORY DISPLAY COUNT:' : 'SECTION NAME:'}
                    </span>
                    <span className='paragraph-small-regular'>{categoryDisplayCount || sectionName}</span>
                </div>

                {categoryDisplayCount && (
                    <span>
                        <DownArrowIcon />
                    </span>
                )}
            </div>
        </>
    );
};
