import React from 'react';
import ContentLoader from 'react-content-loader';

export default function TableLoader(props) {
    const { hasSwitch, columns, numberOfRow } = props;

    const numberOfColumn = hasSwitch ? columns - 1 : columns;

    return (
        <>
            {[...Array(numberOfRow ?? 10)].map((_, index) => (
                <SingleTableRow
                    key={index}
                    numberOfColumn={numberOfColumn}
                    hasSwitch={hasSwitch}
                />
            ))}
        </>
    );
}

const SingleTableRow = (props) => {
    const { hasSwitch, numberOfColumn } = props;

    return (
        <tr className='border-b last:border-0'>
            {hasSwitch && (
                <td>
                    <div className='w-full h-full'>
                        <ContentLoader
                            width='100%'
                            height='100%'
                            className='h-[70px] w-full pl-6 pt-6'>
                            <rect
                                x='0'
                                y='0'
                                rx='12'
                                ry='12'
                                width='45'
                                height='24'
                            />
                        </ContentLoader>
                    </div>
                </td>
            )}

            {[...Array(numberOfColumn)].map((_, index) => (
                <td key={index}>
                    <div className='w-full h-full'>
                        <ContentLoader
                            width='100%'
                            height='100%'
                            className='h-[70px] w-full pl-6 pt-6'>
                            <rect
                                x='0'
                                y='0'
                                rx='4'
                                ry='4'
                                width='90%'
                                height='24'
                            />
                        </ContentLoader>
                    </div>
                </td>
            ))}
        </tr>
    );
};
