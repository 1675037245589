import React from 'react';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import { useWindowSize } from '@uidotdev/usehooks';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function ContentControllerButton(props) {
    const { setShowPopup, setShowReservationDetails } = props;

    const tableLocation = [
        { label: 'All', value: 'null' },
        { label: 'Ground floor', value: 'null' },
        { label: 'First floor', value: 'null' },
        { label: 'Balcony', value: 'null' },
        { label: 'Garden', value: 'null' },
        { label: 'Banquet Hall', value: 'null' },
    ];

    const { width: screenWidth } = useWindowSize();

    return (
        <>
            <div className='flex flex-row-reverse justify-between mb-6 md:block'>
                <div className='flex md:border-b md:border-neutral-300 md:pb-4 md:mb-4'>
                    <div
                        onClick={() => setShowPopup('reserveTable')}
                        className='md:w-1/2'>
                        <LargePrimaryButton
                            label={screenWidth > 1023 ? 'Reserve table' : 'Reserve / Waitlist'}
                            leftIconDefault={<AddIcon />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                            isDefault={false}
                            hideLabel='sm:text-sm mobile:text-xs'
                        />
                    </div>

                    <div
                        onClick={() => setShowPopup('waitingList')}
                        className='ml-4 lg:hidden'>
                        <LargePrimaryButton
                            label='Waitlist'
                            isDefault={false}
                            leftIconDefault={<AddIcon />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                        />
                    </div>

                    <div
                        className='ml-4 lg:ml-2 md:w-1/2'
                        onClick={() => setShowReservationDetails((prevState) => !prevState)}>
                        <LargePrimaryButton
                            label='Reservations / waitlist'
                            hideLabel='sm:text-sm mobile:text-xs xl:text-shades-50'
                            buttonStyle='xl:bg-primary-500'
                        />
                    </div>
                </div>

                <div className='w-full max-w-[278px] mr-2 md:mr-0 md:max-w-full'>
                    <DropdownWithValueLabel
                        selectedItem={tableLocation[0]}
                        menuItems={tableLocation}
                    />
                </div>
            </div>
        </>
    );
}
