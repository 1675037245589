import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import ContentLoader from 'react-content-loader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export default function Loader() {
    return (
        <>
            <div className='mt-4 flex flex-row max-w-full w-full'>
                <div className='max-w-[603px] mr-4 w-full'>
                    <TitleDescriptionLoader
                        className='max-w-[330px] md:max-w-full mb-2'
                        descHeight={30}
                    />
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[52px] w-full max-w-[360px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='360'
                            height={52}
                        />
                    </ContentLoader>
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[52px] w-full max-w-[360px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='360'
                            height={52}
                        />
                    </ContentLoader>
                    <TitleDescriptionLoader
                        className='max-w-[532px] md:max-w-full mb-2'
                        descHeight={40}
                    />
                    <DropdownBoxLoader className='max-w-[312px] md:max-w-full mb-2' />
                    <TitleDescriptionLoader
                        className='max-w-[330px] md:max-w-full mb-2'
                        descHeight={40}
                    />
                    <TitleDescriptionLoader
                        className='max-w-[330px] md:max-w-full mb-2'
                        descHeight={40}
                    />

                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[50px] w-full max-w-[100px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='100px'
                            height={50}
                        />
                    </ContentLoader>
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[50px] w-full max-w-[100px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='100px'
                            height={50}
                        />
                    </ContentLoader>
                    <TitleDescriptionLoader
                        className='max-w-[330px] md:max-w-full mb-2'
                        descHeight={30}
                    />
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[52px] w-full max-w-[360px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='360'
                            height={52}
                        />
                    </ContentLoader>
                    <TitleDescriptionLoader
                        className='max-w-[330px] md:max-w-full mb-2'
                        descHeight={30}
                    />
                    <ContentLoader
                        width='100%'
                        height='100%'
                        className={`h-[52px] w-full max-w-[360px] mb-4`}>
                        <rect
                            x='0'
                            y={0}
                            rx='12'
                            ry='12'
                            width='360'
                            height={52}
                        />
                    </ContentLoader>
                </div>

                <ContentLoader
                    width='100%'
                    height='100%'
                    className={`h-[666px] w-full max-w-[319px] ml-6`}>
                    <rect
                        x='0'
                        y={0}
                        rx='48'
                        ry='48'
                        width='319'
                        height={666}
                    />
                </ContentLoader>
            </div>
        </>
    );
}
