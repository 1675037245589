import React, { useState } from 'react';

import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';

export default function ListVIewOutletInformation() {
    const [isShowDetails, setIsShowDetails] = useState(false);

    const handleClickShowDetails = () => {
        setIsShowDetails(!isShowDetails);
    };

    return (
        <>
            <div className='mb-4 pb-4 border-b border-neutral-300'>
                <div className='w-full px-4 py-2.5 border border-neutral-300 rounded-md'>
                    <div
                        className='flex flex-row items-center justify-between cursor-pointer'
                        onClick={handleClickShowDetails}>
                        <div className='max-w-[223px]'>
                            <h3 className='paragraph-overline-small text-neutral-700'>OUTLET NAME:</h3>
                            <div className='paragraph-small-regular flex mt-1'>
                                Domino's Pizza - Univerisity Road Outlet
                            </div>
                        </div>
                        <div className='flex flex-row items-center'>
                            <div className={`${isShowDetails && 'rotate-180'}`}>
                                <DownArrow />
                            </div>
                        </div>
                    </div>

                    {isShowDetails && (
                        <div className='mt-1'>
                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>STATE:</span>
                                <span className='paragraph-small-regular ml-1'>Gujarat</span>
                            </div>
                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>CITY:</span>
                                <span className='paragraph-small-regular ml-1'>Rajkot</span>
                            </div>
                            <div className='mb-[5px] flex flex-row'>
                                <span className='paragraph-overline-small text-neutral-700'>ADDRESS:</span>
                                <div className='paragraph-small-regular ml-1 break-words'>
                                    Shyamal Infinity, beneath Radio Mirchi, kalawad road
                                </div>
                            </div>

                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>MENU NAME:</span>
                                <span className='paragraph-small-regular ml-1'>Menu - 007</span>
                            </div>

                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>CREATED BY:</span>
                                <span className='paragraph-small-regular ml-1'>Sarthak Kanchan</span>
                            </div>

                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>CREATED ON:</span>
                                <span className='paragraph-small-regular ml-1'>20 Nov 2022</span>
                            </div>

                            <div className='mb-[5px]'>
                                <span className='paragraph-overline-small text-neutral-700'>CATEGORIES ATTACHED:</span>
                                <span className='paragraph-small-regular ml-1'>12</span>
                            </div>

                            <div className=''>
                                <span className='paragraph-overline-small text-neutral-700'>DISHES ATTACHED:</span>
                                <span className='paragraph-small-regular ml-1'>08</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
