import React from 'react';
import { Tab } from '../../../../../../Components/Tabs/Tabs';
import Header from '../../../../../../Components/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';

const Tabs = (props) => {
    const { subTab } = useParams();

    const tabData = [
        {
            label: 'Category layout',
            key: 'category',
            title: 'Category layout',
            description: 'Define row-wise category layout for your mobile application.',
        },
        {
            label: 'Home screen layout',
            key: 'home',
            title: 'Home screen layout',
            description:
                'Define the hierarchy of the sections that your customers will view in the mobile application.',
        },
    ];

    const navigate = useNavigate();

    const handleNavigation = (route) => {
        navigate(`/settings/store/appearance/${route}`);
    };

    return (
        <>
            <div className='flex flex-row items-center mb-6 mobile:block'>
                {tabData.map((tab, index) => (
                    <div
                        className={`${
                            index === 0
                                ? 'mr-2 mobile:mr-0 mobile:mb-2 md:w-1/2 mobile:w-full'
                                : 'mobile:w-full md:w-1/2 ml-2 mobile:ml-0 min-w-[164px]'
                        } cursor-pointer`}
                        key={tab.key}
                        onClick={() => handleNavigation(tab.key)}>
                        <Tab
                            label={tab.label}
                            isActive={subTab === tab.key}
                            hideLabel='sm:text-sm mobile:text-base'
                            tabStyle='sm:px-1'
                        />
                    </div>
                ))}
            </div>
            <div className='mb-4'>
                <TitleDescription
                    title={tabData.find((tab) => tab.key === subTab)?.title}
                    description={tabData.find((tab) => tab.key === subTab)?.description}
                />
            </div>
        </>
    );
};

export default Tabs;
