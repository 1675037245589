import React, { useRef } from 'react';
import { ReactComponent as CalanderIcon } from '../../../Assets/calendar.svg';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import ListViewNotificationList from './ListViewNotificationList';
import ListViewPresetList from './ListViewPresetList';
import { IsMobileScreen } from '../../../Constants/Constants';
import { CheckBoxWithoutLabels } from '../../../Components/FormControl/FormControls';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import ListViewLoader from '../../../Components/ContentLoader/ListViewLoader';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { ListBlankComponentView } from '../../../Components/BlankComponent/ListBlankComponentView';

export const NotificationList = (props) => {
    const {
        handleClickTitle,
        showCheckbox,
        handleCheckboxChange,
        checkedItems,
        setCheckedItems,
        notificationList,
        selectedLangaugeId,
        fetchDetailsOfNotification,
        setNotificationId,
        editNotification,
        setEditNotification,
        isNotificationListLoading,
        fetchNotificationList,
        paginationState,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const selectAll = () => {
        // Check if all riderDetails IDs are in checkedItems
        const allNotificationIds = notificationList.map((notification) => notification.id);
        const allChecked = allNotificationIds.every((id) => checkedItems.includes(id));

        if (allChecked) {
            setCheckedItems([]);
        } else {
            const missingIds = allNotificationIds.filter((id) => !checkedItems.includes(id));
            setCheckedItems([...checkedItems, ...missingIds]);
        }
    };

    const cellRenderer = ({ index, key, parent, style }) => {
        const el = notificationList[index];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                {({ measure }) => (
                    <div
                        className='mt-2'
                        key={index}
                        style={{ ...style }}>
                        <ListViewNotificationList
                            date={el?.date}
                            description={el?.translations?.description[selectedLangaugeId]}
                            notificationTitle={el?.translations?.title[selectedLangaugeId]}
                            screen={el?.screen}
                            time={el?.time}
                            el={el}
                            notificationId={el?.id}
                            showCheckbox={showCheckbox}
                            handleCheckboxChange={handleCheckboxChange}
                            handleNotificationDetailPopup={() =>
                                handleClickTitle('Notification details', 'Edit/Delete/Resend notification', el)
                            }
                            onResize={measure}
                            cache={cache}
                            index={index}
                            fetchDetailsOfNotification={fetchDetailsOfNotification}
                            setEditNotification={setEditNotification}
                            editNotification={editNotification}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 150,
        })
    ).current;

    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight === scrollTop && paginationState.lastPage > paginationState.selectedPage) {
            fetchNotificationList({ page: paginationState.selectedPage + 1, isScroll: true });
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full border border-neutral-300 rounded-lg overflow-x-auto scrollbar-style mt-4'>
                    {isNotificationListLoading ? (
                        <table>
                            <tbody>
                                <TableLoader
                                    hasSwitch={false}
                                    columns={5}
                                />
                            </tbody>
                        </table>
                    ) : (
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='shadow-innerShadow paragraph-overline-small text-neutral-700 bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[230px]'>
                                        <div className='flex flex-row'>
                                            {showCheckbox && (
                                                <div className='mr-4 cursor-pointer'>
                                                    <CheckBoxWithoutLabels onChange={selectAll} />
                                                </div>
                                            )}
                                            NOTIFICATION TITLE
                                        </div>
                                    </th>
                                    <th className='px-6 min-w-[378px]'>DESCRIPTION</th>
                                    <th className='px-6 min-w-[202px]'>SCREEN</th>
                                    <th className='px-6 min-w-[234px]'>DATE</th>
                                    <th className='px-6 min-w-[220px]'>TIME</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notificationList.map((el, index) => {
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-neutral-300 justify-center h-[70px] border-t first:border-none'
                                            key={index}>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    {showCheckbox && (
                                                        <div className='mr-4 cursor-pointer'>
                                                            <CheckBoxWithoutLabels
                                                                onChange={(isChecked) => {
                                                                    handleCheckboxChange(el.id, isChecked);
                                                                }}
                                                                isChecked={checkedItems.includes(el.id)}
                                                            />
                                                        </div>
                                                    )}
                                                    <span
                                                        className='cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                        onClick={() => {
                                                            handleClickTitle(
                                                                'Notification details',
                                                                'Edit/Delete/Resend notification',
                                                                el
                                                            );
                                                            fetchDetailsOfNotification(el.id);
                                                            setEditNotification(!editNotification);
                                                        }}>
                                                        {el?.translations?.title[selectedLangaugeId]}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className='px-6'>
                                                {el?.translations?.description[selectedLangaugeId]}
                                            </td>
                                            <td className='px-6'>{el.screen}</td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    <CalanderIcon />
                                                    <span className='ml-1'>{el.date}</span>
                                                </div>
                                            </td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    <TimerIcon />
                                                    <span className='ml-1'>{el.time}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : isNotificationListLoading ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mt-4'>
                    {notificationList && notificationList.length > 0 ? (
                        <List
                            width={700}
                            height={600}
                            rowCount={notificationList.length}
                            rowHeight={cache.rowHeight}
                            rowRenderer={cellRenderer}
                            onScroll={handleScroll}
                            deferredMeasurementCache={cache}
                            autoWidth
                        />
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
};

export const PresetList = (props) => {
    const {
        handleClickName,
        presetList,
        selectedLangaugeId,
        fetchDetailsOfPreset,
        setEditPreset,
        editPreset,
        isPresetListLoading,
        fetchPresetList,
        paginationState,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const cellRenderer = ({ index, key, parent, style }) => {
        const el = presetList[index];
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}>
                {({ measure }) => (
                    <div
                        className='mt-2'
                        key={index}
                        style={{ ...style }}>
                        <ListViewPresetList
                            el={el}
                            presetName={el?.preset_name}
                            notificationTitle={el?.translations?.title[selectedLangaugeId]}
                            description={el?.translations?.description[selectedLangaugeId]}
                            modificationDate={el?.modification_date}
                            modificationTime={el?.modification_time}
                            handleClickViewDetails={() =>
                                handleClickName('Edit preset', 'Edit preset notification settings', el)
                            }
                            onResize={measure}
                            cache={cache}
                            index={index}
                            fetchDetailsOfPreset={fetchDetailsOfPreset}
                            setEditPreset={setEditPreset}
                            editPreset={editPreset}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 150,
        })
    ).current;

    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight === scrollTop && paginationState.lastPage > paginationState.selectedPage) {
            fetchPresetList({ page: paginationState.selectedPage + 1, isScroll: true });
        }
    };

    return (
        <>
            {!isMobileScreen ? (
                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border mt-4'>
                    {isPresetListLoading ? (
                        <table>
                            <tbody>
                                <TableLoader
                                    hasSwitch={false}
                                    columns={5}
                                />
                            </tbody>
                        </table>
                    ) : (
                        <table className='w-full break-words table-row-bg'>
                            <thead>
                                <tr className='shadow-innerShadow paragraph-overline-small text-neutral-700 bg-neutral-50 text-left justify-center h-11'>
                                    <th className='px-6 min-w-[196px]'>PRESET NAME</th>
                                    <th className='px-6 min-w-[236px]'>NOTIFICATION TITLE</th>
                                    <th className='px-6 min-w-[378px]'>DESCRIPTION</th>
                                    <th className='px-6 min-w-[225px]'>MODIFICATION DATE</th>
                                    <th className='px-6 min-w-[234px]'>MODIFICATION TIME</th>
                                </tr>
                            </thead>
                            <tbody>
                                {presetList.map((el, index) => {
                                    return (
                                        <tr
                                            className='paragraph-small-regular border-t first:border-none border-neutral-300 justify-center h-[70px]'
                                            key={index}>
                                            <td
                                                className='px-6 cursor-pointer text-primary-500 hover:underline underline-offset-3'
                                                onClick={() => {
                                                    fetchDetailsOfPreset(el);
                                                    setEditPreset(!editPreset);
                                                }}>
                                                {el?.preset_name}
                                            </td>
                                            <td className='px-6'>{el?.translations?.title[selectedLangaugeId]}</td>
                                            <td className='px-6'>
                                                {el?.translations?.description[selectedLangaugeId]}
                                            </td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    <CalanderIcon />
                                                    <span className='ml-1'>{el?.modification_date}</span>
                                                </div>
                                            </td>
                                            <td className='px-6'>
                                                <div className='flex flex-row items-center'>
                                                    <TimerIcon />
                                                    <span className='ml-1'>{el?.modification_time}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            ) : isPresetListLoading ? (
                <div>
                    {[...Array(10)].map((_, index) => (
                        <ListViewLoader
                            key={index}
                            hasSwitch={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='mt-4'>
                    {presetList && presetList.length > 0 ? (
                        <List
                            width={700}
                            height={600}
                            rowCount={presetList.length}
                            rowHeight={cache.rowHeight}
                            rowRenderer={cellRenderer}
                            onScroll={handleScroll}
                            deferredMeasurementCache={cache}
                            autoWidth
                        />
                    ) : (
                        <ListBlankComponentView previewText={'No Content Available'} />
                    )}
                </div>
            )}
        </>
    );
};
