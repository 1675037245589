import React from 'react';
import ContentLoader from 'react-content-loader';

const SetAlertLoader = () => {
    return (
        <ContentLoader
            width='852px'
            height='250px'
            className=' w-fit rounded-md'>
            {/* header */}
            <rect
                x='6'
                y='5'
                rx='4'
                ry='4'
                width='180'
                height='35'
            />
            <rect
                x='380'
                y='5'
                rx='4'
                ry='4'
                width='30'
                height='30'
            />
            <rect
                x='6'
                y='50'
                rx='4'
                ry='4'
                width='300'
                height='20'
            />
            {/* second part */}
            <rect
                x='6'
                y='90'
                rx='4'
                ry='4'
                width='180'
                height='20'
            />
            <rect
                x='6'
                y='120'
                rx='4'
                ry='4'
                width='395'
                height='40'
            />
            {/* first rider line */}

            <rect
                x='6'
                y='200'
                rx='4'
                ry='4'
                width='395'
                height='40'
            />
        </ContentLoader>
    );
};

export default SetAlertLoader;
