import React, { useEffect, useRef, useState } from 'react';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import useOutsideClick from '../../../../Helper/Hooks/useOutsideClick';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import { SingleLoader } from '../../../../Components/ContentLoader/DropdownLoader';
import { useParams } from 'react-router-dom';
import { bannerCategoryType } from '../../Utils/constants';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function RearrangeFlyout(props) {
    const { handleClickClose, selectedPage, updateSequence } = props;

    const { bannerType } = useParams();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [menuItem, setMenuItem] = useState([]);

    const fetchBanners = async (params) => {
        const banner_type = bannerCategoryType.find((category) => category.url === bannerType).apiParam;

        try {
            const response = await APIV2.get(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/banners`,
                { params: { ...params, banner_type } }
            );

            response.banners.last_page === params.page && setIsLastPage(true);

            return await response.banners;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const fetchedData = await fetchBanners({ page: 1, per_page: 10 });

            setMenuItem(fetchedData.data);

            setIsListLoading(false);
        };

        fetchData();
    }, []);

    const ref = useRef();

    const [isLastPage, setIsLastPage] = useState(false);

    const [pageState, setPageState] = useState(1);

    const [isListLoading, setIsListLoading] = useState(true);

    useOutsideClick(ref, handleClickClose);

    useEffect(() => {
        setPageState(selectedPage);
    }, [selectedPage]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const fetchMoreData = async () => {
        setIsListLoading(true);

        const response = await fetchBanners({ page: pageState + 1, per_page: 10 });

        if (response) {
            setMenuItem((prevItems) => [...prevItems, ...response.data]);

            setPageState(pageState + 1);

            setIsListLoading(false);
        }
    };

    const handleDragOver = (event) => {
        const { over } = event;

        const thirdLastIndex = menuItem.length - 2;

        if (!isLastPage && over && over.id === menuItem[thirdLastIndex].id) {
            fetchMoreData();
        }
    };

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setMenuItem((items) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }

    const updateListSequence = async () => {
        const list = menuItem.map((el) => ({ id: el.id }));

        try {
            const response = await APIV2.patch(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/banners/change/sequence`,
                { banners: list }
            );
            if (response.success) {
                handleClickClose();
                updateSequence();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center items-center overflow-auto md:bg-white md:relative p-4'>
                <div
                    className='max-w-[800px] w-full right-0 fixed h-screen bg-shades-50 px-8 py-8 md:px-0 md:pb-0 m-auto md:max-w-full overflow-auto md:relative md:pt-0 md:h-auto'
                    ref={ref}>
                    {isMobileScreen && (
                        <div className='flex flex-row items-center mb-4'>
                            <span
                                className='mr-2 cursor-pointer'
                                onClick={handleClickClose}>
                                <LeftArrowIcon className='rotate-90' />
                            </span>

                            <div className='paragraph-large-medium'>Back to banner page</div>
                        </div>
                    )}

                    <div className='paragraph-large-medium mb-8 md:mb-4'>Re-arrage offer list</div>

                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToVerticalAxis,
                            restrictToParentElement,
                            restrictToFirstScrollableAncestor,
                        ]}
                        onDragOver={handleDragOver}>
                        <TableHeader />

                        <div
                            className={`overflow-auto border border-neutral-300 rounded-b-xl border-t-0 w-full ${menuItem?.length > 10 ? 'h-[70.5vh]' : 'h-fit'}`}>
                            <SortableContext
                                items={menuItem}
                                strategy={verticalListSortingStrategy}>
                                {menuItem?.map((el, index) => {
                                    return (
                                        <TableRows
                                            {...el}
                                            key={el.id}
                                            index={index}
                                        />
                                    );
                                })}
                            </SortableContext>

                            {isListLoading && (
                                <div className='flex flex-row border-t border-neutral-300'>
                                    <div className='w-1/2'>
                                        <SingleLoader className='max-w-[170px] h-[24px] my-5 ml-6' />
                                    </div>
                                    <div className=''>
                                        <SingleLoader className='max-w-[170px] h-[24px] my-5 ml-6' />
                                    </div>
                                </div>
                            )}
                        </div>
                    </DndContext>

                    <div className='flex flex-row items-center absolute bottom-5 w-full max-w-[735px] md:fixed md:bottom-0 md:shadow-dropShadow md:-ml-4 md:pl-4 md:pr-4 md:pb-4 md:bg-white md:pt-3'>
                        <div
                            className='w-1/2 mr-4'
                            onClick={handleClickClose}>
                            <LargeTertiaryButton label='Cancel' />
                        </div>

                        <div
                            className='w-1/2 ml-4'
                            onClick={updateListSequence}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
