import React from 'react';
import DropdownWithValueLabel from '../../../Components/DropDown/DropdownWithValueLabel';

export default function RiderOrderFilter(props) {
    const { setAppliedRiderOrderFilter, setAppliedFilter } = props;

    const riderOrderFilterStatus = [
        { label: 'All', value: null },
        { label: 'Cash on delivery', value: 'cash_on_delivery' },
        { label: 'Online', value: 'online' },
    ];
    const riderOrderFilterRatingStatus = [
        { label: 'All', value: null },
        { label: 'Good', value: 'good' },
        { label: 'Average', value: 'average' },
        { label: 'Bad', value: 'bad' },
    ];

    const handleSelection = (selection) => {
        setAppliedRiderOrderFilter(selection);
    };
    const handleRatingSelection = (selection) => {
        setAppliedFilter(selection);
    };

    return (
        <section className='flex md:block items-center justify-end mb-6 md:mb-4 mt-4'>
            <div className='flex sm:block'>
                <div className='w-[200px] md:w-1/2 sm:w-full mr-2 sm:mr-0 sm:mb-3 md:mr-1'>
                    <DropdownWithValueLabel
                        menuItems={riderOrderFilterStatus}
                        label='Payment Mode'
                        placeholder='Payment Mode'
                        selectedItem={{ label: 'All', value: null }}
                        setSelected={(selection) => handleSelection(selection.value)}
                    />
                </div>
                <div className='w-[200px] md:w-1/2 sm:w-full ml-2 sm:ml-0 md:ml-1'>
                    <DropdownWithValueLabel
                        menuItems={riderOrderFilterRatingStatus}
                        label='Rating Type'
                        placeholder='Rating Type'
                        selectedItem={{ label: 'All', value: null }}
                        setSelected={(selection) => handleRatingSelection(selection.value)}
                    />
                </div>
            </div>
        </section>
    );
}
