import React from 'react';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { preferenceMenuItem } from '../../../../Constants/Constants';

export default function UpSellCurrentCart() {
    return (
        <>
            <DropdownSection
                title='Up-sell current dish in cart'
                description='Would you like to up-sell the current dish in the cart? It will be recommended in the cart section of the ordering process within the mobile application.'
                marginBetween='mb-6'
                label='(Up-sell current dish)'
                menuItems={preferenceMenuItem}
                labelStyle='paragraph-medium-italic text-neutral-500'
                labelPaddingB='pb-2'
                name='upSellInCart'
                placeholder='Select your preference'
            />
        </>
    );
}
