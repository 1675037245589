import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { InputArea } from './InputField';
import TitleDescription from '../TitleDescription/TitleDescription';

export default function InputAreaSection(props) {
    const {
        title,
        description,
        name,
        disabled,
        placeholder,
        boxHeight,
        label,
        labelStyle,
        marginBetween,
        maxWidth,
        className,
        rules,
        onFocus,
    } = props;

    const { control, setValue } = useFormContext();

    useEffect(() => {
        disabled && setValue(name, '');

        //eslint-disable-next-line
    }, [disabled]);

    const handleInputChange = (value) => {
        props?.handleInputChange?.(value);
    };

    return (
        <>
            {(title || description) && (
                <div className={`${(title || description) && !marginBetween && 'mb-4'} ${marginBetween}`}>
                    <TitleDescription
                        title={title}
                        disabled={disabled}
                        description={description}
                    />
                </div>
            )}

            <div className={`w-full md:max-w-full ${maxWidth ?? 'max-w-[312px]'} ${className}`}>
                <Controller
                    render={({ field }) => (
                        <>
                            <InputArea
                                boxHeight={boxHeight}
                                labelMarginB='pb-2'
                                placeholder={placeholder}
                                shadow='shadow-xSmall'
                                enteredValue={(e) => {
                                    handleInputChange(e);
                                    field.onChange(e);
                                }}
                                disabled={disabled}
                                inputValue={field.value ?? ''}
                                label={label}
                                labelStyle={labelStyle}
                                onFocus={onFocus}
                            />
                        </>
                    )}
                    name={name}
                    control={control}
                    rules={rules ?? {}}
                />
            </div>
        </>
    );
}
