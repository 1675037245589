import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import { TableRows } from './TableComponent';
import { BlankComponentTable } from '../../../../Components/BlankComponent/BlankComponent';
import TableLoader from '../../../../Components/ContentLoader/TableLoader';
import { CheckBoxWithoutLabels } from '../../../../Components/FormControl/FormControls';

export default function TableSection(props) {
    const {
        tab,
        list,
        selectedBanners,
        setEditBannerData,
        setLinkBannerData,
        handleClickUnlink,
        handleSelection,
        bannerType,
        setStoreBannerList,
        pageState,
        isLoading,
    } = props;

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(list, result.source.index, result.destination.index);

        updateListSequence(reorderedItems);

        const parts = bannerType.split('-');

        const resultString = parts[0] + parts[1].charAt(0).toUpperCase() + parts[1].slice(1);

        setStoreBannerList((prevState) => {
            return { ...prevState, [resultString]: reorderedItems };
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateListSequence = async (updatedList) => {
        const list = updatedList.map((el) => {
            return { id: el.id };
        });

        try {
            await APIV2.patch(
                `/restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/banners/change/sequence`,
                { banners: list }
            );
        } catch (error) {
            console.log(error);
        }
    };

    const isSelectionEnable = pageState.isSelectionEnable;

    const handleAllSelection = (isSelected) => {
        list?.forEach((el) => handleSelection(isSelected, el.id));
    };
    return (
        <>
            <section className='w-full border border-neutral-300 rounded-lg overflow-x-auto scrollbar-style'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 h-11 justify-center'>
                            <th className='text-left pl-6 min-w-[254px] shadow-innerShadow'>
                                <div className='flex flex-row'>
                                    {isSelectionEnable && (
                                        <span className='mr-2 -ml-2'>
                                            <CheckBoxWithoutLabels
                                                onChange={(isSelected) => handleAllSelection(isSelected)}
                                                id={0}
                                                isChecked={list?.every?.((el) => selectedBanners.includes(el.id))}
                                            />
                                        </span>
                                    )}
                                    BANNER
                                </div>
                            </th>
                            <th className='text-left pl-6 min-w-[158px] shadow-innerShadow'>VALID FROM</th>
                            <th className='text-left pl-6 min-w-[159px] shadow-innerShadow'>VALID TILL</th>
                            <th className='text-left pl-6 min-w-[156px] shadow-innerShadow'>BANNER SCREEN</th>
                            <th className='text-left pl-6 min-w-[177px] shadow-innerShadow'>AVAILABLE MODES</th>
                            <th className='text-left pl-6 min-w-[215px] shadow-innerShadow'>AVAILABLE TIME SLOTS</th>
                            <th className='text-left pl-6 min-w-[151px] shadow-innerShadow'>
                                {tab === 'banner-catalogue' ? 'LINK OUTLET' : 'UNLINK OUTLET'}
                            </th>
                        </tr>
                    </thead>

                    {isLoading ? (
                        <tbody>
                            <TableLoader columns={7} />
                        </tbody>
                    ) : !list?.length ? (
                        <BlankComponentTable
                            previewText='No banner at this moment'
                            colSpan={7}
                        />
                    ) : (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='droppable'>
                                {(provided) => (
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {list?.map((el, index) => {
                                            return (
                                                <Draggable
                                                    key={el.id}
                                                    draggableId={`${el.id}`}
                                                    index={index}
                                                    isDragDisabled={true}>
                                                    {(provided) => (
                                                        <TableRows
                                                            handleClickUnlink={handleClickUnlink}
                                                            isEditbanner={pageState.isEditbanner}
                                                            tab={tab}
                                                            item={el}
                                                            provided={provided}
                                                            isRowSelected={selectedBanners.includes(el.id)}
                                                            isSelectionEnable={isSelectionEnable}
                                                            setLinkBannerData={setLinkBannerData}
                                                            handleSelection={(isSelected) =>
                                                                handleSelection(isSelected, el.id)
                                                            }
                                                            setEditBannerData={setEditBannerData}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </table>
            </section>
        </>
    );
}
