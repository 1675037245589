import React from 'react';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { DefaultInputField } from '../InputField/InputField';
import Dropdown from '../DropDown/Dropdown';

function Header(props) {
    const {
        hasInputField,
        hasDropDown,
        placeholder,
        isEnable,
        title,
        headerBottomLine,
        hasSwitch,
        labelMarginB,
        label,
        labelStyle,
        shadow,
        labelPaddingB,
        placeholderTextColor,
        menuItems,
        marginBetween,
        page,
        boxHeight,
        selectedItem,
        openFromBottom,
        fixedHeight,
        setSelectedItemFromDropDown,
        enteredValue,
        value,
    } = props;

    return (
        <div className={`max-w-[636px] ${page !== 'menuManagement' && 'lg:max-w-[462px]'} md:max-w-full`}>
            <div className='flex flex-row items-center justify-between mb-1'>
                <h1 className={`paragraph-large-medium ${page !== 'menuManagement' && 'md:paragraph-medium-medium'}`}>
                    {title}
                </h1>
                {hasSwitch && <ToggleSwitch isEnable={isEnable} />}
            </div>
            <span
                className={`paragraph-medium-italic text-neutral-500 ${
                    page !== 'menuManagement' && 'md:paragraph-small-italic'
                }`}>
                {headerBottomLine}
            </span>
            {(hasInputField || hasDropDown) && (
                <div className={`${marginBetween ?? 'mt-4'} max-w-[312px] w-full relative md:max-w-full`}>
                    {hasInputField && (
                        <DefaultInputField
                            boxHeight={boxHeight ?? '[52px]'}
                            labelMarginB={labelMarginB}
                            label={label}
                            labelStyle={labelStyle}
                            placeholder={placeholder}
                            placeholderTextColor={placeholderTextColor}
                            shadow={shadow}
                            enteredValue={enteredValue}
                            value={value}
                        />
                    )}
                    {hasDropDown && (
                        <Dropdown
                            fixedHeight={fixedHeight}
                            height={boxHeight ?? 'h-[52px]'}
                            label={label}
                            menuItems={menuItems ?? []}
                            labelPaddingB={labelPaddingB}
                            labelStyle={labelStyle}
                            shadow={shadow}
                            placeholder={placeholder}
                            selectedItem={selectedItem}
                            openFromBottom={openFromBottom}
                            setSelectedItemFromDropDown={setSelectedItemFromDropDown}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default Header;
