import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import TableLoader from '../../../../../../Components/ContentLoader/TableLoader';
import ContentLoader from 'react-content-loader';

export default function Loader() {
    return (
        <>
            <div className='flex mt-6 flex-row'>
                <div className=''>
                    <div className='flex flex-row items-center'>
                        <DropdownBoxLoader
                            className='max-w-[150px] md:max-w-full mb-2'
                            height={50}
                            boxHeight='h-12'
                        />
                        <DropdownBoxLoader
                            className='max-w-[180px] md:max-w-full mb-2 ml-6'
                            height={50}
                            boxHeight='h-12'
                        />
                    </div>

                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />

                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border max-w-[636px] my-4'>
                        <table className='w-full break-words table-row-bg'>
                            <tbody>
                                <TableLoader
                                    numberOfRow={6}
                                    columns={3}
                                />
                            </tbody>
                        </table>
                    </div>

                    <TitleDescriptionLoader
                        className='max-w-[600px] md:max-w-full mb-2 mt-6'
                        descHeight={50}
                    />
                    <DropdownBoxLoader
                        className='max-w-[320px] md:max-w-full mb-2 mt-6'
                        height={50}
                        boxHeight='h-12'
                    />
                </div>

                <ContentLoader
                    width='100%'
                    height='100%'
                    className={`h-[666px] w-full max-w-[319px] ml-6`}>
                    <rect
                        x='0'
                        y={0}
                        rx='48'
                        ry='48'
                        width='319'
                        height={666}
                    />
                </ContentLoader>
            </div>
        </>
    );
}
