import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as EnableIcon } from '../../../../Assets/success-tick.svg';
import { ReactComponent as DisableIcon } from '../../../../Assets/cancel.svg';

export default function DropDownUnableDisable(props) {
    const { handleSelection, className, selectedValue, height, disabled } = props;

    const menuItems = [
        {
            icon: <EnableIcon stroke='#FAFAFA' />,
            label: 'Enable',
            value: true,
        },
        {
            icon: <DisableIcon stroke='#FAFAFA' />,
            label: 'Disable',
            value: false,
        },
    ];

    const [selectedItem, setSelectedItem] = useState();

    useEffect(() => {
        const selectedItem = menuItems.find((el) => el.value === !!selectedValue);

        setSelectedItem(selectedItem);

        //eslint-disable-next-line
    }, [selectedValue]);

    const handleClickMenuItem = (selection) => {
        setSelectedItem(selection);
        handleSelection?.(selection.value);
    };

    return (
        <>
            <div className={`relative ${className}`}>
                <Menu as='div'>
                    <div className='dropdown-icon'>
                        <Menu.Button
                            disabled={disabled}
                            className={`shadow-xSmall w-full flex flex-row justify-between mobile:max-w-full rounded-md outline-none border py-[14px] appearance-none px-4 border-neutral-300 paragraph-small-regular ${
                                height ?? 'h-[52px]'
                            }`}>
                            {disabled ? (
                                <span className='paragraph-small-regular text-neutral-300'>This field is disabled</span>
                            ) : (
                                <div className='flex flex-row items-center'>
                                    {selectedItem?.icon}

                                    <span className={`ml-2 paragraph-small-regular ${disabled && 'text-neutral-300'}`}>
                                        {selectedItem?.label}
                                    </span>
                                </div>
                            )}

                            <DownArrowIcon
                                className={`drop-down-icon-rotate min-w-[24px] min-h-[24px]`}
                                fill={disabled ? '#D3D2D8' : '#131126'}
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'>
                        <Menu.Items className='absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50'>
                            {menuItems.map((el, index) => {
                                return (
                                    <div
                                        className='pt-2 mb-2 cursor-pointer'
                                        key={index}
                                        onClick={() => handleClickMenuItem(el)}>
                                        <Menu.Item>
                                            <div className='flex flow-row items-center'>
                                                {el.icon}
                                                <span className='paragraph-small-regular ml-1'>{el.label}</span>
                                            </div>
                                        </Menu.Item>
                                    </div>
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </>
    );
}
