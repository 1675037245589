import React from 'react';
import { useFormContext } from 'react-hook-form';
import TwoInputAreaSection from '../../Components/TwoInputAreaSection';
import APIV5 from '../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';

export default function AboutUs(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, isValid },
    } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateAboutUsSetting = async (params) => {
        try {
            const response = await APIV5.put(`/restaurants/${selectedRestaurantId}?section=about_us`, { ...params });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = (data) => {
        const formattedData = {};

        data.translations.about_us.forEach((value, index) => {
            if (value !== null) {
                formattedData[index.toString()] = value;
            }
        });

        updateAboutUsSetting({ translations: { about_us: formattedData } });
    };

    return (
        <TwoInputAreaSection
            showFooter={isDirty}
            title='About us'
            description='Write about us information that will go into the mobile application for your customers to see in the help section.'
            placeholder='Enter about us information in'
            name='about_us'
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => handleDiscardForm()}
            isLoading={watch('isLoadingForm')}
        />
    );
}
