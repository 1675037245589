import React, { useEffect } from 'react';
import InputSection from '../../../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function CashbackReemSection() {
    const {
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const orderingModeSetting = watch('cashback_settings.restaurant_ordering_mode_cashback_settings');

    useEffect(() => {
        orderingModeSetting?.map((el, index) => {
            setValue(`cashback_settings.cashback_redeem_percentage.${index}`, el.cashback_redeem_percentage, {
                shouldDirty: false,
            });
            setValue(
                `defaultValue.cashback_settings.cashback_redeem_percentage.${index}`,
                el.cashback_redeem_percentage,
                { shouldDirty: false }
            );
        });

        // eslint-disable-next-line
    }, [orderingModeSetting]);

    return (
        <>
            {watch('cashback_settings.sameAdvanceConfiguration') !== false ? (
                <div className='mb-4'>
                    <InputSection
                        name='cashback_settings.same_cashback_redeem_percentage'
                        title='Cashback redeem percentage'
                        placeholder='Enter cashback in percentage'
                        shadow='shadow-smallDropDownShadow'
                        disabled={!watch('cashback_settings.advanceSettingEnable')}
                        enteringLimit={100}
                        inputType='number'
                        marginBetween='mb-2'
                        rules={
                            watch('cashback_settings.advanceSettingEnable')
                                ? { required: 'Please fill this field' }
                                : {}
                        }
                    />

                    {errors?.cashback_settings?.same_cashback_redeem_percentage && (
                        <ErrorMessage errors={errors.cashback_settings.same_cashback_redeem_percentage} />
                    )}
                </div>
            ) : (
                watch('orderingModes')?.map((el, index) => {
                    return (
                        <div
                            key={index}
                            className='mb-4'>
                            <InputSection
                                name={`cashback_settings.cashback_redeem_percentage.${index}`}
                                title={`${el.display_name} redeem percentage`}
                                placeholder='Enter cashback in percentage'
                                shadow='shadow-smallDropDownShadow'
                                disabled={!watch('cashback_settings.advanceSettingEnable')}
                                enteringLimit={100}
                                inputType='number'
                                dontShowError
                                marginBetween='mb-2'
                                rules={
                                    watch('cashback_settings.advanceSettingEnable')
                                        ? { required: 'Please fill this field' }
                                        : {}
                                }
                            />

                            {errors?.cashback_settings?.cashback_redeem_percentage?.[index] && (
                                <ErrorMessage errors={errors.cashback_settings.cashback_redeem_percentage?.[index]} />
                            )}
                        </div>
                    );
                })
            )}
        </>
    );
}
