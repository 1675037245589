import React from 'react';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import ListViewCustomerIndividualDetails from './ListViewCustomerIndividualDetails';
import { IsMobileScreen } from '../../../Constants/Constants';

import CustomerDetailsTopLoader from './CustomerDetailsTopLoader';

export default function OrderDetailsTop(props) {
    const { goToCustomerInformation, customerDetails, isCustomerTopLoading } = props;

    const isMobileScreen = IsMobileScreen();
    return (
        <>
            {isMobileScreen && (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={goToCustomerInformation}>
                    <LeftArrow className='rotate-90' />
                    <span className='ml-1'>Back to individuals</span>
                </div>
            )}

            {!isMobileScreen ? (
                isCustomerTopLoading ? (
                    <CustomerDetailsTopLoader />
                ) : (
                    <div className='grid grid-cols-[minmax(198px,306px)_minmax(263px,371px)_minmax(232px,340px)_255px] xl:grid-cols-[minmax(232px,1336px)_263px] pb-4 mb-4 border-b border-neutral-300'>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Name:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.name ? customerDetails?.name : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Mobile number:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails.country_code && customerDetails.mobile_number
                                    ? `${customerDetails.country_code} ${customerDetails.mobile_number}`
                                    : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Email ID:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.email ? customerDetails?.email : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Revenue generated:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.revenue_generated
                                    ? `₹${customerDetails?.revenue_generated}/-`
                                    : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Total orders:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.total_orders
                                    ? customerDetails?.total_orders
                                    : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Last order:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.last_order ? customerDetails?.last_order : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>First order:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.first_order ? customerDetails?.first_order : 'N/A'}
                            </span>
                        </div>
                        <div className='mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Join date:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.join_date ? customerDetails?.join_date : 'N/A'}
                            </span>
                        </div>
                        <div className='xl:mb-3'>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Order rating:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.order_rating
                                    ? customerDetails.order_rating
                                    : 'N/A'}
                            </span>
                        </div>
                        <div className=''>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Cashback earned:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {customerDetails && customerDetails?.cashback
                                    ? `₹${customerDetails?.cashback}/-`
                                    : 'N/A'}
                            </span>
                        </div>
                        {/* remaining from api side */}
                        <div className=''>
                            <span className='paragraph-medium-semi-bold text-neutral-400'>Discount availed:</span>
                            <span className='paragraph-medium-regular ml-1'>
                                {' '}
                                {customerDetails && customerDetails?.discount_availed
                                    ? `₹${customerDetails?.discount_availed}/-`
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                )
            ) : (
                <div className='mb-4 pb-4 border-b border-neutral-300'>
                    <ListViewCustomerIndividualDetails
                        customerName={customerDetails && customerDetails?.name ? customerDetails?.name : 'N/A'}
                        mobileNumber={
                            customerDetails && customerDetails.country_code && customerDetails.mobile_number
                                ? `${customerDetails.country_code} ${customerDetails.mobile_number}`
                                : 'N/A'
                        }
                        totalOrders={
                            customerDetails && customerDetails?.total_orders ? customerDetails?.total_orders : 'N/A'
                        }
                        cashbackEarned={
                            customerDetails && customerDetails?.cashback ? `₹${customerDetails?.cashback}/-` : 'N/A'
                        }
                        lastOrder={customerDetails && customerDetails?.last_order ? customerDetails?.last_order : 'N/A'}
                        orderRating={
                            customerDetails && customerDetails?.order_rating ? customerDetails.order_rating : 'N/A'
                        }
                        emailId={customerDetails && customerDetails?.email ? customerDetails?.email : 'N/A'}
                        revenueGenerated={
                            customerDetails && customerDetails?.revenue_generated
                                ? `₹${customerDetails?.revenue_generated}/-`
                                : 'N/A'
                        }
                        firstOrder={
                            customerDetails && customerDetails?.first_order ? customerDetails?.first_order : 'N/A'
                        }
                        joinDate={customerDetails && customerDetails?.join_date ? customerDetails?.join_date : 'N/A'}
                        discountAvailed={
                            customerDetails && customerDetails?.discount_availed
                                ? `₹${customerDetails?.discount_availed}/-`
                                : 'N/A'
                        }
                    />
                </div>
            )}
        </>
    );
}
