import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import TableLoader from '../../../../../../Components/ContentLoader/TableLoader';

export default function OrderingModeLoader() {
    return (
        <>
            <div className='mr-4 w-full mt-6'>
                <div className='flex flex-row justify-between'>
                    <TitleDescriptionLoader className='max-w-[600px] md:max-w-full mb-2' />
                    <DropdownBoxLoader
                        className='max-w-[150px] md:max-w-full mb-2'
                        height={50}
                        boxHeight='h-12'
                    />
                </div>
                <DropdownBoxLoader
                    className='max-w-[330px] md:max-w-full mb-2 mt-5'
                    height={30}
                    boxHeight='h-11'
                />

                <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border max-w-[636px]'>
                    <table className='w-full break-words table-row-bg'>
                        <tbody>
                            <TableLoader
                                numberOfRow={6}
                                columns={4}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
