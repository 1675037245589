import React from 'react';
import Layout from '../Components/Layout';
import { useFormContext } from 'react-hook-form';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import InputSection from '../../../Components/InputSection';
import DropdownSection from '../../../Components/DropdownSection';
import APIV5 from '../../../../../api/axios/APIV5';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import _ from 'lodash';
import Loader from './Components/Loader';

export default function ServiceRadius(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        setValue,
        watch,
        formState: { errors, isDirty },
        reset,
        setError,
    } = useFormContext();

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateOrderingMode = async (data) => {
        const paramas = formatData(data.service_radius);

        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=service_radius`, { ...paramas });

            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            setError('service_radius.service_radius', { message: error.response.data.errors.service_radius });
        }
    };

    const formatData = (data) => {
        return {
            latitude: data.latitude,
            longitude: data.longitude,
            service_radius: data.service_radius,
            consider_own_service_radius: !data.thirdPartyRiderService.value,
            consider_third_party_rider_service_radius: data.thirdPartyRiderService.value,
        };
    };

    const onSubmit = () => {
        if (!_.isEmpty(errors)) return;
        handleSubmit(updateOrderingMode)();
        setShowTabChangeWarningPopup(false);
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={onSubmit}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <InputSection
                    className='mb-4'
                    title='Outlet location'
                    description="Provide your outlet's Latitude and Longitude. You can find this with the help of Google Maps. Please make sure to be accurate, as this is used in a variety of calculations."
                    label='(Latitude)'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    placeholder='Enter latitude coordinate'
                    shadow='shadow-smallDropDownShadow'
                    name='service_radius.latitude'
                    rules={{ required: 'Please enter valid latitude' }}
                    inputType='number'
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />

                <InputSection
                    className='mb-4'
                    label='(Longitude)'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    placeholder='Enter longitude coordinate'
                    shadow='shadow-smallDropDownShadow'
                    name='service_radius.longitude'
                    rules={{ required: 'Please enter valid longitude' }}
                    inputType='number'
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />
                <div className='mb-4'>
                    <InputSection
                        title='Service radius'
                        description='The service radius is the limit for your delivery orders. Customers trying to order beyond the service radius will not be able to place an order for that location.'
                        label='(Service availability radius in kms)'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        placeholder='Enter radius in kms'
                        shadow='shadow-smallDropDownShadow'
                        name='service_radius.service_radius'
                        rules={{ required: 'Please enter service radius' }}
                        inputType='number'
                        enteringLimit={999}
                        dontShowError
                        handleInputChange={() => {
                            console.log('object');
                            setShowTabChangeWarningPopup(true);
                        }}
                    />
                    {errors?.service_radius?.service_radius && (
                        <ErrorMessage errors={errors.service_radius.service_radius} />
                    )}
                </div>

                <DropdownSection
                    className='mb-4'
                    title='Serviceability'
                    description='Select if you would like to accept the orders from the customers in your outlet service radius and 3rd party delivery areas combined.'
                    menuItems={preferenceMenuItem}
                    shadow='shadow-smallDropDownShadow'
                    openFromBottom
                    name='service_radius.thirdPartyRiderService'
                    rules={{ required: 'Please select a serviceability option' }}
                    placeholder='Please select a serviceability option'
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
            </div>
        </Layout>
    );
}
