import APIV3 from '../../../api/axios/APIV3';
import APIV5 from '../../../api/axios/APIV5';
import { handleErrorResponse } from './LoaderFunctions';

const timeout = 1000;

export const fetchSections = async ({ setSectionList, setloaderStatus, selectedOutletId }) => {
    //this function is used to fetch sections
    setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: false });
    try {
        const response = await APIV5.get(`outlets/${selectedOutletId}/outlet-sections/section-list`);
        setSectionList(response.sections);
        setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        return response.sections;
    } catch (error) {
        console.error(error);
        setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        throw error;
    }
};

export const fetchTable = async ({
    //this function is used to fetch table
    setloaderStatus,
    totalTable,
    setTableList,
    setTotalTable,
    section,
    sectionList,
    selectedOutletId,
}) => {
    try {
        const selectedSection = sectionList.find((sections) => sections.internal_name == section);
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: false });
        const responseSubSection = await Promise.all(
            selectedSection.sub_section.map((item, index) => {
                return APIV5.get(`outlets/${selectedOutletId}/outlet-sections/${item.id}/table-list`, {
                    params: {
                        per_page: 1,
                        page: 1,
                    },
                });
            })
        );
        if (responseSubSection) {
            formateTableResonse({
                setTableList,
                setTotalTable,
                subsectionData: [...responseSubSection],
                selectedSection,
            });
            setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        }
    } catch (error) {
        console.log(error);
    }
};

const formateTableResonse = ({ setTableList, setTotalTable, subsectionData, selectedSection }) => {
    //this function is used to formate fetchTable api response
    let tables = [];
    setTotalTable([]);
    subsectionData.map((item, index) => {
        setTotalTable((prevState) => [...prevState, subsectionData[index]?.tables?.total]);
        const table = {
            subSection: selectedSection?.sub_section[index],
            sectionId: item?.tables?.data[0]?.outlet_section_id,
            table: item?.tables?.data,
            currentPage: item?.tables?.current_page,
            lastPage: item?.tables?.last_page,
            perPage: item?.tables?.per_page,
            nextPageUrl: item?.tables?.next_page_url,
            subSectionTitle: selectedSection?.sub_section[index]?.internal_name,
            totalTable: item?.tables?.total,
        };
        tables = [...tables, table];
    });
    setTableList(tables);
};

export const createTable = async ({
    //this function is used to create table in sub section
    tableData,
    setloaderStatus,
    handleClickClose,
    seterrorOfApi,
    section,
    sectionList,
    setTotalTable,
    setTableList,
    selectedRestaurantId,
    selectedOutletId,
}) => {
    setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
    const requestedBody = {
        internal_name: tableData?.internal_name,
        capacity: tableData?.capacity,
        outlet_section_id: tableData?.outlet_section_id?.value,
        outlet_sub_section_id: tableData?.outlet_sub_section_id?.value,
        deeplink: tableData?.deeplink,
    };
    try {
        const response = await APIV3.post(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/outlet-table-create`,
            requestedBody
        );
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                //here fetchTable is called for the fetch newely created table
                fetchTable({ setloaderStatus, setTableList, setTotalTable, section, sectionList, selectedOutletId });
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        handleErrorResponse(setloaderStatus);
        seterrorOfApi(error.message);
    }
};

export const editTable = async ({
    tableData,
    setloaderStatus,
    handleClickClose,
    selectedTableId,
    setisDelete,
    setTotalTable,
    setTableList,
    seterrorOfApi,
    section,
    sectionList,
    selectedRestaurantId,
    selectedOutletId,
}) => {
    //this function is used to edit table data of subsection
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
        const requestedData = {
            internal_name: tableData?.internal_name,
            capacity: tableData?.capacity,
            outlet_section_id: tableData?.outlet_section.value,
            outlet_sub_section_id: tableData?.outlet_sub_section.value,
            deeplink: tableData?.deeplink,
        };

        const response = await APIV3.put(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/outlet-tables/${selectedTableId}/edit-table`,
            requestedData
        );
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                //here fetchTable is called for the fetch edited table data
                fetchTable({ setloaderStatus, setTableList, setTotalTable, section, sectionList, selectedOutletId });
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus, setisDelete);
        }
    } catch (error) {
        seterrorOfApi(error.message);
        handleErrorResponse(setloaderStatus, setisDelete);
    }
};

export const deleteTable = async ({
    setloaderStatus,
    handleClickClose,
    selectedTableId,
    setisDelete,
    section,
    sectionList,
    setTotalTable,
    setTableList,
    seterrorOfApi,
    selectedRestaurantId,
    selectedOutletId,
}) => {
    //this function is used to delete table from subsection
    setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
    setisDelete(true);

    try {
        const response = await APIV3.delete(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/outlet-tables/${selectedTableId}`
        );
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                fetchTable({ setloaderStatus, setTableList, setTotalTable, section, sectionList, selectedOutletId });
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus, setisDelete);
        }
    } catch (error) {
        seterrorOfApi(error.message);
        handleErrorResponse(setloaderStatus, setisDelete);
    }
};

export const handleShowMore = async ({
    section,
    index,
    setloaderStatus,
    setTableList,
    selectedSection,
    selectedOutletId,
}) => {
    //this function is used to fetch more table from specific section and it return next page tables from table list
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: false });

        const response = await APIV5.get(
            `/outlets/${selectedOutletId}/outlet-sections/${section?.sectionId}/table-list`,
            {
                params: {
                    per_page: 1,
                    page: section?.currentPage + 1,
                },
            }
        );
        if (response.success) {
            setTableList((previousState) => {
                const updatedTableList = [...previousState];
                updatedTableList[index] = {
                    subSection: selectedSection?.sub_section[index],
                    table: [...previousState[index]?.table, ...response?.tables?.data],
                    currentPage: response?.tables?.current_page,
                    lastPage: response?.tables?.last_page,
                    perPage: response?.tables?.per_page,
                    nextPageUrl: response?.tables?.next_page_url,
                    sectionId: previousState[index]?.sectionId,
                    totalTable: response?.tables?.total,
                };
                return updatedTableList;
            });
            setloaderStatus({ isLoading: false, isResponseSuccess: '', isClicked: false });
        }
    } catch (error) {}
};

export const createSection = async ({
    //this function is used to create section
    sectionData,
    setloaderStatus,
    handleClickClose,
    setSectionList,
    setIsLoading,
    seterrorOfApi,
    selectedOutletId,
}) => {
    setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
    const requestedBody = {
        section_name: sectionData.internal_name ?? sectionData.outlet_sub_section_id.value,
        sub_sections: sectionData.subSection.map((subSection) => subSection.name),
        outlet_ordering_mode_id: sectionData.ordering_mode.value,
    };
    try {
        const response = await APIV5.post(`outlets/${selectedOutletId}/outlet-sections/create-section`, requestedBody);
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(() => {
                handleClickClose();
                fetchSections({ setSectionList, setloaderStatus, selectedOutletId });
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        seterrorOfApi(error?.response?.data?.message);
        handleErrorResponse(setloaderStatus);
    }
};

export const editSection = async ({
    //this function is used to edit section
    sectionData,
    sectionList,
    setloaderStatus,
    handleClickClose,
    watch,
    setSectionList,
    setIsLoading,
    seterrorOfApi,
    section,
    setTotalTable,
    setTableList,
    navigate,
    selectedRestaurantId,
    selectedOutletId,
}) => {
    const requestedBody = {
        section_name: sectionData?.internal_name,
        outlet_ordering_mode_id: sectionData.ordering_mode.value,
        sub_section: sectionData?.subSection?.map((item) => ({ sub_section_name: item.name, sub_section_id: item.id })),
    };

    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        const response = await APIV5.put(
            `outlets/${selectedOutletId}/outlet-sections/${watch('sectionId')}/edit-section`,
            requestedBody
        );
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(async () => {
                handleClickClose();
                //here we fetch sections after edit specific section
                const sections = await fetchSections({ setSectionList, setloaderStatus, selectedOutletId });
                navigate(`/table-management/${sectionData?.internal_name}`, { replace: true });
                //here we pass fetched sections list to fetch table function to fetch table of selected section
                fetchTable({
                    setloaderStatus,
                    setTableList,
                    setTotalTable,
                    section: sectionData?.internal_name,
                    sectionList: sections,
                    selectedOutletId,
                });
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        seterrorOfApi(error?.response?.data?.message);
        handleErrorResponse(setloaderStatus);
    }
};

export const deleteSection = async ({
    //this function is used to delete section
    sectionData,
    sectionList,
    setloaderStatus,
    setisDelete,
    navigate,
    handleClickClose,
    setSectionList,
    setIsLoading,
    seterrorOfApi,
    selectedSection,
    section,
    setTableList,
    setTotalTable,
    selectedOutletId,
}) => {
    const deleteSectionId = sectionList.find((section) => section.internal_name == sectionData.internal_name)?.id;
    setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });
    setisDelete(true);

    try {
        setisDelete(true);
        const response = await APIV5.delete(
            `outlets/${selectedOutletId}/outlet-sections/${deleteSectionId}/delete-section`
        );
        if (response.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            setTimeout(async () => {
                handleClickClose();
                const sections = await fetchSections({ setSectionList, setloaderStatus, selectedOutletId });
                if (selectedSection.id == deleteSectionId) {
                    //here we check if user request selected section for delete than we delete that section and navigate to the first section from the section list
                    navigate(`/table-management/${sections[0].internal_name}`);
                    fetchTable({
                        setloaderStatus,
                        setTableList,
                        setTotalTable,
                        section: sections[0].internal_name,
                        sectionList: sections,
                        selectedOutletId,
                    });
                }
            }, timeout);
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus, setisDelete);
        }
    } catch (error) {
        seterrorOfApi(error?.response?.data?.message);
        handleErrorResponse(setloaderStatus, setisDelete);
    }
};

export const handleClickSaveOnTableArrangement = async ({
    //this function is used to rearrange table in table rearrangement screen
    tableList,
    setloaderStatus,
    handleClickClose,
    setTableLists,
    setTotalTable,
    section,
    sectionList,
    seterrorOfApi,
    selectedRestaurantId,
    selectedOutletId,
}) => {
    const tablesList = tableList.map((table) => table.tables).flat();
    const requestedData = {
        tables: tablesList,
    };
    let response = null;
    try {
        setloaderStatus({ isLoading: true, isResponseSuccess: '', isClicked: true });

        response = await APIV3.patch(
            `restaurants/${selectedRestaurantId}/outlets/${selectedOutletId}/outlet-tables/change-sequence`,
            requestedData
        );
        if (response && response?.success) {
            setloaderStatus({ isResponseSuccess: true, isLoading: false, isClicked: true });
            handleClickClose();
            fetchTable({
                setloaderStatus,
                setTableList: setTableLists,
                setTotalTable,
                section,
                sectionList,
                selectedOutletId,
            });
        } else {
            seterrorOfApi(response.message);
            handleErrorResponse(setloaderStatus);
        }
    } catch (error) {
        seterrorOfApi(error.message);
        handleErrorResponse(setloaderStatus);
    }
};
