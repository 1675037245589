import React, { useState } from 'react';
import OrderingModes from './Components/OrderingModes';
import { Controller, useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import TitleDescription from '../../../../../Components/TitleDescription/TitleDescription';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import Loader from './Components/Loader';

export default function OtherCharges(props) {
    const { fetchSetting, handleDiscardForm, setShowTabChangeWarningPopup } = props;

    const restaurantOrderingMode = useSelector((state) => state.outlet.orderingModes);

    const {
        handleSubmit,
        formState: { isDirty },
        control,
        clearErrors,
        watch,
        reset,
        setValue,
    } = useFormContext();

    const formatData = (data, otherCharges) => {
        let formattedData;

        if (otherCharges.keepSamePrice) {
            formattedData = {
                other_charges: data.map((el) => ({
                    convenience_charge: data[0].enableConvenienceCharge.value ? data[0].convenience_charge : 0,
                    is_convenience_charge_applicable: data[0].enableConvenienceCharge.value,

                    restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,

                    packaging_charge: data[0]?.enablePackaging.value ? data[0]?.packaging_charge : 0,
                    is_packaging_charge_applicable: data[0].enablePackaging.value,
                })),
            };
        } else {
            formattedData = {
                other_charges: data.map((el) => ({
                    convenience_charge: el.enableConvenienceCharge.value ? el.convenience_charge : 0,
                    packaging_charge: el.enablePackaging.value ? el.packaging_charge : 0,
                    restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                    is_convenience_charge_applicable: el.enableConvenienceCharge.value,
                    is_packaging_charge_applicable: el.enablePackaging.value,
                })),
            };
        }

        const filteredOtherCharges = formattedData.other_charges.filter((charge) => {
            return watch('ordering_modes').some(
                (mode) => mode.restaurant_ordering_mode_id === charge.restaurant_ordering_mode_id
            );
        });

        formattedData.other_charges = filteredOtherCharges;

        return formattedData;
    };

    const onSubmit = (data) => {
        updateOtherCharges(formatData(data.other_charges, data.otherCharges));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateOtherCharges = async (params) => {
        try {
            const response = await APIV5.put(`/outlets/${selectedOutletId}?section=other_charges`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
                setShowFooter(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnableToggle = (value, fieldChange) => {
        fieldChange(value);
        setShowTabChangeWarningPopup(true);
        !value && clearErrors();
    };

    const otherCharges = watch('other_charges');

    const getTitleOfMode = (id) => {
        return restaurantOrderingMode.find((el) => el.id === id)?.display_name;
    };

    const [showFooter, setShowFooter] = useState(false);

    const availableOrderingMode = watch('ordering_modes');

    const filteredOtherCharges = otherCharges?.filter((charge) =>
        availableOrderingMode?.some((mode) => mode.restaurant_ordering_mode_id === charge.restaurant_ordering_mode_id)
    );

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                reset(undefined, { keepValues: true });
                setShowFooter(false);
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={showFooter}>
            <div className='max-w-[636px] w-full md:max-w-full md:pb-20'>
                <div className='pb-6 border-b border-neutral-300 mb-6 flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full'>
                    <TitleDescription title='Keep same price for all ordering modes' />

                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={field.value ?? false}
                                canChange
                                setIsEnabled={(value) => {
                                    setShowFooter(true);
                                    handleEnableToggle(value, field.onChange);
                                }}
                            />
                        )}
                        name='otherCharges.keepSamePrice'
                        control={control}
                    />
                </div>

                {watch('otherCharges.keepSamePrice') ? (
                    <OrderingModes
                        setShowFooter={setShowFooter}
                        index={0}
                        title={''}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                ) : (
                    filteredOtherCharges?.map((el, index) => (
                        <div
                            key={index}
                            className='mb-6 border-b border-neutral-300 last:border-none'>
                            <OrderingModes
                                {...el}
                                index={index}
                                title={getTitleOfMode(el.restaurant_ordering_mode_id)}
                                setShowFooter={setShowFooter}
                                setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                            />
                        </div>
                    ))
                )}
            </div>
        </Layout>
    );
}
