import { format } from 'date-fns';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import { dayTimeSlots } from '../../../../../Components/Timings/Components/functions';
import { ReactComponent as CalenderIcon } from '../../../../../Assets/calendar.svg';
import { ReactComponent as TimerIcon } from '../../../../../Assets/timer.svg';
import { ReactComponent as DownIcon } from '../../../../../Assets/chevron-down.svg';

export default function DateAndTimeSection() {
    const [showCalender, setShowCalender] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);

    return (
        <>
            <div className='flex flex-row items-center mb-4 md:block md:mb-0'>
                <div className='w-1/2 mr-2 md:w-full md:mr-0 md:mb-2'>
                    <div className='paragraph-small-medium pb-1'>Select date</div>

                    <div
                        className='border border-neutral-300 rounded-lg h-12 justify-between flex flex-row w-full py-3 px-4 cursor-pointer'
                        onClick={() => setShowCalender((prevState) => !prevState)}>
                        <div className='flex flex-row items-center gap-2'>
                            <CalenderIcon height={24} />
                            <span className={`paragraph-small-regular ${!selectedDate && 'text-neutral-300'}`}>
                                {selectedDate ?? 'Select date'}
                            </span>
                        </div>
                        <DownIcon className={`${!showCalender ? 'rotate-0' : 'rotate-180'}`} />
                    </div>

                    {showCalender && (
                        <div className='relative'>
                            <div className='absolute left-[67%] -top-5'>
                                <ReactDatePicker
                                    selected={new Date()}
                                    onChange={(selectedDate) => {
                                        setSelectedDate(format(selectedDate, 'dd MMM yyyy'));
                                        setShowCalender(false);
                                    }}
                                    timeIntervals={15}
                                    timeCaption='time'
                                    dateFormat='dd MMM yyyy'
                                    popperPlacement='bottom-end'
                                    minDate={new Date()}
                                    showPopperArrow={false}
                                    className='hidden'
                                    open={showCalender}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className='w-1/2 ml-2 md:w-full md:ml-0 md:mb-2'>
                    <DropdownWithValueLabel
                        icon={<TimerIcon height={24} />}
                        menuItems={dayTimeSlots}
                        placeholder='Select time'
                        label='Select time'
                        fixedHeight='h-[200px]'
                    />
                </div>
            </div>
        </>
    );
}
