import React from 'react';

export default function OutletmenuInformation() {
    return (
        <>
            <div className='grid grid-cols-[minmax(380px,489px)_minmax(193px,302px)_minmax(185px,294px)_187px] xl:grid-cols-[minmax(232px,1336px)_263px]'>
                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Outlet name:</span>
                    <span className='paragraph-medium-regular ml-1'>Domino's Pizza - Tagore Road Outlet</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>State:</span>
                    <span className='paragraph-medium-regular ml-1'>Gujarat</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>City:</span>
                    <span className='paragraph-medium-regular ml-1'>Rajkot</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Menu name:</span>
                    <span className='paragraph-medium-regular ml-1'>Menu - 007</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Created by:</span>
                    <span className='paragraph-medium-regular ml-1'>Sarthak Kanchan</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Created on:</span>
                    <span className='paragraph-medium-regular ml-1'>20 Nov 2022</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Categories attached:</span>
                    <span className='paragraph-medium-regular ml-1'>12</span>
                </div>

                <div className='mb-3'>
                    <span className='paragraph-medium-semi-bold text-neutral-400'>Dishes attached:</span>
                    <span className='paragraph-medium-regular ml-1'>128</span>
                </div>
            </div>

            <div className='mb-4 pb-4 border-b border-neutral-300'>
                <span className='paragraph-medium-semi-bold text-neutral-400'>Outlet address:</span>
                <span className='paragraph-medium-regular ml-1'>
                    Shyamal Infinity, beneath Radio Mirchi, kalawad road
                </span>
            </div>
        </>
    );
}
