import React, { useState } from 'react';

export default function UpSellItem(props) {
    const { dishDetails } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleCardClick = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const toggleShowDetails = (e) => {
        e.stopPropagation();
        setShowDetails((prevShowDetails) => !prevShowDetails);
    };

    return (
        <>
            <div
                className={`pt-4 px-4 border border-neutral-300 rounded-lg ${!showDetails && 'cursor-pointer'}`}
                onClick={handleCardClick}>
                <div className='w-full justify-between flex pb-4'>
                    <span className='paragraph-medium-medium'>Up-sell items</span>
                    <span
                        className='paragraph-medium-medium text-primary-500 cursor-pointer'
                        onClick={toggleShowDetails}>
                        {!showDetails ? 'Show' : 'Hide'}
                    </span>
                </div>

                {showDetails && (
                    <div className='pb-4'>
                        {dishDetails?.upselling_items?.length ? (
                            <div className='flex flex-row mr-4'>
                                <span className='paragraph-small-regular text-neutral-500 mr-1 min-w-[94px] flex-shrink-0'>
                                    Item name:
                                </span>

                                <div className='flex flex-col gap-1 paragraph-small-regular'>
                                    {dishDetails?.upselling_items?.map((el, index) => (
                                        <div key={index}>{el.internal_name}</div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            'NA'
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
