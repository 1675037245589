import React from 'react';
import CancelButton from '../../../Components/Buttons/CancelButton';
import { LargeDestructiveButton } from '../../../Components/Buttons/Button';
import { ReactComponent as TrashIcon } from '../../../Assets/trash.svg';

export default function RestartOrderPopup(props) {
    const { handleClickClose } = props;

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 md:px-4 flex'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 py-6 m-auto md:w-full md:px-4 md:py-4'>
                    <div className='pb-3 flex flex-row items-center'>
                        <div className='p-2 bg-destructive-200 rounded-md'>
                            <TrashIcon stroke='#EF4444' />
                        </div>
                        <h2 className='paragraph-large-medium ml-2'>Restart the order?</h2>
                    </div>
                    <p className='paragraph-medium-italic text-neutral-500 pb-3'>
                        Are you sure to restart the order of
                        <span className='text-destructive-500 italic'> Sarthak Kanchan</span> with{' '}
                        <span className='text-destructive-500 italic'>0</span> dishes?
                    </p>

                    <div className='flex flex-row justify-between'>
                        <div className='w-1/2'>
                            <CancelButton handleClickCancel={handleClickClose} />
                        </div>
                        <div
                            className='w-1/2 md:ml-[7.5px] ml-2.5 cursor-pointer'
                            onClick={handleClickClose}>
                            <LargeDestructiveButton label='Restart' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
