import React from 'react';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import LargeTitleSwitch from '../../../../../../Components/ToggleSwitch/LargeTitleSwitch';
import InputSection from '../../../../../../Components/InputField/InputSection';
import InputAreaSection from '../../../../../Setting/Components/InputAreaSection';
import DropdownSection from '../../../../../../Components/DropDown/DropdownSection';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';
import { showHideMenuItems } from '../../../../../../Constants/Constants';

export default function VariantGroupForm() {
    const {
        watch,
        formState: { errors },
        control,
        setValue,
    } = useFormContext();

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const toggleVariantAvailability = (isOn) => {
        setValue('pricing.variantPrice', []);
    };

    return (
        <>
            <TitleDescription
                className='mb-4'
                title='Dish pricing'
                description='Set the dish price for different types of ordering modes. You can set different pricing for different ordering modes.'
            />

            <LargeTitleSwitch
                classNames='mb-4'
                title='Does this dish have variants?'
                description='Enable it to add variants to this dish'
                switchName='do_add_variant'
                control={control}
                handleToggle={(isOn) => toggleVariantAvailability(isOn)}
            />

            {watch('do_add_variant') && (
                <>
                    <TitleDescription
                        className='mb-2'
                        title='Title'
                        description='Please enter the variant group titles that will be displayed with the category image in the mobile application.'
                    />

                    <div className='flex flex-row items-start mb-6 md:block gap-3'>
                        {availableLanguages.map((el, index) => {
                            return (
                                <div
                                    className='w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full md:mr-0 md:mb-2 relative'
                                    key={index}>
                                    <InputSection
                                        boxHeight='[52px]'
                                        labelMarginB='mb-2'
                                        label={`(${el.language_label})`}
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                        placeholder={`Enter title in ${el.language_label}`}
                                        shadow='shadow-smallDropDownShadow'
                                        name={`variantGroupTitle.${el.id}`}
                                        rules={{ required: `Please enter title in ${el.language_label}` }}
                                    />

                                    {errors?.variantGroupTitle?.[el.id]?.message && (
                                        <ErrorMessage
                                            errors={errors?.variantGroupTitle?.[el.id]}
                                            className='absolute -bottom-5'
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <TitleDescription
                        className='mb-2'
                        title='Description'
                        description='Please enter the variant group of the category that will be displayed in the mobile application.'
                    />

                    <div className='flex flex-row items-start md:block gap-3 mb-4'>
                        {availableLanguages.map((el, index) => {
                            return (
                                <div
                                    className='w-1/2 md:mb-2 md:w-full'
                                    key={index}>
                                    <InputAreaSection
                                        name={`variantGroupDescription.${el.id}`}
                                        placeholder={`Enter description in ${el.language_label}`}
                                        paddingT='pt-4'
                                        label={`(${el.language_label})`}
                                        shadow='shadow-smallDropDownShadow'
                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <DropdownSection
                        name='has_aggmark_show'
                        menuItems={showHideMenuItems}
                        placeholder='Select show agg mark'
                        title='Show agmark icon'
                        className='mb-6'
                        marginBetween='mb-2'
                        rules={{ required: 'Please select preference' }}
                    />
                </>
            )}
        </>
    );
}
