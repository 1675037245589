import React from 'react';
import Slider from 'react-slick';
import imgSlider1 from '../../../Assets/LoginSliders/login-slide01.png';
import imgSlider2 from '../../../Assets/LoginSliders/login-slide02.png';
import imgSlider3 from '../../../Assets/LoginSliders/login-slide03.png';

const LoginSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        className: 'login-slide',
        autoplaySpeed: 1500,
    };

    const banners = [
        {
            image: imgSlider1,
            title: "Digitize your restaurant's operations",
            description:
                'Manage everything from handling restaurant services to settle billing digitally now with Orderloop',
        },
        {
            image: imgSlider2,
            title: 'Trusted by 1000+ restaurants',
            description:
                'Orderloop is actively working with 1000+ restaurants (and growing) across the country to make their brand grow.',
        },
        {
            image: imgSlider3,
            title: "Discover your brand's potential",
            description: 'With Orderloop, you can create a steady flow of customers who are loyal towards your brand.',
        },
    ];

    return (
        <>
            <div className='max-w-[825px] w-full bg-[#695AD3] xl:hidden px-4 pt-10 relative before:absolute before:bg-[#695AD3] before:w-screen before:right-full before:top-0 before:bottom-0'>
                <div className='w-full h-full bg-[#4B39C9] rounded-t-[325px] overflow-hidden max-w-[657px] pageContentLarge:max-w-[500px] pageContentLarge:float-right pageContentLarge:mr-[70px] mx-auto pb-10'>
                    <div className='text-center'>
                        <Slider {...settings}>
                            {banners.map((el, index) => {
                                const [firstWord, secondWord, ...restWords] = el.title.split(' ');
                                return (
                                    <div key={index}>
                                        <div className='w-full max-w-[631px] mx-auto pt-[35px] pageContentLarge:pt-7 pageContentLarge:px-3'>
                                            <img
                                                src={el.image}
                                                alt='Login Slider'
                                            />
                                        </div>
                                        <div className='display-small-medium pt-[19px] pageContentLarge:pt-4 px-4 pageContentLarge:px-2 pb-6 pageContentLarge:pb-3 first-letter: text-white pageContentLarge:text-2xl'>
                                            <div>
                                                {firstWord} {secondWord}
                                            </div>
                                            <div>{restWords.join(' ')}</div>
                                        </div>
                                        <p className='paragraph-large-regular text-white px-36 pageContentLarge:px-5 pageContentLarge:text-base'>
                                            {el.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginSlider;
