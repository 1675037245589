import React, { useRef } from 'react';
import ListView from './ListView';
import _ from 'lodash';
import { ListBlankComponentView } from '../../../../Components/BlankComponent/ListBlankComponentView';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

export default function ListViewSection(props) {
    const { orderDetails, fetchOrderDetail, paginationState } = props;

    return (
        <div className='mt-[3px]'>
            {!_.isEmpty(orderDetails) ? (
                orderDetails.map((el, index) => (
                    <ListView
                        key={index}
                        customerName={el.customer_name}
                        points={el.points}
                        createdAt={el.created_at}
                        index={index}
                    />
                ))
            ) : (
                <ListBlankComponentView previewText={'No Content Available'} />
            )}
        </div>
    );
}
