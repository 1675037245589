import React from 'react';
import GridViewCard from '../Components/GridViewCards';
import { useWindowSize } from '@uidotdev/usehooks';

export default function GridSection(props) {
    const { outlets, handleRestaurantSelection, setStatusPopupData, image_url } = props;

    const screenWidth = useWindowSize().width;

    return (
        <>
            <div
                className={`lg:grid lg:grid-cols-2 xl:grid-cols-3 -mx-2.5 md:-mx-0 md:grid-cols-none md:block grid ${
                    screenWidth < 1400 ? 'grid-cols-3' : 'grid-cols-4'
                }`}>
                {outlets?.map((el, index) => {
                    return (
                        <GridViewCard
                            image_url={image_url}
                            setStatusPopupData={setStatusPopupData}
                            handleRestaurantSelection={() => handleRestaurantSelection(el.restaurant_id, el.id)}
                            {...el}
                            key={index}
                        />
                    );
                })}
            </div>
        </>
    );
}
