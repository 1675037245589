import store from '../../reduxStore/index';
import * as Sentry from '@sentry/browser';

export const interceptorsRequest = (request) => {
    let authenticationData = localStorage.getItem('authToken');

    if (authenticationData) {
        request.headers.Authorization = `Bearer ${authenticationData}`;
    }

    store.dispatch({
        type: 'START_LOADING',
    });

    store.dispatch({
        type: 'set-submit-loader',
        payload: { submitLoader: true },
    });

    return request;
};

export const interceptorsRequestError = (error) => {
    store.dispatch({ type: 'FINISH_LOADING' });
    store.dispatch({
        type: 'set-submit-loader',
        payload: { submitLoader: false },
    });
    return Promise.reject(error);
};

export const interceptorsResponse = (response) => {
    store.dispatch({
        type: 'FINISH_LOADING',
    });

    store.dispatch({
        type: 'set-submit-loader',
        payload: { submitLoader: false },
    });

    return response.data;
};

export const interceptorsResponseError = (error) => {
    store.dispatch({ type: 'FINISH_LOADING' });

    store.dispatch({
        type: 'set-submit-loader',
        payload: { submitLoader: false },
    });

    const {
        // config,
        response: { status },
    } = error;

    const email = store.getState().auth.user ? store.getState().auth.user.email : '';

    Sentry.configureScope((scope) => scope.setLevel('Error').setUser({ email: email }));
    Sentry.captureException(error);

    if ((status === 401 || status === 400 || status === 403) && window.location.pathname !== '/login') {
        // TODO: take necessary steps when there is an error
        //TODO: Remove auth details from redux store.
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedRestaurant');
        window.location.href = '/login';
    }

    return Promise.reject(error);
};
