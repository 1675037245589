import React, { useState } from 'react';
import PaginationWithNumber from '../../../../Components/Pagination/PaginationWithNumber';
import LinkOutletPopup from './Components/LinkOutlet';
import PresetDetails from './Components/PresetDetails';
import { IsMobileScreen } from '../../../../Constants/Constants';
import SelectCategoriesDishesPopup from '../../Popups/SelectCategoriesDishesPopup';

export default function CataloguePreset(props) {
    const { handleClickCreatedBy } = props;

    const [showLinkOutlet, setShowLinkOutlet] = useState(false);

    const handleClickLinkOutlet = () => {
        setShowLinkOutlet(!showLinkOutlet);
    };

    const [showSelectCategoryDishesPopUp, setShowSelectCategoryDishesPopUp] = useState(false);

    const handleClickPreset = () => {
        setShowSelectCategoryDishesPopUp(!showSelectCategoryDishesPopUp);
    };

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <PresetDetails
                handleClickPreset={handleClickPreset}
                handleClickLinkOutlet={handleClickLinkOutlet}
                handleClickCreatedBy={handleClickCreatedBy}
            />

            {!isMobileScreen && <PaginationWithNumber />}

            {showLinkOutlet && (
                <LinkOutletPopup
                    headerBottomLine='Link the menu with the desired outlets'
                    page='cataloguePreset'
                    handleClickClose={handleClickLinkOutlet}
                />
            )}

            {showSelectCategoryDishesPopUp && (
                <SelectCategoriesDishesPopup
                    page='Catalogue Preset'
                    handleClickClose={handleClickPreset}
                />
            )}
        </>
    );
}
