import React, { useEffect, useState } from 'react';
import Table from './Table';
import { IsMobileScreen } from '../../../../../../Constants/Constants';
import ListViewOutletOrderingMode from '../../Components/ListViewOutletOrderingMode';
import { useFormContext } from 'react-hook-form';
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import { useWindowSize } from '@uidotdev/usehooks';

export default function ListSection(props) {
    const {
        isRearrange,
        setShowFooter,
        response,
        rearrangeOrderingMode,
        setRearrangeOrderingMode,
        setShowTabChangeWarningPopup,
    } = props;

    const isMobileScreen = IsMobileScreen();

    const hasWidth = !!useWindowSize().width;

    const {
        watch,
        formState: { isSubmitSuccessful },
        control,
    } = useFormContext();

    const orderingMode = isRearrange ? rearrangeOrderingMode : watch('ordering_modes');

    useEffect(() => {
        !rearrangeOrderingMode?.length &&
            setRearrangeOrderingMode(watch('ordering_modes')?.filter((el) => el.is_enabled));
    }, [watch('ordering_modes')]);

    useEffect(() => {
        isSubmitSuccessful && setRearrangeOrderingMode([]);
    }, [isSubmitSuccessful]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const { setValue, getValues } = useFormContext();

    const handleDragEnd = (event) => {
        setShowTabChangeWarningPopup(true);

        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            const modes = getValues('ordering_modes');

            const oldIndex = modes.findIndex((item) => item.id === active.id);
            const newIndex = modes.findIndex((item) => item.id === over.id);

            const updatedValue = arrayMove(modes, oldIndex, newIndex);

            setValue('ordering_modes', updatedValue, { shouldDirty: true });

            setShowFooter(true);
        }
    };

    if (!hasWidth) {
        return null;
    }

    return (
        <>
            {isMobileScreen ? (
                <>
                    <div className='pb-[70px]'>
                        ``{' '}
                        {orderingMode?.length ? (
                            <>
                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={handleDragEnd}
                                    modifiers={[
                                        restrictToVerticalAxis,
                                        restrictToParentElement,
                                        restrictToFirstScrollableAncestor,
                                    ]}>
                                    <SortableContext
                                        items={orderingMode}
                                        strategy={verticalListSortingStrategy}
                                        disabled={!isRearrange}>
                                        {orderingMode?.map((el, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ListViewOutletOrderingMode
                                                        index={index}
                                                        isRearrange={isRearrange}
                                                        key={index}
                                                        control={control}
                                                        {...el}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </SortableContext>
                                </DndContext>
                            </>
                        ) : (
                            <>
                                {[...Array(10)].map((_, index) => (
                                    <DropdownBoxLoader
                                        boxHeight='h-[70px]'
                                        height={70}
                                        key={index}
                                        className='mt-2'
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <Table
                        response={response}
                        isRearrange={isRearrange}
                        orderingMode={orderingMode}
                        setShowFooter={setShowFooter}
                        rearrangeOrderingMode={rearrangeOrderingMode}
                        setRearrangeOrderingMode={setRearrangeOrderingMode}
                        setShowTabChangeWarningPopup={setShowTabChangeWarningPopup}
                    />
                </>
            )}
        </>
    );
}
