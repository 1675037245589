import React from 'react';
import { useParams } from 'react-router-dom';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { Tab } from '../../../Components/Tabs/Tabs';
import { IsMobileScreen } from '../../../Constants/Constants';

const HeaderTab = ({ handleNavigation }) => {
    const { section } = useParams();

    const isMobileScreen = IsMobileScreen();

    const tabs = [
        {
            item: 'Ordered Cashback users',
            link: '/ordered-reward-points',
            onClick: () =>
                handleNavigation({
                    route: 'ordered-reward-points',
                }),
        },
        {
            item: 'Signup Cashback users',
            link: '/sign-up-reward-points',
            onClick: () => handleNavigation({ route: 'sign-up-reward-points' }),
        },
    ];

    return (
        <>
            {!isMobileScreen ? (
                <div className='flex flex-row items-center mb-4 pb-4 border-b border-neutral-300'>
                    {tabs.map((el, index) => (
                        <div
                            className='mr-4 lg:mr-2 cursor-pointer last:mr-0 last:lg:mr-0'
                            onClick={() => el.onClick()}
                            key={index}>
                            <Tab
                                label={el.item}
                                isActive={section === el.link.slice(1)}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className='w-full mb-4 pb-4 border-b border-neutral-300'>
                    <DropDownTabs menuItems={tabs} />
                </div>
            )}
        </>
    );
};

export default HeaderTab;
