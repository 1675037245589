import React from 'react';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import Dropdown from '../../../../Components/DropDown/Dropdown';
import { InputArea } from '../../../../Components/InputField/InputField';

const ApplyOffers = (props) => {
    const { handleClickClose } = props;
    const offerList = ['Big Discount - 120!', 'Big Discount - 50!', 'We Welcome You', 'Exclusive 2022 BOGO'];

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[475px] w-full rounded-xl bg-shades-50 px-8 md:px-5 py-6 m-auto'>
                    <div className='flex flex-row justify-between items-center mb-6'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Apply offer</span>
                            <span className='paragraph-medium-italic text-neutral-500'>Select offer </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='mb-4'>
                        <Dropdown
                            label='Offers type'
                            selectedItem='We Welcome You'
                            menuItems={offerList}
                        />
                    </div>
                    <div className='mb-12 md:mb-10'>
                        <InputArea
                            placeholder='Enter reason of offer'
                            paddingT='pt-4'
                            label='Descriprion'
                            shadow='shadow-smallDropDownShadow'
                            resizeNone='resize-none'
                            boxHeight='md:h-20'
                        />
                    </div>

                    <div className='cursor-pointer'>
                        <LargePrimaryButton label='Apply' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApplyOffers;
