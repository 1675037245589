import React from 'react';
import { OutletStatisticsListView } from '../Tables/ListView';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function OutletWiseStatistics() {
    const outletDetails = [
        {
            name: "Domino's Pizza - Master Outlet(All)",
            orders: '1,245',
            sales: '₹1,08,234',
            tax: '₹2,395',
            discount: '₹4,999',
            modified: '0',
            reprinted: '0',
            waiwedoff: '0.00',
        },
        {
            name: "Domino's Pizza - Univerisity Road Outlet",
            orders: '356',
            sales: '₹35,234',
            tax: '₹986',
            discount: '₹795',
            modified: '0',
            reprinted: '0',
            waiwedoff: '0.00',
        },
        {
            name: "Domino's Pizza - Tagore Road Outlet",
            orders: '794',
            sales: '₹75,234',
            tax: '₹1,564',
            discount: '₹2,999',
            modified: '0',
            reprinted: '0',
            waiwedoff: '0.00',
        },
        {
            name: "Domino's Pizza - Raiya Road Outlet",
            orders: '198',
            sales: '₹8,234',
            tax: '₹345',
            discount: '₹1,765',
            modified: '0',
            reprinted: '0',
            waiwedoff: '0.00',
        },
    ];

    const isMobileScreen = IsMobileScreen();
    return (
        <>
            <div className='mb-4 md:mb-0'>
                <span className='paragraph-large-medium text-black'>Outlet wise statistics</span>
            </div>

            {!isMobileScreen ? (
                <>
                    <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                        <table className='w-full break-words'>
                            <thead>
                                <tr className='bg-neutral-50 paragraph-overline-small text-neutral-700 shadow-innerShadow uppercase text-left h-16 justify-center'>
                                    <th className='px-6 min-w-[322px] min-width-unset'>
                                        outletDetails <div>name</div>
                                    </th>
                                    <th className='px-6 min-w-[132px] min-width-unset'>
                                        total <div>orders</div>
                                    </th>
                                    <th className='px-6 min-w-[137px] min-width-unset'>
                                        total <div>sales</div>
                                    </th>
                                    <th className='px-6 min-w-[119px] min-width-unset'>
                                        total <div>Tax</div>
                                    </th>
                                    <th className='px-6 min-w-[143px] min-width-unset'>
                                        total <div>discount</div>
                                    </th>
                                    <th className='px-6 min-w-[138px] min-width-unset'>
                                        bill <div>modified</div>
                                    </th>
                                    <th className='pl-6 min-w-[126px] min-width-unset'>
                                        bill <div>re-printed</div>
                                    </th>
                                    <th className='px-6 min-w-[153px] min-width-unset'>
                                        total <div>waived off</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {outletDetails.map((row, index) => (
                                    <tr
                                        key={index}
                                        className='first:border-none border-t border-neutral-300 even:bg-neutral-50 paragraph-small-regular h-[70px] justify-center'>
                                        <td className='pl-6'>{row.name}</td>
                                        <td className='pl-6'>{row.orders}</td>
                                        <td className='pl-6'>{row.sales}</td>
                                        <td className='pl-6'>{row.tax}</td>
                                        <td className='pl-6'>{row.discount}</td>
                                        <td className='pl-6'>{row.modified}</td>
                                        <td className='pl-6'>{row.reprinted}</td>
                                        <td className='pl-6'>{row.waiwedoff}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div>
                    {outletDetails.map((el, index) => {
                        return (
                            <div
                                className='pt-2'
                                key={index}>
                                <OutletStatisticsListView
                                    {...el}
                                    content={el.name}
                                    title='OUTLET NAME:'
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}
