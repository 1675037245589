import { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../../Assets/chevron-down.svg';

export const TableListView = (props) => {
    const { details, type } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDropDown = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='px-2 py-3 border border-neutral-300 rounded-md'>
                <div
                    className='w-full flex flex-row justify-between items-center cursor-pointer'
                    onClick={handleClickDropDown}>
                    <div className='flex flex-row items-center'>
                        {details[0].icon && (
                            <>
                                <div className='min-w-[40px] min-h-[40px] mx-2'>
                                    <img
                                        src={details[0].icon}
                                        alt=''
                                        className=''
                                    />
                                </div>
                            </>
                        )}
                        <div className='ml-2 min-h-[44px]'>
                            <div className='paragraph-overline-small text-neutral-700 mb-1'>
                                {type === 'category' ? 'FAVORITE CATEGORY' : 'FAVORITE DISH:'}
                            </div>
                            <div className='paragraph-x-small-regular text-neutral-900'>
                                {details[0].icon ? details[1] : details[0]}
                            </div>
                        </div>
                    </div>
                    <div className={`w-6 h-6 mr-2 ${showDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>

                {showDetails && (
                    <div className={`${type === 'category' ? 'pl-16' : 'pl-2'}`}>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL ORDERS:</span>
                            <span className='paragraph-x-small-regular ml-1'>
                                {type === 'category' ? details[2] : details[1]}
                            </span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>REVENUE GENERATED:</span>
                            <span className='paragraph-x-small-regular ml-1'>
                                {type === 'category' ? details[3] : details[2]}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export const OutletStatisticsListView = (props) => {
    const { icon, title, content, orders, sales, tax, discount, modified, reprinted, waiwedoff } = props;

    const [showDetails, setShowDetails] = useState(false);

    const handleClickDropDown = () => {
        setShowDetails(!showDetails);
    };

    return (
        <>
            <div className='px-2 py-3 border border-neutral-300 rounded-md'>
                <div
                    className='w-full flex flex-row justify-between items-center cursor-pointer'
                    onClick={handleClickDropDown}>
                    <div className='flex flex-row items-center'>
                        {icon && (
                            <>
                                <div className='w-10 h-10 mx-2'>
                                    <img
                                        src={icon}
                                        alt=''
                                        className=''
                                    />
                                </div>
                            </>
                        )}
                        <div className='ml-2 min-h-[44px]'>
                            <div className='paragraph-overline-small text-neutral-700 mb-1'>{title}</div>
                            <div className='paragraph-x-small-regular text-neutral-900'>{content}</div>
                        </div>
                    </div>
                    <div className={`w-6 h-6 mr-2 ${showDetails && 'rotate-180'}`}>
                        <DownArrow />
                    </div>
                </div>
                {showDetails && (
                    <div className='pl-2'>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL ORDERS:</span>
                            <span className='paragraph-x-small-regular ml-1'>{orders}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL SALES:</span>
                            <span className='paragraph-x-small-regular ml-1'>{sales}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL TAX:</span>
                            <span className='paragraph-x-small-regular ml-1'>{tax}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL DISCOUNT:</span>
                            <span className='paragraph-x-small-regular ml-1'>{discount}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>BILL MODIFIED:</span>
                            <span className='paragraph-x-small-regular ml-1'>{modified}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>BILL RE-PRINTED:</span>
                            <span className='paragraph-x-small-regular ml-1'>{reprinted}</span>
                        </div>
                        <div>
                            <span className='paragraph-overline-small text-neutral-700'>TOTAL WAIVED OFF:</span>
                            <span className='paragraph-x-small-regular ml-1'>{waiwedoff}</span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
