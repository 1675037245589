import React from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { CheckBox } from '../../../Components/FormControl/FormControls';
import { LargePrimaryButton } from '../../../Components/Buttons/Button';

export default function FireDishesPopup(props) {
    const { handleClickClose } = props;

    const dish = [
        {
            name: 'Double Cheese Margherita',
            qty: '02',
        },
        { name: 'Farmhouse Extraveganza Veggie', qty: '01' },
        { name: 'Indi Tandoori Paneers', qty: '01' },
    ];
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-50 flex justify-center items-center overflow-auto p-4'>
                <div className='max-w-[629px] w-full rounded-xl bg-shades-50 px-6 py-6 m-auto md:p-4'>
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-4'>
                        <div className='flex flex-col'>
                            <span className='paragraph-large-medium'>Fire Dishes</span>
                            <span className='paragraph-medium-italic text-neutral-500'>
                                Select dishes you would like to start preparaing
                            </span>
                        </div>

                        <div
                            className='cursor-pointer'
                            onClick={handleClickClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className=''>
                        <span className='paragraph-large-medium mr-2'>Toppings-veg (giant slices)</span>
                        <span className='paragraph-medium-italic text-neutral-500'>(0/5)</span>
                    </div>

                    <div className='mb-12 md:mb-4'>
                        {dish.map((el, index) => (
                            <div
                                className='pt-4 flex flex-row justify-between'
                                key={index}>
                                <div className='flex flex-row items-center label-text-format pr-4'>
                                    <CheckBox
                                        label={
                                            <>
                                                {el.name}
                                                <span className='inline-block align-middle pl-2'>
                                                    <VegIcon />
                                                </span>
                                            </>
                                        }
                                        optionId={el.name + index}
                                        paddingL='4 md:pl-2'
                                        labelStyle='paragraph-medium-regular block overflow-hidden'
                                    />
                                </div>
                                <span className='paragraph-small-regular '>{el.qty}</span>
                            </div>
                        ))}
                    </div>

                    <div className='flex flex-row justify-between sm:block'>
                        <CheckBox
                            label='Select/deselect all'
                            optionId='selectDeselectAll'
                            paddingL='4 md:pl-2'
                            labelStyle='paragraph-large-medium'
                        />
                        <div className='max-w-[151px] w-full sm:max-w-full sm:mt-6'>
                            <LargePrimaryButton label='KOT selected' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
