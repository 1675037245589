import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as Filter } from '../../../../Assets/filter.svg';
import { ReactComponent as ExportIcon } from '../../../../Assets/export.svg';
import CalenderField from '../../../../Components/Calender/CalenderField';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import APIV3 from '../../../../api/axios/APIV3';

const Header = (props) => {
    const { selectedDates, setSelectedDates, fetchOrderDetail, setFilters, setIsLoading, setCashbackTypePagination } =
        props;

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const formatDate = (date) => format(date, 'yyyy-MM-dd');

    const [showFilters, setShowFilters] = useState(false);

    const handleClickFilter = () => {
        setShowFilters(!showFilters);
    };

    const cashbackStatus = [
        { label: 'All', value: null },
        { label: 'Cashback Earned', value: 'earned' },
        { label: 'Cashback Redeemed', value: 'redeemed' },
    ];

    const [cashbackType, setCashbackType] = useState('');
    const [isDisable, setIsDisable] = useState(false);

    let data;

    const handleClickExportData = async () => {
        const [from_date, to_date] = selectedDates.sort((a, b) => a - b);

        data = {
            type: 'loyalty_cashback_report',
            start_date: formatDate(from_date),
            end_date: formatDate(to_date),
            outlet_id: selectedOutletId,
        };

        if (cashbackType?.trim() !== '' && cashbackType !== null) {
            data.cashback_type = cashbackType;
        }

        try {
            setIsDisable(true);
            const response = await APIV3.post(`export-report`, data);
            if (response.success === true) {
                setIsDisable(false);
            } else {
                setIsDisable(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelection = (selection) => {
        setCashbackType(selection.value);
        setIsLoading(false);
        fetchOrderDetail({ cashback_type: selection.value });
        setCashbackTypePagination({ cashback_type: selection.value });

        setFilters((prevState) => {
            return { ...prevState, selectedType: selection.value };
        });
        setIsLoading(true);
    };

    return (
        <>
            <div className='flex flex-row justify-between pb-4 lg:mb-3 md:block md:mb-4'>
                <div className='flex flex-row'>
                    <CalenderField
                        setSelectedDates={setSelectedDates}
                        selectedDates={selectedDates}
                    />
                </div>
                <div className='flex flex-row md:justify-between md:mt-4'>
                    <div
                        className='mx-4 lg:mx-2 md:w-1/2 md:mr-[8.5px] md:ml-0 mobile:mr-1'
                        onClick={handleClickFilter}>
                        <LargePrimaryButton
                            label='Filters'
                            leftIconDefault={<Filter fill='#FFFFFF' />}
                            leftIconClick={<Filter fill='#C4BEED' />}
                        />
                    </div>

                    <div
                        className='md:w-1/2 md:ml-[8.5px] mobile:ml-1'
                        onClick={handleClickExportData}>
                        <LargePrimaryButton
                            label='Export data'
                            leftIconDefault={<ExportIcon stroke='#FFFFFF' />}
                            leftIconClick={<ExportIcon stroke='#C4BEED' />}
                            hideLabel='lg:hidden md:block'
                            isClicked={isDisable}
                        />
                    </div>
                </div>
            </div>
            {showFilters ? (
                <div className='flex sm:block items-center justify-end mt-4 mb-4 md:mb-4'>
                    <div className='flex mobile:block'>
                        <div className='w-[200px] sm:w-1/2 mobile:w-full mobile:mr-0 mobile:mb-3'>
                            <DropdownWithValueLabel
                                menuItems={cashbackStatus}
                                placeholder='Select Type'
                                setSelected={(selection) => handleSelection(selection)}
                                label='Cashback Type'
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Header;
