import React from 'react';
import { ReactComponent as LeftArrow } from '../../../Assets/chevron-down.svg';
import { ReactComponent as Close } from '../../../Assets/close.svg';
import ReactApexChart from 'react-apexcharts';
import { IsMobileScreen } from '../../../Constants/Constants';

export default function TotalSalesPopup(props) {
    const { handleClickClose } = props;

    const salesCardContent = [
        { 'My amount': '₹5,50,000.00' },
        {
            Discount: '- ₹1,10,000.00',
        },
        {
            'Delivery charge': '₹1,000.00',
        },
        {
            'Container charge': '₹600.00',
        },
        {
            'Service charge': '₹0.00',
        },
        {
            Tax: '₹7,000.00',
        },
        { 'Waived off': '₹0.00' },
        {
            'Round off': '- ₹0.00',
        },
    ];

    const totalSalesINR = salesCardContent.reduce((acc, obj) => {
        const value = parseFloat(Object.values(obj)[0].replace(/[₹, ]/g, ''));
        return acc + value;
    }, 0);

    const totlalSales = ['Online Sales', 'Offline Sales'];

    const sales = [25, 75];

    const isMobileScreen = IsMobileScreen();

    const chartOptions = {
        plotOptions: {
            pie: {
                donut: {
                    size: '62%',
                },
            },
        },
        labels: totlalSales,
        legend: {
            show: false,
        },

        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            style: {
                fontSize: 16,
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
            },
            formatter: function (val) {
                return parseInt(val) + '%';
            },
        },
        colors: ['#FFBB3C', '#74C2B9'],
        stroke: {
            colors: 'transparent',
        },
    };
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex md:relative p-4 md:p-0 overflow-auto'>
                <div className='h-[484px] max-w-[710px] w-full md:h-full md:overflow-auto rounded-xl md:rounded-none bg-shades-50 px-8 py-6 m-auto [&::-webkit-scrollbar]:hidden md:px-4 md:max-w-full md:py-0 md:mt-0 md:pb-4'>
                    {isMobileScreen && (
                        <div
                            className='flex flex-row mb-4 pt-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <div className='w-6 h-6'>
                                <LeftArrow className='rotate-90' />
                            </div>
                            <span className='ml-1 paragraph-medium-medium'>Back to dashboard</span>
                        </div>
                    )}
                    <div className='flex flex-row justify-between items-center mb-6 md:mb-2'>
                        <div>
                            <span className='paragraph-large-medium'>Total sales</span>
                            <p className='paragraph-medium-italic text-neutral-500'>
                                Total sales defines the overall collection restaurant made
                            </p>
                        </div>
                        <div
                            className='md:hidden cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <Close />
                        </div>
                    </div>

                    <div className='flex flex-row justify-between items-center md:block'>
                        <div className='w-full'>
                            <div className='w-[238px] mx-[5.5px] mb-[26px] flex items-center justify-center md:mx-auto'>
                                <ReactApexChart
                                    className='m-auto'
                                    type='donut'
                                    width={280}
                                    height={280}
                                    series={sales}
                                    options={chartOptions}
                                />
                            </div>
                            <div className='flex flex-row justify-between md:justify-center w-full max-w-[251px] md:max-w-full'>
                                <div className='flex flex-row items-center'>
                                    <div className='h-[11px] w-[11px] rounded-full bg-tertiary-600 mr-2' />
                                    <span className='paragraph-medium-regular'>Online Sales</span>
                                </div>

                                <div className='flex flex-row items-center md:ml-6'>
                                    <div className='h-[11px] w-[11px] rounded-full bg-secondary-600 mr-2' />
                                    <span className='paragraph-medium-regular'>Offline Sales</span>
                                </div>
                            </div>
                        </div>
                        <div className='max-w-[315px] w-full md:mt-10 md:max-w-full'>
                            <div className='border-b border-neutral-300 mb-4'>
                                {salesCardContent.map((el, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='flex flex-row justify-between mb-4'>
                                            <span className='paragraph-medium-regular'>{Object.keys(el)}</span>
                                            <span className='paragraph-medium-regular'>{Object.values(el)}</span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='flex flex-row justify-between max-w-[315px] md:max-w-full'>
                                <span className='paragraph-medium-semi-bold'>Total Sales</span>
                                <span className='paragraph-medium-semi-bold'>{`₹${totalSalesINR
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
