import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DropDownTabs } from '../../../Components/DropDown/DropDownTabs';
import { Tab } from '../../../Components/Tabs/Tabs';
import { IsMobileScreen } from '../../../Constants/Constants';
import { getUsers } from '../UserSetting/Components/ApiFunctions';
import { useSelector } from 'react-redux';

const HeaderTab = ({ handleNavigation }) => {
    const { settingType } = useParams();
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const isMobileScreen = IsMobileScreen();

    const tabs = [
        {
            item: 'User settings',
            link: 'user/user-list',
            onClick: () =>
                handleNavigation({
                    route: 'user/user-list',
                    loaderFunc: getUsers({ handleArrayState: () => {}, selectedRestaurantId }),
                }),
        },
        {
            item: 'Store settings',
            link: 'store/ordering-mode',
            onClick: () => handleNavigation({ route: 'store/ordering-mode' }),
        },
        {
            item: 'Global settings',
            link: 'global/app-settings',
            onClick: () => handleNavigation({ route: 'global/app-settings' }),
        },
    ];

    return (
        <>
            {!isMobileScreen ? (
                <div className='flex flex-row items-center mb-4 pb-4 border-b border-neutral-300'>
                    {tabs.map((el, index) => (
                        <div
                            className='mr-4 lg:mr-2 cursor-pointer last:mr-0 last:lg:mr-0'
                            onClick={() => el.onClick()}
                            key={index}>
                            <Tab
                                label={el.item}
                                isActive={settingType === el.link.substring(0, el.link.indexOf('/'))}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className='w-full mb-4 pb-4 border-b border-neutral-300'>
                    <DropDownTabs menuItems={tabs} />
                </div>
            )}
        </>
    );
};

export default HeaderTab;
