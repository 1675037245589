import React from 'react';
import ContentLoader from 'react-content-loader';

export function MenuItemsLoader() {
    return (
        <div className='w-full my-2 h-full'>
            <ContentLoader
                width='100%'
                height='100%'
                className='h-[160px] w-full'
                backgroundColor='#d7d8d8'>
                <rect
                    x='0'
                    y='0'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='35'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='70'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='105'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
                <rect
                    x='0'
                    y='140'
                    rx='4'
                    ry='4'
                    width='100%'
                    height='15'
                />
            </ContentLoader>
        </div>
    );
}
