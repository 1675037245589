import React from 'react';
import InputSection from '../../Components/InputSection';
import DropdownSection from '../../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function MinimumAmountSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='my-4 max-w-[312px] relative md:max-w-full'>
                <DropdownSection
                    menuItems={preferenceMenuItem}
                    label='Minimum amount preference'
                    shadow='shadow-smallDropDownShadow'
                    labelPaddingB='pb-2'
                    height='h-[52px]'
                    labelStyle='paragraph-large-medium'
                    name='bill_payments.isAddMinimumAmount'
                    placeholder='Please select preference'
                    disabled={watch('bill_payments.customizableType')?.value !== 'simpleDiscount'}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('bill_payments.customizableType')?.value === 'simpleDiscount')
                                return 'Please select preference';
                        },
                    }}
                    clearErrorName={['bill_payments.minimum_payment_amount']}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />

                {errors?.bill_payments?.isAddMinimumAmount && (
                    <ErrorMessage errors={errors?.bill_payments?.isAddMinimumAmount} />
                )}
            </div>

            <InputSection
                placeholder='Please add the minimum amount'
                name='bill_payments.minimum_payment_amount'
                label='Minimum amount'
                labelStyle={`paragraph-large-medium ${
                    !watch('bill_payments.isAddMinimumAmount')?.value && 'text-neutral-300'
                }`}
                shadow='shadow-smallDropDownShadow'
                disabled={!watch('bill_payments.isAddMinimumAmount')?.value}
                inputType='number'
                rules={{
                    validate: (value) => {
                        if (value) return true;
                        if (watch('bill_payments.isAddMinimumAmount')?.value) return 'Please enter a minimum amount';
                    },
                }}
                handleInputChange={() => setShowTabChangeWarningPopup(true)}
            />

            {errors?.bill_payments?.minimum_payment_amount && (
                <ErrorMessage errors={errors?.bill_payments?.minimum_payment_amount} />
            )}
        </>
    );
}
