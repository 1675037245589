import React, { useState } from 'react';
import { ReactComponent as LeftArrow } from '../../Assets/chevron-down.svg';
import Header from '../../Components/Header/Header';
import { DefaultInputField } from '../../Components/InputField/InputField';
import { LargeDestructiveButton, LargePrimaryButton } from '../../Components/Buttons/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsMobileScreen, IsXlScreen } from '../../Constants/Constants';
import MobileScreenSubCategoryScreen from '../../Components/MobileViewScreen/MobileScreenSubCategoryScreen';
import { useSelector } from 'react-redux';
import { addCategory } from './Components/Catalogue/functions';
import { ErrorMessage } from '../../Components/Error/ErrorMessage';
import DetailsDropDown from './Components/Catalogue/Components/DetailsDropDown';
import _ from 'lodash';

export default function AddSubCategory() {
    const navigate = useNavigate();

    const location = useLocation();

    const { selectedCategoryDish } = location.state;

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [title, setTitle] = useState([]);

    const [internalName, setInternalName] = useState('');

    const isMobileScreen = IsMobileScreen();

    const isXlScreen = IsXlScreen();

    const goToMenuPage = () => {
        navigate('/menu');
    };

    const [errors, setErrors] = useState({ title: [], internalName: {} });

    const handleEnterTitle = (value, languageDetail, index) => {
        setErrors((prevState) => ({
            ...prevState,
            title: prevState?.title?.map((el, titleIndex) => {
                if (index === titleIndex) {
                    return {};
                }
                return el;
            }),
        }));

        setTitle((prevTitle) => {
            const existingIndex = prevTitle?.findIndex((item) => item.id === languageDetail.id);

            if (existingIndex !== -1) {
                const updatedTitle = [...prevTitle];
                updatedTitle[existingIndex].title = value;
                return updatedTitle;
            } else {
                return [...prevTitle, { id: languageDetail.id, title: value }];
            }
        });
    };

    const checkValidation = () => {
        let isValid = true;

        if (!internalName) {
            setErrors((prevState) => ({ ...prevState, internalName: { message: 'Please enter internal name' } }));
            isValid = false;
        }

        const titleErrors = availableLanguages.map((_, index) => {
            if (!title?.[index]?.title) {
                return { message: 'Please enter title' };
            }
            return {};
        });

        if (!_.every(titleErrors, _.isEmpty)) {
            isValid = false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            title: titleErrors,
        }));
        return isValid;
    };

    const formateBody = () => {
        const titleTranslations = title.reduce((acc, curr) => {
            acc[curr.id] = curr.title;
            return acc;
        }, {});

        const body = {
            internal_name: internalName,
            translations: {
                title: titleTranslations,
            },
            parent_id: selectedCategoryDish.category.category_id,
        };

        return body;
    };

    const handleSave = async () => {
        const isValid = checkValidation();

        if (!isValid) return;

        const response = await addCategory(selectedRestaurantId, formateBody());

        setErrors((prevState) => ({
            ...prevState,
            internalName: { message: response?.data?.errors?.internal_name },
        }));
    };

    const handleEnterInternalName = (name) => {
        setInternalName(name);
        setErrors((prevState) => ({ ...prevState, internalName: {} }));
    };

    const languageMenuItem = availableLanguages?.map((el) => ({ label: el.language_label, value: el }));

    const [selectedLanguageForMobile, setSelectedLanguageForMobile] = useState(languageMenuItem[0]);

    return (
        <>
            {!isMobileScreen && (
                <div className='bg-[#fafafa]'>
                    <div className='px-8 lg:px-4 pt-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full'>
                        {isMobileScreen && (
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                                onClick={goToMenuPage}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to catalogue</span>
                            </div>
                        )}

                        <div className='flex flex-row xl:block xl:mb-0'>
                            <div>
                                <div className='min-w-[747px] w-full pr-[110px] xl:pr-0 xl:pb-4 xl:border-r-0 xl:border-b xl:mb-6 xl:min-w-full pb-6 md:pb-2'>
                                    <div className='mb-6'>
                                        <h1 className={`paragraph-large-medium`}>Internal name</h1>

                                        <span className={`paragraph-medium-italic text-neutral-500`}>
                                            Internal name will be used to search the item.
                                        </span>

                                        <div className={`max-w-[312px] w-full relative md:max-w-full mt-6`}>
                                            <DefaultInputField
                                                boxHeight='[52px]'
                                                placeholder='Enter internal name'
                                                shadow='shadow-smallDropDownShadow'
                                                enteredValue={(internalName) => handleEnterInternalName(internalName)}
                                            />
                                            {errors?.internalName?.message && (
                                                <ErrorMessage errors={errors?.internalName} />
                                            )}
                                        </div>
                                    </div>

                                    <div className='mb-2'>
                                        <Header
                                            title='Title'
                                            page='menuManagement'
                                            headerBottomLine='Please enter the category titles that will be displayed with the category image in the mobile application.'
                                        />
                                    </div>

                                    <div className='flex flex-row items-center mb-6 md:block'>
                                        {availableLanguages.map((el, index) => {
                                            return (
                                                <div
                                                    className='mr-1.5 w-1/2 lg:w-full lg:max-w-[312px] md:max-w-full md:mr-0 md:mb-2 relative'
                                                    key={index}>
                                                    <DefaultInputField
                                                        boxHeight='[52px]'
                                                        labelMarginB='mb-2'
                                                        label={`(${el.language_label})`}
                                                        labelStyle='paragraph-medium-italic text-neutral-500'
                                                        placeholder={`Enter title in ${el.language_label}`}
                                                        shadow='shadow-smallDropDownShadow'
                                                        enteredValue={(value) => handleEnterTitle(value, el, index)}
                                                    />
                                                    {errors?.title?.[index]?.message && (
                                                        <ErrorMessage
                                                            errors={errors?.title?.[index]}
                                                            className='absolute -bottom-5'
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`w-full flex justify-center pb-10 ${!isXlScreen && 'border-l'} border-neutral-300`}>
                                <div className='flex flex-col gap-4 items-center h-full'>
                                    <MobileScreenSubCategoryScreen
                                        internalName={internalName}
                                        title={
                                            title.find((el) => el.id === selectedLanguageForMobile?.value?.id)?.title
                                        }
                                    />
                                    <DetailsDropDown
                                        menuItem={languageMenuItem}
                                        handleChangeDropDown={(selection) => setSelectedLanguageForMobile(selection)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='z-[49] md:z-[8] group-[.undock-left-panel]:z-[8] fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 lg:py-4'>
                        <div className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'>
                            <LargeDestructiveButton label='Discard' />
                        </div>

                        <div
                            className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                            onClick={handleSave}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
