import React, { useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import riderImage from '../../../Assets/rider-image.png';
import ListViewRiderDetail from './ListViewRiderDetail';
import { IsMobileScreen } from '../../../Constants/Constants';
import { format, parse } from 'date-fns';
import RiderDetailsTopLoader from './RiderDetailsTopLoader';
import ListViewRiderDetailTop from './ListViewRiderDetailTop';

const RiderDetailsTop = (props) => {
    const { goToRiderPage, riderDetails, isRiderTopLoading } = props;
    const isMobileScreen = IsMobileScreen();

    function formatDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        const monthNames = ['Jan', 'Feb', 'March', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[date.getMonth()];
        const formattedDate = `${day} ${monthName} ${year}`;

        return formattedDate;
    }

    return (
        <>
            {isMobileScreen && (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={goToRiderPage}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='ml-1'>Back to riders</span>
                </div>
            )}

            {!isMobileScreen ? (
                isRiderTopLoading ? (
                    <RiderDetailsTopLoader />
                ) : (
                    <div className='mt-4 items-center flex flex-row border-b pb-4 border-neutral-300 xl:items-start'>
                        <img
                            src={
                                riderDetails && riderDetails?.profile_image_url
                                    ? riderDetails?.profile_image_url
                                    : 'N/A'
                            }
                            alt=''
                            className='h-[120px] w-[120px]'
                        />
                        <div className='w-full pr-[9px] py-1.5 pl-6 xl:py-0 lg:pl-4 lg:pr-0'>
                            <div className='flex flex-row justify-between'>
                                <div className='flex flex-row xl:flex-col'>
                                    <div className='mr-[103px]'>
                                        <div className='mt-1.5 pb-1.5 lg:mt-0'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>Name:</span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails.first_name && riderDetails.last_name
                                                    ? `${riderDetails.first_name} ${riderDetails.last_name}`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Orders delivered:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.orders_delivered
                                                    ? riderDetails?.orders_delivered
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Mobile number:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.mobile_number
                                                    ? riderDetails?.mobile_number
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Last order:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.latest_rider_order
                                                    ? formatDate(riderDetails?.latest_rider_order)
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row xl:flex-col'>
                                    <div className='mr-[103px] xl:mr-0'>
                                        <div className='mt-1.5 pb-1.5 lg:mt-0'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Delivery radius:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.delivery_radius !== 0
                                                    ? `${riderDetails?.delivery_radius} kms`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Join date:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.join_date
                                                    ? formatDate(riderDetails?.join_date)
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Delivery rate:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.delivery_rate !== 0
                                                    ? `₹${riderDetails?.delivery_rate}/-`
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                        <div className='mt-1.5 pb-1.5'>
                                            <span className='paragraph-medium-semi-bold text-neutral-400'>
                                                Rider avg. rating:
                                            </span>
                                            <span className='ml-1 paragraph-medium-regular'>
                                                {riderDetails && riderDetails?.average_rating
                                                    ? riderDetails?.average_rating
                                                    : '0.0'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-1.5 pb-1.5 flex flex-row lg:pb-0'>
                                <span className='paragraph-medium-semi-bold text-neutral-400 min-w-[112px]'>
                                    Rider address:
                                </span>
                                <span className='ml-1 paragraph-medium-regular'>
                                    {riderDetails && riderDetails?.rider_address ? riderDetails?.rider_address : 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className='border-b pb-4 mb-4 border-neutral-300'>
                    <ListViewRiderDetailTop
                        riderName={
                            riderDetails && riderDetails.first_name && riderDetails.last_name
                                ? `${riderDetails.first_name} ${riderDetails.last_name}`
                                : 'N/A'
                        }
                        riderRating={riderDetails && riderDetails?.average_rating ? riderDetails?.average_rating : 0}
                        billName={riderDetails && riderDetails?.rider_name ? riderDetails?.rider_name : 'N/A'}
                        mobileNumber={riderDetails && riderDetails?.mobile_number ? riderDetails?.mobile_number : 'N/A'}
                        ordersDelivered={
                            riderDetails && riderDetails?.orders_delivered ? riderDetails?.orders_delivered : 'N/A'
                        }
                        deliveryRadius={
                            riderDetails && riderDetails?.delivery_radius ? riderDetails?.delivery_radius : 'N/A'
                        }
                        deliveryRate={riderDetails && riderDetails?.delivery_rate ? riderDetails?.delivery_rate : 'N/A'}
                        joinDate={riderDetails && riderDetails?.join_date ? formatDate(riderDetails?.join_date) : 'N/A'}
                        lastOrder={
                            riderDetails && riderDetails?.latest_rider_order
                                ? formatDate(riderDetails?.latest_rider_order)
                                : 'N/A'
                        }
                        riderAddress={riderDetails && riderDetails?.rider_address ? riderDetails?.rider_address : 'N/A'}
                    />
                </div>
            )}
        </>
    );
};

export default RiderDetailsTop;
