import React from 'react';
import { LargePrimaryButton, LargeTertiaryButton } from '../../../../../Components/Buttons/Button';

export default function Footer(props) {
    const { handleClickSave, isSaveDisabled, handleClickClose } = props;

    return (
        <>
            <div className='flex justify-end mt-12 md:fixed md:bottom-0 md:block md:w-full md:pb-1 md:pt-2 md:shadow-dropShadow md:bg-white md:-ml-4'>
                <div className='md:max-w-full md:mx-4 flex flex-row'>
                    <LargeTertiaryButton
                        label='Cancel'
                        onClick={handleClickClose}
                    />

                    <div
                        className='ml-5 w-full'
                        onClick={handleClickSave}>
                        <LargePrimaryButton
                            label='Save changes'
                            disabled={isSaveDisabled}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
