import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LargeTitleSwitch from '../../Components/ToggleSwitch/LargeTitleSwitch';
import Layout from './Components/Layout';
import { ReactComponent as RedirectIcon } from '../../Assets/redirect.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOTIFICATION_SOUND_LOOP } from '../../reduxStore/types/device';
import TitleDescription from '../../Components/TitleDescription/TitleDescription';
import { LargePrimaryButton } from '../../Components/Buttons/Button';
// import APIV2 from '../../api/axios/APIV2';
// import { useSelector } from 'react-redux';

export default function DeviceSetting() {
    const notificationSoundLoop = JSON.parse(localStorage.getItem('notificationSoundLoop'));

    const methods = useForm({
        defaultValues: {
            continuousIncomingTone: !!notificationSoundLoop,
        },
    });

    const { control, watch } = methods;

    // const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const toggleNotification = async () => {
        // try {
        //     const response = await APIV2.patch(`/restaurants/${selectedRestaurantId}/devices/toggle-accepting-orders`, {
        //         device_id: localStorage.getItem('deviceId'),
        //     });
        // } catch (error) {
        //     console.log('error : ', error);
        // }
    };

    const dispatch = useDispatch();

    const toggleContinuesTone = () => {
        localStorage.setItem('notificationSoundLoop', watch('continuousIncomingTone') ? 1 : 0);

        dispatch({
            type: NOTIFICATION_SOUND_LOOP,
            payload: {
                isContinueLoop: watch('continuousIncomingTone'),
            },
        });
    };

    const handleSave = () => {
        toggleNotification();
        toggleContinuesTone();
    };

    const navigate = useNavigate();

    return (
        <>
            <FormProvider {...methods}>
                <Layout handleSave={handleSave}>
                    <LargeTitleSwitch
                        classNames='mb-6'
                        switchName='enableNotification'
                        title='Enable Notifications'
                        description='Enable this option to activate notifications for all relevant updates and alerts.'
                        control={control}
                    />

                    <LargeTitleSwitch
                        classNames='mb-6'
                        switchName='continuousIncomingTone'
                        title='Enable Continuous Incoming Tone'
                        description='Enable this option to activate continuous ringing for new incoming orders.'
                        control={control}
                    />

                    <TitleDescription
                        className='mb-3'
                        title='Select Restaurant & Outlets'
                        description='Choose your preferred restaurant and outlets'
                    />

                    <div
                        className='max-w-[210px]'
                        onClick={() => navigate('/select-restaurant')}>
                        <LargePrimaryButton
                            label='Select restaurant'
                            rightIconDefault={<RedirectIcon fill='#FFFFFF' />}
                            rightIconClick={<RedirectIcon fill='#C4BEED' />}
                        />
                    </div>
                </Layout>
            </FormProvider>
        </>
    );
}
