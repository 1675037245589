import React from 'react';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';

const OrderActions = (props) => {
    const { orderingMode, setShowPopup } = props;

    return (
        <>
            {orderingMode !== 'Dine-In order' ? (
                <div className=''>
                    <div className='flex flex-row mb-2'>
                        <div
                            className='w-1/2 mr-[7px] cursor-pointer'
                            onClick={() => setShowPopup('cancelOrder')}>
                            <LargeDestructiveButton
                                label='Cancel order'
                                isDefault={false}
                            />
                        </div>

                        <div
                            className='w-1/2 ml-[7px]'
                            onClick={() => setShowPopup('acceptOrder')}>
                            <LargePrimaryButton
                                label='Accept order'
                                isDefault={false}
                            />
                        </div>
                    </div>

                    <div
                        className='w-full'
                        onClick={() => setShowPopup('riderAssignment')}>
                        <LargeTertiaryButton
                            label='Rider assignment'
                            isDefault={false}
                        />
                    </div>
                </div>
            ) : (
                <div className='gap-2 items-center text-center justify-center'>
                    <div
                        className='w-full mb-3'
                        onClick={() => setShowPopup('readyOrder')}>
                        <LargePrimaryButton
                            label='Ready order'
                            isDefault={false}
                        />
                    </div>

                    <span
                        className='text-primary-500 paragraph-medium-regular cursor-pointer text-center hover:underline underline-offset-2'
                        onClick={() => setShowPopup('cancelOrder')}>
                        Cancel order
                    </span>
                </div>
            )}
        </>
    );
};

export default OrderActions;
