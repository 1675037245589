import React from 'react';
import { DefaultInputField } from '../../../../../Components/InputField/InputField';
import DropdownWithValueLabel from '../../../../../Components/DropDown/DropdownWithValueLabel';
import ToggleSwitch from '../../../../../Components/ToggleSwitch/ToggleSwitch';
import { offerTypes } from '../../../Constants/Constants';
import { ReactComponent as SearchIcon } from '../../../../../Assets/search.svg';

export default function Filters(props) {
    const { handleFilterChange } = props;

    return (
        <>
            <div className='flex flex-row justify-between items-center my-[34px] md:block md:my-0 md:mt-4'>
                <div className='max-w-[375px] w-full lg:max-w-[250px] md:max-w-full md:mb-2'>
                    <DefaultInputField
                        placeholderIcon={<SearchIcon stroke='#D3D2D8' />}
                        placeholder='Search outlet'
                        enteredValue={(value) => handleFilterChange('keyword', value)}
                    />
                </div>

                <div className='flex flex-row items-center md:block gap-4'>
                    <div className='flex flex-row items-center md:justify-between md:mb-4'>
                        <span className='paragraph-large-medium text-black mx-3 lg:mx-2'>Show enabled offers</span>
                        <ToggleSwitch
                            setIsEnabled={(value) => handleFilterChange('is_offer_active', value ? 1 : null)}
                            canChange
                            isEnable={false}
                        />
                    </div>

                    <div className='w-[200px] md:w-full ml-2 sm:ml-0 md:ml-1 -mt-[22px] md:-mt-0 lg:w-[160px] md:mb-2'>
                        <DropdownWithValueLabel
                            menuItems={offerTypes}
                            label='Offer Type'
                            placeholder='Select offer type'
                            setSelected={(selection) => handleFilterChange('offer_type', selection.value)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
