import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as TimerIcon } from '../../Assets/timer.svg';
import TotalBill from './Components/TotalBill';
import './styles.css';
import OrderHeader from './Components/OrderHeader';
import OrderDishes, { ItemsComponent } from './Components/OrderDishes';
import OrderActions from './Components/OrderActions';

export default function KOT(props) {
    const {
        icon,
        orderingMode,
        label,
        tableServed,
        dishPacked,
        dishServed,
        handleClickCancelOrder,
        isUpdated,
        items,
        setShowPopup,
        kotFixedHeight,
    } = props;

    const [showWithItems, setShowWithItems] = useState(false);
    const [showViewDetailsTooltip, setShowViewDetailsTooltip] = useState(false);

    const kotRef = useRef();

    const [dishFixedHeight, setDishFixedHeight] = useState(0);

    useEffect(() => {
        setDishFixedHeight(kotFixedHeight - kotRef?.current?.offsetHeight);
    }, [kotFixedHeight]);

    return (
        <>
            <div
                className={`w-full border border-neutral-300 rounded-xl p-4`}
                ref={kotRef}>
                <OrderHeader
                    orderingMode={orderingMode}
                    icon={icon}
                    label={label}
                    setShowPopup={setShowPopup}
                />
                <OrderDishes
                    dishPacked={dishPacked}
                    dishServed={dishServed}
                    tableServed={tableServed}
                    isUpdated={isUpdated}
                />
                <ItemsComponent
                    items={items}
                    handleClickWithDropDown={() => setShowWithItems((prevState) => !prevState)}
                    showWithItems={showWithItems}
                    dishFixedHeight={kotFixedHeight ? dishFixedHeight : 'auto'}
                />
                <div className='flex flex-row justify-between paragraph-medium-semi-bold mb-4 pb-4 border-b border-neutral-300'>
                    <TotalBill />
                </div>

                <div className='flex flex-row justify-between pb-4 mb-4 border-b border-neutral-300'>
                    <span className='paragraph-medium-semi-bold'>
                        Mr. Sarthak's<span className='paragraph-medium-regular text-primary-500'> 56th order</span>
                    </span>

                    <div
                        className='relative'
                        onMouseEnter={() => setShowViewDetailsTooltip(true)}
                        onMouseLeave={() => setShowViewDetailsTooltip(false)}>
                        <span
                            onClick={() => orderingMode === 'Dine-In order' && setShowPopup('customerDetails')}
                            className='paragraph-medium-underline text-primary-500 cursor-pointer'>
                            View details
                        </span>

                        {showViewDetailsTooltip && orderingMode !== 'Dine-In order' && (
                            <div className='border border-neutral-300 rounded-lg p-4 absolute w-[150px] right-0 bg-neutral-50'>
                                <div
                                    className='w-full mb-2 pb-2 cursor-pointer'
                                    onClick={() => setShowPopup('customerDetails')}>
                                    Customer details
                                </div>
                                <div
                                    className='cursor-pointer'
                                    onClick={() => setShowPopup('riderDetails')}>
                                    Rider details
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <OrderActions
                    orderingMode={orderingMode}
                    handleClickCancelOrder={handleClickCancelOrder}
                    setShowPopup={setShowPopup}
                />

                <div className='mt-4 pt-4 border-t flex flex-row justify-between paragraph-medium-semi-bold border-neutral-300 text-[#F15B5B]'>
                    <span>Time elapsed</span>

                    <div className='flex flex-row items-center'>
                        <span className='ping-animation absolute h-6 w-6 rounded-full bg-[#F15B5B]' />
                        <div className='w-[20px] h-[20px] rounded-full bg-white absolute animate-none ml-0.5' />
                        <TimerIcon
                            height={24}
                            width={24}
                            stroke='#F15B5B'
                            className='z-50'
                        />

                        <span className='ml-2'>
                            {/* {timeElapsed[0].mins} mins {timeElapsed[1].seconds} secs */}
                            15 mins 16 secs
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
