import React, { useEffect, useState } from 'react';
import SelectColorPopup from '../../../../../Components/SelectColorPopup/SelectColorPopup';
import MobileScreenBrand from '../../../../../Components/MobileViewScreen/MobileScreenBrand';
import Layout from '../../../Components/Layout';
import { useFormContext } from 'react-hook-form';
import FormLayout from './Components/FormLayout';
import LogoSection from './Components/LogoSection';
import UploadImagePopup from '../../../../../Components/UploadImagePopup/UploadImagePopup';
import InputSection from '../../../Components/InputSection';
import ColorSelectionSection from './Components/ColorSelectionSection';
import MessageSection from './Components/MessageSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import SelectImageFromLibraryPopup from '../../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import Loader from './Components/Loader';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';

export default function Brand(props) {
    const { fetchSettings, handleDiscardForm, fetchlanguageList } = props;

    const {
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { isDirty, isValid, errors },
    } = useFormContext();

    const onSubmit = (data) => {
        updateSetting(formateData(data.brand_settings));
    };

    const formateData = (data) => {
        console.log('data ==>', data);

        const transformedData = {
            logo_footer_image_id: data?.logo_footer_image?.id,
            logo_intro_image_id: data?.logo_intro_image?.id,
            brand_color_primary: data?.brand_color_primary,
            brand_color_secondary: data?.brand_color_secondary,
            logo_height: data?.logo_height,
            translations: {},
        };

        Object.entries(data.translations).forEach(([key, value]) => {
            transformedData.translations[key] = {};
            value.forEach((item, index) => {
                if (item !== null) {
                    transformedData.translations[key][index.toString()] = item;
                }
            });
        });

        return { ...transformedData };
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const updateSetting = async (params) => {
        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}?section=brand_settings`, {
                ...params,
            });

            if (response.success) {
                reset();
                setValue('isLoadingForm', true);
                fetchlanguageList();
                fetchSettings();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [showuploadImageKey, setShowuploadImageKey] = useState(null);

    const [showSelectFromLibraryPopupKey, setShowSelectFromLibraryPopupKey] = useState(null);

    const [mobileScreenPage, setMobileScreenPage] = useState();

    const [showColorPopup, setShowColorPopup] = useState(null);

    const handleClickSaveColor = (color) => {
        showColorPopup === 'brand_color_secondary' && setMobileScreenPage('category');

        showColorPopup === 'brand_color_primary' && setMobileScreenPage('home');

        setValue(`brand_settings.${showColorPopup}`, color, { shouldDirty: true });

        setShowColorPopup(null);
    };

    useEffect(() => {
        showuploadImageKey === 'brand_logo' && setMobileScreenPage('home');
        showuploadImageKey === 'footer_logo' && setMobileScreenPage('category');
    }, [showuploadImageKey]);

    return watch('isLoadingForm') ? (
        <Loader />
    ) : (
        <>
            <Layout
                handleClickSave={handleSubmit(onSubmit)}
                handleClickCancel={() => handleDiscardForm()}
                showFooter={isDirty}>
                <div className='flex flex-row justify-between xl:block -mb-20 xl:mb-0'>
                    <FormLayout isDirty={isDirty}>
                        <LogoSection
                            setShowuploadImageKey={setShowuploadImageKey}
                            setShowSelectFromLibraryPopupKey={setShowSelectFromLibraryPopupKey}
                        />

                        <div className='mb-4'>
                            <InputSection
                                inputValue={watch('brand_settings.logo_height')}
                                title='Logo height'
                                description="Adjust the height of your brand logo on the mobile application's home screen."
                                name='brand_settings.logo_height'
                                placeholder='Enter logo height in pixels'
                                rules={{ required: 'Please ennter logo height' }}
                                inputType='number'
                            />
                            {errors?.brand_settings?.logo_height && (
                                <ErrorMessage errors={errors?.brand_settings?.logo_height} />
                            )}
                        </div>

                        <ColorSelectionSection handleClickColorSelector={(key) => setShowColorPopup(key)} />

                        <MessageSection setMobileScreenPage={setMobileScreenPage} />
                    </FormLayout>

                    <div className='w-full justify-center flex pageContent:max-w-[370px] pageContent:pb-8 pageContent:mt-3 pageContent:h-full pageContentLarge:overflow-auto pageContent:mx-auto'>
                        <MobileScreenBrand
                            brandLogo={watch('brand_settings.brand_logo.image_url')}
                            footerLogo={watch('brand_settings.footer_logo.image_url')}
                            mobileScreenPage={mobileScreenPage}
                            selectedColors={{
                                primaryColor: watch('brand_settings.brand_color_primary'),
                                secondaryColor: watch('brand_settings.brand_color_secondary'),
                            }}
                            greetingMassage={watch('brand_settings.translations.like_to_order')?.[1]}
                            instructionmassage={watch('brand_settings.translations.special_instruction_label')?.[1]}
                        />
                    </div>
                </div>
            </Layout>

            {!!showuploadImageKey && (
                <UploadImagePopup
                    setUploadedImageDetails={(imageDetails) =>
                        setValue(`brand_settings.${showuploadImageKey}`, imageDetails)
                    }
                    handleClickClose={() => setShowuploadImageKey(null)}
                    backToPage='global setting'
                />
            )}

            {!!showSelectFromLibraryPopupKey && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibraryPopupKey(null)}
                    handleSelectImage={(imageDetails) =>
                        setValue(`brand_settings.${showSelectFromLibraryPopupKey}`, imageDetails)
                    }
                />
            )}

            {!!showColorPopup && (
                <SelectColorPopup
                    handleClickClose={() => setShowColorPopup(null)}
                    backToPage='global setting'
                    description='Select the primary color of your brand'
                    handleClickSave={handleClickSaveColor}
                    selectedColor={watch('brand_settings')?.[showColorPopup]}
                />
            )}
        </>
    );
}
