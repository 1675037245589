import React from 'react';
import { SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';

export default function Loader() {
    return (
        <>
            <div className='mr-4 w-full mt-6 max-w-[636px]'>
                <div className='flex flex-row items-center justify-between max-w-[636px] w-full'>
                    <SingleLoader className='max-w-[340px] h-[30px] mt-2' />
                    <SingleLoader className='max-w-[40px] h-6 mt-2' />
                </div>

                <TitleDescriptionLoader className='mt-6' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />

                <TitleDescriptionLoader className='mt-6' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />

                <TitleDescriptionLoader className='mt-6' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />

                <TitleDescriptionLoader className='mt-6' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />
                <SingleLoader className='max-w-full h-[60px] mt-4' />
            </div>
        </>
    );
}
