import React from 'react';
import ToggleSwitch from '../../../../../../Components/ToggleSwitch/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import TitleDescription from '../../../../../../Components/TitleDescription/TitleDescription';
import DropdownSection from '../../Components/DropdownSection';
import InputSection from '../../Components/InputSection';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function DistanceFeeGstSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        watch,
        control,
        clearErrors,
        formState: { errors },
    } = useFormContext();

    const gstSlabMenu = [
        { label: '5%', value: 5 },
        { label: '12%', value: 12 },
        { label: '18%', value: 18 },
        { label: '28%', value: 28 },
    ];

    const toggleSwitch = (fieldChange, value, errorName) => {
        fieldChange(value);
        clearErrors(errorName);
        setShowTabChangeWarningPopup(true);
    };

    return (
        <>
            <div className='mb-4'>
                <div className='flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full mb-4'>
                    <TitleDescription
                        title='Distance fees'
                        description='The distance fees can be set above a normal radius. Charges set above the normal radius will apply only if the delivery is beyond the radius. A distance fee per kilometre can be charged to the customers.'
                    />

                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={field.value ?? false}
                                canChange
                                setIsEnabled={(value) => {
                                    toggleSwitch(field.onChange, value, [
                                        'delivery_charge.deliveryRadius',
                                        'delivery_charge.extraCharges',
                                    ]);
                                }}
                            />
                        )}
                        name='delivery_charge.hasDistanceFees'
                        control={control}
                    />
                </div>

                <InputSection
                    name='delivery_charge.distance_fee.normal_delivery_radius'
                    label='(Normal delivery radius)'
                    labelStyle={`paragraph-medium-italic ${
                        !watch('delivery_charge.hasDistanceFees') ? 'text-neutral-300' : 'text-neutral-500'
                    }`}
                    placeholder='Enter distance in kms'
                    rule={{ required: 'Please enter a delivery radius' }}
                    inputType='number'
                    disabled={!watch('delivery_charge.hasDistanceFees')}
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('delivery_charge.hasDistanceFees')) return 'Please enter a delivery radius';
                        },
                    }}
                />
                {errors?.delivery_charge?.distance_fee?.normal_delivery_radius && (
                    <ErrorMessage errors={errors?.delivery_charge?.distance_fee?.normal_delivery_radius} />
                )}
            </div>

            <div className='max-w-[312px] w-full mb-6 md:max-w-full'>
                <InputSection
                    name='delivery_charge.distance_fee.extra_charge'
                    rule={{ required: 'Please enter a extra charges' }}
                    inputType='number'
                    disabled={!watch('delivery_charge.hasDistanceFees')}
                    label='(Extra charge)'
                    labelStyle={`paragraph-medium-italic ${
                        !watch('delivery_charge.hasDistanceFees') ? 'text-neutral-300' : 'text-neutral-500'
                    }`}
                    placeholder='Enter extra charge in rupees'
                    shadow='shadow-smallDropDownShadow'
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('delivery_charge.hasDistanceFees')) return 'Please enter a delivery radius';
                        },
                    }}
                    handleInputChange={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.delivery_charge?.distance_fee?.extra_charge && (
                    <ErrorMessage errors={errors?.delivery_charge?.distance_fee?.extra_charge} />
                )}
            </div>

            <div className='mb-4'>
                <div className='flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full mb-4'>
                    <TitleDescription
                        title='(GST Slabs)'
                        description='You can choose to separately display and collect taxes on delivery charges for the customers.'
                    />

                    <Controller
                        render={({ field }) => (
                            <ToggleSwitch
                                isEnable={field.value ?? false}
                                canChange
                                setIsEnabled={(value) => toggleSwitch(field.onChange, value, 'delivery_charge.gstSlab')}
                            />
                        )}
                        name='delivery_charge.hasGstSlab'
                        control={control}
                    />
                </div>

                <DropdownSection
                    name='delivery_charge.gstSlab'
                    menuItems={gstSlabMenu}
                    labelPaddingB='pb-2'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    shadow='shadow-smallDropDownShadow'
                    placeholder='Please select GST slab'
                    openFromBottom
                    disabled={!watch('delivery_charge.hasGstSlab')}
                    rules={{
                        validate: (value) => {
                            if (value) return true;
                            if (watch('delivery_charge.hasGstSlab')) return 'Please select GST slab';
                        },
                    }}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.delivery_charge?.gstSlab && <ErrorMessage errors={errors?.delivery_charge?.gstSlab} />}
            </div>
        </>
    );
}
