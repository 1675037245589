import React, { useEffect, useRef, useState } from 'react';
import { Dishes } from './Card';
import { fetchDishList, getFoodType, unLinkItem } from '../functions';
import { useWindowSize } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import ListLoader from './ListLoader';

import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';

export default function SubCategoryDishList(props) {
    const {
        pageState,
        setSelectedCategoryDish,
        selectedCategoryDish,
        handleClickEditCategoryDish,
        setAllCategoryDish,
        allCategoryDish,
        selectedDishId,
        setSelectedDishId,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const [isLoading, setIsLoading] = useState(true);

    const [subCategoryDishList, setSubCategoryDishList] = useState([]);

    const checkDishExist = (category) => {
        const hasDish = allCategoryDish
            .find((el) => el.category_id === category.parent_id)
            ?.sub_categories?.[0]?.hasOwnProperty('dish');

        if (hasDish) {
            const dishList = allCategoryDish
                .find((el) => el.category_id === category.parent_id)
                ?.sub_categories?.find((el) => el.category_id === category.category_id)?.dish;

            setSubCategoryDishList((prevState) => [
                ...prevState,
                { category: { name: category.internal_name, id: category.category_id }, dish: dishList },
            ]);
        }

        return hasDish;
    };

    const getAllSubCategory = async (category) => {
        let dishList = [];

        const hasDish = checkDishExist(category);

        if (hasDish) {
            return;
        }

        for (let index = 0; index < Math.ceil(category.total_items / 10); index++) {
            const dish = await fetchDishList(
                selectedRestaurantId,
                { page: index + 1, per_page: 10 },
                category.category_id
            );

            dishList.push(...dish.data);
        }

        setAllCategoryDish((prevState) =>
            prevState.map((el) => {
                if (el.category_id === category.parent_id) {
                    return {
                        ...el,
                        sub_categories: el.sub_categories.map((subCategoryEl) => {
                            if (subCategoryEl.category_id === category.category_id) {
                                return { ...subCategoryEl, dish: dishList };
                            } else {
                                return subCategoryEl;
                            }
                        }),
                    };
                }

                return el;
            })
        );

        setSubCategoryDishList((prevState) => [
            ...prevState,
            { category: { name: category.internal_name, id: category.category_id }, dish: dishList },
        ]);
    };

    useEffect(() => {
        if (!!selectedCategoryDish?.subCategory?.length) {
            setIsLoading(true);

            setSubCategoryDishList([]);

            const getAllSubCategoryPromises = selectedCategoryDish?.subCategory.map((el) => getAllSubCategory(el));

            Promise.all(getAllSubCategoryPromises)
                .then(() => {
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error while fetching subcategories:', error);
                });
        }
    }, [selectedCategoryDish.category.category_id]);

    const screenSize = useWindowSize();

    const dishListParentRef = useRef(null);

    const subCategoryDishListRef = useRef([]);

    const [disableScroll, setDisableScroll] = useState(false);

    useEffect(() => {
        const dishParentDiv = dishListParentRef?.current;

        dishParentDiv?.addEventListener('scrollend', () => setDisableScroll(false));

        const targetIndex = subCategoryDishList.findIndex(
            (item) => item.category.id === selectedCategoryDish.subCategoryId
        );

        if (targetIndex !== -1 && subCategoryDishListRef.current[targetIndex]) {
            setDisableScroll(true);

            subCategoryDishListRef.current[targetIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [selectedCategoryDish.subCategoryId]);

    const handleScroll = (event) => {
        const scrollPosition = event?.target?.scrollTop;

        const categoryIndex = findCategoryIndexByScrollPosition(scrollPosition);

        if (categoryIndex !== -1) {
            setSelectedCategoryDish((prevState) => ({
                ...prevState,
                subCategoryId: subCategoryDishList[categoryIndex].category.id,
            }));
        }
    };

    const findCategoryIndexByScrollPosition = (scrollPosition) => {
        let cumulativeHeight = 0;

        for (let i = 0; i < subCategoryDishListRef.current.length; i++) {
            const categoryHeight = subCategoryDishListRef.current[i].offsetHeight;

            cumulativeHeight += categoryHeight;

            if (cumulativeHeight > scrollPosition) {
                return i;
            }
        }

        return -1;
    };

    return (
        <>
            {isLoading ? (
                <ListLoader className='min-w-[410px] h-[104px]' />
            ) : (
                <div
                    onScroll={(e) => !disableScroll && handleScroll(e)}
                    id='parent'
                    ref={dishListParentRef}
                    className='scrollbar-style -mr-8 pr-8 overflow-auto -ml-6 pl-6 pageContentSmall:-mr-4'
                    style={{ height: screenSize.height - 300 }}>
                    {subCategoryDishList?.map((el, index) => {
                        return (
                            <div
                                key={index}
                                ref={(ref) => (subCategoryDishListRef.current[index] = ref)}>
                                <SingleCategoryDishList
                                    details={el}
                                    handleClickEditCategoryDish={handleClickEditCategoryDish}
                                    setSelectedCategoryDish={setSelectedCategoryDish}
                                    selectedCategoryDish={selectedCategoryDish}
                                    pageState={pageState}
                                    selectedDishId={selectedDishId}
                                    setSelectedDishId={setSelectedDishId}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

const SingleCategoryDishList = (props) => {
    const {
        details,
        handleClickEditCategoryDish,
        setSelectedCategoryDish,
        selectedCategoryDish,
        pageState,
        selectedDishId,
        setSelectedDishId,
    } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const handleUnlinkItem = (dish) => {
        const params = {
            item_id: dish.item_id,
            category_id: dish.category_id,
        };

        unLinkItem(selectedRestaurantId, params);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
    };

    return (
        <>
            <h5 className='text-neutral-700 paragraph-large-italic pt-4'>{details.category.name}</h5>

            {!details?.dish.length && (
                <span className='paragraph-small-italic text-neutral-500'>No dishes available</span>
            )}

            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={details?.dish.map((item) => item.item_id)}
                    disabled={!pageState.reArrange}>
                    {details?.dish.map((dish, index) => (
                        <SortableItem
                            key={dish.item_id}
                            id={dish.item_id}>
                            <DraggableItem
                                key={dish.item_id}
                                index={index}
                                handleClickEditCategoryDish={() => handleClickEditCategoryDish(dish.item_id)}
                                item={dish}
                                setSelectedCategoryDish={setSelectedCategoryDish}
                                selectedCategoryDish={selectedCategoryDish}
                                pageState={pageState}
                                handleUnlinkItem={() => handleUnlinkItem(dish)}
                                selectedDishId={selectedDishId}
                                setSelectedDishId={setSelectedDishId}
                            />
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        </>
    );
};

const DraggableItem = (props) => {
    const {
        index,
        handleClickEditCategoryDish,
        item,
        setSelectedCategoryDish,
        selectedCategoryDish,
        handleUnlinkItem,
        pageState,
        selectedDishId,
        setSelectedDishId,
    } = props;

    const handleSelectDish = (isSelected) => {
        const selectedDishState = isSelected
            ? [...selectedDishId, item.item_id]
            : selectedDishId.filter((el) => el !== item.item_id);

        setSelectedDishId(selectedDishState);
    };

    return (
        <>
            <div className={`mt-4 min-w-[411px] lg:min-w-0 ${(pageState.edit || pageState.reArrange) && 'md:pl-2.5'}`}>
                <Dishes
                    handleClickEdit={handleClickEditCategoryDish}
                    isEdit={pageState.edit}
                    title={item.internal_name}
                    image={item.image_url}
                    variants={item.variants_count}
                    handleClickDish={() =>
                        setSelectedCategoryDish((prevState) => ({ ...prevState, dish: item.item_id }))
                    }
                    isActive={selectedCategoryDish.dish === item.item_id}
                    isRearrange={pageState.reArrange}
                    type={getFoodType(item)}
                    index={index}
                    showUnlink={!!item.is_mapped_item}
                    handleUnlinkItem={handleUnlinkItem}
                    isSelectionActive={pageState.selection}
                    onSelectDish={(isSelected) => handleSelectDish(isSelected)}
                />
            </div>
        </>
    );
};
