import { ReactComponent as ADIcon } from './FlagIcons/AD.svg';
import { ReactComponent as AEIcon } from './FlagIcons/AE.svg';
import { ReactComponent as AFIcon } from './FlagIcons/AF.svg';
import { ReactComponent as AGIcon } from './FlagIcons/AG.svg';
import { ReactComponent as AIIcon } from './FlagIcons/AI.svg';
import { ReactComponent as ALIcon } from './FlagIcons/AL.svg';
import { ReactComponent as AMIcon } from './FlagIcons/AM.svg';
import { ReactComponent as AOIcon } from './FlagIcons/AO.svg';
import { ReactComponent as AQIcon } from './FlagIcons/AQ.svg';
import { ReactComponent as ARIcon } from './FlagIcons/AR.svg';
import { ReactComponent as ASIcon } from './FlagIcons/AS.svg';
import { ReactComponent as ATIcon } from './FlagIcons/AT.svg';
import { ReactComponent as AUIcon } from './FlagIcons/AU.svg';
import { ReactComponent as AWIcon } from './FlagIcons/AW.svg';
import { ReactComponent as AZIcon } from './FlagIcons/AZ.svg';
import { ReactComponent as BAIcon } from './FlagIcons/BA.svg';
import { ReactComponent as BBIcon } from './FlagIcons/BB.svg';
import { ReactComponent as BDIcon } from './FlagIcons/BD.svg';
import { ReactComponent as BEIcon } from './FlagIcons/BE.svg';
import { ReactComponent as BFIcon } from './FlagIcons/BF.svg';
import { ReactComponent as BGIcon } from './FlagIcons/BG.svg';
import { ReactComponent as BHIcon } from './FlagIcons/BH.svg';
import { ReactComponent as BIIcon } from './FlagIcons/BI.svg';
import { ReactComponent as BJIcon } from './FlagIcons/BJ.svg';
import { ReactComponent as BLIcon } from './FlagIcons/BL.svg';
import { ReactComponent as BMIcon } from './FlagIcons/BM.svg';
import { ReactComponent as BNIcon } from './FlagIcons/BN.svg';
import { ReactComponent as BOIcon } from './FlagIcons/BO.svg';
import { ReactComponent as BRIcon } from './FlagIcons/BR.svg';
import { ReactComponent as BSIcon } from './FlagIcons/BS.svg';
import { ReactComponent as BTIcon } from './FlagIcons/BT.svg';
import { ReactComponent as BWIcon } from './FlagIcons/BW.svg';
import { ReactComponent as BYIcon } from './FlagIcons/BY.svg';
import { ReactComponent as BZIcon } from './FlagIcons/BZ.svg';
import { ReactComponent as CAIcon } from './FlagIcons/CA.svg';
import { ReactComponent as CCIcon } from './FlagIcons/CC.svg';
import { ReactComponent as CDIcon } from './FlagIcons/CD.svg';
import { ReactComponent as CFIcon } from './FlagIcons/CF.svg';
import { ReactComponent as CGIcon } from './FlagIcons/CG.svg';
import { ReactComponent as CHIcon } from './FlagIcons/CH.svg';
import { ReactComponent as CIIcon } from './FlagIcons/CI.svg';
import { ReactComponent as CKIcon } from './FlagIcons/CK.svg';
import { ReactComponent as CLIcon } from './FlagIcons/CL.svg';
import { ReactComponent as CMIcon } from './FlagIcons/CM.svg';
import { ReactComponent as CNIcon } from './FlagIcons/CN.svg';
import { ReactComponent as COIcon } from './FlagIcons/CO.svg';
import { ReactComponent as CRIcon } from './FlagIcons/CR.svg';
import { ReactComponent as CUIcon } from './FlagIcons/CU.svg';
import { ReactComponent as CVIcon } from './FlagIcons/CV.svg';
import { ReactComponent as CXIcon } from './FlagIcons/CX.svg';
import { ReactComponent as CYIcon } from './FlagIcons/CY.svg';
import { ReactComponent as CZIcon } from './FlagIcons/CZ.svg';
import { ReactComponent as DEIcon } from './FlagIcons/DE.svg';
import { ReactComponent as DJIcon } from './FlagIcons/DJ.svg';
import { ReactComponent as DKIcon } from './FlagIcons/DK.svg';
import { ReactComponent as DMIcon } from './FlagIcons/DM.svg';
import { ReactComponent as DOIcon } from './FlagIcons/DO.svg';
import { ReactComponent as DZIcon } from './FlagIcons/DZ.svg';
import { ReactComponent as ECIcon } from './FlagIcons/EC.svg';
import { ReactComponent as EEIcon } from './FlagIcons/EE.svg';
import { ReactComponent as EGIcon } from './FlagIcons/EG.svg';
import { ReactComponent as ERIcon } from './FlagIcons/ER.svg';
import { ReactComponent as ESIcon } from './FlagIcons/ES.svg';
import { ReactComponent as ETIcon } from './FlagIcons/ET.svg';
import { ReactComponent as FIIcon } from './FlagIcons/FI.svg';
import { ReactComponent as FJIcon } from './FlagIcons/FJ.svg';
import { ReactComponent as FKIcon } from './FlagIcons/FK.svg';
import { ReactComponent as FMIcon } from './FlagIcons/FM.svg';
import { ReactComponent as FOIcon } from './FlagIcons/FO.svg';
import { ReactComponent as FRIcon } from './FlagIcons/FR.svg';
import { ReactComponent as GAIcon } from './FlagIcons/GA.svg';
import { ReactComponent as GBIcon } from './FlagIcons/GB.svg';
import { ReactComponent as GDIcon } from './FlagIcons/GD.svg';
import { ReactComponent as GEIcon } from './FlagIcons/GE.svg';
import { ReactComponent as GHIcon } from './FlagIcons/GH.svg';
import { ReactComponent as GIIcon } from './FlagIcons/GI.svg';
import { ReactComponent as GLIcon } from './FlagIcons/GL.svg';
import { ReactComponent as GMIcon } from './FlagIcons/GM.svg';
import { ReactComponent as GNIcon } from './FlagIcons/GN.svg';
import { ReactComponent as GQIcon } from './FlagIcons/GQ.svg';
import { ReactComponent as GRIcon } from './FlagIcons/GR.svg';
import { ReactComponent as GTIcon } from './FlagIcons/GT.svg';
import { ReactComponent as GUIcon } from './FlagIcons/GU.svg';
import { ReactComponent as GWIcon } from './FlagIcons/GW.svg';
import { ReactComponent as GYIcon } from './FlagIcons/GY.svg';
import { ReactComponent as HKIcon } from './FlagIcons/HK.svg';
import { ReactComponent as HNIcon } from './FlagIcons/HN.svg';
import { ReactComponent as HRIcon } from './FlagIcons/HR.svg';
import { ReactComponent as HTIcon } from './FlagIcons/HT.svg';
import { ReactComponent as HUIcon } from './FlagIcons/HU.svg';
import { ReactComponent as IDIcon } from './FlagIcons/ID.svg';
import { ReactComponent as IEIcon } from './FlagIcons/IE.svg';
import { ReactComponent as ILIcon } from './FlagIcons/IL.svg';
import { ReactComponent as IMIcon } from './FlagIcons/IM.svg';
import { ReactComponent as INIcon } from './FlagIcons/IN.svg';
import { ReactComponent as IQIcon } from './FlagIcons/IQ.svg';
import { ReactComponent as IRIcon } from './FlagIcons/IR.svg';
import { ReactComponent as ISIcon } from './FlagIcons/IS.svg';
import { ReactComponent as ITIcon } from './FlagIcons/IT.svg';
import { ReactComponent as JMIcon } from './FlagIcons/JM.svg';
import { ReactComponent as JOIcon } from './FlagIcons/JO.svg';
import { ReactComponent as JPIcon } from './FlagIcons/JP.svg';
import { ReactComponent as KEIcon } from './FlagIcons/KE.svg';
import { ReactComponent as KGIcon } from './FlagIcons/KG.svg';
import { ReactComponent as KHIcon } from './FlagIcons/KH.svg';
import { ReactComponent as KIIcon } from './FlagIcons/KI.svg';
import { ReactComponent as KMIcon } from './FlagIcons/KM.svg';
import { ReactComponent as KNIcon } from './FlagIcons/KN.svg';
import { ReactComponent as KPIcon } from './FlagIcons/KP.svg';
import { ReactComponent as KRIcon } from './FlagIcons/KR.svg';
import { ReactComponent as KWIcon } from './FlagIcons/KW.svg';
import { ReactComponent as KYIcon } from './FlagIcons/KY.svg';
import { ReactComponent as KZIcon } from './FlagIcons/KZ.svg';
import { ReactComponent as LAIcon } from './FlagIcons/LA.svg';
import { ReactComponent as LBIcon } from './FlagIcons/LB.svg';
import { ReactComponent as LCIcon } from './FlagIcons/LC.svg';
import { ReactComponent as LIIcon } from './FlagIcons/LI.svg';
import { ReactComponent as LKIcon } from './FlagIcons/LK.svg';
import { ReactComponent as LRIcon } from './FlagIcons/LR.svg';
import { ReactComponent as LSIcon } from './FlagIcons/LS.svg';
import { ReactComponent as LTIcon } from './FlagIcons/LT.svg';
import { ReactComponent as LUIcon } from './FlagIcons/LU.svg';
import { ReactComponent as LVIcon } from './FlagIcons/LV.svg';
import { ReactComponent as LYIcon } from './FlagIcons/LY.svg';
import { ReactComponent as MAIcon } from './FlagIcons/MA.svg';
import { ReactComponent as MCIcon } from './FlagIcons/MC.svg';
import { ReactComponent as MDIcon } from './FlagIcons/MD.svg';
import { ReactComponent as MEIcon } from './FlagIcons/ME.svg';
import { ReactComponent as MFIcon } from './FlagIcons/MF.svg';
import { ReactComponent as MGIcon } from './FlagIcons/MG.svg';
import { ReactComponent as MHIcon } from './FlagIcons/MH.svg';
import { ReactComponent as MKIcon } from './FlagIcons/MK.svg';
import { ReactComponent as MLIcon } from './FlagIcons/ML.svg';
import { ReactComponent as MMIcon } from './FlagIcons/MM.svg';
import { ReactComponent as MNIcon } from './FlagIcons/MN.svg';
import { ReactComponent as MOIcon } from './FlagIcons/MO.svg';
import { ReactComponent as MPIcon } from './FlagIcons/MP.svg';
import { ReactComponent as MRIcon } from './FlagIcons/MR.svg';
import { ReactComponent as MSIcon } from './FlagIcons/MS.svg';
import { ReactComponent as MTIcon } from './FlagIcons/MT.svg';
import { ReactComponent as MUIcon } from './FlagIcons/MU.svg';
import { ReactComponent as MVIcon } from './FlagIcons/MV.svg';
import { ReactComponent as MWIcon } from './FlagIcons/MW.svg';
import { ReactComponent as MXIcon } from './FlagIcons/MX.svg';
import { ReactComponent as MYIcon } from './FlagIcons/MY.svg';
import { ReactComponent as MZIcon } from './FlagIcons/MZ.svg';
import { ReactComponent as NAIcon } from './FlagIcons/NA.svg';
import { ReactComponent as NCIcon } from './FlagIcons/NC.svg';
import { ReactComponent as NEIcon } from './FlagIcons/NE.svg';
import { ReactComponent as NGIcon } from './FlagIcons/NG.svg';
import { ReactComponent as NIIcon } from './FlagIcons/NI.svg';
import { ReactComponent as NLIcon } from './FlagIcons/NL.svg';
import { ReactComponent as NOIcon } from './FlagIcons/NO.svg';
import { ReactComponent as NPIcon } from './FlagIcons/NP.svg';
import { ReactComponent as NRIcon } from './FlagIcons/NR.svg';
import { ReactComponent as NUIcon } from './FlagIcons/NU.svg';
import { ReactComponent as NZIcon } from './FlagIcons/NZ.svg';
import { ReactComponent as OMIcon } from './FlagIcons/OM.svg';
import { ReactComponent as PAIcon } from './FlagIcons/PA.svg';
import { ReactComponent as PEIcon } from './FlagIcons/PE.svg';
import { ReactComponent as PFIcon } from './FlagIcons/PF.svg';
import { ReactComponent as PGIcon } from './FlagIcons/PG.svg';
import { ReactComponent as PHIcon } from './FlagIcons/PH.svg';
import { ReactComponent as PKIcon } from './FlagIcons/PK.svg';
import { ReactComponent as PLIcon } from './FlagIcons/PL.svg';
import { ReactComponent as PMIcon } from './FlagIcons/PM.svg';
import { ReactComponent as PNIcon } from './FlagIcons/PN.svg';
import { ReactComponent as PRIcon } from './FlagIcons/PR.svg';
import { ReactComponent as PTIcon } from './FlagIcons/PT.svg';
import { ReactComponent as PWIcon } from './FlagIcons/PW.svg';
import { ReactComponent as PYIcon } from './FlagIcons/PY.svg';
import { ReactComponent as QAIcon } from './FlagIcons/QA.svg';
import { ReactComponent as ROIcon } from './FlagIcons/RO.svg';
import { ReactComponent as RSIcon } from './FlagIcons/RS.svg';
import { ReactComponent as RUIcon } from './FlagIcons/RU.svg';
import { ReactComponent as RWIcon } from './FlagIcons/RW.svg';
import { ReactComponent as SAIcon } from './FlagIcons/SA.svg';
import { ReactComponent as SBIcon } from './FlagIcons/SB.svg';
import { ReactComponent as SCIcon } from './FlagIcons/SC.svg';
import { ReactComponent as SDIcon } from './FlagIcons/SD.svg';
import { ReactComponent as SEIcon } from './FlagIcons/SE.svg';
import { ReactComponent as SGIcon } from './FlagIcons/SG.svg';
import { ReactComponent as SHIcon } from './FlagIcons/SH.svg';
import { ReactComponent as SIIcon } from './FlagIcons/SI.svg';
import { ReactComponent as SKIcon } from './FlagIcons/SK.svg';
import { ReactComponent as SLIcon } from './FlagIcons/SL.svg';
import { ReactComponent as SMIcon } from './FlagIcons/SM.svg';
import { ReactComponent as SNIcon } from './FlagIcons/SN.svg';
import { ReactComponent as SOIcon } from './FlagIcons/SO.svg';
import { ReactComponent as SRIcon } from './FlagIcons/SR.svg';
import { ReactComponent as STIcon } from './FlagIcons/ST.svg';
import { ReactComponent as SVIcon } from './FlagIcons/SV.svg';
import { ReactComponent as SYIcon } from './FlagIcons/SY.svg';
import { ReactComponent as SZIcon } from './FlagIcons/SZ.svg';
import { ReactComponent as TCIcon } from './FlagIcons/TC.svg';
import { ReactComponent as TDIcon } from './FlagIcons/TD.svg';
import { ReactComponent as TGIcon } from './FlagIcons/TG.svg';
import { ReactComponent as THIcon } from './FlagIcons/TH.svg';
import { ReactComponent as TJIcon } from './FlagIcons/TJ.svg';
import { ReactComponent as TKIcon } from './FlagIcons/TK.svg';
import { ReactComponent as TLIcon } from './FlagIcons/TL.svg';
import { ReactComponent as TMIcon } from './FlagIcons/TM.svg';
import { ReactComponent as TNIcon } from './FlagIcons/TN.svg';
import { ReactComponent as TOIcon } from './FlagIcons/TO.svg';
import { ReactComponent as TRIcon } from './FlagIcons/TR.svg';
import { ReactComponent as TTIcon } from './FlagIcons/TT.svg';
import { ReactComponent as TVIcon } from './FlagIcons/TV.svg';
import { ReactComponent as TWIcon } from './FlagIcons/TW.svg';
import { ReactComponent as TZIcon } from './FlagIcons/TZ.svg';
import { ReactComponent as UAIcon } from './FlagIcons/UA.svg';
import { ReactComponent as UGIcon } from './FlagIcons/UG.svg';
import { ReactComponent as USIcon } from './FlagIcons/US.svg';
import { ReactComponent as UYIcon } from './FlagIcons/UY.svg';
import { ReactComponent as UZIcon } from './FlagIcons/UZ.svg';
import { ReactComponent as VAIcon } from './FlagIcons/VA.svg';
import { ReactComponent as VCIcon } from './FlagIcons/VC.svg';
import { ReactComponent as VEIcon } from './FlagIcons/VE.svg';
import { ReactComponent as VGIcon } from './FlagIcons/VG.svg';
import { ReactComponent as VIIcon } from './FlagIcons/VI.svg';
import { ReactComponent as VNIcon } from './FlagIcons/VN.svg';
import { ReactComponent as VUIcon } from './FlagIcons/VU.svg';
import { ReactComponent as WFIcon } from './FlagIcons/WF.svg';
import { ReactComponent as WSIcon } from './FlagIcons/WS.svg';
import { ReactComponent as XKIcon } from './FlagIcons/XK.svg';
import { ReactComponent as YEIcon } from './FlagIcons/YE.svg';
import { ReactComponent as YTIcon } from './FlagIcons/YT.svg';
import { ReactComponent as ZAIcon } from './FlagIcons/ZA.svg';
import { ReactComponent as ZMIcon } from './FlagIcons/ZM.svg';
import { ReactComponent as ZWIcon } from './FlagIcons/ZW.svg';

export const countryCodes = [
    {
        shortName: 'AD',
        fullName: 'ANDORRA',
        code: '376',
        flag: (
            <ADIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AE',
        fullName: 'UNITED ARAB EMIRATES',
        code: '971',
        flag: (
            <AEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AF',
        fullName: 'AFGHANISTAN',
        code: '93',
        flag: (
            <AFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AG',
        fullName: 'ANTIGUA AND BARBUDA',
        code: '1268',
        flag: (
            <AGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AI',
        fullName: 'ANGUILLA',
        code: '1264',
        flag: (
            <AIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AL',
        fullName: 'ALBANIA',
        code: '355',
        flag: (
            <ALIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AM',
        fullName: 'ARMENIA',
        code: '374',
        flag: (
            <AMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AO',
        fullName: 'ANGOLA',
        code: '244',
        flag: (
            <AOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AQ',
        fullName: 'ANTARCTICA',
        code: '672',
        flag: (
            <AQIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AR',
        fullName: 'ARGENTINA',
        code: '54',
        flag: (
            <ARIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AS',
        fullName: 'AMERICAN SAMOA',
        code: '1684',
        flag: (
            <ASIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AT',
        fullName: 'AUSTRIA',
        code: '43',
        flag: (
            <ATIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AU',
        fullName: 'AUSTRALIA',
        code: '61',
        flag: (
            <AUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AW',
        fullName: 'ARUBA',
        code: '297',
        flag: (
            <AWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'AZ',
        fullName: 'AZERBAIJAN',
        code: '994',
        flag: (
            <AZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BA',
        fullName: 'BOSNIA AND HERZEGOVINA',
        code: '387',
        flag: (
            <BAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BB',
        fullName: 'BARBADOS',
        code: '1246',
        flag: (
            <BBIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BD',
        fullName: 'BANGLADESH',
        code: '880',
        flag: (
            <BDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BE',
        fullName: 'BELGIUM',
        code: '32',
        flag: (
            <BEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BF',
        fullName: 'BURKINA FASO',
        code: '226',
        flag: (
            <BFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BG',
        fullName: 'BULGARIA',
        code: '359',
        flag: (
            <BGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BH',
        fullName: 'BAHRAIN',
        code: '973',
        flag: (
            <BHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BI',
        fullName: 'BURUNDI',
        code: '257',
        flag: (
            <BIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BJ',
        fullName: 'BENIN',
        code: '229',
        flag: (
            <BJIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BL',
        fullName: 'SAINT BARTHELEMY',
        code: '590',
        flag: (
            <BLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BM',
        fullName: 'BERMUDA',
        code: '1441',
        flag: (
            <BMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BN',
        fullName: 'BRUNEI DARUSSALAM',
        code: '673',
        flag: (
            <BNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BO',
        fullName: 'BOLIVIA',
        code: '591',
        flag: (
            <BOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BR',
        fullName: 'BRAZIL',
        code: '55',
        flag: (
            <BRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BS',
        fullName: 'BAHAMAS',
        code: '1242',
        flag: (
            <BSIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BT',
        fullName: 'BHUTAN',
        code: '975',
        flag: (
            <BTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BW',
        fullName: 'BOTSWANA',
        code: '267',
        flag: (
            <BWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BY',
        fullName: 'BELARUS',
        code: '375',
        flag: (
            <BYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'BZ',
        fullName: 'BELIZE',
        code: '501',
        flag: (
            <BZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CA',
        fullName: 'CANADA',
        code: '1',
        flag: (
            <CAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CC',
        fullName: 'COCOS (KEELING) ISLANDS',
        code: '61',
        flag: (
            <CCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CD',
        fullName: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
        code: '243',
        flag: (
            <CDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CF',
        fullName: 'CENTRAL AFRICAN REPUBLIC',
        code: '236',
        flag: (
            <CFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CG',
        fullName: 'CONGO',
        code: '242',
        flag: (
            <CGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CH',
        fullName: 'SWITZERLAND',
        code: '41',
        flag: (
            <CHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CI',
        fullName: 'COTE D IVOIRE',
        code: '225',
        flag: (
            <CIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CK',
        fullName: 'COOK ISLANDS',
        code: '682',
        flag: (
            <CKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CL',
        fullName: 'CHILE',
        code: '56',
        flag: (
            <CLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CM',
        fullName: 'CAMEROON',
        code: '237',
        flag: (
            <CMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CN',
        fullName: 'CHINA',
        code: '86',
        flag: (
            <CNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CO',
        fullName: 'COLOMBIA',
        code: '57',
        flag: (
            <COIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CR',
        fullName: 'COSTA RICA',
        code: '506',
        flag: (
            <CRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CU',
        fullName: 'CUBA',
        code: '53',
        flag: (
            <CUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CV',
        fullName: 'CAPE VERDE',
        code: '238',
        flag: (
            <CVIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CX',
        fullName: 'CHRISTMAS ISLAND',
        code: '61',
        flag: (
            <CXIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CY',
        fullName: 'CYPRUS',
        code: '357',
        flag: (
            <CYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'CZ',
        fullName: 'CZECH REPUBLIC',
        code: '420',
        flag: (
            <CZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DE',
        fullName: 'GERMANY',
        code: '49',
        flag: (
            <DEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DJ',
        fullName: 'DJIBOUTI',
        code: '253',
        flag: (
            <DJIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DK',
        fullName: 'DENMARK',
        code: '45',
        flag: (
            <DKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DM',
        fullName: 'DOMINICA',
        code: '1767',
        flag: (
            <DMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DO',
        fullName: 'DOMINICAN REPUBLIC',
        code: '1809',
        flag: (
            <DOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'DZ',
        fullName: 'ALGERIA',
        code: '213',
        flag: (
            <DZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'EC',
        fullName: 'ECUADOR',
        code: '593',
        flag: (
            <ECIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'EE',
        fullName: 'ESTONIA',
        code: '372',
        flag: (
            <EEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'EG',
        fullName: 'EGYPT',
        code: '20',
        flag: (
            <EGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ER',
        fullName: 'ERITREA',
        code: '291',
        flag: (
            <ERIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ES',
        fullName: 'SPAIN',
        code: '34',
        flag: (
            <ESIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ET',
        fullName: 'ETHIOPIA',
        code: '251',
        flag: (
            <ETIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FI',
        fullName: 'FINLAND',
        code: '358',
        flag: (
            <FIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FJ',
        fullName: 'FIJI',
        code: '679',
        flag: (
            <FJIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FK',
        fullName: 'FALKLAND ISLANDS (MALVINAS)',
        code: '500',
        flag: (
            <FKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FM',
        fullName: 'MICRONESIA, FEDERATED STATES OF',
        code: '691',
        flag: (
            <FMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FO',
        fullName: 'FAROE ISLANDS',
        code: '298',
        flag: (
            <FOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'FR',
        fullName: 'FRANCE',
        code: '33',
        flag: (
            <FRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GA',
        fullName: 'GABON',
        code: '241',
        flag: (
            <GAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GB',
        fullName: 'UNITED KINGDOM',
        code: '44',
        flag: (
            <GBIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GD',
        fullName: 'GRENADA',
        code: '1473',
        flag: (
            <GDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GE',
        fullName: 'GEORGIA',
        code: '995',
        flag: (
            <GEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GH',
        fullName: 'GHANA',
        code: '233',
        flag: (
            <GHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GI',
        fullName: 'GIBRALTAR',
        code: '350',
        flag: (
            <GIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GL',
        fullName: 'GREENLAND',
        code: '299',
        flag: (
            <GLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GM',
        fullName: 'GAMBIA',
        code: '220',
        flag: (
            <GMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GN',
        fullName: 'GUINEA',
        code: '224',
        flag: (
            <GNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GQ',
        fullName: 'EQUATORIAL GUINEA',
        code: '240',
        flag: (
            <GQIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GR',
        fullName: 'GREECE',
        code: '30',
        flag: (
            <GRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GT',
        fullName: 'GUATEMALA',
        code: '502',
        flag: (
            <GTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GU',
        fullName: 'GUAM',
        code: '1671',
        flag: (
            <GUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GW',
        fullName: 'GUINEA-BISSAU',
        code: '245',
        flag: (
            <GWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'GY',
        fullName: 'GUYANA',
        code: '592',
        flag: (
            <GYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'HK',
        fullName: 'HONG KONG',
        code: '852',
        flag: (
            <HKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'HN',
        fullName: 'HONDURAS',
        code: '504',
        flag: (
            <HNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'HR',
        fullName: 'CROATIA',
        code: '385',
        flag: (
            <HRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'HT',
        fullName: 'HAITI',
        code: '509',
        flag: (
            <HTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'HU',
        fullName: 'HUNGARY',
        code: '36',
        flag: (
            <HUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ID',
        fullName: 'INDONESIA',
        code: '62',
        flag: (
            <IDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IE',
        fullName: 'IRELAND',
        code: '353',
        flag: (
            <IEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IL',
        fullName: 'ISRAEL',
        code: '972',
        flag: (
            <ILIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IM',
        fullName: 'ISLE OF MAN',
        code: '44',
        flag: (
            <IMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IN',
        fullName: 'INDIA',
        code: '91',
        flag: (
            <INIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IQ',
        fullName: 'IRAQ',
        code: '964',
        flag: (
            <IQIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IR',
        fullName: 'IRAN, ISLAMIC REPUBLIC OF',
        code: '98',
        flag: (
            <IRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IS',
        fullName: 'ICELAND',
        code: '354',
        flag: (
            <ISIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'IT',
        fullName: 'ITALY',
        code: '39',
        flag: (
            <ITIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'JM',
        fullName: 'JAMAICA',
        code: '1876',
        flag: (
            <JMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'JO',
        fullName: 'JORDAN',
        code: '962',
        flag: (
            <JOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'JP',
        fullName: 'JAPAN',
        code: '81',
        flag: (
            <JPIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KE',
        fullName: 'KENYA',
        code: '254',
        flag: (
            <KEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KG',
        fullName: 'KYRGYZSTAN',
        code: '996',
        flag: (
            <KGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KH',
        fullName: 'CAMBODIA',
        code: '855',
        flag: (
            <KHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KI',
        fullName: 'KIRIBATI',
        code: '686',
        flag: (
            <KIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KM',
        fullName: 'COMOROS',
        code: '269',
        flag: (
            <KMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KN',
        fullName: 'SAINT KITTS AND NEVIS',
        code: '1869',
        flag: (
            <KNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KP',
        fullName: 'KOREA DEMOCRATIC PEOPLES REPUBLIC OF',
        code: '850',
        flag: (
            <KPIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KR',
        fullName: 'KOREA REPUBLIC OF',
        code: '82',
        flag: (
            <KRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KW',
        fullName: 'KUWAIT',
        code: '965',
        flag: (
            <KWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KY',
        fullName: 'CAYMAN ISLANDS',
        code: '1345',
        flag: (
            <KYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'KZ',
        fullName: 'KAZAKSTAN',
        code: '7',
        flag: (
            <KZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LA',
        fullName: 'LAO PEOPLES DEMOCRATIC REPUBLIC',
        code: '856',
        flag: (
            <LAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LB',
        fullName: 'LEBANON',
        code: '961',
        flag: (
            <LBIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LC',
        fullName: 'SAINT LUCIA',
        code: '1758',
        flag: (
            <LCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LI',
        fullName: 'LIECHTENSTEIN',
        code: '423',
        flag: (
            <LIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LK',
        fullName: 'SRI LANKA',
        code: '94',
        flag: (
            <LKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LR',
        fullName: 'LIBERIA',
        code: '231',
        flag: (
            <LRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LS',
        fullName: 'LESOTHO',
        code: '266',
        flag: (
            <LSIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LT',
        fullName: 'LITHUANIA',
        code: '370',
        flag: (
            <LTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LU',
        fullName: 'LUXEMBOURG',
        code: '352',
        flag: (
            <LUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LV',
        fullName: 'LATVIA',
        code: '371',
        flag: (
            <LVIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'LY',
        fullName: 'LIBYAN ARAB JAMAHIRIYA',
        code: '218',
        flag: (
            <LYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MA',
        fullName: 'MOROCCO',
        code: '212',
        flag: (
            <MAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MC',
        fullName: 'MONACO',
        code: '377',
        flag: (
            <MCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MD',
        fullName: 'MOLDOVA, REPUBLIC OF',
        code: '373',
        flag: (
            <MDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ME',
        fullName: 'MONTENEGRO',
        code: '382',
        flag: (
            <MEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MF',
        fullName: 'SAINT MARTIN',
        code: '1599',
        flag: (
            <MFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MG',
        fullName: 'MADAGASCAR',
        code: '261',
        flag: (
            <MGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MH',
        fullName: 'MARSHALL ISLANDS',
        code: '692',
        flag: (
            <MHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MK',
        fullName: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
        code: '389',
        flag: (
            <MKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ML',
        fullName: 'MALI',
        code: '223',
        flag: (
            <MLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MM',
        fullName: 'MYANMAR',
        code: '95',
        flag: (
            <MMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MN',
        fullName: 'MONGOLIA',
        code: '976',
        flag: (
            <MNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MO',
        fullName: 'MACAU',
        code: '853',
        flag: (
            <MOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MP',
        fullName: 'NORTHERN MARIANA ISLANDS',
        code: '1670',
        flag: (
            <MPIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MR',
        fullName: 'MAURITANIA',
        code: '222',
        flag: (
            <MRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MS',
        fullName: 'MONTSERRAT',
        code: '1664',
        flag: (
            <MSIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MT',
        fullName: 'MALTA',
        code: '356',
        flag: (
            <MTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MU',
        fullName: 'MAURITIUS',
        code: '230',
        flag: (
            <MUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MV',
        fullName: 'MALDIVES',
        code: '960',
        flag: (
            <MVIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MW',
        fullName: 'MALAWI',
        code: '265',
        flag: (
            <MWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MX',
        fullName: 'MEXICO',
        code: '52',
        flag: (
            <MXIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MY',
        fullName: 'MALAYSIA',
        code: '60',
        flag: (
            <MYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'MZ',
        fullName: 'MOZAMBIQUE',
        code: '258',
        flag: (
            <MZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NA',
        fullName: 'NAMIBIA',
        code: '264',
        flag: (
            <NAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NC',
        fullName: 'NEW CALEDONIA',
        code: '687',
        flag: (
            <NCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NE',
        fullName: 'NIGER',
        code: '227',
        flag: (
            <NEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NG',
        fullName: 'NIGERIA',
        code: '234',
        flag: (
            <NGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NI',
        fullName: 'NICARAGUA',
        code: '505',
        flag: (
            <NIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NL',
        fullName: 'NETHERLANDS',
        code: '31',
        flag: (
            <NLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NO',
        fullName: 'NORWAY',
        code: '47',
        flag: (
            <NOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NP',
        fullName: 'NEPAL',
        code: '977',
        flag: (
            <NPIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NR',
        fullName: 'NAURU',
        code: '674',
        flag: (
            <NRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NU',
        fullName: 'NIUE',
        code: '683',
        flag: (
            <NUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'NZ',
        fullName: 'NEW ZEALAND',
        code: '64',
        flag: (
            <NZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'OM',
        fullName: 'OMAN',
        code: '968',
        flag: (
            <OMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PA',
        fullName: 'PANAMA',
        code: '507',
        flag: (
            <PAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PE',
        fullName: 'PERU',
        code: '51',
        flag: (
            <PEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PF',
        fullName: 'FRENCH POLYNESIA',
        code: '689',
        flag: (
            <PFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PG',
        fullName: 'PAPUA NEW GUINEA',
        code: '675',
        flag: (
            <PGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PH',
        fullName: 'PHILIPPINES',
        code: '63',
        flag: (
            <PHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PK',
        fullName: 'PAKISTAN',
        code: '92',
        flag: (
            <PKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PL',
        fullName: 'POLAND',
        code: '48',
        flag: (
            <PLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PM',
        fullName: 'SAINT PIERRE AND MIQUELON',
        code: '508',
        flag: (
            <PMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PN',
        fullName: 'PITCAIRN',
        code: '870',
        flag: (
            <PNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PR',
        fullName: 'PUERTO RICO',
        code: '1',
        flag: (
            <PRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PT',
        fullName: 'PORTUGAL',
        code: '351',
        flag: (
            <PTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PW',
        fullName: 'PALAU',
        code: '680',
        flag: (
            <PWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'PY',
        fullName: 'PARAGUAY',
        code: '595',
        flag: (
            <PYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'QA',
        fullName: 'QATAR',
        code: '974',
        flag: (
            <QAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'RO',
        fullName: 'ROMANIA',
        code: '40',
        flag: (
            <ROIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'RS',
        fullName: 'SERBIA',
        code: '381',
        flag: (
            <RSIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'RU',
        fullName: 'RUSSIAN FEDERATION',
        code: '7',
        flag: (
            <RUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'RW',
        fullName: 'RWANDA',
        code: '250',
        flag: (
            <RWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SA',
        fullName: 'SAUDI ARABIA',
        code: '966',
        flag: (
            <SAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SB',
        fullName: 'SOLOMON ISLANDS',
        code: '677',
        flag: (
            <SBIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SC',
        fullName: 'SEYCHELLES',
        code: '248',
        flag: (
            <SCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SD',
        fullName: 'SUDAN',
        code: '249',
        flag: (
            <SDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SE',
        fullName: 'SWEDEN',
        code: '46',
        flag: (
            <SEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SG',
        fullName: 'SINGAPORE',
        code: '65',
        flag: (
            <SGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SH',
        fullName: 'SAINT HELENA',
        code: '290',
        flag: (
            <SHIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SI',
        fullName: 'SLOVENIA',
        code: '386',
        flag: (
            <SIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SK',
        fullName: 'SLOVAKIA',
        code: '421',
        flag: (
            <SKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SL',
        fullName: 'SIERRA LEONE',
        code: '232',
        flag: (
            <SLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SM',
        fullName: 'SAN MARINO',
        code: '378',
        flag: (
            <SMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SN',
        fullName: 'SENEGAL',
        code: '221',
        flag: (
            <SNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SO',
        fullName: 'SOMALIA',
        code: '252',
        flag: (
            <SOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SR',
        fullName: 'SURINAME',
        code: '597',
        flag: (
            <SRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ST',
        fullName: 'SAO TOME AND PRINCIPE',
        code: '239',
        flag: (
            <STIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SV',
        fullName: 'EL SALVADOR',
        code: '503',
        flag: (
            <SVIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SY',
        fullName: 'SYRIAN ARAB REPUBLIC',
        code: '963',
        flag: (
            <SYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'SZ',
        fullName: 'SWAZILAND',
        code: '268',
        flag: (
            <SZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TC',
        fullName: 'TURKS AND CAICOS ISLANDS',
        code: '1649',
        flag: (
            <TCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TD',
        fullName: 'CHAD',
        code: '235',
        flag: (
            <TDIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TG',
        fullName: 'TOGO',
        code: '228',
        flag: (
            <TGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TH',
        fullName: 'THAILAND',
        code: '66',
        flag: (
            <THIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TJ',
        fullName: 'TAJIKISTAN',
        code: '992',
        flag: (
            <TJIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TK',
        fullName: 'TOKELAU',
        code: '690',
        flag: (
            <TKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TL',
        fullName: 'TIMOR-LESTE',
        code: '670',
        flag: (
            <TLIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TM',
        fullName: 'TURKMENISTAN',
        code: '993',
        flag: (
            <TMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TN',
        fullName: 'TUNISIA',
        code: '216',
        flag: (
            <TNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TO',
        fullName: 'TONGA',
        code: '676',
        flag: (
            <TOIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TR',
        fullName: 'TURKEY',
        code: '90',
        flag: (
            <TRIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TT',
        fullName: 'TRINIDAD AND TOBAGO',
        code: '1868',
        flag: (
            <TTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TV',
        fullName: 'TUVALU',
        code: '688',
        flag: (
            <TVIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TW',
        fullName: 'TAIWAN, PROVINCE OF CHINA',
        code: '886',
        flag: (
            <TWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'TZ',
        fullName: 'UNITED REPUBLIC OF TANZANIA',
        code: '255',
        flag: (
            <TZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'UA',
        fullName: 'UKRAINE',
        code: '380',
        flag: (
            <UAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'UG',
        fullName: 'UGANDA',
        code: '256',
        flag: (
            <UGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'US',
        fullName: 'UNITED STATES',
        code: '1',
        flag: (
            <USIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'UY',
        fullName: 'URUGUAY',
        code: '598',
        flag: (
            <UYIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'UZ',
        fullName: 'UZBEKISTAN',
        code: '998',
        flag: (
            <UZIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VA',
        fullName: 'HOLY SEE (VATICAN CITY STATE)',
        code: '39',
        flag: (
            <VAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VC',
        fullName: 'SAINT VINCENT AND THE GRENADINES',
        code: '1784',
        flag: (
            <VCIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VE',
        fullName: 'VENEZUELA',
        code: '58',
        flag: (
            <VEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VG',
        fullName: 'VIRGIN ISLANDS, BRITISH',
        code: '1284',
        flag: (
            <VGIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VI',
        fullName: 'VIRGIN ISLANDS, U.S.',
        code: '1340',
        flag: (
            <VIIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VN',
        fullName: 'VIET NAM',
        code: '84',
        flag: (
            <VNIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'VU',
        fullName: 'VANUATU',
        code: '678',
        flag: (
            <VUIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'WF',
        fullName: 'WALLIS AND FUTUNA',
        code: '681',
        flag: (
            <WFIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'WS',
        fullName: 'SAMOA',
        code: '685',
        flag: (
            <WSIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'XK',
        fullName: 'KOSOVO',
        code: '381',
        flag: (
            <XKIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'YE',
        fullName: 'YEMEN',
        code: '967',
        flag: (
            <YEIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'YT',
        fullName: 'MAYOTTE',
        code: '262',
        flag: (
            <YTIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ZA',
        fullName: 'SOUTH AFRICA',
        code: '27',
        flag: (
            <ZAIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ZM',
        fullName: 'ZAMBIA',
        code: '260',
        flag: (
            <ZMIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
    {
        shortName: 'ZW',
        fullName: 'ZIMBABWE',
        code: '263',
        flag: (
            <ZWIcon
                width={24}
                height={24}
                className='rounded-md'
            />
        ),
    },
];

export const currencyData = [
    { currencyCode: 'AFN', countryname: 'Afghanistan', currencyName: 'Afghanistan Afghani', symbol: `&#1547;` },

    { currencyCode: 'ARS', countryname: 'Argentina', currencyName: 'Argentine Peso', symbol: '&#36;' },

    { currencyCode: 'AWG', countryname: 'Aruba', currencyName: 'Aruban florin', symbol: '&#402;' },

    { currencyCode: 'AUD', countryname: 'Australia', currencyName: 'Australian Dollar', symbol: '&#65;&#36;' },

    { currencyCode: 'AZN', countryname: 'Azerbaijan', currencyName: 'Azerbaijani Manat', symbol: '&#8380;' },

    { currencyCode: 'BSD', countryname: 'The Bahamas', currencyName: 'Bahamas Dollar', symbol: '&#66;&#36;' },

    { currencyCode: 'BBD', countryname: 'Barbados', currencyName: 'Barbados Dollar', symbol: '&#66;&#100;&#115;&#36;' },

    {
        currencyCode: 'BDT',
        countryname: "People's Republic of Bangladesh",
        currencyName: 'Bangladeshi taka',
        symbol: '&#2547;',
    },

    { currencyCode: 'BYN', countryname: 'Belarus', currencyName: 'Belarus Ruble', symbol: `&#66;&#114;` },

    { currencyCode: 'BZD', countryname: 'Belize', currencyName: 'Belize Dollar', symbol: '&#66;&#90;&#36;' },

    {
        currencyCode: 'BMD',
        countryname: 'British Overseas Territory of Bermuda',
        currencyName: 'Bermudian Dollar',
        symbol: '&#66;&#68;&#36;',
    },

    { currencyCode: 'BOP', countryname: 'Bolivia', currencyName: 'Boliviano', symbol: '&#66;&#115;' },

    {
        currencyCode: 'BAM',
        countryname: 'Bosnia and Herzegovina',
        currencyName: 'Bosnia-Herzegovina Convertible Marka',
        symbol: '&#75;&#77;',
    },

    { currencyCode: 'BWP', countryname: 'Botswana', currencyName: 'Botswana pula', symbol: '&#80;' },

    { currencyCode: 'BGN', countryname: 'Bulgaria', currencyName: 'Bulgarian lev', symbol: '&#1083;&#1074;' },

    { currencyCode: 'BRL', countryname: 'Brazil', currencyName: 'Brazilian real', symbol: '&#82;&#36;' },

    { currencyCode: 'BND', countryname: 'Sultanate of Brunei', currencyName: 'Brunei dollar', symbol: '&#66;&#36;' },

    { currencyCode: 'KHR', countryname: 'Cambodia', currencyName: 'Cambodian riel', symbol: '&#6107;' },

    { currencyCode: 'CAD', countryname: 'Canada', currencyName: 'Canadian dollar', symbol: '&#67;&#36;' },

    { currencyCode: 'KYD', countryname: 'Cayman Islands', currencyName: 'Cayman Islands dollar', symbol: '&#36;' },

    { currencyCode: 'CLP', countryname: 'Chile', currencyName: 'Chilean peso', symbol: '&#36;' },

    { currencyCode: 'CNY', countryname: 'China', currencyName: 'Chinese Yuan Renminbi', symbol: '&#165;' },

    { currencyCode: 'COP', countryname: 'Colombia', currencyName: 'Colombian peso', symbol: '&#36;' },

    { currencyCode: 'CRC', countryname: 'Costa Rica', currencyName: 'Costa Rican colón', symbol: '&#8353;' },

    { currencyCode: 'HRK', countryname: 'Croatia', currencyName: 'Croatian kuna', symbol: '&#107;&#110;' },

    { currencyCode: 'CUP', countryname: 'Cuba', currencyName: 'Cuban peso', symbol: '&#8369;' },

    { currencyCode: 'CZK', countryname: 'Czech Republic', currencyName: 'Czech koruna', symbol: '&#75;&#269;' },

    {
        currencyCode: 'DKK',
        countryname: 'Denmark, Greenland, and the Faroe Islands',
        currencyName: 'Danish krone',
        symbol: '&#107;&#114;',
    },

    {
        currencyCode: 'DOP',
        countryname: 'Dominican Republic',
        currencyName: 'Dominican peso',
        symbol: '&#82;&#68;&#36;',
    },

    {
        currencyCode: 'XCD',
        countryname: 'Antigua and Barbuda',
        currencyName: 'Eastern Caribbean dollar',
        symbol: '&#36;',
    },

    {
        currencyCode: 'XCD',
        countryname: 'Commonwealth of Dominica',
        currencyName: 'Eastern Caribbean dollar',
        symbol: '&#36;',
    },

    { currencyCode: 'XCD', currencyName: 'Eastern Caribbean dollar', countryname: 'Grenada', symbol: '&#36;' },

    { currencyCode: 'XCD', currencyName: 'Eastern Caribbean dollar', countryname: 'Montserrat', symbol: '&#36;' },

    {
        currencyCode: 'XCD',
        currencyName: 'Eastern Caribbean dollar',
        countryname: 'St. Kitts and Nevis',
        symbol: '&#36;',
    },

    {
        currencyCode: 'XCD',
        currencyName: 'Eastern Caribbean dollar',
        countryname: 'Saint Lucia and St. Vincent and the Grenadines',
        symbol: '&#36;',
    },

    { currencyCode: 'EGP', countryname: 'Egypt', currencyName: 'Egyptian pound', symbol: '&#163;' },

    { currencyCode: 'SVC', countryname: 'El Salvador', currencyName: 'Salvadoran colón', symbol: '&#36;' },

    { currencyCode: 'EEK', countryname: 'Estonia', currencyName: 'Estonian kroon', symbol: '&#75;&#114;' },

    { currencyCode: 'EUR', countryname: 'European Union', currencyName: 'Euro', symbol: '&#8364;' },

    {
        currencyCode: 'FKP',
        countryname: 'Falkland Islands',
        currencyName: 'Falkland Islands (Malvinas) Pound',
        symbol: '&#70;&#75;&#163;',
    },

    { currencyCode: 'FJD', countryname: 'Fiji', currencyName: 'Fijian dollar', symbol: '&#70;&#74;&#36;' },

    { currencyCode: 'GHC', countryname: 'Ghana', currencyName: 'Ghanaian cedi', symbol: '&#71;&#72;&#162;' },

    { currencyCode: 'GIP', countryname: 'Gibraltar', currencyName: 'Gibraltar pound', symbol: '&#163;' },

    { currencyCode: 'GTQ', countryname: 'Guatemala', currencyName: 'Guatemalan quetzal', symbol: '&#81;' },

    { currencyCode: 'GGP', countryname: 'Guernsey', currencyName: 'Guernsey pound', symbol: '&#81;' },

    { currencyCode: 'GYD', countryname: 'Guyana', currencyName: 'Guyanese dollar', symbol: '&#71;&#89;&#36;' },

    { currencyCode: 'HNL', countryname: 'Honduras', currencyName: 'Honduran lempira', symbol: '&#76;' },

    { currencyCode: 'HKD', countryname: 'Hong Kong', currencyName: 'Hong Kong dollar', symbol: '&#72;&#75;&#36;' },

    { currencyCode: 'HUF', countryname: 'Hungary', currencyName: 'Hungarian forint', symbol: '&#70;&#116;' },

    { currencyCode: 'ISK', countryname: 'Iceland', currencyName: 'Icelandic króna', symbol: '&#237;&#107;&#114;' },

    { currencyCode: 'INR', countryname: 'India', currencyName: 'Indian rupee', symbol: '&#8377;' },

    { currencyCode: 'IDR', countryname: 'Indonesia', currencyName: 'Indonesian rupiah', symbol: '&#82;&#112;' },

    { currencyCode: 'IRR', countryname: 'Iran', currencyName: 'Iranian rial', symbol: '&#65020;' },

    { currencyCode: 'IMP', countryname: 'Isle of Man', currencyName: 'Manx pound', symbol: '&#163;' },

    {
        currencyCode: 'ILS',
        countryname: 'Israel, Palestinian territories of the West Bank and the Gaza Strip',
        currencyName: 'Israeli Shekel',
        symbol: '&#8362;',
    },

    { currencyCode: 'JMD', countryname: 'Jamaica', currencyName: 'Jamaican dollar', symbol: '&#74;&#36;' },

    { currencyCode: 'JPY', countryname: 'Japan', currencyName: 'Japanese yen', symbol: '&#165;' },

    { currencyCode: 'JEP', countryname: 'Jersey', currencyName: 'Jersey pound', symbol: '&#163;' },

    { currencyCode: 'KZT', countryname: 'Kazakhstan', currencyName: 'Kazakhstani tenge', symbol: '&#8376;' },

    { currencyCode: 'KPW', countryname: 'North Korea', currencyName: 'North Korean won', symbol: '&#8361;' },

    { currencyCode: 'KPW', countryname: 'South Korea', currencyName: 'South Korean won', symbol: '&#8361;' },

    { currencyCode: 'KGS', countryname: 'Kyrgyz Republic', currencyName: 'Kyrgyzstani som', symbol: '&#1083;&#1074;' },

    { currencyCode: 'LAK', countryname: 'Laos', currencyName: 'Lao kip', symbol: '&#8365;' },

    { currencyCode: 'LAK', countryname: 'Laos', currencyName: 'Latvian lats', symbol: '&#8364;' },

    { currencyCode: 'LVL', countryname: 'Laos', currencyName: 'Latvian lats', symbol: '&#8364;' },

    { currencyCode: 'LBP', countryname: 'Lebanon', currencyName: 'Lebanese pound', symbol: '&#76;&#163;' },

    { currencyCode: 'LRD', countryname: 'Liberia', currencyName: 'Liberian dollar', symbol: '&#76;&#68;&#36;' },

    { currencyCode: 'LTL', countryname: 'Lithuania', currencyName: 'Lithuanian litas', symbol: '&#8364;' },

    {
        currencyCode: 'MKD',
        countryname: 'North Macedonia',
        currencyName: 'Macedonian denar',
        symbol: '&#1076;&#1077;&#1085;',
    },

    { currencyCode: 'MYR', countryname: 'Malaysia', currencyName: 'Malaysian ringgit', symbol: '&#82;&#77;' },

    { currencyCode: 'MUR', countryname: 'Mauritius', currencyName: 'Mauritian rupee', symbol: '&#82;&#115;' },

    { currencyCode: 'MXN', countryname: 'Mexico', currencyName: 'Mexican peso', symbol: '&#77;&#101;&#120;&#36;' },

    { currencyCode: 'MNT', countryname: 'Mongolia', currencyName: 'Mongolian tögrög', symbol: '&#8366;' },

    { currencyCode: 'MZN', countryname: 'Mozambique', currencyName: 'Mozambican metical', symbol: '&#77;&#84;' },

    { currencyCode: 'NAD', countryname: 'Namibia', currencyName: 'Namibian dollar', symbol: '&#78;&#36;' },

    {
        currencyCode: 'NPR',
        countryname: 'Federal Democratic Republic of Nepal',
        currencyName: 'Nepalese rupee',
        symbol: '&#82;&#115;&#46;',
    },

    {
        currencyCode: 'ANG',
        countryname: 'Curaçao and Sint Maarten',
        currencyName: 'Netherlands Antillean guilder',
        symbol: '&#402;',
    },

    { currencyCode: 'NZD', countryname: 'New Zealand', currencyName: 'New Zealand dollar', symbol: '&#36;' },

    { currencyCode: 'NZD', countryname: 'The Cook Islands', currencyName: 'New Zealand dollar', symbol: '&#36;' },

    { currencyCode: 'NZD', countryname: 'The Cook Islands', currencyName: 'Niue', symbol: '&#36;' },

    { currencyCode: 'NZD', countryname: 'The Ross Dependency', currencyName: 'Niue', symbol: '&#36;' },

    { currencyCode: 'NZD', countryname: 'Tokelau', currencyName: 'Niue', symbol: '&#36;' },

    { currencyCode: 'NZD', countryname: 'The Pitcairn Islands', currencyName: 'Niue', symbol: '&#36;' },

    { currencyCode: 'NIO', countryname: 'Nicaragua', currencyName: 'Nicaraguan córdoba', symbol: '&#67;&#36;' },

    { currencyCode: 'NGN', countryname: 'Nigeria', currencyName: 'Nigerian naira', symbol: '&#8358;' },

    {
        currencyCode: 'NOK',
        countryname: 'Norway and its dependent territories',
        currencyName: 'Norwegian krone',
        symbol: '&#107;&#114;',
    },

    { currencyCode: 'OMR', countryname: 'Oman', currencyName: 'Omani rial', symbol: '&#65020;' },

    { currencyCode: 'PKR', countryname: 'Pakistan', currencyName: 'Pakistani rupee', symbol: '&#82;&#115;' },

    { currencyCode: 'PAB', countryname: 'Panama', currencyName: 'Panamanian balboa', symbol: '&#66;&#47;&#46;' },

    { currencyCode: 'PYG', countryname: 'Paraguay', currencyName: 'Paraguayan Guaraní', symbol: '&#8370;' },

    { currencyCode: 'PEN', countryname: 'Peru', currencyName: 'Sol', symbol: '&#83;&#47;&#46;' },

    { currencyCode: 'PHP', countryname: 'Philippines', currencyName: 'Philippine peso', symbol: '&#8369;' },

    { currencyCode: 'PLN', countryname: 'Poland', currencyName: 'Polish złoty', symbol: '&#122;&#322;' },

    { currencyCode: 'QAR', countryname: 'State of Qatar', currencyName: 'Qatari Riyal', symbol: '&#65020;' },

    { currencyCode: 'RON', countryname: 'Romania', currencyName: 'Romanian leu (Leu românesc)', symbol: '&#76;' },

    {
        currencyCode: 'RUB',
        countryname: 'Russian Federation, Abkhazia and South Ossetia, Donetsk and Luhansk',
        currencyName: 'Russian ruble',
        symbol: '&#8381;',
    },

    {
        currencyCode: 'SHP',
        countryname: 'Saint Helena, Ascension and Tristan da Cunha',
        currencyName: 'Saint Helena pound',
        symbol: '&#163;',
    },

    { currencyCode: 'SAR', countryname: 'Saudi Arabia', currencyName: 'Saudi riyal', symbol: '&#65020;' },

    { currencyCode: 'RSD', countryname: 'Serbia', currencyName: 'Serbian dinar', symbol: '&#100;&#105;&#110;' },

    { currencyCode: 'SCR', countryname: 'Seychelles', currencyName: 'Seychellois rupee', symbol: '&#82;&#115;' },

    { currencyCode: 'SGD', countryname: 'Singapore', currencyName: 'Singapore dollar', symbol: '&#83;&#36;' },

    {
        currencyCode: 'SBD',
        countryname: 'Solomon Islands',
        currencyName: 'Solomon Islands dollar',
        symbol: '&#83;&#73;&#36;',
    },

    {
        currencyCode: 'SOS',
        countryname: 'Somalia',
        currencyName: 'Somali shilling',
        symbol: '&#83;&#104;&#46;&#83;&#111;',
    },

    { currencyCode: 'ZAR', countryname: 'South Africa', currencyName: 'South African rand', symbol: '&#82;' },

    { currencyCode: 'LKR', countryname: 'Sri Lanka', currencyName: 'Sri Lankan rupee', symbol: '&#82;&#115;' },

    { currencyCode: 'SEK', countryname: 'Sweden', currencyName: 'Swedish krona', symbol: '&#107;&#114;' },

    { currencyCode: 'CHF', countryname: 'Switzerland', currencyName: 'Swiss franc', symbol: '&#67;&#72;&#102;' },

    { currencyCode: 'SRD', countryname: 'Suriname', currencyName: 'Suriname Dollar', symbol: '&#83;&#114;&#36;' },

    { currencyCode: 'SYP', countryname: 'Syria', currencyName: 'Syrian pound', symbol: '&#163;&#83;' },

    { currencyCode: 'TWD', countryname: 'Taiwan', currencyName: 'New Taiwan dollar', symbol: '&#78;&#84;&#36;' },

    { currencyCode: 'THB', countryname: 'Thailand', currencyName: 'Thai baht', symbol: '&#3647;' },

    {
        currencyCode: 'TTD',
        countryname: 'Trinidad and Tobago',
        currencyName: 'Trinidad and Tobago dollar',
        symbol: '&#84;&#84;&#36;',
    },

    {
        currencyCode: 'TRY',
        countryname: 'Turkey, Turkish Republic of Northern Cyprus',
        currencyName: 'Turkey Lira',
        symbol: '&#8378;',
    },

    { currencyCode: 'TVD', countryname: 'Tuvalu', currencyName: 'Tuvaluan dollar', symbol: '&#84;&#86;&#36;' },

    { currencyCode: 'UAH', countryname: 'Ukraine', currencyName: 'Ukrainian hryvnia', symbol: '&#8372;' },

    { currencyCode: 'GBP', countryname: 'United Kingdom', currencyName: 'Pound sterling', symbol: '&#163;' },

    { currencyCode: 'GBP', countryname: 'Jersey', currencyName: 'Pound sterling', symbol: '&#163;' },

    { currencyCode: 'GBP', countryname: 'Guernsey', currencyName: 'Pound sterling', symbol: '&#163;' },

    { currencyCode: 'GBP', countryname: 'Guernsey', currencyName: 'The Isle of Man', symbol: '&#163;' },

    { currencyCode: 'GBP', countryname: 'Guernsey', currencyName: 'Gibraltar', symbol: '&#163;' },

    {
        currencyCode: 'GBP',
        countryname: 'Guernsey',
        currencyName: 'South Georgia and the South Sandwich Islands',
        symbol: '&#163;',
    },

    { currencyCode: 'GBP', countryname: 'Guernsey', currencyName: 'The British Antarctic Territory', symbol: '&#163;' },

    { currencyCode: 'GBP', countryname: 'Guernsey', currencyName: 'Tristan da Cunha', symbol: '&#163;' },

    { currencyCode: 'UGX', countryname: 'Uganda', currencyName: 'Ugandan shilling', symbol: '&#85;&#83;&#104;' },

    { currencyCode: 'USD', countryname: 'United States', currencyName: 'United States dollar', symbol: '&#36;' },

    { currencyCode: 'UYU', countryname: 'Uruguayan', currencyName: 'Peso Uruguayolar', symbol: '&#36;&#85;' },

    { currencyCode: 'UZS', countryname: 'Uzbekistan', currencyName: "Uzbekistani so'm", symbol: '&#1083;&#1074;' },

    { currencyCode: 'VEF', countryname: 'Venezuela', currencyName: 'Venezuelan bolívar', symbol: '&#66;&#115;' },

    { currencyCode: 'VND', countryname: 'Vietnam', currencyName: 'Vietnamese dong (Đồng)', symbol: '&#8363;' },

    { currencyCode: 'VND', countryname: 'Yemen', currencyName: 'Yemeni rial', symbol: '&#65020;' },

    { currencyCode: 'ZWD', countryname: 'Zimbabwe', currencyName: 'Zimbabwean dollar', symbol: '&#90;&#36;' },
];
