import React, { useRef, useState } from 'react';
import { ReactComponent as DownArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as AddIcon } from '../../../Assets/add.svg';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import { useNavigate } from 'react-router-dom';

export default function NewOrder(props) {
    const { className, setShowDeliveryPopup, setShowTakeAwayPopup } = props;

    const [showMode, setShowMode] = useState(false);

    const orderingMode = [
        {
            label: 'Delivery',
            value: 'delivery',
            onClick: () => {
                setShowDeliveryPopup(true);
            },
        },
        {
            label: 'Takeaway',
            value: 'takeaway',
            onClick: () => {
                setShowTakeAwayPopup(true);
            },
        },
    ];

    const modesOption = useRef(null);

    useOutsideClick(modesOption, () => setShowMode((prevState) => !prevState));

    const handleClickMode = (mode) => {
        mode.onClick();
        setShowMode((prevState) => !prevState);
    };

    const navigate = useNavigate();

    return (
        <>
            <div className={`relative ${className}`}>
                <div className={`flex flex-row`}>
                    <button
                        className='bg-primary-500 text-white px-4 py-2 rounded-sm cursor-pointer flex flex-row items-center'
                        onClick={() => navigate('/pos')}>
                        <AddIcon
                            height={18}
                            className='mr-[3px]'
                        />
                        <span className='lg:hidden'>New order</span>
                    </button>

                    <div
                        className='bg-primary-500 h-9 rounded-sm ml-0.5 justify-center items-center flex px-1 cursor-pointer'
                        onClick={() => setShowMode((prevState) => !prevState)}>
                        <DownArrowIcon
                            fill='#FFFFFF'
                            height={16}
                        />
                    </div>
                </div>

                {showMode && (
                    <div
                        className='absolute border border-neutral-300 bg-white right-3 mt-[5px] shadow-medium px-4 py-1 rounded-lg'
                        ref={modesOption}>
                        {orderingMode?.map((el, index) => {
                            return (
                                <div
                                    className='py-1 cursor-pointer'
                                    key={index}
                                    onClick={() => handleClickMode(el)}>
                                    <span>{el.label}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
}
