import React from 'react';
import { useFormContext } from 'react-hook-form';
import Layout from '../Components/Layout';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import DropdownSection from '../../../Components/DropdownSection';
import InputSection from '../../../Components/InputSection';
import { useSelector } from 'react-redux';
import APIV5 from '../../../../../api/axios/APIV5';
import { ErrorMessage } from '../../../../../Components/Error/ErrorMessage';
import Loader from './Components/Loader';

export default function ThirdPartyPOS(props) {
    const { handleDiscardForm, fetchSetting, setShowTabChangeWarningPopup } = props;

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors, isDirty },
    } = useFormContext();

    const petPoojaFields = [
        {
            label: '(App key)',
            placeholder: 'Enter app key',
            id: 'third_party_pos.petpooja.app_key',
            rule: { required: 'Please enter app key' },
        },
        {
            label: '(App secret)',
            placeholder: 'Enter app secret',
            id: 'third_party_pos.petpooja.app_secret',
            rule: { required: 'Please enter app secret' },
        },
        {
            label: '(Access token)',
            placeholder: 'Enter access token',
            id: 'third_party_pos.petpooja.access_token',
            rule: { required: 'Please enter access token' },
        },
        {
            label: '(Restaurant ID)',
            placeholder: 'Enter restaurant ID',
            id: 'third_party_pos.petpooja.restaurant_id',
            rule: { required: 'Please enter restaurant ID' },
        },
    ];

    const billberryFields = [
        {
            label: '(API key)',
            placeholder: 'Enter api key',
            id: 'third_party_pos.billberry.billberry_api_key',
            rule: { required: 'Please enter api key' },
        },
        {
            label: '(Store ID)',
            placeholder: 'Enter store ID',
            id: 'third_party_pos.billberry.billberry_store_id',
            rule: { required: 'Please enter store ID' },
        },
    ];

    const formatData = (data) => {
        return {
            petpooja: {
                app_key: data.petpooja.app_key,
                is_pet_pooja_integration_enabled: data.enablePetPoojaIntegration.value ? 1 : 0,
                app_secret: data.petpooja.app_secret,
                access_token: data.petpooja.access_token,
                restaurant_id: data.petpooja.restaurant_id,
                is_pet_pooja_order_assignment_enabled: data.enablePetPoojaOrder.value ? 1 : 0,
                is_enabled_pet_pooja_full_integration: 0,
            },
            billberry: {
                is_bill_berry_integration_enabled: data.enableBillberryIntegration.value ? 1 : 0,
                billberry_api_key: data.billberry.billberry_api_key ?? null,
                billberry_store_id: data.billberry.billberry_store_id ?? null,
            },
        };
    };

    const onSubmit = (data) => {
        updateThirdParty(formatData(data.third_party_pos));
        setShowTabChangeWarningPopup(false);
    };

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateThirdParty = async (params) => {
        try {
            const response = await APIV5.put(`outlets/${selectedOutletId}?section=third_party_pos`, { ...params });
            if (response.success) {
                reset();
                setValue('isFormLoading', true);
                fetchSetting();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return watch('isFormLoading') ? (
        <Loader />
    ) : (
        <Layout
            handleClickSave={handleSubmit(onSubmit)}
            handleClickCancel={() => {
                handleDiscardForm();
                setShowTabChangeWarningPopup(false);
            }}
            showFooter={isDirty}>
            <div className='md:pb-20'>
                <DropdownSection
                    className='mb-4'
                    name='third_party_pos.enablePetPoojaIntegration'
                    title='Enable Pet Pooja integration'
                    description='Enable to start integrating pej pooja menu.'
                    menuItems={preferenceMenuItem}
                    shadow='shadow-smallDropDownShadow'
                    label='(Enable Pet Pooja POS)'
                    placeholder='Please select preference'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    rules={{ required: 'Please select preference' }}
                    labelPaddingB='pb-2'
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />

                {watch('third_party_pos.enablePetPoojaIntegration')?.value &&
                    petPoojaFields.map((el, index) => (
                        <div
                            className='mb-4 last:mb-0'
                            key={index}>
                            <InputSection
                                key={index}
                                label={el.label}
                                labelStyle='paragraph-medium-italic text-neutral-500'
                                shadow='shadow-smallDropDownShadow'
                                name={el.id}
                                rules={el.rule}
                                placeholder={el.placeholder}
                                handleInputChange={() => setShowTabChangeWarningPopup(true)}
                            />
                            {errors &&
                                errors.third_party_pos &&
                                errors.third_party_pos.petpooja &&
                                errors.third_party_pos.petpooja[el.id.split('.').pop()] && (
                                    <ErrorMessage errors={errors.third_party_pos.petpooja[el.id.split('.').pop()]} />
                                )}
                        </div>
                    ))}

                <DropdownSection
                    className='mt-6 mb-4'
                    title='Enable Pet Pooja orders'
                    description='Enable to start getting orders to pet pooja.'
                    label='(Enable third party POS orders)'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    shadow='shadow-smallDropDownShadow'
                    menuItems={preferenceMenuItem}
                    openFromBottom={watch('enablePetPoojaIntegration')?.value}
                    name='third_party_pos.enablePetPoojaOrder'
                    placeholder='Please select preference'
                    rules={{ required: 'Please select preference' }}
                    labelPaddingB='pb-2'
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />

                <DropdownSection
                    className='mb-4'
                    name='third_party_pos.enableBillberryIntegration'
                    title='Enable Billberry integration'
                    description='Enable to start integrating billberry.'
                    menuItems={preferenceMenuItem}
                    shadow='shadow-smallDropDownShadow'
                    label='(Enable billberry POS)'
                    placeholder='Please select preference'
                    labelStyle='paragraph-medium-italic text-neutral-500'
                    rules={{ required: 'Please select preference' }}
                    labelPaddingB='pb-2'
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />

                {watch('third_party_pos.enableBillberryIntegration')?.value &&
                    billberryFields.map((el, index) => (
                        <div
                            className='mb-4'
                            key={index}>
                            <InputSection
                                key={index}
                                label={el.label}
                                labelStyle='paragraph-medium-italic text-neutral-500'
                                shadow='shadow-smallDropDownShadow'
                                name={el.id}
                                rules={el.rule}
                                placeholder={el.placeholder}
                                handleInputChange={() => setShowTabChangeWarningPopup(true)}
                            />
                            {errors &&
                                errors.third_party_pos &&
                                errors.third_party_pos.billberry &&
                                errors.third_party_pos.billberry[el.id.split('.').pop()] && (
                                    <ErrorMessage errors={errors.third_party_pos.billberry[el.id.split('.').pop()]} />
                                )}
                        </div>
                    ))}
            </div>
        </Layout>
    );
}
