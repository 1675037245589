import React from 'react';
import Header from './Header';
import { IsMobileScreen } from '../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';

export default function Layout(props) {
    const { children, showCustomerDetailsPopup, setSelectedDates, selectedDates } = props;

    const screenSize = useWindowSize();

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            <div className='bg-[#fafafa]'>
                {(!isMobileScreen || !showCustomerDetailsPopup) && (
                    <div className='pb-[70px] lg:pb-[23px] w-full max-w-[1336px] mx-auto bg-white'>
                        <Header
                            setSelectedDates={setSelectedDates}
                            selectedDates={selectedDates}
                        />

                        {children}
                    </div>
                )}
            </div>
        </>
    );
}
