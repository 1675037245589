const { format, addMinutes, parse } = require('date-fns');

function generateTimeSlots() {
    const timeSlots = [];
    const baseDate = new Date(2023, 0, 1);

    for (let i = 0; i < (24 * 60) / 10; i++) {
        const currentDate = addMinutes(baseDate, i * 10);
        const timeSlot = format(currentDate, 'hh:mm a');
        timeSlots.push(timeSlot);
    }

    return timeSlots;
}

export const dayTimeSlots = generateTimeSlots();

export const removeSlotIdFromEachDay = (array) => {
    return array.map((item) => ({
        days_of_week: item.days_of_week,
        time_slots: item.time_slots.map((slot) => {
            const { id, ...rest } = slot;
            return rest;
        }),
    }));
};

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(image, canvas, crop, scale = 1, rotate, setCroppedImage) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
    ctx.restore();

    const binaryImageData = canvas.toDataURL('image/png');

    setCroppedImage(binaryImageData);
}

export const formateDateTime = (date) => {
    if (!date) {
        return;
    }

    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());

    return {
        date: format(parsedDate, 'd MMM yyyy'),
        time: format(parsedDate, 'hh:mm aa'),
    };
};
