import React, { useState } from 'react';
import { LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import DeliveyEstimation from '../../Components/DeliveyEstimation';
import AvailableRiderDropdown from '../../Components/AvailableRiderDropdown';
import { IsMobileScreen } from '../../../../Constants/Constants';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import AssignRiderDetailsLoader from './Compnents/AssignRiderDetailsLoader';

export default function AssignRiderPopup(props) {
    const { handleClickClose } = props;

    const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState();

    const [rider, setRider] = useState();

    const isMobileScreen = IsMobileScreen();

    const deliveryPartnerMenuItem = [
        { label: 'Bolt', value: 'bolt' },
        { label: 'ShadowFax', value: 'shadow-fax' },
    ];

    return (
        <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
            <div
                className={`bg-white w-[460px] rounded-xl px-8 py-6 m-auto overflow-auto [&::-webkit-scrollbar]:hidden md:px-0 md:py-0 md:w-full md:rounded-none`}>
                {isMobileScreen && (
                    <div
                        className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                        onClick={handleClickClose}>
                        <LeftArrowIcon className='rotate-90' />
                        <span className='ml-1'>Back to Orders</span>
                    </div>
                )}

                <div className='flex flex-row justify-between mb-4 pb-2'>
                    <div className=''>
                        <div className='paragraph-large-medium md:paragraph-medium-medium'>Assign rider</div>
                        <span className='paragraph-medium-italic text-neutral-500'>
                            Select rider to deliver this order
                        </span>
                    </div>
                    <span
                        className='cursor-pointer md:hidden'
                        onClick={handleClickClose}>
                        <CloseIcon
                            height={24}
                            width={24}
                        />
                    </span>
                </div>

                <div className='mb-4'>
                    <DropdownWithValueLabel
                        menuItems={deliveryPartnerMenuItem}
                        label='Select delivery partner'
                        placeholder='Select delivery partner'
                        setSelected={setSelectedDeliveryPartner}
                    />
                </div>

                {selectedDeliveryPartner?.value === 'bolt' && (
                    <div className=''>
                        <AvailableRiderDropdown
                            menuItems={[
                                { riderName: 'Amrendr bahubali', active: true, mobileNumber: '(+91) 657438291' },
                                { riderName: 'Mahendr bahubali', active: false, mobileNumber: '(+91) 657438291' },
                            ]}
                            label='Select rider'
                            placeholder='Select rider'
                            setSelectedItemFromDropDown={setRider}
                        />
                    </div>
                )}

                {/* <AssignRiderDetailsLoader /> */}

                {((selectedDeliveryPartner?.value !== 'bolt' && selectedDeliveryPartner) || rider) && (
                    <DeliveyEstimation />
                )}

                <div className='mt-12 md:fixed md:bottom-0 md:w-full md:pr-4 md:pb-2 md:pt-4 md:shadow-dropShadow md:-ml-4 md:pl-4'>
                    <LargePrimaryButton label='Assign rider' />
                </div>
            </div>
        </div>
    );
}
