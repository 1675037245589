import React, { useState } from 'react';
import { ReactComponent as VegIcon } from '../../../Assets/vegetable-icon.svg';
import { ReactComponent as NonVegIcon } from '../../../Assets/non-veg.svg';
import { ReactComponent as DropDownIcon } from '../../../Assets/chevron-down.svg';
import { LargeDestructiveButton, LargePrimaryButton, LargeTertiaryButton } from '../../../Components/Buttons/Button';
import { useWindowSize } from '@uidotdev/usehooks';
import TotalBillBreakDown from './OrderCardComponent/TotalBillBreakDown';

const OrdersCard = (props) => {
    const {
        ordersIcon,
        orderingMode,
        orderLabel,
        isUpdated,
        items,
        handleClickCancelOrder,
        handleClickAssignRider,
        borderStyle,
        paddingRight,
        handleClickOrderDetails,
    } = props;

    const [showWithItems, setShowWithItems] = useState(false);

    const handleClickWithDropDown = () => {
        setShowWithItems(!showWithItems);
    };

    const screenHeight = useWindowSize().height;

    return (
        <>
            <div
                className={`w-full border border-neutral-300 rounded-lg pt-[11px] bg-white xl:border-none ${borderStyle}`}
                style={{ height: screenHeight - 246 }}>
                <div className='px-4 md:px-0'>
                    <div className='flex flex-row justify-between items-center mb-5 pb-[15px] border-b border-neutral-300'>
                        <div className='flex flex-row items-center'>
                            <div>{ordersIcon}</div>
                            <div className='ml-[6px]'>
                                <div className='paragraph-medium-italic text-neutral-500'>{orderingMode}</div>
                                <div className='paragraph-large-medium'>Order {orderLabel}</div>
                            </div>
                        </div>
                        <div className='flex flex-col items-end'>
                            <span className='paragraph-medium-italic text-neutral-500'>KOT #357</span>
                            <div
                                className='leading-7'
                                onClick={handleClickOrderDetails}>
                                <span className='text-lg font-normal text-primary-500 border-b border-primary-500 leading-none cursor-pointer'>
                                    View details
                                </span>
                            </div>
                        </div>
                    </div>

                    {isUpdated && (
                        <div className='mb-4 pb-4 border-b border-neutral-300 text-center'>
                            <span className='paragraph-overline-large text-tertiary-800'>ORDERED DISHES UPDATED!</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between mb-[19px]'>
                        <span className='text-base leading-4 border-b border-neutral-900'>Ordered dishes</span>
                        <span className='text-base leading-4 border-b border-neutral-900'>Quantity</span>
                    </div>
                </div>

                <div className={`pl-4 md:px-0 ${paddingRight ?? 'pr-0.5'}`}>
                    <div
                        className='mb-3 md:mb-0 scrollbar-style md:[&::-webkit-scrollbar]:hidden overflow-auto pr-2 md:max-h-full min-h-[115px]'
                        style={{ maxHeight: screenHeight - 565 }}>
                        <div>
                            {items.map((el, index) => (
                                <div
                                    className='mb-4 last:mb-0'
                                    key={index}>
                                    <div className='flex flex-row justify-between mb-1'>
                                        <div className='flex flex-row'>
                                            <span
                                                className={`flex ${
                                                    el.isPrepared
                                                        ? 'paragraph-medium-strikethrough'
                                                        : 'paragraph-medium-semi-bold'
                                                } ${el.updatedQuantity && 'text-tertiary-800'}`}>
                                                {el.displayName}
                                            </span>
                                            <div className='min-w-[15.6px] min-h-[15.6px] pt-1 ml-2'>
                                                {el.foodType === 'veg' ? <VegIcon /> : <NonVegIcon />}
                                            </div>
                                        </div>
                                        <div className='min-w-[70px] text-right'>
                                            <span
                                                className={`${
                                                    el.isDeleted
                                                        ? 'paragraph-medium-strikethrough'
                                                        : 'paragraph-medium-semi-bold'
                                                } ${
                                                    el.updatedQuantity &&
                                                    'paragraph-x-small-strikethrough text-tertiary-800'
                                                }`}>
                                                {('0' + el.quantity).slice(-2)}
                                            </span>
                                            {el.updatedQuantity && (
                                                <span className='paragraph-medium-semi-bold text-tertiary-800 ml-1'>
                                                    {('0' + el.updatedQuantity).slice(-2)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='pl-[21px] pr-11 ml-2 border-l border-neutral-300'>
                                        {el.customization && (
                                            <div className=''>
                                                <div className='flex flex-row items-center'>
                                                    <span
                                                        className={`${
                                                            el.isPrepared
                                                                ? 'paragraph-small-strikethrough mr-1'
                                                                : 'paragraph-small-medium'
                                                        } title-sap relative`}>
                                                        With
                                                    </span>
                                                    {el.isPrepared && (
                                                        <div
                                                            className='cursor-pointer'
                                                            onClick={handleClickWithDropDown}>
                                                            <DropDownIcon
                                                                height={20}
                                                                className={`${showWithItems && 'rotate-180'}`}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {!(!showWithItems && el.isPrepared) && (
                                                    <div className='flex flex-col paragraph-small-italic text-neutral-500 mb-[1px]'>
                                                        {el.customization.map((el, index) => (
                                                            <div
                                                                className='mt-1'
                                                                key={index}>
                                                                {el.displayName}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {el.addons && (
                                            <div className='w-full'>
                                                <span className='paragraph-small-medium title-sap relative'>Note</span>
                                                <p className='mt-1 flex flex-col paragraph-small-italic text-neutral-500 pr-48 2xl:pr-28 xl:pr-5'>
                                                    {el.addons}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='bg-white sticky md:relative bottom-8 md:px-4 md:pb-1 mt-auto'>
                    <div className='px-4 md:px-0 border-t border-neutral-300 pt-4'>
                        <TotalBillBreakDown />
                    </div>

                    <div className='px-4 pt-[13px] pb-[12px] md:fixed md:bottom-0 md:left-0 md:right-0 md:bg-white md:shadow-dropShadow'>
                        <div className='flex flex-row justify-between'>
                            <div
                                className='w-1/2 pr-[7px] cursor-pointer'
                                onClick={handleClickCancelOrder}>
                                <LargeDestructiveButton
                                    isDefault={false}
                                    fontsWeight={true}
                                    label='Cancel order'
                                />
                            </div>
                            <div className='w-1/2 pl-[7px]'>
                                <LargePrimaryButton
                                    isDefault={false}
                                    fontsWeight={true}
                                    label='Accept order'
                                />
                            </div>
                        </div>
                        <div
                            className='w-full mt-2 cursor-pointer'
                            onClick={handleClickAssignRider}>
                            <LargeTertiaryButton label='Rider assignment' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrdersCard;
