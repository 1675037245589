import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as LinearMenuIcon } from '../../../../../../Assets/linear-menu.svg';
import { useSelector } from 'react-redux';

export default function TableRow(props) {
    const { isRearrange, label, id, index, value } = props;

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isRearrange ? 'pointer' : 'default',
    };

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    return (
        <>
            <tr
                ref={setNodeRef}
                style={style}
                key={index}
                {...attributes}
                {...listeners}
                className={`h-[70px] justify-center paragraph-small-regular border-t first:border-none border-neutral-300`}>
                <td className='pl-6'>
                    <div className='flex flex-row items-center'>
                        {isRearrange && (
                            <span className='mr-3'>
                                <LinearMenuIcon />
                            </span>
                        )}

                        <span>{label}</span>
                    </div>
                </td>

                <td className='py-[14px] px-6'>
                    {value?.choices?.map((el, index) => {
                        return (
                            <div
                                className='flex flex-row items-start mt-0.5 pb-0.5'
                                key={index}>
                                <span className='bg-black rounded-full mt-2 h-1.5 w-full max-w-[6px] mr-5' />
                                <span className='paragraph-small-regular'>
                                    {el?.translations?.name?.[selectedLanguageId]}
                                </span>
                            </div>
                        );
                    })}
                </td>
            </tr>
        </>
    );
}
