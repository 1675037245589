import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { ReactComponent as SearchIcon } from '../../Assets/search.svg';

export const StateCityDropdown = (props) => {
    const { label, menuItems, setFilter, setClearFilter, clearFilter } = props;

    const [selectedItem, setSelectedItem] = useState('All');

    const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);

    useEffect(() => {
        setFilteredMenuItems(menuItems);
    }, [menuItems]);

    useEffect(() => {
        clearFilter && selectedItem !== 'All' && handleClickMenuItem('All');

        // eslint-disable-next-line
    }, [clearFilter]);

    const handleClickMenuItem = (selectedItem) => {
        setSelectedItem(selectedItem);

        setFilteredMenuItems(menuItems);

        setFilter(selectedItem === 'All' ? null : selectedItem.toLowerCase());

        setClearFilter && setClearFilter(false);
    };

    const handleChangeInputValue = (e) => {
        let filteredCountryCodes = menuItems.filter((el) => el.toLowerCase().includes(e.target.value.toLowerCase()));

        setFilteredMenuItems([...filteredCountryCodes]);
    };

    return (
        <>
            <div className=''>
                <div className='flex flex-row justify-between'>
                    <div className='paragraph-small-medium mb-1'>{label}</div>
                    {selectedItem !== 'All' && (
                        <div
                            className='text-primary-500 paragraph-small-medium cursor-pointer'
                            onClick={() => handleClickMenuItem('All')}>
                            Clear
                        </div>
                    )}
                </div>

                <div className='relative'>
                    <Menu as='div'>
                        <div className='dropdown-icon'>
                            <Menu.Button
                                className='shadow-Xsmall w-full flex flex-row justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none px-4 border-neutral-300 h-12 paragraph-small-regular'
                                onFocus={() => setFilteredMenuItems(menuItems)}>
                                {selectedItem ?? 'All'}
                                <DownArrowIcon className={`drop-down-icon-rotate min-w-[24px] min-h-[24px]`} />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'>
                            <Menu.Items className='absolute left-0 right-0 mt-2 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium-medium bg-shades-50 font-normal z-50 max-h-[195px] overflow-auto scrollbar-style'>
                                <div className='w-full pt-2 mb-2 flex flex-row items-center'>
                                    <SearchIcon />
                                    <input
                                        onChange={handleChangeInputValue}
                                        placeholder={`Search ${label.toLowerCase()}`}
                                        className='ml-2 placeholder:paragraph-small-regular placeholder:text-neutral-300 outline-none w-full border-b border-neutral-300'
                                    />
                                </div>

                                {filteredMenuItems?.map((el, index) => {
                                    return (
                                        <div
                                            className='pt-2 mb-2 cursor-pointer w-full'
                                            key={index}
                                            onClick={() => handleClickMenuItem(el)}>
                                            <Menu.Item>
                                                <div className='paragraph-small-regular active:paragraph-small-semi-bold hover:text-primary-500'>
                                                    {el}
                                                </div>
                                            </Menu.Item>
                                        </div>
                                    );
                                })}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </>
    );
};
