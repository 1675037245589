import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from '../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../Assets/chevron-down.svg';
import PickColor from '../ColorPicker/PickColor';
import { LargePrimaryButton } from '../Buttons/Button';
import { IsMobileScreen } from '../../Constants/Constants';

export default function SelectColorPopup(props) {
    const { handleClickClose, backToPage, description, handleClickSave } = props;

    const isMobileScreen = IsMobileScreen();

    const [selectedColor, setSelectedColor] = useState(null);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 z-[101] flex md:absolute md:z-[9] md:top-0 md:block md:overflow-hidden'>
                <div className='max-w-[427px] w-full rounded-xl bg-shades-50 m-auto px-8 py-6 md:h-full md:rounded-none md:px-4 md:py-4 md:max-w-full'>
                    {isMobileScreen && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='ml-1'>Back to {backToPage}</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between pb-4 items-center'>
                        <div>
                            <div className='paragraph-large-medium'>Color picker</div>
                            <div className='paragraph-medium-italic text-neutral-500'>{description}</div>
                        </div>

                        {!isMobileScreen && (
                            <span
                                onClick={handleClickClose}
                                className='cursor-pointer'>
                                <CloseIcon />
                            </span>
                        )}
                    </div>

                    <PickColor
                        hideTitle={true}
                        setSelectedColorFromPopup={setSelectedColor}
                        selectedColor={props.selectedColor ?? selectedColor}
                    />

                    <div className='flex flex-row w-full mt-4 md:fixed md:bottom-0 md:bg-slate-50 md:-ml-4 md:px-4 md:pb-1 md:pt-2 md:shadow-dropShadow'>
                        <button
                            className='w-1/2 mr-2 h-[48px] rounded border border-neutral-300 bg-shades-50 paragraph-medium-semiBold text-neutral-500'
                            onClick={handleClickClose}>
                            Cancel
                        </button>

                        <div
                            className='w-1/2 ml-2 cursor-pointer'
                            onClick={() => handleClickSave(selectedColor)}>
                            <LargePrimaryButton label='Save' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
