import React from 'react';

export default function TableHeader(props) {
    const { showDescription } = props;

    return (
        <thead className='table table-fixed'>
            <tr className='bg-neutral-50 uppercase paragraph-overline-small text-neutral-700 h-11'>
                <th className='min-w-[196px] text-left pl-6 shadow-innerShadow'>COUPON CODE</th>
                <th className='min-w-[133px] text-left pl-6 shadow-innerShadow'>OFFER TYPE</th>
                <th className='min-w-[168px] text-left pl-6 shadow-innerShadow'>TITLE</th>
                {showDescription && <th className='min-w-[235px] text-left pl-6 shadow-innerShadow'>DESCRIPTION</th>}
            </tr>
        </thead>
    );
}
