import React from 'react';
import InputSection from '../../../../../Components/InputField/InputSection';
import DropdownSection from '../../../../../Components/DropDown/DropdownSection';
import { preferenceMenuItem } from '../../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';

export default function OrderCount() {
    const { watch } = useFormContext();

    return (
        <>
            <DropdownSection
                name='hasMinMaxOrderCount'
                className='mb-4'
                menuItems={preferenceMenuItem}
                title='Order counts'
                description='Select minimum and maximum order counts required to use this offer.'
                placeholder='Select your preference'
                rules={{ required: 'Please select preference' }}
                zIndex='z-[1]'
            />

            {!!watch('hasMinMaxOrderCount')?.value && (
                <div className='flex flex-row gap-3 w-full mb-6 lg:block lg:gap-0'>
                    <InputSection
                        name='min_required_order_counts'
                        inputType='number'
                        label='(Minimum order count)'
                        placeholder='Enter minimum order count'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        className='lg:mb-4'
                        rules={{ required: 'Please enter order count' }}
                    />

                    <InputSection
                        name='max_required_order_counts'
                        inputType='number'
                        label='(Maximum order count)'
                        placeholder='Enter maximum order count'
                        labelStyle='paragraph-medium-italic text-neutral-500'
                        rules={{ required: 'Please enter order count' }}
                    />
                </div>
            )}
        </>
    );
}
