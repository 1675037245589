import React from 'react';
import { useFieldArray } from 'react-hook-form';
import SlotSection from './SlotSection';

export default function AllDaySameTimeSelection(props) {
    const { fieldName, control, preparationTimeName, setShowTabChangeWarningPopup } = props;

    const { fields, append, remove } = useFieldArray({
        control,
        name: fieldName,
    });

    const emptySlot = preparationTimeName
        ? { start_time: '', end_time: '', [preparationTimeName]: '' }
        : { start_time: '', end_time: '' };

    const handleClickAppend = () => {
        append(emptySlot);
        setShowTabChangeWarningPopup?.(true);
    };

    return (
        <SlotSection
            fields={fields}
            handleClickAppend={handleClickAppend}
            remove={remove}
            {...props}
        />
    );
}
