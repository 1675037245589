import React, { useState } from 'react';
import { ReactComponent as DownArrow } from '../../../Assets/chevron-down.svg';
import Catalogue from '../Components/Catalogue/Catalogue';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import OutletmenuInformation from './Components/OutletmenuInformation';
import { IsMobileScreen } from '../../../Constants/Constants';
import ListVIewOutletInformation from './ListVIewOutletInformation';
import HeaderButtonsAndPopups from '../Components/HeaderButtonsAndPopups';

export default function MenuManagementOutletMenu() {
    const location = useLocation();

    const page = location.state?.page;

    const navigate = useNavigate();

    const goToMenupage = () => {
        navigate('/menu');
    };

    const isMobileScreen = IsMobileScreen();

    const [showManuManegePopup, setShowManuManegePopup] = useState(false);

    const toggleManuManegePopup = () => {
        setShowManuManegePopup(!showManuManegePopup);
    };

    const [showOutletDetails, setShowOutletDetails] = useState(true);

    const toggleOutletDetails = () => {
        setShowOutletDetails(!showOutletDetails);
    };
    const [editMenu, setEditMenu] = useState(false);

    const [isRearrange, setIsRearrange] = useState(false);

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div className='px-8 pb-[70px] lg:px-4 lg:pb-[23px] pt-4 w-full max-w-[1336px] mx-auto bg-white'>
                    {isMobileScreen && showOutletDetails && (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={goToMenupage}>
                            <DownArrow className='rotate-90' />
                            <span className='paragraph-medium-medium ml-1'>Back to outlet menu</span>
                        </div>
                    )}

                    {!isMobileScreen ? <OutletmenuInformation /> : showOutletDetails && <ListVIewOutletInformation />}

                    <HeaderButtonsAndPopups
                        page='Outlet menu details'
                        toggleShowRenderPage={toggleOutletDetails}
                        editMenu={editMenu}
                        isRearrange={isRearrange}
                        setEditMenu={setEditMenu}
                        setIsRearrange={setIsRearrange}
                    />
                    {(showOutletDetails || !isMobileScreen) && (
                        <Catalogue
                            page={page}
                            toggleManuManegePopup={toggleManuManegePopup}
                            editMenu={editMenu}
                            isRearrange={isRearrange}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
