import { SingleLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';

export const CategoryLoader = () => {
    return (
        <div className='p-4 border border-neutral-300 rounded-lg'>
            <SingleLoader className='h-6' />
            <SingleLoader className='w-[65%] h-6 mt-4' />
            <SingleLoader className='w-[35%] h-6 mt-4' />
            <SingleLoader className='h-6 mt-4' />
            <SingleLoader className='w-[65%] h-6 mt-4' />
            <SingleLoader className='w-[35%] h-6 mt-4' />
        </div>
    );
};

export const DishDetailLoader = () => {
    const numLoaders = 8;

    const loadersArray = Array.from({ length: numLoaders }, (_, index) => index);

    return (
        <div>
            {loadersArray.map((_, index) => (
                <SingleLoader
                    key={index}
                    className={`h-14 rounded-lg${index === 0 ? ' pt-0.5' : ' mt-4'}`}
                />
            ))}
        </div>
    );
};
