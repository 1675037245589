import React from 'react';
import { format } from 'date-fns';
import { ReactComponent as CalenderIcon } from '../../../../Assets/calendar.svg';
import { ReactComponent as ScheduleIcon } from '../../../../Assets/schedule.svg';

export default function PendingTableSection(props) {
    const { setShowComplaintDetails, setShowComplaintImage } = props;

    const sampleComplaint = {
        external_order_id: 'ORDER-62-2084-2016-09-23-33',
        order_id: 1906491425,
        reference_id: '59dd6d0d-095b-4232-a6b0-0c468b03857b',
        image_urls: [
            'https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
            'https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTExL2xyL3drMTQ4OTY0My1pbWFnZS5qcGc.jpg',
            'https://images.unsplash.com/photo-1521747116042-5a810fda9664',
        ],
        complaint_message: 'Food taste is unsatisfactory',
        complaint_reason: 'Poor taste or quality',
        created_at: 1596925042,
        expired_at: 1597011442,
        ordered_items: [
            {
                quantity: 1,
                name: 'Mutton Tikka Tawa',
                dish_id: '80346',
                total_cost: 124,
            },
            {
                quantity: 1,
                name: 'Mutton Tikka Tawa',
                dish_id: '80346',
                total_cost: 411,
            },
            {
                quantity: 1,
                name: 'Mutton Tikka Tawa',
                dish_id: '80346',
                total_cost: 927,
            },
        ],
        customer_complaints_count: 2,
        repeat_customer_count: 8,
        refund_options: [
            {
                id: 1,
                amount: 120,
                refund_type: 'SOURCE',
            },
            {
                id: 2,
                amount: 60,
                refund_type: 'SOURCE',
            },
        ],
        min_custom_refund: 35,
    };

    const pendingComplaints = [sampleComplaint, sampleComplaint, sampleComplaint];

    return (
        <>
            <div className='w-full rounded-lg overflow-auto scrollbar-style border-neutral-300 border'>
                <table className='w-full break-words'>
                    <thead>
                        <tr className='bg-neutral-50 uppercase shadow-innerShadow paragraph-overline-small text-neutral-700 pl-6 h-11'>
                            <th className='pl-6 text-left'>order id</th>
                            <th className='pl-6 text-left'>COMPLAINT REASON</th>
                            <th className='pl-6 text-left'>TOTAL AMOUNT</th>
                            <th className='pl-6 text-left'>CREATED AT</th>
                            <th className='pl-6 text-left'>EXPIRED AT</th>
                            <th className='pl-6 text-left'>MIN. CUSTOM REFUND</th>
                            <th className='pl-6 text-left'>images</th>
                        </tr>
                    </thead>

                    <tbody>
                        {pendingComplaints?.map((el, index) => {
                            const createdAt = {
                                date: format(new Date(el.created_at * 1000), 'dd MMM yyyy'),
                                time: format(new Date(el.created_at * 1000), 'hh:mm a'),
                            };

                            const expiredAt = {
                                date: format(new Date(el.expired_at * 1000), 'dd MMM yyyy'),
                                time: format(new Date(el.expired_at * 1000), 'hh:mm a'),
                            };

                            return (
                                <tr
                                    className='even:bg-neutral-50 border-t first:border-none border-neutral-300 paragraph-small-regular h-[70px] justify-center'
                                    key={index}>
                                    <td
                                        className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                        onClick={() => setShowComplaintDetails(true)}>
                                        {el?.order_id}
                                    </td>

                                    <td className='pl-6'>{el?.complaint_reason}</td>

                                    <td className='pl-6'>
                                        {el?.ordered_items?.reduce((sum, item) => sum + item.total_cost, 0)}
                                    </td>

                                    <td className='pl-6'>
                                        <div className='flex flex-row items-center mb-1'>
                                            <CalenderIcon className='w-6 h-6' />
                                            <span>{createdAt.date}</span>
                                        </div>

                                        <div className='flex flex-row items-center'>
                                            <ScheduleIcon className='w-6 h-6' />
                                            <span>{createdAt.time}</span>
                                        </div>
                                    </td>

                                    <td className='pl-6'>
                                        <div className='flex flex-row items-center mb-1'>
                                            <CalenderIcon className='w-6 h-6' />
                                            <span>{expiredAt.date}</span>
                                        </div>

                                        <div className='flex flex-row items-center'>
                                            <ScheduleIcon className='w-6 h-6' />
                                            <span>{expiredAt.time}</span>
                                        </div>
                                    </td>

                                    <td className='pl-6'>{el.min_custom_refund}</td>

                                    {index % 2 === 0 ? (
                                        <td className='pl-6'>-</td>
                                    ) : (
                                        <td
                                            className='pl-6 cursor-pointer text-primary-500 hover:underline'
                                            onClick={() => setShowComplaintImage(true)}>
                                            Show image
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
