import React, { useEffect, useState } from 'react';
import { LargeTertiaryButton } from '../../../../Components/Buttons/Button';
import ScheduleNotification from '../../../Notifications/Components/ScheduleNotification';
import DropdownWithValueLabel from '../../../../Components/DropDown/DropdownWithValueLabel';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';
import ToggleSwitch from '../../../../Components/ToggleSwitch/ToggleSwitch';
import APIV2 from '../../../../api/axios/APIV2';
import { useSelector } from 'react-redux';
import SelectImageFromLibraryPopup from '../../../Banners/Component/SelectImagePopupComponent/SelectImageFromLibraryPopup';
import TitleDescription from '../../../../Components/TitleDescription/TitleDescription';
import UploadImagePopup from '../../../../Components/UploadImagePopup/UploadImagePopup';
import InputSection from '../../../../Components/InputField/InputSection';
import InputAreaSection from '../../../../Components/InputField/InputAreaSection';

export default function NotificationSettings() {
    const {
        control,
        watch,
        clearErrors,
        setValue,
        formState: { errors },
    } = useFormContext();

    const availableLanguages = useSelector((state) => state.langauge.availableLanguages);

    useEffect(() => {
        fetchMobileScreens();

        // eslint-disable-next-line
    }, []);

    const [formattedMobileScreenList, setFormattedMobileScreenList] = useState([]);

    const formatScreen = (screen) => {
        return { label: screen.charAt(0).toUpperCase() + screen.slice(1).replace(/-/g, ' '), value: screen };
    };

    const fetchMobileScreens = async () => {
        try {
            const response = await APIV2.get('/mobile/screens-list');

            const formattedScreenList = response.screens.map((el) => formatScreen(el));

            setFormattedMobileScreenList(formattedScreenList);
        } catch (error) {
            console.log(error);
        }
    };

    const [showUploadImage, setShowUploadImage] = useState(null);

    const handleUploadImage = (details, id) => {
        setValue(`notification_image.${id}`, details);
        clearErrors(`notification_image.${id}`);
    };

    const handleSelection = () => {
        clearErrors('notification_screen');
    };

    useEffect(() => {
        clearErrors(['notification_title', 'notification_description', 'notification_image', 'notification_screen']);
    }, [watch('send_notification')]);

    const [showSelectFromLibrary, setShowSelectFromLibrary] = useState(null);

    const scheduleNotificationMenuItems = [
        { label: 'As offer live', value: 'instant' },
        { label: 'Custom date & time', value: 'custom' },
    ];

    return (
        <>
            <div className='max-w-[636px] md:max-w-full lg:mb-5 md:mb-12 lg:max-w-[459px] w-full'>
                <div className='mb-4'>
                    <div className='flex flex-row justify-between max-w-[636px] md:max-w-full lg:max-w-[459px] w-full'>
                        <TitleDescription
                            title='Send notification'
                            description='Would you like to send notification to customers.'
                        />
                        <Controller
                            render={({ field }) => (
                                <ToggleSwitch
                                    isEnable={field.value ?? false}
                                    canChange
                                    setIsEnabled={field.onChange}
                                />
                            )}
                            name='send_notification'
                            control={control}
                        />
                    </div>
                </div>

                {watch('send_notification') && (
                    <>
                        <div className='mb-3'>
                            <TitleDescription
                                title='Notification title'
                                description='Please enter the notification title that your customers will see for the offer.'
                                disabled={!watch('send_notification')}
                            />
                        </div>

                        <div className='flex flex-row justify-between w-full mb-4 lg:block gap-3'>
                            {availableLanguages.map((el, index) => {
                                return (
                                    <div
                                        className='w-full '
                                        key={index}>
                                        <InputSection
                                            className='lg:mb-4'
                                            name={`notification_title.${el.language_id}`}
                                            disabled={!watch('send_notification')}
                                            label={`(${el.language_label})`}
                                            placeholder={`Enter title in ${el.language_label}`}
                                            labelStyle={`paragraph-medium-italic ${
                                                !watch('send_notification') ? 'text-neutral-300' : 'text-neutral-500'
                                            }`}
                                            rules={
                                                watch('send_notification')
                                                    ? { required: `Please enter title in ${el.language_label}` }
                                                    : { required: false }
                                            }
                                        />

                                        {errors?.notification_title?.[el.language_id] && (
                                            <ErrorMessage errors={errors.notification_title?.[el.language_id]} />
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        <div className='mb-3'>
                            <TitleDescription
                                title='Notification description'
                                description='Please enter the notification description that your customers will see for the offer.'
                                disabled={!watch('send_notification')}
                            />
                        </div>

                        <div className='flex flex-row w-full mb-4 lg:block justify-between gap-4'>
                            {availableLanguages.map((el, index) => (
                                <div
                                    className='min-w-[311px] w-full lg:mb-4 md:max-w-full lg:min-w-0 lg:max-w-[311px]'
                                    key={index}>
                                    <InputAreaSection
                                        name={`notification_description.${el.language_id}`}
                                        disabled={!watch('send_notification')}
                                        boxHeight='h-[120px]'
                                        label={`(${el.language_label})`}
                                        placeholder={`Enter description in ${el.language_label}`}
                                        labelStyle={`paragraph-medium-italic ${
                                            !watch('send_notification') ? 'text-neutral-300' : 'text-neutral-500'
                                        }`}
                                        rules={
                                            watch('send_notification')
                                                ? { required: `Please enter description in ${el.language_label}` }
                                                : { required: false }
                                        }
                                    />

                                    {errors?.notification_description?.[el.language_id] && (
                                        <ErrorMessage errors={errors.notification_description?.[el.language_id]} />
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className='w-full'>
                            <div className='mb-3.5'>
                                <Controller
                                    render={({ field }) => (
                                        <DropdownWithValueLabel
                                            label='Display screen'
                                            placeholder='Select display screen'
                                            buttonTextColor='neutral-300'
                                            menuItems={formattedMobileScreenList}
                                            setSelected={(selection) => {
                                                field.onChange(selection);
                                                handleSelection();
                                            }}
                                            selectedItem={field.value}
                                            disabled={!watch('send_notification')}
                                            fixedHeight='h-[200px]'
                                            zIndex='z-[1]'
                                        />
                                    )}
                                    name='notification_screen'
                                    control={control}
                                    rules={
                                        watch('send_notification')
                                            ? { required: 'Please select display screen' }
                                            : { required: false }
                                    }
                                />
                                {errors?.notification_screen && <ErrorMessage errors={errors.notification_screen} />}
                            </div>

                            <div className='mb-4'>
                                <ScheduleNotification
                                    disabled={!watch('send_notification')}
                                    label='Schedule notification'
                                    menuItems={scheduleNotificationMenuItems}
                                    watch={watch}
                                    timeTypeName='notification_time_type'
                                    control={control}
                                    selectedTimeName='notification_selected_time'
                                    selectedDateName='notification_selected_date'
                                    setValue={setValue}
                                />
                            </div>

                            <div className=''>
                                <div className='mb-2 -mr-2'>
                                    <TitleDescription
                                        title='Select image'
                                        description='Please select image to display in notification'
                                    />
                                </div>

                                {availableLanguages.map((el, index) =>
                                    !watch(`notification_image.${el.language_id}`) ? (
                                        <div
                                            className='mb-4'
                                            key={index}>
                                            <span
                                                className={`paragraph-medium-italic ${
                                                    !watch('send_notification')
                                                        ? 'text-neutral-300'
                                                        : 'text-neutral-500'
                                                }`}>
                                                ( {el.language_label} )
                                            </span>

                                            <div className='flex flex-row paragraph-medium-regular mobile:text-sm mt-2 '>
                                                <button
                                                    onClick={() =>
                                                        watch('send_notification') && setShowUploadImage(el.language_id)
                                                    }
                                                    className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 mr-1.5 mobile:mr-1 ${
                                                        !watch('send_notification')
                                                            ? 'cursor-not-allowed text-neutral-300'
                                                            : 'text-neutral-500'
                                                    }`}>
                                                    Upload an image
                                                </button>
                                                <button
                                                    onClick={() => setShowSelectFromLibrary(el.language_id)}
                                                    className={`justify-center h-12 border-neutral-300 rounded-md border w-1/2 ml-1.5 mobile:ml-1 ${
                                                        !watch('send_notification')
                                                            ? 'text-neutral-300 cursor-not-allowed'
                                                            : 'text-neutral-500'
                                                    }`}>
                                                    Select from library
                                                </button>
                                            </div>

                                            {errors?.notification_image?.[el.language_id] && (
                                                <ErrorMessage errors={errors.notification_image[el.language_id]} />
                                            )}
                                        </div>
                                    ) : (
                                        <div
                                            className='flex flex-row gap-4 items-center mb-4'
                                            key={index}>
                                            <img
                                                alt=''
                                                src={watch('notification_image')?.[el.language_id]?.image_url}
                                                className='min-w-[197px] h-[109px] lg:max-w-[136px] lg:min-w-0 lg:w-full rounded-md md:mr-[17px] md:min-w-[163px]'
                                            />

                                            <div
                                                className='max-w-[197px] w-full md:max-w-full lg:max-w-[158px] mb-2'
                                                onClick={() => setShowUploadImage(true)}>
                                                <LargeTertiaryButton
                                                    label='Replace image'
                                                    isDefault={false}
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>

            {!!showUploadImage && (
                <UploadImagePopup
                    setUploadedImageDetails={(details) => handleUploadImage(details, showUploadImage)}
                    handleClickClose={() => setShowUploadImage(false)}
                />
            )}

            {!!showSelectFromLibrary && (
                <SelectImageFromLibraryPopup
                    handleClickClose={() => setShowSelectFromLibrary(null)}
                    handleSelectImage={(details) => handleUploadImage(details, showSelectFromLibrary)}
                />
            )}
        </>
    );
}
