import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import APIV3 from '../../../../api/axios/APIV3';
import SearchableDropdown from '../../../../Components/SearchableDropdown/SearchableDropdown';
import { ErrorMessage } from '../../../../Components/Error/ErrorMessage';

export default function ItemList() {
    const {
        watch,
        clearErrors,
        setValue,
        formState: { errors },
    } = useFormContext();

    const [initialCategoryList, setInitialCategoryList] = useState([]);

    const [categoryMenuItems, setCategoryMenuItems] = useState([]);

    const formatList = (list) => {
        const formattedList = list.map((el) => ({
            label: el.internal_name,
            value: el,
            ...el,
        }));

        setCategoryMenuItems(formattedList);

        setInitialCategoryList(formattedList);
    };

    const fetchCategoryList = async (param) => {
        const params = { language_id: 1, ...param };

        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/categories/internal-name`, {
                params,
            });

            formatList(response.categories);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCategoryList();
        // eslint-disable-next-line
    }, []);

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const onCompleteTyping = (searchQuery, fieldName) => {
        if (searchQuery === '') {
            setValue(fieldName, undefined);
            setCategoryMenuItems([]);
        } else {
            const filteredItems = filterItemsByLabel(searchQuery);
            setCategoryMenuItems(filteredItems);
        }
    };

    const filterItemsByLabel = (searchQuery) => {
        if (!searchQuery) {
            return initialCategoryList;
        }

        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        return initialCategoryList.filter((item) => item.label?.toLowerCase().includes(lowerCaseSearchQuery));
    };

    const fetchCategoryItem = async (id) => {
        const params = {
            language_id: 1,
            is_recursive: 1,
        };

        try {
            const response = await APIV3.get(`restaurants/${selectedRestaurantId}/categories/${id}/items`, { params });

            setValue('selected_item_category.list', response.items);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectCategory = (fieldChange, selection) => {
        fieldChange(selection);

        fetchCategoryItem(selection.category_id);

        clearErrors('selected_item_category.category');
        clearErrors('selected_item_category');
    };

    return (
        <section className=''>
            <h1 className='paragraph-large-medium md:paragraph-medium-medium'>Select the category of the item</h1>

            <span className='paragraph-medium-italic md:paragraph-small-italic text-neutral-400'>
                Select the category of the item you want to add to the menu.
            </span>

            <div className='max-w-[312px] mt-3'>
                <Controller
                    render={({ field }) => (
                        <SearchableDropdown
                            onCompleteTyping={(searchQuery) =>
                                onCompleteTyping(searchQuery, `selected_item_category.list`)
                            }
                            menuItems={categoryMenuItems}
                            placeholder='Select category'
                            handleSelect={(selection) => handleSelectCategory(field.onChange, selection)}
                            fixedHeight={`${categoryMenuItems.length > 5 && 'h-[230px]'}`}
                            labelAsSelectedItem
                            displayValue={watch('selected_item_category.category')?.label}
                        />
                    )}
                    name='selected_item_category.category'
                />

                {errors?.selected_item_category && <ErrorMessage errors={errors.selected_item_category} />}
            </div>

            <div className=''>
                {watch('selected_item_category.list')?.map((el, index) => (
                    <div
                        className='mt-7'
                        key={index}>
                        <span className='paragraph-small-semi-bold'>
                            {el.internal_name} | {el.translations.title[1]}
                        </span>

                        <div className='mt-3 max-w-[312px]'>
                            {el.variants.length ? (
                                el.variants.map((variant, variantIndex) => (
                                    <div
                                        className=''
                                        key={variantIndex}>
                                        {variant.options.map((option, optionIndex) => (
                                            <React.Fragment key={optionIndex}>
                                                <div className='mt-2 mb-1 paragraph-x-small-regular'>
                                                    {option.translations.title[1]}
                                                </div>

                                                <Controller
                                                    render={({ field }) => (
                                                        <SearchableDropdown
                                                            onCompleteTyping={(searchQuery) =>
                                                                onCompleteTyping(
                                                                    searchQuery,
                                                                    `selected_item_category.list.${index}.variants.${variantIndex}.options.${optionIndex}.selectedOption`
                                                                )
                                                            }
                                                            menuItems={categoryMenuItems}
                                                            placeholder='Select category'
                                                            handleSelect={(selection) => {
                                                                field.onChange(selection);
                                                                clearErrors('selected_item_category');
                                                            }}
                                                            fixedHeight={`${categoryMenuItems.length > 5 && 'h-[230px]'}`}
                                                            labelAsSelectedItem
                                                            displayValue={field.value?.label}
                                                        />
                                                    )}
                                                    name={`selected_item_category.list.${index}.variants.${variantIndex}.options.${optionIndex}.selectedOption`}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <Controller
                                    render={({ field }) => (
                                        <SearchableDropdown
                                            onCompleteTyping={(searchQuery) =>
                                                onCompleteTyping(
                                                    searchQuery,
                                                    `selected_item_category.list.${index}.selectedOption`
                                                )
                                            }
                                            menuItems={categoryMenuItems}
                                            placeholder='Select category'
                                            handleSelect={(selection) => {
                                                field.onChange(selection);
                                                clearErrors('selected_item_category');
                                            }}
                                            fixedHeight={`${categoryMenuItems.length > 5 && 'h-[230px]'}`}
                                            labelAsSelectedItem
                                            displayValue={field.value?.label}
                                        />
                                    )}
                                    name={`selected_item_category.list.${index}.selectedOption`}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
