export const bannerType = [
    { label: 'Primary banners', value: { is_primary: 1, is_secondary: 0, is_tertiary: 0 } },
    { label: 'Secondary banners', value: { is_primary: 0, is_secondary: 1, is_tertiary: 0 } },
    { label: 'Tertiary banners', value: { is_primary: 0, is_secondary: 0, is_tertiary: 1 } },
];

export const validityMenuItem = [
    { label: 'Always', value: 'always' },
    { label: 'Select duration', value: 'Select duration' },
];
