import React from 'react';
import TitleDescriptionLoader from '../../../../../../Components/ContentLoader/TitleDescriptionLoader';
import { DropdownBoxLoader } from '../../../../../../Components/ContentLoader/DropdownLoader';
import ContentLoader from 'react-content-loader';

export default function Loader() {
    return (
        <>
            <div className='flex flex-col mt-4'>
                <TitleDescriptionLoader className='max-w-[450px] md:max-w-full mb-2' />
                <DropdownBoxLoader
                    className='max-w-[312px] md:max-w-full mb-2'
                    hasLabel
                />
                <ContentLoader
                    width='100%'
                    height='48'
                    className='max-w-[630px] md:max-w-full w-full'>
                    <rect
                        x='0'
                        y='0'
                        rx='4'
                        ry='4'
                        width='100%'
                        height='30'
                    />
                </ContentLoader>
            </div>
        </>
    );
}
