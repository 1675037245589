import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as DropdownIcon } from '../../../Assets/chevron-down.svg';

export default function MetaTagDropdown(props) {
    const { handleChangeTag, disabled, menuItems } = props;

    const handleClickItem = (item) => {
        handleChangeTag(item);
    };

    return (
        <div className='dropdown-icon'>
            <Menu
                as='div'
                className='relative inline-block text-left'>
                <div>
                    <Menu.Button
                        disabled={disabled}
                        className={`paragraph-small-medium inline-flex items-center remove-shadow ${
                            disabled ? 'text-neutral-300' : 'text-primary-500'
                        } w-full justify-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                        Add meta tag
                        <span className='-mr-1.5'>
                            <DropdownIcon
                                className='drop-down-icon-rotate'
                                height={16}
                                fill={disabled ? '#D3D2D8' : '#6C5DD3'}
                            />
                        </span>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items className='absolute shadow-medium right-0 mt-[3px] z-50 px-4 py-2 w-full min-w-[164px] origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none paragraph-small-medium'>
                        {menuItems.map((el, index) => (
                            <div
                                className='pt-2 mb-2 cursor-pointer'
                                onClick={() => handleClickItem(el)}
                                key={index}>
                                <Menu.Item>
                                    <span className='hover:text-primary-500'>{el}</span>
                                </Menu.Item>
                            </div>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
