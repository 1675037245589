import React, { useState } from 'react';
import { IsMobileScreen } from '../../../Constants/Constants';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';

export default function ComplaintDetailsPopup(props) {
    const { handleClickClose, setShowRejectComplaintPopup, setShowAcceptComplaintPopup } = props;

    const isMobileScreen = IsMobileScreen();

    const displayedDetails = [
        { item: 'Order Id', value: 1906491425 },
        { item: 'Reason', value: 'Poor taste or quality' },
        { item: 'Created at', value: '09 Aug 2020 10:17 PM' },
        { item: 'Complaints count', value: 2 },
        { item: 'Repeat customer count', value: 8 },
        { item: 'Min. custom refund', value: 35 },
        { item: 'Expired at', value: '09 Aug 2020 10:17 PM' },
    ];

    const orderedDishes = [
        {
            quantity: 1,
            name: 'Mutton Tikka Tawa',
            dish_id: '80346',
            total_cost: 124,
        },
        {
            quantity: 1,
            name: 'Mutton Tikka Tawa',
            dish_id: '80346',
            total_cost: 411,
        },
        {
            quantity: 1,
            name: 'Mutton Tikka Tawa',
            dish_id: '80346',
            total_cost: 927,
        },
    ];

    const halfLength = Math.ceil(displayedDetails?.length / 2);

    const firstHalf = displayedDetails?.slice(0, halfLength);

    const secondHalf = displayedDetails?.slice(halfLength);

    const [showOrderDetails, setShowOrderDetails] = useState(false);

    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex justify-center overflow-auto md:bg-white md:relative p-4'>
                <div className='bg-white w-[652px] md:w-full rounded-xl px-8 py-6 m-auto md:p-0'>
                    {isMobileScreen ? (
                        <div
                            className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                            onClick={handleClickClose}>
                            <LeftArrowIcon className='rotate-90' />
                            <span className='paragraph-medium-medium pl-1'>Back to order rating</span>
                        </div>
                    ) : (
                        <div className='flex flex-row justify-between items-center mb-4 border-b border-neutral-300 pb-4'>
                            <div className=''>
                                <div className='paragraph-large-medium'>Complaint Details</div>
                                <div className='paragraph-medium-italic text-neutral-500'>
                                    Comprehensive overview of the complaint.
                                    {displayedDetails.order_id}
                                </div>
                            </div>

                            <div
                                className='cursor-pointer'
                                onClick={handleClickClose}>
                                <CloseIcon />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row justify-between md:block'>
                        <div className='flex flex-col'>
                            {firstHalf?.map((el, index) => (
                                <Item
                                    key={index}
                                    {...el}
                                />
                            ))}
                        </div>

                        <div className='flex flex-col'>
                            {secondHalf?.map((el, index) => (
                                <Item
                                    key={index}
                                    {...el}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-row mt-1.5 mb-4 border-b border-neutral-300 pb-4'>
                        <span className='paragraph-medium-medium text-neutral-500'>Message:</span>

                        <span className='pl-2 paragraph-medium-regular'>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio atque eum corrupti?
                        </span>
                    </div>

                    <div className='flex flex-row justify-between items-center'>
                        <h3 className='paragraph-medium-semi-bold'>Order summary</h3>
                        {/* <span
                            className='paragraph-medium-regular text-primary-500 cursor-pointer'
                            onClick={() => setShowOrderDetails((prevState) => !prevState)}>
                            {showOrderDetails ? 'Hide' : 'Show'}
                        </span> */}

                        <span
                            className='paragraph-medium-regular text-primary-500 cursor-pointer'
                            onClick={() => setShowOrderDetails((prevState) => !prevState)}
                            style={{
                                transition: 'color 0.3s ease-in-out',
                                cursor: 'pointer',
                            }}>
                            {showOrderDetails ? 'Hide' : 'Show'}
                        </span>
                    </div>

                    <div
                        className={`overflow-hidden pr-8 transition-all duration-700 ease-in-out ${
                            showOrderDetails ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'
                        }`}>
                        <div className='scrollbar-style md:[&::-webkit-scrollbar]:hidden overflow-auto h-full md:max-h-full mb-2 md:pr-0 -mr-8 md:mr-0 mt-3'>
                            {orderedDishes?.map((el, index) => (
                                <DishItem
                                    dish={el.name}
                                    quantity={el.quantity}
                                    price={el.total_cost}
                                    key={index}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='border-b border-neutral-300 mt-4' />

                    <div className='flex flex-row items-center gap-4 mt-9'>
                        <div className='w-1/2'>
                            <span className='paragraph-large-medium'>Step 1/2</span>
                        </div>

                        <div className='flex flex-row w-1/2 gap-3'>
                            <div
                                className='w-full'
                                onClick={() => {
                                    setShowRejectComplaintPopup(true);
                                    handleClickClose();
                                }}>
                                <LargeDestructiveButton label='Reject' />
                            </div>
                            <div
                                className='w-full'
                                onClick={() => {
                                    setShowAcceptComplaintPopup(true);
                                    handleClickClose();
                                }}>
                                <LargePrimaryButton label='Accept' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const Item = (props) => {
    const { item, value } = props;

    return (
        <div className='my-1.5 flex flex-row first:my-0 first:mb-1.5'>
            <span className='paragraph-medium-medium text-neutral-500'>{item}:</span>

            <span className='pl-2 paragraph-medium-regular'>{value}</span>
        </div>
    );
};

const DishItem = (props) => {
    const { price, quantity, dish } = props;

    const convertToCurrency = (price) =>
        price.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        });

    return (
        <div className='flex flex-row justify-between paragraph-medium-regular mt-1.5 pb-1.5 last:pb-0 first:mt-0'>
            <div className='flex flex-row'>
                <span className='mr-2 whitespace-nowrap'>{quantity} X</span>
                {dish}
            </div>

            <span className='min-w-[114px] text-right'>{convertToCurrency(price)}</span>
        </div>
    );
};
