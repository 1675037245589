import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../Assets/close.svg';
import { ReactComponent as LeftArrowIcon } from '../../../Assets/chevron-down.svg';
import _ from 'lodash';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../Components/Buttons/Button';
import Selectlangauge from '../Components/Selectlangauge';
import LangaugeViseDetails from '../Components/LangaugeViseDetails';
import { IsMobileScreen } from '../../../Constants/Constants';
import { FormProvider, useForm } from 'react-hook-form';
import UploadImagePopup from '../../../Components/UploadImagePopup/UploadImagePopup';
import { useSelector } from 'react-redux';
import InputSection from '../../Setting/Components/InputSection';
import APIV5 from '../../../api/axios/APIV5';
import useOutsideClick from '../../../Helper/Hooks/useOutsideClick';
import PresetPopupLoader from '../Components/PresetPopupLoader';

export default function NotificationPopup(props) {
    const {
        pageTitle,
        handleClickClose,
        popupDetails,
        pageTagline,
        handleClickUploadImage,
        selectedLangaugeId,
        showUploadImagePopup,
        setShowUploadImagePopup,
        uploadImageBackToPageName,
        setIsPresetEdited,
        isPresetEdited,
        details,
        setEditPreset,
        editPreset,
        metaTagsList,
        screensList,
        categoriesList,
        setCheckedItems,
        handleConfirmDeletePopup,
        setIsNotificationEdited,
        isNotificationEdited,
        setEditNotification,
        editNotification,
        isPresetDetailLoading,
        handleClick,
        selectedType,
    } = props;

    const toggleUploadImagePopup = () => {
        setShowUploadImagePopup(null);
    };

    const isMobileScreen = IsMobileScreen();

    const popupRef = useRef();
    useOutsideClick(popupRef, handleClick);

    const methods = useForm();

    const {
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
    } = methods;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const languages = useSelector((state) => state.langauge.availableLanguages);

    const [notificationDetailsEnable, setNotificationDetailsEnable] = useState(false);

    const [selectedLangauge, setSelectedLangauge] = useState([]);

    const [isSendNotificationButton, setIsSendNotificationButton] = useState(false);

    const [backToPage, setBackToPage] = useState('');

    const handleChangeLangauge = (value) => {
        setNotificationDetailsEnable(value.length !== 0);
        value.length === 0 ? setSelectedLangauge(['English']) : setSelectedLangauge(value);
    };

    useEffect(() => {
        setValue('notificationData', details);
        setValue('langauges', languages);

        languages.map((el) => setValue(`notification_title.${el.id}`, details?.translations.title[el.id]));
        languages.map((el) => setValue(`notification_description.${el.id}`, details?.translations.description[el.id]));
        languages.map((el) => setValue(`notification_time_type.${el.id}`, details?.interval));

        if (pageTitle === 'Notification details') {
            const timestamp = details?.time_to_send;
            const dateParts = timestamp.split(' ');
            const datePart = dateParts[0];
            const timePart = dateParts[1];
            const [year, month, day] = datePart.split('-');
            const [hours, minutes] = timePart.split(':');
            const dateObj = new Date(year, month - 1, day, hours, minutes);

            const formattedDate = dateObj.toString();

            setValue('notification_selected_time', {
                label: new Date(formattedDate),
                value: new Date(formattedDate),
            });
        }
        pageTitle === 'Notification details' &&
            languages.map((el) => setValue(`imageDetails.${el.id}`, details?.translations.image[el.id]?.image_url));
        pageTitle === 'Edit preset' &&
            languages.map((el) => setValue(`imageDetails.${el.id}`, details?.translations.image[el.id]));

        setValue('preset_name', popupDetails?.preset_name);
    }, [languages, details, popupDetails]);

    const languageList = useSelector((state) => state.langauge.availableLanguages);

    useEffect(() => {
        switch (pageTitle) {
            case 'Edit preset':
                setNotificationDetailsEnable(true);
                setIsSendNotificationButton(false);
                setBackToPage('presets list');
                setSelectedLangauge(languageList.map((lang) => lang.language_label));
                break;

            case 'Notification details':
                setNotificationDetailsEnable(true);
                setIsSendNotificationButton(false);
                setBackToPage('notification list');
                setSelectedLangauge(languageList.map((lang) => lang.language_label));
                break;

            default:
                setNotificationDetailsEnable(false);
                setIsSendNotificationButton(true);
                setBackToPage('notifications list');
                break;
        }
    }, [pageTitle, languageList]);

    const handleDiscardChanges = () => {
        languages.map((el) => setValue(`imageDetails.${el.id}`, ''));
        languages.map((el) => setValue(`notification_time_type.${el.id}`, ''));
        languages.map((el) => setValue(`notification_title.${el.id}`, details?.translations?.title[el.id] || ''));
        languages.map((el) =>
            setValue(`notification_description.${el.id}`, details?.translations?.description[el.id] || '')
        );
        languages.map((el) => setValue(`notification_time_type.${el.id}`, details?.interval));
    };

    const handleEditPreset = async (data) => {
        let translations = {};
        let interval = data?.notification_time_type[1]?.value;
        if (!interval) {
            for (const lang of languages) {
                const langValue = watch(`notification_time_type.${lang.id}`);
                if (langValue) {
                    interval = langValue;
                    break;
                }
            }
        }

        data?.notification_title?.forEach((item, index) => {
            translations = {
                ...translations,
                title: { ...translations.title, [index]: item },
                description: { ...translations.description, [index]: data?.notification_description?.[index] },
                image: { ...translations.image, [index]: data?.imageDetails?.[index]?.id },
            };
        });

        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}/preset-notifications/${details.id}`, {
                interval,
                translations,
            });
            {
                response.success === 'true' && setIsPresetEdited(!isPresetEdited);
                setEditPreset(!editPreset);
            }
            setIsPresetEdited(!isPresetEdited);
            setEditPreset(!editPreset);
        } catch (error) {
            console.log('Error updating preset:', error);
        }
    };

    let putNotification = {
        time_to_send: '',
        translations: {
            title: {},
            description: {},
            image: {},
        },
        screen: '',
        param: '',
    };

    const handleEditNotification = async (data) => {
        const parsedDate = data?.notificationTimeType ? new Date(data.notificationTimeType) : null;
        let formattedDate = '';

        if (parsedDate) {
            formattedDate = `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1)
                .toString()
                .padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;
        }

        const inputTime = data?.notificationTime || '';
        let formattedTime = '';

        if (inputTime) {
            const [timeStr, period] = inputTime.split(' ');
            const [hours, minutes] = timeStr?.split(':')?.map((part) => parseInt(part)) || [];

            const periodLower = period ? period.toLowerCase() : '';

            let hours24 = hours;
            if (periodLower === 'pm' && hours < 12) {
                hours24 += 12;
            } else if (periodLower === 'am' && hours === 12) {
                hours24 = 0;
            }

            formattedTime = `${hours24.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        }

        let translations = {};
        let time_to_send = formattedDate && formattedTime ? `${formattedDate} ${formattedTime}` : '';
        let screen = '';
        let param = null;

        data?.notification_title?.forEach((item, index) => {
            translations = {
                ...translations,
                title: { ...translations.title, [index]: item },
                description: { ...translations.description, [index]: data?.notification_description?.[index] },
                image: { ...translations.image, [index]: data?.imageDetails?.[index]?.id },
            };
            screen = data?.screen.label;
            param = data?.category?.value;
        });

        try {
            const response = await APIV5.put(`restaurants/${selectedRestaurantId}/notifications/${details.id}`, {
                time_to_send,
                translations,
                screen,
                param,
            });
            {
                response.success === 'true' && setIsNotificationEdited(!isNotificationEdited);
                setEditNotification(!editNotification);
            }
            setIsNotificationEdited(!isNotificationEdited);
            setEditNotification(!editNotification);
        } catch (error) {
            console.log('Error updating preset:', error);
        }
    };

    const handleButtonClick = () => {
        if (pageTitle === 'Edit preset') {
            handleSubmit(handleEditPreset)();
        } else {
            handleSubmit(handleEditNotification)();
        }
    };
    const handleDeleteButtonClick = () => {
        if (pageTitle === 'Edit preset') {
            handleDiscardChanges();
        } else {
            handleConfirmDeletePopup();
            setCheckedItems([details.id]);
        }
    };

    console.log('languages', watch('languages'));

    if (showUploadImagePopup) {
        return (
            <UploadImagePopup
                backToPage={uploadImageBackToPageName}
                showBackArrow={true}
                selectedType={selectedType}
                handleClickClose={toggleUploadImagePopup}
                pageTagLine='Upload an image to be used notification'
                setUploadedImageDetails={(data) => setValue(`imageDetails.${showUploadImagePopup}`, data)}
            />
        );
    }

    return (
        <>
            <FormProvider {...methods}>
                <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex overflow-auto p-4 md:p-0 md:relative'>
                    <div
                        className={`w-full md:h-full md:overflow-auto rounded-xl md:rounded-none bg-shades-50 px-8 py-6 md:py-0 m-auto [&::-webkit-scrollbar]:hidden md:px-4 md:max-w-full ${
                            selectedLangauge.length !== 1 ? 'max-w-[940px] lg:max-w-[734px]' : 'max-w-[475px]'
                        }`}
                        ref={popupRef}>
                        {isPresetDetailLoading && pageTitle === 'Edit preset' ? (
                            <PresetPopupLoader />
                        ) : (
                            <>
                                {!isMobileScreen ? (
                                    <div className='flex flex-row justify-between items-center mb-6'>
                                        <div className=''>
                                            <span className='paragraph-large-medium'>{pageTitle}</span>
                                            <p className='paragraph-medium-italic text-neutral-500'>{pageTagline}</p>
                                        </div>
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => handleClickClose()}>
                                            <CloseIcon />
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className='flex flex-row mb-4 pt-4 cursor-pointer'
                                        onClick={() => handleClickClose()}>
                                        <div className='w-6 h-6'>
                                            <LeftArrowIcon className='rotate-90' />
                                        </div>
                                        <span className='ml-1 paragraph-medium-medium'>Back to {backToPage}</span>
                                    </div>
                                )}

                                {pageTitle === 'Edit preset' ? (
                                    <div className='mb-3.5'>
                                        <InputSection
                                            label='Preset name'
                                            labelStyle='paragraph-small-medium text-neutral-300'
                                            disabled={notificationDetailsEnable}
                                            name='preset_name'
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`mb-3.5 max-w-[411px] md:mb-3 md:max-w-full ${
                                            selectedLangauge.length !== 1 && 'lg:max-w-[311px]'
                                        }`}>
                                        <Selectlangauge
                                            handleChangeLangauge={handleChangeLangauge}
                                            notificationDetailsEnable={notificationDetailsEnable}
                                            pageTitle={pageTitle}
                                            details={details}
                                        />
                                    </div>
                                )}

                                <div className='flex flex-row md:block md:mb-20'>
                                    {watch('langauges')?.map((el, index) => (
                                        <div
                                            key={index}
                                            className={`${
                                                selectedLangauge?.length === 1
                                                    ? 'w-full'
                                                    : 'w-1/2 odd:pr-6 even:pl-6 even:border-l border-neutral-300 md:w-full odd:md:pr-0 even:md:pl-0 md:even:border-t even:md:mt-4 even:md:pt-4 md:even:border-l-0'
                                            }`}>
                                            <LangaugeViseDetails
                                                disabled={notificationDetailsEnable}
                                                pageTitle={pageTitle}
                                                popupDetails={popupDetails}
                                                handleClickUploadImage={handleClickUploadImage}
                                                setShowUploadImagePopup={setShowUploadImagePopup}
                                                index={index}
                                                hasImageSizeReccomandation={selectedLangauge.length === 1}
                                                details={details}
                                                selectedLangaugeId={selectedLangaugeId}
                                                setSelectedLangauge={setSelectedLangauge}
                                                id={el.id}
                                                metaTagsList={metaTagsList}
                                                screensList={screensList}
                                                categoriesList={categoriesList}
                                                language={el.language_label}
                                            />
                                        </div>
                                    ))}
                                </div>

                                {isSendNotificationButton ? (
                                    <div className='mt-12 md:bottom-0 md:fixed md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4 md:-ml-4'>
                                        <LargePrimaryButton
                                            label='Send notification'
                                            disabled={!notificationDetailsEnable}
                                        />
                                    </div>
                                ) : (
                                    <div className='flex flex-row mt-12 md:-ml-4 md:bottom-0 md:fixed md:w-full md:pt-2 md:shadow-dropShadow md:bg-white md:pb-1 md:px-4'>
                                        <div
                                            className={`w-1/2 ${selectedLangauge.length === 2 ? 'mr-6' : 'mr-2'} mobile:mr-0`}
                                            onClick={handleDeleteButtonClick}>
                                            <LargeDestructiveButton
                                                label={
                                                    pageTitle === 'Notification details' ? 'Delete' : 'Discard changes'
                                                }
                                                disabled={!notificationDetailsEnable}
                                                fontsSize='text-base mobile:text-[15px]'
                                            />
                                        </div>

                                        <div
                                            className={`w-1/2 ml-[9.5px] ${selectedLangauge.length === 2 ? 'ml-6' : 'ml-2'}`}
                                            onClick={handleButtonClick}>
                                            <LargePrimaryButton
                                                label={pageTitle === 'Notification details' ? 'Resend' : 'Save changes'}
                                                disabled={!notificationDetailsEnable}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </FormProvider>
        </>
    );
}
