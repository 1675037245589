import React, { useState } from 'react';
import ReservationsWaitlist from './Components/ReservationsWaitlist';
import { IsMobileScreen } from '../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import ContentControllerButton from './Components/ContentControllerButton';
import AvailableTables from './Components/AvailableTables';
import StatusColorViewer from './Components/StatusColorViewer';
// import TakeawayPopup from '../Order/Popup/Takeaway/TakeawayPopup';
// import DeliveryPopup from '../Order/Popup/Delivery/DeliveryPopup';
import EnterTableCustomerDetailsPopup from './Components/EnterTableCustomerDetailsPopup';
import Layout from './Components/Layout';
import { ContextProvider } from '../../Components/ContextProvider/ContextProvider';
import MobileOrderingMode from '../../Components/PosComponents/MobileOrderingMode';
import ReserveWaitListFillDetails from './Popups/ReserveWaitListFillDetails/ReserveWaitListFillDetailsPopup';
import AddReservationFeePopup from './Popups/AddReservationFee/AddReservationFeePopup';
import ReservationDetailsPopup from './Popups/ReservationDetails/ReservationDetailsPopup';
import RefundPopup from '../BillPayment/Components/RefundPopup';

export default function PosTableView() {
    const [showReservationDetails, setShowReservationDetails] = useState(false);

    const screenWidth = useWindowSize().width;

    const isMobileScreen = IsMobileScreen();

    const [showPopup, setShowPopup] = useState(null);

    return (
        <>
            <ContextProvider context={{ showPopup, setShowPopup }}>
                <Layout>
                    <div className='flex flex-row'>
                        <div
                            className={`${
                                showReservationDetails && screenWidth > 1280 && 'border-r border-neutral-300 pr-5 mr-8'
                            }`}>
                            <ContentControllerButton
                                setShowPopup={setShowPopup}
                                setShowReservationDetails={setShowReservationDetails}
                            />

                            <AvailableTables
                                showReservationDetails={showReservationDetails}
                                setShowPopup={setShowPopup}
                            />
                        </div>

                        {showReservationDetails && screenWidth > 1280 && (
                            <div className='max-w-[377px] pageContent:min-w-max w-full pt-6 sticky top-[60px] h-full'>
                                <ReservationsWaitlist setShowPopup={setShowPopup} />
                            </div>
                        )}
                    </div>

                    {!isMobileScreen && <StatusColorViewer />}
                </Layout>
            </ContextProvider>

            {/* {showPopup === 'delivery' && (
                <DeliveryPopup
                    handleClickClose={() => setShowPopup(null)}
                    backToPage='table view'
                />
            )}

            {showPopup === 'takeaway' && (
                <TakeawayPopup
                    handleClickClose={() => setShowPopup(null)}
                    backToPage='table view'
                />
            )} */}

            {showPopup === 'waitingList' && (
                <ReserveWaitListFillDetails
                    type='waiting'
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'reserveTable' && (
                <ReserveWaitListFillDetails
                    type='reserve'
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'enterTableCustomerDetails' && (
                <EnterTableCustomerDetailsPopup handleClickClose={() => setShowPopup(null)} />
            )}

            {showPopup === 'reservationDetails' && <ReservationDetailsPopup setShowPopup={setShowPopup} />}

            {showPopup === 'waitListDetails' && (
                <ReservationDetailsPopup
                    type='waitList'
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'mobileOrderingMode' && <MobileOrderingMode handleClickClose={() => setShowPopup(null)} />}

            {showPopup === 'reservationWaitList' && screenWidth < 1280 && (
                <ReserveWaitListFillDetails
                    handleClickClose={() => setShowPopup(null)}
                    setShowPopup={setShowPopup}
                />
            )}

            {showPopup === 'addReservationFee' && <AddReservationFeePopup setShowPopup={setShowPopup} />}

            {showPopup === 'refund' && <RefundPopup handleClickClose={() => setShowPopup('reservationDetails')} />}
        </>
    );
}
