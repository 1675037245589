import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';
import { IsMobileScreen } from '../../../../Constants/Constants';

export default function LinkOutletHeader(props) {
    const { handleClickClose } = props;

    const isMobileScreen = IsMobileScreen();

    return (
        <>
            {isMobileScreen ? (
                <div
                    className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer'
                    onClick={handleClickClose}>
                    <LeftArrowIcon className='rotate-90' />
                    <span className='paragraph-medium-medium ml-1'>Back to catalogue preset</span>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center mb-2 pb-4'>
                    <div>
                        <span className='paragraph-large-medium'>Link outlets</span>
                        <div className='flex flex-row items-center'>
                            <span className='font-normal italic text-base leading-6 text-neutral-500'>
                                Enable the banner for the outlets
                            </span>
                        </div>
                    </div>
                    <div
                        className='cursor-pointer'
                        onClick={handleClickClose}>
                        <CloseIcon />
                    </div>
                </div>
            )}
        </>
    );
}
