import React from 'react';
import DropdownSection from '../../../../Components/DropDown/DropdownSection';
import { bannerType } from './constants';

export default function TypeSelection() {
    return (
        <DropdownSection
            label='Banner type'
            boxHeight='h-[44px]'
            name='bannerType'
            className='mb-[13px] relative'
            menuItems={bannerType}
            maxWidth='max-w-full'
        />
    );
}
