import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { List } from 'react-virtualized';
import { ReactComponent as DownArrowIcon } from '../../Assets/chevron-down.svg';
import { MenuItemsLoader } from '../ContentLoader/MenuItemsLoader.js';

export default function SearchableDropdownWithWindowing(props) {
    const {
        menuItems,
        placeholder,
        handleSelect,
        setIsLoadingMenuItems,
        isLoadingMenuItems,
        openFromBottom,
        setPageState,
        pageState,
        fetchNewData,
        fixedHeight,
        showDisplayValue,
        defaultValue,
        action,
        label,
        labelMarginB,
        labelStyle,
        actionTextStyle,
        handleClickAction,
        removeSectionStyle,
        actionTextColor,
    } = props;

    const onCompleteTyping = (searchedText) => {
        setDisplayValue(searchedText);
        setIsLoadingMenuItems?.(!!searchedText.length);
        props.onCompleteTyping?.(searchedText);
    };

    // useEffect(() => {
    //     setOptions(menuItems);
    //     setDisplayValue(defaultValue);
    // }, [menuItems]);

    const [displayValue, setDisplayValue] = useState(defaultValue);

    const fetchMoreOptions = async () => {
        setIsLoadingMenuItems(true);
        try {
            fetchNewData({ page: pageState.page + 1, isScroll: true, perPage: 1 });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingMenuItems(false);
        }
    };

    const rowRenderer = ({ index, key, style }) => {
        const { label, value } = menuItems[index];

        return (
            <Combobox.Label
                onClick={(e) => {
                    handleChange(menuItems[index]);
                }}
                key={key}
                className={({ active }) =>
                    `paragraph-small-regular ml-1 pt-5 mb-2 cursor-pointer ${
                        displayValue === label &&
                        'text-primary-500  active:paragraph-small-semi-bold active:text-primary-500'
                    }`
                }
                value={value}
                style={style}>
                <span>{label}</span>
            </Combobox.Label>
        );
    };

    const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
        if (scrollHeight - clientHeight - 1 < scrollTop && pageState.lastPage > pageState.page) {
            fetchMoreOptions();

            setPageState &&
                setPageState((prevState) => {
                    return { ...prevState, page: prevState.page + 1 };
                });
        }
    };

    const handleChange = (value) => {
        handleSelect(value.value);
        showDisplayValue ? setDisplayValue(value.label) : setDisplayValue('');
    };

    return (
        <div className=''>
            {(label || action) && (
                <div className={`justify-between w-full ${labelMarginB ?? 'mb-1'} flex flex-row`}>
                    <label className={`${labelStyle ?? 'paragraph-small-medium'}`}>{label}</label>
                    <span
                        className={`${actionTextStyle ?? 'paragraph-small-medium'} ${
                            actionTextColor ?? 'text-primary-500'
                        } cursor-pointer ${removeSectionStyle}`}
                        onClick={handleClickAction}>
                        {action}
                    </span>
                </div>
            )}
            <Combobox value={displayValue}>
                <div className='relative'>
                    <Combobox.Button className='relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-Xsmall focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm'>
                        <Combobox.Input
                            className='w-full flex flex-row items-center justify-between mobile:max-w-full rounded-md outline-none border py-3 appearance-none border-neutral-300 pl-4 pr-10 placeholder:text-neutral-300'
                            placeholder={placeholder}
                            displayValue={displayValue}
                            onChange={(event) => onCompleteTyping(event.target.value)}
                        />

                        <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
                            <DownArrowIcon
                                className='dropDownIconRotate'
                                height={24}
                                width={24}
                                fill='#131126'
                            />
                        </span>
                    </Combobox.Button>

                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-100'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'>
                        <Combobox.Label
                            autoFocus={true}
                            className={`absolute left-0 right-0 mt-11 px-4 py-2 border paragraph-small-medium rounded-md shadow-medium bg-shades-50 font-normal z-50 outline-none ${
                                openFromBottom && 'bottom-full mb-2'
                            } ${fixedHeight && fixedHeight + ' overflow-auto scrollbar-style'}`}>
                            {isLoadingMenuItems ? (
                                <MenuItemsLoader />
                            ) : !menuItems?.length ? (
                                <div className='relative cursor-default select-none px-1 py-2 paragraph-small-regular text-neutral-700'>
                                    Nothing found.
                                </div>
                            ) : (
                                <List
                                    width={600}
                                    autoWidth
                                    height={150}
                                    rowCount={menuItems.length}
                                    rowHeight={35}
                                    rowRenderer={rowRenderer}
                                    onScroll={handleScroll}
                                />
                            )}
                        </Combobox.Label>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}
