import React, { useEffect, useState } from 'react';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import TableLoader from '../../../Components/ContentLoader/TableLoader';
import { BlankComponentTable } from '../../../Components/BlankComponent/BlankComponent';

export default function OutletWiseOfferTable(props) {
    const { list, activeState, selectedOffer, setSelectedOffer, unlinkOutlet, onSelectOffer, isListLoading } = props;

    const [menuItem, setMenuItem] = useState(list);

    useEffect(() => {
        setMenuItem(list);
    }, [list]);

    return (
        <>
            <section>
                <div className='w-full border border-neutral-300 rounded-lg overflow-auto scrollbar-style'>
                    <table className='w-full break-words'>
                        <TableHeader
                            isActiveSelection={activeState.selection}
                            list={menuItem}
                            selectedOffer={selectedOffer}
                            setSelectedOffer={setSelectedOffer}
                        />

                        {isListLoading ? (
                            <tbody>
                                <TableLoader columns={5} />
                            </tbody>
                        ) : !menuItem?.length ? (
                            <BlankComponentTable
                                colSpan={5}
                                previewText='No offer at this moment'
                            />
                        ) : (
                            <tbody className='overflow-auto h-[70px] scrollbar-style'>
                                {menuItem?.map((el) => {
                                    return (
                                        <TableRows
                                            {...el}
                                            key={el.id}
                                            selectedOffer={selectedOffer}
                                            setSelectedOffer={setSelectedOffer}
                                            activeState={activeState}
                                            onSelectOffer={onSelectOffer}
                                            unlinkOutlet={() => unlinkOutlet(el.id)}
                                        />
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            </section>
        </>
    );
}
