import React from 'react';
import { DropDownTabs } from '../../../../Components/DropDown/DropDownTabs';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { IsIpadScreen, IsMobileScreen } from '../../../../Constants/Constants';
import { useWindowSize } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../Components/Buttons/Button';
import { MultipleTab } from '../../../../Components/Tabs/Tabs';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { addDish, editDish } from '../../Components/Catalogue/functions';
import { useSelector } from 'react-redux';
import {
    formatBadgeIds,
    formatBasicDetails,
    formateDeleteOrderingMode,
    formatePriceAndTimingWithoutVariant,
    formateVariant,
    formatOrderingModeWithVariant,
    formatUpSellingDetails,
} from '../Helper/formattingFunctions';

export default function Layout(props) {
    const { children, showHeaderTab } = props;

    const { dishTab, dishId, categoryId } = useParams();

    const orderingMode = useSelector((state) => state.outlet.orderingModes);

    const isMobileScreen = IsMobileScreen();
    const screenSize = useWindowSize();
    const isIpadScreen = IsIpadScreen();
    const navigate = useNavigate();

    const isEditMode = !!dishId;

    const tabs = [
        {
            item: 'Basic details',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/basic-details`
                ),
            link: 'basic-details',
            formState: 'details',
        },
        {
            item: 'Expose dish',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/expose-dish`
                ),
            link: 'expose-dish',
            formState: 'exposeDish',
        },
        {
            item: 'Dish pricing',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/dish-pricing`
                ),
            link: 'dish-pricing',
            formState: 'pricing',
        },
        {
            item: 'Badges',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/badges`
                ),
            link: 'badges',
            formState: 'badges',
        },
        {
            item: 'Link add-on groups',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/link-addon-groups`
                ),
            link: 'link-addon-groups',
            formState: 'linkAddon',
        },
        {
            item: 'Dish timings',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/dish-timings`
                ),
            link: 'dish-timings',
            formState: 'timings',
        },
        {
            item: 'Up-sell dishes',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/up-sell-dishes`
                ),
            link: 'up-sell-dishes',
            formState: 'upSellDish',
        },
        {
            item: 'Up-sell current dish in cart',
            onClick: () =>
                navigate(
                    `${isEditMode ? `/menu/${categoryId}/edit-dish/${dishId}` : `/menu/dish/${categoryId}`}/up-sell-current-dish`
                ),
            link: 'up-sell-current-dish',
            formState: 'upSellInCart',
        },
    ];

    const {
        handleSubmit,
        formState: { errors },
        setError,
    } = useFormContext();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const createDish = async (body) => {
        try {
            const response = await addDish(selectedRestaurantId, body, categoryId);

            if (response?.success) {
                navigate('/menu/catalogue');
            }
        } catch (error) {
            console.log('error : ', error);

            console.log(
                `error?.response?.data?.errors?.internal_name ==>`,
                error?.response?.data?.errors?.internal_name
            );

            if (error?.response?.data?.errors?.internal_name) {
                setError('details.nickname', { message: error?.response?.data?.errors?.internal_name });
            }
        }
    };

    const updateDish = async (body) => {
        try {
            const response = await editDish(body, categoryId, dishId);

            if (response?.success) {
                navigate('/menu/catalogue');
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const formatDishData = (data) => {
        console.log('const data = ', data);

        const basicDetails = formatBasicDetails(data.details);
        const badgeId = formatBadgeIds(data.availableBadge);
        const upSellingDetails = formatUpSellingDetails(data?.upSelling);
        const upSellCurrentDish = { upselling_item: data?.upSellInCart?.value };
        const deletedOrderingMode = formateDeleteOrderingMode(data.exposeDish, orderingMode);

        let orderingModeVariantPrice;

        if (!data?.do_add_variant) {
            orderingModeVariantPrice = formatePriceAndTimingWithoutVariant(data);
        } else {
            orderingModeVariantPrice = {
                ...formateVariant(
                    data?.pricing?.variantPrice,
                    data.dishPackingPrice,
                    data.dishGstSlab?.value,
                    data?.variantGroupTitle,
                    data?.variantGroupDescription,
                    data.has_aggmark_show?.value ?? 0,
                    orderingMode,
                    data?.variantGroupId,
                    data?.availableAllergens
                ),
                ...formatOrderingModeWithVariant(data),
            };
        }

        const state = {
            ...basicDetails,
            ...badgeId,
            ...deletedOrderingMode,
            ...upSellingDetails,
            ...upSellCurrentDish,
            ...orderingModeVariantPrice,
        };

        console.log(' state : ', state);

        return state;
    };

    const onSave = (data) => {
        const currentIndex = tabs.findIndex((el) => el.link === dishTab);

        const nextSection = tabs[currentIndex + 1]?.link;

        const route = isEditMode
            ? `/menu/${categoryId}/edit-dish/${dishId}/${nextSection}`
            : `/menu/dish/${categoryId}/${nextSection}`;

        if (nextSection) {
            navigate(route);
        } else {
            // formatDishData(data);
            isEditMode ? updateDish(formatDishData(data)) : createDish(formatDishData(data));
        }
    };

    const findHasError = (tab) => {
        let errorObj = errors;

        if (_.isEmpty(errors)) return;

        if (tab.formState === 'details' && Array.isArray(errorObj?.details?.display)) {
            const displayErrors = errorObj?.details?.display?.slice(1);
            const allNull = displayErrors.every((error) => error === null);
            if (allNull) return false;
        }

        return !!errorObj[tab.formState];
    };

    return (
        <>
            <div className='bg-[#fafafa]'>
                <div
                    className={`px-8 lg:px-4 w-full max-w-[1336px] mx-auto bg-white relative md:max-w-full ${
                        !showHeaderTab ? 'md:px-0' : 'px-3'
                    }`}
                    style={{ height: isIpadScreen ? screenSize.height - 132 : screenSize.height - 147 }}>
                    {isMobileScreen && showHeaderTab && (
                        <div className=''>
                            <div
                                className='flex paragraph-medium-medium flex-row mb-4 cursor-pointer md:px-1 md:pt-4'
                                onClick={() => navigate('/menu')}>
                                <LeftArrow className='rotate-90' />
                                <span className='ml-1'>Back to catalogue</span>
                            </div>

                            <div className='block mb-4 pb-4 border-b border-neutral-300 mx-1'>
                                <DropDownTabs menuItems={tabs} />
                            </div>
                        </div>
                    )}

                    <div className='flex flex-row md:block'>
                        {!isMobileScreen && (
                            <div
                                className='flex flex-col mt-4 pr-6 mr-5 border-r min-w-[197px] border-neutral-300 overflow-auto [&::-webkit-scrollbar]:hidden lg:pr-4 lg:mr-3 lg:min-w-[190px]'
                                style={{ height: isIpadScreen ? screenSize.height - 148 : screenSize.height - 163 }}>
                                {tabs.map((el, index) => {
                                    const hasError = findHasError(el);

                                    return (
                                        <div
                                            className='lg:max-w-[173px] w-full mb-4'
                                            key={index}>
                                            <MultipleTab
                                                hasError={hasError}
                                                label={el.item}
                                                index={index}
                                                onClick={el.onClick}
                                                isActive={dishTab === el.link}
                                                minWidth='min-w-[173px]'
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div
                            className='mt-4 w-full pl-1 md:pr-1 md:mt-0 overflow-auto md:pb-20 md:overflow-visible'
                            style={{ height: screenSize.width > 767 ? screenSize.height - 165 : 'auto' }}>
                            {children}
                        </div>
                    </div>
                </div>

                <div className='sticky z-[49] md:z-[8] group-[.undock-left-panel]:z-[8] md:fixed md:justify-center md:border-none md:py-0 md:pb-1 md:pt-2 md:shadow-dropShadow left-0 right-0 bottom-0 flex px-8 lg:px-4 flex-row justify-end w-full max-w-[1336px] mx-auto bg-white py-6 border-t border-neutral-300 lg:py-4'>
                    <div
                        className='min-w-[196px] mr-5 md:w-1/2 md:mr-[7.5px] md:min-w-0'
                        onClick={() => navigate('/menu')}>
                        <LargeDestructiveButton label='Discard' />
                    </div>

                    <div
                        className='min-w-[196px] md:w-1/2 md:ml-[7.5px] md:min-w-0'
                        onClick={handleSubmit(onSave)}>
                        <LargePrimaryButton
                            label={dishTab === 'up-sell-current-dish' ? 'Save' : 'Next'}
                            isDefault={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
