import React, { useEffect, useState } from 'react';
import { ReactComponent as MobileIcon } from '../../../Assets/mobile.svg';

export const InputField = (props) => {
    const {
        isMobileNumberPage,
        isValidInput,
        setIsValidInput,
        onKeyDown,
        setValue,
        inputValue,
        onInputClick,
        showMobileIcon,
        paddingLeft,
        clearErrors,
        disabled,
        mobileNumberFIeldName,
        otpFieldName,
    } = props;
    const handleChange = (e) => {
        const validLength = isMobileNumberPage ? 10 : 5;

        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const truncatedValue = numericValue.slice(0, validLength);
        isMobileNumberPage ? setValue(mobileNumberFIeldName, truncatedValue) : setValue(otpFieldName, truncatedValue);
        clearErrors && clearErrors(mobileNumberFIeldName);
        !isValidInput && truncatedValue.length === validLength && setIsValidInput(true);
    };

    const initialInputfieldDetails = {
        mobileField: {
            label: 'Enter mobile Number',
            placeholder: 'Enter Mobile Number',
        },

        otpField: {
            label: 'Enter OTP',
            placeholder: 'Enter OTP',
        },
    };

    const [inputfieldDetails, setInputfieldDetails] = useState(initialInputfieldDetails.mobileField);

    useEffect(() => {
        isMobileNumberPage
            ? setInputfieldDetails(initialInputfieldDetails.mobileField)
            : setInputfieldDetails(initialInputfieldDetails.otpField);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileNumberPage]);

    return (
        <>
            <label className='paragraph-small-medium'>{inputfieldDetails.label}</label>
            <div
                className='relative mt-1'
                onClick={onInputClick}>
                {showMobileIcon && (
                    <div className='h-6 w-6 rounded absolute top-3 left-4'>
                        <MobileIcon stroke='#D3D2D8' />
                    </div>
                )}

                <input
                    onKeyDown={onKeyDown}
                    onFocus={onInputClick}
                    type='text'
                    disabled={disabled}
                    value={inputValue || ''}
                    onChange={handleChange}
                    placeholder={inputfieldDetails.placeholder}
                    className={`${paddingLeft} w-full h-12 paragraph-small-regular disabled:text-neutral-300 border rounded-md border-neutral-300 pl-12 pr-4 placeholder:text-neutral-300 focus:border-primary-500 disabled:bg-white outline-none focus:outline-none focus:ring-4 focus:ring-primary-100 caret-primary-500 placeholder:paragraph-small-regular`}
                />
            </div>

            {!isValidInput && (
                <p className='pt-1 paragraph-x-small-regular text-destructive-500'>
                    * {isMobileNumberPage ? 'Enter valid mobile number' : 'Entered OTP is invalid'}{' '}
                </p>
            )}
        </>
    );
};
