import React from 'react';
import { IsMobileScreen } from '../../../../Constants/Constants';
import { ReactComponent as LeftArrow } from '../../../../Assets/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../Assets/close.svg';

export default function Layout(props) {
    const { children, handleClickClose, title, description } = props;

    const isMobileScreen = IsMobileScreen();
    return (
        <>
            <div className='fixed bg-black bg-opacity-50 inset-0 md:z-[9] z-50 flex md:relative p-4 md:p-0 overflow-auto'>
                <div className='h-fit max-w-[710px] w-full md:h-full md:overflow-auto rounded-xl md:rounded-none bg-shades-50 px-8 py-6 m-auto [&::-webkit-scrollbar]:hidden md:px-4 md:max-w-full md:py-0 md:mt-0 md:pb-4'>
                    {isMobileScreen && (
                        <div
                            className='flex flex-row mb-4 pt-4 cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <div className='w-6 h-6'>
                                <LeftArrow className='rotate-90' />
                            </div>
                            <span className='ml-1 paragraph-medium-medium'>Back to dashboard</span>
                        </div>
                    )}

                    <div className='flex flex-row justify-between items-center mb-6 md:mb-2'>
                        <div>
                            <span className='paragraph-large-medium'>{title}</span>
                            <p className='paragraph-medium-italic text-neutral-500'>{description}</p>
                        </div>
                        <div
                            className='md:hidden cursor-pointer'
                            onClick={() => handleClickClose()}>
                            <CloseIcon />
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
}
