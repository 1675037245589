import React, { useState } from 'react';
import { LargeDestructiveButton, LargePrimaryButton } from '../../../../../../Components/Buttons/Button';

import { ReactComponent as AddIcon } from '../../../../../../Assets/add.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../Assets/trash.svg';
import { MultipleTab } from '../../../../../../Components/Tabs/Tabs';
import DeleteVariantPopUp from './DeleteVariantPopUp';
import { IsIpadScreen } from '../../../../../../Constants/Constants';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function Variants() {
    const { setValue, watch } = useFormContext();

    const [showDeleteVariantPopup, setShowDeleteVariantPopup] = useState(false);

    const handleClickVariant = (index) => {
        setValue('pricing.activeVariant', index);
        deleteVariant && setShowDeleteVariantPopup(!showDeleteVariantPopup);
    };

    const [deleteVariant, setDeleteVariant] = useState(false);

    const isIpadScreen = IsIpadScreen();

    const activeVariant = watch('pricing.activeVariant');

    const availableVariant = watch('pricing.variantPrice') ?? [];

    const handleClickAddVariant = () => {
        const newNumberOfVariants = availableVariant?.length;

        setValue('pricing.variantPrice', [...availableVariant, { id: newNumberOfVariants }]);

        availableVariant?.length === 0 && setValue('pricing.activeVariant', 0);
    };

    const handleClickDelete = () => {
        const filteredVariants = availableVariant.filter((el) => el.id !== activeVariant);

        setValue('pricing.variantPrice', filteredVariants);

        setValue('pricing.activeVariant', 0);

        setShowDeleteVariantPopup(false);

        setDeleteVariant(!deleteVariant);
    };

    const selectedLanguageId = useSelector((state) => state.langauge.languageId);

    const renderVariants = useCallback(() => {
        return availableVariant?.map((el, index) => {
            const variantName = availableVariant?.[index]?.title?.[selectedLanguageId];

            return (
                <div
                    className='mr-4 mb-4 lg:mb-2 inline-block align-top'
                    key={index}>
                    <MultipleTab
                        maxWidth='max-w-full'
                        maxHeight='max-h-fit'
                        minWidth='min-w-[143px]'
                        label={!!variantName ? variantName : 'Variant name'}
                        icon={<DeleteIcon stroke={el.id === activeVariant ? '#6C5DD3' : '#3F3D46'} />}
                        showIcon={deleteVariant}
                        index={index}
                        isActive={el.id === activeVariant}
                        onClick={() => handleClickVariant(el.id)}
                        textWrap={!variantName && 'text-neutral-300 italic'}
                    />
                </div>
            );
        });
    }, [availableVariant, activeVariant, deleteVariant, watch('pricing')]);

    return (
        <>
            {watch('do_add_variant') && !isIpadScreen && (
                <div className='mb-2'>
                    <div className='inline-block align-top'>{renderVariants()}</div>

                    <div
                        className='mr-2 mb-4 inline-block align-top cursor-pointer'
                        onClick={handleClickAddVariant}>
                        <LargePrimaryButton
                            label='Add variant'
                            leftIconDefault={<AddIcon stoke='#FFFFFF' />}
                            leftIconClick={<AddIcon stroke='#C4BEED' />}
                            isDefault={false}
                        />
                    </div>

                    <div
                        className='ml-2 mb-4 min-w-[146px] inline-block align-top cursor-pointer'
                        onClick={() => setDeleteVariant(!deleteVariant)}>
                        <LargeDestructiveButton
                            isClicked={!!deleteVariant}
                            label='Delete variant'
                        />
                    </div>
                </div>
            )}
            {showDeleteVariantPopup && (
                <DeleteVariantPopUp
                    handleClickClose={() => setShowDeleteVariantPopup(false)}
                    variantNumber={activeVariant}
                    handleClickDelete={handleClickDelete}
                />
            )}
        </>
    );
}
