import React from 'react';
import DropdownSection from '../../Components/DropdownSection';
import { preferenceMenuItem } from '../../../../../../Constants/Constants';
import InputSection from '../../Components/InputSection';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../../../../Components/Error/ErrorMessage';

export default function UsageCountPerUserSection(props) {
    const { setShowTabChangeWarningPopup } = props;

    const {
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <div className='my-4 max-w-[312px] relative md:max-w-full'>
                <DropdownSection
                    menuItems={preferenceMenuItem}
                    label='Max. usage count per user preference'
                    shadow='shadow-smallDropDownShadow'
                    labelPaddingB='pb-2'
                    height='h-[52px]'
                    labelStyle='paragraph-large-medium'
                    rules={{ required: 'Please select preference' }}
                    name='bill_payments.isMaximumUsagePerUser'
                    placeholder='Please select preference'
                    clearErrorName={['bill_payments.max_usage_count_per_user']}
                    handleSelection={() => setShowTabChangeWarningPopup(true)}
                />
                {errors?.bill_payments?.isMaximumUsagePerUser && (
                    <ErrorMessage errors={errors?.bill_payments?.isMaximumUsagePerUser} />
                )}
            </div>

            <InputSection
                placeholder='Please add usage count per user'
                name='bill_payments.max_usage_count_per_user'
                label='Maximum usage count per user'
                labelStyle={`paragraph-large-medium ${
                    !watch('bill_payments.isMaximumUsagePerUser')?.value && 'text-neutral-300'
                }`}
                shadow='shadow-smallDropDownShadow'
                disabled={!watch('bill_payments.isMaximumUsagePerUser')?.value}
                inputType='number'
                rules={{
                    validate: (value) => {
                        if (value) return true;
                        if (watch('bill_payments.isMaximumUsagePerUser')?.value) return 'Please enter a minimum amount';
                    },
                }}
                handleInputChange={() => setShowTabChangeWarningPopup(true)}
            />
            {errors?.bill_payments?.max_usage_count_per_user && (
                <ErrorMessage errors={errors?.bill_payments?.max_usage_count_per_user} />
            )}
        </>
    );
}
