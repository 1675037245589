import React from 'react';
import { SingleLoader } from '../../../Components/ContentLoader/DropdownLoader';

export function Loader() {
    return (
        <>
            <div className='pr-6 mr-5'>
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
                <SingleLoader className='max-w-[175px] h-[48px] mt-4' />
            </div>
        </>
    );
}

export function CardLoader() {
    return <SingleLoader className='max-w-[196px] h-[230px]' />;
}
